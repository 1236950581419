import React from 'react';
import Login from 'pages/public/Login';
import Reset from 'pages/public/ResetLink';
import ChangePassword from 'pages/public/ChangePassword';
import { Navigate, Route, Routes } from 'react-router-dom';

import { PublicRoutes as PublicRoutesDirectives } from './routeConstans';
import ForgotPassword from 'pages/public/ForgotPassword';
import Otp from 'pages/public/Otp';
import GenerateOtp from 'pages/public/GenerateOtp';
import RESETPASSWORD from 'pages/public/CreatePassword';

const PublicRoutes = () => {
  const { LOGIN, RESET, RESETPASSWORD:RESETPSWD , FORGOTPASSWORD, GENERATEOTP, VERIFYOTP } =
    PublicRoutesDirectives;
  return (
    <Routes>
      <Route path='/' element={<Navigate to={'/login'} replace />} />
      <Route path={LOGIN} element={<Login />} />
      <Route path={RESET} element={<Reset />} />
      <Route path={RESETPSWD} element={<RESETPASSWORD/>} />
      <Route path={FORGOTPASSWORD} element={<ForgotPassword />} />
      <Route path={RESET} element={<ChangePassword />} />
      <Route path={GENERATEOTP} element={<GenerateOtp />} />
      <Route path={VERIFYOTP} element={<Otp />} />
      <Route path='*' element={<Navigate to={'/login'} />} />
    </Routes>
  );
};

export default PublicRoutes;
