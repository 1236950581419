import {
  Breadcrumbs,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import HomeIcon from 'assets/png/dashboardactive.svg';
import ArrowIcon from 'assets/png/breadarrow.svg';
import Funnelimg from 'assets/png/FilterFunnel.svg';
import SearchIcon from 'assets/png/nsearch.svg';
import UP from 'assets/png/up.svg';
import DOWN from 'assets/png/down.svg';
import PreIcon from 'assets/png/pre.svg';
import CloseIcon from '@mui/icons-material/Close';
import React, { useEffect, useRef, useState } from 'react';
import { To, useNavigate, useSearchParams } from 'react-router-dom';
import { AdminPrivateRoutes } from 'routes/routeConstans';
import Paginate from 'components/Pagination/Pagination';
import NCAdedIcon from 'assets/iconcomponents/noclientsadded';
import { useDebounce } from 'hooks';
import { useAppSelector } from 'store/hooks';
import { MailboxENDPOINT } from 'utils/Constant';
import { getAllListData } from 'services/CommonServices';
import Loader from 'components/loader';
import { MailboxFilterDrawer } from '../MailboxFilter/MailboxFilterDrawer';
import moment from 'moment';
import { AccessDenied, MailBoxType, mailBoxTypeToText } from 'utils/helper';
import { toast } from 'react-toastify';

const Defaultparam = {
  search: null,
  userId: null,
  order: null,
  skip: 0,
  sortby: null,
  status: null,
  take: 50,
  from: null,
  to: null,
  crm: null,
  organizationId: null,
  type: null,
};

export const MailboxList = () => {
  const {
    userInfo: { organizationId: orgdy },
  }: any = useAppSelector((store: any) => store.userLogin);

  const [params, setParams] = useState<Record<string, number | string | null>>(Defaultparam);
  const [dweropen, setdweropen] = useState(false);
  const [pagetotal, setpagetotal] = useState<number>(0);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [isLoading, setLoading] = useState(false);
  const debouncedParams = useDebounce(params, 500);
  const [commonState, setcommonstate] = useState<any[]>([]);

  const containerRef = useRef<HTMLDivElement>(null);

  const dweropn = () => setdweropen(true);
  const dwerclose = () => setdweropen(false);
  const navigate = useNavigate();
  const [searchParams]: any = useSearchParams();
  const gotoNavigate = (link: To) => {
    navigate(link);
  };

  const handleScroll = (scrollAmount: number) => {
    if (!containerRef.current) {
      return;
    }
    // Calculate the new scroll position
    const newScrollPosition = scrollPosition + scrollAmount;
    // Update the state with the new scroll position
    if (newScrollPosition >= 0 && newScrollPosition <= 800) {
      setScrollPosition(newScrollPosition);
      const element = containerRef.current;
      // Access the container element and set its scrollLeft property
      element.scrollLeft = newScrollPosition;
    }
  };

  const sortablecliks: (sor: string, ord: string) => void = (sor: string, ord: string) => {
    setParams((prev: any) => ({
      ...prev,
      sortby: sor ? sor : null,
      order: ord ? ord : null,
    }));
  };

  const handleFilterPag = (filteredobj1: any) => {
    setParams((prev) => ({
      ...prev,
      ...filteredobj1,
    }));
  };

  const handlePag = (sk: number, tk: number) => {
    setParams((prev: any) => ({
      ...prev,
      skip: sk,
      take: tk,
    }));
  };

  const listdataapi = () => {
    const searcharr = [
      `${orgdy ? `organizationId=${orgdy}` : null}`,
      `${debouncedParams.search ? `search=${encodeURIComponent(debouncedParams.search)}` : null}`,
      `${debouncedParams.status ? `approvalStatus=${debouncedParams.status}` : null}`,
      `${debouncedParams.from ? `from=${debouncedParams.from}` : null}`,
      `${debouncedParams.to ? `to=${debouncedParams.to}` : null}`,
      `${debouncedParams.type ? `type=${debouncedParams.type}` : null}`,
      `${debouncedParams.order ? `order=${debouncedParams.order}` : null}`,
      `${debouncedParams.sortby ? `sortby=${debouncedParams.sortby}` : null}`,
      `${
        debouncedParams.skip
          ? `skip=${debouncedParams.skip}`
          : debouncedParams.take
            ? 'skip=0'
            : null
      }`,
      `${debouncedParams.take ? `take=${debouncedParams.take}` : null}`,
    ]
      .filter((ea: any) => ea !== 'null')
      .join('&');
    setLoading(true);
    getAllListData(
      `${MailboxENDPOINT.mailboxList}${Object.values({ ...params }).length > 0 && '?'}${searcharr}`,
      true,
    )
      .then((val: any) => {
        if (val && val.data && val.data.length > 0) {
          setcommonstate([...val.data]);
          setpagetotal(Number(val.total));
        } else {
          setcommonstate([]);
        }
        setLoading(!true);
      })
      .catch((e) => {
        setLoading(!true);
        if (e === 'Forbidden resource') {
          toast.error(AccessDenied);
          navigate('/dashboard');
        }
      });
  };

  useEffect(() => {
    listdataapi();
  }, [debouncedParams]);
  return (
    <Grid container>
      {isLoading && <Loader />}
      <Grid item md={12} sm={12} xs={12}>
        <Typography component={'div'} className='breadcrumbs d-flex-a'>
          <Breadcrumbs aria-label='breadcrumb'>
            <Link underline='hover' href='/'>
              <img src={HomeIcon} className='home' alt='Home' />
            </Link>
            <Typography
              color='text.primary'
              sx={{ cursor: 'pointer' }}
              onClick={() => gotoNavigate(AdminPrivateRoutes.MAILBOX.MAILBOXLIST)}>
              {' '}
              <img src={ArrowIcon} alt='arrow' className='arrows' /> Mail Box
            </Typography>
          </Breadcrumbs>
        </Typography>

        <Typography
          component={'div'}
          className='d-flex-a flex-sb title-add-box'
          sx={{ paddingRight: '56px' }}>
          <Typography className='page-title'>Mail Box</Typography>
          <Typography
            component={'div'}
            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Typography
              component={'div'}
              sx={{ marginRight: '24px', cursor: 'pointer' }}
              onClick={() =>
                gotoNavigate(
                  `${AdminPrivateRoutes.RESUMES.RESUMESEDIT}?id=${searchParams.get('id')}`,
                )
              }></Typography>
          </Typography>
        </Typography>

        <Typography component={'div'} className='main-content'>
          <Typography component={'div'} className='white-box'>
            <Typography component={'div'} className='d-flex-cc flex-sb'>
              <TextField
                className='custom-search'
                placeholder='Search'
                id='outlined-start-adornment'
                value={params.search}
                sx={{ width: '320px' }}
                onChange={(e: any) =>
                  setParams((prev: any) => ({
                    ...prev,
                    search: e.target.value,
                    skip: 0,
                    take: 50,
                  }))
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <img src={SearchIcon} alt='search' />
                    </InputAdornment>
                  ),
                  endAdornment: params.search ? (
                    <InputAdornment position='end'>
                      <IconButton
                        aria-label='toggle password visibility'
                        edge='end'
                        onClick={() => {
                          setParams(() => ({ ...Defaultparam, search: '' }));
                        }}>
                        <CloseIcon style={{ height: '20px', width: '20px' }} />
                      </IconButton>
                    </InputAdornment>
                  ) : null,
                }}
              />
              <Typography
                component={'div'}
                className='d-flex-cc flex-sb d-flex-nwrp filterfunnel-con'
                onClick={dweropn}>
                <img src={Funnelimg} alt='filter funnel icon' />
                <span className='filterfunnel'>More Filters</span>
              </Typography>
            </Typography>
            <Typography
              component={'div'}
              className='mt-32'
              sx={{ position: 'relative', width: '100%' }}>
              <Button onClick={() => handleScroll(-200)} className='pre-btn'>
                <img src={PreIcon} alt='' />
              </Button>
              <Button onClick={() => handleScroll(200)} className='next-btn'>
                <img src={PreIcon} alt='' style={{ transform: 'rotate(180deg)' }} />
              </Button>
              <TableContainer component={Paper} className='sb-table2' ref={containerRef}>
                <Table
                  sx={{ overflowX: 'auto', minWidth: 650 }}
                  stickyHeader
                  className='custom-table custom-table-client'
                  aria-label='sticky Header'>
                  <TableHead>
                    <TableRow
                      sx={{
                        marginLeft: '50px',
                        marginRight: '43px',

                        position: 'relative',
                      }}>
                      <TableCell sx={{ whiteSpace: 'nowrap' }}>
                        To
                        <span className='sorting'>
                          <img
                            src={UP}
                            alt='asc'
                            className='up'
                            onClick={() => sortablecliks('mailTo', 'asc')}
                          />
                          <img
                            src={DOWN}
                            alt='desc'
                            className='down'
                            onClick={() => sortablecliks('mailTo', 'desc')}
                          />
                        </span>
                      </TableCell>
                      <TableCell sx={{ whiteSpace: 'nowrap' }}>
                        Subject
                        {/* <span className='sorting'>
                          <img
                            src={UP}
                            alt='asc'
                            className='up'
                            onClick={() => sortablecliks('position', 'asc')}
                          />
                          <img
                            src={DOWN}
                            alt='desc'
                            className='down'
                            onClick={() => sortablecliks('position', 'desc')}
                          />
                        </span> */}
                      </TableCell>
                      <TableCell sx={{ whiteSpace: 'nowrap' }}>
                        Message
                        {/* <span className='sorting'>
                          <img
                            src={UP}
                            alt='asc'
                            className='up'
                            onClick={() => sortablecliks('company', 'asc')}
                          />
                          <img
                            src={DOWN}
                            alt='desc'
                            className='down'
                            onClick={() => sortablecliks('company', 'desc')}
                          />
                        </span> */}
                      </TableCell>
                      <TableCell sx={{ whiteSpace: 'nowrap' }}>
                        Type
                        <span className='sorting'>
                          <img
                            src={UP}
                            alt='asc'
                            className='up'
                            onClick={() => sortablecliks('type', 'asc')}
                          />
                          <img
                            src={DOWN}
                            alt='desc'
                            className='down'
                            onClick={() => sortablecliks('type', 'desc')}
                          />
                        </span>
                      </TableCell>
                      <TableCell sx={{ whiteSpace: 'nowrap' }}>
                        Sent Date
                        <span className='sorting'>
                          <img
                            src={UP}
                            alt='asc'
                            className='up'
                            onClick={() => sortablecliks('createdAt', 'asc')}
                          />
                          <img
                            src={DOWN}
                            alt='desc'
                            className='down'
                            onClick={() => sortablecliks('createdAt', 'desc')}
                          />
                        </span>
                      </TableCell>
                      <TableCell sx={{ whiteSpace: 'nowrap' }}>
                        Sent By
                        <span className='sorting'>
                          <img
                            src={UP}
                            alt='asc'
                            className='up'
                            onClick={() => sortablecliks('interviewDate', 'asc')}
                          />
                          <img
                            src={DOWN}
                            alt='desc'
                            className='down'
                            onClick={() => sortablecliks('interviewDate', 'desc')}
                          />
                        </span>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {commonState.length ? (
                      commonState.map((row) => {
                        const to = row?.mailTo ?? '-';
                        const subject = row?.subject ?? '-';
                        const content = row?.content.slice(0, 50).concat('...') ?? '-';
                        const type = row?.type ?? '-';
                        const sentDate = moment(row?.createdAt).format('DD-MM-YYYY') ?? '-';
                        const sentBy = `${row?.user?.firstName ?? ' '} ${
                          row?.user?.lastName ?? ' '
                        }`;

                        return (
                          <TableRow key={row.id}>
                            <TableCell sx={{ whiteSpace: 'wrap' }}>
                              <Typography
                                sx={{ fontSize: '12px', maxWidth: '200px' }}
                                component={'p'}>
                                {to}
                              </Typography>
                            </TableCell>
                            <TableCell sx={{ whiteSpace: 'wrap' }}>
                              <Typography
                                sx={{ fontSize: '12px', maxWidth: '200px' }}
                                component={'p'}>
                                {subject}
                              </Typography>
                            </TableCell>
                            <TableCell sx={{ whiteSpace: 'nowrap' }}>
                              <div
                                style={{
                                  cursor: 'pointer',
                                  color: '#5F2EE5',
                                  fontSize: '12px',
                                  maxWidth: '200px',
                                }}
                                onClick={() =>
                                  gotoNavigate(
                                    `${AdminPrivateRoutes.MAILBOX.MAILBOXVIEW}?id=${row.id}`,
                                  )
                                }
                                dangerouslySetInnerHTML={{ __html: content }}></div>
                            </TableCell>
                            <TableCell sx={{ whiteSpace: 'nowrap' }}>
                              <Typography sx={{ fontSize: '12px' }} component={'p'}>
                                {mailBoxTypeToText[type as MailBoxType] || '-'}
                              </Typography>
                            </TableCell>
                            <TableCell sx={{ whiteSpace: 'nowrap' }}>
                              <Typography sx={{ fontSize: '12px' }} component={'p'}>
                                {sentDate}
                              </Typography>
                            </TableCell>
                            <TableCell sx={{ whiteSpace: 'nowrap' }}>
                              <Typography sx={{ fontSize: '12px' }} component={'p'}>
                                {sentBy}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan={14} align={'center'}>
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'center',
                              alignItems: 'center',
                              marginBottom: '43px',
                            }}>
                            <NCAdedIcon />
                            <Typography className='no-client'>Mail items not found</Typography>
                          </div>
                        </td>
                      </tr>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Typography>
            <Paginate total={pagetotal} setfn={handlePag} DefaultPerPage={50} />
          </Typography>
        </Typography>
      </Grid>
      <MailboxFilterDrawer
        setParams={setParams}
        isOpen={dweropen}
        closeDrawer={dwerclose}
        callback={handleFilterPag}
        listdataapi={listdataapi}
        setcommonstate={setcommonstate}
        setLoading={setLoading}
        setpagetotal={setpagetotal}
      />
    </Grid>
  );
};
