import { Grid, TextField, Typography } from '@mui/material';
import CustomSelect from 'components/CustomSelect/customSelect';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import {
  Control,
  Controller,
  FormState,
  UseFormHandleSubmit,
  UseFormTrigger,
  UseFormWatch,
} from 'react-hook-form';
import { getAllListData } from 'services/CommonServices';
import { useAppSelector } from 'store/hooks';
import { leaveFilterType } from 'types';
import { SETTING_ENDPOINT } from 'utils/Constant';

interface LeaveFilterFormProps {
  control: Control<leaveFilterType, any>;
  handleSubmit: UseFormHandleSubmit<leaveFilterType, undefined>;
  formState: FormState<leaveFilterType>;
  onSubmit: (data: leaveFilterType) => void;
  watch: UseFormWatch<leaveFilterType>;
  trigger: UseFormTrigger<leaveFilterType>;
  setParams: any;
}

const LeaveFilterForm: React.FC<LeaveFilterFormProps> = ({
  control,
  handleSubmit,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  formState: { errors },
  onSubmit,
  // trigger,
  watch,
  // setParams,
}) => {
  const {
    userInfo: { organizationId: orgdy },
  }: any = useAppSelector((store: any) => store.userLogin);
  // const currentDate = moment().format('YYYY-MM-DD');
  const [interviewFromDate, setInterviewFromDate] = useState<string | null>(null);
  // const [interviewToDate, setInterviewToDate] = useState<string | null>(null);
  const [leaveTypeData, setLeaveTypeData] = useState<any>([]);

  useEffect(() => {
    getAllListData(
      `${SETTING_ENDPOINT.leaves.leavePolicy}/type?organizationId=${orgdy}&status=true`,
      true,
    )
      .then((res) => {
        const options: any = [];
        res.data.map((e: any) => {
          options.push({ label: e.type, value: e.id });
        });
        setLeaveTypeData(options);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  return (
    <form className='private-form clientfilter-form' onSubmit={handleSubmit(onSubmit)}>
      <Grid container>
        <Grid item md={12} sm={12} xs={12} sx={{ width: '100%' }}>
          <Grid container sx={{ width: '100%' }}>
            <Grid item md={12} sm={12} xs={12}>
              <Typography component={'div'} className='custom-field'>
                <Typography component={'p'}>From Date</Typography>
                <Controller
                  control={control}
                  defaultValue={undefined}
                  name='fromDate'
                  render={({ field: { onChange, value } }) => {
                    return (
                      <TextField
                        id='date'
                        type='date'
                        className='clientfilter-time-from-to'
                        value={value ? value : null}
                        onChange={(e) => {
                          setInterviewFromDate(moment(e.target.value).format('YYYY-MM-DD'));
                          onChange(e);
                        }}
                        // className={classes.textField}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        // InputProps={{
                        //   inputProps: { max: interviewToDate ?? currentDate },
                        // }}
                      />
                    );
                  }}
                />
              </Typography>
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <Typography component={'div'} className='custom-field'>
                <Typography component={'p'}>To Date</Typography>
                <Controller
                  control={control}
                  defaultValue={undefined}
                  name='toDate'
                  render={({ field: { onChange, value } }) => {
                    return (
                      <TextField
                        id='date'
                        type='date'
                        className='clientfilter-time-from-to'
                        value={value ? value : null}
                        onChange={(e) => {
                          // setInterviewToDate(moment(e.target.value).format('YYYY-MM-DD'));
                          onChange(e);
                        }}
                        // className={classes.textField}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        InputProps={{
                          inputProps: { min: interviewFromDate },
                        }}
                      />
                    );
                  }}
                />
              </Typography>
            </Grid>

            <Grid item md={12} sm={12} xs={12}>
              <Typography component={'div'} className='custom-field'>
                <Typography component={'p'}>Leave Type</Typography>
                <Controller
                  control={control}
                  name='leaveType'
                  defaultValue={undefined}
                  render={({ field: { onChange, value, name } }) => {
                    return (
                      <CustomSelect
                        labelKey={'label'}
                        valueKey={'value'}
                        isClearable={false}
                        options={leaveTypeData}
                        name={name}
                        value={value ? value : null}
                        onChange={(e) => {
                          onChange(e);
                        }}
                        placeHolder='Select Leave type'
                      />
                    );
                  }}
                />
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <button
          type='submit'
          className='clientfilter-form-btn'
          style={{
            ...(!(watch('fromDate') || watch('toDate') || watch('leaveType'))
              ? { opacity: '0.5', cursor: 'not-allowed' }
              : {}),
          }}>
          Apply
        </button>
      </Grid>
    </form>
  );
};

export default LeaveFilterForm;
