import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Grid, Typography } from '@mui/material';
import CloseIcon from 'assets/png/close.svg';
import CustomInput from 'components/CustomInput/customInput';
import Loader from 'components/loader';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { addNewData } from 'services/CommonServices';
import { useAppSelector } from 'store/hooks';
import { SETTING_ENDPOINT } from 'utils/Constant';
import { designationAddSchema } from 'utils/ValidatorSchema';

export const DesignationAdd = ({ handleDesignationClose, designationList, setValue }: any) => {
  const {
    userInfo: { organizationId: orgdy },
  }: any = useAppSelector((store: any) => store.userLogin);
  const [loader, setLoader] = useState<any>(false);
  const {
    control: control,
    handleSubmit: handleSubmit,
    reset: resetForm,
    formState: { errors },
  } = useForm<any>({
    criteriaMode: 'all',
    defaultValues: {
      designation: '',
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(designationAddSchema),
  });
  const onSubmitForm = (data: any) => {
    setLoader(true);
    addNewData(
      {
        organizationId: orgdy ? orgdy : '',
        name: data.designation,
        type: 'candidate',
        status: true,
      },
      `${String(SETTING_ENDPOINT.designation)}`,
      undefined,
      true,
    )
      .then(() => {
        resetForm();
        handleDesignationClose();
        designationList();
        setValue('canditateCurrentDesignation', '');
        setLoader(false);
      })
      .catch(() => {
        setLoader(false);
      });
  };
  return (
    <Box className='logout-modal'>
      {loader && <Loader />}
      <Typography className='text-right'>
        <img src={CloseIcon} alt='close' onClick={handleDesignationClose} />
      </Typography>
      <Typography className='l-title'>Add New Designation!</Typography>
      <form className='private-form' id={'des-form'} onSubmit={handleSubmit(onSubmitForm)}>
        <Grid item md={12} sm={12} xs={12} sx={{ padding: '20px 100px 16px 100px' }}>
          <Typography component={'div'} className='custom-field'>
            <Typography component={'p'} sx={{ marginRight: '10px' }}>
              New Designation <span>*</span>
            </Typography>
            <Controller
              control={control}
              defaultValue={null}
              name='designation'
              render={({ field: { onChange, value, name } }) => {
                return (
                  <CustomInput
                    value={value}
                    name={name}
                    className='custom-input'
                    placeHolder='New Designation'
                    error={errors.designation?.message}
                    onChange={(e) => {
                      onChange(e);
                    }}
                  />
                );
              }}
            />
          </Typography>
        </Grid>
      </form>
      <Typography className='d-flex-ja mb-24'>
        <Button className='l-cancel-btn mr-16' onClick={handleDesignationClose}>
          Cancel
        </Button>
        <Button className='l-save-btn' type='submit' form={'des-form'}>
          save
        </Button>
      </Typography>
    </Box>
  );
};
