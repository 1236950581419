import React, { useEffect, useState, useRef } from 'react';
import {
  Breadcrumbs,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  // Pagination,
  // PaginationItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import HomeIcon from 'assets/png/dashboardactive.svg';
import ArrowIcon from 'assets/png/breadarrow.svg';
import PreIcon from 'assets/png/pre.svg';
import EditIcon from 'assets/png/edit.svg';
import AddIcon from 'assets/png/addplus.svg';
import SearchIcon from 'assets/png/nsearch.svg';
// import ArrowBackIcon from '@mui/icons-material/ArrowBack';
// import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useNavigate } from 'react-router-dom';
import { deleteData, getAllListData, patchData } from 'services/CommonServices';
import { SETTING_ENDPOINT } from 'utils/Constant';
import { AdminPrivateRoutes } from 'routes/routeConstans';
import ConfirmDialog from 'components/ConfirmDialog/ConfirmDialog';
import { useDebounce } from 'hooks';
import moment from 'moment';
import UP from 'assets/png/up.svg';
import DOWN from 'assets/png/down.svg';
import { useAppSelector } from 'store/hooks';
import Paginate from 'components/Pagination/Pagination';
import Loader from 'components/loader';
import ExcelIcon from 'assets/iconcomponents/excelicon';
import ImageWithAuth from 'components/ProfileImage';
import TableTooltip from 'components/Tooltip/Tabletooltip';
import { toast } from 'react-toastify';
import { AccessDenied } from 'utils/helper';
// import { isNil } from 'lodash';

interface Statetype {
  search?: string | null;
  order?: string | null;
  skip?: number | null;
  sortby?: string | null;
  status?: string | null;
  take?: number | null;
  name?: string | null;
  email?: string | null;
  roles?: any;
}

const Defaultparam = {
  search: null,
  skip: 0,
  order: 'asc',
  sortby: 'name',
  status: null,
  take: 50,
  name: null,
  email: null,
  roles: Array(14)
    .fill(1)
    .map((el: number | any, id: any) => Number(el * id) + 1),
};

const users = () => {
  const {
    userInfo: { organizationId: orgdy },
  }: any = useAppSelector((store: any) => store.userLogin);
  const navigate = useNavigate();
  const [usersGetData, setUsersGetData] = useState<any>([]);
  const [pagetotal, setpagetotal] = useState<number>(0);
  const [loader, setLoader] = useState<any>(false);
  const [confirmOpen, setConfirmOpen] = useState<any>({
    show: false,
    id: '',
  });
  const [params, setParams] = useState<Statetype>({ ...Defaultparam });
  const debouncedParams = useDebounce(params, 500);
  const getList = () => {
    setLoader(true);
    const searcharr = [
      `${orgdy ? `organizationId=${orgdy}` : null}`,
      `${debouncedParams.roles ? `roles=${String(debouncedParams.roles.toString())}` : null}`,
      `${debouncedParams.search ? `search=${encodeURIComponent(debouncedParams.search)}` : null}`,
      `${debouncedParams.name ? `name=${debouncedParams.name}` : null}`,
      `${debouncedParams.email ? `search=${debouncedParams.email}` : null}`,
      `${debouncedParams.status ? `status=${debouncedParams.status}` : null}`,
      `${debouncedParams.order ? `order=${debouncedParams.order}` : null}`,
      `${debouncedParams.sortby ? `sortby=${debouncedParams.sortby}` : null}`,
      `${
        debouncedParams.skip
          ? `skip=${debouncedParams.skip}`
          : debouncedParams.take
            ? 'skip=0'
            : null
      }`,
      `${debouncedParams.take ? `take=${debouncedParams.take}` : null}`,
    ]
      .filter((ea: any) => ea !== 'null')
      .join('&');

    getAllListData(
      `${SETTING_ENDPOINT.users}${
        Object.values({ ...params, organizationId: orgdy }).length > 0 && '?'
      }${searcharr}`,
      true,
    )
      .then((res) => {
        setpagetotal(Number(res.total));
        setUsersGetData(res && res.data);
        setLoader(false);
      })
      .catch((e) => {
        if (e === 'Forbidden resource') {
          toast.error(AccessDenied);
          navigate('/dashboard');
        }
        // console.log(e);
        setLoader(false);
      });
  };
  const deleteUsers = (id: any) => {
    setLoader(true);
    deleteData(id, `${SETTING_ENDPOINT.users}`, true)
      .then(() => {
        getList();
        setConfirmOpen((pre: any) => ({
          ...pre,
          show: false,
        }));
        setLoader(false);
      })
      .catch(() => {
        // console.log(err);
        setLoader(false);
      });
  };
  const [scrollPosition, setScrollPosition] = useState(0);
  const containerRef = useRef(null) as any;
  // Function to handle scrolling when the button is clicked
  const handleScroll = (scrollAmount: number) => {
    // Calculate the new scroll position
    const newScrollPosition = scrollPosition + scrollAmount;
    // Update the state with the new scroll position
    if (newScrollPosition >= 0 && newScrollPosition <= 800) {
      setScrollPosition(newScrollPosition);
      const element = containerRef.current;
      // Access the container element and set its scrollLeft property
      element.scrollLeft = newScrollPosition;
    }
  };
  const sortablecliks: (sor: string, ord: string) => void = (sor: string, ord: string) => {
    setParams((prev: any) => ({
      ...prev,
      sortby: sor ? sor : null,
      order: ord ? ord : null,
    }));
  };
  const handlePag = (sk: number, tk: number) => {
    setParams((prev: any) => ({
      ...prev,
      skip: sk,
      take: tk,
    }));
  };

  const reInvite = async (id: string) => {
    setLoader(true);
    await patchData(id, {}, `${SETTING_ENDPOINT.users}/invite`, true)
      .then(() => setLoader(false))
      .catch(() => setLoader(false));
  };

  useEffect(() => {
    getList();
  }, [debouncedParams]);

  return (
    <Grid container>
      {loader && <Loader />}
      <Grid item md={12} sm={12} xs={12}>
        <Typography component={'div'} className='breadcrumbs d-flex-a'>
          <Breadcrumbs aria-label='breadcrumb'>
            <Link underline='hover' color='inherit' href='/'>
              <img src={HomeIcon} className='home' alt='Home' />
            </Link>
            <Link underline='hover' color='inherit' href=''>
              <img src={ArrowIcon} alt='arrow' className='arrows' />
              Settings
            </Link>
            <Typography color='text.primary'>
              {' '}
              <img src={ArrowIcon} alt='arrow' className='arrows' /> Users
            </Typography>
          </Breadcrumbs>
        </Typography>

        <Typography component={'div'} className='d-flex-a flex-sb title-add-box'>
          <Typography className='page-title'>Users</Typography>
          <Typography component={'div'} className='d-flex-a'>
            <div className='expot-icon'>
              <span>
                <ExcelIcon
                  url={
                    'users?type=export&roles=1,2,3,4,5,6,7,8,9,10,11,12,13,14&order=asc&sortby=name'
                  }
                  fileName={'Users'}
                  setLoader={setLoader}
                />
              </span>
            </div>
            <Button
              className='add-btn'
              onClick={() => navigate(AdminPrivateRoutes.SETTINGS.USERS.USERSADD)}>
              <img src={AddIcon} alt='plus' /> Add User
            </Button>
          </Typography>
        </Typography>
        <Typography component={'div'} className='main-content'>
          <Typography component={'div'} className='white-box'>
            <TextField
              className='custom-search'
              placeholder='Search'
              id='outlined-start-adornment'
              value={params.search}
              sx={{ width: '320px' }}
              onChange={(e: any) =>
                setParams((prev: any) => ({
                  ...prev,
                  search: e.target.value,
                  skip: 0,
                  take: 50,
                }))
              }
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <img src={SearchIcon} alt='search' />
                  </InputAdornment>
                ),
                endAdornment: params.search ? (
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='toggle password visibility'
                      edge='end'
                      onClick={() => {
                        setParams(() => ({ ...Defaultparam, search: '' }));
                      }}>
                      <CloseIcon style={{ height: '20px', width: '20px' }} />
                    </IconButton>
                  </InputAdornment>
                ) : null,
              }}
            />
            <Typography component={'div'} className='mt-32' sx={{ position: 'relative' }}>
              <Button onClick={() => handleScroll(-200)} className='pre-btn'>
                <img src={PreIcon} alt='' />
              </Button>
              <Button onClick={() => handleScroll(200)} className='next-btn'>
                <img src={PreIcon} alt='' style={{ transform: 'rotate(180deg)' }} />
              </Button>
              <TableContainer component={Paper} className='sb-table' ref={containerRef}>
                <Table
                  sx={{ minWidth: '1600px' }}
                  className='custom-table'
                  aria-label='simple table'>
                  <TableHead>
                    <TableRow>
                      <TableCell align='center' className='w250'>
                        User{' '}
                        <span className='sorting'>
                          <img
                            src={UP}
                            alt='asc'
                            className='up'
                            onClick={() => sortablecliks('name', 'asc')}
                          />
                          <img
                            src={DOWN}
                            alt='desc'
                            className='down'
                            onClick={() => sortablecliks('name', 'desc')}
                          />
                        </span>
                      </TableCell>
                      <TableCell className='w200'>
                        User Role{' '}
                        <span className='sorting'>
                          <img
                            src={UP}
                            alt='asc'
                            className='up'
                            onClick={() => sortablecliks('roleName', 'asc')}
                          />
                          <img
                            src={DOWN}
                            alt='desc'
                            className='down'
                            onClick={() => sortablecliks('roleName', 'desc')}
                          />
                        </span>
                      </TableCell>
                      <TableCell className='w200'>
                        Designation{' '}
                        <span className='sorting'>
                          <img
                            src={UP}
                            alt='asc'
                            className='up'
                            onClick={() => sortablecliks('designation', 'asc')}
                          />
                          <img
                            src={DOWN}
                            alt='desc'
                            className='down'
                            onClick={() => sortablecliks('designation', 'desc')}
                          />
                        </span>
                      </TableCell>
                      <TableCell className='w270'>
                        Email{' '}
                        <span className='sorting'>
                          <img
                            src={UP}
                            alt='asc'
                            className='up'
                            onClick={() => sortablecliks('email', 'asc')}
                          />
                          <img
                            src={DOWN}
                            alt='desc'
                            className='down'
                            onClick={() => sortablecliks('email', 'desc')}
                          />
                        </span>
                      </TableCell>
                      <TableCell className='w250'>
                        Date / Time{' '}
                        <span className='sorting'>
                          <img
                            src={UP}
                            alt='asc'
                            className='up'
                            onClick={() => sortablecliks('createdAt', 'asc')}
                          />
                          <img
                            src={DOWN}
                            alt='desc'
                            className='down'
                            onClick={() => sortablecliks('createdAt', 'desc')}
                          />
                        </span>
                      </TableCell>
                      <TableCell className='w250'>
                        Password Created{' '}
                        {/* <span className='sorting'>
                          <img
                            src={UP}
                            alt='asc'
                            className='up'
                            // onClick={() => sortablecliks('ip', 'asc')}
                          />
                          <img
                            src={DOWN}
                            alt='desc'
                            className='down'
                            // onClick={() => sortablecliks('ip', 'desc')}
                          />
                        </span> */}
                      </TableCell>

                      <TableCell className='w250'>
                        OTP Triggered
                        {/* <span className='sorting'>
                          <img
                            src={UP}
                            alt='asc'
                            className='up'
                            // onClick={() => sortablecliks('ip', 'asc')}
                          />
                          <img
                            src={DOWN}
                            alt='desc'
                            className='down'
                            // onClick={() => sortablecliks('ip', 'desc')}
                          />
                        </span> */}
                      </TableCell>
                      <TableCell className='w250'>
                        Last OTP Generated{' '}
                        {/* <span className='sorting'>
                          <img
                            src={UP}
                            alt='asc'
                            className='up'
                            // onClick={() => sortablecliks('ip', 'asc')}
                          />
                          <img
                            src={DOWN}
                            alt='desc'
                            className='down'
                            // onClick={() => sortablecliks('ip', 'desc')}
                          />
                        </span> */}
                      </TableCell>
                      {/* <TableCell className='w250'>
                        Resent OTP{' '}
                        <span className='sorting'>
                          <img
                            src={UP}
                            alt='asc'
                            className='up'
                            // onClick={() => sortablecliks('ip', 'asc')}
                          />
                          <img
                            src={DOWN}
                            alt='desc'
                            className='down'
                            // onClick={() => sortablecliks('ip', 'desc')}
                          />
                        </span>
                      </TableCell> */}
                      <TableCell className='w250'>
                        Invite Link
                        {/* <span className='sorting'>
                          <img
                            src={UP}
                            alt='asc'
                            className='up'
                            // onClick={() => sortablecliks('ip', 'asc')}
                          />
                          <img
                            src={DOWN}
                            alt='desc'
                            className='down'
                            // onClick={() => sortablecliks('ip', 'desc')}
                          />
                        </span> */}
                      </TableCell>
                      <TableCell className='w270' align='center'>
                        Resend Password{' '}
                      </TableCell>
                      <TableCell className='w150'>
                        Status
                        <span className='sorting'>
                          <img
                            src={UP}
                            alt='asc'
                            className='up'
                            onClick={() => sortablecliks('status', 'desc')}
                          />
                          <img
                            src={DOWN}
                            alt='desc'
                            className='down'
                            onClick={() => sortablecliks('status', 'asc')}
                          />
                        </span>
                      </TableCell>
                      <TableCell className='w150'>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {usersGetData && usersGetData.length ? (
                      usersGetData.map((row: any) => (
                        <TableRow
                          key={row.name}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                          <TableCell
                            sx={
                              {
                                // minWidth: '200px',
                                // textTransform: 'capitalize',
                                // display: 'flex',
                                // gap: '16px',
                                // alignItems: 'center',
                              }
                            }>
                            <div
                              style={{
                                minWidth: '150px',
                                display: 'flex',
                                textTransform: 'capitalize',
                                gap: '16px',
                                alignItems: 'center',
                              }}>
                              {row && row.imageId ? (
                                <Typography className='ip-img'>
                                  <ImageWithAuth className='default-ippic' id={row.imageId} />
                                </Typography>
                              ) : (
                                <Typography className='ip-img'>
                                  <Typography className='default-iptext'>
                                    {row.firstName.toUpperCase().trim().split('')[0] +
                                      row.lastName.toUpperCase().trim().split('')[0]}
                                  </Typography>
                                </Typography>
                              )}
                              <TableTooltip
                                value={
                                  row.firstName ? `${row.firstName} ${row.lastName}` : '-'
                                }></TableTooltip>
                            </div>
                          </TableCell>
                          <TableCell sx={{ textTransform: 'capitalize', minWidth: '100px' }}>
                            <TableTooltip value={row.roleName ? row.roleName : '-'} />
                          </TableCell>
                          <TableCell sx={{ textTransform: 'capitalize', minWidth: '100px' }}>
                            {row.designation ? row.designation : '-'}
                          </TableCell>
                          <TableCell sx={{ textTransform: 'capitalize', minWidth: '100px' }}>
                            <TableTooltip value={row.email ? row.email : '-'} />
                          </TableCell>
                          <TableCell sx={{ minWidth: '250px' }}>
                            {row && row.createdAt
                              ? moment(row.createdAt).format('YYYY MMM Do | hh:mm:ss A')
                              : ' '}
                          </TableCell>
                          <TableCell
                            align='center'
                            sx={{ minWidth: '100px', textTransform: 'capitalize' }}>
                            {row.passwordCreated ? row.passwordCreated : '-'}
                          </TableCell>
                          <TableCell
                            align='center'
                            sx={{ minWidth: '100px', textTransform: 'capitalize' }}>
                            {row?.otpData ? 'Yes' : 'No'}
                          </TableCell>
                          <TableCell align='center' sx={{ minWidth: '100px' }}>
                            {row.otpData && row.otpData.otp ? row.otpData.otp : '-'}
                          </TableCell>
                          <TableCell sx={{ minWidth: '100px' }}>
                            {row.invitelink ? (
                              <a href={row.invitelink}>
                                <TableTooltip value={row.invitelink ? row.invitelink : '-'} />
                              </a>
                            ) : (
                              '-'
                            )}
                          </TableCell>
                          <TableCell
                            sx={{ minWidth: '200px', display: 'flex', justifyContent: 'center' }}
                            align='center'>
                            <Typography
                              className={'dip-button'}
                              onClick={() => (row.passwordCreated === 'No' ? reInvite(row.id) : '')}
                              sx={{
                                opacity: row.passwordCreated !== 'No' ? '0.5' : '',
                                cursor:
                                  row.passwordCreated === 'No'
                                    ? 'pointer !important '
                                    : 'not-allowed !important',
                              }}>
                              Re-Invite
                            </Typography>
                          </TableCell>
                          <TableCell sx={{ minWidth: '100px' }}>
                            <Typography
                              className={row && row.status ? 'active-badge' : 'inactive-badge'}>
                              {row.status === true ? 'Active' : 'Inactive'}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <img
                              src={EditIcon}
                              alt='Edit'
                              onClick={() =>
                                navigate(`${AdminPrivateRoutes.SETTINGS.USERS.USERSEDIT}/${row.id}`)
                              }
                            />
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <tr className={'text-center'}>
                        <td colSpan={12} align={'center'}>
                          No Record(s) Found.
                        </td>
                      </tr>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Typography>
            <Grid container className='mt-24'>
              <Grid item md={12} sm={12} xs={12}>
                <Typography component={'div'} className='d-flex-a flex-end pagination'>
                  <Paginate total={pagetotal} setfn={handlePag} DefaultPerPage={50} />
                </Typography>
              </Grid>
            </Grid>
          </Typography>
        </Typography>
      </Grid>
      <ConfirmDialog
        title='Confirm'
        floatingBtnShow={true}
        open={confirmOpen.show}
        close={() =>
          setConfirmOpen({
            show: false,
          })
        }
        visibleClose={true}
        onConfirm={(act: any) =>
          act === 'yes'
            ? deleteUsers(confirmOpen.id)
            : setConfirmOpen({
                show: false,
              })
        }
        autoCloseDisable={true}>
        {'Are you sure do you want to delete?'}
      </ConfirmDialog>
    </Grid>
  );
};

export default users;
