/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';
import {
  Box,
  Button,
  Grid,
  FormControlLabel,
  RadioGroup,
  Radio,
  InputAdornment,
  NativeSelect,
  Typography,
  TextField,
  Checkbox,
  styled,
  Modal,
} from '@mui/material';
import CustomSelect from 'components/CustomSelect/customSelect';
import CustomInput from 'components/CustomInput/customInput';
import CustomPhoneInput from 'components/CustomPhoneInput/customPhoneInput';
import EmailIcon from 'assets/png/email.svg';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { resumePersonalSchema } from 'utils/ValidatorSchema';
import { experienceYear, experienceMonth, noticePeriod } from 'utils/Config';
import Loader from 'components/loader';
import { addNewData, getAllListData, getSingleData, updateData } from 'services/CommonServices';
import { ResumesENDPOINT, SETTING_ENDPOINT } from 'utils/Constant';
import { useNavigate, useSearchParams } from 'react-router-dom';
import _ from 'lodash';
import ResumeDuplication from '../ResumeDuplication';
import { HttpStatusCode } from 'axios';
import moment from 'moment';
import { boolean } from 'yup';
import { useAppSelector } from 'store/hooks';
import { DesignationAdd } from './designationAdd';

const CheckIcon = styled('span')(({ theme }) => ({
  borderRadius: 4,
  width: 16,
  height: 16,
  outline: '1px solid #EE4B22',
  backgroundColor: theme.palette.mode === 'dark' ? '#fff' : '#fff',
  '.Mui-focusVisible &': {
    outline: '1px auto #EE4B22',
    outlineOffset: 1,
  },
  'input:hover ~ &': {
    backgroundColor: theme.palette.mode === 'dark' ? '#ffffff' : '#ffffff',
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background: theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
  },
}));

const CheckedIcon = styled(CheckIcon)({
  backgroundColor: '#EE4B22',
  '&:before': {
    display: 'block',
    width: 16,
    height: 16,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: '#EE4B22',
  },
});

type defaultprops = {
  positionId: string;
  orgId: string;
  clientId: string;
  resumeIds: string;
  step: number;
  setStep: (val: number) => void;
  loader: boolean;
  setLoader: (val: boolean) => void;
  position?: string;
  positionData?: any;
  resumeData: (val: number) => void;
};
const ResumePersonal: React.FC<defaultprops> = ({
  positionId,
  clientId,
  orgId,
  resumeIds,
  step,
  setStep,
  loader,
  setLoader,
  position,
  positionData,
  resumeData,
}) => {
  const {
    userInfo: { organizationId: orgdy },
  }: any = useAppSelector((store: any) => store.userLogin);
  const [designationModelopen, setDesignationModelOpen] = useState(false);
  const navigate = useNavigate();
  const now = new Date();
  const [designation, setDesignation] = useState<any>();
  const [draftCheck, setDraftCheck] = useState<boolean>();
  const currentDate = moment().subtract(18, 'years').format('YYYY-MM-DD');
  const [searchParams]: any = useSearchParams();
  const [personalId, setPersonalId] = useState('');
  const [resumeId, setResumeId] = useState('');
  const [open, setOpen] = useState(false);
  const [isIgnore, setIsIgnore] = useState(false);
  const [duplication, setDuplication] = useState<{
    data: any;
    message: string;
    duplicationStatus: string;
  }>({
    data: {},
    message: 'Oops! The resume is already uploaded by someone',
    duplicationStatus: '',
  });
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setDuplication({ data: {}, message: '', duplicationStatus: '' });
  };

  const handleDesignationOpen = () => {
    setDesignationModelOpen(true);
  };
  const handleDesignationClose = () => {
    setValue('canditateCurrentDesignation', '');
    setDesignationModelOpen(false);
  };
  const {
    control: control,
    handleSubmit: handleSubmit,
    reset: resetForm,
    setValue,
    // setError,
    trigger,
    watch,
    // watch,
    getValues,
    formState: { errors },
  } = useForm<any>({
    criteriaMode: 'all',
    defaultValues: {
      positionFor: '',
      canditateFirstName: '',
      canditateLastName: '',
      canditateEmail: '',
      countryCodeText: '',
      countryCode: '',
      canditatePhoneNo: '',
      formatteValue: '',
      canditateDob: '',
      canditateGender: 'male',
      canditateMaritalStatus: 'single',
      canditateCurrentDesignation: '',
      canditateTotalExperienceYear: '',
      canditateTotalExperienceMonth: '',
      canditateAnnualCtc: '',
      canditateExpectation: '',
      canditateNoticePeriod: '',
      canditateFamily: '',
      isWorking: false,
      canditatePresentLocation: '',
      canditateNative: '',
      isSaved: null,
      id: '',
      passportAndVisa: false,
      passportNumber: '',
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(resumePersonalSchema),
  });
  const duplicationFn = (res: {
    message: string;
    duplicationStatus: string;
    data: any;
    status: HttpStatusCode;
  }) => {
    setDuplication({
      data: res.data,
      message: res.message,
      duplicationStatus: res.duplicationStatus,
    });
    handleOpen();
  };

  const personalDraft = () => {
    setLoader(true);
    const personalData = {
      isSaved: false,
      position: getValues('positionFor'),
      firstName: getValues('canditateFirstName'),
      lastName: getValues('canditateLastName'),
      email: getValues('canditateEmail'),
      countryCode: getValues('countryCode'),
      phoneNumber: getValues('countryCode')
        ? getValues('canditatePhoneNo').slice(getValues('countryCode').length)
        : '',
      dob: getValues('canditateDob'),
      designationId: getValues('canditateCurrentDesignation'),
      expYears: getValues('canditateTotalExperienceYear'),
      expMonths: getValues('canditateTotalExperienceMonth'),
      currentCtc: getValues('canditateAnnualCtc'),
      currentCtcCurrency: 'inr',
      expectedCtc: getValues('canditateExpectation'),
      expectedCtcCurrency: 'inr',
      noticePeriod: getValues('canditateNoticePeriod'),
      isWorking: String(getValues('isWorking')) === 'true' ? true : false,
      gender: getValues('canditateGender'),
      maritalStatus: getValues('canditateMaritalStatus'),
      family: getValues('canditateFamily'),
      presentLocation: getValues('canditatePresentLocation'),
      native: getValues('canditateNative'),
      passportAndVisa: getValues('passportAndVisa') === true,
      passportNo: getValues('passportNumber'),
      ...(getValues('id') ? { id: getValues('id') } : {}),
      isIgnore,
    };
    const formdata: any = new FormData();
    formdata.append('resumeId', resumeId);
    formdata.append('resumeTabType', 'personal');
    formdata.append('personalData', JSON.stringify(personalData));

    addNewData(
      formdata,
      `${ResumesENDPOINT.resumeList}`,
      {
        'Content-Type': 'multipart/form-data',
      },
      true,
    )
      .then((res) => {
        setLoader(false);
        if (res?.status === 400) {
          duplicationFn(res);
        } else {
          navigate('/resumes');
        }
      })
      .catch((e) => {
        setLoader(false);
        console.log(e);
      });
  };

  const back = () => {
    navigate('/resumes');
  };
  const designationList = () => {
    getAllListData(`${SETTING_ENDPOINT.designation}?organizationId=${orgdy}&type=candidate`, true)
      .then((res: any) => {
        const options: any = [];
        res.data.map((e: any) => {
          options.push({ label: e.name, value: e.id });
        });
        setDesignation(options);
        setLoader(false);
      })
      .catch(() => {
        setLoader(false);
      });
  };
  const onSubmitForm = (data: any) => {
    setLoader(true);
    if (searchParams.get('id')) {
      const personalData = {
        isSaved: true,
        position: data.positionFor,
        firstName: data.canditateFirstName,
        lastName: data.canditateLastName,
        email: data.canditateEmail,
        countryCode: data.countryCode,
        phoneNumber: data.canditatePhoneNo.slice(data.countryCode.length),
        dob: data.canditateDob,
        designationId: data.canditateCurrentDesignation,
        expYears: data.canditateTotalExperienceYear,
        expMonths: data.canditateTotalExperienceMonth,
        currentCtc: data.canditateAnnualCtc,
        currentCtcCurrency: 'inr',
        expectedCtc: data.canditateExpectation,
        expectedCtcCurrency: 'inr',
        noticePeriod: data.canditateNoticePeriod,
        isWorking: data.isWorking === 'true' ? true : false,
        gender: data.canditateGender,
        maritalStatus: data.canditateMaritalStatus,
        family: data.canditateFamily,
        presentLocation: data.canditatePresentLocation,
        native: data.canditateNative,
        passportAndVisa: data.passportAndVisa === true,
        passportNo: data.passportNumber,
        ...(data.id ? { id: data.id } : {}),
        isIgnore,
      };
      const formdata: any = new FormData();
      formdata.append('resumeId', resumeId);
      formdata.append('resumeTabType', 'personal');
      formdata.append('personalData', JSON.stringify(personalData));

      addNewData(
        formdata,
        `${ResumesENDPOINT.resumeList}`,
        {
          'Content-Type': 'multipart/form-data',
        },
        true,
      )
        .then((res) => {
          setLoader(false);
          if (res?.status === 400) {
            duplicationFn(res);
          } else {
            setStep(1);
          }
        })
        .catch((e) => {
          setLoader(false);
          console.log(e);
        });
    } else {
      const personalData = {
        isSaved: true,
        position: data.positionFor,
        firstName: data.canditateFirstName,
        lastName: data.canditateLastName,
        email: data.canditateEmail,
        countryCode: data.countryCode,
        phoneNumber: data.canditatePhoneNo.slice(data.countryCode.length),
        dob: data.canditateDob,
        designationId: data.canditateCurrentDesignation,
        expYears: data.canditateTotalExperienceYear,
        expMonths: data.canditateTotalExperienceMonth,
        currentCtc: data.canditateAnnualCtc,
        currentCtcCurrency: 'inr',
        expectedCtc: data.canditateExpectation,
        expectedCtcCurrency: 'inr',
        noticePeriod: data.canditateNoticePeriod,
        isWorking: data.isWorking === 'true' ? true : false,
        gender: data.canditateGender,
        maritalStatus: data.canditateMaritalStatus,
        family: data.canditateFamily,
        presentLocation: data.canditatePresentLocation,
        native: data.canditateNative,
        passportAndVisa: data.passportAndVisa === true,
        passportNo: data.passportNumber,
        ...(data.id ? { id: data.id } : {}),
        // id: data.,
        isIgnore,
      };
      const formdata: any = new FormData();
      formdata.append('resumeId', resumeId);
      formdata.append('resumeTabType', 'personal');
      formdata.append('personalData', JSON.stringify(personalData));

      addNewData(
        formdata,
        `${ResumesENDPOINT.resumeList}`,
        {
          'Content-Type': 'multipart/form-data',
        },
        true,
      )
        .then((res) => {
          setLoader(false);
          if (res?.status === 400) {
            duplicationFn(res);
          } else {
            setStep(1);
          }
        })
        .catch((e) => {
          setLoader(false);
          console.log(e);
        });
    }
  };

  const getInitialStatus = async () => {
    if (searchParams.get('id') || resumeIds) {
      setLoader(true);
      await getSingleData(searchParams.get('id') || resumeIds, ResumesENDPOINT.resumeList)
        .then((resp: any) => {
          setValue('positionFor', resp?.data[0]?.position && resp?.data[0]?.position?.title);
          setResumeId(resp?.data && resp?.data[0]?.id);
          setDraftCheck(resp?.data[0]?.approvalStatus === 'draft' ? true : false);
          if (resp && !_.isEmpty(resp?.data) && !_.isEmpty(resp?.data[0])) {
            setValue('isSaved', resp?.data[0]?.personal?.isSaved);
            setValue('canditateFirstName', resp?.data[0]?.personal?.firstName);
            setValue('canditateLastName', resp?.data[0]?.personal?.lastName);
            setValue('canditateEmail', resp?.data[0]?.personal?.email);
            setValue(
              'canditatePhoneNo',
              `${resp?.data[0]?.personal?.countryCode}${resp?.data[0]?.personal?.phoneNumber}`,
            );
            setValue(
              'formatteValue',
              `+${resp?.data[0]?.personal?.countryCode} ${resp?.data[0]?.personal?.phoneNumber}`,
            );
            setValue('canditateDob', resp?.data[0]?.personal?.dob);
            setValue('canditateGender', resp?.data[0]?.personal?.gender);
            setValue('canditateMaritalStatus', resp?.data[0]?.personal?.maritalStatus);
            setValue('canditateCurrentDesignation', resp?.data[0]?.personal?.designationId);
            // setValue('canditateCurrentDesignation', resp?.data[0]?.personal?.designation);
            setValue('canditateTotalExperienceYear', String(resp?.data[0]?.personal?.expYears));
            setValue('canditateTotalExperienceMonth', String(resp?.data[0]?.personal?.expMonths));
            setValue('canditateAnnualCtc', resp?.data[0]?.personal?.currentCtc);
            setValue('canditateExpectation', resp?.data[0]?.personal?.expectedCtc);
            setValue('canditateFamily', resp?.data[0]?.personal?.family);
            setValue('isWorking', resp?.data[0]?.personal?.isWorking);
            setValue('canditatePresentLocation', resp?.data[0]?.personal?.presentLocation);
            setValue('canditateNative', resp?.data[0]?.personal?.native);
            setValue('canditateNoticePeriod', resp?.data[0]?.personal?.noticePeriod);
            setValue('countryCode', resp?.data[0]?.personal?.countryCode);
            setValue(
              'passportAndVisa',
              resp?.data[0]?.personal?.passportAndVisa ? 'true' : 'false',
            );
            setValue('passportNumber', resp?.data[0]?.personal?.passportNo);
            setValue('id', resp?.data[0]?.personal?.id);
            setPersonalId(resp?.data[0]?.personal?.id);
            resumeData(resp?.data && resp?.data[0]);
            // const dat = positionData.find((re: any) => re.value === resp?.data[0].positionId);
          }
          setLoader(false);
        })
        .catch((e: any) => {
          setLoader(false);
          console.log(e);
        });
    }
  };
  React.useEffect(() => {
    if (searchParams.get('id') || resumeIds) {
      designationList();
      getInitialStatus();
    }
  }, []);
  React.useEffect(() => {
    setValue('positionFor', position);
    setResumeId(resumeIds);
  }, [resumeIds]);
  React.useEffect(() => {
    if (watch('passportAndVisa') === 'false') {
      setValue('passportNumber', '');
    }
  }, [watch('passportAndVisa')]);
  return (
    <Grid container>
      {loader && <Loader />}
      <Grid item md={12} sm={12} xs={12}>
        <Typography component={'div'} className='step-parent-box'>
          <Typography component={'div'} className='d-flex-a flex-sb'>
            <Typography className='stepper-title'>Personal</Typography>
            <Typography className='d-flex-a'>
              <Button className='cancel-btn mr-16' onClick={back}>
                Cancel
              </Button>
              <Button
                className='go-to-clnt-list-btn mr-16'
                disabled={!draftCheck ? true : false}
                sx={{ cursor: !draftCheck ? 'not-allowed' : 'pointer' }}
                onClick={personalDraft}>
                Draft
              </Button>
              <Button
                className='s-save-btn'
                form={'personal-form'}
                type={'submit'}
                onClick={() => handleOpen()}>
                Save and Continue
              </Button>
            </Typography>
          </Typography>
          <Typography className='bdiveder' />
          <form className='private-form' id={'personal-form'} onSubmit={handleSubmit(onSubmitForm)}>
            <Grid container columnSpacing={4.25}>
              <Grid item md={4} sm={12} xs={12} className='mt-15'>
                <Typography component={'div'} className='custom-field'>
                  <Typography component={'p'}>
                    Position For <span>*</span>
                  </Typography>

                  <Controller
                    control={control}
                    defaultValue={null}
                    name='positionFor'
                    render={({ field: { onChange, value, name } }) => {
                      return (
                        <CustomInput
                          value={value}
                          name={name}
                          disabled
                          className='custom-input'
                          placeHolder='Position For'
                          error={errors.positionFor?.message}
                          onChange={(e) => {
                            onChange(e);
                          }}
                        />
                      );
                    }}
                  />
                </Typography>
              </Grid>
              <Grid item md={4} sm={12} xs={12} className='mt-15'>
                <Typography component={'div'} className='custom-field'>
                  <Typography component={'p'}>
                    Canditate First Name <span>*</span>
                  </Typography>
                  <Controller
                    control={control}
                    defaultValue={null}
                    name='canditateFirstName'
                    render={({ field: { onChange, value, name } }) => {
                      return (
                        <CustomInput
                          value={value}
                          name={name}
                          className='custom-input'
                          placeHolder='First Name'
                          error={errors.canditateFirstName?.message}
                          onChange={(e) => {
                            onChange(e);
                          }}
                        />
                      );
                    }}
                  />
                </Typography>
              </Grid>
              <Grid item md={4} sm={12} xs={12} className='mt-15'>
                <Typography component={'div'} className='custom-field'>
                  <Typography component={'p'}>
                    Candidate Last Name <span>*</span>
                  </Typography>
                  <Controller
                    control={control}
                    defaultValue={null}
                    name='canditateLastName'
                    render={({ field: { onChange, value, name } }) => {
                      return (
                        <CustomInput
                          value={value}
                          name={name}
                          className='custom-input'
                          placeHolder='Last Name'
                          error={errors.canditateLastName?.message}
                          onChange={(e) => {
                            onChange(e);
                          }}
                        />
                      );
                    }}
                  />
                </Typography>
              </Grid>
              <Grid item md={4} sm={12} xs={12} className='mt-15'>
                <Typography component={'div'} className='custom-field'>
                  <Typography component={'p'}>
                    Email <span>*</span>
                  </Typography>
                  <Controller
                    control={control}
                    defaultValue={null}
                    name='canditateEmail'
                    render={({ field: { onChange, value, name } }) => {
                      return (
                        <CustomInput
                          value={value}
                          name={name}
                          inputProps={{
                            startAdornment: (
                              <InputAdornment position='start' style={{ marginRight: '-6px' }}>
                                <img src={EmailIcon} alt='email' />
                              </InputAdornment>
                            ),
                          }}
                          onChange={(e) => {
                            onChange(e);
                          }}
                          error={errors.canditateEmail?.message}
                          className='custom-input'
                          placeHolder='Enter Email'
                        />
                      );
                    }}
                  />
                </Typography>
              </Grid>
              <Grid item md={4} sm={12} xs={12} className='mt-15'>
                <Typography component={'div'} className='custom-field'>
                  <Typography component={'p'}>
                    Phone No <span>*</span>
                  </Typography>
                  <Controller
                    control={control}
                    defaultValue={null}
                    name='formatteValue'
                    render={({ field: { onChange, value, name } }) => {
                      return (
                        // <CustomInput
                        //   value={value}
                        //   type='number'
                        //   minLength={10}
                        //   maxLength={11}
                        //   name={name}
                        //   disabled={!editProfile ? true : false}
                        //   className='custom-input'
                        //   placeHolder='Enter Phone Number'
                        //   error={errors.phone?.message}
                        //   onChange={(e) => {
                        //     onChange(e);
                        //   }}
                        // />
                        // <PhoneInput
                        //   containerClass='custom-field'
                        //   country={'eg'}
                        //   enableSearch={true}
                        //   value={value}
                        //   onChange={(e) => onChange(e)}
                        // />
                        <CustomPhoneInput
                          placeholder='(000) 000-0000'
                          containerClass={errors.phoneNumber?.message ? 'phone-input-error' : ''}
                          inputClass={
                            errors.phoneNumber?.message
                              ? 'phone-input phone-input-no-border'
                              : 'phone-input'
                          }
                          initialCountry={'in'}
                          specialLabel={''}
                          searchClass={'search-custom-class'}
                          value={value}
                          enableSearch={true}
                          searchNotFound={'No Country Found'}
                          inputStyle={{
                            width: '100%',
                            padding: '9px 11px',
                            paddingLeft: '60px',
                            borderRadius: '6px',
                            color: value && String(value).length > 0 ? 'black' : '#bdbdbd',
                            fontSize: '16px !important',
                            fontFamily: 'Poppins-Regular',
                            boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
                          }}
                          onChange={(value, data, event, formattedValue) => {
                            const { countryCode, dialCode } = data;
                            setValue('countryCode', dialCode);
                            setValue('countryCodeText', countryCode);
                            setValue('canditatePhoneNo', value);
                            setValue('formatteValue', formattedValue);
                            trigger('formatteValue');
                          }}
                          error={errors.formatteValue?.message ? errors.formatteValue?.message : ''}
                          inputProps={{ tabIndex: 11 }}
                        />
                      );
                    }}
                  />
                </Typography>
              </Grid>
              <Grid item md={4} sm={12} xs={12} className='mt-15'>
                <Typography component={'div'} className='custom-field'>
                  <Typography component={'p'}>
                    Date of Birth <span>*</span>
                  </Typography>
                  <Controller
                    control={control}
                    name={'canditateDob'}
                    render={({ field: { onChange, value, name } }) => {
                      return (
                        <>
                          <TextField
                            value={value}
                            name={name}
                            id='date'
                            type='date'
                            placeholder={'Select Date of Birth'}
                            className={`clientfilter-time-from-to ${
                              errors.canditateDob && errors.canditateDob.message
                                ? 'clientfilter-time-from-to-error'
                                : ''
                            }`}
                            InputProps={{
                              inputProps: {
                                max: currentDate,
                              },
                            }}
                            onChange={(e) => {
                              onChange(e);
                            }}
                          />
                          {errors && errors.canditateDob && errors?.canditateDob?.message && (
                            <div className='Custom-error '>
                              {String(errors?.canditateDob?.message)}
                            </div>
                          )}
                        </>
                      );
                    }}
                  />
                </Typography>
              </Grid>
              <Grid item md={4} sm={12} xs={12} className='mt-15'>
                <Typography component={'div'} className='custom-field'>
                  <Typography component={'p'}>
                    Current Designation <span>*</span>
                  </Typography>
                  <Controller
                    control={control}
                    defaultValue={null}
                    name='canditateCurrentDesignation'
                    render={({ field: { onChange, value, name } }) => {
                      return (
                        <CustomSelect
                          labelKey={'label'}
                          valueKey={'value'}
                          options={
                            designation?.length
                              ? [
                                  {
                                    label: '+ Add Designation',
                                    value: '',
                                    isDisabled: false,
                                    isFixed: true,
                                    isSelectable: false,
                                    custmdwn: true,
                                  },
                                  ...designation,
                                ]
                              : [
                                  {
                                    label: '+ Add Designation',
                                    value: '',
                                    isDisabled: false,
                                    isFixed: true,
                                    isSelectable: false,
                                    custmdwn: true,
                                  },
                                ]
                          }
                          name={name}
                          value={value ? value : null}
                          custmfn={handleDesignationOpen}
                          onChange={(e) => {
                            onChange(e);
                          }}
                          placeHolder='Current Designation'
                          error={errors.canditateCurrentDesignation?.message}
                        />

                        // <CustomInput
                        //   value={value}
                        //   name={name}
                        //   className='custom-input'
                        //   placeHolder='Current Designation'
                        //   error={errors.canditateCurrentDesignation?.message}
                        //   onChange={(e) => {
                        //     onChange(e);
                        //   }}
                        // />
                      );
                    }}
                  />
                </Typography>
              </Grid>
              <Grid item md={4} sm={12} xs={12} className='mt-15'>
                <Typography component={'div'} className='custom-field'>
                  <Typography component={'p'}>
                    Total Years of Exp <span>*</span>
                  </Typography>
                  <Controller
                    control={control}
                    defaultValue={''}
                    name='canditateTotalExperienceYear'
                    render={({ field: { onChange, value, name } }) => {
                      return (
                        <CustomInput
                          // labelKey={'label'}
                          // valueKey={'value'}
                          // options={experienceYear}
                          className='custom-input'
                          type={'number'}
                          name={name}
                          value={value}
                          onChange={(e) => {
                            const vals = e.target.value.replace(/\D/g, ''); // Remove any non-digit characters
                            setValue('canditateTotalExperienceYear', e.target.value);
                            trigger('canditateTotalExperienceYear');
                            if (getValues('canditateTotalExperienceYear') === 0) {
                              setValue('canditateTotalExperienceMonth', '');
                              setValue('canditateAnnualCtc', '');
                              setValue('canditateExpectation', '');
                              setValue('canditateNoticePeriod', '');
                              setValue('isWorking', false);
                            }
                            onChange(vals);
                          }}
                          placeHolder='Select'
                          error={errors.canditateTotalExperienceYear?.message}
                        />
                      );
                    }}
                  />
                </Typography>
              </Grid>
              <Grid item md={4} sm={12} xs={12} className='mt-15'>
                <Typography component={'div'} className='custom-field'>
                  <Typography component={'p'} sx={{ visibility: 'hidden' }}>
                    Month <span>*</span>
                  </Typography>
                  <Controller
                    control={control}
                    defaultValue={''}
                    name='canditateTotalExperienceMonth'
                    render={({ field: { onChange, value, name } }) => {
                      return (
                        <CustomInput
                          // labelKey={'label'}
                          // valueKey={'value'}
                          type={'number'}
                          className='custom-input'
                          disabled={watch('canditateTotalExperienceYear') == 0 ? true : false}
                          // options={experienceMonth}
                          name={name}
                          value={value}
                          onChange={(e) => {
                            const vals = e.target.value.replace(/\D/g, '');
                            onChange(vals);
                          }}
                          placeHolder='Select'
                          error={errors.canditateTotalExperienceMonth?.message}
                        />
                      );
                    }}
                  />
                </Typography>
              </Grid>
              <Grid item md={4} sm={12} xs={12} className='mt-15'>
                <Typography component={'div'} className='custom-field'>
                  <Typography component={'p'}>
                    Annual CTC <span>*</span>
                  </Typography>
                  <Controller
                    control={control}
                    defaultValue={''}
                    name='canditateAnnualCtc'
                    render={({ field: { onChange, value, name } }) => {
                      return (
                        <CustomInput
                          className='custom-input'
                          name={name}
                          value={value}
                          type={'number'}
                          onChange={(e) => {
                            const vals = e.target.value.replace(/\D/g, '');
                            return onChange(vals);
                          }}
                          disabled={watch('canditateTotalExperienceYear') == 0 ? true : false}
                          placeHolder='Annual CTC'
                          error={errors.canditateAnnualCtc?.message}
                          customComponent={
                            <Box
                              sx={{
                                minWidth: 120,
                                position: 'absolute',
                                right: '-40px',
                                transform: 'scale(0.9)',
                              }}>
                              <NativeSelect
                                // defaultValue={'inr'}
                                className='position-currency-option'
                                IconComponent={ExpandMoreRoundedIcon}
                                sx={{
                                  border: 'none !important',
                                  minHeight: '20px !important',
                                  '&::before': { borderBottom: 'transparent !important' },
                                  '& > select': {
                                    minWidth: '40px !important',
                                    border: 'none !important',
                                    outline: 'none !important',
                                    marginRight: '8px',
                                  },
                                }}>
                                <option value={'inr'}>INR</option>
                                <option value={'usd'}>USD</option>
                                <option value={'eu'}>EU</option>
                              </NativeSelect>
                            </Box>
                          }
                        />
                      );
                    }}
                  />
                </Typography>
              </Grid>
              <Grid item md={4} sm={12} xs={12} className='mt-15'>
                <Typography component={'div'} className='custom-field'>
                  <Typography component={'p'}>
                    Expected CTC <span>*</span>
                  </Typography>
                  <Controller
                    control={control}
                    defaultValue={''}
                    name='canditateExpectation'
                    render={({ field: { onChange, value, name } }) => {
                      return (
                        <CustomInput
                          className='custom-input'
                          name={name}
                          value={value}
                          type={'number'}
                          onChange={(e) => {
                            const vals = e.target.value.replace(/\D/g, '');
                            return onChange(vals);
                          }}
                          disabled={watch('canditateTotalExperienceYear') == 0 ? true : false}
                          placeHolder='Expected CTC'
                          error={errors.canditateExpectation?.message}
                          customComponent={
                            <Box
                              sx={{
                                minWidth: 120,
                                position: 'absolute',
                                right: '-40px',
                                transform: 'scale(0.9)',
                              }}>
                              <NativeSelect
                                // defaultValue={'inr'}
                                className='position-currency-option'
                                IconComponent={ExpandMoreRoundedIcon}
                                sx={{
                                  border: 'none !important',
                                  minHeight: '20px !important',
                                  '&::before': { borderBottom: 'transparent !important' },
                                  '& > select': {
                                    minWidth: '40px !important',
                                    border: 'none !important',
                                    outline: 'none !important',
                                    marginRight: '8px',
                                  },
                                }}>
                                <option value={'inr'}>INR</option>
                                <option value={'usd'}>USD</option>
                                <option value={'eu'}>EU</option>
                              </NativeSelect>
                            </Box>
                          }
                        />
                      );
                    }}
                  />
                </Typography>
              </Grid>
              <Grid item md={4} sm={12} xs={12} className='mt-15'>
                <Typography component={'div'} className='custom-field'>
                  <Typography component={'p'}>
                    Notice Period <span>*</span>
                  </Typography>
                  <Controller
                    control={control}
                    defaultValue={''}
                    name='canditateNoticePeriod'
                    render={({ field: { onChange, value, name } }) => {
                      return (
                        <CustomSelect
                          labelKey={'label'}
                          valueKey={'value'}
                          options={noticePeriod}
                          name={name}
                          value={value}
                          disabled={
                            watch('canditateTotalExperienceYear') == 0 || watch('isWorking')
                              ? true
                              : false
                          }
                          onChange={(e) => {
                            onChange(e);
                            trigger('canditateTotalExperienceYear');
                          }}
                          placeHolder='Select'
                          error={errors.canditateNoticePeriod?.message}
                        />
                      );
                    }}
                  />
                  <div style={{ display: 'flex', marginTop: '5px' }}>
                    {/* <FormControlLabel
                      label=''
                      disabled={watch('canditateTotalExperienceYear') == 0 ? true : false}
                      name='isWorking'
                      control={
                        <Checkbox
                          className='custom-checkbox'
                          sx={{
                            '&:hover': { bgcolor: 'transparent' },
                          }}
                          disableRipple
                          color='default'
                          checkedIcon={<CheckedIcon />}
                          icon={<CheckIcon />}
                        />
                      }
                    /> */}
                    <Controller
                      control={control}
                      name='isWorking'
                      render={({ field: { onChange, value, name } }) => {
                        return (
                          <Checkbox
                            className='custom-checkbox'
                            sx={{
                              '&:hover': { bgcolor: 'transparent' },
                            }}
                            checked={value}
                            disabled={
                              watch('canditateNoticePeriod') ||
                              watch('canditateTotalExperienceYear') == 0
                                ? true
                                : false
                            }
                            name={name}
                            value={value}
                            onChange={(e) => {
                              onChange(e);
                            }}
                            color='default'
                            checkedIcon={<CheckedIcon />}
                            icon={<CheckIcon />}
                          />
                        );
                      }}
                    />
                    <Typography component={'p'} sx={{ marginTop: '7px', marginLeft: '10px' }}>
                      Presently Working
                    </Typography>
                  </div>
                </Typography>
              </Grid>
              <Grid item md={4} sm={12} xs={12} className='mt-15'>
                <Typography component={'div'} className='custom-field'>
                  <Typography component={'p'}>
                    Gender <span>*</span>
                  </Typography>
                  <Controller
                    control={control}
                    defaultValue=''
                    name='canditateGender'
                    render={({ field: { onChange, value, name } }) => {
                      return (
                        <RadioGroup
                          className={`custom-radio ${
                            errors.canditateGender && errors.canditateGender.message
                              ? 'custom-radio'
                              : ''
                          }`}
                          row
                          aria-labelledby=''
                          name={name}
                          value={value}
                          onChange={(e) => {
                            onChange(e);
                          }}>
                          <FormControlLabel
                            value='male'
                            control={<Radio />}
                            label='Male'
                            checked={value === 'male'}
                          />
                          <FormControlLabel
                            value='female'
                            control={<Radio />}
                            label='Female'
                            checked={value === 'female'}
                          />
                        </RadioGroup>
                      );
                    }}
                  />{' '}
                  {errors && errors.canditateGender && errors?.canditateGender?.message && (
                    <div className='Custom-error '>{String(errors?.canditateGender?.message)}</div>
                  )}
                </Typography>
              </Grid>
              <Grid item md={4} sm={12} xs={12} className='mt-15'>
                <Typography component={'div'} className='custom-field'>
                  <Typography component={'p'}>
                    Marital Status <span>*</span>
                  </Typography>
                  <Controller
                    control={control}
                    defaultValue=''
                    name='canditateMaritalStatus'
                    render={({ field: { onChange, value, name } }) => {
                      return (
                        <RadioGroup
                          className={`custom-radio ${
                            errors.canditateMaritalStatus && errors.canditateMaritalStatus.message
                              ? 'custom-radio'
                              : ''
                          }`}
                          row
                          aria-labelledby=''
                          name={name}
                          value={value}
                          onChange={(e) => {
                            onChange(e);
                          }}>
                          <FormControlLabel
                            value='single'
                            control={<Radio />}
                            label='Single'
                            checked={value === 'single'}
                          />
                          <FormControlLabel
                            value='married'
                            control={<Radio />}
                            label='Married'
                            checked={value === 'married'}
                          />
                          {/* <FormControlLabel
                            value='separated'
                            control={<Radio />}
                            label='Separated'
                            checked={value === 'separated'}
                          /> */}
                        </RadioGroup>
                      );
                    }}
                  />
                  {errors &&
                    errors.canditateMaritalStatus &&
                    errors?.canditateMaritalStatus?.message && (
                      <div className='Custom-error '>
                        {String(errors?.canditateMaritalStatus?.message)}
                      </div>
                    )}
                </Typography>
              </Grid>
              <Grid item md={4} sm={12} xs={12} className='mt-15'>
                <Typography component={'div'} className='custom-field'>
                  <Typography component={'p'}>
                    Passport and VISA <span>*</span>
                  </Typography>
                  <Controller
                    control={control}
                    defaultValue=''
                    name='passportAndVisa'
                    render={({ field: { onChange, value, name } }) => {
                      return (
                        <RadioGroup
                          className={`custom-radio ${
                            errors.passportAndVisa && errors.passportAndVisa.message
                              ? 'custom-radio'
                              : ''
                          }`}
                          row
                          aria-labelledby=''
                          name={name}
                          value={value}
                          onChange={(e) => {
                            onChange(e);
                            trigger('passportNumber');
                          }}>
                          <FormControlLabel
                            value={'true'}
                            control={<Radio />}
                            label='Yes'
                            checked={value === 'true'}
                          />
                          <FormControlLabel
                            value={'false'}
                            control={<Radio />}
                            label='No'
                            checked={value === 'false'}
                          />
                        </RadioGroup>
                      );
                    }}
                  />
                  {errors && errors.passportAndVisa && errors?.passportAndVisa?.message && (
                    <div className='Custom-error '>{String(errors?.passportAndVisa?.message)}</div>
                  )}
                </Typography>
              </Grid>
              <Grid item md={4} sm={12} xs={12} className='mt-15'>
                <Typography component={'div'} className='custom-field'>
                  <Typography component={'p'}>
                    Present Location <span>*</span>
                  </Typography>
                  <Controller
                    control={control}
                    defaultValue={null}
                    name='canditatePresentLocation'
                    render={({ field: { onChange, value, name } }) => {
                      return (
                        <CustomInput
                          value={value}
                          name={name}
                          className='custom-input'
                          placeHolder='Present Location'
                          error={errors.canditatePresentLocation?.message}
                          onChange={(e) => {
                            onChange(e);
                          }}
                        />
                      );
                    }}
                  />
                </Typography>
              </Grid>
              <Grid item md={4} sm={12} xs={12} className='mt-15'>
                <Typography component={'div'} className='custom-field'>
                  <Typography component={'p'}>Native Location</Typography>
                  <Controller
                    control={control}
                    defaultValue={null}
                    name='canditateNative'
                    render={({ field: { onChange, value, name } }) => {
                      return (
                        <CustomInput
                          value={value}
                          name={name}
                          className='custom-input'
                          placeHolder='Native Location'
                          error={errors.canditateNative?.message}
                          onChange={(e) => {
                            onChange(e);
                          }}
                        />
                      );
                    }}
                  />
                </Typography>
              </Grid>
              <Grid item md={4} sm={12} xs={12} className='mt-15'>
                <Typography component={'div'} className='custom-field'>
                  <Typography component={'p'}>Passport No</Typography>
                  <Controller
                    control={control}
                    defaultValue={null}
                    name='passportNumber'
                    render={({ field: { onChange, value, name } }) => {
                      return (
                        <CustomInput
                          value={value}
                          name={name}
                          className='custom-input'
                          placeHolder='Passport No'
                          disabled={watch('passportAndVisa') === 'true' ? false : true}
                          error={errors.passportNumber?.message}
                          onChange={(e) => {
                            onChange(e);
                          }}
                        />
                      );
                    }}
                  />
                </Typography>
              </Grid>
              <Grid item md={12} sm={12} xs={12} className='mt-15'>
                <Typography component={'div'} className='custom-field'>
                  <Typography component={'p'}>
                    Family (Dependents) <span>*</span>
                  </Typography>
                  <Controller
                    control={control}
                    defaultValue={null}
                    name='canditateFamily'
                    render={({ field: { onChange, value, name } }) => {
                      return (
                        <CustomInput
                          value={value}
                          name={name}
                          isTextArea={true}
                          className='custom-input'
                          error={errors.canditateFamily?.message}
                          onChange={(e) => {
                            onChange(e);
                          }}
                        />
                      );
                    }}
                  />
                </Typography>
              </Grid>
            </Grid>
          </form>
        </Typography>
      </Grid>
      {!_.isEmpty(duplication?.data) && open && (
        <ResumeDuplication
          open={open}
          data={duplication?.data}
          message={duplication?.message}
          duplicationStatus={duplication?.duplicationStatus}
          handleClose={handleClose}
          setIsIgnore={setIsIgnore}
        />
      )}
      <Modal
        open={designationModelopen}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'>
        <DesignationAdd
          handleDesignationClose={handleDesignationClose}
          designationList={designationList}
          setValue={setValue}
        />
      </Modal>
    </Grid>
  );
};
export default ResumePersonal;
