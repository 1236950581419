import { configureStore } from '@reduxjs/toolkit';
import logger from 'redux-logger';
const middleware = [logger];

import counterReducer from 'store/reducers';
import { userLoginReducer } from 'store/reducers/UserReducer';
import ClientReducer from 'store/reducers/clientreducer';
import clientcorporatereducer from './reducers/clientcorporatereducer';
export const store = configureStore({
  reducer: {
    counter: counterReducer,
    userLogin: userLoginReducer,
    clientreducer: ClientReducer,
    corporatereducer:clientcorporatereducer
  },
  middleware,
});
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
