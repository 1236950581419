import { Tooltip, Typography } from '@mui/material';
import FileSaver from 'file-saver';
import React from 'react';
import { getAllListData } from 'services/CommonServices';
import excelIcon from '../png/excel.svg';

const ExcelIcon = ({ url, fileName, setLoader }: any) => {
  const exportXl = () => {
    setLoader(true);
    getAllListData(url, true)
      .then((res) => {
        const date = Date.now();
        const data = new Blob([res]);
        FileSaver.saveAs(data, `${fileName}_${date}.csv`);
        setLoader(false);
      })
      .catch(() => setLoader(false));
  };
  return (
    <Typography onClick={exportXl} className='pointer' style={{ cursor: 'pointer' }}>
      <Tooltip title='Export to excel'>
        <img src={excelIcon} alt={'Export to excel'} style={{ width: '30px' }} />
      </Tooltip>
    </Typography>
  );
};

export default ExcelIcon;
