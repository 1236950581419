import { Grid, Typography, Breadcrumbs, Link, Button, Popover } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { AdminPrivateRoutes } from 'routes/routeConstans';
import HomeIcon from 'assets/png/dashboardactive.svg';
import ArrowIcon from 'assets/png/breadarrow.svg';
import { To, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import EditIcon from 'assets/png/edit.svg';
import { getSingleData } from 'services/CommonServices';
import { BillingENDPOINT } from 'utils/Constant';
import _ from 'lodash';
import ApprovalModal from '../ApprovalModal';
import { CreatePayment } from './createPayment';
import moment from 'moment';
import Loader from 'components/loader';
import { CreateInvoice } from './createInvoice';
import { useAppSelector } from 'store/hooks';
import { toast } from 'react-toastify';
import { AccessDenied } from 'utils/helper';
import { WriteOff } from './writeOff';
import { CancelInvoice } from './cancelInvoice';

const BillingView = () => {
  const [panchorEl, setPAnchorEl] = React.useState<HTMLAnchorElement | null>(null);
  const open = Boolean(panchorEl);
  const paid = open ? 'simple-popover' : undefined;
  const handleClose = () => {
    setPAnchorEl(null);
  };
  const handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    setPAnchorEl(event.currentTarget);
  };
  const {
    userInfo: { roleId },
  }: any = useAppSelector((store: any) => store.userLogin);
  const location = useLocation();
  const [Ld, setLd] = useState(false);
  const [searchParams]: any = useSearchParams();
  const [clientData, setClientData] = useState<any>([]);
  const [candidateData, setCandidateData] = useState<any>([]);
  const [invoiceData, setInvoiceData] = useState<any>([]);
  const [paymentData, setPaymentData] = useState<any>([]);
  const [addClientData, setAddClientData] = useState<any>([]);
  const [editInvoiceData, setEditInvoiceData] = useState<any>([]);

  const [ids, setIds] = useState<{ billingId: string; resumeId: string }>({
    billingId: '',
    resumeId: '',
  });
  const [modalOpen, setModalOpen] = useState<{ open: boolean; title: string }>({
    open: false,
    title: '',
  });
  const [paymentModalOpen, setPaymentModalOpen] = useState<boolean>(false);
  const [invioceModalOpen, setInvoiceModalOpen] = useState<any>({ open: false, data: [] });
  const [writeOffModalOpen, setWriteOffModalOpen] = useState<boolean>(false);
  const [invoiceId, setInvoiceId] = useState<string>('');
  const [cancelInvoiceModalOpen, setCancelInvoiceModalOpen] = useState<boolean>(false);
  const [resumeStatus, setResumeStatus] = useState<any>();

  const navigate = useNavigate();
  const gotoNavigate = (link: To) => {
    navigate(link);
  };
  const getList = () => {
    if (location?.state?.id !== searchParams.get('id')) {
      gotoNavigate(AdminPrivateRoutes.DASHBOARD);
    } else {
      getSingleData(searchParams.get('id'), BillingENDPOINT.billing)
        .then((res) => {
          if (
            res &&
            !_.isEmpty(res.data) &&
            res.data &&
            res.data.length > 0 &&
            !_.isEmpty(res.data[0])
          ) {
            const data = res?.data[0];
            setResumeStatus(data?.resume?.resumeStatus);
            // setButtonDisable({ payment: data.payment, invoice: data.invoice });
            setIds({
              billingId: res?.data[0]?.id,
              resumeId: res?.data[0]?.resumeId,
            });

            if (!_.isEmpty(data)) {
              searchParams.set('positionId', data.resume.positionId);
              searchParams.set('resumeId', data.resume.id);
              setClientData(() => [
                {
                  title: 'Client Name',
                  subtitle: data?.resume?.client?.corporateName
                    ? data?.resume?.client?.corporateName
                    : '-',
                },
                {
                  title: 'Position',
                  subtitle: data?.resume?.position?.title ? data?.resume?.position?.title : '-',
                },
                {
                  title: 'Recuriter',
                  subtitle: data?.resume?.createdBy?.firstName
                    ? `${data?.resume?.createdBy?.firstName} ${
                        data?.resume?.createdBy?.lastName ? data?.resume?.createdBy?.lastName : ''
                      }`
                    : '-',
                },
                {
                  title: 'Account Holder',
                  subtitle: data?.resume?.position?.crm[0]?.crm?.user?.firstName
                    ? `${data?.resume?.position?.crm[0]?.crm?.user?.firstName} ${
                        data?.resume?.position?.crm[0]?.crm?.user?.lastName
                          ? data?.resume?.position?.crm[0]?.crm?.user?.lastName
                          : ''
                      }`
                    : '-',
                },
                {
                  title: 'Billing %',
                  subtitle: data?.billingPercentage
                    ? `${Number(data?.billingPercentage).toFixed(0)}%`
                    : '-',
                },
                {
                  title: 'Billing Amount',
                  subtitle: data?.billingAmount
                    ? `${Number(data?.billingAmount).toLocaleString('en-IN')}`
                    : '-',
                },
                {
                  title: 'Proof of Offer',
                  subtitle: data?.personal?.noticePeriod ? data?.personal?.noticePeriod : '---',
                },
              ]);
              setCandidateData(() => [
                {
                  title: 'Candidate Name',
                  subtitle: data?.resume?.personal?.firstName
                    ? `${data?.resume?.personal?.firstName} ${
                        data?.resume?.personal?.lastName ? data?.resume?.personal?.lastName : ''
                      }`
                    : '-',
                },
                {
                  title: 'CTC Offered',
                  subtitle: data?.resume?.offeredCTC
                    ? Number(data?.resume?.offeredCTC).toFixed(0)
                    : '-',
                },
                {
                  title: 'Designation Offered',
                  subtitle: data?.resume?.designationOffered
                    ? data?.resume?.designationOffered
                    : '-',
                },
                {
                  title: 'Billing Date',
                  subtitle: data?.billingDate
                    ? moment(data?.billingDate).format('DD-MM-YYYY')
                    : '-',
                },
                {
                  title: 'Joined Date',
                  subtitle: data?.resume?.joinedDate
                    ? moment(data?.resume?.joinedDate).format('DD-MM-YYYY')
                    : '-',
                },
              ]);
              const canceldata = data?.resume?.resumeStatus?.find((e: any) => e.type === 'invoice');
              setEditInvoiceData(data?.invoice ? data?.invoice : []);
              setInvoiceData(() =>
                canceldata?.status === 'cancelled'
                  ? [
                      {
                        title: 'Cancel Remarks',
                        subtitle: canceldata?.remarks ? canceldata?.remarks : '--',
                      },
                    ]
                  : canceldata?.status === 'generated'
                    ? [
                        {
                          title: 'Invoice No',
                          subtitle: data?.invoice?.invoiceNo ? data?.invoice?.invoiceNo : '--',
                        },
                        {
                          title: 'Invoice Date',
                          subtitle: data?.invoice?.invoiceDate ? data?.invoice?.invoiceDate : '--',
                        },
                        {
                          title: 'Professional Fees',
                          subtitle: data?.invoice?.professionalFees
                            ? Number(data?.invoice?.professionalFees).toFixed(0)
                            : '--',
                        },
                        {
                          title: 'Tax',
                          subtitle: data?.invoice?.tax
                            ? Number(data?.invoice?.tax).toFixed(0)
                            : '--',
                        },
                      ]
                    : [],
              );
              setPaymentData(() =>
                data?.payment?.type === 'payment'
                  ? [
                      {
                        title: 'Mode of Payment',
                        subtitle: data?.payment?.modeOfPayment
                          ? data?.payment?.modeOfPayment
                          : '--',
                      },
                      {
                        title: 'Payment Received Date',
                        subtitle: data?.payment?.paymentReceivedDate
                          ? data?.payment?.paymentReceivedDate
                          : '--',
                      },
                      {
                        title: 'Payment Received ',
                        subtitle: data?.payment?.amount
                          ? Number(data?.payment?.amount).toFixed(0)
                          : '--',
                      },
                      {
                        title: 'Reference No',
                        subtitle: data?.payment?.referenceNo ? data?.payment?.referenceNo : '--',
                      },
                      {
                        title: 'Shortfall',
                        subtitle: data?.payment?.shortfall ? data?.payment?.shortfall : '--',
                      },
                      {
                        title: 'Payment Remarks',
                        subtitle: data?.payment?.remarks ? data?.payment?.remarks : '--',
                      },
                    ]
                  : data?.payment?.type === 'writeoff'
                    ? [
                        {
                          title: 'Write Off',
                          subtitle: data?.payment?.message ? (
                            <div dangerouslySetInnerHTML={{ __html: data?.payment?.message }} />
                          ) : (
                            '--'
                          ),
                        },
                        {
                          title: 'Write Off Amount',
                          subtitle: data?.payment?.amount ? data?.payment?.amount : '--',
                        },

                        {
                          title: 'Reason For Write Off',
                          subtitle: data?.payment?.remarks ? data?.payment?.remarks : '--',
                        },
                      ]
                    : [],
              );
              setAddClientData(() => [
                {
                  title: 'Address To',
                  subtitle: data.name ? data.name : '---',
                },
                {
                  title: 'Designation',
                  subtitle: data.designation ? data.designation : '---',
                },
                {
                  title: 'Billing Address',
                  subtitle:
                    data.billingAddress1 || data.billingAddress2
                      ? `${data.billingAddress1} 
                    ${data.billingAddress2} 
                    ${data.billingState}   
                    ${data.billingCity}-${data.billingPincode}`
                      : '---',
                },
                {
                  title: 'Courier Address',
                  subtitle:
                    data.courierAddress1 || data.courierAddress2
                      ? `${data.courierAddress1} 
                    ${data.courierAddress2} 
                    ${data.courierState}   
                    ${data.courierCity}-${data.courierPincode}`
                      : '---',
                },
                {
                  title: 'GSTN',
                  subtitle: data.gstn ? data.gstn : '---',
                },
                {
                  title: 'Remarks',
                  subtitle: data.remarks ? data.remarks : '---',
                },
                {
                  title: 'Phone Number',
                  subtitle: data.phoneNumber ? `${data.countryCode} ${data.phoneNumber}` : '---',
                },
                {
                  title: 'Email',
                  subtitle: data.email ? data?.email : '---',
                },
              ]);
              setInvoiceId(data?.invoice?.id ? data?.invoice?.id : '');
            }
          }
        })
        .catch((err) => {
          if (err === 'Forbidden resource') {
            toast.error(AccessDenied);
            navigate('/dashboard');
          }
        });
    }
  };
  const handleModalOpen = (title: string) => {
    setModalOpen({ open: true, title });
  };

  useEffect(() => {
    getList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container>
      {Ld && <Loader />}
      <Grid item md={12} sm={12} xs={12}>
        <Typography component={'div'} className='breadcrumbs d-flex-a'>
          <Breadcrumbs aria-label='breadcrumb'>
            <Link underline='hover' href='/'>
              <img src={HomeIcon} className='home' alt='Home' />
            </Link>
            <Typography
              color='text.primary'
              sx={{ cursor: 'pointer' }}
              onClick={() => gotoNavigate(AdminPrivateRoutes.BILLING.BILLINGLIST)}>
              {' '}
              <img src={ArrowIcon} alt='arrow' className='arrows' /> Billing
            </Typography>
            <Typography color='text.primary' sx={{ cursor: 'pointer' }}>
              {' '}
              <img src={ArrowIcon} alt='arrow' className='arrows' /> View Billing
            </Typography>
          </Breadcrumbs>
        </Typography>

        <Typography
          component={'div'}
          className='d-flex-a flex-sb title-add-box'
          sx={{ paddingRight: '56px' }}>
          <Typography className='page-title'>View Billing</Typography>

          <Typography component={'div'} className='d-flex'>
            {[14].includes(roleId) &&
              resumeStatus &&
              resumeStatus?.find((e: any) => e.type === 'invoice')?.status != 'cancelled' && (
                <>
                  {resumeStatus?.find((e: any) => e.type === 'payment')?.status === 'payment' ? (
                    <>
                      <Typography
                        component={'div'}
                        style={{ padding: '12px 10px 12px 10px', width: '147px' }}
                        className='add-btn-apruv mr-1'
                        onClick={(e: any) => {
                          handleClick(e);
                        }}>
                        <Typography component={'div'}>Payment</Typography>
                      </Typography>
                      <Typography component={'div'} className='add-btn-apruv mr-1'>
                        <Typography
                          component={'div'}
                          onClick={() => setCancelInvoiceModalOpen(true)}>
                          Cancel Invoice
                        </Typography>
                      </Typography>
                    </>
                  ) : resumeStatus?.find((e: any) => e.type === 'payment')?.status ===
                    'writeoff' ? (
                    ''
                  ) : (
                    ''
                  )}
                  {resumeStatus &&
                    resumeStatus?.find((e: any) => e.type === 'invoice')?.status === 'invoice' && (
                      <Typography
                        component={'div'}
                        className='add-btn-apruv mr-1'
                        onClick={() => {
                          setInvoiceModalOpen((prev: any) => ({
                            ...prev,
                            open: true,
                            data: [],
                          }));
                        }}>
                        <Typography component={'div'}>Create Invoice</Typography>
                      </Typography>
                    )}
                </>
              )}
            <Typography style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              {location?.state?.isApproval ? (
                <>
                  <span style={{ marginTop: '5px', marginRight: '15px' }}>
                    <img
                      src={EditIcon}
                      alt='Edit'
                      className='pointer'
                      onClick={() => {
                        gotoNavigate(
                          `${AdminPrivateRoutes.BILLING.BILLINGEDIT}?id=${searchParams.get(
                            'id',
                          )}&positionId=${searchParams.get(
                            'positionId',
                          )}&resumeId=${searchParams.get('resumeId')}`,
                        );
                      }}
                    />
                  </span>
                  <Typography
                    component={'div'}
                    sx={{
                      width: '96px',
                      height: '36px',
                      borderRadius: '6px',
                      padding: '12px 10px 10px 10px',
                      border: '1px solid #D0D5DD',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      fontSize: '14px',
                      fontWeight: 500,
                      color: '#344051',
                      cursor: 'pointer',
                    }}
                    onClick={() => gotoNavigate(AdminPrivateRoutes.BILLING.BILLINGAPPROVE)}>
                    Cancel
                  </Typography>
                  <div style={{ width: '16px' }}></div>
                  <Typography
                    component={'div'}
                    sx={{
                      width: '96px',
                      height: '36px',
                      borderRadius: '6px',
                      padding: '12px 10px 10px 10px',
                      border: '1px solid #EE4B22',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      fontSize: '14px',
                      fontWeight: 500,
                      color: '#EE4B22',
                      cursor: 'pointer',
                    }}
                    onClick={() => handleModalOpen('Reject Billing')}>
                    Reject
                  </Typography>
                  <div style={{ width: '16px' }}></div>
                  <Typography
                    component={'div'}
                    className='client-add-btn'
                    onClick={() => handleModalOpen('Approve Billing')}>
                    <Typography>Approve</Typography>
                  </Typography>
                </>
              ) : (
                <Typography component={'div'} className='d-flex-a'>
                  <Button className='s-add-btn'> Download</Button>
                </Typography>
              )}
            </Typography>
          </Typography>
        </Typography>
      </Grid>
      <Grid container>
        <Grid item md={12} sm={12} xs={12}>
          <Typography
            component={'div'}
            className='main-content view-position'
            style={{ padding: '20px', minHeight: '100%' }}>
            <Typography
              component={'div'}
              className='white-box view-position-white-box'
              style={{ minHeight: '100%' }}>
              <div className='section-heading'>Client Information </div>
              <Typography className='bdiveder' />
              <main className='main-content'>
                {clientData.map((dat1: any, idx: number) => {
                  return (
                    <div className='child-items' key={idx}>
                      <div className='heading'>{dat1.title}</div>
                      <div className='sub-heading'>{dat1.subtitle}</div>
                    </div>
                  );
                })}
                <div className='child-items'></div>
                <div className='child-items'></div>
              </main>
            </Typography>
            <Typography
              component={'div'}
              className='white-box view-position-white-box'
              sx={{ minHeight: '100% !important' }}>
              <Typography className='bdiveder' />
              <div className='section-heading'>Candidate Information </div>
              <Typography className='bdiveder' />
              <main className='main-content'>
                {candidateData.map((dat1: any, idx: number) => {
                  return (
                    <div className='child-items' key={idx}>
                      <div className='heading'>{dat1.title}</div>
                      <div className='sub-heading'>{dat1.subtitle}</div>
                    </div>
                  );
                })}
                <div className='child-items'></div>
                <div className='child-items'></div>
              </main>
            </Typography>
            {invoiceData && invoiceData.length > 0 && (
              <Typography
                component={'div'}
                className='white-box view-position-white-box'
                sx={{ minHeight: '100% !important' }}>
                <Typography className='bdiveder' />
                <div className='section-heading'>
                  Invoice Details{' '}
                  {resumeStatus?.find((e: any) => e.type === 'payment')?.status === 'payment' && (
                    <img
                      style={{ height: '15px', width: '15px', marginLeft: '5px' }}
                      src={EditIcon}
                      alt='Edit'
                      className='pointer'
                      onClick={() =>
                        setInvoiceModalOpen((prev: any) => ({
                          ...prev,
                          open: true,
                          data: editInvoiceData,
                        }))
                      }
                    />
                  )}
                </div>
                <Typography className='bdiveder' />
                <main className='main-content'>
                  {invoiceData &&
                    invoiceData?.map((dat1: any, idx: number) => {
                      return (
                        <div className='child-items' key={idx}>
                          <div className='heading'>{dat1?.title}</div>
                          <div className='sub-heading'>{dat1?.subtitle}</div>
                        </div>
                      );
                    })}
                  <div className='child-items'></div>
                  <div className='child-items'></div>
                </main>
              </Typography>
            )}
            {paymentData && paymentData.length > 0 && (
              <Typography
                component={'div'}
                className='white-box view-position-white-box'
                sx={{ minHeight: '100% !important' }}>
                <Typography className='bdiveder' />
                <div className='section-heading'>
                  {resumeStatus?.find((e: any) => e.type === 'payment')?.status === 'created'
                    ? 'Payment Details'
                    : 'Write Off Details'}
                </div>
                <Typography className='bdiveder' />
                <main className='main-content'>
                  {paymentData &&
                    paymentData.map((dat1: any, idx: number) => {
                      return (
                        <div className='child-items' key={idx}>
                          <div className='heading'>{dat1.title}</div>
                          <div className='sub-heading'>{dat1.subtitle}</div>
                        </div>
                      );
                    })}
                  <div className='child-items'></div>
                  <div className='child-items'></div>
                </main>
              </Typography>
            )}
            <Typography
              component={'div'}
              className='white-box view-position-white-box'
              sx={{ minHeight: '100% !important' }}>
              <Typography className='bdiveder' />
              <div className='section-heading'>Additional Client Information</div>
              <Typography className='bdiveder' />
              <main className='main-content'>
                {addClientData.map((dat1: any, idx: number) => {
                  return (
                    <div className='child-items' key={idx}>
                      <div className='heading'>{dat1.title}</div>
                      <div className={dat1.title === 'Email' ? 'email-heading' : 'sub-heading'}>
                        {dat1.subtitle}
                      </div>
                    </div>
                  );
                })}
                <div className='child-items'></div>
                <div className='child-items'></div>
              </main>
            </Typography>
          </Typography>
        </Grid>
      </Grid>

      <Popover
        id={paid}
        anchorEl={panchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        sx={{
          width: '100%',
          padding: '12px',
          '& .MuiPaper-root': {
            border: '1px solid #EE4B22', // Red border
            top: '163px !important',
          },
        }}>
        <Typography component={'div'}>
          <Typography className='payment-menu-item' onClick={() => setPaymentModalOpen(true)}>
            Create Payment
          </Typography>
          <Typography className='payment-menu-item' onClick={() => setWriteOffModalOpen(true)}>
            Write Off
          </Typography>
        </Typography>
      </Popover>
      <ApprovalModal
        open={modalOpen.open}
        title={modalOpen.title}
        setOpen={setModalOpen}
        id={location?.state?.id}
      />
      <CreatePayment
        open={paymentModalOpen}
        setpaymentModalOpen={setPaymentModalOpen}
        setLd={setLd}
        ids={ids}
        getList={getList}
        handleClosePop={handleClose}
      />
      <WriteOff
        open={writeOffModalOpen}
        setWriteOffModalOpen={setWriteOffModalOpen}
        setLd={setLd}
        ids={ids}
        getList={getList}
        handleClosePop={handleClose}
      />
      <CancelInvoice
        open={cancelInvoiceModalOpen}
        setCancelInvoiceModalOpen={setCancelInvoiceModalOpen}
        setLd={setLd}
        ids={invoiceId}
        getList={getList}
      />
      <CreateInvoice
        open={invioceModalOpen.open}
        invoiveData={invioceModalOpen.data}
        setInvoiceModalOpen={setInvoiceModalOpen}
        setLd={setLd}
        ids={ids}
        getList={getList}
      />
    </Grid>
  );
};
export default BillingView;
