/* eslint-disable camelcase */
/* eslint-disable no-irregular-whitespace */
import { Breadcrumbs, Button, Modal, Grid, Link, Typography } from '@mui/material';
import React, { useEffect, useLayoutEffect, useRef } from 'react';
import CustomSelect from 'components/CustomSelect/customSelect';
import { CKEditor } from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import CloseIcon from 'assets/png/close.svg';
import HomeIcon from 'assets/png/dashboardactive.svg';
import ArrowIcon from 'assets/png/breadarrow.svg';
import EyeIcon from 'assets/png/Eye.svg';
import Editpencilsvg from 'assets/png/Editpencil.svg';
// import CopyIcon from 'assets/png/Copy.svg';
import AddIcon from 'assets/png/addplus.svg';
import AddIconcomp from 'assets/iconcomponents/addicon';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import CustomInput from 'components/CustomInput/customInput';
import { addNewData, getAllListData } from 'services/CommonServices';
import { SETTING_ENDPOINT } from 'utils/Constant';
import _ from 'lodash';
import { useAppSelector } from 'store/hooks';
import { yupResolver } from '@hookform/resolvers/yup';
import { AddcategorySchema, EmailAddSchema } from 'utils/ValidatorSchema';
import { Controller, useForm } from 'react-hook-form';
import { AddcategoryType, EmailAddType } from 'types';
import Loader from 'components/loader';
import { defaultText } from 'utils/Config';
import Editor from 'ck-editor-plugin/build/ckeditor';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AccessDenied } from 'utils/helper';

interface Rightinterface {
  Category?: string;
  template?: string;
  subject?: string;
  content?: string;
  extra?: { idx?: any; idx1?: any; fullobj?: { value?: any }; nonitrobj?: any };
}

const EmailTemplate = () => {
  const Righcolcon: Rightinterface = {
    Category: '',
    template: '',
    subject: '',
    content: '',
    extra: {},
  };
  const {
    userInfo: { organizationId: orgdy },
  }: any = useAppSelector((store: any) => store.userLogin);
  const [Ld, setLd] = React.useState(false);
  const navigate = useNavigate();
  // const [firstrndr, setfirstrndr] = React.useState(!false);
  const Categorygref: React.MutableRefObject<any> = useRef();
  const EmailAddref: React.MutableRefObject<any> = useRef();
  const [open, setOpen] = React.useState(false);
  const [ctgopen, setctgopen] = React.useState(false);
  // const [ctginpt, setctginpt] = React.useState('');
  const [Catgitrval, setCatgitrval] = React.useState(1);
  const [Catgitrvalfilters, setCatgitrvalfilters] = React.useState<any>([]);
  const [editorContent, setEditorContent] = React.useState<any>(defaultText);
  const [Emaillis, setEmaillis] = React.useState<any>([]);
  const [TempvalEdit, setTempvalEdit] = React.useState<any>({});
  const [RightContent, setRightContent] = React.useState<Rightinterface>(Righcolcon);
  const [Editstate, setEditstate] = React.useState(false);

  const handleOpenctg = () => setctgopen(true);
  const handleClosectg = () => {
    CatgryresetForm();
    setctgopen(false);
  };

  const {
    control: Catgrycontrol,
    handleSubmit: CatgryhandleSubmit,
    reset: CatgryresetForm,
    // setValue,
    // setError,
    // watch,
    formState: { errors: Catgryerror },
  } = useForm<AddcategoryType>({
    criteriaMode: 'all',
    defaultValues: {
      name: '',
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(AddcategorySchema),
  });
  const {
    control: EmailAddcontrol,
    handleSubmit: EmailAddhandleSubmit,
    reset: EmailAddresetForm,
    setValue: setEmailaddval,
    clearErrors: clearEmailErrors,
    // setError,
    // watch,
    formState: { errors: EmailAdderror },
  } = useForm<EmailAddType>({
    criteriaMode: 'all',
    defaultValues: {
      emailCategoryId: undefined,
      template: undefined,
      subject: undefined,
      content: undefined,
      status: false,
      id: undefined,
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(EmailAddSchema),
  });

  // const [Contentiterator, setContentiterator] = React.useState<
  //   { value?: number; name?: string; fulltemplate?: any }[]
  // >([]);

  const editorConfig = {
    // height:'400px',
    toolbar: {
      items: [
        'heading',
        '|',
        'bold',
        'italic',
        'link',
        'bulletedList',
        'numberedList',
        '|',
        'undo',
        'redo',
      ],
    },
  };

  const [value, setValue] = React.useState('1');
  const [Category, setCategory] = React.useState<
    { name: string; value?: number; template?: any[]; idx?: any }[]
  >([]);

  const handleEditorChange = (event: any, editor: any) => {
    clearEmailErrors('content');
    const data = editor && editor.getData();
    setEditorContent(data);
    setEmailaddval('content', data);
  };

  const handleOpen = () => {
    EmailAddresetForm();
    setOpen(true);
  };
  const handleClose = () => {
    EmailAddresetForm();
    setEditorContent(defaultText);
    setOpen(false);
    setEditstate(!true);
  };

  const getInitialStatus: () => void = () => {
    setLd(!false);
    getAllListData(`${SETTING_ENDPOINT.EmailTemplate}`, true)
      .then((resp: any) => {
        // console.log(resp.data[0]);
        setLd(false);
        if (resp && !_.isEmpty(resp.data)) {
          console.log(resp.data, 'datarsesp');
          const mapd: any = resp.data.map((l1: any, idx: any) => {
            return {
              name: l1.name ? l1.name : '',
              value: l1.id ? l1.id : '',
              template: l1.templates ? l1.templates : [],
              idx: idx + 1,
            };
          });
          console.log(mapd, 'mapd');

          // console.log(Contentiterator, 'continrt44');
          // temporary solution for faster populate
          // if (firstrndr) {
          const araayprocessed: any =
            mapd && mapd[Catgitrval ? Catgitrval - 1 : 0]
              ? mapd[Catgitrval ? Catgitrval - 1 : 0]
              : {};
          // console.log(araayprocessed,'araayprocessed1st');
          // const araayprocessed: any =
          // mapd && !_.isEmpty(mapd) && mapd.find((fl1: any) => fl1.idx === 0);
          const araayprocessed2: any =
            araayprocessed &&
            araayprocessed.template &&
            !_.isEmpty(araayprocessed.template) &&
            araayprocessed.template.map((el1: any) => ({
              name: el1.template ? el1.template : '',
              value: el1.value ? el1.value : '',
              fullobj: el1 ? el1 : {},
              nonitrobj: araayprocessed ? araayprocessed : {},
            }));
          araayprocessed2 && _.isArray(araayprocessed2)
            ? setCatgitrvalfilters(araayprocessed2)
            : setCatgitrvalfilters([]);
          // temporary solution
          setCategory(mapd && !_.isEmpty(mapd) ? mapd : []);
          setEmaillis(
            mapd && !_.isEmpty(mapd)
              ? mapd.map((l1: any) => {
                  return { ...l1, label: l1.name ? l1.name : '' };
                })
              : [],
          );
          // setfirstrndr(false);
          // }
          // console.log(Emaillis, 'Emailismapd');
          setCatgitrval((py: any) => (py === 1 ? 1 : py));
          // setEmaillis([{ label: null, value: null }]);
          setLd(false);
        }
        // setLoader(false);
      })
      .catch(() => {
        setLd(false);
        // console.log(e);
      });
  };

  function Initialvaluefetcher() {
    // Catgitrvalfilters
    const araayprocessed: any =
      Category && !_.isEmpty(Category) && Category.find((fl1: any) => fl1.idx === Catgitrval);
    const araayprocessed2: any =
      araayprocessed &&
      araayprocessed.template &&
      !_.isEmpty(araayprocessed.template) &&
      araayprocessed.template.map((el1: any) => ({
        name: el1.template ? el1.template : '',
        value: el1.value ? el1.value : '',
        fullobj: el1 ? el1 : {},
        nonitrobj: araayprocessed ? araayprocessed : {},
      }));
    araayprocessed2 && _.isArray(araayprocessed2)
      ? setCatgitrvalfilters(araayprocessed2)
      : setCatgitrvalfilters([]);
  }

  useLayoutEffect(() => {
    getInitialStatus();
    Initialvaluefetcher();
  }, []);
  // useEffect(() => {
  //   getInitialStatus();
  // }, []);

  useEffect(() => {
    // getInitialStatus();
    setRightContent(Righcolcon);
    Initialvaluefetcher();
  }, [Catgitrval, open]);

  useEffect(() => {
    !open && getInitialStatus();
  }, [open === false]);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
    setRightContent(Righcolcon);
  };

  const onSubmitFormCatgry = async (data: any) => {
    setLd(!false);
    setEditstate(!true);
    try {
      // const Resu1:any = await  addNewData(
      await addNewData(
        {
          ...data,
          organizationId: orgdy ? orgdy : '',
          status: true,
        },
        `${String(SETTING_ENDPOINT.EmailTemplate)}/category`,
        undefined,
        true,
      );
      getInitialStatus();
      handleClosectg();
      CatgryresetForm();
      // console.log( Resu1,' Resu1');
    } catch (e: any) {
      if (e === 'Forbidden resource') {
        toast.error(AccessDenied);
        navigate('/dashboard');
      }
      // console.log(err);
    } finally {
      setLd(false);
    }
  };

  const EmailaddHandle = async (data: any) => {
    setLd(!false);
    try {
      // await updateData(locationstate.id, data, 'functional-area', true)
      // const Resu1:any = await  addNewData(
      Editstate
        ? await addNewData(
            {
              ...data,
              // organizationId: orgdy ? orgdy : '',
              // id: data.id ? data.id : '',
              status: true,
            },
            `${String(SETTING_ENDPOINT.EmailTemplate)}`,
            undefined,
            true,
          )
        : await addNewData(
            {
              ...data,
              // organizationId: orgdy ? orgdy : '',
              status: true,
            },
            `${String(SETTING_ENDPOINT.EmailTemplate)}`,
            undefined,
            true,
          );
    } catch (err: any) {
      // console.log(err);
    } finally {
      getInitialStatus();
      // setCatgitrval((pli:any) => pli);
      setRightContent(Righcolcon);
      handleClose();
      setLd(false);
      setEditstate(false);
    }
  };

  const EditViewedform: () => void = () => {
    // console.log(TempvalEdit, 'TempvalEdit');
    const arg_id: any =
      TempvalEdit && TempvalEdit.extra && _.isObject(TempvalEdit.extra) && TempvalEdit.extra.idx1
        ? TempvalEdit.extra.idx1!
        : '';
    const arg_emlctgry: any =
      TempvalEdit && TempvalEdit.extra && _.isObject(TempvalEdit.extra) && TempvalEdit.extra.idx
        ? TempvalEdit.extra.idx!
        : '';

    handleOpen();
    setEditstate(true);
    setEmailaddval('content', TempvalEdit && TempvalEdit.content ? TempvalEdit.content : '');
    setEditorContent(TempvalEdit && TempvalEdit.content ? TempvalEdit.content : defaultText);
    setEmailaddval('emailCategoryId', arg_emlctgry);
    setEmailaddval('id', arg_id);
    setEmailaddval('subject', TempvalEdit && TempvalEdit.subject ? TempvalEdit.subject : 'sunio');
    setEmailaddval('template', TempvalEdit && TempvalEdit.template ? TempvalEdit.template : '');
    setEmailaddval('status', true);
  };

  return (
    <Grid container>
      <Grid item md={12} sm={12} xs={12}>
        {Ld && <Loader />}
        <Typography component={'div'} className='breadcrumbs d-flex-a'>
          <Breadcrumbs aria-label='breadcrumb'>
            <Link underline='hover' color='inherit' href='/'>
              <img src={HomeIcon} className='home' alt='Home' />
            </Link>
            <Link underline='hover' color='inherit' href=''>
              <img src={ArrowIcon} alt='arrow' className='arrows' />
              Settings
            </Link>
            <Typography color='text.primary'>
              {' '}
              <img src={ArrowIcon} alt='arrow' className='arrows' />
              Email Templates
            </Typography>
          </Breadcrumbs>
        </Typography>

        <Typography component={'div'} className='d-flex-a flex-sb title-add-box'>
          <Typography className='page-title'>Email Templates</Typography>
          <Button className='add-btn' onClick={handleOpen}>
            <img src={AddIcon} alt='plus' /> Create Email Template
          </Button>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby='modal-modal-title'
            aria-describedby='modal-modal-description'
            disableEnforceFocus={true}>
            <Box className='email-modal'>
              <Typography component={'div'} className='d-flex-a flex-sb mb-30'>
                <Typography className='l-title'>
                  {Editstate ? 'Edit' : 'Create'} Email Template
                </Typography>
                <Typography className='text-right'>
                  <img
                    src={CloseIcon}
                    alt='close'
                    onClick={handleClose}
                    style={{ cursor: 'pointer' }}
                  />
                </Typography>
              </Typography>
              <Typography component={'div'}>
                <form className='private-form' onSubmit={EmailAddhandleSubmit(EmailaddHandle)}>
                  <Grid container>
                    <Grid item md={12} sm={12} xs={12}>
                      <Typography component={'div'} className='custom-field'>
                        <Typography component={'p'}>
                          Category <span>*</span>
                        </Typography>
                        {/* <CustomSelect
                          labelKey={'label'}
                          valueKey={'value'}
                          options={[{}]}
                          name={''}
                          value={''}
                          placeHolder='Category'
                        /> */}
                        <Controller
                          name='emailCategoryId'
                          control={EmailAddcontrol}
                          render={({ field: { onChange, value, name } }) => (
                            // <CustomInput
                            //   value={value}
                            //   name={name}
                            //   placeHolder='Category'
                            //   className='custom-input'
                            //   error={EmailAdderror.emailCategoryId?.message}
                            //   onChange={(e) => {
                            //     onChange(e);
                            //   }}
                            // />
                            <CustomSelect
                              labelKey={'label'}
                              valueKey={'value'}
                              options={Emaillis}
                              name={name}
                              value={value}
                              onChange={(e) => {
                                onChange(e);
                              }}
                              placeHolder='Category'
                              error={EmailAdderror.emailCategoryId?.message}
                            />
                          )}
                        />
                      </Typography>
                    </Grid>
                    <Grid item md={12} sm={12} xs={12}>
                      <Typography component={'div'} className='custom-field'>
                        <Typography component={'p'}>
                          Template Name <span>*</span>
                        </Typography>
                        {/* <CustomSelect
                          labelKey={'label'}
                          valueKey={'value'}
                          options={[{}]}
                          name={''}
                          value={''}
                          placeHolder=''
                        /> */}
                        <Controller
                          render={({ field: { onChange, value, name } }) => (
                            <CustomInput
                              value={value}
                              name={name}
                              placeHolder='Template Name'
                              className='custom-input'
                              error={EmailAdderror.template?.message}
                              onChange={(e) => {
                                onChange(e);
                              }}
                            />
                          )}
                          name='template'
                          control={EmailAddcontrol}
                        />
                      </Typography>
                    </Grid>
                    <Grid item md={12} sm={12} xs={12}>
                      <Typography component={'div'} className='custom-field'>
                        <Typography component={'p'}>
                          Subject <span>*</span>
                        </Typography>
                        {/* <CustomSelect
                          labelKey={'label'}
                          valueKey={'value'}
                          options={[{}]}
                          name={''}
                          value={''}
                          placeHolder='Subject'
                        /> */}
                        <Controller
                          render={({ field: { onChange, value, name } }) => (
                            <CustomInput
                              value={value}
                              name={name}
                              placeHolder='Subject'
                              className='custom-input'
                              error={EmailAdderror.subject?.message}
                              onChange={(e) => {
                                onChange(e);
                              }}
                            />
                          )}
                          name='subject'
                          control={EmailAddcontrol}
                        />
                      </Typography>
                    </Grid>
                    <Grid item md={12} sm={12} xs={12}>
                      <Typography component={'div'} className='custom-field'>
                        <Typography component={'p'}>
                          Content <span>*</span>
                        </Typography>
                        <>
                          <div
                            className={
                              EmailAdderror &&
                              EmailAdderror.content &&
                              EmailAdderror.content.message
                                ? 'ckeditor-config ckeditor-container'
                                : 'ckeditor-container'
                            }>
                            <CKEditor
                              editor={Editor}
                              data={editorContent && editorContent}
                              onChange={handleEditorChange}
                              // config={editorConfig}
                            />
                          </div>
                          {EmailAdderror &&
                            EmailAdderror.content &&
                            EmailAdderror.content.message && (
                              <div className='Custom-error '>
                                {String(EmailAdderror.content.message)}
                              </div>
                            )}
                        </>
                      </Typography>
                    </Grid>
                  </Grid>
                  {/* this is for dum,m,y to trigger form */}
                  <Button
                    type='submit'
                    variant='contained'
                    color='primary'
                    ref={EmailAddref}
                    sx={{ display: 'none' }}>
                    Submit
                  </Button>
                </form>
                <Typography className='d-flex-a flex-end mt-15'>
                  <Button className='l-cancel-btn mr-16' onClick={handleClose}>
                    Cancel
                  </Button>
                  <Button
                    className='l-save-btn'
                    onClick={() => {
                      EmailAddref.current.click();
                    }}>
                    {Editstate ? 'Save' : 'Create'}
                  </Button>
                </Typography>
              </Typography>
            </Box>
          </Modal>
        </Typography>
        <Modal
          open={ctgopen}
          onClose={handleClosectg}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'>
          <Box className='logout-modal' style={{ paddingLeft: '0px', paddingRight: '0px' }}>
            <Typography className='text-right' sx={{ paddingRight: '16px', height: '20px' }}>
              <img
                src={CloseIcon}
                alt='close'
                onClick={handleClosectg}
                style={{ cursor: 'pointer' }}
              />
            </Typography>
            <Typography className='l-title'>Add Category!</Typography>
            <form
              className='private-form'
              id={'branch-add'}
              onSubmit={CatgryhandleSubmit(onSubmitFormCatgry)}>
              <Grid container className='private-form'>
                <Grid item md={12} sm={12} xs={12} sx={{ padding: '25px 36px 40px 44px' }}>
                  <Typography component={'div'} className='custom-field'>
                    <Typography component={'p'} sx={{ marginRight: '10px' }}>
                      Category Name <span>*</span>
                    </Typography>
                    {/* <CustomInput
                    className='custom-input'
                    placeHolder='Category'
                    onChange={(e: any) => {
                      // e.target.value
                      setctginpt(e.target.value);
                    }}
                  /> */}
                    <Controller
                      name='name'
                      control={Catgrycontrol}
                      render={({ field: { onChange, value, name } }) => (
                        <CustomInput
                          value={value}
                          name={name}
                          isTextArea={!true}
                          className='custom-input'
                          placeHolder='Category'
                          error={Catgryerror.name?.message}
                          onChange={(e) => {
                            onChange(e);
                          }}
                        />
                      )}
                    />
                  </Typography>
                </Grid>
              </Grid>
              {/* this is for dum,m,y to trigger form */}
              <Button
                type='submit'
                variant='contained'
                color='primary'
                ref={Categorygref}
                sx={{ display: 'none' }}>
                Submit
              </Button>
            </form>
            <Typography className='d-flex-ja mb-24'>
              <Button
                className='l-cancel-btn mr-16'
                onClick={handleClosectg}
                sx={{ padding: '0px !important' }}>
                Cancel
              </Button>
              <Button
                className='l-save-btn'
                sx={{ height: '36px', padding: '10px 18px' }}
                onClick={() => {
                  Categorygref.current.click();
                }}>
                save
              </Button>
            </Typography>
          </Box>
        </Modal>
        <Typography component={'div'} className='main-content'>
          <Grid container columnSpacing={3.5}>
            <Grid item md={6} sm={12} xs={12}>
              <Typography
                component={'div'}
                className='email-container custom-tab'
                sx={{
                  paddingLeft: '36px',
                  paddingRight: '25px',
                  paddingTop: '17px',
                  width: '100%',
                }}>
                <TabContext value={value}>
                  <Box
                    sx={{
                      width: '100%',
                      display: 'flex',
                      flexFlow: 'row no-wrap',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}>
                    <TabList
                      onChange={handleChange}
                      variant='scrollable'
                      scrollButtons='auto'
                      aria-label='scrollable auto tabs example'
                      sx={{
                        transform:
                          window.innerWidth && window.innerWidth < 1500
                            ? 'translateX(-16px)'
                            : 'translateX(-34px)',
                        overflow: 'hidden',
                        maxWidth: '700px',
                        marginLeft:
                          window.innerWidth && window.innerWidth < 1500
                            ? '6px !important'
                            : '20px !important',
                      }}>
                      {Category && Array.isArray(Category) && Category.length > 0
                        ? Category.map(({ name: labels }: { name: string }, id: any) => {
                            return labels ? (
                              <Tab
                                label={`${labels}`}
                                value={`${id + 1}`}
                                key={id}
                                sx={{ height: '40px', padding: '0px' }}
                                onClick={() => setCatgitrval(id + 1)}
                              />
                            ) : (
                              <div style={{ display: 'none' }}></div>
                            );
                          })
                        : ''}
                    </TabList>
                    {window.innerWidth && window.innerWidth < 1500 ? (
                      <div style={{ flexGrow: 2, flexShrink: 0, flexBasis: '100px' }}></div>
                    ) : (
                      <div style={{ flexGrow: 2, flexShrink: 0, flexBasis: '29px' }}></div>
                    )}
                    <AddIconcomp style={{ flexShrink: '0' }} onClick={handleOpenctg} />
                  </Box>
                  <div className='email-tab-divider'></div>
                  {/* <TabPanel value={`${tty && tty.value ? tty.value : 0}`} > */}
                  <TabPanel value={`${Catgitrval ? Catgitrval : 1}`}>
                    {Catgitrvalfilters && Array.isArray(Catgitrvalfilters) ? (
                      Catgitrvalfilters.map((tty: any, idxn: any) => {
                        return (
                          <Typography component={'div'} className='email-box ' key={idxn}>
                            <Typography component={'div'} className='email-item '>
                              <Grid container>
                                <Grid item md={11} sm={12} xs={12} className='d-flex-a'>
                                  <Typography className='email-name'>
                                    {tty && tty.name ? tty.name : ''}
                                  </Typography>
                                </Grid>
                                <Grid item md={1} sm={12} xs={12}>
                                  <Typography className='email-icons d-flex-a flex-end'>
                                    <img
                                      src={EyeIcon}
                                      alt='view'
                                      style={{ marginRight: '0px' }}
                                      onClick={() => {
                                        setTempvalEdit({
                                          Category:
                                            tty && tty.nonitrobj && tty.nonitrobj.name
                                              ? tty.nonitrobj.name
                                              : '',
                                          template: tty && tty.name ? tty.name : '',
                                          content:
                                            tty && tty.fullobj && tty.fullobj.content
                                              ? tty.fullobj.content
                                              : '',
                                          subject:
                                            tty && tty.fullobj && tty.fullobj.subject
                                              ? tty.fullobj.subject
                                              : '',
                                          extra: {
                                            idx:
                                              tty && tty.nonitrobj && tty.nonitrobj.value
                                                ? tty.nonitrobj.value
                                                : '',
                                            idx1:
                                              tty && tty.fullobj && tty.fullobj.id
                                                ? tty.fullobj.id
                                                : '',
                                            fullobj: tty && tty.fullobj ? tty.fullobj : {},
                                            nonitrobj: tty && tty.nonitrobj ? tty.nonitrobj : {},
                                          },
                                        });
                                        setRightContent({
                                          Category:
                                            tty && tty.nonitrobj && tty.nonitrobj.name
                                              ? tty.nonitrobj.name
                                              : '',
                                          template: tty && tty.name ? tty.name : '',
                                          content:
                                            tty && tty.fullobj && tty.fullobj.content
                                              ? tty.fullobj.content
                                              : '',
                                          subject:
                                            tty && tty.fullobj && tty.fullobj.subject
                                              ? tty.fullobj.subject
                                              : '',
                                          extra: {
                                            idx:
                                              tty && tty.nonitrobj && tty.nonitrobj.value
                                                ? tty.nonitrobj.value
                                                : '',
                                            idx1:
                                              tty && tty.fullobj && tty.fullobj.id
                                                ? tty.fullobj.id
                                                : '',
                                            fullobj: tty && tty.fullobj ? tty.fullobj : {},
                                            nonitrobj: tty && tty.nonitrobj ? tty.nonitrobj : {},
                                          },
                                        });
                                      }}
                                    />
                                    {/* <img src={CopyIcon} alt='copy' /> */}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Typography>
                          </Typography>
                        );
                      })
                    ) : (
                      <></>
                    )}
                  </TabPanel>
                </TabContext>
              </Typography>
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <Typography component={'div'} className='email-container'>
                <Typography component={'div'} sx={{ padding: '32px' }}>
                  <form className='private-form'>
                    <Grid container>
                      <Grid item md={12} sm={12} xs={12} sx={{ marginBottom: '36px' }}>
                        <div
                          style={{
                            width: '100%',
                            cursor: 'pointer',
                            display: 'flex',
                            justifyContent: 'flex-end',
                          }}>
                          {RightContent.Category &&
                          RightContent.template &&
                          RightContent.Category.length > 0 &&
                          RightContent.template.length > 0 ? (
                            <img src={Editpencilsvg} alt='editpencilsvg' onClick={EditViewedform} />
                          ) : (
                            <div style={{ display: 'none' }}></div>
                          )}
                        </div>
                      </Grid>
                      <Grid item md={12} sm={12} xs={12}>
                        <Typography component={'div'} className='custom-field'>
                          <Typography component={'div'} className='f-16-semibold'>
                            {RightContent.Category ? 'Category' : ''}
                          </Typography>
                          <Typography className='f-16'>
                            {RightContent.Category ? RightContent.Category : ''}
                          </Typography>
                        </Typography>
                      </Grid>
                      <Grid item md={12} sm={12} xs={12}>
                        <Typography component={'div'} className='custom-field'>
                          <Typography component={'div'} className='f-16-semibold'>
                            {RightContent.template ? 'Template Name' : ''}
                          </Typography>
                          <Typography className='f-16'>
                            {RightContent.template ? RightContent.template : ''}
                          </Typography>
                        </Typography>
                      </Grid>
                      <Grid item md={12} sm={12} xs={12}>
                        <Typography component={'div'} className='custom-field'>
                          <Typography component={'div'} className='f-16-semibold'>
                            {RightContent.subject ? 'Subject' : ''}
                          </Typography>
                          <Typography className='f-16'>
                            {RightContent.subject ? RightContent.subject : ''}
                          </Typography>
                        </Typography>
                      </Grid>
                      <Grid item md={12} sm={12} xs={12}>
                        <Typography component={'div'} className='custom-field'>
                          <Typography component={'div'} className='f-16-semibold'>
                            {RightContent.content ? 'Content' : ''}
                          </Typography>

                          <Typography className='f-16'>
                            {/* Dear xxx, <br />
                            Thanks for selecting ABC Infotech to pace-up your career. We are glad to
                            inform you that your resume has been shortlisted for an opportunity with
                            us. We shall soon be contacting you for further discussions.
                            <br />
                            In the meanwhile we would suggest you explore other openings and kindly
                            refer your peers if you find their profiles suitable.
                            <br />
                            Incase of any queries feel free to get in touch by writing to us at
                            xxxxxxxxxxx@careertree.com.
                            <br />
                            Regards, Talent Acquisition Group,Careertree.com */}
                            {RightContent.content ? (
                              <div dangerouslySetInnerHTML={{ __html: RightContent.content }} />
                            ) : (
                              ''
                            )}
                          </Typography>
                        </Typography>
                      </Grid>
                    </Grid>
                  </form>

                  <form className='private-form display-none'>
                    <Grid container>
                      <Grid item md={12} sm={12} xs={12}>
                        <Typography component={'div'} className='custom-field'>
                          <Typography component={'p'}>
                            Category <span>*</span>
                          </Typography>
                          <CustomSelect
                            labelKey={'label'}
                            valueKey={'value'}
                            options={[{}]}
                            name={''}
                            value={''}
                            placeHolder='Category'
                          />
                        </Typography>
                      </Grid>
                      <Grid item md={12} sm={12} xs={12}>
                        <Typography component={'div'} className='custom-field'>
                          <Typography component={'p'}>
                            Template Name <span>*</span>
                          </Typography>
                          <CustomSelect
                            labelKey={'label'}
                            valueKey={'value'}
                            options={[{}]}
                            name={''}
                            value={''}
                            placeHolder='Template Name'
                          />
                        </Typography>
                      </Grid>
                      <Grid item md={12} sm={12} xs={12}>
                        <Typography component={'div'} className='custom-field'>
                          <Typography component={'p'}>
                            Subject <span>*</span>
                          </Typography>
                          <CustomSelect
                            labelKey={'label'}
                            valueKey={'value'}
                            options={[{}]}
                            name={''}
                            value={''}
                            placeHolder='Subject'
                          />
                        </Typography>
                      </Grid>
                      <Grid item md={12} sm={12} xs={12}>
                        <Typography component={'div'} className='custom-field'>
                          <Typography component={'p'}>
                            Content <span>*</span>
                          </Typography>
                          <CKEditor editor={Editor} config={editorConfig} />
                        </Typography>
                      </Grid>
                    </Grid>
                  </form>
                  <Typography className='d-flex-a flex-end mt-15 display-none'>
                    <Button className='cancel-btn mr-16'>Cancel</Button>
                    <Button className='s-add-btn'>Edit</Button>
                  </Typography>
                </Typography>
              </Typography>
            </Grid>
          </Grid>
        </Typography>
      </Grid>
    </Grid>
  );
};

export default EmailTemplate;
