/* eslint-disable @typescript-eslint/no-unused-vars */
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Grid,
  Typography,
  Button,
  TextField,
  FormControlLabel,
  Checkbox,
  styled,
} from '@mui/material';
import CustomInput from 'components/CustomInput/customInput';
import React, { useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { addNewData, getSingleData, updateData } from 'services/CommonServices';
import { ResumesENDPOINT } from 'utils/Constant';
import Loader from 'components/loader';
import { resumeExperienceSchema } from 'utils/ValidatorSchema';
import { useNavigate, useSearchParams } from 'react-router-dom';
import AddIcon from 'assets/iconcomponents/addicon';
import Deleteimgicon from 'assets/png/Deleteblack.svg';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { alertOptions } from 'utils/Config';
import moment from 'moment';

const CheckIcon = styled('span')(({ theme }) => ({
  borderRadius: 4,
  width: 16,
  height: 16,
  outline: '1px solid #EE4B22',
  backgroundColor: theme.palette.mode === 'dark' ? '#fff' : '#fff',
  '.Mui-focusVisible &': {
    outline: '1px auto #EE4B22',
    outlineOffset: 1,
  },
  'input:hover ~ &': {
    backgroundColor: theme.palette.mode === 'dark' ? '#ffffff' : '#ffffff',
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background: theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
  },
}));

const CheckedIcon = styled(CheckIcon)({
  backgroundColor: '#EE4B22',
  '&:before': {
    display: 'block',
    width: 16,
    height: 16,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: '#EE4B22',
  },
});

type defaultprops = {
  positionId: string;
  orgId: string;
  clientId: string;
  step: number;
  setStep: (val: any) => void;
  loader: boolean;
  resumeIds: string;
  setLoader: (val: boolean) => void;
  resumeData: (val: number) => void;
};
type experienceForm = {
  companyName: string;
  designation: string;
  fromDate: string;
  toDate: string;
  location: string;
  reportingTo: string;
  specialization: string;
  projectAndCertifications: string;
  vitalInformation: string;
  tillDate: boolean;
  isSaved: boolean;
  rowId?: string;
};
const ResumeExperience: React.FC<defaultprops> = ({
  positionId,
  clientId,
  orgId,
  step,
  setStep,
  resumeIds,
  loader,
  setLoader,
  resumeData,
}) => {
  const [draftCheck, setDraftCheck] = useState(false);
  const currentDate = moment().format('YYYY-MM-DD');
  const [searchParams]: any = useSearchParams();
  const navigate = useNavigate();
  const [resumeId, setResumeId] = useState('');
  const [removeId, setRemoveId] = useState<any>([]);
  const {
    control: control,
    handleSubmit: handleSubmit,
    getValues,
    reset,
    setValue,
    // setError,
    trigger,
    // watch,
    formState: { errors },
  } = useForm<{ experienceData: experienceForm[] }>({
    criteriaMode: 'all',
    defaultValue: {
      experienceData: [
        {
          companyName: '',
          designation: '',
          isSaved: true,
          fromDate: '',
          toDate: '',
          location: '',
          reportingTo: '',
          specialization: '',
          projectAndCertifications: '',
          vitalInformation: '',
          tillDate: false,
          rowId: '',
        },
      ],
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
    // @ts-expect-error ts(2322)
    resolver: yupResolver(resumeExperienceSchema),
  });
  const {
    fields,
    append,
    remove,
    // replace,
  } = useFieldArray({
    control,
    name: 'experienceData',
  });
  const removeSpecialization = (index: number, id: string) => {
    if (id) {
      removeId.push(id);
      setRemoveId([...removeId]);
    }
    remove(index);
  };
  const experienceDraft = () => {
    const filter = getValues('experienceData').map((re: any) => {
      if (re?.isSaved) {
        re['isSaved'] = false;
      }
      delete re.id;
      if (re?.rowId) {
        re['id'] = re.rowId;
      }
      return re;
    });

    const formdata: any = new FormData();
    formdata.append('resumeId', resumeId);
    formdata.append('resumeTabType', 'experience');
    formdata.append('experienceData', JSON.stringify(filter));
    if (removeId.length) {
      formdata.append('removeExperienceIds', JSON.stringify(removeId));
    }

    addNewData(
      formdata,
      `${ResumesENDPOINT.resumeList}`,
      {
        'Content-Type': 'multipart/form-data',
      },
      true,
    )
      .then(() => {
        navigate('/resumes');
        setLoader(false);
      })
      .catch((e) => {
        setLoader(false);
        // console.log(e);
      });
  };
  const back = () => {
    setStep(1);
  };
  const onSubmitForm = (data: any) => {
    setLoader(true);
    const formdata: any = new FormData();
    formdata.append('resumeId', resumeId);
    formdata.append('resumeTabType', 'experience');
    const filter = data.experienceData.map((re: any) => {
      delete re.id;
      if (re?.rowId) {
        re['id'] = re.rowId;
      }
      return re;
    });
    formdata.append('experienceData', JSON.stringify(filter));
    if (removeId.length) {
      formdata.append('removeExperienceIds', JSON.stringify(removeId));
    }
    addNewData(
      formdata,
      `${ResumesENDPOINT.resumeList}`,
      {
        'Content-Type': 'multipart/form-data',
      },
      true,
    )
      .then(() => {
        setStep(3);
        setLoader(false);
        setRemoveId([]);
      })
      .catch((e) => {
        setLoader(false);
        // console.log(e);
      });
  };
  const getInitialStatus = () => {
    if (searchParams.get('id') || resumeIds) {
      setLoader(true);
      getSingleData(searchParams.get('id') || resumeIds, ResumesENDPOINT.resumeList)
        .then((resp: any) => {
          resumeData(resp?.data[0]);
          setResumeId(resp?.data && resp?.data[0]?.id);
          setDraftCheck(resp.data[0].approvalStatus === 'draft' ? true : false);
          // setValue(`experienceData.${0}.tillDate`, resp.data[0]?.personal.isWorking);
          if (resp && !_.isEmpty(resp.data) && !_.isEmpty(resp.data[0]?.experience)) {
            const dat: any = resp.data[0]?.experience;
            setValue('experienceData', resp.data[0]?.experience);
            const finalData: any = [];
            dat.map((ses: any, index: any) =>
              finalData.push({
                companyName: ses.companyName,
                designation: ses.designation,
                isSaved: true,
                fromDate: ses.fromDate,
                toDate: ses.toDate,
                location: ses.location,
                reportingTo: ses.reportingTo,
                specialization: ses.specialization,
                projectAndCertifications: ses.projectAndCertifications,
                vitalInformation: ses.vitalInformation,
                tillDate: ses.tillDate,
                rowId: ses.id,
              }),
            );
            reset({
              experienceData: finalData,
            });
          }
          setLoader(false);
        })
        .catch((e: any) => {
          setLoader(false);
          // console.log(e);
        });
    }
    append({
      companyName: '',
      designation: '',
      isSaved: true,
      fromDate: '',
      toDate: '',
      location: '',
      reportingTo: '',
      specialization: '',
      projectAndCertifications: '',
      vitalInformation: '',
      tillDate: false,
      rowId: '',
    });
  };
  React.useEffect(() => {
    getInitialStatus();
    setResumeId(resumeIds);
  }, [resumeIds]);

  return (
    <Grid container>
      {loader && <Loader />}
      <Grid item md={12} sm={12} xs={12}>
        <Typography component={'div'} className='step-parent-box'>
          <Typography component={'div'} className='d-flex-a flex-sb'>
            <Typography className='stepper-title'>Experience</Typography>
            <Typography className='d-flex-a'>
              <Typography sx={{ marginRight: '20px !important', marginTop: '6px' }}>
                <AddIcon
                  onClick={() =>
                    append({
                      companyName: '',
                      designation: '',
                      isSaved: true,
                      fromDate: '',
                      toDate: '',
                      location: '',
                      reportingTo: '',
                      specialization: '',
                      projectAndCertifications: '',
                      vitalInformation: '',
                      tillDate: false,
                    })
                  }
                />
              </Typography>
              <Button className='cancel-btn mr-16' onClick={back}>
                Back
              </Button>
              <Button
                className='go-to-clnt-list-btn  mr-16'
                disabled={!draftCheck ? true : false}
                sx={{ cursor: !draftCheck ? 'not-allowed' : 'pointer' }}
                onClick={experienceDraft}>
                Draft
              </Button>
              <Button className='s-save-btn' form={'experience-form'} type={'submit'}>
                Save and Continue
              </Button>
            </Typography>
          </Typography>
          <Typography className='bdiveder' />
          <form
            className='private-form'
            id={'experience-form'}
            onSubmit={handleSubmit(onSubmitForm)}>
            {fields && fields.length >= 0
              ? fields.map((item: any, index: number) => {
                  return (
                    <div key={item.id}>
                      {index !== 0 ? (
                        <>
                          <Typography className='bdiveder' />
                          <div
                            className='list-del-add-icon'
                            style={{
                              width: '100%',
                              display: 'flex',
                              justifyContent: 'flex-end',
                              alignItems: 'center',
                            }}>
                            <span
                              style={{ cursor: 'pointer', marginRight: '24px' }}
                              onClick={() => {
                                removeSpecialization(index, item.rowId);
                              }}>
                              <img src={Deleteimgicon} alt='delete' />
                            </span>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                      <Grid container columnSpacing={4.25}>
                        <Grid item md={4} sm={12} xs={12}>
                          <Typography component={'div'} className='custom-field'>
                            <Typography component={'p'}>
                              Company Name <span>*</span>
                            </Typography>
                            <Controller
                              control={control}
                              defaultValue={''}
                              name={`experienceData.${index}.companyName`}
                              render={({ field: { onChange, value, name } }) => {
                                return (
                                  <CustomInput
                                    value={value}
                                    name={name}
                                    className='custom-input'
                                    placeHolder='Enter Company Name'
                                    error={
                                      errors?.experienceData &&
                                      errors?.experienceData?.[index] &&
                                      errors?.experienceData?.[index]?.companyName?.message
                                    }
                                    onChange={(e) => {
                                      onChange(e);
                                    }}
                                  />
                                );
                              }}
                            />
                          </Typography>
                        </Grid>
                        <Grid item md={4} sm={12} xs={12}>
                          <Typography component={'div'} className='custom-field'>
                            <Typography component={'p'}>
                              Designation <span>*</span>
                            </Typography>
                            <Controller
                              control={control}
                              defaultValue={''}
                              name={`experienceData.${index}.designation`}
                              render={({ field: { onChange, value, name } }) => {
                                return (
                                  <CustomInput
                                    value={value}
                                    name={name}
                                    className='custom-input'
                                    placeHolder='Enter Designation'
                                    error={
                                      errors?.experienceData &&
                                      errors?.experienceData?.[index] &&
                                      errors?.experienceData?.[index]?.designation?.message
                                    }
                                    onChange={(e) => {
                                      onChange(e);
                                    }}
                                  />
                                );
                              }}
                            />
                          </Typography>
                        </Grid>
                        <Grid item md={4} sm={12} xs={12}>
                          <Typography
                            component={'div'}
                            className='custom-field'
                            style={{ display: 'flex', gap: '15px' }}>
                            <Grid item md={6} sm={12} xs={12}>
                              <Typography component={'p'}>
                                Employment Period <span>*</span>
                              </Typography>
                              <Controller
                                name={`experienceData.${index}.fromDate`}
                                render={({ field: { onChange, value, name } }) => {
                                  return (
                                    <CustomInput
                                      value={value}
                                      name={name}
                                      className='custom-input'
                                      placeHolder='From'
                                      error={
                                        errors?.experienceData &&
                                        errors?.experienceData?.[index] &&
                                        errors?.experienceData?.[index]?.fromDate?.message
                                      }
                                      onChange={(e) => {
                                        trigger(`experienceData.${index}.fromDate`);
                                        onChange(e);
                                      }}
                                    />
                                  );
                                }}
                                control={control}
                              />
                            </Grid>
                            <Grid item md={6} sm={12} xs={12}>
                              {index === 0 ? (
                                <Typography
                                  component={'p'}
                                  style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                  <Controller
                                    name={`experienceData.${index}.tillDate`}
                                    render={({ field: { onChange, value, name } }) => (
                                      <FormControlLabel
                                        label=''
                                        control={
                                          <Checkbox
                                            className='custom-checkbox'
                                            value={value}
                                            name={name}
                                            checked={getValues(`experienceData.${index}.tillDate`)}
                                            sx={{
                                              '&:hover': { bgcolor: 'transparent' },
                                            }}
                                            color='default'
                                            checkedIcon={<CheckedIcon />}
                                            icon={<CheckIcon />}
                                            onChange={(e) => {
                                              setValue(`experienceData.${index}.toDate`, '');
                                              onChange(e);
                                              trigger(`experienceData.${index}.toDate`);
                                            }}
                                          />
                                        }
                                      />
                                    )}
                                    control={control}
                                  />
                                  Till Date
                                </Typography>
                              ) : (
                                <div style={{ height: '25px' }} />
                              )}
                              <Controller
                                name={`experienceData.${index}.toDate`}
                                render={({ field: { onChange, value, name } }) => {
                                  return (
                                    <CustomInput
                                      value={value}
                                      name={name}
                                      disabled={
                                        getValues(`experienceData.${index}.tillDate`) === true
                                          ? true
                                          : false
                                      }
                                      className='custom-input'
                                      placeHolder='To'
                                      error={
                                        errors?.experienceData &&
                                        errors?.experienceData?.[index] &&
                                        errors?.experienceData?.[index]?.toDate?.message
                                      }
                                      onChange={(e) => {
                                        onChange(e);
                                      }}
                                    />
                                  );
                                }}
                                control={control}
                              />
                            </Grid>
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid container columnSpacing={4.25}>
                        <Grid item md={4} sm={12} xs={12}>
                          <Typography component={'div'} className='custom-field'>
                            <Typography component={'p'}>
                              Specialization / Expertise <span>*</span>
                            </Typography>
                            <Controller
                              control={control}
                              defaultValue={''}
                              name={`experienceData.${index}.specialization`}
                              render={({ field: { onChange, value, name } }) => {
                                return (
                                  <CustomInput
                                    value={value}
                                    name={name}
                                    className='custom-input'
                                    placeHolder='Enter Specialization / Expertise'
                                    error={
                                      errors?.experienceData &&
                                      errors?.experienceData?.[index] &&
                                      errors?.experienceData?.[index]?.specialization?.message
                                    }
                                    onChange={(e) => {
                                      onChange(e);
                                    }}
                                  />
                                );
                              }}
                            />
                          </Typography>
                        </Grid>
                        <Grid item md={8} sm={12} xs={12}>
                          <Typography component={'div'} className='custom-field'>
                            <Typography component={'p'}>
                              Project / Certificate Details (optional)
                            </Typography>
                            <Controller
                              control={control}
                              defaultValue={''}
                              name={`experienceData.${index}.projectAndCertifications`}
                              render={({ field: { onChange, value, name } }) => {
                                return (
                                  <CustomInput
                                    value={value}
                                    name={name}
                                    className='custom-input'
                                    placeHolder='Enter Project / Certificate Details'
                                    error={
                                      errors?.experienceData &&
                                      errors?.experienceData?.[index] &&
                                      errors?.experienceData?.[index]?.projectAndCertifications
                                        ?.message
                                    }
                                    onChange={(e) => {
                                      onChange(e);
                                    }}
                                  />
                                );
                              }}
                            />
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid container columnSpacing={4.25}>
                        <Grid item md={4} sm={12} xs={12}>
                          <Typography component={'div'} className='custom-field'>
                            <Typography component={'p'}>
                              Location <span>*</span>
                            </Typography>
                            <Controller
                              control={control}
                              defaultValue={''}
                              name={`experienceData.${index}.location`}
                              render={({ field: { onChange, value, name } }) => {
                                return (
                                  <CustomInput
                                    value={value}
                                    name={name}
                                    className='custom-input'
                                    placeHolder='Enter Location'
                                    error={
                                      errors?.experienceData &&
                                      errors?.experienceData?.[index] &&
                                      errors?.experienceData?.[index]?.location?.message
                                    }
                                    onChange={(e) => {
                                      onChange(e);
                                    }}
                                  />
                                );
                              }}
                            />
                          </Typography>
                        </Grid>
                        <Grid item md={8} sm={12} xs={12}>
                          <Typography component={'div'} className='custom-field'>
                            <Typography component={'p'}>
                              Other Vital Information(Position Specific)
                            </Typography>
                            <Controller
                              control={control}
                              defaultValue={''}
                              name={`experienceData.${index}.vitalInformation`}
                              render={({ field: { onChange, value, name } }) => {
                                return (
                                  <CustomInput
                                    value={value}
                                    name={name}
                                    className='custom-input'
                                    placeHolder='Enter Vital Information'
                                    error={
                                      errors?.experienceData &&
                                      errors?.experienceData?.[index] &&
                                      errors?.experienceData?.[index]?.vitalInformation?.message
                                    }
                                    onChange={(e) => {
                                      onChange(e);
                                    }}
                                  />
                                );
                              }}
                            />
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid container columnSpacing={4.25}>
                        <Grid item md={4} sm={12} xs={12}>
                          <Typography component={'div'} className='custom-field'>
                            <Typography component={'p'}>
                              Reporting To <span>*</span>
                            </Typography>
                            <Controller
                              control={control}
                              defaultValue={''}
                              name={`experienceData.${index}.reportingTo`}
                              render={({ field: { onChange, value, name } }) => {
                                return (
                                  <CustomInput
                                    value={value}
                                    name={name}
                                    className='custom-input'
                                    placeHolder='Enter Reporting'
                                    error={
                                      errors?.experienceData &&
                                      errors?.experienceData?.[index] &&
                                      errors?.experienceData?.[index]?.reportingTo?.message
                                    }
                                    onChange={(e) => {
                                      onChange(e);
                                    }}
                                  />
                                );
                              }}
                            />
                          </Typography>
                        </Grid>
                      </Grid>
                    </div>
                  );
                })
              : ''}
          </form>
        </Typography>
      </Grid>
    </Grid>
  );
};
export default ResumeExperience;
