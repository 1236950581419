
import React from 'react';
import BackImg from 'assets/png/loginbg.jpg';
import CTLogo from 'assets/png/cthiring_new_logo.png';
import {Button, Grid, TextField, Typography } from '@mui/material';


const ResetLink = () => {
  return (
    <Grid container className="vh-100">
      <Grid item md={6} sm={12} xs={12}   sx={{backgroundImage: `url(${BackImg})`}} className="login-banner" >
        <Typography component={'div'} 
      >
      <Typography className="banner-text">We have the <span>capabilities</span> and <span>experience</span> to navigate you in
      <span>finding solutions</span> you need to move forward</Typography>
        </Typography>
      </Grid>
      <Grid item md={6} sm={12} xs={12} className="login-form">
        <Typography className="w-100 text-right"><img src={CTLogo} alt="Carrier Tree" /></Typography>
          <Typography component={'div'} className="login-box">
            <Typography className="logo-text ">
            Reset Password
            </Typography>
            <Typography className="s-dis">A verification link will be sent to your registered email address.</Typography>
            <form>
            <Typography component={'div'} className="custom-field">
                <Typography component={'p'}>Email</Typography>
                <TextField className="custom-input" id="standard-basic" placeholder="Enter your email" variant="standard" />
              </Typography>
             
              <Button className="submit-btn">Submit</Button>
              <Typography className="forgot-text mt-24 text-center">Back to Sign In</Typography>
            </form>
          </Typography>
       <Typography className="w-100 text-left foot-text">© CT - Hiring 2023</Typography>
      </Grid>
    </Grid>
  );
}

export default ResetLink;
