import React, { Fragment, useEffect, useState } from 'react';
import HomeIcon from 'assets/png/dashboardactive.svg';
import ArrowIcon from 'assets/png/breadarrow.svg';
import {
  Breadcrumbs,
  Button,
  Grid,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import { To, useNavigate, useSearchParams } from 'react-router-dom';
import { AdminPrivateRoutes } from 'routes/routeConstans';
import { addNewData, getAllListData } from 'services/CommonServices';
import { MailboxENDPOINT } from 'utils/Constant';
import Loader from 'components/loader';
import moment from 'moment';
import CustomTagInput from 'components/Taginput/Taginput';
import { Controller, useForm } from 'react-hook-form';
import { MailBoxType } from 'types';
import { MailBoxSchema } from 'utils/ValidatorSchema';
import { yupResolver } from '@hookform/resolvers/yup';
import Wordpng from 'assets/png/Word.png';
import TableTooltip from 'components/Tooltip/Tabletooltip';
import { toast } from 'react-toastify';
import { AccessDenied } from 'utils/helper';

const FileComponentui2 = ({ fileName }: { fileName: string }) => {
  return (
    <Typography component={'div'}>
      <Typography sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography component={'span'} sx={{ marginRight: '.5rem' }}>
          <img src={Wordpng} alt='Wordpng' />
        </Typography>
        <Typography
          component={'span'}
          className='image image-success'
          sx={{ fontSize: '14px', marginTop: '-8px' }}>
          <TableTooltip value={fileName ? fileName : ''} positionfile={true}></TableTooltip>
        </Typography>
      </Typography>
    </Typography>
  );
};

export const MailboxView = () => {
  const [commonState, setcommonstate] = useState<any[]>([]);
  const [editCc, setEditCc] = useState(true);
  const [isLoading, setLoading] = useState(true);
  const navigate = useNavigate();
  const gotoNavigate = (link: To) => {
    navigate(link);
  };

  const [searchParams] = useSearchParams();

  const {
    control,
    formState: { errors },
    handleSubmit,
    // reset,
    setValue,
    // getValues,
  } = useForm<MailBoxType>({
    resolver: yupResolver(MailBoxSchema),
  });

  useEffect(() => {
    getAllListData(`${MailboxENDPOINT.mailboxList}?id=${searchParams.get('id')}`, true)
      .then((val: any) => {
        if (val && val.data && val.data.length > 0) {
          setcommonstate([...val.data]);
        } else {
          setcommonstate([]);
        }
        setLoading(!true);
      })
      .catch((e) => {
        setLoading(!true);
        if (e === 'Forbidden resource') {
          toast.error(AccessDenied);
          navigate('/dashboard');
        }
      });
  }, [searchParams.get('id')]);

  useEffect(() => {
    if (commonState.length) {
      setValue('cc', commonState[0]?.cc ? commonState[0]?.cc?.split(',') : []);
    }
  }, [commonState.length]);

  const onSubmit = (data: MailBoxType) => {
    const obj = {
      cc: data.cc,
    };
    setLoading(true);
    addNewData(obj, `${MailboxENDPOINT.mailboxList}/${searchParams.get('id')}`, {}, true)
      .then(() => {
        gotoNavigate(AdminPrivateRoutes.MAILBOX.MAILBOXLIST);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        if (e === 'Forbidden resource') {
          toast.error(AccessDenied);
          navigate('/dashboard');
        }
      })
      .finally(() => setLoading(false));
  };

  return (
    <Grid container>
      {isLoading && <Loader />}
      <Grid item md={12} sm={12} xs={12}>
        <Typography component={'div'} className='breadcrumbs d-flex-a'>
          <Breadcrumbs aria-label='breadcrumb'>
            <Link underline='hover' href='/'>
              <img src={HomeIcon} className='home' alt='Home' />
            </Link>
            <Typography
              color='text.primary'
              sx={{ cursor: 'pointer' }}
              onClick={() => gotoNavigate(AdminPrivateRoutes.MAILBOX.MAILBOXLIST)}>
              {' '}
              <img src={ArrowIcon} alt='arrow' className='arrows' /> Mail Box
            </Typography>
            <Typography color='text.primary' sx={{ cursor: 'pointer' }}>
              {' '}
              <img src={ArrowIcon} alt='arrow' className='arrows' /> View Sent Item
            </Typography>
          </Breadcrumbs>
        </Typography>

        <Typography
          component={'div'}
          className='d-flex-a flex-sb title-add-box'
          sx={{ paddingRight: '56px' }}>
          <Typography className='page-title'>View Sent Item</Typography>
          <div>
            <Button
              className='cancel-btn mr-16'
              onClick={() => gotoNavigate(AdminPrivateRoutes.MAILBOX.MAILBOXLIST)}>
              Cancel
            </Button>
            <Typography
              component={'div'}
              sx={{ marginRight: '1rem' }}
              className='add-btn-apruv'
              onClick={() => setEditCc(false)}>
              <Typography component={'div'}>Add CC</Typography>
            </Typography>
            <Button className='s-add-btn' form={'mail-form'} type={'submit'}>
              Resend
            </Button>
          </div>
        </Typography>

        <Typography component={'div'} className='main-content'>
          <Typography component={'div'} className='white-box'>
            {commonState.length ? (
              <form id={'mail-form'} className='private-form' onSubmit={handleSubmit(onSubmit)}>
                <TableContainer sx={{ border: '1px solid #E4E7EC', borderRadius: '8px' }}>
                  <Table className='custom-table' aria-label='simple table'>
                    <TableBody>
                      <TableRow
                        sx={{ '&:last-child td, &:last-child th': { border: 0 }, display: 'none' }}>
                        <TableCell></TableCell>
                        <TableCell align='left' sx={{ paddingLeft: '23px !important' }}></TableCell>
                      </TableRow>
                      <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell
                          align='left'
                          sx={{
                            textTransform: 'capitalize',
                            fontWeight: '600',
                            borderRight: '1px solid #E4E7EC',
                            minWidth: '200px',
                          }}>
                          To
                        </TableCell>
                        <TableCell align='left' sx={{ paddingLeft: '23px !important' }}>
                          {commonState[0]?.mailTo}
                        </TableCell>
                      </TableRow>
                      <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell
                          align='left'
                          sx={{
                            textTransform: 'capitalize',
                            fontWeight: '600',
                            borderRight: '1px solid #E4E7EC',
                          }}>
                          Cc
                        </TableCell>
                        <TableCell align='left' sx={{ paddingLeft: '23px !important' }}>
                          <Controller
                            render={({ field: { onChange, value, name } }) => (
                              <>
                                <CustomTagInput
                                  value={value}
                                  name={name}
                                  disabled={editCc}
                                  placeHolder='Add multiple emails separated by enter'
                                  error={errors.cc?.message ? true : false}
                                  onChange={(e: any) => {
                                    onChange(e);
                                  }}
                                />
                                <div className='errorposition'>
                                  {errors.cc ? errors?.cc?.message : ''}
                                </div>
                              </>
                            )}
                            name='cc'
                            control={control}
                          />
                          {/* <CustomTagInput
                          value={commonState[0]?.cc?.split(',') ?? []}
                          name={'cc'}
                          disabled={editCc}
                          // error={errors.technicalSkillsData ? true : false}
                          // onChange={onChange}
                          placeHolder={'Add multiple emails separated by enter'}
                          onChange={() => {}}
                        /> */}
                        </TableCell>
                      </TableRow>
                      <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell
                          align='left'
                          sx={{
                            textTransform: 'capitalize',
                            fontWeight: '600',
                            borderRight: '1px solid #E4E7EC',
                          }}>
                          Subject
                        </TableCell>
                        <TableCell align='left' sx={{ paddingLeft: '23px !important' }}>
                          {commonState[0]?.subject}
                        </TableCell>
                      </TableRow>
                      <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell
                          align='left'
                          sx={{
                            textTransform: 'capitalize',
                            fontWeight: '600',
                            borderRight: '1px solid #E4E7EC',
                          }}>
                          Message
                        </TableCell>
                        <TableCell align='left' sx={{ paddingLeft: '23px !important' }}>
                          <div dangerouslySetInnerHTML={{ __html: commonState[0]?.content }}></div>
                        </TableCell>
                      </TableRow>
                      {commonState[0]?.attachments.length ? (
                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                          <TableCell
                            align='left'
                            sx={{
                              textTransform: 'capitalize',
                              fontWeight: '600',
                              borderRight: '1px solid #E4E7EC',
                            }}>
                            Attachment
                          </TableCell>
                          <TableCell align='left' sx={{ paddingLeft: '23px !important' }}>
                            {commonState[0]?.attachments.length
                              ? commonState[0]?.attachments.map((a: any, i: number) => {
                                  return (
                                    <Fragment key={i}>
                                      <FileComponentui2 fileName={a.fileName} />
                                    </Fragment>
                                  );
                                })
                              : '-'}
                          </TableCell>
                        </TableRow>
                      ) : (
                        <div />
                      )}
                      <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell
                          align='left'
                          sx={{
                            textTransform: 'capitalize',
                            fontWeight: '600',
                            borderRight: '1px solid #E4E7EC',
                          }}>
                          Sent Date
                        </TableCell>
                        <TableCell align='left' sx={{ paddingLeft: '23px !important' }}>
                          {moment(commonState[0]?.createdAt).format('DD-MM-YYYY') ?? '-'}
                        </TableCell>
                      </TableRow>
                      <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell
                          align='left'
                          sx={{
                            textTransform: 'capitalize',
                            fontWeight: '600',
                            borderRight: '1px solid #E4E7EC',
                            minWidth: '200px',
                          }}>
                          Sent By
                        </TableCell>
                        <TableCell
                          align='left'
                          sx={{
                            paddingLeft: '23px !important',
                            borderLeft: '1px solid #e4e7ec !important',
                          }}>
                          {`${commonState[0]?.user?.firstName ?? ' '} ${
                            commonState[0]?.user?.lastName ?? ' '
                          }`}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </form>
            ) : (
              <></>
            )}
          </Typography>
        </Typography>
      </Grid>
    </Grid>
  );
};
