import { PayloadAction, createSlice } from '@reduxjs/toolkit';



export interface Action {
    type:string;
    pay:any[];
  }

const objectSlice = createSlice({
  name: 'object',
  initialState: {},
  reducers: {
    addObject: (state:any, action:PayloadAction<any>) => {
       return action.payload;
    },
    removeObject: (state, action) => {
        return  action.payload;
    },
    // updateObject: (state, action) => {
    //   const { id, data } = action.payload;
    //   state[id] = { ...state[id], ...data };
    // },
  },
});

export const { addObject} = objectSlice.actions;
export default objectSlice.reducer;