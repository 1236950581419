import { Grid, TextField, Typography } from '@mui/material';
import CustomSelect from 'components/CustomSelect/customSelect';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import {
  Control,
  Controller,
  FormState,
  UseFormHandleSubmit,
  UseFormTrigger,
  UseFormWatch,
} from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { getAllListData } from 'services/CommonServices';
import { useAppSelector } from 'store/hooks';
import { BillingFilterType } from 'types';
import { SETTING_ENDPOINT } from 'utils/Constant';

interface BillingFilterFormProps {
  control: Control<BillingFilterType, any>;
  handleSubmit: UseFormHandleSubmit<BillingFilterType, undefined>;
  formState: FormState<BillingFilterType>;
  onSubmit: (data: BillingFilterType) => void;
  watch: UseFormWatch<BillingFilterType>;
  trigger: UseFormTrigger<BillingFilterType>;
  setParams: any;
}

const BillingFilterForm: React.FC<BillingFilterFormProps> = ({
  control,
  handleSubmit,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  formState: { errors },
  onSubmit,
  watch,
  // setParams,
}) => {
  const {
    userInfo: { organizationId: orgdy },
  }: any = useAppSelector((store: any) => store.userLogin);
  // const currentDate = moment().format('YYYY-MM-DD');
  const location: any = useLocation();
  const [interviewFromDate, setInterviewFromDate] = useState<string | null>(null);
  // const [interviewToDate, setInterviewToDate] = useState<string | null>(null);
  const [crmData, setCrmData] = useState<any>([]);

  useEffect(() => {
    getAllListData(
      `${SETTING_ENDPOINT.users}/lite?organizationId=${orgdy}&moduleId=2&screenId=4&crm=true&status=true&sortby=name&order=asc`,
      true,
    )
      .then((res) => {
        const options: any = [];
        res.data.map((e: any) => {
          options.push({ label: `${e.firstName} ${e.lastName}`, value: e.id });
        });
        setCrmData(options);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  return (
    <form className='private-form clientfilter-form' onSubmit={handleSubmit(onSubmit)}>
      <Grid container>
        <Grid item md={12} sm={12} xs={12} sx={{ width: '100%' }}>
          <Grid container sx={{ width: '100%' }}>
            <Grid item md={12} sm={12} xs={12}>
              <Typography component={'div'} className='custom-field'>
                <Typography component={'p'}>Billing From</Typography>
                <Controller
                  control={control}
                  defaultValue={undefined}
                  name='interviewFrom'
                  render={({ field: { onChange, value } }) => {
                    return (
                      <TextField
                        id='date'
                        type='date'
                        className='clientfilter-time-from-to'
                        value={value ? value : null}
                        onChange={(e) => {
                          setInterviewFromDate(moment(e.target.value).format('YYYY-MM-DD'));
                          onChange(e);
                        }}
                        // className={classes.textField}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        // InputProps={{
                        //   inputProps: { max: interviewToDate ?? currentDate },
                        // }}
                      />
                    );
                  }}
                />
              </Typography>
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <Typography component={'div'} className='custom-field'>
                <Typography component={'p'}>Billing Till</Typography>
                <Controller
                  control={control}
                  defaultValue={undefined}
                  name='interviewTo'
                  render={({ field: { onChange, value } }) => {
                    return (
                      <TextField
                        id='date'
                        type='date'
                        className='clientfilter-time-from-to'
                        value={value ? value : null}
                        onChange={(e) => {
                          // setInterviewToDate(moment(e.target.value).format('YYYY-MM-DD'));
                          onChange(e);
                        }}
                        // className={classes.textField}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        InputProps={{
                          inputProps: { min: interviewFromDate },
                        }}
                      />
                    );
                  }}
                />
              </Typography>
            </Grid>
            {location.pathname.includes('approve') && (
              <Grid item md={12} sm={12} xs={12}>
                <Typography component={'div'} className='custom-field'>
                  <Typography component={'p'}>Created By</Typography>
                  <Controller
                    control={control}
                    name='createdBy'
                    defaultValue={undefined}
                    render={({ field: { onChange, value, name } }) => {
                      return (
                        <CustomSelect
                          labelKey={'label'}
                          valueKey={'value'}
                          isClearable={false}
                          options={crmData}
                          name={name}
                          value={value ? value : null}
                          onChange={(e) => {
                            onChange(e);
                          }}
                          placeHolder='Select Created By'
                        />
                      );
                    }}
                  />
                </Typography>
              </Grid>
            )}
          </Grid>
        </Grid>
        <button
          type='submit'
          className='clientfilter-form-btn'
          style={{
            ...(!(watch('interviewFrom') || watch('interviewTo') || watch('createdBy'))
              ? { opacity: '0.5', cursor: 'not-allowed' }
              : {}),
          }}>
          Apply
        </button>
      </Grid>
    </form>
  );
};

export default BillingFilterForm;
