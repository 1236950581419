import React, { useState } from 'react';
import { getSingleData } from 'services/CommonServices';
import { FILE_ENDPOINT } from 'utils/Constant';
import Avatar from 'assets/png/avatarIcon.svg';

type ImageWithAuthProps = {
  id: string;
  className?: any;
  alt?: any;
  styled?: any;
};
const ImageWithAuth = (props: ImageWithAuthProps) => {

  const [imageSrc, setImageSrc] = useState<string>(Avatar);
  React.useEffect(() => {
    getSingleData('', `${FILE_ENDPOINT.files}/${props.id}`, true, {
      responseType: 'blob',
    })
      .then((res: any) => {
        const objectURL = URL.createObjectURL(res);
        setImageSrc(objectURL);
      })
      .catch(() => {});
  }, []);
  return (
    <img
      src={imageSrc}
      alt={props.alt}
      className={props.className}
      style={{ objectFit: 'fill', backgroundColor: '#FDEDE9', ...props.styled }}
    />
  );
};
export default ImageWithAuth;
