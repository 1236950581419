import React, { useEffect, useState } from 'react';
import HomeIcon from 'assets/png/dashboardactive.svg';
import ArrowIcon from 'assets/png/breadarrow.svg';
import {
  Typography,
  Breadcrumbs,
  Button,
  Link,
  Switch,
  Grid,
  Box,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material';
import { useNavigate, To } from 'react-router-dom';
import { AdminPrivateRoutes } from 'routes/routeConstans';
import { SETTING_ENDPOINT } from 'utils/Constant';
import Loader from 'components/loader';
import _ from 'lodash';
import { getAllListData, patchData } from 'services/CommonServices';
import { useAppSelector } from 'store/hooks';
import NCAdedIcon from 'assets/iconcomponents/noclientsadded';
import CloseIcon from 'assets/png/close.svg';
import EditIcon from 'assets/png/edit.svg';
import { toast } from 'react-toastify';
import { AccessDenied } from 'utils/helper';

const LeavePolicy = () => {
  const {
    menuList,
    userInfo: { organizationId: orgdy },
  }: any = useAppSelector((store: any) => store.userLogin);
  const navigate = useNavigate();
  const [loader, setLoader] = useState<any>(false);
  const [leavePolicyData, setLeavePolicyData] = useState<any>([]);
  const [viewLeavePolicyModalOpen, setViewLeavePolicyModalOpen] = React.useState<any>({
    open: false,
    data: {},
  });

  const handleModalClose = () => {
    setViewLeavePolicyModalOpen((prev: any) => ({
      ...prev,
      open: false,
      data: {},
    }));
  };

  const handleModalOpen = (data: any) => {
    setViewLeavePolicyModalOpen((prev: any) => ({
      ...prev,
      open: true,
      data: data,
    }));
  };

  const gotoNavigate = (link: To) => {
    navigate(link);
  };
  const StatusChange = (id: string, status: boolean) => {
    patchData('', { status: status }, `${SETTING_ENDPOINT.leavePolicy.leavePolicyList}/${id}`, true)
      .then(() => {
        getlistData();
      })
      .catch((e: any) => console.log(e));
  };
  const getlistData = () => {
    setLoader(true);
    getAllListData(`${SETTING_ENDPOINT.leavePolicy.leavePolicyList}?organizationId=${orgdy}`, true)
      .then((resp: any) => {
        if (resp && !_.isEmpty(resp.data)) {
          setLeavePolicyData(resp.data);
        }
        setLoader(false);
      })
      .catch((e) => {
        if (e === 'Forbidden resource') {
          toast.error(AccessDenied);
          navigate('/dashboard');
        }
        setLoader(false);
      });
  };
  useEffect(() => {
    getlistData();
  }, []);

  useEffect(() => {
    if (!_.isEmpty(menuList)) {
      const settingData = menuList
        .find((e: any) => e.moduleName === 'Settings')
        .screens.find((a: any) => a.screenName === 'Leave Policy');
      if (!settingData) {
        navigate('/');
      }
    }
  }, [menuList]);
  return (
    <Grid container>
      {loader && <Loader />}
      <Grid item md={12} sm={12} xs={12}>
        <Typography component={'div'} className='breadcrumbs d-flex-a'>
          <Breadcrumbs aria-label='breadcrumb'>
            <Link underline='hover' color='inherit' href='/'>
              <img src={HomeIcon} className='home' alt='Home' />
            </Link>
            <Link underline='hover' color='inherit' href=''>
              <img src={ArrowIcon} alt='arrow' className='arrows' />
              Leave Policy
            </Link>
          </Breadcrumbs>
        </Typography>
        <Typography component={'div'} className='d-flex-a flex-sb title-add-box'>
          <Typography className='page-title'>Leave Policy</Typography>
          <Typography component={'div'} className='d-flex-a'>
            <Button
              className='add-btn'
              onClick={() => gotoNavigate(AdminPrivateRoutes.SETTINGS.LEAVE.LEAVEPOLICY.ADD)}>
              Create Leave Policy
            </Button>
          </Typography>
        </Typography>
        <Grid item md={12} sm={12} xs={12}>
          <Grid container className='main-content'>
            <Grid container className='white-box'>
              {leavePolicyData && leavePolicyData.length ? (
                leavePolicyData.map((dat: any) => (
                  <Grid item md={3} sm={12} xs={12} key={dat} style={{ marginTop: '20px' }}>
                    <div style={{ maxWidth: '348.31px', maxHeight: '230px', marginRight: '30px' }}>
                      <header>
                        <div className='leaveCardHeader'>{dat?.type?.type}</div>
                      </header>
                      <div
                        className='client-card-approve position-apruv-card'
                        style={{ width: '100%', padding: '6px 6.31px 10px 10.82px' }}>
                        <div className='client-card'>
                          <main>
                            <div className='description'>
                              <div className='openings'>
                                <span>Number of Days-</span> <span>{dat?.quota}</span>
                              </div>
                              <div className='openings'>
                                <span>Policy Status</span>{' '}
                                <span>
                                  <Switch
                                    defaultChecked={dat.status}
                                    onChange={(e) => {
                                      StatusChange(dat.id, e.target.checked);
                                    }}
                                    className='custom-switch'
                                  />
                                </span>
                              </div>
                            </div>
                          </main>
                          <div className='divider'></div>
                          <footer>
                            <div className='content-wuth-btn'>
                              <div className='footerdesc'>
                                <div className='footer-leftr'>Created By</div>
                                <div
                                  className='footer-sub'
                                  style={{
                                    textTransform: 'capitalize',
                                  }}>{`${dat?.createdBy?.firstName} ${dat?.createdBy?.lastName}`}</div>
                              </div>{' '}
                              <button className='footer-btn' onClick={() => handleModalOpen(dat)}>
                                View Policy
                              </button>
                            </div>
                          </footer>
                        </div>
                      </div>
                    </div>
                  </Grid>
                ))
              ) : (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    width: '100%',
                    flexDirection: 'column',
                    alignItems: 'center',
                    marginBottom: '50px',
                  }}>
                  <NCAdedIcon />
                  <Typography className='no-client'>No Leave Policy Found</Typography>
                </div>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Modal open={viewLeavePolicyModalOpen.open} onClose={handleModalClose}>
        <Box className='email-modal' sx={{ width: '865px !important', minHeight: '50%' }}>
          <Typography component={'div'} className='d-flex-a flex-sb'>
            <Typography className='l-title'>
              {'Leave Policy Details'}
              <img
                src={EditIcon}
                alt='Edit'
                style={{ cursor: 'pointer', width: '20px', height: '20px', marginLeft: '10px' }}
                onClick={() =>
                  navigate(`/settings/leave/leave-policy/edit/${viewLeavePolicyModalOpen.data.id}`)
                }
              />
            </Typography>
            <Typography className='text-right'>
              <img
                src={CloseIcon}
                alt='close'
                onClick={handleModalClose}
                style={{ cursor: 'pointer' }}
              />
            </Typography>
          </Typography>
          <Grid item md={12} sm={12} xs={12} sx={{ marginTop: '15px' }}>
            <TableContainer component={Paper}>
              <Table className='custom-table' aria-label='simple table'>
                <TableBody>
                  {viewLeavePolicyModalOpen.data && (
                    <>
                      <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell
                          align='left'
                          sx={{ textTransform: 'capitalize', paddingLeft: '80px !important' }}>
                          Leave Policy Name
                        </TableCell>
                        <TableCell align='center' sx={{ textTransform: 'capitalize' }}>
                          {viewLeavePolicyModalOpen.data &&
                          viewLeavePolicyModalOpen.data?.type?.type
                            ? viewLeavePolicyModalOpen.data?.type?.type
                            : '-'}
                        </TableCell>
                      </TableRow>
                      <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell
                          align='left'
                          sx={{ textTransform: 'capitalize', paddingLeft: '80px !important' }}>
                          Number of Days
                        </TableCell>
                        <TableCell align='center' sx={{ textTransform: 'capitalize' }}>
                          {viewLeavePolicyModalOpen?.data && viewLeavePolicyModalOpen.data?.quota
                            ? viewLeavePolicyModalOpen.data?.quota
                            : '-'}
                        </TableCell>
                      </TableRow>
                      <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell
                          align='left'
                          sx={{ textTransform: 'capitalize', paddingLeft: '80px !important' }}>
                          Employee
                        </TableCell>
                        <TableCell align='center' sx={{ textTransform: 'capitalize' }}>
                          {viewLeavePolicyModalOpen?.data &&
                          viewLeavePolicyModalOpen.data?.applicableTo
                            ? viewLeavePolicyModalOpen.data?.applicableTo?.length
                            : '-'}
                        </TableCell>
                      </TableRow>
                      <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell
                          align='left'
                          sx={{ textTransform: 'capitalize', paddingLeft: '80px !important' }}>
                          Carry Forward
                        </TableCell>
                        <TableCell align='center' sx={{ textTransform: 'capitalize' }}>
                          {viewLeavePolicyModalOpen.data
                            ? viewLeavePolicyModalOpen.data?.carryOver === true
                              ? 'Yes'
                              : viewLeavePolicyModalOpen.data?.carryOver === false
                                ? 'No'
                                : '-'
                            : '-'}
                        </TableCell>
                      </TableRow>
                      <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell
                          align='left'
                          sx={{ textTransform: 'capitalize', paddingLeft: '80px !important' }}>
                          Earned Leave
                        </TableCell>
                        <TableCell align='center' sx={{ textTransform: 'capitalize' }}>
                          {viewLeavePolicyModalOpen.data
                            ? viewLeavePolicyModalOpen.data?.paid === true
                              ? 'Yes'
                              : viewLeavePolicyModalOpen.data?.paid === false
                                ? 'No'
                                : '-'
                            : '-'}
                        </TableCell>
                      </TableRow>
                      <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell
                          align='left'
                          sx={{ textTransform: 'capitalize', paddingLeft: '80px !important' }}>
                          Policy Status
                        </TableCell>
                        <TableCell align='center' sx={{ textTransform: 'capitalize' }}>
                          {viewLeavePolicyModalOpen.data
                            ? viewLeavePolicyModalOpen.data?.status === true
                              ? 'Active'
                              : viewLeavePolicyModalOpen.data?.status === false
                                ? 'Inactive'
                                : '-'
                            : '-'}
                        </TableCell>
                      </TableRow>
                      <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell
                          align='left'
                          sx={{ textTransform: 'capitalize', paddingLeft: '80px !important' }}>
                          Description
                        </TableCell>
                        <TableCell align='center' sx={{ textTransform: 'capitalize' }}>
                          {viewLeavePolicyModalOpen.data &&
                          viewLeavePolicyModalOpen.data?.description
                            ? viewLeavePolicyModalOpen.data?.description
                            : '-'}
                        </TableCell>
                      </TableRow>
                    </>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Box>
      </Modal>
    </Grid>
  );
};
export default LeavePolicy;
