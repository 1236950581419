import { TextField, Typography, Grid, Checkbox, styled } from '@mui/material';
import AddIcon from 'assets/iconcomponents/addicon';
import CustomSelect from 'components/CustomSelect/customSelect';
import TableTooltip from 'components/Tooltip/Tabletooltip';
import Deletebucket from 'assets/png/Deletebucket.svg';
import Wordpng from 'assets/png/Word.png';
import Fileupload from 'assets/png/upload-cloud.svg';
import Dropzone from 'react-dropzone';
import DeleteIcon from 'assets/png/Deleteblack.svg';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import React, { useEffect, useState } from 'react';
import { getAllListData } from 'services/CommonServices';
import { ClientsENDPOINT } from 'utils/Constant';
import { ModelComponent } from './modelComponent';
import { yupResolver } from '@hookform/resolvers/yup';
import { DesignationSchema } from 'utils/ValidatorSchema';

const CheckIcon = styled('span')(({ theme }) => ({
  borderRadius: 4,
  width: 16,
  height: 16,
  outline: '1px solid #EE4B22',
  backgroundColor: theme.palette.mode === 'dark' ? '#fff' : '#fff',
  '.Mui-focusVisible &': {
    outline: '1px auto #EE4B22',
    outlineOffset: 1,
  },
  'input:hover ~ &': {
    backgroundColor: theme.palette.mode === 'dark' ? '#ffffff' : '#ffffff',
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background: theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
  },
}));

const CheckedIcon = styled(CheckIcon)({
  backgroundColor: '#EE4B22',
  '&:before': {
    display: 'block',
    width: 16,
    height: 16,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: '#EE4B22',
  },
});

export const ProfessionalFee = ({
  removeExistingDesignation,
  clientName,
  errors,
  control,
  onDrop,
  fileUpload,
  hiddenFileInput,
  watch,
  handledelete,
  getValues,
  setValue,
  removeData,
  setLd,
  handleSubmit,
  onSubmitForm,
}: any) => {
  const [model, setModel] = useState<any>({
    open: false,
    type: '',
    feild: '',
  });

  const {
    control: control1,
    handleSubmit: handleSubmit1,
    setValue: setValue1,
    reset,
    formState: { errors: errors1 },
  } = useForm<any>({
    criteriaMode: 'all',
    defaultValues: {
      name: '',
      type: '',
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(DesignationSchema),
  });

  const [percentage, setPercentage] = useState<any>();
  const [level, setLevel] = useState<any>();
  const [ctcWiseData, setCtcWiseData] = useState<any>();
  const handleOpen = (open: boolean, type: string, tab: string) => {
    setModel((prev: any) => ({
      ...prev,
      open: open,
      type: type,
      tab: tab,
    }));
  };
  const handleClose = () => {
    reset();
    setModel((prev: any) => ({
      ...prev,
      open: false,
      type: '',
    }));
  };
  const {
    fields: fieldsOne,
    append: appendOne,
    // replace: replaceform,
    // update,
    remove: levelRemove,
    // swap,
  } = useFieldArray({
    control,
    name: 'clientDesignations',
  });
  const {
    fields: fieldsTwo,
    append: appendTwo,
    // replace: replaceform,
    // update,
    remove: ctcRemove,
    // swap,
  } = useFieldArray({
    control,
    name: 'ClientDesignationCTC',
  });
  useEffect(() => {
    if (watch(`clientDesignations.${0}.levelWise`)) {
      getValues('clientDesignations')?.map((e: any, i: any) => {
        setValue(`clientDesignations.${i}.levelWise`, true);
      });
    }
    if (watch(`ClientDesignationCTC.${0}.ctcWise`)) {
      getValues('ClientDesignationCTC')?.map((e: any, i: any) => {
        setValue(`ClientDesignationCTC.${i}.ctcWise`, true);
      });
    }
  }, [
    watch(`clientDesignations.${0}.levelWise`),
    watch('clientDesignations'),
    watch(`ClientDesignationCTC.${0}.ctcWise`),
    watch('ClientDesignationCTC'),
  ]);

  const percentageData = () => {
    if (
      watch(`clientDesignations.${0}.levelWise`) ||
      watch(`ClientDesignationCTC.${0}.ctcWise`) ||
      watch('acrossAllLevel')
    ) {
      setLd(true);
      getAllListData(
        `${ClientsENDPOINT.CilentPercentage}?type=${
          watch(`clientDesignations.${0}.levelWise`)
            ? 'levelWise'
            : watch(`ClientDesignationCTC.${0}.ctcWise`)
              ? 'ctcWise'
              : watch('acrossAllLevel')
                ? 'acrossAllLevel'
                : ''
        }&status&order&sortby&skip&take`,
        true,
      )
        .then((res: any) => {
          if (res && res.data && res.data.length) {
            const options: any = [];
            res.data.map((e: any) => {
              options.push({
                label: e.percentage ? `${Math.round(e.percentage)} %` : '',
                value: e.id,
              });
            });
            options.push({
              label: '+ Add Percentage',
              value: 'AddPercentage',
              isDisabled: true,
              isFixed: true,
              isSelectable: false,
              custmdwn: true,
            });
            setPercentage(options);
          }
          setLd(!true);
        })
        .catch(() => {
          // console.log(e);
          setLd(!true);
        });
    } else {
      setPercentage([]);
    }
  };
  const levelData = () => {
    if (watch(`clientDesignations.${0}.levelWise`)) {
      setLd(true);
      getAllListData(`${ClientsENDPOINT.ClientLevel}?status&order&sortby&skip&take`, true)
        .then((res: any) => {
          if (res && res.data && res.data.length) {
            const options: any = [];
            res.data.map((e: any) => {
              options.push({
                label: e.level ? e.level : '',
                value: e.id,
              });
            });
            options.push({
              label: '+ Add Level',
              value: 'AddLevel',
              isDisabled: true,
              isFixed: true,
              isSelectable: false,
              custmdwn: true,
            });
            setLevel(options);
          }
          setLd(!true);
        })
        .catch(() => {
          // console.log(e);
          setLd(!true);
        });
    } else {
      setLevel([]);
    }
  };
  const ctcData = () => {
    if (watch(`ClientDesignationCTC.${0}.ctcWise`)) {
      setLd(true);
      getAllListData(`${ClientsENDPOINT.ClientCTC}?status&order&sortby&skip&take`, true)
        .then((res: any) => {
          if (res && res.data && res.data.length) {
            const options: any = [];
            res.data.map((e: any) => {
              options.push({
                label: e.ctc ? `${Math.round(e.ctc)} LPA` : '',
                value: e.id,
              });
            });
            options.push({
              label: '+ Add CTC',
              value: 'AddLevel',
              isDisabled: true,
              isFixed: true,
              isSelectable: false,
              custmdwn: true,
            });
            setCtcWiseData(options);
          }
          setLd(!true);
        })
        .catch(() => {
          // console.log(e);
          setLd(!true);
        });
    } else {
      setCtcWiseData([]);
    }
  };
  useEffect(() => {
    percentageData();
    levelData();
    ctcData();
  }, [
    watch(`ClientDesignationCTC.${0}.ctcWise`),
    watch(`clientDesignations.${0}.levelWise`),
    watch('acrossAllLevel'),
  ]);

  return (
    <div>
      <form className='private-form' id={'user-add'} onSubmit={handleSubmit(onSubmitForm)}>
        <Typography component={'p'} style={{ fontWeight: 500, display: 'flex' }}>
          Client Name :
          <Typography component={'p'} style={{ fontWeight: 600, marginLeft: '5px' }}>
            {clientName ? clientName : '-'}
          </Typography>
        </Typography>
        <Grid item md={3.3} sm={12} xs={12} className='mt-25'>
          <Typography component={'div'} className='custom-field'>
            <Typography component={'p'}>
              Project Type <span>*</span>
            </Typography>
            <Controller
              control={control}
              defaultValue={''}
              name='projectType'
              render={({ field: { onChange, value, name } }) => {
                return (
                  <CustomSelect
                    labelKey={'label'}
                    valueKey={'value'}
                    options={[
                      { label: 'RPO', value: 'RPO' },
                      { label: 'Non-RPO', value: 'Non-RPO' },
                    ]}
                    name={name}
                    value={value}
                    onChange={(e) => {
                      onChange(e);
                    }}
                    placeHolder='Select'
                    error={errors?.projectType?.message}
                  />
                );
              }}
            />
          </Typography>
        </Grid>
        <Grid container columnSpacing={3}>
          <Grid item md={3.5} sm={12} xs={12}>
            <Typography component={'div'} className='custom-field'>
              <Typography component={'p'}>
                Agreement Start Date <span>*</span>
              </Typography>
              <Controller
                control={control}
                name={'agreementStartDate'}
                render={({ field: { onChange, value, name } }) => {
                  return (
                    <>
                      <TextField
                        value={value}
                        name={name}
                        id='date'
                        type='date'
                        placeholder={'Proposal Date'}
                        className={`clientfilter-time-from-to ${
                          errors.agreementStartDate && errors.agreementStartDate.message
                            ? 'clientfilter-time-from-to-error'
                            : ''
                        }`}
                        onChange={(e) => {
                          onChange(e);
                        }}
                      />
                      {errors &&
                        errors.agreementStartDate &&
                        errors?.agreementStartDate?.message && (
                          <div className='Custom-error '>
                            {String(errors?.agreementStartDate?.message)}
                          </div>
                        )}
                    </>
                  );
                }}
              />
            </Typography>
          </Grid>
          <Grid item md={3.5} sm={12} xs={12}>
            <Typography component={'div'} className='custom-field'>
              <Typography component={'p'}>
                Agreement End Date <span>*</span>
              </Typography>
              <Controller
                control={control}
                name={'agreementEndDate'}
                render={({ field: { onChange, value, name } }) => {
                  return (
                    <>
                      <TextField
                        value={value}
                        name={name}
                        id='date'
                        type='date'
                        placeholder={'Proposal Date'}
                        className={`clientfilter-time-from-to ${
                          errors.agreementEndDate && errors.agreementEndDate.message
                            ? 'clientfilter-time-from-to-error'
                            : ''
                        }`}
                        onChange={(e) => {
                          onChange(e);
                        }}
                      />
                      {errors && errors.agreementEndDate && errors?.agreementEndDate?.message && (
                        <div className='Custom-error '>
                          {String(errors?.agreementEndDate?.message)}
                        </div>
                      )}
                    </>
                  );
                }}
              />
            </Typography>
          </Grid>
        </Grid>
        <Typography component={'p'} className='mt-15'>
          Designation
        </Typography>
        <Grid
          container
          columnSpacing={3}
          sx={{
            display:
              watch(`clientDesignations.${0}.levelWise`) ||
              watch(`ClientDesignationCTC.${0}.ctcWise`)
                ? 'none'
                : '',
          }}>
          <Grid item md={3.5} sm={12} xs={12} sx={{ marginTop: '25px' }}>
            <Typography component={'div'} className='d-flex custom-field'>
              <Controller
                control={control}
                defaultValue={false}
                name='acrossAllLevel'
                render={({ field: { onChange, value, name } }) => {
                  return (
                    <Checkbox
                      className='custom-checkbox'
                      sx={{
                        '&:hover': { bgcolor: 'transparent' },
                      }}
                      disableRipple
                      color='default'
                      checkedIcon={<CheckedIcon />}
                      icon={<CheckIcon />}
                      checked={value}
                      name={name}
                      value={value}
                      onChange={(e) => {
                        if (!e.target.checked) {
                          removeExistingDesignation('professionalFee');
                        }
                        onChange(e);
                      }}
                    />
                  );
                }}
              />
              <Typography component={'p'} style={{ marginLeft: '10px', marginTop: '5px' }}>
                Across all levels
              </Typography>
            </Typography>
          </Grid>
          <Grid item md={3.5} sm={12} xs={12}>
            <Typography component={'div'} className='custom-field'>
              <Typography component={'p'}>Percentage %</Typography>
              <Controller
                control={control}
                defaultValue={''}
                name='allLevelPercentage'
                render={({ field: { onChange, value, name } }) => {
                  const dat =
                    percentage && percentage.length
                      ? percentage
                      : [
                          {
                            label: '+ Add Percentage',
                            value: 'AddPercentage',
                            isDisabled: true,
                            isFixed: true,
                            isSelectable: false,
                            custmdwn: true,
                          },
                        ];
                  return (
                    <CustomSelect
                      labelKey={'label'}
                      valueKey={'value'}
                      options={dat}
                      custmfn={() => {
                        setValue1('type', 'percentage');
                        handleOpen(true, 'acrossAllLevel', 'professtional');
                      }}
                      name={name}
                      value={value}
                      onChange={(e) => {
                        onChange(e);
                      }}
                      placeHolder='Select'
                      error={errors.allLevelPercentage?.message}
                    />
                  );
                }}
              />
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          columnSpacing={3}
          sx={{
            display:
              watch('acrossAllLevel') || watch(`ClientDesignationCTC.${0}.ctcWise`) ? 'none' : '',
          }}>
          {fieldsOne.map((ers: any, idx: number) => {
            return (
              <React.Fragment key={ers.id}>
                <Grid item md={3.5} sm={12} xs={12} className='mt-15'>
                  <Typography
                    component={'div'}
                    className='custom-field d-flex'
                    style={{ marginBottom: '0px' }}>
                    {idx === 0 && (
                      <Controller
                        control={control}
                        defaultValue={false}
                        name={`clientDesignations.${idx}.levelWise`}
                        render={({ field: { onChange, value, name } }) => {
                          return (
                            <Checkbox
                              className='custom-checkbox'
                              sx={{
                                '&:hover': { bgcolor: 'transparent' },
                                marginBottom: '8px',
                              }}
                              disableRipple
                              color='default'
                              checkedIcon={<CheckedIcon />}
                              icon={<CheckIcon />}
                              checked={value}
                              name={name}
                              value={value}
                              onChange={(e) => {
                                if (!e.target.checked) {
                                  removeExistingDesignation('professionalFee');
                                }
                                onChange(e);
                              }}
                            />
                          );
                        }}
                      />
                    )}
                    <Typography component={'p'} style={{ marginLeft: '10px' }}>
                      Level Wise (From)
                    </Typography>
                  </Typography>
                  <Controller
                    control={control}
                    defaultValue={''}
                    name={`clientDesignations.${idx}.levelWiseFrom`}
                    render={({ field: { onChange, value, name } }) => {
                      const dat =
                        level && level.length
                          ? level
                          : [
                              {
                                label: '+ Add Level',
                                value: 'AddLevel',
                                isDisabled: true,
                                isFixed: true,
                                isSelectable: false,
                                custmdwn: true,
                              },
                            ];
                      return (
                        <CustomSelect
                          labelKey={'label'}
                          valueKey={'value'}
                          custmfn={() => {
                            setValue1('type', 'level');
                            handleOpen(true, 'level', 'professtional');
                          }}
                          options={dat}
                          name={name}
                          value={value}
                          onChange={(e) => {
                            onChange(e);
                          }}
                          placeHolder='Select'
                          error={errors?.clientDesignations?.[idx]?.levelWiseFrom?.message}
                        />
                      );
                    }}
                  />
                </Grid>
                <Grid item md={3.5} sm={12} xs={12} className='mt-15'>
                  <Typography component={'div'} className='custom-field'>
                    <Typography component={'p'}>Level Wise (To)</Typography>
                    <Controller
                      control={control}
                      defaultValue={''}
                      name={`clientDesignations.${idx}.levelWiseTo`}
                      render={({ field: { onChange, value, name } }) => {
                        const dat =
                          level && level.length
                            ? level
                            : [
                                {
                                  label: '+ Add Level',
                                  value: 'AddLevel',
                                  isDisabled: true,
                                  isFixed: true,
                                  isSelectable: false,
                                  custmdwn: true,
                                },
                              ];
                        return (
                          <CustomSelect
                            labelKey={'label'}
                            valueKey={'value'}
                            custmfn={() => {
                              setValue1('type', 'level');
                              handleOpen(true, 'level', 'professtional');
                            }}
                            options={dat}
                            name={name}
                            value={value}
                            onChange={(e) => {
                              onChange(e);
                            }}
                            placeHolder='Select'
                            error={errors?.clientDesignations?.[idx]?.levelWiseTo?.message}
                          />
                        );
                      }}
                    />
                  </Typography>
                </Grid>
                <Grid item md={3.5} sm={12} xs={12} className='mt-15'>
                  <Typography component={'div'} className='custom-field'>
                    <Typography component={'p'}>Percentage %</Typography>
                    <Controller
                      control={control}
                      defaultValue={''}
                      name={`clientDesignations.${idx}.levelWisePercentage`}
                      render={({ field: { onChange, value, name } }) => {
                        const dat =
                          percentage && percentage.length
                            ? percentage
                            : [
                                {
                                  label: '+ Add Percentage',
                                  value: 'AddPercentage',
                                  isDisabled: true,
                                  isFixed: true,
                                  isSelectable: false,
                                  custmdwn: true,
                                },
                              ];
                        return (
                          <CustomSelect
                            labelKey={'label'}
                            valueKey={'value'}
                            options={dat}
                            name={name}
                            value={value ? value : ''}
                            custmfn={() => {
                              setValue1('type', 'percentage');
                              handleOpen(true, 'levelWise', 'professtional');
                            }}
                            onChange={(e) => {
                              onChange(e);
                            }}
                            placeHolder='Select'
                            error={errors?.clientDesignations?.[idx]?.levelWisePercentage?.message}
                          />
                        );
                      }}
                    />
                  </Typography>
                </Grid>
                <Grid item md={1.5} sm={12} xs={12} className='mt-15 d-flex-ja'>
                  {watch(`clientDesignations.${0}.levelWise`) && idx === 0 && (
                    <AddIcon
                      onClick={() =>
                        appendOne({
                          levelWise: '',
                          levelWiseFrom: '',
                          levelWiseTo: '',
                          levelWisePercentage: '',
                        })
                      }
                    />
                  )}
                  {idx != 0 && (
                    <img
                      src={DeleteIcon}
                      style={{ marginLeft: '20px', cursor: 'pointer' }}
                      alt='Crossicon'
                      onClick={() => {
                        if (watch(`clientDesignations.${idx}.id`)) {
                          removeData([watch(`clientDesignations.${idx}.id`)], 'professionalFee');
                        }
                        levelRemove(idx);
                      }}
                    />
                  )}
                </Grid>
              </React.Fragment>
            );
          })}
        </Grid>
        <Grid
          container
          columnSpacing={3}
          sx={{
            display:
              watch('acrossAllLevel') || watch(`clientDesignations.${0}.levelWise`) ? 'none' : '',
          }}>
          {fieldsTwo.map((ers: any, idx: number) => {
            return (
              <React.Fragment key={ers.id}>
                <Grid item md={3.5} sm={12} xs={12} className='mt-15'>
                  <Typography
                    component={'div'}
                    className='custom-field d-flex'
                    style={{ marginBottom: '0px' }}>
                    {idx === 0 && (
                      <Controller
                        control={control}
                        defaultValue={false}
                        name={`ClientDesignationCTC.${idx}.ctcWise`}
                        render={({ field: { onChange, value, name } }) => {
                          return (
                            <Checkbox
                              className='custom-checkbox'
                              sx={{
                                '&:hover': { bgcolor: 'transparent' },
                                marginBottom: '8px',
                              }}
                              disableRipple
                              color='default'
                              checkedIcon={<CheckedIcon />}
                              icon={<CheckIcon />}
                              checked={value}
                              name={name}
                              value={value}
                              onChange={(e) => {
                                if (!e.target.checked) {
                                  removeExistingDesignation('professionalFee');
                                }
                                onChange(e);
                              }}
                            />
                          );
                        }}
                      />
                    )}
                    <Typography component={'p'} style={{ marginLeft: '10px' }}>
                      CTC Wise (From)
                    </Typography>
                  </Typography>
                  <Controller
                    control={control}
                    defaultValue={''}
                    name={`ClientDesignationCTC.${idx}.ctcWiseFrom`}
                    render={({ field: { onChange, value, name } }) => {
                      const dat =
                        ctcWiseData && ctcWiseData.length
                          ? ctcWiseData
                          : [
                              {
                                label: '+ Add CTC',
                                value: 'AddPercentage',
                                isDisabled: true,
                                isFixed: true,
                                isSelectable: false,
                                custmdwn: true,
                              },
                            ];
                      return (
                        <CustomSelect
                          labelKey={'label'}
                          valueKey={'value'}
                          custmfn={() => {
                            setValue1('type', 'ctc');
                            handleOpen(true, 'ctc', 'professtional');
                          }}
                          options={dat}
                          name={name}
                          value={value}
                          onChange={(e) => {
                            onChange(e);
                          }}
                          placeHolder='Select'
                          error={errors?.ClientDesignationCTC?.[idx]?.ctcWiseFrom?.message}
                        />
                      );
                    }}
                  />
                </Grid>
                <Grid item md={3.5} sm={12} xs={12} className='mt-15'>
                  <Typography component={'div'} className='custom-field'>
                    <Typography component={'p'}>CTC Wise (To)</Typography>
                    <Controller
                      control={control}
                      defaultValue={''}
                      name={`ClientDesignationCTC.${idx}.ctcWiseTo`}
                      render={({ field: { onChange, value, name } }) => {
                        const dat =
                          ctcWiseData && ctcWiseData.length
                            ? ctcWiseData
                            : [
                                {
                                  label: '+ Add CTC',
                                  value: 'AddPercentage',
                                  isDisabled: true,
                                  isFixed: true,
                                  isSelectable: false,
                                  custmdwn: true,
                                },
                              ];
                        return (
                          <CustomSelect
                            labelKey={'label'}
                            valueKey={'value'}
                            custmfn={() => {
                              setValue1('type', 'ctc');
                              handleOpen(true, 'ctc', 'professtional');
                            }}
                            options={dat}
                            name={name}
                            value={value}
                            onChange={(e) => {
                              onChange(e);
                            }}
                            placeHolder='Select'
                            error={errors?.ClientDesignationCTC?.[idx]?.ctcWiseTo?.message}
                          />
                        );
                      }}
                    />
                  </Typography>
                </Grid>
                <Grid item md={3.5} sm={12} xs={12} className='mt-15'>
                  <Typography component={'div'} className='custom-field'>
                    <Typography component={'p'}>Percentage %</Typography>
                    <Controller
                      control={control}
                      defaultValue={''}
                      name={`ClientDesignationCTC.${idx}.ctcWisePercentage`}
                      render={({ field: { onChange, value, name } }) => {
                        const dat =
                          percentage && percentage.length
                            ? percentage
                            : [
                                {
                                  label: '+ Add Percentage',
                                  value: 'AddPercentage',
                                  isDisabled: true,
                                  isFixed: true,
                                  isSelectable: false,
                                  custmdwn: true,
                                },
                              ];
                        return (
                          <CustomSelect
                            labelKey={'label'}
                            valueKey={'value'}
                            options={dat}
                            custmfn={() => {
                              setValue1('type', 'percentage');
                              handleOpen(true, 'ctcWise', 'professtional');
                            }}
                            name={name}
                            value={value}
                            onChange={(e) => {
                              onChange(e);
                            }}
                            placeHolder='Select'
                            error={errors?.ClientDesignationCTC?.[idx]?.ctcWisePercentage?.message}
                          />
                        );
                      }}
                    />
                  </Typography>
                </Grid>
                <Grid item md={1.5} sm={12} xs={12} className='mt-15 d-flex-ja'>
                  {watch(`ClientDesignationCTC.${0}.ctcWise`) && idx === 0 && (
                    <AddIcon
                      onClick={() =>
                        appendTwo({
                          ctcWise: '',
                          ctcWiseFrom: '',
                          ctcWiseTo: '',
                          ctcWisePercentage: '',
                        })
                      }
                    />
                  )}
                  {idx != 0 && (
                    <img
                      src={DeleteIcon}
                      alt='Crossicon'
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        if (watch(`ClientDesignationCTC.${idx}.id`)) {
                          removeData([watch(`ClientDesignationCTC.${idx}.id`)], 'professionalFee');
                        }
                        ctcRemove(idx);
                      }}
                    />
                  )}
                </Grid>
              </React.Fragment>
            );
          })}
        </Grid>
        <Grid item md={12} sm={12} xs={12}>
          <Typography component={'div'} className='custom-field'>
            <Typography component={'p'}>
              Attach Agreement <span>*</span>
            </Typography>
            <Dropzone onDrop={onDrop}>
              {({ getRootProps, getInputProps }) => {
                return (
                  <div
                    {...getRootProps({
                      className: 'dropzone container container-position pointer',
                      onClick: () => {
                        hiddenFileInput.current?.click();
                      },
                    })}>
                    <div>
                      <input {...getInputProps()} />
                      <p className='img-container'>
                        <img src={Fileupload} alt='file upload' />
                      </p>
                      <div className='fil-uplod-desc'>
                        <p className='first-heading'>
                          <span className='frst-span' role='button'>
                            Click to upload
                          </span>{' '}
                          <span className='drago'>or drag and drop</span>
                        </p>
                        <p className='docx'>DOC, PDF (up to 2MB)</p>
                      </div>
                    </div>
                  </div>
                );
              }}
            </Dropzone>
          </Typography>
          <div>
            {fileUpload && fileUpload.length > 0 ? (
              <Grid item md={12} sm={12} xs={12} style={{ display: 'inherit' }}>
                <Typography component={'div'} className='custom-field file-view-box'>
                  <Typography component={'p'} className='first-children first-green-children'>
                    <div className='image-container'>
                      <span className='image image-success'>
                        <img src={Wordpng} alt='Wordpng' />
                      </span>
                      {/* <span className={success ? 'texted texted-success' : 'texted'}> */}
                      <span className={'texted texted-success'}>
                        <TableTooltip
                          value={
                            fileUpload && fileUpload[0]
                              ? fileUpload[0]?.name || fileUpload[0]?.fileName
                              : fileUpload
                                ? fileUpload.fileName
                                : ''
                          }
                          positionfile={true}></TableTooltip>
                      </span>
                    </div>
                    <div className='Crossicon Crossicon-deletebucket' onClick={handledelete}>
                      <img src={Deletebucket} alt='Crossicon' />
                    </div>
                  </Typography>
                </Typography>
              </Grid>
            ) : (
              ''
            )}
          </div>
        </Grid>
      </form>
      <ModelComponent
        control1={control1}
        errors1={errors1}
        handleSubmit1={handleSubmit1}
        model={model}
        handleClose={handleClose}
        setLd={setLd}
        percentageData={percentageData}
        levelData={levelData}
        ctcData={ctcData}
      />
    </div>
  );
};
