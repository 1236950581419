/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Breadcrumbs,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  TextField,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import HomeIcon from 'assets/png/dashboardactive.svg';
import ArrowIcon from 'assets/png/breadarrow.svg';
import EditIcon from 'assets/png/edit.svg';
import DeleteIcon from 'assets/png/delete.svg';
import AddIcon from 'assets/png/addplus.svg';
import SearchIcon from 'assets/png/nsearch.svg';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import UP from 'assets/png/up.svg';
import DOWN from 'assets/png/down.svg';
import { To, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useAppSelector } from 'store/hooks';
import ConfirmDialog from 'components/ConfirmDialog/ConfirmDialog';
import Paginate from 'components/Pagination/Pagination';
import Loader from 'components/loader';
import { useDebounce } from 'hooks';
// import { toast } from 'react-toastify';
import { AdminPrivateRoutes } from 'routes/routeConstans';
import { getAllListData, deleteData } from 'services/CommonServices';
// import { alertOptions } from 'utils/Config';
import { SETTING_ENDPOINT } from 'utils/Constant';
import ExcelIcon from 'assets/iconcomponents/excelicon';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { AccessDenied } from 'utils/helper';

interface Statetype {
  search?: string | null;
  order?: string | null;
  skip?: number | null;
  sortby?: string | null;
  status?: string | null;
  take?: number | null;
  address?: string | null;
  pincode?: string | null;
  type?: string | null;
}

const Defaultparam = {
  search: null,
  order: 'asc',
  skip: 0,
  sortby: 'name',
  status: null,
  take: 50,
  address: null,
  pincode: null,
  type: null,
};
const Roles = () => {
  const {
    menuList,
    userInfo: { organizationId: orgdy },
  }: any = useAppSelector((store: any) => store.userLogin);
  const navigate = useNavigate();
  const [rolesGetData, setRolesGetData] = useState<any>([]);
  const [pagetotal, setpagetotal] = useState<number>(0);
  const [loader, setLoader] = useState<any>(false);

  const [confirmOpen, setConfirmOpen] = useState<any>({
    show: false,
    id: '',
  });
  const [params, setParams] = useState<Statetype>({ ...Defaultparam });
  const debouncedParams = useDebounce(params, 500);

  const getList = () => {
    setLoader(true);
    const searcharr = [
      `${debouncedParams.search ? `search=${encodeURIComponent(debouncedParams.search)}` : null}`,
      `${debouncedParams.status ? `status=${debouncedParams.status}` : null}`,
      `${debouncedParams.order ? `order=${debouncedParams.order}` : null}`,
      `${debouncedParams.sortby ? `sortby=${debouncedParams.sortby}` : null}`,
      `${
        debouncedParams.skip
          ? `skip=${debouncedParams.skip}`
          : debouncedParams.take
            ? 'skip=0'
            : null
      }`,
      `${debouncedParams.take ? `take=${debouncedParams.take}` : null}`,
    ]
      .filter((ea: any) => ea !== 'null')
      .join('&');

    getAllListData(
      `${SETTING_ENDPOINT.roles}${
        Object.values({ ...params, organizationId: orgdy }).length > 0 && '?'
      }${searcharr}`,
      true,
    )
      .then((res) => {
        setpagetotal(Number(res.total));
        setRolesGetData(res && res.data);
        setLoader(false);
      })
      .catch((e) => {
        if (e === 'Forbidden resource') {
          toast.error(AccessDenied);
          navigate('/dashboard');
        }
        // console.log(e);
        setLoader(false);
      });
  };
  const deleteRole = (id: any) => {
    setLoader(true);
    deleteData(id, `${SETTING_ENDPOINT.roles}`, true)
      .then(() => {
        getList();
        setConfirmOpen((pre: any) => ({
          ...pre,
          show: false,
        }));
        // toast.success('Role deleted successfully', alertOptions);
        setLoader(false);
      })
      .catch((err) => {
        // console.log(err);
        setLoader(false);
      });
  };
  const sortablecliks: (sor: string, ord: string) => void = (sor: string, ord: string) => {
    setParams((prev: any) => ({
      ...prev,
      sortby: sor ? sor : null,
      order: ord ? ord : null,
    }));
  };
  const handlePag = (sk: number, tk: number) => {
    setParams((prev: any) => ({
      ...prev,
      skip: sk,
      take: tk,
    }));
  };

  useEffect(() => {
    getList();
  }, [debouncedParams]);

  return (
    <Grid container>
      {loader && <Loader />}
      <Grid item md={12} sm={12} xs={12}>
        <Typography component={'div'} className='breadcrumbs d-flex-a'>
          <Breadcrumbs aria-label='breadcrumb'>
            <Link underline='hover' color='inherit' href='/'>
              <img src={HomeIcon} className='home' alt='Home' />
            </Link>
            <Link underline='hover' color='inherit' href=''>
              <img src={ArrowIcon} alt='arrow' className='arrows' />
              Settings
            </Link>
            <Typography color='text.primary'>
              {' '}
              <img src={ArrowIcon} alt='arrow' className='arrows' /> Roles
            </Typography>
          </Breadcrumbs>
        </Typography>

        <Typography component={'div'} className='d-flex-a flex-sb title-add-box'>
          <Typography className='page-title'>Roles</Typography>
          <Typography component={'div'} className='d-flex-a'>
            <div className='expot-icon'>
              <span>
                <ExcelIcon
                  url={'roles?type=export&order=asc&sortby=name'}
                  fileName={'Roles'}
                  setLoader={setLoader}
                />
              </span>
            </div>
            {/* <Button
              className='add-btn'
              onClick={() =>
                navigate('/settings/roles/add', {
                  state: {
                    toastType: 'add',
                  },
                })
              }>
              <img src={AddIcon} alt='plus' /> Add Roles
            </Button> */}
          </Typography>
        </Typography>
        <Typography component={'div'} className='main-content'>
          <Typography component={'div'} className='white-box'>
            <TextField
              className='custom-search'
              placeholder='Search'
              id='outlined-start-adornment'
              value={params.search}
              sx={{ width: '320px' }}
              onChange={(e: any) =>
                setParams((prev: any) => ({
                  ...prev,
                  search: e.target.value,
                  skip: 0,
                  take: 50,
                }))
              }
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <img src={SearchIcon} alt='search' />
                  </InputAdornment>
                ),
                endAdornment: params.search ? (
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='toggle password visibility'
                      edge='end'
                      onClick={() => {
                        setParams(() => ({ ...Defaultparam, search: '' }));
                      }}>
                      <CloseIcon style={{ height: '20px', width: '20px' }} />
                    </IconButton>
                  </InputAdornment>
                ) : null,
              }}
            />
            <Typography component={'div'} className='mt-32'>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} className='custom-table' aria-label='simple table'>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        Role{' '}
                        <span className='sorting'>
                          <img
                            src={UP}
                            alt='asc'
                            className='up'
                            onClick={() => sortablecliks('name', 'asc')}
                          />
                          <img
                            src={DOWN}
                            alt='desc'
                            className='down'
                            onClick={() => sortablecliks('name', 'desc')}
                          />
                        </span>
                      </TableCell>
                      <TableCell>
                        User Functional Roles{' '}
                        <span className='sorting'>
                          <img
                            src={UP}
                            alt='asc'
                            className='up'
                            onClick={() => sortablecliks('userFunctionalRoleName', 'asc')}
                          />
                          <img
                            src={DOWN}
                            alt='desc'
                            className='down'
                            onClick={() => sortablecliks('userFunctionalRoleName', 'desc')}
                          />
                        </span>
                      </TableCell>
                      <TableCell>
                        Description{' '}
                        <span className='sorting'>
                          <img
                            src={UP}
                            alt='asc'
                            className='up'
                            onClick={() => sortablecliks('description', 'asc')}
                          />
                          <img
                            src={DOWN}
                            alt='desc'
                            className='down'
                            onClick={() => sortablecliks('description', 'desc')}
                          />
                        </span>
                      </TableCell>
                      <TableCell>
                        Status{' '}
                        <span className='sorting'>
                          <img
                            src={UP}
                            alt='asc'
                            className='up'
                            onClick={() => sortablecliks('status', 'desc')}
                          />
                          <img
                            src={DOWN}
                            alt='desc'
                            className='down'
                            onClick={() => sortablecliks('status', 'asc')}
                          />
                        </span>
                      </TableCell>
                      <TableCell align='center'>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rolesGetData && rolesGetData.length ? (
                      rolesGetData.map((row: any) => (
                        <TableRow
                          key={row?.name}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                          <TableCell sx={{ textTransform: 'capitalize' }}>
                            {row.id === 15 ? 'Administrator' : row?.name ? row.name : '-'}
                          </TableCell>
                          <TableCell sx={{ textTransform: 'capitalize' }}>
                            {row?.userFunctionalRole?.name ? row.userFunctionalRole.name : '-'}
                          </TableCell>
                          <TableCell sx={{ textTransform: 'capitalize' }}>
                            {row?.description ? row.description : '-'}
                          </TableCell>
                          <TableCell>
                            <Typography
                              className={row && row.status ? 'active-badge' : 'inactive-badge'}>
                              {row?.status === true ? 'Active' : 'Inactive'}
                            </Typography>
                          </TableCell>
                          <TableCell align='center'>
                            <img
                              src={EditIcon}
                              alt='Edit'
                              className='pointer'
                              onClick={() =>
                                navigate(`${AdminPrivateRoutes.SETTINGS.ROLE.ROLEEDIT}/${row.id}`, {
                                  state: { toastType: 'edit' },
                                })
                              }
                            />
                            {/* <img
                              src={DeleteIcon}
                              alt='Delete'
                              className='ml-24 pointer'
                              onClick={() =>
                                setConfirmOpen((pre: any) => ({
                                  ...pre,
                                  show: true,
                                  id: row.id,
                                }))
                              }
                            /> */}
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <tr className={'text-center'}>
                        <td colSpan={5} align={'center'}>
                          No Record(s) Found.
                        </td>
                      </tr>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Typography>
            <Grid container className='mt-24'>
              <Grid item md={12} sm={12} xs={12}>
                <Typography component={'div'} className='d-flex-a flex-end pagination'>
                  <Paginate total={pagetotal} setfn={handlePag} DefaultPerPage={50} />
                </Typography>
              </Grid>
            </Grid>
          </Typography>
        </Typography>
      </Grid>
      <ConfirmDialog
        title='Confirm'
        floatingBtnShow={true}
        open={confirmOpen.show}
        close={() =>
          setConfirmOpen({
            show: false,
          })
        }
        visibleClose={true}
        onConfirm={(act: any) =>
          act === 'yes'
            ? deleteRole(confirmOpen.id)
            : setConfirmOpen({
                show: false,
              })
        }
        autoCloseDisable={true}>
        {'Are you sure do you want to delete?'}
      </ConfirmDialog>
    </Grid>
  );
};

export default Roles;
