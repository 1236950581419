import { Box, Button, Divider, Drawer, Grid, Typography } from '@mui/material';
import Close from 'assets/png/Close-drawer.svg';

const ViewDrawer = (props: {
  toggleDrawer: (open: boolean) => void;
  drawerOpen: boolean;
  data: any;
  viewType: string;
  modalOpen: (open: boolean) => void;
  setTitle: (value: string) => void;
}) => {
  const { toggleDrawer, drawerOpen, data, viewType, modalOpen, setTitle } = props;

  return (
    <>
      <Drawer anchor={'right'} open={drawerOpen} onClose={() => toggleDrawer(false)}>
        <Box sx={{ width: '500px', height: '100%', overflowY: 'hidden' }}>
          <Typography sx={{ height: '85%' }}>
            <Typography
              sx={{
                width: '100%',
                height: '96px',
                backgroundColor: '#F2F4F7',
                display: 'flex',
                alignItems: 'center',
                paddingLeft: '32px',
                paddingRight: '25px',
                justifyContent: 'space-between',
              }}>
              <Typography sx={{ fontWeight: '600', fontSize: '20px' }}>
                {viewType === 'approve' ? 'Approve' : 'View'} Leave
              </Typography>
              <Typography
                sx={{
                  width: '20px',
                  height: '20px',
                  cursor: 'pointer',
                }}
                onClick={() => toggleDrawer(false)}>
                <img src={Close} alt='close icon' />
              </Typography>
            </Typography>

            <Grid
              container
              md={12}
              sm={12}
              xs={12}
              sx={{ paddingLeft: '32px', paddingRight: '25px', marginTop: '31px' }}>
              {data?.map(
                (e: any, i: number) =>
                  e.key !== 'status' && (
                    <Grid
                      container
                      md={4}
                      sm={4}
                      xs={4}
                      key={i}
                      sx={{ display: 'flex', flexDirection: 'column' }}>
                      <Typography
                        sx={{
                          fontSize: '14px',
                          fontWeight: 600,
                          color: '#344051',
                          marginBottom: '4px',
                        }}>
                        {e.key}
                      </Typography>
                      <Typography
                        sx={{
                          marginBottom: '50px',
                          fontSize: '14px !important',
                          fontWeight: 400,
                          color: '#637083',
                          textTransform: 'capitalize',
                        }}>
                        {e.value}
                      </Typography>
                    </Grid>
                  ),
              )}
            </Grid>
          </Typography>
          {data?.map((leaveStatus: any, index: number) =>
            ['pending', 'approved'].includes(leaveStatus.value) && leaveStatus.key === 'status' ? (
              <Typography sx={{ height: '15%' }} key={index}>
                <Divider sx={{ marginTop: '16px' }} />

                <Typography className='d-flex-a flex-end mt-30' sx={{ paddingRight: '30px' }}>
                  {viewType === 'approve' ? (
                    <>
                      <Button className='s-cancel-btn mr-16' onClick={() => toggleDrawer(false)}>
                        Cancel
                      </Button>

                      <Button
                        className='resume-ignore-btn mr-16'
                        onClick={() => (modalOpen(true), setTitle('Reject'))}>
                        Reject
                      </Button>

                      <Button
                        className='resume-save-btn'
                        onClick={() => (modalOpen(true), setTitle('Approve'))}>
                        Approve
                      </Button>
                    </>
                  ) : viewType === 'view' ? (
                    <Button
                      className='resume-save-btn'
                      onClick={() => (modalOpen(true), setTitle('Cancel'))}>
                      Cancel Leave
                    </Button>
                  ) : (
                    <></>
                  )}
                </Typography>
              </Typography>
            ) : (
              ''
            ),
          )}
        </Box>
      </Drawer>
    </>
  );
};

export default ViewDrawer;
