import { Modal, Typography, Grid, Button, Box } from '@mui/material';
import CustomInput from 'components/CustomInput/customInput';
import CloseIcon from 'assets/png/close.svg';
import { Controller } from 'react-hook-form';
import { ClientsENDPOINT } from 'utils/Constant';
import { addNewData } from 'services/CommonServices';

export const ModelComponent = ({
  control1,
  errors1,
  handleSubmit1,
  model,
  handleClose,
  setLd,
  percentageData,
  levelData,
  ctcData,
}: any) => {
  const onSubmitForm1 = (data: any) => {
    setLd(true);
    const days = {
      type: model.type,
      days: data.name,
      status: true,
    };
    const percentage = {
      type: model.type,
      percentage: data.name,
      status: true,
    };
    const level = {
      level: data.name,
      status: true,
    };
    const ctc = {
      ctc: data.name,
      status: true,
    };

    addNewData(
      model.type === 'level'
        ? level
        : model.type === 'ctc'
          ? ctc
          : model.tab === 'professtional' &&
              (model.type === 'levelWise' ||
                model.type === 'ctcWise' ||
                model.type === 'acrossAllLevel')
            ? percentage
            : days,
      model.type === 'level'
        ? `${ClientsENDPOINT.ClientLevel}`
        : model.type === 'ctc'
          ? `${ClientsENDPOINT.ClientCTC}`
          : model.tab === 'professtional' &&
              (model.type === 'levelWise' ||
                model.type === 'ctcWise' ||
                model.type === 'acrossAllLevel')
            ? `${ClientsENDPOINT.CilentPercentage}`
            : `${ClientsENDPOINT.ClientDays}`,
      {},
      true,
    )
      .then(() => {
        setLd(false);
        percentageData();
        levelData();
        ctcData();
        handleClose();
      })
      .catch((e) => {
        setLd(false);
        console.log(e);
      });
  };
  return (
    <Modal
      sx={{ zIndex: 11000 }}
      open={model.open}
      onClose={handleClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'>
      <Box className='Degree-modal' sx={{ width: '40%' }}>
        <Typography className='text-right'>
          <img src={CloseIcon} alt='close' style={{ cursor: 'pointer' }} onClick={handleClose} />
        </Typography>
        <div style={{ padding: '15px 70px 30px 70px' }}>
          <Typography className='l-title'>
            Add New{' '}
            {model.type === 'level'
              ? 'Level'
              : model.type === 'ctc'
                ? 'CTC'
                : model.tab === 'professtional' &&
                    (model.type === 'levelWise' ||
                      model.type === 'ctcWise' ||
                      model.type === 'acrossAllLevel')
                  ? 'Percentage'
                  : 'Days'}
            !
          </Typography>
          <Grid container>
            <Grid item md={12} sm={12} xs={12}>
              <Typography component={'div'} className='custom-field'>
                <form
                  className='private-form'
                  id={'option-add'}
                  onSubmit={handleSubmit1(onSubmitForm1)}>
                  <div style={{ height: '10px' }}></div>
                  <Grid container>
                    <Grid item md={12} sm={12} xs={12}>
                      <Grid container>
                        <Grid item md={12} sm={12} xs={12}>
                          <Grid container>
                            <Grid item md={12} sm={12} xs={12}>
                              <Typography component={'div'} className='custom-field'>
                                <Typography component={'p'}>
                                  {model.type === 'level'
                                    ? 'New Level'
                                    : model.type === 'ctc'
                                      ? 'New CTC'
                                      : model.tab === 'professtional' &&
                                          (model.type === 'levelWise' ||
                                            model.type === 'ctcWise' ||
                                            model.type === 'acrossAllLevel')
                                        ? 'New Percentage'
                                        : 'New Days'}
                                  <span>*</span>
                                </Typography>
                                <Controller
                                  control={control1}
                                  defaultValue={undefined}
                                  name='name'
                                  render={({ field: { onChange, value, name } }) => {
                                    return (
                                      <CustomInput
                                        value={value}
                                        name={name}
                                        className='custom-input'
                                        placeHolder={
                                          model.type === 'level'
                                            ? 'New Level'
                                            : model.type === 'ctc'
                                              ? 'New CTC'
                                              : model.tab === 'professtional' &&
                                                  (model.type === 'levelWise' ||
                                                    model.type === 'ctcWise' ||
                                                    model.type === 'acrossAllLevel')
                                                ? 'New Percentage'
                                                : 'New Days'
                                        }
                                        error={errors1.name?.message}
                                        onChange={(e) => {
                                          onChange(e);
                                        }}
                                      />
                                    );
                                  }}
                                />
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </form>
              </Typography>
            </Grid>
          </Grid>
          <Typography className='d-flex-ja mt-15'>
            <Button className='l-cancel-btn mr-16' onClick={handleClose}>
              Cancel
            </Button>
            <Button className='l-save-btn' type='submit' form={'option-add'}>
              save
            </Button>
          </Typography>
        </div>
      </Box>
    </Modal>
  );
};
