/* eslint-disable @typescript-eslint/no-unused-vars */
import BackImg from 'assets/png/interior.png';
import CTLogo from 'assets/png/cthiring_new_logo.png';
import { Button, Grid, Typography } from '@mui/material';
import { otp } from 'utils/ValidatorSchema';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import CustomInput from 'components/CustomInput/customInput';
import { MASTER_ENDPOINT } from 'utils/Constant';
import { addNewData } from 'services/CommonServices';
import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Loader from 'components/loader';
import { jwtDecode } from 'jwt-decode';
import { USER_LOGIN_SUCCESS } from 'store/types/UserTypes';
import { refreshToken } from 'store/actions/UserAction';
import Storage from 'utils/Storage';
import { useDispatch } from 'react-redux';

const Otp = () => {
  const dispatch = useDispatch();
  const [countDown, setCountDown] = useState(
    Number(sessionStorage.getItem('otpTimeCounter')) || 300,
  );
  const location = useLocation();
  const [loader, setLoader] = useState(false);

  const handleRequestCode = async () => {
    setCountDown(300);
  };
  const {
    control: control,
    handleSubmit: handleSubmit,
    reset,
    formState: { errors },
  } = useForm<any>({
    criteriaMode: 'all',
    defaultValues: {
      otp: '',
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(otp),
  });

  const onSubmit = (data: any) => {
    setLoader(true);
    addNewData(
      {
        email: location.state.email,
        otp: Number(data.otp),
        isExpiry: false,
      },
      `${String(MASTER_ENDPOINT.verifyOtp)}`,
      {},
      true,
    )
      .then((response) => {
        // toast.success('OTP verfied successfully', alertOptions);
        // navigate('/login');
        const { accessToken, refreshToken: refreshToken1 } = response;
        const userData: any = jwtDecode(String(accessToken));
        userData['token'] = accessToken;
        userData['refreshToken'] = refreshToken1;
        dispatch({
          payload: userData,
          type: USER_LOGIN_SUCCESS,
        });

        if (accessToken && refreshToken(refreshToken1) && userData?.id) {
          const tokenData: any = {
            refreshToken: refreshToken1,
            token: accessToken,
            userId: userData.id,
          };
          Storage.setItem(Storage.KEYS.AUTH_TOKEN, JSON.stringify(tokenData));
          sessionStorage.removeItem('otpTimeCounter');
        }

        setLoader(false);
      })
      .catch((e: any) => {
        setLoader(false);
        // console.log(e);
        // toast.error(String('Invalid OTPs'), alertOptions);
      });
  };
  const reSend = () => {
    reset();
    addNewData(
      {
        email: location.state.email,
        resendType: true,
      },
      `${String(MASTER_ENDPOINT.generateOtp)}`,
      '',
      true,
    );
  };

  const formatTime = (timeInSeconds: any) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = timeInSeconds % 60;
    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  useEffect(() => {
    // Exit early if countdown is finished
    if (countDown <= 0) return;

    // Set up the timer
    const timer = setInterval(() => {
      setCountDown((prevSeconds) => {
        sessionStorage.setItem('otpTimeCounter', String(prevSeconds - 1));
        return prevSeconds - 1;
      });
    }, 1000);

    // Clean up the timer when the component unmounts or seconds reach 0
    return () => clearInterval(timer);
  }, [countDown]);

  return (
    <Typography
      component={'div'}
      sx={{ backgroundImage: `url(${BackImg})` }}
      className='login-banner'>
      {loader && <Loader />}
      <Grid container className='px-40'>
        <Grid item md={6} sm={12} xs={12} className=''>
          <Typography component={'div'} className='pl-15'>
            <Typography className='mb-36 mt-5r'>
              <img src={CTLogo} alt='Carrier Tree' />
            </Typography>
            <Typography className='banner-text'>
              We have the <span>capabilities</span> and <span>experience</span> to navigate you in
              <span>finding solutions</span> you need to move forward
            </Typography>
          </Typography>
        </Grid>
        <Grid item md={6} sm={12} xs={12}>
          <Typography
            component={'div'}
            className='login-form d-flex-ja'
            style={{ padding: '60px 0px 0px 0px', width: '470px', minHeight: '500px' }}>
            <Typography component={'div'} className='login-box for-log-box'>
              <Typography className='mb-36'>
                <img src={CTLogo} alt='Carrier Tree' style={{ width: '142px', height: '22px' }} />
              </Typography>
              <form id={'otp-form'} onSubmit={handleSubmit(onSubmit)}>
                {/* <Typography className='otp-text'>Enter Code</Typography> */}
                <Typography className='for-dis'>
                  Please Enter the code, which is sent to your Email id.
                </Typography>
                {/* <Typography className='for-dis'>
                  We texted your email {location.state.email}. Please enter the code to sign in.
                </Typography> */}
                <Typography component={'div'} className='custom-field mt-20'>
                  <Typography component={'p'} sx={{ display: 'flex' }}>
                    Code <span style={{ color: 'red' }}>*</span>
                  </Typography>
                  <Controller
                    control={control}
                    name='otp'
                    render={({ field: { onChange, value, name } }) => {
                      return (
                        <CustomInput
                          value={value}
                          name={name}
                          maxLength={6}
                          className='custom-input'
                          placeHolder='Enter the OTP'
                          error={errors.otp?.message}
                          onChange={(e) => {
                            onChange(e.target.value.replace(/[^0-9]/g, ''));
                          }}
                          autoFocus={true}
                        />
                      );
                    }}
                  />
                </Typography>
                {countDown === 0 ? (
                  <Typography component={'div'} className='for-dis pointer ml-10' onClick={reSend}>
                    <Typography component={'p'} onClick={handleRequestCode}>
                      <div style={{ color: '#ee4b22' }}>Resend OTP</div>
                    </Typography>
                  </Typography>
                ) : (
                  <Typography component={'p'} className='for-dis ml-10'>
                    {formatTime(countDown)}
                  </Typography>
                )}
              </form>
              <Typography className='d-flex-ja' flexDirection='column'>
                <Button className='submit-btn' type='submit' form='otp-form'>
                  Submit
                </Button>
              </Typography>
            </Typography>
            {/* <Typography className="w-100 text-left foot-text">© CT - Hiring 2023</Typography> */}
          </Typography>
        </Grid>
      </Grid>
    </Typography>
  );
};

export default Otp;
