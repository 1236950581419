import { Box, Modal, Typography, Grid, Button } from '@mui/material';
import CloseIcon from 'assets/png/close.svg';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import { CancelInvoiceSchema } from 'utils/ValidatorSchema';
import CustomInput from 'components/CustomInput/customInput';
import { BillingENDPOINT } from 'utils/Constant';
import { patchData } from 'services/CommonServices';

export const CancelInvoice = ({ open, setCancelInvoiceModalOpen, ids, setLd, getList }: any) => {
  const {
    control: control,
    handleSubmit,
    // setValue,
    // getValues,
    reset,
    formState: { errors },
  } = useForm<any>({
    criteriaMode: 'all',
    defaultValues: {
      remarks: '',
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(CancelInvoiceSchema),
  });
  const handleClose = () => {
    setCancelInvoiceModalOpen(false);
    reset();
  };
  const onSubmitForm = (data: any) => {
    const obj: any = {
      remarks: data.remarks,
    };
    patchData(ids, obj, `${BillingENDPOINT.cancelInvoice}`, true)
      .then(() => {
        handleClose();
        setLd(false);
        getList();
      })
      .catch((e: any) => {
        setLd(false);
        console.log(e);
      });
  };
  return (
    <Modal
      sx={{ zIndex: 11000 }}
      open={open}
      onClose={handleClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'>
      <Box className='email-modal' sx={{ width: '762 !important', minHeight: '300px' }}>
        <Typography className='d-flex flex-sb '>
          <Typography className='l-title'>Cancel Invoice</Typography>
          <img src={CloseIcon} alt='close' style={{ cursor: 'pointer' }} onClick={handleClose} />
        </Typography>
        <div>
          <Grid container>
            <Grid item md={12} sm={12} xs={12}>
              <form
                className='private-form'
                id={'canceInvoice-add'}
                onSubmit={handleSubmit(onSubmitForm)}>
                <div style={{ height: '50px' }}></div>
                <Grid container>
                  <Grid item md={12} sm={12} xs={12}>
                    <Grid container columnSpacing={3}>
                      <Grid item md={12} sm={12} xs={12}>
                        <Typography component={'div'} className='custom-field'>
                          <Typography component={'p'}>
                            Remarks<span>*</span>
                          </Typography>
                          <Controller
                            control={control}
                            defaultValue={undefined}
                            name='remarks'
                            render={({ field: { onChange, value, name } }) => {
                              return (
                                <CustomInput
                                  value={value}
                                  name={name}
                                  isTextArea={true}
                                  className='custom-input'
                                  placeHolder='Remarks'
                                  error={errors.remarks?.message}
                                  onChange={(e) => {
                                    onChange(e);
                                  }}
                                />
                              );
                            }}
                          />
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            </Grid>
          </Grid>
          <Typography className='bdiveder' />
          <Typography className='d-flex flex-end  mt-25'>
            <Button className='l-cancel-btn mr-16' onClick={handleClose}>
              Cancel
            </Button>
            <Button className='l-save-btn' type='submit' form={'canceInvoice-add'}>
              Submit
            </Button>
          </Typography>
        </div>
      </Box>
    </Modal>
  );
};
