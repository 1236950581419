import React, { useState } from 'react';

function LaptopIcon({ opn, stats }: { opn: any; stats: boolean }) {
  const [color, setcolor] = useState('#141C24');
  if (stats) {
    return (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='24'
        height='24'
        fill='none'
        viewBox='0 0 24 24'>
        <path
          stroke='#3E576F'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1.5'
          d='M11 5H7a2 2 0 00-2 2v9h14v-2.5m-3.975-8.475a3.5 3.5 0 104.95 4.95m-4.95-4.95a3.5 3.5 0 114.95 4.95m-4.95-4.95l4.95 4.95M3 16h18v1a2 2 0 01-2 2H5a2 2 0 01-2-2v-1z'></path>
      </svg>
    );
  }
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      fill='none'
      viewBox='0 0 24 24'
      style={{ cursor: 'pointer' }}
      onMouseOver={() => setcolor('#EE4B22')}
      onMouseOut={() => setcolor('#141C24')}
      onClick={() => opn()}>
      <path
        stroke={color}
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.5'
        d='M5 7a2 2 0 012-2h10a2 2 0 012 2v9H5V7zM3 16h18v1a2 2 0 01-2 2H5a2 2 0 01-2-2v-1z'></path>
    </svg>
  );
}

export default LaptopIcon;
