import { yupResolver } from '@hookform/resolvers/yup';
import {
  Breadcrumbs,
  Button,
  Grid,
  FormControlLabel,
  RadioGroup,
  Radio,
  Link,
  Typography,
} from '@mui/material';
import HomeIcon from 'assets/png/dashboardactive.svg';
import ArrowIcon from 'assets/png/breadarrow.svg';
import { To, useLocation, useNavigate } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { functionalAreaType } from 'types';
import { functionalArea } from 'utils/ValidatorSchema';
import { useEffect, useRef, useState } from 'react';
import { addNewData, updateData } from 'services/CommonServices';
// import { toast } from 'react-toastify';
import { AdminPrivateRoutes } from 'routes/routeConstans';
import CustomInput from 'components/CustomInput/customInput';
import Loader from 'components/loader';
import { useAppSelector } from 'store/hooks';
import { toast } from 'react-toastify';
import { AccessDenied } from 'utils/helper';
// import { alertOptions } from 'utils/Config';

const FunctionalAreaAdd = () => {
  const {
    userInfo: { organizationId: orgdy },
  }: any = useAppSelector((store: any) => store.userLogin);
  const navigate = useNavigate();
  const [Ld, setLd] = useState(false);
  const subtgref: React.MutableRefObject<any> = useRef();
  const gotoNavigate = (link: To) => {
    navigate(link);
  };

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm<functionalAreaType>({
    defaultValues: {
      organizationId: orgdy ? orgdy : '',
      name: '',
      description: '',
      status: !false,
    },
    reValidateMode: 'onChange',
    resolver: yupResolver(functionalArea),
  });

  const location = useLocation();
  const locationstate = location.state;
  const locationpathname = location.pathname;
  const locationbool: boolean =
    locationpathname === AdminPrivateRoutes.SETTINGS.FUNCTIONAL_AREA.FUNCTIONALAREAEDIT;

  const onSubmit = async (data: {
    organizationId: string;
    name: string;
    description: string;
    status: boolean;
  }) => {
    // Access the updated orgid value from the form state
    // const updatedOrgid = formState.values.organizationId;
    // Update the orgid value later using setValue
    // setValue('organizationId', 'c5e4b323-958e-4986-a03f-f358b893f6b4');

    setLd(true);
    try {
      const datas =
        locationstate && Object.keys(locationstate).length > 0 && locationbool
          ? await updateData(locationstate.id, data, 'functional-area', true)
          : await addNewData(data, 'functional-area', undefined, true);
      if (datas && datas.status >= 200 && datas.status < 300) {
        gotoNavigate('/settings/functional-area');
        // toast.success(datas && datas.message ? datas.message : '', alertOptions);
      }
    } catch (e: any) {
      if (e === 'Forbidden resource') {
        toast.error(AccessDenied);
        navigate('/dashboard');
      }
      // toast.error(er || String(er), alertOptions);
    } finally {
      setLd(!true);
    }
  };
  useEffect(() => {
    // if (errors) {
    //   console.log(errors, 'errors');
    //   toast.error(er || String(er), alertOptions);
    // }
    if (locationstate && Object.keys(locationstate).length > 0 && locationbool) {
      // console.log(locationstate, 'locationstate');
      setValue('organizationId', locationstate.organizationId);
      setValue('name', locationstate.name);
      setValue('description', locationstate.description);
      setValue('status', locationstate.status);
    }

    if (!locationstate && locationbool) {
      gotoNavigate('/settings/functional-area');
    }
  }, []);

  // console.log(locationpathname, 'locationpathname');
  return (
    <>
      <Grid container>
        <Grid item md={12} sm={12} xs={12}>
          <Typography component={'div'} className='breadcrumbs d-flex-a'>
            <Breadcrumbs aria-label='breadcrumb'>
              <Link underline='hover' color='inherit' href='/'>
                <img src={HomeIcon} className='home' alt='Home' />
              </Link>
              <Link underline='hover' color='inherit' href=''>
                <img src={ArrowIcon} alt='arrow' className='arrows' />
                Settings
              </Link>
              <Link
                underline='hover'
                color='inherit'
                href={''}
                onClick={() => gotoNavigate('/settings/functional-area')}>
                <img src={ArrowIcon} alt='arrow' className='arrows' />
                Functional Area
              </Link>
              <Typography color='text.primary'>
                {' '}
                <img src={ArrowIcon} alt='arrow' className='arrows' />
                {locationbool ? 'Edit' : 'Add'} Functional Area
              </Typography>
            </Breadcrumbs>
          </Typography>

          <Typography component={'div'} className='d-flex-a flex-sb title-add-box'>
            <Typography className='page-title'>
              {locationbool ? 'Edit' : 'Add'} Functional Area
            </Typography>
            <Typography component={'div'} className='d-flex-a'>
              <Button
                className='cancel-btn mr-16'
                onClick={() => gotoNavigate('/settings/functional-area')}>
                {' '}
                Cancel
              </Button>
              <Button
                className='s-add-btn'
                onClick={() => {
                  subtgref.current.click();
                }}>
                {' '}
                Save
              </Button>
            </Typography>
          </Typography>
          <Typography component={'div'} className='main-content'>
            <Typography component={'div'} className='white-box'>
              <form className='private-form' onSubmit={handleSubmit(onSubmit)}>
                <Grid container>
                  <Grid item md={7} sm={12} xs={12}>
                    <Grid container>
                      <Grid item md={12} sm={12} xs={12}>
                        <Typography component={'div'} className='custom-field'>
                          <Typography component={'p'}>
                            Functional Area <span>*</span>
                          </Typography>
                          {/* <TextField className="custom-input" id="standard-basic" placeholder="" variant="standard" /> */}
                          <Controller
                            render={({ field: { onChange, value, name } }) => (
                              <CustomInput
                                value={value}
                                name={name}
                                placeHolder='Type your functional area'
                                className='custom-input'
                                error={errors.name?.message}
                                onChange={(e) => {
                                  onChange(e);
                                }}
                              />
                            )}
                            name='name'
                            control={control}
                          />
                        </Typography>
                      </Grid>
                      <Grid item md={12} sm={12} xs={12}>
                        <Typography component={'div'} className='custom-field'>
                          <Typography component={'p'}>
                            Description <span>*</span>
                          </Typography>
                          {/* <TextField className="custom-input" id="standard-basic"  multiline
                        rows={4} placeholder="write content here..." variant="standard" /> */}
                          <Controller
                            render={({ field: { onChange, value, name } }) => (
                              <CustomInput
                                value={value}
                                name={name}
                                isTextArea={true}
                                className='custom-input'
                                placeHolder='Write your content here'
                                error={errors.description?.message}
                                onChange={(e) => {
                                  onChange(e);
                                }}
                              />
                            )}
                            name='description'
                            control={control}
                          />
                        </Typography>
                      </Grid>
                      <Grid item md={12} sm={12} xs={12}>
                        <Typography component={'div'} className='custom-field'>
                          <Typography component={'p'}>
                            Status <span>*</span>
                          </Typography>
                          {/* <RadioGroup
                        className="custom-radio"
                        row
                        aria-labelledby=""
                        name=""
                        >
                        <FormControlLabel value="Active" control={<Radio />} label="Active" />
                        <FormControlLabel value="Inactive" control={<Radio />} label="Inactive" />
      
                        </RadioGroup> */}

                          <Controller
                            render={({ field }) => (
                              <>
                                <RadioGroup className='custom-radio' row {...field}>
                                  <FormControlLabel
                                    value={true}
                                    control={<Radio />}
                                    label='Active'
                                  />
                                  <FormControlLabel
                                    value={false}
                                    control={<Radio />}
                                    label='Inactive'
                                  />
                                </RadioGroup>
                                {errors.status && <span>You must select a payment method</span>}
                              </>
                            )}
                            name='status'
                            control={control}
                          />
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                {/* this is for dum,m,y to trigger form */}
                <Button
                  type='submit'
                  variant='contained'
                  color='primary'
                  ref={subtgref}
                  sx={{ display: 'none' }}>
                  Submit
                </Button>
              </form>
            </Typography>
          </Typography>
        </Grid>
      </Grid>
      {Ld && <Loader />}
    </>
  );
};

export default FunctionalAreaAdd;
