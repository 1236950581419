/* eslint-disable no-duplicate-imports */
/* eslint-disable @typescript-eslint/no-unused-vars */

import React, { useEffect, useState } from 'react';
import { DatePicker, Space } from 'antd';
import type { GetProps } from 'antd';
import { RangePickerProps } from 'antd/es/date-picker';
import dayjs from 'dayjs';

export default function DateTimepicker({
  defaultValue,
  onChange,
  disabled,
  name,
  ref,
  style,
  max,
  min,
  DATETIME,
  disableDate,
}: {
  defaultValue?: any;
  onChange?: any;
  disabled?: boolean;
  name?: any;
  ref?: any;
  style?: any;
  max?: any;
  min?: any;
  DATETIME?: boolean;
  disableDate?: any;
}) {
  /**
   * @param {string}  [defaultValue] - A string param.
   * @param {any} [onChange] - onChange event handler which returns date and dateString
   * @param {boolean} [disabled] - disable is a boolean prop which makes the datepicker diusabled.
   * @param {string} [name] - Input form name
   * @param {any} [ref] - Useref hook reference
   * @param {object} [style] - Useref hook reference
   * @param {any} [max] - Maximum date constraint
   * @param {any} [min] - Minimum date constraint
   * @param {boolean} [DATETIME] - DATETIME is a boolean constraint which formats or adds the dte or date time feature based on boolean value passed as prop.
   * @param {any} [disableDate] -disableDate is a array constraint which disables date in given array.
   * @returns {React.JSX} return the antd date time picker as jsx
   */
  const [Dateformat] = useState({
    DATE: 'YYYY-MM-DD',
    DATELabel: 'DD-MM-YYYY',
    DATETIME: 'YYYY-MM-DD HH:mm:ss',
    DATETIMELabel: 'DD-MM-YYYY HH:mm:ss',
  });

  const currentmode = DATETIME ? Dateformat.DATETIME : Dateformat.DATE;
  const currentmodeLabel = DATETIME ? Dateformat.DATETIMELabel : Dateformat.DATELabel;

  const range = (start: number, end: number) => {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  };

  const disableddates = [
    ...disableDate.map((rt: any) => (rt ? dayjs(rt).format(currentmode) : null)),
  ];

  const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    // Check if the current date is Sunday or if it matches any date in disableddates
    return (
      current &&
      (current.day() === 0 || disableddates.includes(current.endOf('day').format(currentmode)))
    );
  };

  return (
    <React.Fragment>
      <div className='antd-custom-wrapper'>
        <DatePicker
          format={currentmode}
          className={'antd-date-picker-scss'}
          disabledDate={disabledDate}
          showTime={DATETIME ? true : false}
          {...(defaultValue && { defaultValue: dayjs(defaultValue, currentmode) })}
          {...(onChange ? { onChange } : undefined)}
          {...(name ? { name } : undefined)}
          {...(ref ? { ref } : undefined)}
          {...(style ? { style } : undefined)}
          {...(max ? { maxDate: dayjs(max) } : undefined)}
          {...(min ? { minDate: dayjs(min) } : undefined)}
          disabled={disabled ? true : false}
        />
        <input
          type='text'
          value={defaultValue ? dayjs(defaultValue).format(currentmodeLabel) : 'Select date'}
          {...(style ? { style } : undefined)}
          className={`custom-date-antd-placeholder ${!defaultValue ? 'select-gray-antd' : ''}`}
        />
      </div>
    </React.Fragment>
  );
}
