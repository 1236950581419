// import CancelIcon from '@mui/icons-material/Cancel';
import React from 'react';
// import Himg from '../../assets/png/ctlogo.png';
// import MoreVertIcon from '@mui/icons-material/MoreVert';
import ImageWithAuth from 'components/ProfileImage';
import Dummyimgn from 'assets/png/cticon.png';
// type ModalProps = {
//   open?: any;
//   close?: any;
//   title?: string;
//   children?: any;
//   onConfirm?: any;
//   floatingBtnShow?: any;
//   autoCloseDisable?: boolean;
//   visibleClose?: boolean;
// };

// const ConfirmDialog: React.FC<ModalProps> = ({

const ClientCard: React.FC<any> = ({
  profileimg,
  created,
  support,
  location,
  createdby,
  onClick,
}: {
  profileimg: string;
  created: string;
  support: string;
  location: string;
  createdby: string;
  onClick?: (...any: any[]) => void;
}) => {
  return (
    <div className='client-card-approve'>
      <div className='client-card'>
        <header>
          <span style={{ width: '40px', height: '40px' }}>
            {profileimg && profileimg ? (
              <ImageWithAuth
                styled={{
                  width: '100%',
                  height: '100%',
                  borderRadius: '100%',
                  objectFit: 'cover',
                  transform: 'translateY(-4px)',
                }}
                id={profileimg}
              />
            ) : (
              <img
                src={Dummyimgn}
                style={{
                  width: '100%',
                  height: '100%',
                  borderRadius: '100%',
                  objectFit: 'cover',
                  transform: 'translateY(-4px)',
                }}
              />
            )}
          </span>
          <div className='non-profil'>
            <div className='dt-con'>
              <div className='heading'>Created On</div>
              <div className='time'>{created}</div>
            </div>
            {/* <MoreVertIcon sx={{ cursor: 'pointer' }} /> */}
          </div>
        </header>
        <main>
          <div className='support'>{support}</div>
          <div className='sub-support'>{location}</div>
        </main>
        <footer>
          <span className='createdby'>Created By:</span>
          &nbsp;
          <span className='director'>{createdby}</span>
        </footer>
        <button className='main-view-btn'>Awaiting Approval</button>
      </div>
      <button className='footer-btn' onClick={onClick}>
        Validate Details
      </button>
    </div>
  );
};
export default ClientCard;
