import React, { useState } from 'react';
import HomeIcon from 'assets/png/dashboardactive.svg';
import CrossIcon from 'assets/iconcomponents/crossicon';
import TickIcon from 'assets/iconcomponents/tickicon';
import ArrowIcon from 'assets/png/breadarrow.svg';
import { Breadcrumbs, Link, Grid, Typography, Button } from '@mui/material';
import CLIENTDETAILS from '../clientdetails';
import CRMDETAILS from '../crmdetail';
import SPOCDETAIL from '../spocdetail';
import BusinessInfoDetail from '../businessInfoDetail';
import TermsAndCondition from '../Terms&Condition/index';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { AdminPrivateRoutes } from 'routes/routeConstans';

const CLIENTADD = () => {
  const navigate = useNavigate();
  const [searchParams]: any = useSearchParams();
  const [tabValue, setTabValue] = useState('1');

  const sidbrCustomstyl = {
    width: '100%',
    height: '40px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '10px',
    cursor: 'pointer',
  };
  const [formview, setformview] = useState({
    crm: false,
    spoc: false,
    clientdetail: true,
    businessDetail: false,
    termsCondition: false,
    clientId: '',
  });
  const [formfinish, setFormfinish] = useState({
    crm: false,
    spoc: false,
    clientdetail: searchParams.get('mode') === 'edit' ? true : false,
    businessDetail: false,
    termsCondition: false,
  });

  return (
    <Grid container>
      {/* {loader && <Loader />} */}
      <Grid item md={12} sm={12} xs={12}>
        <Typography component={'div'} className='breadcrumbs d-flex-a'>
          <Breadcrumbs aria-label='breadcrumb'>
            <Link underline='hover' color='inherit' href='/'>
              <img src={HomeIcon} className='home' alt='Home' />
            </Link>
            <Link underline='hover' color='inherit' href={AdminPrivateRoutes.CLIENTS.CLIENTVIEW}>
              <img src={ArrowIcon} alt='arrow' className='arrows' />
              Clients
            </Link>
            <Typography color='text.primary'>
              <img src={ArrowIcon} alt='arrow' className='arrows' />
              {searchParams.get('mode') === 'edit' ? 'Edit' : 'Add'} Client
            </Typography>
          </Breadcrumbs>
        </Typography>
        <Typography component={'div'} className='d-flex-a flex-sb title-add-box'>
          <Typography className='page-title'>
            {searchParams.get('mode') === 'edit' ? 'Edit' : 'Add'} Client
          </Typography>
          <Typography component={'div'} className='d-flex-a'>
            <Button
              className='cancel-btn mr-16'
              onClick={() => {
                if (formview.clientdetail) {
                  navigate(AdminPrivateRoutes.CLIENTS.CLIENTVIEW);
                } else if (formview.spoc) {
                  setformview((prev: any) => ({
                    ...prev,
                    crm: false,
                    spoc: false,
                    clientdetail: true,
                    termsCondition: false,
                    businessDetail: false,
                  }));
                } else if (formview.crm) {
                  setformview((prev: any) => ({
                    ...prev,
                    crm: false,
                    spoc: true,
                    clientdetail: false,
                    termsCondition: false,
                    businessDetail: false,
                  }));
                } else if (formview.businessDetail) {
                  setformview((prev: any) => ({
                    ...prev,
                    crm: true,
                    spoc: false,
                    clientdetail: false,
                    termsCondition: false,
                    businessDetail: false,
                  }));
                } else if (formview.termsCondition && tabValue === '1') {
                  setformview((prev: any) => ({
                    ...prev,
                    crm: false,
                    spoc: false,
                    clientdetail: false,
                    termsCondition: false,
                    businessDetail: true,
                  }));
                } else if (formview.termsCondition && tabValue === '2') {
                  setTabValue('1');
                } else if (formview.termsCondition && tabValue === '3') {
                  setTabValue('2');
                }
              }}>
              {formview.clientdetail ? 'Cancel' : 'Back'}
            </Button>
            {((formview.termsCondition && tabValue === '1') ||
              (formview.termsCondition && tabValue === '2')) && (
              <Button
                className='s-add-btn'
                sx={{ textTransform: 'none !important' }}
                onClick={() => {
                  if (formview.termsCondition && tabValue === '1') {
                    setTabValue('2');
                  } else if (formview.termsCondition && tabValue === '2') {
                    setTabValue('3');
                  }
                }}>
                {'Next'}
              </Button>
            )}
            {(formview.crm ||
              formview.businessDetail ||
              formview.clientdetail ||
              formview.spoc) && (
              <Button
                className='s-add-btn'
                type={'submit'}
                form={'user-add'}
                sx={{ textTransform: 'none !important' }}>
                {'Save and Continue'}
              </Button>
            )}
            {formview.termsCondition && tabValue === '3' && (
              <Button
                className='s-add-btn'
                type={'submit'}
                form={'user-add'}
                sx={{ textTransform: 'none !important' }}>
                {'Save'}
              </Button>
            )}
          </Typography>
        </Typography>
        <Typography component={'div'} className='pad-box'>
          <Grid container columnSpacing={2}>
            <Grid item md={4} sm={4} xs={4}>
              <Typography component={'div'} className='pro-box2'>
                <Typography
                  component={'p'}
                  className={formview.clientdetail ? 'side-client-text active' : 'side-client-text'}
                  style={{
                    ...sidbrCustomstyl,
                    color: formview.clientdetail ? '#344051' : '#89888E',
                  }}
                  onClick={() => {
                    if (searchParams.get('mode') === 'edit') {
                      setformview((prev: any) => ({
                        ...prev,
                        crm: false,
                        spoc: false,
                        clientdetail: true,
                        termsCondition: false,
                        businessDetail: false,
                      }));
                    }
                  }}>
                  <Typography component={'p'} className='side-client-text2'>
                    Client Details
                  </Typography>
                  {formfinish.clientdetail ? <TickIcon /> : <CrossIcon />}
                </Typography>

                <Typography
                  component={'p'}
                  className={formview.spoc ? 'side-client-text active' : 'side-client-text'}
                  style={{
                    ...sidbrCustomstyl,
                    color: formview.spoc ? '#344051' : '#89888E',
                  }}
                  onClick={() => {
                    if (searchParams.get('mode') === 'edit') {
                      setformview((prev: any) => ({
                        ...prev,
                        crm: false,
                        spoc: true,
                        clientdetail: false,
                        termsCondition: false,
                        businessDetail: false,
                      }));
                    }
                  }}>
                  <Typography component={'p'} className='side-client-text2'>
                    SPOC Details
                  </Typography>
                  {formfinish.spoc ? <TickIcon /> : <CrossIcon />}
                </Typography>
                <Typography
                  component={'p'}
                  className={formview.crm ? 'side-client-text active' : 'side-client-text'}
                  style={{
                    ...sidbrCustomstyl,
                    color: formview.crm ? '#344051' : '#89888E',
                  }}
                  onClick={() => {
                    if (searchParams.get('mode') === 'edit') {
                      setformview((prev: any) => ({
                        ...prev,
                        crm: true,
                        spoc: false,
                        clientdetail: false,
                        termsCondition: false,
                        businessDetail: false,
                      }));
                    }
                  }}>
                  <Typography component={'p'} className='side-client-text2'>
                    CRM Details
                  </Typography>
                  {formfinish.crm ? <TickIcon /> : <CrossIcon />}
                </Typography>
                <Typography
                  component={'p'}
                  className={
                    formview.businessDetail ? 'side-client-text active' : 'side-client-text'
                  }
                  style={{
                    ...sidbrCustomstyl,
                    color: formview.businessDetail ? '#344051' : '#89888E',
                  }}
                  onClick={() => {
                    if (searchParams.get('mode') === 'edit') {
                      setformview((prev: any) => ({
                        ...prev,
                        crm: false,
                        spoc: false,
                        clientdetail: false,
                        termsCondition: false,
                        businessDetail: true,
                      }));
                    }
                  }}>
                  <Typography component={'p'} className='side-client-text2'>
                    Business Info Details
                  </Typography>
                  {formfinish.businessDetail ? <TickIcon /> : <CrossIcon />}
                </Typography>
                <Typography
                  component={'p'}
                  className={
                    formview.termsCondition ? 'side-client-text active' : 'side-client-text'
                  }
                  style={{
                    ...sidbrCustomstyl,
                    color: formview.termsCondition ? '#344051' : '#89888E',
                  }}
                  onClick={() => {
                    if (searchParams.get('mode') === 'edit') {
                      setformview((prev: any) => ({
                        ...prev,
                        crm: false,
                        spoc: false,
                        clientdetail: false,
                        termsCondition: true,
                        businessDetail: false,
                      }));
                    }
                  }}>
                  <Typography component={'p'} className='side-client-text2'>
                    Agreement T&C
                  </Typography>
                  {formfinish.termsCondition ? <TickIcon /> : <CrossIcon />}
                </Typography>
              </Typography>
            </Grid>
            <Grid item md={8} sm={4} xs={4}>
              {formview.clientdetail && (
                <CLIENTDETAILS
                  setformview={setformview}
                  formview={formview}
                  setFormfinish={setFormfinish}
                />
              )}
              {formview.crm && (
                <CRMDETAILS
                  setformview={setformview}
                  formview={formview}
                  setFormfinish={setFormfinish}
                />
              )}
              {formview.spoc && (
                <SPOCDETAIL
                  setformview={setformview}
                  formview={formview}
                  setFormfinish={setFormfinish}
                />
              )}
              {formview.businessDetail && (
                <BusinessInfoDetail
                  setformview={setformview}
                  formview={formview}
                  setFormfinish={setFormfinish}
                />
              )}
              {formview.termsCondition && (
                <TermsAndCondition
                  formview={formview}
                  setFormfinish={setFormfinish}
                  setTabValue={setTabValue}
                  tabValue={tabValue}
                />
              )}
            </Grid>
          </Grid>
        </Typography>
      </Grid>
    </Grid>
  );
};
export default CLIENTADD;
