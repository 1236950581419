import React from 'react';

function TickIcon() {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='none' viewBox='0 0 16 16' style={{cursor:'pointer'}}>
      <path
        fill='#16C06E'
        d='M8 1.5C4.41 1.5 1.5 4.41 1.5 8s2.91 6.5 6.5 6.5 6.5-2.91 6.5-6.5S11.59 1.5 8 1.5zm-1.003 8.803c-.075.075-.181.138-.275.138-.094 0-.2-.066-.278-.141l-1.75-1.75.556-.556 1.475 1.475 3.9-3.928.547.565-4.175 4.197z'></path>
    </svg>
  );
}

export default TickIcon;
