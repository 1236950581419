/* eslint-disable @typescript-eslint/no-unused-vars */
import { Typography } from '@mui/material';
import React, { CSSProperties } from 'react';

import Logo from '../assets/png/cticon.png';

export const Loader: React.FC<{ style?: CSSProperties }> = ({ style }) => {
  return (
    <Typography component={'div'} style={{ ...style }} className='fixed-loader'>
      <Typography
        component={'div'}
        sx={{
          left: ' 50%',
          position: 'fixed',
          top: ' 50%',
          transform: 'translate(-50%, -50%)',
          zIndex: 99,
        }}>
        <Typography component={'div'} className='loadBox'>
          <Typography className='t-box' />
          <Typography className='s-box' />
          <Typography className='s-box' />
          <Typography className='t-box' />
          {/* <Typography className="line-circle"/>
          <img src={Logo} alt="icon" className="centerl" /> */}
        </Typography>
      </Typography>
    </Typography>
  );
};

export default Loader;
