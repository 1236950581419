import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';

const constants = {
  title: '',
  firstName: '',
  lastName: '',
  designationId: '',
  email: '',
  phoneNumber: undefined,
  mobileno: undefined,
  status: !false,
  branchId: '',
  address1: undefined,
  address2: undefined,
  country: '',
  pincode: '',
  city: '',
  sameAsCorporateAddress: false,
  state: '',
  countryCode: '',
  id: undefined,
  // countryCodeText:'',
};

export interface Action {
  type: string;
  pay: any[];
}

const objectSlice = createSlice({
  name: 'clientcorporate',
  initialState: {
    client: {},
    crm: [],
    crm2: [],
    spoc: [],
    removeClientCrmIds: [],
    removeClientSpocIds: [],
    clientimg: { file: undefined, exist: false, file2: undefined },
    logoId: { id: '' },
  },
  reducers: {
    storeinfo: (state: any, action: PayloadAction<any>) => {
      state.client = action.payload;
    },
    storelogoid: (state: any, action: PayloadAction<any>) => {
      state.logoId = action.payload;
    },
    storeimage: (state: any, action: PayloadAction<any>) => {
      state.clientimg = action.payload;
    },
    storeclient: (state: any, action: PayloadAction<any>) => {
      state.client = action.payload;
    },
    storecrm: (state: any, action: PayloadAction<any>) => {
      state.crm = action.payload;
    },
    removecrm: (state: any, action: PayloadAction<any>) => {
      // console.log(filteredobj,'filteredobj',action.payload.id)
      state.crm = [
        ...state.crm.filter((oi1: any) => (oi1.id !== action.payload.id ? action.payload.id : '')),
      ];
    },
    removecrmid: (state: any, action: PayloadAction<any>) => {
      // console.log(filteredobj,'filteredobj',action.payload.id)
      state.removeClientCrmIds = [...state.removeClientCrmIds, action.payload];
    },
    removespocid: (state: any, action: PayloadAction<any>) => {
      // console.log(filteredobj,'filteredobj',action.payload.id)
      state.removeClientSpocIds = [...state.removeClientSpocIds, action.payload];
    },
    clearcrmspocid: (state: any) => {
      state.removeClientCrmIds = [];
      state.removeClientSpocIds = [];
    },
    storecrm2: (state: any, action: PayloadAction<any>) => {
      state.crm2 = action.payload;
    },
    storespoc: (state: any, action: PayloadAction<any>) => {
      state.spoc = action.payload;
    },
    Adddummyspoc: (state: any) => {
      state.spoc = [...state.spoc, constants];
    },
    Localstoragestore: (state: any, action: PayloadAction<any>) => {
      localStorage.setItem('clientcrm2', JSON.stringify(action.payload.clientsCrm));
      localStorage.setItem('clientcrm', JSON.stringify(action.payload.clientsCrm));
      localStorage.setItem('clientdetail', JSON.stringify(action.payload));
      localStorage.setItem('clientspoc', JSON.stringify({ items: action.payload.clientsSpoc }));
    },
    removespocelement: (state: any, action: PayloadAction<any>) => {
      const calcs: any = [
        ...state.spoc.filter((ex: any, id: number) => id !== action.payload.indx),
      ];
      state.spoc = [...calcs];
      localStorage.setItem('clientspoc', JSON.stringify({ items: calcs }));
      // console.log(calcs,'calcsspocs')
    },
    changespoc: (state: any, action: PayloadAction<any>) => {
      // state.spoc = action.payload;
      // const prevspoc = state.spoc.findIndex((er:any) => er.id === action.payload.id);
      // console.log(prevspoc,'prev');
      if (action.payload.mode === 'edit') {
        state.spoc = [
          ...state.spoc.map((val: any, indx: number) => {
            // console.log({val:val.id,act:action.payload.id})

            if (val.id == action.payload.id && indx === action.payload.index) {
              return { ...val, ...action.payload.datum };
            } else if (indx === action.payload.index) {
              return typeof val !== 'object'
                ? { ...action.payload.datum }
                : { ...val, ...action.payload.datum };
            } else {
              return val;
            }
          }),
        ];
      } else {
        // state.spoc[action.payload.index] = {...action.payload.datum}
        // state.spoc = [...state.spoc,]
        if (_.isEmpty(state.spoc)) {
          state.spoc = [{ ...action.payload.datum }];
        } else {
          state.spoc = [
            ...state.spoc.map((r: any, indx: number) => {
              if (indx === action.payload.index) {
                return typeof r !== 'object'
                  ? { ...action.payload.datum }
                  : { ...r, ...action.payload.datum };
              } else {
                return r;
              }
            }),
          ];
        }
      }
      // console.log(state.spoc.find((er:any) => er.id === action.payload.id),'prevspoc');
      // state.spoc.map((er:any,ids:number) => {

      // if(er.id === action.payload.id) {
      //   state.spoc = [{...state.spoc[ids],...action.payload.datum}];
      //   return;
      // }  else {
      //   state.spoc = [{...state.spoc[ids],...action.payload.datum}];
      // }

      //  });
    },
    cleaninfo: (state, action) => {
      return action.payload;
    },
    // updateObject: (state, action) => {
    //   const { id, data } = action.payload;
    //   state[id] = { ...state[id], ...data };
    // },
  },
});

export const {
  storeinfo,
  cleaninfo,
  storeclient,
  storecrm,
  storecrm2,
  storespoc,
  storeimage,
  removecrm,
  removecrmid,
  removespocid,
  clearcrmspocid,
  changespoc,
  Localstoragestore,
  storelogoid,
  Adddummyspoc,
  removespocelement,
} = objectSlice.actions;
export default objectSlice.reducer;
