/* eslint-disable @typescript-eslint/no-unused-vars */
// import CancelIcon from '@mui/icons-material/Cancel';
import React from 'react';
// import Himg from '../../assets/png/ctlogo.png';
// import MoreVertIcon from '@mui/icons-material/MoreVert';
import ImageWithAuth from 'components/ProfileImage';
import Dummyimgn from 'assets/png/cticon.png';
// type ModalProps = {
//   open?: any;
//   close?: any;
//   title?: string;
//   children?: any;
//   onConfirm?: any;
//   floatingBtnShow?: any;
//   autoCloseDisable?: boolean;
//   visibleClose?: boolean;
// };

// const ConfirmDialog: React.FC<ModalProps> = ({

const PositionCard: React.FC<any> = ({
  approvalStatus,
  profileimg,
  title,
  created,
  crm,
  openings,
  recuiters,
  uid,
  companyname,
  onClick,
}: {
  approvalStatus: string;
  profileimg?: string;
  title: string;
  created: string;
  crm: string;
  openings: number;
  recuiters: string;
  uid: string;
  companyname: string;
  onClick?: any;
}) => {
  
  return (
    <div className='client-card-approve position-apruv-card'>
      <div className='uplinedesign'></div>
      <div className='client-card'>
        <header>
          <span
            style={{ width: '54px', height: '54px', padding: '8px', backgroundColor: '#FFF4F1' }}>
            {profileimg && profileimg ? (
              <ImageWithAuth
                styled={{
                  width: '100%',
                  height: '100%',
                  borderRadius: '5.4px',
                  objectFit: 'cover',
                  transform: 'translateY(-4px)',
                }}
                id={profileimg}
              />
            ) : (
              <img
                src={Dummyimgn}
                style={{
                  width: '100%',
                  height: '100%',
                  borderRadius: '5.4px',
                  objectFit: 'cover',
                  transform: 'translateY(-4px)',
                }}
              />
            )}
          </span>
          <div className='non-profil'>
            {approvalStatus === 'awaitingApproval' ? (
              <button className='main-view-btn awaitingApproval'>
                <span>Awaiting Approval</span>
              </button>
            ) : (
              <></>
            )}
            {approvalStatus === 'rejected' ? (
              <button className='main-view-btn'>
                <span>Rejected</span>
              </button>
            ) : (
              <></>
            )}
            {approvalStatus === 'assigned' ? (
              <button className='main-view-btn assigned'>
                <span>Assigned</span>
              </button>
            ) : (
              <></>
            )}
            {approvalStatus === 'inProcess' ? (
              <button className='main-view-btn inProcess'>
                <span>In Process</span>
              </button>
            ) : (
              <></>
            )}
            {approvalStatus === 'onHold' ? (
              <button className='main-view-btn onHold'>
                <span>On Hold</span>
              </button>
            ) : (
              <></>
            )}
            {approvalStatus === 'billed' ? (
              <button className='main-view-btn billed'>
                <span>Billed</span>
              </button>
            ) : (
              <></>
            )}
            {approvalStatus === 'terminated' ? (
              <button className='main-view-btn terminated'>
                <span>Terminated</span>
              </button>
            ) : (
              <></>
            )}
          </div>
        </header>
        <main>
          <div className='company' style={{ textTransform: 'capitalize' }}>
            {companyname}
          </div>
          <div className='description'>
            <div className='descon'>
              <div className='jobtitle'>{title ? title : ''}</div>
              <div className='time'>
                <span>Created On -</span> <span>{created}</span>
              </div>
            </div>
            <div className='openings'>
              <span>Openings -</span> <span>{openings}</span>
            </div>
          </div>
        </main>
        <div className='divider'></div>
        <footer>
          <div className='openings'>
            <span>CRM -</span> <span>{crm ? crm : ''}</span>
          </div>
          <div className='content-wuth-btn'>
            {' '}
            <div className='footerdesc'>
              <div className='footer-leftr'>Recruiters</div>
              <div className='footer-sub'>{recuiters ? recuiters : ''}</div>
            </div>{' '}
            <button className='footer-btn' onClick={onClick}>
              Validate Details
            </button>
          </div>
        </footer>
      </div>
    </div>
  );
};
export default PositionCard;
