import {
  Grid,
  Typography,
  Breadcrumbs,
  Button,
  Link,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import HomeIcon from 'assets/png/dashboardactive.svg';
import ArrowIcon from 'assets/png/breadarrow.svg';
import { useNavigate, useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { industryAddSchema } from 'utils/ValidatorSchema';
import CustomInput from 'components/CustomInput/customInput';
import _ from 'lodash';
import { updateData, addNewData, getAllListData } from 'services/CommonServices';
import { SETTING_ENDPOINT } from 'utils/Constant';
import { AdminPrivateRoutes } from 'routes/routeConstans';
import { useAppSelector } from 'store/hooks';
import Loader from 'components/loader';
import { IndustryType } from 'types';
import { toast } from 'react-toastify';
import { AccessDenied } from 'utils/helper';

export const IndustryAdd = () => {
  const { editId } = useParams();
  const navigate = useNavigate();
  const [loader, setLoader] = useState<boolean>(false);
  const {
    userInfo: { organizationId: orgdy },
  }: any = useAppSelector((store: any) => store.userLogin);
  const {
    control: control,
    handleSubmit: handleSubmit,
    reset: resetForm,
    setValue,
    formState: { errors },
  } = useForm<IndustryType>({
    criteriaMode: 'all',
    defaultValues: {
      name: '',
      description: '',
      status: true,
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(industryAddSchema),
  });

  const getInitialStatus = () => {
    if (editId) {
      getAllListData(`${SETTING_ENDPOINT.Industry}?id=${editId}`, true)
        .then((resp: any) => {
          if (resp && !_.isEmpty(resp.data)) {
            setValue('name', resp.data[0].name);
            resp.data[0]?.description && setValue('description', resp.data[0].description);
            setValue('status', resp.data[0].status);
          }
          setLoader(false);
        })
        .catch((e) => {
          if (e === 'Forbidden resource') {
            toast.error(AccessDenied);
            navigate('/dashboard');
          }
          setLoader(false);
        });
    }
  };

  const onSubmitForm = (data: IndustryType) => {
    setLoader(true);
    if (!_.isUndefined(editId)) {
      updateData(
        editId,
        {
          organizationId: orgdy,
          name: data.name,
          status: data.status,
          description: data.description,
        },
        `${String(SETTING_ENDPOINT.Industry)}`,
        true,
      )
        .then(() => {
          resetForm();
          navigate(`${AdminPrivateRoutes.SETTINGS.INDUSTRY.INDUSTRYLIST}`);
          setLoader(false);
        })
        .catch((e) => {
          if (e === 'Forbidden resource') {
            toast.error(AccessDenied);
            navigate('/dashboard');
          }
          setLoader(false);
        });
    } else {
      addNewData(
        {
          organizationId: orgdy,
          name: data.name,
          status: data.status,
          description: data.description,
        },
        `${String(SETTING_ENDPOINT.Industry)}`,
        undefined,
        true,
      )
        .then(() => {
          resetForm();
          navigate(`${AdminPrivateRoutes.SETTINGS.INDUSTRY.INDUSTRYLIST}`);
          setLoader(false);
        })
        .catch((e) => {
          if (e === 'Forbidden resource') {
            toast.error(AccessDenied);
            navigate('/dashboard');
          }
          setLoader(false);
        });
    }
  };

  useEffect(() => {
    getInitialStatus();
  }, []);

  return (
    <>
      <Grid container>
        {loader && <Loader />}
        <Grid item md={12} sm={12} xs={12}>
          <Typography component={'div'} className='breadcrumbs d-flex-a'>
            <Breadcrumbs aria-label='breadcrumb'>
              <Link underline='hover' color='inherit' href='/'>
                <img src={HomeIcon} className='home' alt='Home' />
              </Link>
              <Link underline='hover' color='inherit' href=''>
                <img src={ArrowIcon} alt='arrow' className='arrows' />
                Settings
              </Link>
              <Link
                underline='hover'
                color='inherit'
                href={AdminPrivateRoutes.SETTINGS.INDUSTRY.INDUSTRYLIST}>
                <img src={ArrowIcon} alt='arrow' className='arrows' />
                Industry
              </Link>
              <Typography color='text.primary'>
                {' '}
                <img src={ArrowIcon} alt='arrow' className='arrows' />
                {editId ? 'Edit' : 'Add'} Industry
              </Typography>
            </Breadcrumbs>
          </Typography>
          <Typography component={'div'} className='d-flex-a flex-sb title-add-box'>
            <Typography className='page-title'>{editId ? 'Edit' : 'Add'} Industry</Typography>
            <Typography component={'div'} className='d-flex-a'>
              <Button
                className='cancel-btn mr-16'
                onClick={() => navigate(`${AdminPrivateRoutes.SETTINGS.INDUSTRY.INDUSTRYLIST}`)}>
                Cancel
              </Button>
              <Button form={'industry-add'} className='s-add-btn' type='submit'>
                Save
              </Button>
            </Typography>
          </Typography>
          <Typography component={'div'} className='main-content'>
            <Typography component={'div'} className='white-box'>
              <form
                className='private-form'
                id={'industry-add'}
                onSubmit={handleSubmit(onSubmitForm)}>
                <Grid container>
                  <Grid item md={7} sm={12} xs={12}>
                    <Grid container columnSpacing={4.25}>
                      <Grid item md={12} sm={12} xs={12}>
                        <Typography component={'div'} className='custom-field'>
                          <Typography component={'p'}>
                            Industry Type<span>*</span>
                          </Typography>
                          <Controller
                            control={control}
                            name='name'
                            render={({ field: { onChange, value, name } }) => {
                              return (
                                <CustomInput
                                  value={value}
                                  name={name}
                                  className='custom-input'
                                  placeHolder='Industry Type'
                                  error={errors.name?.message}
                                  onChange={(e) => {
                                    onChange(e);
                                  }}
                                />
                              );
                            }}
                          />
                        </Typography>
                      </Grid>
                      <Grid item md={12} sm={12} xs={12} sx={{ paddingBottom: '12px' }}>
                        <Typography component={'div'} className='custom-field'>
                          <Typography component={'p'}>Description</Typography>
                          <Controller
                            control={control}
                            name='description'
                            render={({ field: { onChange, value, name } }) => {
                              return (
                                <CustomInput
                                  value={value}
                                  name={name}
                                  isTextArea={true}
                                  className='custom-input custom-input-position'
                                  placeHolder='Write content here'
                                  error={errors.description?.message}
                                  onChange={(e) => {
                                    onChange(e);
                                  }}
                                />
                              );
                            }}
                          />
                        </Typography>
                      </Grid>
                      <Grid item md={12} sm={12} xs={12}>
                        <Typography component={'div'} className='custom-field'>
                          <Typography component={'p'}>
                            Status <span>*</span>
                          </Typography>

                          <Controller
                            render={({ field }) => (
                              <>
                                <RadioGroup className='custom-radio' row {...field}>
                                  <FormControlLabel
                                    value={true}
                                    control={<Radio />}
                                    label='Active'
                                  />
                                  <FormControlLabel
                                    value={false}
                                    control={<Radio />}
                                    label='Inactive'
                                  />
                                </RadioGroup>
                                {errors.status && <span>You must select a status</span>}
                              </>
                            )}
                            name='status'
                            control={control}
                          />
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            </Typography>
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};
