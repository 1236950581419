import React from 'react';

function AddIcon({style,onClick}:any) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='22'
      height='22'
      fill='none'
      viewBox='0 0 22 22'
      style={{ cursor: 'pointer',...style}}
      onClick={onClick}
      >
      <path
        fill='#EE4B22'
        d='M10.998 20.537c-5.229 0-9.482-4.254-9.482-9.483 0-5.23 4.253-9.483 9.482-9.483 5.23 0 9.483 4.254 9.483 9.483 0 5.229-4.253 9.483-9.483 9.483zm0-17.449c-4.392 0-7.965 3.573-7.965 7.966 0 4.392 3.573 7.965 7.965 7.965 4.393 0 7.966-3.573 7.966-7.965 0-4.392-3.573-7.966-7.966-7.966z'></path>
      <path
        fill='#EE4B22'
        d='M11 16.364a.758.758 0 01-.758-.759V6.502a.759.759 0 111.517 0v9.103a.758.758 0 01-.758.76z'></path>
      <path
        fill='#EE4B22'
        d='M15.553 11.812H6.45a.759.759 0 110-1.517h9.104a.758.758 0 110 1.517z'></path>
    </svg>
  );
}

export default AddIcon;
