import { RadioGroup, FormControlLabel, Grid, Typography, Radio } from '@mui/material';
import { Controller } from 'react-hook-form';

export const PaymentTC = ({ control, errors, handleSubmit, onSubmitForm }: any) => {
  return (
    <div>
      <form className='private-form' id={'user-add'} onSubmit={handleSubmit(onSubmitForm)}>
        <Grid item md={12} sm={12} xs={12} className='mt-25'>
          <Typography component={'div'} className='custom-field'>
            <Typography component={'p'}>
              Proposal Submitted<span>*</span>
            </Typography>
            <Controller
              control={control}
              name='proposalSubmitted'
              render={({ field: { onChange, value, name } }) => {
                return (
                  <RadioGroup
                    className={`custom-radio ${
                      errors.proposalSubmitted && errors.proposalSubmitted.message
                        ? 'custom-radio'
                        : ''
                    }`}
                    row
                    aria-labelledby=''
                    name={name}
                    value={value}
                    onChange={(e) => {
                      onChange(e);
                    }}>
                    <FormControlLabel value={30} control={<Radio />} label='30 Days' />
                    <FormControlLabel value={24} control={<Radio />} label='45 Days' />
                    <FormControlLabel value={60} control={<Radio />} label='60 Days' />
                    <FormControlLabel value={90} control={<Radio />} label='90 Days' />
                    <FormControlLabel value={180} control={<Radio />} label='180 Days' />
                  </RadioGroup>
                );
              }}
            />
            {errors && errors.proposalSubmitted && errors?.proposalSubmitted?.message && (
              <div className='Custom-error '>{String(errors?.proposalSubmitted?.message)}</div>
            )}
          </Typography>
        </Grid>
      </form>
    </div>
  );
};
