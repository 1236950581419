/* eslint-disable quotes */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Breadcrumbs,
  Button,
  Grid,
  FormControlLabel,
  Checkbox,
  InputAdornment,
  Link,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import HomeIcon from 'assets/png/dashboardactive.svg';
import ArrowIcon from 'assets/png/breadarrow.svg';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import BackImg from 'assets/png/interior.png';
import CTLogo from 'assets/png/cthiring_new_logo.png';
import { To, useNavigate } from 'react-router-dom';
import BanImg from 'assets/png/pro-banner.png';
import { styled } from '@mui/material/styles';
import { yupResolver } from '@hookform/resolvers/yup';
import { changepasswordSchema } from 'utils/ValidatorSchema';
import { Controller, useForm } from 'react-hook-form';
import CustomInput from 'components/CustomInput/customInput';
import { useRef, useState } from 'react';
import { deleteData, updateData } from 'services/CommonServices';
import Loader from 'components/loader';
import { toast } from 'react-toastify';
import { alertOptions } from 'utils/Config';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { logout } from 'store/actions/UserAction';
import { MASTER_ENDPOINT } from 'utils/Constant';

const Dashboard = () => {
  const subtgref: React.MutableRefObject<any> = useRef();
  const dispatch = useAppDispatch();
  const userDetail: any = useAppSelector((store: any) => store.userLogin.userInfo);
  const [Ld, setLd] = useState(false);
  const {
    handleSubmit,
    control,
    reset: resetForm,
    formState: { errors },
    setValue,
    watch,
  } = useForm<{
    newPassword: any;
    confirmPassword: any;
  }>({
    defaultValues: {
      newPassword: '',
      confirmPassword: '',
    },
    reValidateMode: 'onChange',
    resolver: yupResolver(changepasswordSchema),
  });

  const logoutsession = () => {
    logout(dispatch);
    deleteData(userDetail.refreshTokenId, MASTER_ENDPOINT.Logout)
      .then((res: any) => {
        // toast.success('Logged out successfully', alertOptions);
      })
      .catch((e) => {
        // console.log(e);
      });
  };

  const onSubmitForm = async (data: any) => {
    setLd(true);
    if (watch('newPassword') !== watch('confirmPassword')) {
      toast.error(`Passwords don't match`, alertOptions);
      setLd(!true);
      return;
    }
    if (!/[a-z]+/.test(watch('newPassword'))) {
      toast.error('Password must contain atleast one lower case letter', alertOptions);
      setLd(!true);
      return;
    }
    try {
      const updatedpsd = await updateData('', data, 'password/change', true);
      logoutsession();
      // console.log(updatedpsd, 'updated');
    } catch (error) {
      // console.log(error);
    } finally {
      setLd(false);
      resetForm();
    }
  };
  return (
    <>
      <Grid container>
        <Grid item md={12} sm={12} xs={12}>
          <Typography component={'div'} className='breadcrumbs d-flex-a'>
            <Breadcrumbs aria-label='breadcrumb'>
              <Link underline='hover' color='inherit' href='/'>
                <img src={HomeIcon} className='home' alt='Home' />
              </Link>
              <Link underline='hover' color='inherit' href={'/dashboard'}>
                <img src={ArrowIcon} alt='arrow' className='arrows' />
                Dashboard
              </Link>
            </Breadcrumbs>
          </Typography>

          <Typography component={'div'} className='d-flex-a flex-sb title-add-box'>
            <Typography className='page-title'>Dashboard</Typography>
          </Typography>
          <Typography component={'div'} className='main-content'>
            <Typography component={'div'} className='white-box'></Typography>
          </Typography>
        </Grid>
      </Grid>
      {Ld && <Loader />}
    </>
  );
};

export default Dashboard;
