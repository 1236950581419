/* eslint-disable @typescript-eslint/no-unused-vars */
import { yupResolver } from '@hookform/resolvers/yup';
import { Grid, Typography, Button, Rating, Box, Modal } from '@mui/material';
import CustomInput from 'components/CustomInput/customInput';
import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { addNewData, getAllListData, getSingleData } from 'services/CommonServices';
import { PositionENDPOINT, ResumesENDPOINT } from 'utils/Constant';
import Loader from 'components/loader';
import CloseIcon from '@mui/icons-material/Close';
import { resumeConsultantSchema } from 'utils/ValidatorSchema';
import ClientSuccessIcon from 'assets/png/clientsuccessicon.svg';
import _ from 'lodash';

type defaultprops = {
  positionId: string;
  orgId: string;
  clientId: string;
  step: number;
  setStep: (val: any) => void;
  loader: boolean;
  setLoader: (val: boolean) => void;
  resumeIds: string;
  resumeData: (val: number) => void;
};

const ResumeConsultant: React.FC<defaultprops> = ({
  positionId,
  clientId,
  orgId,
  step,
  setStep,
  loader,
  setLoader,
  resumeIds,
  resumeData,
}) => {
  const [draftCheck, setDraftCheck] = useState<boolean>();
  const [searchParams]: any = useSearchParams();
  const [behaviouralSkills, setBehaviouralSkills] = useState([]);
  const [technicalSkills, setTechnicalSkills] = useState([]);
  const [rating, setRating] = useState([]);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const [resumeId, setResumeId] = useState('');
  const [personalData, setPersonalData] = useState<any>([]);
  const {
    control: control,
    handleSubmit: handleSubmit,
    // reset: resetForm,
    setValue,
    getValues,
    // setError,
    // watch,
    formState: { errors },
  } = useForm<any>({
    criteriaMode: 'all',
    defaultValues: {
      technicalSkills: [],
      behaviouralSkills: [],
      consultantAssessment: '',
      interviewAvailability: '',
      comments: '',
      achievements: '',
      isSaved: false,
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(resumeConsultantSchema),
  });
  const handleClosectg = () => {
    back();
    setOpen(false);
  };
  const draftConsultant = () => {
    const assessmentData = {
      isSaved: false,
      consultantAssessment: getValues('consultantAssessment'),
      interviewAvailability: getValues('interviewAvailability'),
      achievements: getValues('achievements'),
      comments: getValues('comments'),
      technicalSkillData: getValues('technicalSkills'),
      behaviouralSkillData: getValues('behaviouralSkills'),
    };
    const formdata: any = new FormData();
    formdata.append('resumeId', resumeId);
    formdata.append('resumeTabType', 'assessment');
    formdata.append('assessmentData', JSON.stringify(assessmentData));

    addNewData(
      formdata,
      `${ResumesENDPOINT.resumeList}`,
      {
        'Content-Type': 'multipart/form-data',
      },
      true,
    )
      .then(() => {
        setLoader(false);
        navigate('/resumes');
      })
      .catch((e) => {
        setLoader(false);
        console.log(e);
      });
  };
  const onSubmitForm = (data: any) => {
    setLoader(true);
    const assessmentData = {
      isSaved: true,
      consultantAssessment: data.consultantAssessment,
      interviewAvailability: data.interviewAvailability,
      comments: data.comments,
      achievements: data.achievements,
      technicalSkillData: data.technicalSkills,
      behaviouralSkillData: data.behaviouralSkills,
    };
    const formdata: any = new FormData();
    formdata.append('resumeId', resumeId);
    formdata.append('resumeTabType', 'assessment');
    formdata.append('assessmentData', JSON.stringify(assessmentData));

    addNewData(
      formdata,
      `${ResumesENDPOINT.resumeList}`,
      {
        'Content-Type': 'multipart/form-data',
      },
      true,
    )
      .then(() => {
        setLoader(false);
        back();
        setOpen(true);
      })
      .catch((e) => {
        setLoader(false);
        console.log(e);
      });
  };
  const back = () => {
    navigate('/resumes');
  };
  React.useEffect(() => {
    if (positionId) {
      getAllListData(
        `${PositionENDPOINT.position}?organizationId=${orgId}&clientId=${clientId}&id=${positionId}`,
      )
        .then(async (res: any) => {
          setBehaviouralSkills(res?.data[0]?.behaviouralSkills);
          setTechnicalSkills(res?.data[0]?.technicalSkills);
          const behaviouralSkills = res?.data[0]?.behaviouralSkills.map(
            (re: any, index: number) => {
              if (re) {
                setValue(`behaviouralSkills.${index}.behaviouralSkillsId`, re.id);
              }
              return;
            },
          );
          await Promise.all(behaviouralSkills);
          const technicalSkills = res?.data[0]?.technicalSkills.map((re: any, index: number) => {
            if (re) {
              setValue(`technicalSkills.${index}.technicalSkillsId`, re.id);
            }
            return;
          });
          await Promise.all(technicalSkills);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, [positionId]);

  const getInitialStatus = () => {
    if (searchParams.get('id') || resumeIds) {
      setLoader(true);
      getSingleData(searchParams.get('id') || resumeIds, ResumesENDPOINT.resumeList)
        .then(async (resp: any) => {
          resumeData(resp.data[0]);
          setResumeId(resp.data && resp.data[0]?.id);
          setPersonalData(resp.data[0].personal);
          setDraftCheck(resp.data[0].approvalStatus === 'draft' ? true : false);
          if (resp && !_.isEmpty(resp.data) && !_.isEmpty(resp.data[0])) {
            const dat: any = resp.data[0]?.personal;
            setValue('consultantAssessment', dat.consultantAssessment);
            setValue('achievements', dat.achievements);
            setValue('comments', dat.comments);
            setValue('isSaved', resp?.data[0]?.assessment[0]?.isSaved);
            setValue('interviewAvailability', dat.interviewAvailability);
            const behaviouralSkills = resp?.data[0]?.assessment
              .filter((re: any) => re.behaviouralSkillsId)
              .map((re: any, index: number) => {
                if (re.behaviouralSkillsId) {
                  setValue(
                    `behaviouralSkills.${index}.behaviouralSkillsId`,
                    re.behaviouralSkillsId,
                  );
                  setValue(`behaviouralSkills.${index}.rating`, re.rating);
                  setValue(`behaviouralSkills.${index}.id`, re.id);
                }
                return;
              });
            await Promise.all(behaviouralSkills);
            const technicalSkills = resp?.data[0]?.assessment
              .filter((re: any) => re.technicalSkillsId)
              .map((re: any, index: number) => {
                if (re.technicalSkillsId) {
                  setValue(`technicalSkills.${index}.technicalSkillsId`, re.technicalSkillsId);
                  setValue(`technicalSkills.${index}.rating`, re.rating);
                  setValue(`technicalSkills.${index}.id`, re.id);
                }
                return;
              });
            await Promise.all(technicalSkills);
          }

          setLoader(false);
        })
        .catch((e: any) => {
          setLoader(false);
          console.log(e);
        });
    }
  };
  React.useEffect(() => {
    getInitialStatus();
    setResumeId(resumeIds);
  }, [resumeIds]);

  return (
    <Grid container>
      {loader && <Loader />}
      <Grid item md={12} sm={12} xs={12}>
        <Typography component={'div'} className='step-parent-box'>
          <Typography component={'div'} className='d-flex-a flex-sb'>
            <Typography className='stepper-title'>Consultant Assessment </Typography>
            <Typography className='d-flex-a'>
              <Button
                className='cancel-btn mr-16'
                onClick={() => {
                  if (personalData.expYears === 0) {
                    setStep(1);
                  } else {
                    setStep(2);
                  }
                }}>
                Back
              </Button>
              <Button
                className='go-to-clnt-list-btn  mr-16'
                disabled={!draftCheck ? true : false}
                sx={{ cursor: !draftCheck ? 'not-allowed' : 'pointer' }}
                onClick={draftConsultant}>
                Draft
              </Button>
              <Button className='s-add-btn' form={'consultant-form'} type={'submit'}>
                Save
              </Button>
            </Typography>
          </Typography>
          <Typography className='bdiveder' />
          <form
            className='private-form'
            id={'consultant-form'}
            onSubmit={handleSubmit(onSubmitForm)}>
            <Grid container columnSpacing={4.25}>
              <Grid item md={12} sm={12} xs={12}>
                <Typography component={'div'} className='custom-field'>
                  <Typography component={'p'}>Rate Technical Skills</Typography>
                  <Typography
                    component={'div'}
                    sx={{
                      display: 'flex',
                      flexWrap: 'wrap',
                    }}
                    columnGap={5}>
                    {technicalSkills &&
                      technicalSkills.map((re: any, index: number) => {
                        return (
                          <div key={index} style={{ width: '30%', marginTop: '15px' }}>
                            <div style={{ display: 'flex', justifyContent: 'end' }}>
                              <Controller
                                defaultValue={0}
                                render={({ field: { onChange, value, name } }) => (
                                  <>
                                    <Rating
                                      sx={{ color: '#EE4B22 ', marginRight: '7px' }}
                                      name={name}
                                      value={value}
                                      onChange={(event, newValue) => {
                                        onChange(event);
                                        setValue(
                                          `technicalSkills.${index}.technicalSkillsId`,
                                          re.id,
                                        );
                                      }}
                                    />
                                    <div
                                      style={{
                                        width: '20px',
                                        height: '20px',
                                        top: '448px',
                                        left: '609px',
                                        padding: '0px 6px 5px 6px',
                                        borderRadius: '4px',
                                        border: '1px solid #FDEDE9',
                                        gap: '6px',
                                        background: '#FDEDE9',
                                        fontSize: '15px',
                                      }}>
                                      {value}
                                    </div>
                                  </>
                                )}
                                name={`technicalSkills.${index}.rating`}
                                control={control}
                              />
                            </div>
                            <CustomInput
                              disabled
                              value={re.skill}
                              className='custom-input'
                              placeHolder=''
                              error={errors.technicalSkills?.message}
                            />
                          </div>
                        );
                      })}
                  </Typography>
                </Typography>
              </Grid>
              <Grid item md={12} sm={12} xs={12}>
                <Typography component={'div'} className='custom-field'>
                  <Typography component={'p'}>Rate Behavioural Skills</Typography>
                  <Typography
                    component={'div'}
                    sx={{
                      display: 'flex',
                      flexWrap: 'wrap',
                    }}
                    columnGap={5}>
                    {behaviouralSkills &&
                      behaviouralSkills.map((re: any, index: number) => {
                        return (
                          <div key={index} style={{ width: '30%', marginTop: '15px' }}>
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'end',
                              }}>
                              <Controller
                                defaultValue={0}
                                render={({ field: { onChange, value, name } }) => (
                                  <>
                                    <Rating
                                      sx={{ color: '#EE4B22 ', marginRight: '7px' }}
                                      name={name}
                                      value={value}
                                      onChange={(event, newValue) => {
                                        onChange(event);
                                        setValue(
                                          `behaviouralSkills.${index}.behaviouralSkillsId`,
                                          re.id,
                                        );
                                      }}
                                    />
                                    <div
                                      style={{
                                        width: '20px',
                                        height: '20px',
                                        top: '448px',
                                        left: '609px',
                                        padding: '0px 6px 5px 6px',
                                        borderRadius: '4px',
                                        border: '1px solid #FDEDE9',
                                        gap: '6px',
                                        background: '#FDEDE9',
                                        fontSize: '15px',
                                      }}>
                                      {value}
                                    </div>
                                  </>
                                )}
                                name={`behaviouralSkills.${index}.rating`}
                                control={control}
                              />
                            </div>
                            <CustomInput
                              disabled
                              value={re.skill}
                              className='custom-input'
                              placeHolder=''
                              error={errors.behaviouralSkills?.message}
                            />
                          </div>
                        );
                      })}
                  </Typography>
                </Typography>
              </Grid>
              <Grid container item columnSpacing={4.25}>
                <Grid item md={6} sm={12} xs={12} sx={{ paddingBottom: '12px' }}>
                  <Typography component={'div'} className='custom-field'>
                    <Typography component={'p'}>
                      Noteworthy Contributions / Achievements <span>*</span>
                    </Typography>
                    <Controller
                      render={({ field: { onChange, value, name } }) => (
                        <CustomInput
                          value={value ? value : ''}
                          name={name}
                          isTextArea={true}
                          className='custom-input custom-input-position'
                          placeHolder='Noteworthy Contributions / Achievements'
                          error={errors.achievements?.message}
                          onChange={(e) => {
                            onChange(e);
                          }}
                        />
                      )}
                      name='achievements'
                      control={control}
                    />
                  </Typography>
                </Grid>
                <Grid item md={6} sm={12} xs={12} sx={{ paddingBottom: '12px' }}>
                  <Typography component={'div'} className='custom-field'>
                    <Typography component={'p'}>
                      Reasons for Shortlisting <span>*</span>
                    </Typography>
                    <Controller
                      render={({ field: { onChange, value, name } }) => (
                        <CustomInput
                          value={value ? value : ''}
                          name={name}
                          isTextArea={true}
                          className='custom-input custom-input-position'
                          placeHolder='Reasons for Shortlisting'
                          error={errors.consultantAssessment?.message}
                          onChange={(e) => {
                            onChange(e);
                          }}
                        />
                      )}
                      name='consultantAssessment'
                      control={control}
                    />
                  </Typography>
                </Grid>
              </Grid>
              <Grid container item columnSpacing={4.25}>
                <Grid item md={6} sm={12} xs={12} sx={{ paddingBottom: '12px' }}>
                  <Typography component={'div'} className='custom-field'>
                    <Typography component={'p'}>
                      Interview Availability <span>*</span>
                    </Typography>
                    <Controller
                      render={({ field: { onChange, value, name } }) => (
                        <CustomInput
                          value={value ? value : ''}
                          name={name}
                          isTextArea={true}
                          className='custom-input custom-input-position'
                          placeHolder='Interview Availability'
                          error={errors.interviewAvailability?.message}
                          onChange={(e) => {
                            onChange(e);
                          }}
                        />
                      )}
                      name='interviewAvailability'
                      control={control}
                    />
                  </Typography>
                </Grid>
                <Grid item md={6} sm={12} xs={12} sx={{ paddingBottom: '12px' }}>
                  <Typography component={'div'} className='custom-field'>
                    <Typography component={'p'}>Any Other Inputs</Typography>
                    <Controller
                      render={({ field: { onChange, value, name } }) => (
                        <CustomInput
                          value={value ? value : ''}
                          name={name}
                          isTextArea={true}
                          className='custom-input custom-input-position'
                          placeHolder='Any Other Inputs'
                          error={errors.comments?.message}
                          onChange={(e) => {
                            onChange(e);
                          }}
                        />
                      )}
                      name='comments'
                      control={control}
                    />
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Typography>
      </Grid>
      <Modal
        open={open}
        onClose={handleClosectg}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'>
        <Box className='logout-modal' style={{ paddingLeft: '0px', paddingRight: '0px' }}>
          <Typography
            className='d-flex-ja mb-24'
            sx={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
            <img src={ClientSuccessIcon} alt='client success' />
            <Typography className='client-sucess-con'>
              <Typography className='title' style={{ marginBottom: '9px' }}>
                Resume Creation Successful!
              </Typography>
              <Typography className='approval' style={{ marginBottom: '24px' }}>
                Awaiting Approval
              </Typography>
            </Typography>
            <Button className='client-success-modal-btn' onClick={handleClosectg}>
              Ok
            </Button>
          </Typography>
          <CloseIcon
            onClick={handleClosectg}
            style={{
              cursor: 'pointer',
              height: '20px',
              width: '20px',
              position: 'absolute',
              top: '16px',
              right: '16px',
            }}
          />
        </Box>
      </Modal>
    </Grid>
  );
};
export default ResumeConsultant;
