import { Breadcrumbs, Button, Grid, Link, Typography } from '@mui/material';
import HomeIcon from 'assets/png/dashboardactive.svg';
import { To, useNavigate, useSearchParams } from 'react-router-dom';
import { AdminPrivateRoutes } from 'routes/routeConstans';
import ArrowIcon from 'assets/png/breadarrow.svg';
import { useEffect, useState } from 'react';
import { getAllListData } from 'services/CommonServices';
import { BillingENDPOINT } from 'utils/Constant';
import Loader from 'components/loader';

export const ClientAgreementView: any = () => {
  const navigate = useNavigate();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [commonState, setcommonstate] = useState<any[]>([]);
  const [isLoading, setLoading] = useState(true);
  const [searchParams] = useSearchParams();
  const gotoNavigate = (link: To) => {
    navigate(link);
  };

  useEffect(() => {
    getAllListData(`${BillingENDPOINT.clientAgreement}?id=${searchParams.get('id')}`, true)
      .then((val: any) => {
        if (val && val.data && val.data.length > 0) {
          setcommonstate([...val.data]);
        } else {
          setcommonstate([]);
        }
        setLoading(!true);
      })
      .catch(() => {
        setLoading(!true);
      });
  }, [searchParams.get('id')]);

  return (
    <Grid container>
      {isLoading && <Loader />}
      <Grid item md={12} sm={12} xs={12}>
        <Typography component={'div'} className='breadcrumbs d-flex-a'>
          <Breadcrumbs aria-label='breadcrumb'>
            <Link underline='hover' href='/'>
              <img src={HomeIcon} className='home' alt='Home' />
            </Link>
            <Typography
              color='text.primary'
              sx={{ cursor: 'pointer' }}
              onClick={() => gotoNavigate(AdminPrivateRoutes.CLIENTAGREEMENT.CLIENTAGREEMENTLIST)}>
              {' '}
              <img src={ArrowIcon} alt='arrow' className='arrows' />
              Agreements
            </Typography>
            <Typography color='text.primary' sx={{ cursor: 'pointer' }}>
              {' '}
              <img src={ArrowIcon} alt='arrow' className='arrows' /> View Agreement
            </Typography>
          </Breadcrumbs>
        </Typography>
        <Typography
          component={'div'}
          className='d-flex-a flex-sb title-add-box'
          sx={{ paddingRight: '56px' }}>
          <Typography className='page-title'>View Agreement</Typography>
          <Button
            className='cancel-btn mr-16'
            onClick={() => gotoNavigate(AdminPrivateRoutes.CLIENTAGREEMENT.CLIENTAGREEMENTLIST)}>
            Back
          </Button>
        </Typography>
      </Grid>
    </Grid>
  );
};
