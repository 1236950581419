import { yupResolver } from '@hookform/resolvers/yup';
import { TabContext, TabList } from '@mui/lab';
import { Box, Button, Divider, Drawer, Grid, Tab, Typography } from '@mui/material';
import Close from 'assets/png/Close-drawer.svg';
import CustomInput from 'components/CustomInput/customInput';
import CustomSelect from 'components/CustomSelect/customSelect';
import DateTimepicker from 'components/Datepicker/AntdDateTimePicker';
import Loader from 'components/loader';
import _ from 'lodash';
import moment from 'moment';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { addNewData, getAllListData } from 'services/CommonServices';
import { useAppSelector } from 'store/hooks';
import { ApplyLeaveType } from 'types';
import { LEAVE_ENDPOINT, SETTING_ENDPOINT } from 'utils/Constant';
import { AccessDenied } from 'utils/helper';
import { ApplyLeaveSchema } from 'utils/ValidatorSchema';

const ApplyLeave = (props: {
  toggleDrawer: (open: boolean) => void;
  drawerOpen: boolean;
  getLeaveData: () => void;
  getLeavePolicyData: () => void;
}) => {
  const { toggleDrawer, drawerOpen, getLeaveData, getLeavePolicyData } = props;
  const [tabValue, setTabValue] = useState<string>('1');
  const {
    userInfo: { id, organizationId, branchId },
  }: any = useAppSelector((store: any) => store.userLogin);
  const [policyType, setPolicyType] = useState<any>({
    label: [],
    value: [],
  });
  const navigate = useNavigate();
  const [days, setDays] = useState<number>(0);
  const [leaveList, setLeaveList] = useState<any>([]);
  const [startDate, setStartDate] = useState<string>('');
  const [endDate, setEndDate] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(true);
  const currentDate = new Date();
  const day = String(currentDate.getDate()).padStart(2, '0');
  const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const year = currentDate.getFullYear();

  const handleChange = (event: any, newValue: React.SetStateAction<string>) => {
    setTabValue(newValue);
    setValue('sessionFrom', '');
    setValue('sessionTo', '');
    if (Number(newValue) === 1 && days >= 1) {
      setValue('sessionFrom', '1');
      setValue('sessionTo', '2');
    } else if (Number(newValue) === 2 && days === 1) {
      setValue('sessionTo', '2');
    }
  };
  const {
    control,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm<ApplyLeaveType>({
    criteriaMode: 'all',
    defaultValues: {
      leaveFrom: '',
      leaveTo: '',
      leaveType: '',
      reason: '',
      sessionFrom: '',
      sessionTo: '',
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(ApplyLeaveSchema),
  });
  const onSubmitForm = (data: ApplyLeaveType) => {
    setLoading(true);
    const bodyData = {
      leavePolicyId: data.leaveType,
      fromDate: data.leaveFrom,
      toDate: data.leaveTo,
      isFullDayLeave: Number(tabValue) === 1,
      sessionFrom: Number(tabValue) === 1 ? 1 : Number(data.sessionFrom),
      sessionTo: Number(tabValue) === 2 ? Number(data.sessionTo) : 2,
      numOfDays: days,
      note: data.reason,
    };
    addNewData(bodyData, LEAVE_ENDPOINT.leaves, '', true)
      .then(() => {
        toggleDrawer(false);
        clearData();
        setLoading(false);
        getLeaveData();
        getLeavePolicyData();
      })
      .catch((e) => {
        setLoading(false);
        if (e === 'Forbidden resource') {
          toast.error(AccessDenied);
          navigate('/dashboard');
        }
      });
  };
  const clearData = () => {
    reset();
    setStartDate('');
    setEndDate('');
    setDays(0);
    setTabValue('1');
  };

  const findDays = () => {
    if (!_.isEmpty(startDate) && !_.isEmpty(endDate)) {
      let noOfDays = 0;
      for (
        let date = new Date(startDate);
        date <= new Date(endDate);
        date.setDate(date.getDate() + 1)
      ) {
        if (moment(date).format('dddd').toLowerCase() !== 'sunday') {
          noOfDays += 1;
        }
      }
      // const noOfDays = end.diff(start, 'days');
      setDays(noOfDays);
    }
  };

  React.useEffect(() => {
    getAllListData(
      `${LEAVE_ENDPOINT.policyTypeGet}?status=true&organizationId=${organizationId}&userId=${id}`,
    )
      .then((res) => {
        const options: any = [];
        res.data.map((e: any) => {
          options.push({ label: e.type, value: e.policies[0].id });
        });
        setPolicyType(options);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, []);

  React.useEffect(() => {
    findDays();
  }, [startDate, endDate]);

  const getLeaveList = () => {
    setLoading(true);
    getAllListData(
      `${SETTING_ENDPOINT.leaves.holidays}?organizationId=${organizationId}&branchId=${
        branchId ? branchId : ''
      }&status=true`,
      true,
    )
      .then((res: any) => {
        const final = res?.data?.filter((re: any) => re.status).map((es: any) => es.holidayOn);
        setLeaveList((pre: any) => [...pre, ...final]);
        setLoading(false);
      })
      .catch(() => {
        // console.log(e);
        setLoading(false);
      });
  };

  React.useEffect(() => {
    getLeaveList();
  }, []);

  return (
    <>
      {loading && <Loader />}
      <Drawer
        anchor={'right'}
        open={drawerOpen}
        onClose={() => toggleDrawer(false)}
        sx={{ zIndex: 1202 }}>
        <Box sx={{ width: '500px', height: '100%', overflowY: 'hidden' }}>
          <Typography sx={{ height: '80%', overflow: 'auto' }}>
            <Typography
              sx={{
                width: '100%',
                height: '96px',
                backgroundColor: '#F2F4F7',
                display: 'flex',
                alignItems: 'center',
                paddingLeft: '32px',
                paddingRight: '25px',
                justifyContent: 'space-between',
              }}>
              <Typography sx={{ fontWeight: '600', fontSize: '20px' }}>Request Leave</Typography>
              <Typography
                sx={{
                  width: '20px',
                  height: '20px',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  toggleDrawer(false);
                  clearData();
                }}>
                <img src={Close} alt='close icon' />
              </Typography>
            </Typography>

            <form
              className='private-form'
              id={'applyLeaveForm'}
              onSubmit={handleSubmit(onSubmitForm)}>
              <Grid
                container
                md={12}
                sm={12}
                xs={12}
                sx={{ paddingLeft: '32px', paddingRight: '25px', marginTop: '31px' }}>
                <Grid
                  container
                  md={6}
                  sm={6}
                  xs={6}
                  sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography component={'div'} className='custom-field' sx={{ width: '95%' }}>
                    <Typography component={'p'}>
                      Leave From<span>*</span>
                    </Typography>
                    <Controller
                      control={control}
                      defaultValue={''}
                      name='leaveFrom'
                      render={({ field: { onChange, value, name, ref: refField } }) => {
                        return (
                          <>
                            <DateTimepicker
                              defaultValue={value}
                              disableDate={leaveList}
                              ref={refField}
                              name={name}
                              style={{
                                width: '100%',
                                padding: '8px 11px',
                                ...(errors?.leaveFrom?.message ? { borderColor: 'red' } : {}),
                              }}
                              onChange={(date: any, dateString: string) => {
                                setStartDate(dateString);
                                // setValue('leaveTo', dateString);
                                findDays();

                                onChange(dateString ? dateString : null);
                              }}
                            />
                            <div className='errorposition'>
                              {errors?.leaveFrom?.message ? errors?.leaveFrom.message : ''}
                            </div>
                          </>
                          // <CustomInput
                          //   id='date'
                          //   type='date'
                          //   className='clientfilter-time-from-to'
                          //   onChange={(e) => {
                          //     onChange(e);
                          //     setStartDate(e.target.value);
                          //     // setValue('leaveFrom', e.target.value);
                          //     findDays();
                          //   }}
                          //   error={errors?.leaveFrom?.message}
                          //   InputLabelProps={{
                          //     shrink: true,
                          //   }}
                          // />
                        );
                      }}
                    />
                  </Typography>
                </Grid>
                <Grid
                  container
                  md={6}
                  sm={6}
                  xs={6}
                  sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography
                    component={'div'}
                    className='custom-field'
                    sx={{ width: '80%', marginLeft: '20px' }}>
                    <Typography component={'p'}>
                      Leave To<span>*</span>
                    </Typography>
                    <Controller
                      control={control}
                      defaultValue={''}
                      name='leaveTo'
                      render={({ field: { onChange, value, name, ref: refField } }) => (
                        <>
                          <DateTimepicker
                            defaultValue={value}
                            disableDate={leaveList}
                            ref={refField}
                            name={name}
                            // className={`clientfilter-time-from-to ${
                            //   errors?.leaveTo?.message && errors?.leaveTo?.message
                            //     ? 'clientfilter-time-from-to-error'
                            //     : ''
                            // }`}
                            style={{
                              width: '100%',
                              padding: '8px 11px',
                              ...(errors?.leaveTo?.message ? { borderColor: 'red' } : {}),
                            }}
                            onChange={(date: any, dateString: string) => {
                              setEndDate(dateString);
                              // setValue('leaveTo', dateString);
                              findDays();

                              onChange(dateString ? dateString : null);
                            }}
                            min={watch('leaveFrom') || `${year}-${month}-${day}`}
                          />
                          <div className='errorposition'>
                            {errors?.leaveTo?.message ? errors?.leaveTo.message : ''}
                          </div>
                        </>
                        // <CustomInput
                        //   id='date'
                        //   type='date'
                        //   className='clientfilter-time-from-to'
                        //   onChange={(e) => {
                        //     onChange(e);
                        //     setEndDate(e.target.value);
                        //     // setValue('leaveTo', e.target.value);
                        //     findDays();
                        //   }}
                        //   error={errors?.leaveTo?.message}
                        //   InputLabelProps={{
                        //     shrink: true,
                        //   }}
                        //   inputProps={{
                        //     inputProps: { min: watch('leaveFrom') || `${year}-${month}-${day}` },
                        //   }}
                        // />
                      )}
                    />
                  </Typography>
                </Grid>

                <Grid
                  container
                  md={12}
                  sm={12}
                  xs={12}
                  sx={{ display: 'flex', flexDirection: 'column', marginTop: '10px' }}>
                  <Typography component={'div'} className='custom-field' sx={{ width: '95%' }}>
                    <Typography component={'p'}>
                      Leave Type<span>*</span>
                    </Typography>

                    <Controller
                      control={control}
                      defaultValue={''}
                      name={'leaveType'}
                      // name={'modeOfEducation'}
                      render={({ field: { onChange, value, name } }) => {
                        return (
                          <CustomSelect
                            labelKey={'label'}
                            valueKey={'value'}
                            options={policyType}
                            name={name}
                            placeHolder='Select'
                            value={value}
                            onChange={(e) => {
                              onChange(e);
                              setValue('sessionFrom', '1');
                              setValue('sessionTo', '2');
                            }}
                            error={errors?.leaveType?.message}
                          />
                        );
                      }}
                    />
                  </Typography>
                </Grid>
                {watch('leaveType') && (
                  <Grid item md={12} sm={12} xs={12} sx={{ marginTop: '15px' }}>
                    <Typography component={'div'} className='custom-field'>
                      <Typography component={'p'}>
                        Session<span>*</span>
                      </Typography>
                      <Box>
                        <TabContext value={tabValue}>
                          <Typography
                            component={'div'}
                            className='d-flex-a d-flex-sb custom-position-tab'>
                            <Box
                              sx={{
                                borderColor: 'divider',
                                borderRadius: '8px',
                                background: '#F5F6FA',
                                padding: '5px',
                              }}>
                              <TabList onChange={handleChange} aria-label='lab API tabs example'>
                                <Tab label='Full day' value='1' />
                                <Tab label='Custom' value='2' />
                              </TabList>
                            </Box>
                          </Typography>
                        </TabContext>
                      </Box>
                    </Typography>
                  </Grid>
                )}

                <Grid container md={12} sm={12} xs={12}>
                  {!_.isEmpty(tabValue) && Number(tabValue) !== 1 && (
                    <Grid container md={6} sm={6} xs={6}>
                      <Typography component={'div'} className='custom-field' sx={{ width: '90%' }}>
                        <Typography component={'p'}>
                          {days > 1 ? 'From' : 'On'}{' '}
                          {startDate && moment(startDate).format('DD-MM-YYYY')}
                        </Typography>

                        <Controller
                          control={control}
                          defaultValue={''}
                          name={'sessionFrom'}
                          // name={'modeOfEducation'}
                          render={({ field: { onChange, value, name } }) => {
                            return (
                              <CustomSelect
                                labelKey={'label'}
                                valueKey={'value'}
                                options={[
                                  { label: 'First Half', value: 1 },
                                  { label: 'Second Half', value: 2 },
                                ]}
                                name={name}
                                placeHolder='Select'
                                value={value}
                                onChange={(e) => {
                                  onChange(e);
                                  if (days === 1) {
                                    setValue('sessionTo', e.target.value);
                                  }
                                }}
                                error={errors?.sessionFrom?.message}
                              />
                            );
                          }}
                        />
                      </Typography>
                    </Grid>
                  )}
                  {days > 1 && Number(tabValue) == 2 && (
                    <Grid
                      container
                      md={6}
                      sm={6}
                      xs={6}
                      sx={{ display: 'flex', flexDirection: 'column' }}>
                      <Typography component={'div'} className='custom-field' sx={{ width: '90%' }}>
                        <Typography component={'p'}>
                          To {endDate && moment(endDate).format('DD-MM-YYYY')}
                        </Typography>
                        <Controller
                          control={control}
                          defaultValue={''}
                          name={'sessionTo'}
                          // name={'modeOfEducation'}
                          render={({ field: { onChange, value, name } }) => {
                            return (
                              <CustomSelect
                                labelKey={'label'}
                                valueKey={'value'}
                                options={[
                                  { label: 'First Half', value: 1 },
                                  { label: 'Second Half', value: 2 },
                                ]}
                                name={name}
                                placeHolder='Select'
                                value={value}
                                onChange={onChange}
                                error={errors?.sessionTo?.message}
                              />
                            );
                          }}
                        />
                      </Typography>
                    </Grid>
                  )}
                </Grid>
                <Grid
                  container
                  md={12}
                  sm={12}
                  xs={12}
                  sx={{ display: 'flex', flexDirection: 'column', marginTop: '15px !important' }}>
                  <Typography component={'div'} className='custom-field'>
                    <Typography component={'p'}>
                      Reason<span>*</span>
                    </Typography>

                    <Controller
                      control={control}
                      defaultValue={''}
                      name={'reason'}
                      render={({ field: { onChange, value, name } }) => {
                        return (
                          <CustomInput
                            extraStyle={{ width: '95%' }}
                            className='custom-input'
                            value={value}
                            name={name}
                            placeHolder='Type here'
                            isTextArea={true}
                            onChange={onChange}
                            error={errors?.reason?.message}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        );
                      }}
                    />
                  </Typography>
                </Grid>
              </Grid>
            </form>
          </Typography>

          <Typography sx={{ height: '15%' }}>
            <Divider sx={{ marginTop: '16px' }} />

            <Typography className='d-flex-a flex-end mt-30' sx={{ paddingRight: '30px' }}>
              <Button
                className='s-cancel-btn mr-16'
                onClick={() => (toggleDrawer(false), clearData())}>
                Cancel
              </Button>

              <Button className='resume-save-btn' type='submit' form={'applyLeaveForm'}>
                Submit
              </Button>
            </Typography>
          </Typography>
        </Box>
      </Drawer>
    </>
  );
};

export default ApplyLeave;
