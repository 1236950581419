import { yupResolver } from '@hookform/resolvers/yup';
import { Grid, Typography, Popover, Box, Button, Modal } from '@mui/material';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { CvShortListSchema } from 'utils/ValidatorSchema';
import { resumeStatusToBgColor } from 'utils/helper';
import CvReject from 'assets/png/cv-reject.svg';
import CvApprove from 'assets/png/cv-approve.svg';
import { CvShortListType } from 'types';
import CustomInput from 'components/CustomInput/customInput';
import CustomSelect from 'components/CustomSelect/customSelect';
import { cvRejectReason } from 'utils/Config';
import CloseIcon from 'assets/png/close.svg';
import { patchData } from 'services/CommonServices';
import { ResumesENDPOINT } from 'utils/Constant';
import { StatusTag } from './statusTag';

export const ScreeningComponent: React.FC<{
  resumeId: string;
  candidateName: string;
  getList: () => void;
  screeningStatus: any;
  userId: string;
  crmUserId: string;
}> = ({ screeningStatus, candidateName, resumeId, getList, userId, crmUserId }) => {
  const [panchorEl, setPAnchorEl] = React.useState<HTMLAnchorElement | null>(null);
  const [isRejectModalOpen, setRejectModalOpen] = React.useState(false);
  const open = Boolean(panchorEl);
  const paid = open ? 'simple-popover' : undefined;
  const handleClose = () => {
    setPAnchorEl(null);
  };
  const handleModalClose = () => {
    reset();
    setRejectModalOpen(false);
  };
  const handleModalOpen = (status: string) => {
    setValue('status', status);
    setPAnchorEl(null);
    setRejectModalOpen(true);
  };
  const handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    setPAnchorEl(event.currentTarget);
  };

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
    getValues,
  } = useForm({ resolver: yupResolver(CvShortListSchema) });

  const onSubmit = (data: CvShortListType) => {
    patchData('', { ...data }, `${ResumesENDPOINT.resumeList}/screening-status/${resumeId}`, true)
      .then(() => {
        handleModalClose();
        getList();
      })
      .catch((e: any) => console.log(e));
  };
  return (
    <div>
      <Popover
        id={paid}
        anchorEl={panchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        style={{ width: '100%', padding: '1rem' }}>
        <Typography component={'div'}>
          <Typography
            onClick={() => handleModalOpen('S')}
            className='menu-item'
            style={{ padding: '0px 15px 0px 15px' }}>
            <img src={CvReject} alt='approve' />
            Shortlist
          </Typography>
          <Typography
            onClick={() => handleModalOpen('R')}
            className='menu-item'
            style={{ padding: '0px 15px 0px 15px' }}>
            <img src={CvApprove} alt='reject' />
            Reject
          </Typography>
        </Typography>
      </Popover>
      <Typography component={'p'}>
        <StatusTag
          color='#4A5569'
          backgroundColor={resumeStatusToBgColor[screeningStatus]}
          tag={screeningStatus}
          userId={userId}
          crmUserId={crmUserId}
          onClick={(e: any) => {
            screeningStatus === 'FA' ? userId === crmUserId && handleClick(e) : '';
          }}
        />
      </Typography>
      <Modal open={isRejectModalOpen} onClose={handleModalClose}>
        <Box className='logout-modal p-32'>
          <Typography
            component={'div'}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginBottom: '1rem',
            }}>
            <Typography sx={{ fontSize: '20px', fontWeight: '600' }}>
              {getValues('status') === 'R' ? 'Reject CV' : 'Shortlist CV'}
            </Typography>
            <Typography className='text-right'>
              <img
                src={CloseIcon}
                alt='close'
                style={{ cursor: 'pointer' }}
                onClick={handleModalClose}
              />
            </Typography>
          </Typography>
          <form id='cv-reject' className='private-form' onSubmit={handleSubmit(onSubmit)}>
            <Grid container>
              <Grid item md={12} sm={12} xs={12}>
                <Typography component={'div'} className='custom-field'>
                  <Typography component={'p'}>Candidate Name</Typography>
                  <CustomInput
                    disabled
                    value={candidateName}
                    placeHolder='Candidate Name'
                    className='custom-input'
                  />
                </Typography>
              </Grid>
              {getValues('status') === 'R' && (
                <Grid item md={12} sm={12} xs={12}>
                  <Typography component={'div'} className='custom-field'>
                    <Typography component={'p'}>
                      Reason <span>*</span>
                    </Typography>
                    <Controller
                      name='reason'
                      control={control}
                      render={({ field: { onChange, value, name } }) => (
                        <CustomSelect
                          labelKey={'label'}
                          valueKey={'value'}
                          options={cvRejectReason}
                          name={name}
                          value={value}
                          onChange={(e) => {
                            onChange(e);
                          }}
                          placeHolder='Reason'
                          error={errors.reason?.message}
                        />
                      )}
                    />
                  </Typography>
                </Grid>
              )}
              <Grid item md={12} sm={12} xs={12}>
                <Typography component={'div'} className='custom-field'>
                  <Typography component={'p'}>Remarks</Typography>
                  <Controller
                    name='remarks'
                    control={control}
                    render={({ field: { onChange, value, name } }) => (
                      <CustomInput
                        name={name}
                        value={value}
                        isTextArea={true}
                        onChange={(e) => {
                          onChange(e);
                        }}
                        className='custom-input'
                        placeHolder='Remarks'
                        error={errors.remarks?.message}
                      />
                    )}
                  />
                </Typography>
              </Grid>
              <Grid item md={12} sm={12} xs={12}>
                <Typography
                  component={'div'}
                  sx={{ justifyContent: 'flex-end' }}
                  className='d-flex-a'>
                  <Button className='cancel-btn mr-16' onClick={handleModalClose}>
                    Cancel
                  </Button>
                  <Button form={'cv-reject'} className='s-add-btn' type='submit'>
                    Submit
                  </Button>
                </Typography>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Modal>
    </div>
  );
};
