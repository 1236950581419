import {
  Breadcrumbs,
  Button,
  // Button,
  // Checkbox,
  // FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  TextField,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import HomeIcon from 'assets/png/dashboardactive.svg';
import ArrowIcon from 'assets/png/breadarrow.svg';
import EditIcon from 'assets/png/edit.svg';
// import EditIcon from 'assets/png/edit.svg';
// import DeleteIcon from 'assets/png/delete.svg';
import AddIcon from 'assets/png/addplus.svg';
import SearchIcon from 'assets/png/nsearch.svg';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { To, useNavigate } from 'react-router-dom';
import { getAllListData } from 'services/CommonServices';
import { useEffect, useRef, useState } from 'react';
import { useDebounce } from 'hooks';
import { PositionENDPOINT } from 'utils/Constant';
import ConfirmDialog from 'components/ConfirmDialog/ConfirmDialog';
import Loader from 'components/loader';
import { useAppSelector } from 'store/hooks';
import UP from 'assets/png/up.svg';
import DOWN from 'assets/png/down.svg';
import Paginate from 'components/Pagination/Pagination';
import { AdminPrivateRoutes } from 'routes/routeConstans';
// import PdfIcon from 'assets/iconcomponents/pdficon';
// import ExcelIcon from 'assets/iconcomponents/excelicon';
import PreIcon from 'assets/png/pre.svg';
import NCAdedIcon from 'assets/iconcomponents/noclientsadded';
import Funnelimg from 'assets/png/FilterFunnel.svg';
import * as R from 'ramda';
import moment from 'moment';
import _ from 'lodash';
import TableTooltip from 'components/Tooltip/Tabletooltip';
import ExcelIcon from 'assets/iconcomponents/excelicon';
import { PositionFilterDrawer } from './PositionFilter/PositionFilterDrawer';
import { toast } from 'react-toastify';
import { AccessDenied } from 'utils/helper';

const CheckIcon = styled('span')(({ theme }) => ({
  borderRadius: 4,
  width: 16,
  height: 16,
  // boxShadow:
  //   theme.palette.mode === 'dark'
  //     ? '0 0 0 1px rgb(16 22 26 / 40%)'
  //     : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
  backgroundColor: theme.palette.mode === 'dark' ? '#EFEFEF' : '#EFEFEF',
  // backgroundImage:
  //   theme.palette.mode === 'dark'
  //     ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
  //     : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
  '.Mui-focusVisible &': {
    outline: '1px auto #D0D5DD',
    outlineOffset: 1,
  },
  'input:hover ~ &': {
    backgroundColor: theme.palette.mode === 'dark' ? '#ffffff' : '#ffffff',
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background: theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
  },
}));

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const CheckedIcon = styled(CheckIcon)({
  backgroundColor: '#0061FF',
  // backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&:before': {
    display: 'block',
    width: 16,
    height: 16,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: '#0061FF',
  },
});

function createData(
  name?: string,
  calories?: number,
  fat?: number,
  carbs?: number,
  protein?: number,
) {
  return { name, calories, fat, carbs, protein };
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const rows = [
  createData('Frozen yoghurt'),
  createData('Ice cream sandwich'),
  createData('Eclair'),
  createData('Cupcake'),
  createData('Gingerbread'),
];

const Positions = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const {
    menuList,
    userInfo: { organizationId: orgdy, id: Useruniqueid },
  }: any = useAppSelector((store: any) => store.userLogin);
  const Defaultparam = {
    search: '',
    order: '',
    skip: 0,
    sortby: '',
    status: '',
    take: 50,
    from: '',
    to: '',
    crm: '',
    organizationId: '',
    location: '',
    approvalStatus: '',
    userId: '',
  };
  const [positionModule, setpositionModule] = useState([]);
  const navigate = useNavigate();
  const [commonstate, setcommonstate] = useState<any[]>([]);
  const [pagetotal, setpagetotal] = useState<number>(0);
  const [approvetotal, setapprovetotal] = useState<number>(0);
  const [crmApprovalTotal, setCrmApprovalTotal] = useState<number>(0);
  const [params, setParams] = useState<any>({ ...Defaultparam });

  const [Ld, setLd] = useState(false);
  const debouncedParams = useDebounce(params, 500);
  const [confirmOpen, setConfirmOpen] = useState<any>({
    show: false,
    id: '',
  });
  const [scrollPosition, setScrollPosition] = useState(0);
  const containerRef = useRef(null) as any;
  // Function to handle scrolling when the button is clicked
  const handleScroll = (scrollAmount: number) => {
    // Calculate the new scroll position
    const newScrollPosition = scrollPosition + scrollAmount;
    // Update the state with the new scroll position
    if (newScrollPosition >= 0 && newScrollPosition <= 800) {
      setScrollPosition(newScrollPosition);
      const element = containerRef.current;
      // Access the container element and set its scrollLeft property
      element.scrollLeft = newScrollPosition;
    }
  };

  const gotoNavigate = (link: To, state?: any) => {
    navigate(link, state);
  };

  const listdataapi = () => {
    const searcharr = [
      `${orgdy ? `organizationId=${orgdy}` : null}`,
      `${debouncedParams.search ? `search=${encodeURIComponent(debouncedParams.search)}` : null}`,
      `${
        debouncedParams.approvalStatus ? `approvalStatus=${debouncedParams.approvalStatus}` : null
      }`,
      `${debouncedParams.from ? `from=${debouncedParams.from}` : null}`,
      `${debouncedParams.to ? `to=${debouncedParams.to}` : null}`,
      `${debouncedParams.crm ? `crmId=${debouncedParams.crm}` : null}`,
      `${debouncedParams.order ? `order=${debouncedParams.order}` : null}`,
      `${debouncedParams.location ? `location=${debouncedParams.location}` : null}`,
      `${debouncedParams.sortby ? `sortby=${debouncedParams.sortby}` : null}`,
      `${debouncedParams.userId ? `userId=${debouncedParams.userId}` : null}`,
      `${
        debouncedParams.status
          ? `status=${
              debouncedParams.status === 'active'
                ? true
                : debouncedParams.status === 'inActive'
                  ? false
                  : null
            }`
          : null
      }`,
      `${
        debouncedParams.skip
          ? `skip=${debouncedParams.skip}`
          : debouncedParams.take
            ? 'skip=0'
            : null
      }`,
      `${debouncedParams.take ? `take=${debouncedParams.take}` : null}`,
      'screenType=view',
    ]
      .filter((ea: any) => ea !== 'null')
      .join('&');
    setLd(true);
    getAllListData(
      `${PositionENDPOINT.position}${Object.values({ ...params }).length > 0 && '?'}${searcharr}`,
      true,
    )
      .then((val: any) => {
        val && val.totalAwaitingApproval ? setapprovetotal(val.totalAwaitingApproval) : '';
        val && val.totalCRMApprovalPending ? setCrmApprovalTotal(val.totalCRMApprovalPending) : '';
        if (val && val.data && val.data.length > 0) {
          setcommonstate([...val.data]);
          setpagetotal(Number(val.total));
        } else {
          setcommonstate([]);
        }
        setLd(!true);
      })
      .catch((e) => {
        if (e === 'Forbidden resource') {
          toast.error(AccessDenied);
          navigate('/dashboard');
        }
        setLd(!true);
      });
  };

  useEffect(() => {
    listdataapi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedParams]);

  const sortablecliks: (sor: string, ord: string) => void = (sor: string, ord: string) => {
    setParams((prev: any) => ({
      ...prev,
      sortby: sor ? sor : null,
      order: ord ? ord : null,
    }));
  };

  function handlePag(sk: number, tk: number) {
    setParams((prev: any) => ({
      ...prev,
      skip: sk,
      take: tk,
    }));
  }

  const localstorageclear: any = () => {
    localStorage.setItem('clientcrm', JSON.stringify([]));
    localStorage.setItem('clientcrm2', JSON.stringify([]));
    localStorage.setItem('clientdetail', JSON.stringify({}));
    localStorage.setItem('clientspoc', JSON.stringify([]));

    localStorage.setItem('refreshstate', JSON.stringify({}));
  };

  const [dweropen, setdweropen] = useState(false);
  const dweropn = () => setdweropen(true);
  const dwerclose = () => setdweropen(false);

  // position vals
  useEffect(() => {
    if (!_.isEmpty(menuList)) {
      const settingData = menuList.find((e: any) => e.moduleName === 'Position');
      setpositionModule(settingData && settingData.screens ? settingData.screens : {});
    }
  }, [menuList]);

  console.log(positionModule);

  return (
    <>
      <Grid container>
        <Grid item md={12} sm={12} xs={12}>
          <Typography component={'div'} className='breadcrumbs d-flex-a'>
            <Breadcrumbs aria-label='breadcrumb'>
              <Link underline='hover' color='inherit' href='/'>
                <img src={HomeIcon} className='home' alt='Home' />
              </Link>
              {/* <Link underline='hover' color='inherit' href=''>
                <img src={ArrowIcon} alt='arrow' className='arrows' />
                Setting
              </Link> */}
              <Typography
                color='text.primary'
                sx={{ cursor: 'pointer' }}
                onClick={() => gotoNavigate(AdminPrivateRoutes.POSITIONS.POSITIONSVIEW)}>
                {' '}
                <img src={ArrowIcon} alt='arrow' className='arrows' /> Positions
              </Typography>
            </Breadcrumbs>
          </Typography>

          <Typography
            component={'div'}
            className='d-flex-a flex-sb title-add-box'
            sx={{ paddingRight: '56px' }}>
            <Typography className='page-title'>Positions</Typography>
            <Typography
              component={'div'}
              style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <div className='expot-icon'>
                <span>
                  <ExcelIcon
                    url={`${PositionENDPOINT.position}${
                      Object.values({ ...params }).length > 0 && '?'
                    }${[
                      `${orgdy ? `organizationId=${orgdy}` : null}`,
                      `${
                        debouncedParams.search
                          ? `search=${encodeURIComponent(debouncedParams.search)}`
                          : null
                      }`,
                      `${
                        debouncedParams.approvalStatus
                          ? `approvalStatus=${debouncedParams.approvalStatus}`
                          : null
                      }`,
                      `${debouncedParams.from ? `from=${debouncedParams.from}` : null}`,
                      `${debouncedParams.to ? `to=${debouncedParams.to}` : null}`,
                      `${debouncedParams.crm ? `crmId=${debouncedParams.crm}` : null}`,
                      `${debouncedParams.location ? `location=${debouncedParams.location}` : null}`,
                      `${debouncedParams.userId ? `userId=${debouncedParams.userId}` : null}`,
                      `${
                        debouncedParams.status
                          ? `status=${
                              debouncedParams.status === 'active'
                                ? true
                                : debouncedParams.status === 'inActive'
                                  ? false
                                  : null
                            }`
                          : null
                      }`,
                      'order=desc',
                      'sortby=createdAt',
                      'type=export',
                      'screenType=view',
                    ]
                      .filter((ea: any) => ea !== 'null')
                      .join('&')}`}
                    fileName={'positions'}
                    setLoader={setLd}
                  />
                </span>
                <span style={{ width: '12.17px' }}></span>
              </div>
              {crmApprovalTotal > 0 && (
                <Typography
                  component={'div'}
                  className='add-btn-apruv mr-16'
                  onClick={() => {
                    gotoNavigate(AdminPrivateRoutes.POSITIONS.CRMAPPROVAL);
                  }}>
                  <Typography component={'div'}>CRM Change Approval</Typography>{' '}
                  <Typography className='apruv-cunt'>{crmApprovalTotal}</Typography>
                </Typography>
              )}
              {R.findIndex(R.propEq('Approve Position', 'screenName'))(
                positionModule ? positionModule : [],
              ) !== -1 ? (
                <Typography
                  component={'div'}
                  className='add-btn-apruv'
                  onClick={() => {
                    if (approvetotal > 0) {
                      gotoNavigate(AdminPrivateRoutes.POSITIONS.POSITIONSAPPROVE);
                    }
                  }}>
                  <Typography component={'div'}>Approve Position</Typography>{' '}
                  <Typography className='apruv-cunt'>{approvetotal}</Typography>
                </Typography>
              ) : (
                ''
              )}

              <div style={{ width: '16px' }}></div>
              {R.findIndex(R.propEq('Add Position', 'screenName'))(
                positionModule ? positionModule : [],
              ) !== -1 ? (
                <Typography
                  component={'div'}
                  className='client-add-btn'
                  onClick={() => gotoNavigate(AdminPrivateRoutes.POSITIONS.POSITIONSADD)}>
                  <img src={AddIcon} alt='plus' />
                  <Typography onClick={localstorageclear}>Add Position</Typography>
                </Typography>
              ) : (
                ''
              )}
            </Typography>
          </Typography>
          <Typography component={'div'} className='main-content'>
            <Typography component={'div'} className='white-box'>
              <Typography component={'div'} className='d-flex-cc flex-sb'>
                <TextField
                  className='custom-search'
                  placeholder='Search'
                  id='outlined-start-adornment'
                  value={params.search}
                  sx={{ width: '320px' }}
                  onChange={(e: any) =>
                    setParams((prev: any) => ({
                      ...prev,
                      search: e.target.value,
                      skip: 0,
                      take: 50,
                    }))
                  }
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <img src={SearchIcon} alt='search' />
                      </InputAdornment>
                    ),
                    endAdornment: params.search ? (
                      <InputAdornment position='end'>
                        <IconButton
                          aria-label='toggle password visibility'
                          edge='end'
                          onClick={() => {
                            setParams(() => ({ ...Defaultparam, search: '' }));
                          }}>
                          <CloseIcon style={{ height: '20px', width: '20px' }} />
                        </IconButton>
                      </InputAdornment>
                    ) : null,
                  }}
                />
                <Typography
                  component={'div'}
                  className='d-flex-cc flex-sb d-flex-nwrp filterfunnel-con'
                  onClick={dweropn}>
                  <img src={Funnelimg} alt='filter funnel icon' />
                  <span className='filterfunnel'>More Filters</span>
                </Typography>
              </Typography>
              <Typography component={'div'} className='mt-32' sx={{ position: 'relative' }}>
                <Button onClick={() => handleScroll(-200)} className='pre-btn'>
                  <img src={PreIcon} alt='' />
                </Button>
                <Button onClick={() => handleScroll(200)} className='next-btn'>
                  <img src={PreIcon} alt='' style={{ transform: 'rotate(180deg)' }} />
                </Button>
                <TableContainer component={Paper} className='sb-table2' ref={containerRef}>
                  <Table sx={{ minWidth: 750 }} className='custom-table' aria-label='simple table'>
                    <TableHead>
                      <TableRow
                        sx={{
                          marginLeft: '50px',
                          marginRight: '43px',
                          // width: 'calc(100% - 93px)',
                        }}>
                        <TableCell sx={{ whiteSpace: 'nowrap' }}>
                          Job Title
                          <span className='sorting'>
                            <img
                              src={UP}
                              alt='asc'
                              className='up'
                              onClick={() => sortablecliks('title', 'asc')}
                            />
                            <img
                              src={DOWN}
                              alt='desc'
                              className='down'
                              onClick={() => sortablecliks('title', 'desc')}
                            />
                          </span>
                        </TableCell>
                        <TableCell sx={{ whiteSpace: 'nowrap' }}>
                          Client
                          <span className='sorting'>
                            <img
                              src={UP}
                              alt='asc'
                              className='up'
                              onClick={() => sortablecliks('client', 'asc')}
                            />
                            <img
                              src={DOWN}
                              alt='desc'
                              className='down'
                              onClick={() => sortablecliks('client', 'desc')}
                            />
                          </span>
                        </TableCell>
                        <TableCell sx={{ whiteSpace: 'nowrap' }}>
                          Total Openings
                          <span className='sorting'>
                            <img
                              src={UP}
                              alt='asc'
                              className='up'
                              onClick={() => sortablecliks('totalOpenins', 'asc')}
                            />
                            <img
                              src={DOWN}
                              alt='desc'
                              className='down'
                              onClick={() => sortablecliks('totalOpenins', 'desc')}
                            />
                          </span>
                        </TableCell>
                        <TableCell sx={{ whiteSpace: 'nowrap' }}>
                          CRM
                          <span className='sorting'>
                            <img
                              src={UP}
                              alt='asc'
                              className='up'
                              onClick={() => sortablecliks('crm', 'asc')}
                            />
                            <img
                              src={DOWN}
                              alt='desc'
                              className='down'
                              onClick={() => sortablecliks('crm', 'desc')}
                            />
                          </span>
                        </TableCell>
                        <TableCell sx={{ whiteSpace: 'nowrap' }}>
                          Recruiters
                          <span className='sorting'>
                            <img
                              src={UP}
                              alt='asc'
                              className='up'
                              onClick={() => sortablecliks('recruiter', 'asc')}
                            />
                            <img
                              src={DOWN}
                              alt='desc'
                              className='down'
                              onClick={() => sortablecliks('recruiter', 'desc')}
                            />
                          </span>
                        </TableCell>
                        <TableCell align='center' sx={{ whiteSpace: 'nowrap' }}>
                          CV Sent
                          {/* <span className='sorting'>
                            <img
                              src={UP}
                              alt='asc'
                              className='up'
                              onClick={() => sortablecliks('createdBy', 'asc')}
                            />
                            <img
                              src={DOWN}
                              alt='desc'
                              className='down'
                              onClick={() => sortablecliks('createdBy', 'desc')}
                            />
                          </span> */}
                        </TableCell>
                        <TableCell sx={{ whiteSpace: 'nowrap' }}>
                          Joined
                          {/* <span className='sorting'>
                            <img
                              src={UP}
                              alt='asc'
                              className='up'
                              onClick={() => sortablecliks('createdBy', 'asc')}
                            />
                            <img
                              src={DOWN}
                              alt='desc'
                              className='down'
                              onClick={() => sortablecliks('createdBy', 'desc')}
                            />
                          </span> */}
                        </TableCell>
                        <TableCell align='center' sx={{ whiteSpace: 'nowrap' }}>
                          Status
                          <span className='sorting'>
                            <img
                              src={UP}
                              alt='asc'
                              className='up'
                              onClick={() => sortablecliks('approvalStatus', 'desc')}
                            />
                            <img
                              src={DOWN}
                              alt='desc'
                              className='down'
                              onClick={() => sortablecliks('approvalStatus', 'asc')}
                            />
                          </span>
                        </TableCell>
                        <TableCell sx={{ whiteSpace: 'nowrap' }}>
                          Created By
                          <span className='sorting'>
                            <img
                              src={UP}
                              alt='asc'
                              className='up'
                              onClick={() => sortablecliks('user', 'asc')}
                            />
                            <img
                              src={DOWN}
                              alt='desc'
                              className='down'
                              onClick={() => sortablecliks('user', 'desc')}
                            />
                          </span>
                        </TableCell>
                        <TableCell sx={{ whiteSpace: 'nowrap' }}>
                          Created On
                          <span className='sorting'>
                            <img
                              src={UP}
                              alt='asc'
                              className='up'
                              onClick={() => sortablecliks('createdAt', 'asc')}
                            />
                            <img
                              src={DOWN}
                              alt='desc'
                              className='down'
                              onClick={() => sortablecliks('createdAt', 'desc')}
                            />
                          </span>
                        </TableCell>
                        <TableCell sx={{ whiteSpace: 'nowrap' }}>
                          Modified On
                          <span className='sorting'>
                            <img
                              src={UP}
                              alt='asc'
                              className='up'
                              onClick={() => sortablecliks('updatedAt', 'asc')}
                            />
                            <img
                              src={DOWN}
                              alt='desc'
                              className='down'
                              onClick={() => sortablecliks('updatedAt', 'desc')}
                            />
                          </span>
                        </TableCell>

                        <TableCell sx={{ whiteSpace: 'nowrap' }}>
                          Action
                          {/* <span className='sorting'>
                            <img
                              src={UP}
                              alt='asc'
                              className='up'
                              onClick={() => sortablecliks('status', 'asc')}
                            />
                            <img
                              src={DOWN}
                              alt='desc'
                              className='down'
                              onClick={() => sortablecliks('status', 'desc')}
                            />
                          </span> */}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {commonstate && commonstate.length > 0 ? (
                        commonstate.map((row) => (
                          <TableRow
                            key={row.id}
                            sx={{
                              '&:last-child td, &:last-child th': { border: 0 },
                              // verticalAlign: 'baseline',
                              marginLeft: '50px',
                              marginRight: '43px',
                              // width: 'calc(100% - 93px)',
                            }}>
                            <TableCell
                              sx={{
                                textTransform: 'capitalize',
                                whiteSpace: 'nowrap',
                                minWidth: '120px',
                              }}>
                              <div
                                onClick={() => {
                                  gotoNavigate(
                                    `${AdminPrivateRoutes.POSITIONS.VIEWPOSITIONS}?id=${
                                      row.id
                                    }&screen=${
                                      R.findIndex(R.propEq('View Position', 'screenName'))(
                                        positionModule ? positionModule : [],
                                      ) !== -1
                                        ? true
                                        : false
                                    }`,
                                    {
                                      state: {
                                        edit:
                                          R.findIndex(R.propEq('Add Position', 'screenName'))(
                                            positionModule ? positionModule : [],
                                          ) !== -1 &&
                                          row?.crm[0]?.crm?.user?.id === Useruniqueid &&
                                          row.approvalStatus !== 'billed',
                                      },
                                    },
                                  );
                                  // !_.isEmpty(row.createdBy) && row.createdBy === Useruniqueid ?  gotoNavigate(`${AdminPrivateRoutes.POSITIONS.VIEWPOSITIONS}?id=${row.id}`) :'';
                                }}
                                style={{
                                  color: '#5F2EE5',
                                  cursor:
                                    R.findIndex(R.propEq('View Position', 'screenName'))(
                                      positionModule ? positionModule : [],
                                    ) !== -1
                                      ? 'pointer'
                                      : 'default',
                                }}>
                                {row?.title?.length > 9 ? (
                                  <TableTooltip
                                    style={{ color: '#5F2EE5' }}
                                    value={row && row.title ? `${row.title}` : '-'}
                                    position={true}></TableTooltip>
                                ) : (
                                  row?.title
                                )}
                              </div>
                            </TableCell>
                            <TableCell sx={{ textTransform: 'capitalize', minWidth: '110px' }}>
                              {row?.client?.corporateName?.length > 9 ? (
                                <TableTooltip
                                  value={
                                    row && row.client && !_.isEmpty(row.client.corporateName)
                                      ? row.client.corporateName
                                      : '-'
                                  }
                                />
                              ) : (
                                row?.client?.corporateName
                              )}
                            </TableCell>
                            <TableCell
                              sx={{
                                textTransform: 'capitalize',
                                minWidth: '110px',
                                textAlign: 'center !important',
                              }}>
                              {row && row.totalOpenins ? row.totalOpenins : 0}
                            </TableCell>
                            <TableCell
                              sx={{
                                textTransform: 'capitalize',
                                maxWidth: '200px',
                                minWidth: '115px',
                                hypens: 'auto',
                              }}>
                              {`${row?.crm[0]?.crm?.user?.firstName} ${row?.crm[0]?.crm?.user?.lastName}`
                                .length > 9 ? (
                                <TableTooltip
                                  value={
                                    row?.crm?.length
                                      ? `${row?.crm[0]?.crm?.user?.firstName} ${row?.crm[0]?.crm?.user?.lastName}`
                                      : '-'
                                  }
                                />
                              ) : (
                                `${row?.crm[0]?.crm?.user?.firstName} ${row?.crm[0]?.crm?.user?.lastName}`
                              )}
                            </TableCell>
                            <TableCell
                              sx={{
                                textTransform: 'capitalize',
                                maxWidth: '200px',
                                minWidth: '115px',
                                hypens: 'auto',
                              }}>
                              <TableTooltip
                                value={
                                  row && row.recruiters
                                    ? row.recruiters
                                        .map((val1: any) => {
                                          if (
                                            !_.isEmpty(val1) &&
                                            !_.isEmpty(val1.user) &&
                                            !_.isEmpty(val1.user.firstName) &&
                                            !_.isEmpty(val1.user.lastName)
                                          ) {
                                            return `${val1.user.firstName} ${val1.user.lastName}`;
                                          } else {
                                            return '-';
                                          }
                                        })
                                        .toString()
                                    : '-'
                                }
                              />
                            </TableCell>

                            <TableCell
                              align='center'
                              sx={{
                                textTransform: 'capitalize',
                                minWidth: '100px',
                                hyphens: 'auto',
                              }}>
                              {row?.cvSent ? row.cvSent : '-'}
                            </TableCell>

                            <TableCell
                              sx={{
                                textTransform: 'capitalize',
                                minWidth: '120px',
                                hyphens: 'auto',
                              }}>
                              {row && row.expectedJoiningDate
                                ? moment(row.expectedJoiningDate).format('DD-MM-YYYY')
                                : ' '}
                            </TableCell>
                            <TableCell
                              align='center'
                              sx={{
                                textTransform: 'capitalize',
                                hyphens: 'auto',
                                minWidth: '150px',
                              }}>
                              {row &&
                              row.approvalStatus &&
                              row &&
                              row.approvalStatus === 'awaitingApproval' ? (
                                <button className='position-view-btn awaitingApproval'>
                                  <span>Awaiting Approval</span>
                                </button>
                              ) : (
                                <></>
                              )}
                              {row &&
                              row.approvalStatus &&
                              row &&
                              row.approvalStatus === 'rejected' ? (
                                <button className='position-view-btn rejected'>
                                  <span>Rejected</span>
                                </button>
                              ) : (
                                <></>
                              )}
                              {row &&
                              row.approvalStatus &&
                              row &&
                              row.approvalStatus === 'assigned' ? (
                                <button className='position-view-btn assigned'>
                                  <span>Assigned</span>
                                </button>
                              ) : (
                                <></>
                              )}
                              {row &&
                              row.approvalStatus &&
                              row &&
                              row.approvalStatus === 'inProcess' ? (
                                <button className='position-view-btn inProcess'>
                                  <span>In Process</span>
                                </button>
                              ) : (
                                <></>
                              )}
                              {row &&
                              row.approvalStatus &&
                              row &&
                              row.approvalStatus === 'onHold' ? (
                                <button className='position-view-btn onHold'>
                                  <span>On Hold</span>
                                </button>
                              ) : (
                                <></>
                              )}
                              {row &&
                              row.approvalStatus &&
                              row &&
                              row.approvalStatus === 'billed' ? (
                                <button className='position-view-btn billed'>
                                  <span>Billed</span>
                                </button>
                              ) : (
                                <></>
                              )}
                              {row &&
                              row.approvalStatus &&
                              row &&
                              row.approvalStatus === 'terminated' ? (
                                <button className='position-view-btn terminated'>
                                  <span>Terminated</span>
                                </button>
                              ) : (
                                <></>
                              )}
                            </TableCell>
                            <TableCell sx={{ textTransform: 'capitalize', minWidth: '150px' }}>
                              <TableTooltip
                                value={
                                  row &&
                                  row.user &&
                                  !_.isEmpty(row.user.firstName) &&
                                  !_.isEmpty(row.user.lastName)
                                    ? `${row.user.firstName} ${row.user.lastName}`
                                    : '-'
                                }></TableTooltip>
                            </TableCell>
                            <TableCell sx={{ textTransform: 'capitalize', minWidth: '150px' }}>
                              {row && row.createdAt
                                ? moment(row.createdAt).format('DD-MM-YYYY')
                                : ' '}
                            </TableCell>
                            <TableCell sx={{ textTransform: 'capitalize', minWidth: '100px' }}>
                              {row && row.history && row.history[0] && row.history[0].createdAt
                                ? moment(row.history[0].createdAt).format('DD-MM-YYYY')
                                : '-'}
                            </TableCell>
                            {R.findIndex(R.propEq('Add Position', 'screenName'))(
                              positionModule ? positionModule : [],
                            ) !== -1 &&
                            row?.crm[0]?.crm?.user?.id === Useruniqueid &&
                            row.approvalStatus !== 'billed' ? (
                              <TableCell sx={{ textTransform: 'capitalize', minWidth: '100px' }}>
                                <span style={{ marginLeft: '16px' }}>
                                  <img
                                    src={EditIcon}
                                    alt='Edit'
                                    className='pointer'
                                    onClick={() => {
                                      // console.log(clientreducer,'clientreducer');
                                      gotoNavigate(
                                        `${AdminPrivateRoutes.POSITIONS.POSITIONSADD}?mode=edit&positionid=${row.id}`,
                                      );
                                    }}
                                  />
                                </span>
                              </TableCell>
                            ) : (
                              <TableCell sx={{ textTransform: 'capitalize', minWidth: '100px' }}>
                                <span style={{ marginLeft: '16px' }}>
                                  <img
                                    src={EditIcon}
                                    alt='Edit'
                                    // className='pointer'
                                    style={{ filter: 'saturate(10%)', opacity: '50%' }}
                                  />
                                </span>
                              </TableCell>
                            )}
                          </TableRow>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={10} align={'center'}>
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginBottom: '43px',
                              }}>
                              <NCAdedIcon />
                              <Typography className='no-client'>No Position added</Typography>
                            </div>
                          </td>
                        </tr>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Typography>
              <Paginate total={pagetotal} setfn={handlePag} DefaultPerPage={50} />
            </Typography>
          </Typography>
        </Grid>

        <PositionFilterDrawer
          setParams={setParams}
          isOpen={dweropen}
          closeDrawer={dwerclose}
          // callback={handleFilterPag}
          setcommonstate={setcommonstate}
          setLoading={setLd}
          setpagetotal={setpagetotal}
          listdataapi={listdataapi}
          Defaultparam={Defaultparam}
        />
        <ConfirmDialog
          title='Confirm'
          floatingBtnShow={true}
          open={confirmOpen.show}
          close={() =>
            setConfirmOpen({
              show: false,
              id: '',
            })
          }
          visibleClose={true}
          onConfirm={(act: any) =>
            act === 'yes'
              ? ''
              : setConfirmOpen({
                  show: false,
                  id: '',
                })
          }
          autoCloseDisable={true}>
          {'Are you sure do you want to delete?'}
        </ConfirmDialog>
      </Grid>
      {Ld && <Loader />}
    </>
  );
};

export default Positions;
