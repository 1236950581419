import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { TagsInput } from 'react-tag-input-component';

const CustomTagInput = ({
  value,
  name,
  placeHolder,
  onChange,
  error,
  disabled,
}: {
  value: any;
  name: string;
  placeHolder?: string;
  onChange: any;
  error?: boolean;
  disabled?: boolean;
}) => {
  const [selected, setSelected] = useState([]);
  useEffect(() => {
    setSelected(!_.isEmpty(value) ? value : []);
  }, [value]);
  return (
    <div className={`taginput ${error ? 'tagselecterror' : ''} ${disabled ? 'disabled' : ''}`}>
      <TagsInput
        value={selected}
        onChange={onChange}
        disabled={disabled}
        separators={[',', 'Enter']}
        classNames={{ input: 'tag-select' }}
        // onChange={setSelected}
        name={name}
        placeHolder={placeHolder ? placeHolder : ''}
      />
    </div>
  );
};

export default CustomTagInput;
