import { Grid, TextField, Typography } from '@mui/material';
import CustomSelect from 'components/CustomSelect/customSelect';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import {
  Control,
  Controller,
  FormState,
  UseFormHandleSubmit,
  UseFormTrigger,
  UseFormWatch,
} from 'react-hook-form';
import { getAllListData } from 'services/CommonServices';
import { useAppSelector } from 'store/hooks';
import { MyplanFilterType } from 'types';
import { ClientsENDPOINT } from 'utils/Constant';

interface ClientFilterFormProps {
  control: Control<MyplanFilterType, any>;
  handleSubmit: UseFormHandleSubmit<MyplanFilterType, undefined>;
  formState: FormState<MyplanFilterType>;
  onSubmit: (data: MyplanFilterType) => void;
  watch: UseFormWatch<MyplanFilterType>;
  trigger: UseFormTrigger<MyplanFilterType>;
  setParams: any;
}

export const PlanFilterForm: React.FC<ClientFilterFormProps> = ({
  control,
  handleSubmit,
  formState: { errors },
  onSubmit,
  watch,
}) => {
  const [Statusdropwn] = useState<any>([
    { label: '1st half', value: 'firstHalf' },
    { label: '2nd half', value: 'secondHalf' },
    { label: 'Full day', value: 'fullDay' },
  ]);
  const [crmdropwn, setcrmdropwn] = useState<any>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [Degrilis, setDegrilis] = useState<any>([
    { label: 'Sourcing', value: 'sourcing' },
    { label: 'Others', value: 'others' },
  ]);
  const {
    userInfo: { organizationId: orgdy },
  }: any = useAppSelector((store: any) => store.userLogin);
  const currentDate = new Date();
  const day = String(currentDate.getDate()).padStart(2, '0');
  const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const year = currentDate.getFullYear();
  const [fromMaxDate, setFromMaxDate] = useState<string | null>(null);
  const [toMaxDate, setToMaxDate] = useState<string | null>(null);

  const getPositionvalues = async () => {
    try {
      const getapi = await getAllListData(
        `${ClientsENDPOINT.Client}?organizationId=${orgdy}&approvalStatus=${'approved'}&status=true`,
        true,
      );

      if (!_.isEmpty(getapi) && !_.isEmpty(getapi.data)) {
        const mappedcrm: any = getapi.data.map((p2: any) => {
          if (!_.isEmpty(p2)) {
            return {
              label: p2 && p2.corporateName ? `${p2.corporateName}` : '',
              value: p2.id ? p2.id : '',
            };
          }
        });
        !_.isEmpty(mappedcrm) ? setcrmdropwn(mappedcrm) : '';
      }
    } catch (er) {
      // console.log(er, 'er');
    } finally {
      // console.log('finally');
    }
  };
  useEffect(() => {
    getPositionvalues();
  }, []);

  return (
    <form className='private-form clientfilter-form' onSubmit={handleSubmit(onSubmit)}>
      <Grid container>
        <Grid item md={12} sm={12} xs={12} sx={{ width: '100%' }}>
          <Grid container sx={{ width: '100%' }}>
            <Grid item md={12} sm={12} xs={12} sx={{ width: '100%' }}>
              <Typography component={'div'} className='custom-field' sx={{ width: '100%' }}>
                <Typography component={'p'}>From Date</Typography>
                {/* <TextField className="custom-input" id="standard-basic" placeholder="" variant="standard" /> */}
                <Controller
                  control={control}
                  defaultValue={''}
                  name='fromDate'
                  render={({ field: { onChange, value } }) => {
                    return (
                      <TextField
                        id='date'
                        type='date'
                        defaultValue={value}
                        className='clientfilter-time-from-to'
                        onChange={(e) => {
                          setFromMaxDate(e.target.value);
                          onChange(e);
                        }}
                        // className={classes.textField}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        InputProps={{
                          inputProps: { max: toMaxDate || `${year}-${month}-${day}` },
                        }}
                      />
                    );
                  }}
                />
              </Typography>
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <Typography component={'div'} className='custom-field'>
                <Typography component={'p'}>To Date</Typography>
                {/* <TextField className="custom-input" id="standard-basic" placeholder="" variant="standard" /> */}
                <Controller
                  control={control}
                  defaultValue={''}
                  name='toDate'
                  render={({ field: { onChange, value } }) => {
                    return (
                      <TextField
                        id='date'
                        type='date'
                        className='clientfilter-time-from-to'
                        defaultValue={value}
                        onChange={(e) => {
                          setToMaxDate(e.target.value);
                          onChange(e);
                        }}
                        // className={classes.textField}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        InputProps={{
                          inputProps: { min: fromMaxDate, max: `${year}-${month}-${day}` },
                        }}
                      />
                    );
                  }}
                />
              </Typography>
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <Typography component={'div'} className='custom-field'>
                <Typography component={'p'}>Session</Typography>
                {/* <TextField className="custom-input" id="standard-basic" placeholder="" variant="standard" /> */}
                <Controller
                  control={control}
                  name='session'
                  render={({ field: { onChange, value, name } }) => {
                    return (
                      <CustomSelect
                        labelKey={'label'}
                        valueKey={'value'}
                        isClearable={false}
                        options={Statusdropwn}
                        name={name}
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                        }}
                        placeHolder='Select Session'
                        error={errors.session?.message}
                      />
                    );
                  }}
                />
              </Typography>
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <Typography component={'div'} className='custom-field'>
                {/* <Typography component={'p'}>CRM</Typography> */}
                <Typography component={'p'}>Work Type</Typography>
                {/* <TextField className="custom-input" id="standard-basic" placeholder="" variant="standard" /> */}
                <Controller
                  control={control}
                  name='workType'
                  render={({ field: { onChange, value, name } }) => {
                    return (
                      <CustomSelect
                        isClearable={false}
                        labelKey={'label'}
                        valueKey={'value'}
                        options={Degrilis}
                        name={name}
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                        }}
                        placeHolder='Select Work Type'
                        error={errors.workType?.message}
                      />
                    );
                  }}
                />
              </Typography>
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <Typography component={'div'} className='custom-field'>
                {/* <Typography component={'p'}>CRM</Typography> */}
                <Typography component={'p'}>Work Plan</Typography>
                {/* <TextField className="custom-input" id="standard-basic" placeholder="" variant="standard" /> */}
                <Controller
                  control={control}
                  name='workPlan'
                  render={({ field: { onChange, value, name } }) => {
                    return (
                      <CustomSelect
                        isClearable={false}
                        labelKey={'label'}
                        valueKey={'value'}
                        options={[
                          { label: 'Current Day', value: 'current' },
                          { label: 'Previous Day', value: 'previous' },
                        ]}
                        name={name}
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                        }}
                        placeHolder='Select Work Plan'
                        error={errors.workType?.message}
                      />
                    );
                  }}
                />
              </Typography>
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <Typography component={'div'} className='custom-field'>
                <Typography component={'p'}>Clients</Typography>
                {/* <TextField className="custom-input" id="standard-basic" placeholder="" variant="standard" /> */}
                <Controller
                  control={control}
                  name='clientId'
                  render={({ field: { onChange, value, name } }) => {
                    return (
                      <CustomSelect
                        isClearable={false}
                        labelKey={'label'}
                        valueKey={'value'}
                        options={crmdropwn}
                        name={name}
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                        }}
                        placeHolder='Select Clients'
                        error={errors.clientId?.message}
                      />
                    );
                  }}
                />{' '}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <button
        type='submit'
        className='clientfilter-form-btn'
        style={{
          ...(!(
            watch('fromDate') ||
            watch('toDate') ||
            watch('clientId') ||
            watch('employee') ||
            watch('session') ||
            watch('workPlan') ||
            watch('workType')
          )
            ? { opacity: '0.5', cursor: 'not-allowed' }
            : {}),
        }}>
        Apply
      </button>
    </form>
  );
};
