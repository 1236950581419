import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Divider, Grid, Modal, Typography } from '@mui/material';
import CloseIcon from 'assets/png/close.svg';
import CustomInput from 'components/CustomInput/customInput';
import { Controller, useForm } from 'react-hook-form';
import { To, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AdminPrivateRoutes } from 'routes/routeConstans';
import { patchData } from 'services/CommonServices';
import { useAppSelector } from 'store/hooks';
import { BillingENDPOINT } from 'utils/Constant';
import { BillingApprovalSchema } from 'utils/ValidatorSchema';
import { AccessDenied, BillingStatus } from 'utils/helper';

const ApprovalModal = (prop: { title: string; open: boolean; setOpen: any; id: string }) => {
  const {
    userInfo: { roleId },
  }: any = useAppSelector((store: any) => store.userLogin);

  const { title, open, setOpen, id } = prop;

  const handleModalClose = () => {
    reset();
    setOpen({ open: false, title: '' });
  };

  const navigate = useNavigate();
  const gotoNavigate = (link: To) => {
    navigate(link);
  };

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({ resolver: yupResolver(BillingApprovalSchema) });

  const onSubmit = (data?: { remarks?: string }) => {
    const approvalStatus =
      title === 'Approve Billing'
        ? roleId === 6
          ? BillingStatus.BhApproved
          : BillingStatus.L1Approved
        : title === 'Reject Billing'
          ? roleId === 6
            ? BillingStatus.BhRejected
            : BillingStatus.L1Rejected
          : '';
    patchData(id, { ...data, approvalStatus }, BillingENDPOINT.billing, true)
      .then(() => {
        handleModalClose();
        gotoNavigate(AdminPrivateRoutes.BILLING.BILLINGLIST);
      })
      .catch((e: any) => {
        if (e === 'Forbidden resource') {
          toast.error(AccessDenied);
          navigate('/dashboard');
        }
      });
  };

  return (
    <Modal open={open} onClose={handleModalClose}>
      {title === 'Approve Billing' ? (
        <Box className='logout-modal p-32'>
          <Typography
            component={'div'}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginBottom: '1rem',
            }}>
            <Typography sx={{ fontSize: '20px', fontWeight: '600', color: '#344051 !important' }}>
              {title}
            </Typography>
            <Typography className='text-right'>
              <img
                src={CloseIcon}
                alt='close'
                style={{ cursor: 'pointer' }}
                onClick={handleModalClose}
              />
            </Typography>
          </Typography>
          <form id='cv-reject' className='private-form' onSubmit={handleSubmit(onSubmit)}>
            <Grid container>
              <Grid item md={12} sm={12} xs={12}>
                <Typography component={'div'} className='custom-field'>
                  <Typography component={'p'}>
                    Remarks<span>*</span>
                  </Typography>
                  <Controller
                    name='remarks'
                    control={control}
                    render={({ field: { onChange, value, name } }) => (
                      <CustomInput
                        name={name}
                        value={value}
                        isTextArea={true}
                        onChange={onChange}
                        className='custom-input'
                        placeHolder='Remarks'
                        error={errors.remarks?.message}
                      />
                    )}
                  />
                </Typography>
              </Grid>
              <Grid item md={12} sm={12} xs={12}>
                <Divider sx={{ marginBottom: '20px', marginTop: '10px' }} />
              </Grid>
              <Grid item md={12} sm={12} xs={12}>
                <Typography
                  component={'div'}
                  sx={{ justifyContent: 'flex-end' }}
                  className='d-flex-a'>
                  <Button className='cancel-btn mr-16' onClick={handleModalClose}>
                    Cancel
                  </Button>
                  <Button form={'cv-reject'} className='s-add-btn' type='submit'>
                    Submit
                  </Button>
                </Typography>
              </Grid>
            </Grid>
          </form>
        </Box>
      ) : (
        <Box className='logout-modal p-32'>
          <Typography className='text-right'>
            <img
              src={CloseIcon}
              alt='close'
              style={{ cursor: 'pointer' }}
              onClick={handleModalClose}
            />
          </Typography>
          <Typography display={'flex'} justifyContent={'center'}>
            <Typography sx={{ fontSize: '20px', fontWeight: '600', color: '#344051 !important' }}>
              {title}
            </Typography>
          </Typography>
          <Typography
            sx={{
              fontSize: '16px',
              fontWeight: '400',
              color: '#637083 !important',
              marginTop: '24px',
              display: 'flex',
              justifyContent: 'center',
            }}>
            Once rejected the invoice request for the same closure/offer
          </Typography>
          <Typography
            sx={{
              fontSize: '16px',
              fontWeight: '400',
              color: '#637083 !important',
              display: 'flex',
              justifyContent: 'center',
            }}>
            Cannot be re-raised. Are you sure you want to reject?{' '}
          </Typography>
          <Typography display={'flex'} justifyContent={'center'} marginTop={'40px'}>
            <Button className='cancel-btn mr-16' onClick={handleModalClose}>
              No
            </Button>
            <Button className='s-add-btn' onClick={() => onSubmit()}>
              Yes
            </Button>
          </Typography>
        </Box>
      )}
    </Modal>
  );
};
export default ApprovalModal;
