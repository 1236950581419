import Closepng from 'assets/png/close.svg';
import { Modal, Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { AdminPrivateRoutes } from 'routes/routeConstans';

const Modalclientinactive = ({
  open,
  handleClose,
  clientData,
}: {
  open: boolean;
  handleClose: () => void;
  clientData?: {name:string;id:string|number;};
}) => {

    const navigate = useNavigate();

const gotoNavigate = (link:any) => {
    navigate(link);
  };

    return (    <Modal open={open} onClose={handleClose}>
      <Box className='client-inactiv-modal'>

          <img src={Closepng} alt='close' className='closepng' onClick={handleClose} />
       <Box  className='box-center'>
        <Typography className='main-title'>Client Inactive</Typography>
        <Typography className='main-sub-desc'>{clientData && clientData.name ? clientData.name.toUpperCase() :''} has been temporarily blacklisted for violating CTH norms and is currently inactive.</Typography>
        <Typography className='main-sub-highlight'>Would you like to activate {clientData && clientData.name ? clientData.name.toUpperCase() :''} to continue proceeding with their operations?</Typography>
        </Box>
        <div className='button-box'><button  className='client-aprv-btn  aprvd-btn-1' onClick={handleClose}>
        No
              </button>
              <button
             
                className='client-aprv-btn aprvd-btn-2'
                onClick={ () =>  gotoNavigate(
                    `${AdminPrivateRoutes.CLIENTS.CLIENTADD}?mode=edit&clientid=${clientData && clientData.id ? clientData.id :''}`,
                  )}
                >
                Yes
              </button></div>
        </Box></Modal>) 
  };

export default Modalclientinactive;
