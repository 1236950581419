import {
  Grid,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@mui/material';
import Loader from 'components/loader';
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { getAllListData } from 'services/CommonServices';
import { ResumesENDPOINT } from 'utils/Constant';

type defaultprops = {
  positionId?: string;
  loading: any;
  setloading: any;
};

const OverAllStatus: React.FC<defaultprops> = ({ loading, setloading }) => {
  const [searchParams] = useSearchParams();
  const [data, setData] = useState<any>({});
  const getList = () => {
    setloading(true);
    getAllListData(`${ResumesENDPOINT.OverAllStatus}/${searchParams.get('id')}`, true)
      .then((res: any) => {
        setData(res.data);
        setloading(false);
      })
      .catch(() => setloading(false));
  };

  useEffect(() => {
    getList();
  }, []);

  return (
    <Grid container>
      {loading && <Loader />}
      <Grid item md={5}>
        <TableContainer component={Paper}>
          <Table className='custom-table' aria-label='simple table'>
            <TableHead>
              <TableRow>
                <TableCell align='center'>Status</TableCell>
                <TableCell align='center'>No. of Candidates</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Array.isArray(Object.keys(data)) &&
                Object.keys(data).length &&
                Object.keys(data).map((labelKey: string, index) => (
                  <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell
                      align='left'
                      sx={{ textTransform: 'capitalize', paddingLeft: '80px !important' }}>
                      {labelKey.replace(/([a-z])([A-Z])/g, '$1 $2')}
                    </TableCell>
                    <TableCell align='center' sx={{ textTransform: 'capitalize' }}>
                      {data[labelKey]}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};
export default OverAllStatus;
