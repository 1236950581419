import {
  Grid,
  Typography,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@mui/material';
import Paginate from 'components/Pagination/Pagination';
import { useDebounce } from 'hooks';
import React, { useEffect, useState } from 'react';
import { getAllListData } from 'services/CommonServices';
import { useAppSelector } from 'store/hooks';
import { SETTING_ENDPOINT } from 'utils/Constant';

const Defaultparam = {
  search: null,
  userId: null,
  order: 'asc',
  skip: 0,
  sortby: 'createdAt',
  status: true,
  take: 50,
  leavePolicyId: null,
  organizationId: null,
};
type defaultprops = {
  setLoader: (val: boolean) => void;
  id: string;
};
const SelectedEmployee: React.FC<defaultprops> = ({ setLoader, id }) => {
  const {
    userInfo: { organizationId: orgdy },
  }: any = useAppSelector((store: any) => store.userLogin);
  const [pagetotal, setpagetotal] = useState<number>(0);
  const [params, setParams] = useState<any>({ ...Defaultparam });
  const debouncedParams: any = useDebounce(params, 500);
  const [isSelectedEmployee, setIsSelectedEmployee] = useState<any>([]);

  const getList = () => {
    setLoader(true);
    const searcharr = [
      `${orgdy ? `organizationId=${orgdy}` : null}`,
      `${id ? `leavePolicyId=${id}` : null}`,
      `${debouncedParams.search ? `search=${encodeURIComponent(debouncedParams.search)}` : null}`,
      `${debouncedParams.status ? `status=${debouncedParams.status}` : null}`,
      `${debouncedParams.order ? `order=${debouncedParams.order}` : null}`,
      `${debouncedParams.sortby ? `sortby=${debouncedParams.sortby}` : null}`,
      `${
        debouncedParams.skip
          ? `skip=${debouncedParams.skip}`
          : debouncedParams.take
            ? 'skip=0'
            : null
      }`,
      `${debouncedParams.take ? `take=${debouncedParams.take}` : null}`,
    ]
      .filter((ea: any) => ea !== 'null')
      .join('&');

    getAllListData(
      `${SETTING_ENDPOINT.users}/lite${
        Object.values({ ...params, organizationId: orgdy }).length > 0 && '?'
      }${searcharr}`,
      true,
    )
      .then((res: any) => {
        setpagetotal(Number(res.total));
        setIsSelectedEmployee(res && res.data);
        setLoader(false);
      })
      .catch(() => {
        setLoader(false);
      });
  };
  const handlePag = (sk: number, tk: number) => {
    setParams((prev: any) => ({
      ...prev,
      skip: sk,
      take: tk,
    }));
  };
  useEffect(() => {
    getList();
  }, [debouncedParams]);
  return (
    <Grid container className='private-form'>
      <Grid item md={12} sm={12} xs={12}>
        <Typography component={'div'}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} className='custom-table' aria-label='simple table'>
              <TableHead>
                <TableRow>
                  <TableCell>Emp. Id</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Role </TableCell>
                  <TableCell>Branch</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isSelectedEmployee && isSelectedEmployee.length ? (
                  isSelectedEmployee.map((row: any) => {
                    return (
                      <TableRow
                        key={row.id}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                        }}>
                        <TableCell sx={{ textTransform: 'capitalize' }}>
                          {row && row?.empCode ? row?.empCode : '-'}
                        </TableCell>
                        <TableCell>
                          {row && row.firstName ? `${row.firstName} ${row.lastName}` : '-'}
                        </TableCell>
                        <TableCell>{row && row?.role?.name ? row?.role?.name : '-'}</TableCell>
                        <TableCell sx={{ textTransform: 'capitalize' }}>
                          {row && row?.branch ? row?.branch?.name : '-'}
                        </TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <tr className={'text-center'}>
                    <td colSpan={4} align={'center'}>
                      No Record(s) Found.
                    </td>
                  </tr>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Typography>
        <Grid container className='mt-10'>
          <Grid item md={12} sm={12} xs={12}>
            <Typography component={'div'} className='d-flex-a flex-end pagination'>
              <Paginate total={pagetotal} setfn={handlePag} DefaultPerPage={50} />
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default SelectedEmployee;
