/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Breadcrumbs,
  Button,
  Grid,
  FormControlLabel,
  RadioGroup,
  Radio,
  Link,
  Typography,
  TextField,
  Box,
  NativeSelect,
  Modal,
} from '@mui/material';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import Deletebucket from 'assets/png/Deletebucket.svg';
import HomeIcon from 'assets/png/dashboardactive.svg';
import Crossicon from 'assets/png/Cross icon.svg';
import ArrowIcon from 'assets/png/breadarrow.svg';
import Fileupload from 'assets/png/upload-cloud.svg';
import Wordpng from 'assets/png/Word.png';
import PDFpng from 'assets/png/pngimg.png';
import Pptlogo from 'assets/iconcomponents/ppticon';
import Closepng from 'assets/png/close.svg';
import CloseIcon from '@mui/icons-material/Close';
import Svggreycircle from 'assets/png/Clear Circle Solid.svg';
import { To, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { PositionType, PositionType2, PositionType3 } from 'types';
import { PositionSchema, PositionSchema2, PositionSchema3 } from 'utils/ValidatorSchema';
import { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { toast } from 'react-toastify';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AdminPrivateRoutes } from 'routes/routeConstans';
import CustomInput from 'components/CustomInput/customInput';
import Loader from 'components/loader';
import { useAppSelector } from 'store/hooks';
import CustomSelect from 'components/CustomSelect/customSelect';
import { CKEditor } from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Dropzone, { useDropzone } from 'react-dropzone';
import { addNewData, getAllListData, updateData } from 'services/CommonServices';
import { ClientsENDPOINT, PositionENDPOINT, SETTING_ENDPOINT } from 'utils/Constant';
import _ from 'lodash';
import ClientSuccessIcon from 'assets/png/clientsuccessicon.svg';
import CustomTagInput from 'components/Taginput/Taginput';
import TableTooltip from 'components/Tooltip/Tabletooltip';
import { alertOptions } from 'utils/Config';
import Editor from 'ck-editor-plugin/build/ckeditor';
import CrmChange from './ChangeCRM';
import { AccessDenied, fileTypewordPDF } from 'utils/helper';
import { Country } from 'country-state-city';

const FileComponentui = ({
  file,
  idxc,
  setcalbk,
  success,
  handledelete,
}: {
  file: any;
  idxc: number;
  uid?: string;
  setcalbk: (idxc: number) => void;
  handledelete: (id: any, i: number) => void;
  success: boolean;
}) => {
  // const [clik, setclik] = useState(false);
  // const handledelete = () => {
  //   // setclik(true);
  //   setcalbk(idxc);
  // };
  const handledeletedata = (fileId: any, i: number) => {
    handledelete(fileId, i);
    // setRemoveId(resumeFileId ? resumeFileId : '');
    // setfileupload('');
    // hiddenFileInput.current.value = '';
  };
  return (
    <>
      {/* <Grid item md={4} sm={12} xs={12} style={{display:clik ? 'none':'inherit'}}> */}
      {/* <Grid item md={4} sm={12} xs={12} style={{ display: clik ? 'inherit' : 'inherit' }}>
        <Typography component={'div'} className='custom-field file-view-box'>
          <Typography
            component={'p'}
            className={`${success ? 'first-children first-green-children' : 'first-children'}`}>
            {success ? (
              <div className='image-container'>
                <span className='image image-success'>
                  <img src={Wordpng} alt='Wordpng' />
                </span>
                <span className={success ? 'texted texted-success' : 'texted'}>
                  <TableTooltip
                    value={file.name ? file.name : ''}
                    positionfile={true}></TableTooltip>
                </span>
              </div>
            ) : (
              <div className='image-container'>
                <span className='image'>
                  <Pptlogo texted={`.${file.name.split('.').pop()}`} />
                </span>
                {success ? (
                  <span className='texted' style={{ transform: 'translateY(4px)' }}>
                    <TableTooltip
                      value={file.name ? file.name : ''}
                      positionfile={true}></TableTooltip>
                  </span>
                ) : (
                  <span className='texted'>
                    {' '}
                    <TableTooltip
                      value={file.name ? file.name : ''}
                      positionfile={true}></TableTooltip>
                  </span>
                )}
              </div>
            )}
            {success ? (
              <div className='Crossicon Crossicon-deletebucket' onClick={handledelete}>
                <img src={Deletebucket} alt='Crossicon' />
              </div>
            ) : (
              <div className='Crossicon' onClick={handledelete}>
                <img src={Crossicon} alt='Crossicon' />
              </div>
            )}
          </Typography>
          {success ? (
            <></>
          ) : (
            <Typography component={'p'} className='errored-position'>
              {file.path.includes('.doc' || '.docx')
                ? 'This document size is not supported, please delete and upload another file.'
                : 'This document is not supported, please delete and upload another file.'}
            </Typography>
          )}
        </Typography>
      </Grid> */}
      <Grid item md={12} sm={12} xs={12} style={{ display: 'inherit' }}>
        <Typography component={'div'} className='custom-field file-view-box'>
          <Typography className='first-children first-green-children'>
            <div className='image-container'>
              <span className='image image-success'>
                {file?.type === 'application/pdf' || file?.mimetype === 'application/pdf' ? (
                  <img src={PDFpng} alt='PDFpng' style={{ height: '20px', width: '20px' }} />
                ) : (
                  <img src={Wordpng} alt='Wordpng' />
                )}
              </span>
              {/* <span className={success ? 'texted texted-success' : 'texted'}> */}
              <span className={'texted'}>
                <TableTooltip
                  value={file.name ? file.name : file.fileName ? file.fileName : ''}
                  positionfile={true}></TableTooltip>
              </span>
            </div>
            <div
              className='Crossicon Crossicon-deletebucket'
              onClick={() => handledeletedata(file.id, idxc)}>
              <img src={Deletebucket} alt='Crossicon' />
            </div>
          </Typography>
        </Typography>
      </Grid>
    </>
  );
};
// const FileComponentui2 = ({
//   fileName,
//   id,
//   setcalbk,
// }: {
//   fileName: string;
//   id: string;
//   setcalbk: any;
// }) => {
//   const [clik, setclik] = useState(false);
//   const handledelete = () => {
//     setclik(true);
//     setcalbk(id);
//   };
//   return (
//     <>
//       {/* <Grid item md={4} sm={12} xs={12} style={{display:clik ? 'none':'inherit'}}> */}
//       <Grid item md={4} sm={12} xs={12} style={{ display: clik ? 'none' : 'inherit' }}>
//         <Typography component={'div'} className='custom-field file-view-box'>
//           <Typography component={'p'} className={'first-children first-green-children'}>
//             {
//               <div className='image-container'>
//                 <span className='image image-success'>
//                   <img src={Wordpng} alt='Wordpng' />
//                 </span>
//                 <span className={'texted texted-success'}>
//                   <TableTooltip value={fileName ? fileName : ''} positionfile={true}></TableTooltip>
//                 </span>
//               </div>
//             }
//             <div className='Crossicon Crossicon-deletebucket' onClick={handledelete}>
//               <img src={Deletebucket} alt='Crossicon' />
//             </div>
//           </Typography>
//         </Typography>
//       </Grid>
//     </>
//   );
// };
const AddPositions = () => {
  const today = new Date().toISOString().split('T')[0];
  const {
    menuList,
    userInfo: { organizationId: orgdy, id: userId },
  }: any = useAppSelector((store: any) => store.userLogin);
  const [searchParams] = useSearchParams();
  const Mode = searchParams.get('mode');
  const [ctgopen, setctgopen] = useState(false);
  const [spocOpen, setSpocOpen] = useState<any>({
    show: false,
    name: '',
  });
  const [country, setCountry] = useState([]);
  const [removeId, setRemoveId] = useState<any>([]);
  const hiddenFileInput: any = useRef(null);
  const positionid = searchParams.get('positionid');
  const [openCRMchange, setOpenCRMchange] = useState<boolean>(false);
  // const [editload, seteditload] = useState<boolean>(false); // Store all files
  const [formerror, setformerror] = useState<boolean>(false);
  const [fileupload, setfileupload] = useState<any>([]); // Store all files
  const [removeFileIds, setremoveFileIds] = useState<any>([]); // Store all files
  const [prefileupload, setprefileupload] = useState<any>([]); // Store all files
  const [Clientename, setClientename] = useState<any>([]);
  const [Spocname, setSpocname] = useState<any>([]);
  const [functionalArea, setfunctionalArea] = useState<any>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [industry, setindustry] = useState<any>([]);
  const [Recuiterslis, setRecuiterslis] = useState<any>([]);
  const [editRecruitersList, setEditRecruiterList] = useState<any[]>([]);
  const [dynamictotalposition] = useState<any>(0);
  // const [Age, setAge] = useState<any>([]);
  const [reasonfalse, setreasonfalse] = useState(false);
  const [crmId, setcrmId] = useState<any>([]);
  const [currentCRM, setCurrentCRM] = useState<any>();
  const [crmdata, setcrmdata] = useState<any>({});
  const [prerecuiters, setprerecuiters] = useState<any>({});
  const [technicaskpre, settechnicaskpre] = useState<any>({});
  const [behaviourpreprop, setbehaviourpreprop] = useState<any>({});
  const [spocdata, setspocdata] = useState<any>([]);
  const [fileRejectionItems, setfileRejectionItems] = useState<any>([]);
  const [isRejectionModalOpened, setRejectionModalOpen] = useState(false);
  const [isRejectionModalOpened3, setRejectionModalOpen3] = useState(false);
  // const [publishinwebsites, setpublishinwebsites] = useState<any>(true);
  // const subtgref: React.MutableRefObject<any> = useRef();
  const [editorContent, setEditorContent] = useState<any>(undefined);
  const [modaluid, setmodaluid] = useState<any>(undefined);
  const navigate = useNavigate();
  const [Ld, setLd] = useState(false);
  const gotoNavigate = (link: To, state?: any) => {
    navigate(link, state);
  };

  const maxSize = 2 * 1024 * 1024;
  // const { getRootProps, getInputProps, open, acceptedFiles, fileRejections, } = useDropzone({
  // const { getRootProps, getInputProps, open } = useDropzone({
  //   // Disable click and keydown behavior

  //   noClick: true,
  //   noKeyboard: true,
  //   multiple: true,
  //   accept: {
  //     'application/msword': ['.doc'],
  //     'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
  //   },
  //   onDrop: (acceptedFiles, fileRejections) => {
  //     setformerror(false);
  //     setfileupload((prevFiles: any) => [
  //       ...prevFiles, // Keep previous files
  //       ...acceptedFiles.map((file) => file),
  //     ]); // Add new files
  //     setfileRejectionItems((prevFiles: any) => [
  //       ...prevFiles, // Keep previous files
  //       ...fileRejections.map((file) => file.file),
  //     ]); // Add new files
  //   },
  //   maxSize: maxSize,
  // });
  const onFileChange = (file: any) => {
    if (file[0] && file[0].size) {
      if (fileTypewordPDF.includes(file[0].type)) {
        let fileSize = 0;
        fileSize = Math.round(file[0].size / 1024);
        if (fileSize > 2048) {
          toast.error('File is too big, Please select a file less than 2MB', alertOptions);
          return;
        }
      } else {
        toast.error('You can upload documents of types  DOCX and DOC only', alertOptions);
        return;
      }
    }
    setfileupload((prevFiles: any) => {
      if (prevFiles.length >= 3) {
        toast.error('Maximum file upload limit is 3', alertOptions);
        return [
          ...prevFiles, // Keep previous files
          // ...file.map((data: any) => data),
        ];
      }
      return [
        ...prevFiles, // Keep previous files
        ...file.map((data: any) => data),
      ];
    });
    hiddenFileInput.current.value = '';
  };

  const onDrop = useCallback((acceptedFiles: any) => {
    onFileChange(acceptedFiles);
    // eslint-disable-next-line prettier/prettier, react-hooks/exhaustive-deps
  }, []);

  fileupload.map((file: any) => {
    return (
      <li key={file && file.path}>
        {file.path} - {file.size} bytes
      </li>
    );
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
    setValue,
    trigger,
    getValues,
    // reset,
  } = useForm<PositionType>({
    defaultValues: {
      organizationId: orgdy ? orgdy : '',
      clientId: undefined,
      spocId: undefined,
      title: undefined,
      location: undefined,
      country: undefined,
      jobCode: undefined, // prefix should be updated in organization
      functionalAreaId: undefined,
      industry: undefined,
      gender: undefined,
      minAge: undefined,
      maxAge: undefined,
      qualification: undefined,
      minCurrency: 'inr',
      minCtc: undefined,
      maxCurrency: 'inr',
      maxCtc: undefined,
      expectedJoiningDate: undefined,
      totalOpenins: undefined,
      crmId: undefined,
      resumeType: undefined,
      minExp: undefined,
      maxExp: undefined,
      projectType: undefined,
      billableValue: undefined,
      hideResumeContacts: true,
      publishInWebsite: undefined,
      keyResponsibilities: undefined,
      industryExposure: undefined,
      competencies: undefined,
      jobDescription: '',
      approvalStatus: 'awaitingApproval', // approved , rejected
      status: true, // Active / Inactive
      technicalSkillsData: [],
      behaviouralSkillsData: [],
      recruitersData: [],
    },
    reValidateMode: 'onChange',
    resolver: yupResolver(PositionSchema),
  });
  const {
    handleSubmit: handleSubmit2,
    control: control2,
    formState: { errors: errors2 },
    setValue: setValue2,
    reset: reset2,
    // trigger:trigger2,
  } = useForm<PositionType2>({
    defaultValues: {
      Openingsnumber: 0,
    },
    reValidateMode: 'onChange',
    resolver: yupResolver(PositionSchema2),
  });

  const {
    handleSubmit: handleSubmit3,
    control: control3,
    formState: { errors: errors3 },
    getValues: getValues3,
    // setValue: setValue3,
    reset: reset3,
    // trigger:trigger2,
  } = useForm<PositionType3>({
    defaultValues: {
      reason: undefined,
    },
    reValidateMode: 'onChange',
    resolver: yupResolver(PositionSchema3),
  });

  const handleEditorChange = (event: any, editor: any) => {
    const data = editor && editor.getData();
    errors.jobDescription && errors.jobDescription.message && trigger('jobDescription');
    setValue('jobDescription', data);
    setEditorContent(data);
  };

  const handleRejectClose = () => {
    // reset();
    // setValue2('Openingsnumber', 0);
    // const filteredModaldata = !_.isEmpty(getValues('recruitersData'))
    //   ? getValues('recruitersData').filter((io1: any) => {
    //       return !_.isEmpty(io1.userId) && io1.userId !== modaluid['userId'];
    //     })
    //   : [];
    // setValue('recruitersData', filteredModaldata);
    setRejectionModalOpen(false);
  };
  const handleRejectClose3 = () => {
    setRejectionModalOpen3(false);
    reset3();
    setreasonfalse(false);
  };
  const onSubmitForm2: SubmitHandler<{
    Openingsnumber: number;
  }> = (data2) => {
    const data = getValues('recruitersData');
    const totalOpennings = Number(getValues('totalOpenins'));
    const existingTotal = data.reduce((prev, curr) => Number(prev) + curr.target, 0);
    const currentOpennings = existingTotal + data2.Openingsnumber;
    if (currentOpennings > totalOpennings) {
      toast.error(String('Total opening value exceeds'), alertOptions);
      return;
    }
    setValue('recruitersData', [
      ...data,
      { userId: modaluid['userId'], target: data2.Openingsnumber },
    ]);
    setRejectionModalOpen(false);

    reset2();
    setValue2('Openingsnumber', 0);
  };
  const onSubmitForm3 = () => {
    console.log(fileupload);

    if (fileupload && fileupload.length) {
      setRejectionModalOpen3(true);
    } else if (fileupload.length && fileupload.length < 4) {
      toast.error('Maximum file upload limit is 3', alertOptions);
    } else {
      setRejectionModalOpen3(true);
    }
  };
  const onSubmitForm32 = () => {
    onSubmit(getValues());
    // if (_.isEmpty(fileupload) && _.isEmpty(prefileupload)) {
    //   setformerror(true);
    //   return;
    // }
    setreasonfalse(true);
    // subtgref.current.click();
  };
  const handleOpenctg = () => {
    setctgopen(true);
  };
  const handleClosectg = () => {
    gotoNavigate(AdminPrivateRoutes.POSITIONS.POSITIONSVIEW);
    setctgopen(false);
  };

  // const handleOpenSpoc = () => {
  //   setSpocOpen(true);
  // };

  // const handleCloseSpoc = () => {
  //   setSpocOpen({ show: false, name: '' });
  // };

  const handleCloseSpocCancel = () => {
    setSpocOpen({ show: false, name: '' });
    setValue('spocId', '');
    // trigger('spocId');
    // reset();
  };
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const location = useLocation();
  // const locationstate = location.state;
  // const locationpathname = location.pathname;
  // const locationbool: boolean =
  //   locationpathname === AdminPrivateRoutes.POSITIONS.POSITIONSADD;
  const locationbool: boolean = Mode === 'edit';
  const onSubmit = async (data: PositionType) => {
    console.log(fileupload);

    if (fileupload && fileupload.length && fileupload.length > 3) {
      toast.error('Maximum file upload limit is 3', alertOptions);
    } else {
      // else {
      //   toast.error('Maximum file upload limit is 3', alertOptions);
      // }
      // if (_.isEmpty(fileupload) && Mode !== 'edit') {
      // if (_.isEmpty(fileupload) && Mode !== 'edit') {
      //   setformerror(true);
      //   toast.error(String('Files is required'), {
      //     autoClose: 3000,
      //     closeOnClick: true,
      //     draggable: true,
      //     hideProgressBar: false,
      //     pauseOnHover: true,
      //     position: 'top-right',
      //     progress: undefined,
      //   });
      //   return;
      // }

      const totalOpennings = Number(data.totalOpenins);
      const recruiterOpennings = data.recruitersData.reduce(
        (prev, curr) => Number(prev) + curr.target,
        0,
      );

      if (totalOpennings !== recruiterOpennings) {
        toast.error(String('Total opennings does not match recruiters'), {
          autoClose: 3000,
          closeOnClick: true,
          draggable: true,
          hideProgressBar: false,
          pauseOnHover: true,
          position: 'top-right',
          progress: undefined,
        });
        return;
      }

      const formdata: any = new FormData();
      // for data start
      formdata.append('organizationId', data && data.organizationId ? data.organizationId : '');
      formdata.append('clientId', data && data.clientId ? data.clientId : '');
      formdata.append('spocId', data && data.spocId ? data.spocId : '');
      formdata.append('title', data && data.title ? data.title : '');
      formdata.append('location', data && data.location ? data.location : '');
      formdata.append('country', 'IN');
      formdata.append('jobCode', data && data.jobCode ? data.jobCode : '');
      formdata.append(
        'functionalAreaId',
        data && data.functionalAreaId ? data.functionalAreaId : '',
      );
      formdata.append('industry', data && data.industry ? data.industry : '');
      formdata.append('gender', data && data.gender ? data.gender : '');
      formdata.append('minAge', data && data.minAge ? data.minAge : '');
      formdata.append('maxAge', data && data.maxAge ? data.maxAge : '');
      formdata.append('qualification', data && data.qualification ? data.qualification : '');
      formdata.append('minCurrency', data && data.minCurrency ? data.minCurrency : '');
      formdata.append('minCtc', data && data.minCtc ? data.minCtc : '');
      formdata.append('maxCurrency', data && data.maxCurrency ? data.maxCurrency : '');
      formdata.append('maxCtc', data && data.maxCtc ? data.maxCtc : '');
      formdata.append(
        'expectedJoiningDate',
        data && data.expectedJoiningDate ? data.expectedJoiningDate : '',
      );
      formdata.append('totalOpenins', data && data.totalOpenins ? data.totalOpenins : '');
      formdata.append('crmId', data && data.crmId ? data.crmId : '');
      formdata.append('resumeType', data && data.resumeType ? data.resumeType : '');
      formdata.append('projectType', data && data.projectType ? data.projectType : '');
      formdata.append('billableValue', data && data.billableValue ? data.billableValue : '');
      formdata.append('hideResumeContacts', data.hideResumeContacts);
      formdata.append(
        'publishInWebsite',
        data && data.publishInWebsite ? data.publishInWebsite : false,
      );
      String(getValues('publishInWebsite')) === 'true'
        ? formdata.append(
            'keyResponsibilities',
            data && data.keyResponsibilities ? data.keyResponsibilities : '',
          )
        : formdata.append('keyResponsibilities', '');
      String(getValues('publishInWebsite')) === 'true'
        ? formdata.append(
            'industryExposure',
            data && data.industryExposure ? data.industryExposure : '',
          )
        : formdata.append('industryExposure', '');
      String(getValues('publishInWebsite')) === 'true'
        ? formdata.append('competencies', data && data.competencies ? data.competencies : '')
        : formdata.append('competencies', '');
      formdata.append('jobDescription', editorContent && editorContent ? editorContent : '');
      formdata.append('approvalStatus', 'awaitingApproval');
      formdata.append('status', data && data.status ? data.status : '');
      Mode === 'edit'
        ? formdata.append(
            'technicalSkillsData',
            data && data.technicalSkillsData && data.technicalSkillsData.length > 0
              ? JSON.stringify(
                  data.technicalSkillsData.map((rt1: any) => {
                    return technicaskpre[rt1]
                      ? { skill: rt1, id: technicaskpre[rt1] }
                      : { skill: rt1 };
                  }),
                )
              : JSON.stringify([{ skill: '' }]),
          )
        : formdata.append(
            'technicalSkillsData',
            data && data.technicalSkillsData && data.technicalSkillsData.length > 0
              ? JSON.stringify(data.technicalSkillsData.map((rt1: any) => ({ skill: rt1 })))
              : JSON.stringify([{ skill: '' }]),
          );

      const technicalkeys: any = Object.keys(technicaskpre);
      const techlodashkeys: any = _.difference(technicalkeys, data.technicalSkillsData);
      const removeTechnicalSkillIds: any = _.map([technicaskpre], (obj) =>
        _.get(obj, techlodashkeys),
      );

      Mode === 'edit' &&
      !_.isEmpty(removeTechnicalSkillIds) &&
      _.every(removeTechnicalSkillIds, Boolean)
        ? formdata.append('removeTechnicalSkillIds', JSON.stringify(removeTechnicalSkillIds))
        : '';

      Mode === 'edit'
        ? formdata.append(
            'behaviouralSkillsData',
            data && data.behaviouralSkillsData && data.behaviouralSkillsData.length > 0
              ? JSON.stringify(
                  data.behaviouralSkillsData.map((rt1: any) => {
                    return behaviourpreprop[rt1]
                      ? { skill: rt1, id: behaviourpreprop[rt1] }
                      : { skill: rt1 };
                  }),
                )
              : JSON.stringify([{ skill: '' }]),
          )
        : '';

      const behaviourkeys: any = Object.keys(behaviourpreprop);
      const lodashkeys: any = _.difference(behaviourkeys, data.behaviouralSkillsData);
      const removeBehaviouralSkillIds: any = _.map([behaviourpreprop], (obj) =>
        _.get(obj, lodashkeys),
      );

      Mode === 'edit' &&
      !_.isEmpty(removeBehaviouralSkillIds) &&
      _.every(removeBehaviouralSkillIds, Boolean)
        ? formdata.append('removeBehaviouralSkillIds', JSON.stringify(removeBehaviouralSkillIds))
        : '';

      Mode !== 'edit'
        ? formdata.append(
            'behaviouralSkillsData',
            data && data.behaviouralSkillsData && data.behaviouralSkillsData.length > 0
              ? JSON.stringify(
                  data.behaviouralSkillsData.map((rt1: any) => {
                    return { skill: rt1 };
                  }),
                )
              : JSON.stringify([{ skill: '' }]),
          )
        : '';

      Mode !== 'edit' && data && data.recruitersData
        ? formdata.append('recruitersData', JSON.stringify(data.recruitersData))
        : '';
      const removerecruiterIds: any = [];
      Mode === 'edit' && data && data.recruitersData
        ? formdata.append(
            'recruitersData',
            JSON.stringify(
              data.recruitersData.map((ef: any) => {
                // const removerecruiterIds1: any = !_.isEmpty(Object.values(prerecuiters))
                //   ? Object.keys(prerecuiters).map((kim: any) => {
                //       if (kim !== ef.userId) {
                //         return prerecuiters[ef.userId];
                //       }
                //     })
                //   : [];
                // !_.isEmpty(removerecruiterIds1) && _.isArray(removerecruiterIds1)
                //   ? removerecruiterIds.push(...removerecruiterIds1)
                //   : '';
                return Object.keys(prerecuiters).includes(ef.userId)
                  ? { ...ef, id: prerecuiters[ef.userId] }
                  : ef;
              }),
            ),
          )
        : '';
      if (Mode === 'edit') {
        const oldRecruiterData = data.recruitersData;
        const missingItem = editRecruitersList.filter(
          (item1) => !oldRecruiterData.some((item2: any) => _.isEqual(item1.userId, item2.userId)),
        );
        missingItem.forEach((i) => removerecruiterIds.push(i.id));
      }
      !_.isEmpty(removerecruiterIds) && _.isArray(removerecruiterIds)
        ? formdata.append(
            'removerecruiterIds',
            JSON.stringify(removerecruiterIds.filter((r1: any) => Boolean(r1))),
          )
        : '';

      // !_.isEmpty(fileupload) ? formdata.append('files', fileupload) : '';
      !_.isEmpty(fileupload)
        ? fileupload.forEach((files: any) => formdata.append('files', files))
        : '';
      Mode === 'edit' && getValues3('reason')
        ? formdata.append('reason', getValues3('reason'))
        : '';
      Mode === 'edit' && removeId ? formdata.append('removeFileIds', JSON.stringify(removeId)) : '';

      formdata.append('minExp', data.minExp);
      formdata.append('maxExp', data.maxExp);

      // for data end
      try {
        setLd(true);
        const datas =
          Mode === 'edit'
            ? await updateData(
                String(positionid),
                formdata,
                `${String(PositionENDPOINT.position)}`,
                true,
                {
                  'Content-Type': 'multipart/form-data',
                },
              )
            : await addNewData(
                formdata,
                `${PositionENDPOINT.position}`,
                {
                  'Content-Type': 'multipart/form-data',
                },
                true,
              );
        Mode === 'edit'
          ? gotoNavigate(`${AdminPrivateRoutes.POSITIONS.POSITIONSVIEW}`)
          : handleOpenctg();
        console.log(datas, 'datas');
      } catch (e: any) {
        if (e === 'Forbidden resource') {
          toast.error(AccessDenied);
          navigate('/dashboard');
        }
        // toast.error(er || String(er), alertOptions);
        setLd(!true);
      } finally {
        setLd(!true);
      }
    }
  };

  const totbval: number = useMemo(() => {
    return !_.isEmpty(watch('totalOpenins')) || _.isInteger(Number(watch('billableValue')))
      ? (Number(watch('totalOpenins')) ? Number(watch('totalOpenins')) : 0) *
          (Number(watch('billableValue')) ? Number(watch('billableValue')) : 0)
      : 0;
  }, [watch('totalOpenins'), watch('billableValue'), Ld]);

  useEffect(() => {
    if (String(getValues('publishInWebsite')) === 'false') {
      setValue('keyResponsibilities', 'hello');
      setValue('industryExposure', 'hello');
      setValue('competencies', 'hello');
    }
    if (String(getValues('publishInWebsite')) === 'true') {
      setValue('keyResponsibilities', '');
      setValue('industryExposure', '');
      setValue('competencies', '');
    }
  }, [watch('publishInWebsite')]);

  const alldropdownapicalls = async () => {
    const getdate = new Date();
    try {
      setLd(true);
      const Jobcodeapi =
        Mode !== 'edit'
          ? await getAllListData(
              `position/job-code?year=${getdate.getFullYear()}&organizationId=${orgdy}`,
              true,
            )
          : [];
      Mode !== 'edit' && !_.isEmpty(Jobcodeapi) && !_.isEmpty(Jobcodeapi.jobCode)
        ? setValue('jobCode', Jobcodeapi.jobCode)
        : '';
      const Industrydetailsapi = await getAllListData(
        `industry?organizationId=${orgdy}&status=true`,
        true,
      );
      const industryparse =
        !_.isEmpty(Industrydetailsapi) &&
        !_.isEmpty(Industrydetailsapi.data) &&
        Industrydetailsapi.data.map((mpdat1: any) => {
          return !_.isEmpty(mpdat1) && !_.isEmpty(mpdat1.id) && !_.isEmpty(mpdat1.name)
            ? { label: mpdat1.name, value: mpdat1.id }
            : {};
        });
      !_.isEmpty(industryparse) && _.isArray(industryparse) ? setindustry(industryparse) : '';

      const getapi = await getAllListData(
        `${ClientsENDPOINT.Client}?approvalStatus=${'approved'}&status=true&crm=${userId}&id=${
          !_.isEmpty(location?.state?.clientId) ? location?.state?.clientId : ''
        }`,
        true,
      );

      const Userdata: any = await getAllListData(
        `${SETTING_ENDPOINT.users}/lite?moduleId=3&screenId=7&organizationId=${orgdy}&sortby=name&order=asc`,
        true,
      );

      if (Userdata && !_.isEmpty(Userdata) && !_.isEmpty(Userdata.data)) {
        const userdatadropdown: any = Userdata.data.map((p1: any) => {
          if (!_.isEmpty(p1)) {
            return {
              label: p1 && p1.firstName && p1.lastName ? `${p1.firstName} ${p1.lastName}` : '',
              value:
                p1 && p1.id
                  ? {
                      userId: p1.id,
                      target:
                        !_.isEmpty(watch('totalOpenins')) && _.isNumber(watch('totalOpenins'))
                          ? Number(watch('totalOpenins'))
                          : 0,
                    }
                  : '',
            };
          }
        });
        !_.isEmpty(userdatadropdown) && setRecuiterslis(userdatadropdown);
      }

      const functionalarea: any = await getAllListData(`${SETTING_ENDPOINT.functionalArea}`, true);

      if (functionalarea && !_.isEmpty(functionalarea) && !_.isEmpty(functionalarea.data)) {
        const functionalareadropdown: any = functionalarea.data.map((p1: any) => {
          if (!_.isEmpty(p1)) {
            return { label: p1 && p1.name ? p1.name : '', value: p1 && p1.id ? p1.id : '' };
          }
        });
        !_.isEmpty(functionalareadropdown) && setfunctionalArea(functionalareadropdown);
      }

      if (getapi && !_.isEmpty(getapi) && !_.isEmpty(getapi.data)) {
        const Localspoc: any = {};
        const Localcrm: any = {};
        const clientdropdown: any = getapi.data.map((p1: any) => {
          if (!_.isEmpty(p1)) {
            p1.id ? (Localspoc[p1.id] = p1.clientsSpoc) : '';
            p1.id ? (Localcrm[p1.id] = p1.clientsCrm) : '';
            return {
              label: p1 && p1.corporateName ? p1.corporateName : '',
              value: p1 && p1.id ? p1.id : '',
            };
          }
        });
        !_.isEmpty(location?.state?.clientId) && setValue('clientId', location?.state?.clientId);
        !_.isEmpty(clientdropdown) && setClientename(clientdropdown);
        !_.isEmpty(Localcrm) && setcrmdata(Localcrm);
        !_.isEmpty(Localspoc) && setspocdata(Localspoc);
      }
      if (Mode === 'edit' && positionid) {
        const SetvalueData = await getAllListData(
          `${PositionENDPOINT.position}?approvalStatus=awaitingApproval,assigned,inProcess,onHold,billed,terminated&id=${positionid}`,
          true,
        );
        if (
          SetvalueData &&
          SetvalueData.data &&
          SetvalueData.data.length > 0 &&
          !_.isEmpty(SetvalueData.data[0])
        ) {
          const data = SetvalueData.data[0];

          //  prefilling value
          // for data start
          setCurrentCRM(data.crm[0]);
          setValue('organizationId', data && data.organizationId ? data.organizationId : '');
          setValue('clientId', data && data.clientId ? data.clientId : '');
          setValue('spocId', data && data.spocId ? data.spocId : '');
          setValue('title', data && data.title ? data.title : '');
          setValue('location', data && data.location ? data.location : '');
          setValue('country', data && data.country ? data.country : '');
          setValue('jobCode', data && data.jobCode ? data.jobCode : '');
          setValue('functionalAreaId', data && data.functionalAreaId ? data.functionalAreaId : '');
          setValue('industry', data && data.industry ? data.industry : '');
          setValue('gender', data && data.gender ? data.gender : '');
          setValue('minAge', data && data.minAge ? data.minAge : '');
          setValue('maxAge', data && data.maxAge ? data.maxAge : '');
          setValue('qualification', data && data.qualification ? data.qualification : '');
          setValue('minCurrency', data && data.minCurrency ? data.minCurrency : '');
          setValue('minCtc', data && data.minCtc ? data.minCtc : '');
          setValue('maxCurrency', data && data.maxCurrency ? data.maxCurrency : '');
          setValue('maxCtc', data && data.maxCtc ? data.maxCtc : '');
          setValue('maxExp', data && data.maxExp ? data.maxExp : '');
          setValue('minExp', data && data.minExp ? data.minExp : '');
          setValue(
            'expectedJoiningDate',
            data && data.expectedJoiningDate ? data.expectedJoiningDate : '',
          );
          setValue('totalOpenins', data && data.totalOpenins ? data.totalOpenins : '');
          setValue('crmId', data && data?.crm[0]?.crmId ? data?.crm[0]?.crmId : '');
          setValue('resumeType', data && data.resumeType ? data.resumeType : '');
          setValue('projectType', data && data.projectType ? data.projectType : '');
          setValue('billableValue', data && data.billableValue ? data.billableValue : '');
          setValue('hideResumeContacts', data.hideResumeContacts);
          setValue(
            'publishInWebsite',
            data && data.publishInWebsite ? data.publishInWebsite : false,
          );
          setValue(
            'keyResponsibilities',
            data && data.keyResponsibilities ? data.keyResponsibilities : 'hello',
          );
          setValue(
            'industryExposure',
            data && data.industryExposure ? data.industryExposure : 'hello',
          );
          setValue('competencies', data && data.competencies ? data.competencies : 'hello');
          setValue('jobDescription', data && data.jobDescription ? data.jobDescription : '');
          data && data.jobDescription ? setEditorContent(data.jobDescription) : '';
          setValue('approvalStatus', data && data.approvalStatus ? data.approvalStatus : '');
          setValue('status', data && data.status ? data.status : '');
          setValue(
            'technicalSkillsData',
            data && data.technicalSkills && data.technicalSkills.length > 0
              ? data.technicalSkills.map((rt1: any) => rt1.skill)
              : [],
          );
          data && data.technicalSkills && data.technicalSkills.length > 0
            ? data.technicalSkills.map((rt1: any) =>
                settechnicaskpre((pre: any) => ({ ...pre, [rt1.skill]: rt1.id })),
              )
            : [];

          setValue(
            'behaviouralSkillsData',
            data && data.behaviouralSkills && data.behaviouralSkills.length > 0
              ? data.behaviouralSkills.map((rt1: any) => rt1.skill)
              : [],
          );
          data && data.behaviouralSkills && data.behaviouralSkills.length > 0
            ? data.behaviouralSkills.map((rt1: any) =>
                setbehaviourpreprop((pre: any) => ({ ...pre, [rt1.skill]: rt1.id })),
              )
            : [];
          data && data.recruiters
            ? setValue(
                'recruitersData',
                data.recruiters.map((ty12: any) => {
                  setprerecuiters((pl: any) => ({ ...pl, [ty12.userId]: ty12.id }));
                  setEditRecruiterList((pl: any) => [...pl, { id: ty12.id, userId: ty12.userId }]);
                  return { userId: ty12.userId, target: ty12.target };
                }),
              )
            : '';
          data && data.files && !_.isEmpty(data.files) ? setfileupload(data.files) : [];
          // val.totalInReview ? setapprovetotal(Number(val.totalInReview)):'';
        }
      }
    } catch (e: any) {
      setLd(!true);
    } finally {
      setLd(!true);
    }
  };

  useEffect(() => {
    alldropdownapicalls();
  }, []);

  useEffect(() => {
    if (!_.isEmpty(watch('clientId'))) {
      if (!_.isEmpty(crmdata[watch('clientId')])) {
        const mappedcrm: any = crmdata[watch('clientId')]
          // .filter((e: any) => e.userId === userId)
          .map((p2: any) => {
            if (!_.isEmpty(p2)) {
              return {
                label:
                  p2.user && p2.user.firstName && p2.user.lastName
                    ? `${p2.user.firstName} ${p2.user.lastName}`
                    : '',
                value: p2.id ? p2.id : '',
                userId: p2.userId,
              };
            }
          });

        !_.isEmpty(mappedcrm) ? setcrmId(mappedcrm) : '';
        if (
          mappedcrm
            ?.filter((e: any) => e.value === watch('crmId'))
            ?.find((e: any) => e.userId !== userId)
        ) {
          gotoNavigate(AdminPrivateRoutes.POSITIONS.POSITIONSVIEW);
        } else if (!positionid) {
          setValue('crmId', mappedcrm?.find((e: any) => e.userId === userId)?.value);
        }
      }

      if (!_.isEmpty(spocdata[watch('clientId')])) {
        const mappedspoc: any = spocdata[watch('clientId')]
          .filter((e: any) => e.status)
          .map((p2: any) => {
            if (p2.status) {
              return {
                label: p2 && p2.firstName && p2.lastName ? `${p2.firstName} ${p2.lastName}` : '',
                value: p2.id ? p2.id : '',
              };
            }
          });

        !_.isEmpty(mappedspoc) ? setSpocname(mappedspoc) : '';
      }
    }
  }, [watch('clientId')]);

  function handleRejectprops(idxen: number) {
    setfileRejectionItems((val: any) => val.filter((o: any, i: number) => idxen !== i));
  }
  function handleSuccessprops(idxen: number) {
    setfileupload((val: any) => val.filter((o: any, i: number) => idxen !== i));
  }
  function handleSuccessprops2(idxen: string) {
    setremoveFileIds((pl: any) => [...pl, idxen]);
  }
  function handleRemove(id: any, ixn: number) {
    if (id) {
      removeId.push(id);
      setRemoveId([...removeId]);
      setfileupload((val: any) => val?.filter((o: any, i: number) => id !== o?.id));
    } else {
      setfileupload((val: any) => val.filter((o: any, i: number) => ixn !== i));
    }
  }

  useEffect(() => {
    if (!_.isEmpty(menuList)) {
      const settingData = menuList
        .find((e: any) => e.moduleName === 'Position')
        .screens.find((a: any) => a.screenName === 'Add Position');
      if (!settingData) {
        navigate('/');
      }
    }
  }, [menuList]);
  // useEffect(() => {
  //   if (!_.isEmpty(location?.state?.clientId)) {
  //     setValue('clientId', location.state.clientId);
  //   }
  // }, [location?.state]);

  // useEffect(() => {
  //   if (watch('spocId').length < 0) {
  //     setSpocOpen(true);
  //   }
  // }, []);

  useEffect(() => {
    const options: any = [];
    Country.getAllCountries().map((country) => {
      options.push({ label: country.name, value: country.isoCode });
    });
    setCountry(options);
  }, []);

  const filterData = Spocname.find((item: any) => item.value === spocOpen?.name)?.label;

  return (
    <>
      <Grid container>
        <Grid item md={12} sm={12} xs={12}>
          <Typography component={'div'} className='breadcrumbs d-flex-a'>
            <Breadcrumbs aria-label='breadcrumb'>
              <Link underline='hover' color='inherit' href='/'>
                <img src={HomeIcon} className='home' alt='Home' />
              </Link>
              <Link
                underline='hover'
                color='inherit'
                href={''}
                onClick={() => gotoNavigate(AdminPrivateRoutes.POSITIONS.POSITIONSVIEW)}
                style={{ cursor: 'pointer' }}>
                <img src={ArrowIcon} alt='arrow' className='arrows' />
                Positions
              </Link>
              <Typography
                color='text.primary'
                sx={{ cursor: 'pointer' }}
                onClick={() => gotoNavigate(AdminPrivateRoutes.POSITIONS.POSITIONSADD)}>
                {' '}
                <img src={ArrowIcon} alt='arrow' className='arrows' />
                {locationbool ? 'Edit' : 'Add'} Position
              </Typography>
            </Breadcrumbs>
          </Typography>

          <Typography component={'div'} className='d-flex-a flex-sb title-add-box'>
            <Typography className='page-title'>{locationbool ? 'Edit' : 'Add'} Position</Typography>
            <Typography component={'div'} className='d-flex-a'>
              <Button
                className='cancel-btn mr-16'
                onClick={() =>
                  !_.isEmpty(location?.state?.clientId)
                    ? gotoNavigate(AdminPrivateRoutes.CLIENTS.CLIENTVIEWDETAILS, {
                        state: { id: location.state.clientId },
                      })
                    : gotoNavigate('/positions')
                }>
                {' '}
                Cancel
              </Button>
              <Button className='s-add-btn' type={'submit'} form={'user-add'}>
                {' '}
                Save
              </Button>
            </Typography>
          </Typography>
          <Typography component={'div'} className='main-content'>
            <form
              className='private-form'
              id={'user-add'}
              onSubmit={
                Mode === 'edit' && !reasonfalse
                  ? handleSubmit(onSubmitForm3)
                  : handleSubmit(onSubmit)
              }>
              <Typography component={'div'} className='white-box position-white-box'>
                <Grid container>
                  <Grid item md={8} sm={12} xs={12}>
                    <Grid container columnSpacing={4.25}>
                      <Grid item md={6} sm={12} xs={12}>
                        <Typography
                          component={'div'}
                          className='custom-field position-container-field'>
                          <Typography component={'p'}>
                            Client Name <span>*</span>
                          </Typography>
                          {/* <TextField className="custom-input" id="standard-basic" placeholder="" variant="standard" /> */}
                          <Controller
                            control={control}
                            defaultValue={''}
                            name='clientId'
                            render={({ field: { onChange, value, name } }) => {
                              return (
                                <CustomSelect
                                  labelKey={'label'}
                                  valueKey={'value'}
                                  options={Clientename}
                                  name={name}
                                  value={value}
                                  onChange={(e) => {
                                    // setqualiload(false);
                                    onChange(e);
                                  }}
                                  placeHolder='Select'
                                  error={errors.clientId?.message}
                                />
                              );
                            }}
                          />
                        </Typography>
                      </Grid>
                      <Grid item md={6} sm={12} xs={12}>
                        <Typography component={'div'} className='custom-field'>
                          <Typography component={'p'}>
                            SPOC Name <span>*</span>
                          </Typography>
                          {/* <TextField className="custom-input" id="standard-basic" placeholder="" variant="standard" /> */}
                          <Controller
                            control={control}
                            defaultValue={''}
                            name='spocId'
                            render={({ field: { onChange, value, name } }) => {
                              return (
                                <CustomSelect
                                  labelKey={'label'}
                                  valueKey={'value'}
                                  options={Spocname}
                                  name={name}
                                  isClearable={false}
                                  value={value ? value : null}
                                  onChange={(e) => {
                                    if (e.target.value !== '') {
                                      setSpocOpen({
                                        show: true,
                                        name: e.target?.value,
                                      });
                                    }
                                    onChange(e);
                                  }}
                                  placeHolder='Select'
                                  error={errors.spocId?.message}
                                />
                              );
                            }}
                          />
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Typography>
              <Typography
                component={'div'}
                className='white-box position-white-box position-white-box-2'>
                <Grid container>
                  <Grid item md={12} sm={12} xs={12}>
                    <Typography component={'div'} className='basic-details-position'>
                      Basic Details
                    </Typography>
                    <Grid container columnSpacing={4.25}>
                      <Grid item md={4} sm={12} xs={12}>
                        <Typography
                          component={'div'}
                          className='custom-field position-container-field'>
                          <Typography component={'p'}>
                            Job Title <span>*</span>
                          </Typography>
                          {/* <TextField className="custom-input" id="standard-basic" placeholder="" variant="standard" /> */}
                          <Controller
                            control={control}
                            defaultValue={''}
                            name={'title'}
                            render={({ field: { onChange, value, name } }) => {
                              return (
                                <CustomInput
                                  value={value}
                                  name={name}
                                  className='custom-input'
                                  placeHolder='Job Title'
                                  error={errors.title ? errors?.title?.message : ''}
                                  onChange={(e) => {
                                    onChange(e);
                                  }}
                                />
                              );
                            }}
                          />
                        </Typography>
                      </Grid>
                      <Grid item md={4} sm={12} xs={12}>
                        <Typography component={'div'} className='custom-field'>
                          <Typography component={'p'}>
                            Job Country <span>*</span>
                          </Typography>
                          {/* <TextField className="custom-input" id="standard-basic" placeholder="" variant="standard" /> */}
                          <Controller
                            control={control}
                            defaultValue={''}
                            name={'country'}
                            render={({ field: { onChange, value, name } }) => {
                              return (
                                <CustomSelect
                                  labelKey={'label'}
                                  valueKey={'value'}
                                  options={country}
                                  value={value}
                                  name={name}
                                  className='custom-input'
                                  placeHolder='Job Country'
                                  error={errors.country ? errors?.country?.message : ''}
                                  onChange={(e) => {
                                    onChange(e);
                                  }}
                                />
                              );
                            }}
                          />
                        </Typography>
                      </Grid>
                      <Grid item md={4} sm={12} xs={12}>
                        <Typography component={'div'} className='custom-field'>
                          <Typography component={'p'}>
                            Job Location <span>*</span>
                          </Typography>
                          {/* <TextField className="custom-input" id="standard-basic" placeholder="" variant="standard" /> */}
                          <Controller
                            control={control}
                            defaultValue={''}
                            name={'location'}
                            render={({ field: { onChange, value, name } }) => {
                              return (
                                <CustomInput
                                  value={value}
                                  name={name}
                                  className='custom-input'
                                  placeHolder='Job Location'
                                  error={errors.location ? errors?.location?.message : ''}
                                  onChange={(e) => {
                                    onChange(e);
                                  }}
                                />
                              );
                            }}
                          />
                        </Typography>
                      </Grid>

                      <Grid item md={4} sm={12} xs={12}>
                        <Typography component={'div'} className='custom-field'>
                          <Typography component={'p'}>
                            Job Code <span>*</span>
                          </Typography>
                          {/* <TextField className="custom-input" id="standard-basic" placeholder="" variant="standard" /> */}
                          <Controller
                            control={control}
                            defaultValue={''}
                            name={'jobCode'}
                            render={({ field: { onChange, value, name } }) => {
                              return (
                                <CustomInput
                                  disabled
                                  value={value}
                                  name={name}
                                  className='custom-input'
                                  placeHolder='Specialization'
                                  error={errors.jobCode ? errors?.jobCode?.message : ''}
                                  onChange={(e) => {
                                    onChange(e);
                                  }}
                                />
                              );
                            }}
                          />
                        </Typography>
                      </Grid>
                      <Grid item md={4} sm={12} xs={12}>
                        <Typography component={'div'} className='custom-field'>
                          <Typography component={'p'}>
                            Functional Area <span>*</span>
                          </Typography>
                          {/* <TextField className="custom-input" id="standard-basic" placeholder="" variant="standard" /> */}

                          <Controller
                            control={control}
                            defaultValue={''}
                            name='functionalAreaId'
                            render={({ field: { onChange, value, name } }) => {
                              return (
                                <CustomSelect
                                  labelKey={'label'}
                                  valueKey={'value'}
                                  options={functionalArea}
                                  name={name}
                                  value={value}
                                  onChange={(e) => {
                                    // setqualiload(false);
                                    onChange(e);
                                  }}
                                  placeHolder='Select'
                                  error={errors.functionalAreaId?.message}
                                />
                              );
                            }}
                          />
                        </Typography>
                      </Grid>
                      <Grid item md={4} sm={12} xs={12}>
                        <Typography component={'div'} className='custom-field'>
                          <Typography component={'p'}>
                            Industry <span>*</span>
                          </Typography>
                          {/* <TextField className="custom-input" id="standard-basic" placeholder="" variant="standard" /> */}

                          <Controller
                            control={control}
                            defaultValue={''}
                            name='industry'
                            render={({ field: { onChange, value, name } }) => {
                              return (
                                <CustomSelect
                                  labelKey={'label'}
                                  valueKey={'value'}
                                  options={industry}
                                  name={name}
                                  value={value}
                                  onChange={(e) => {
                                    // setqualiload(false);
                                    onChange(e);
                                  }}
                                  placeHolder='Select'
                                  error={errors.industry?.message}
                                />
                              );
                            }}
                          />
                        </Typography>
                      </Grid>
                      <Grid item md={4} sm={12} xs={12}>
                        <Typography component={'div'} className='custom-field'>
                          <Typography component={'p'}>
                            Gender <span>*</span>
                          </Typography>
                          {/* <TextField className="custom-input" id="standard-basic" placeholder="" variant="standard" /> */}

                          <Controller
                            control={control}
                            defaultValue='noPreference'
                            name='gender'
                            render={({ field: { onChange, value, name } }) => {
                              return (
                                <RadioGroup
                                  className='custom-radio'
                                  row
                                  aria-labelledby=''
                                  name={name}
                                  value={value}
                                  onChange={(e) => {
                                    onChange(e);
                                  }}>
                                  <FormControlLabel value='male' control={<Radio />} label='Male' />
                                  <FormControlLabel
                                    value='female'
                                    control={<Radio />}
                                    label='Female'
                                  />
                                  {/* <FormControlLabel
                                    value='noPreference'
                                    control={<Radio />}
                                    label='No Preference'
                                  /> */}
                                </RadioGroup>
                              );
                            }}
                          />
                        </Typography>
                      </Grid>
                      <Grid item md={4} sm={12} xs={12}>
                        <Typography component={'div'} className='custom-field'>
                          <Typography component={'p'}>
                            Age <span>*</span>
                          </Typography>
                          {/* <TextField className="custom-input" id="standard-basic" placeholder="" variant="standard" /> */}

                          <Controller
                            control={control}
                            name='minAge'
                            render={({ field: { onChange, value, name } }) => {
                              return (
                                <CustomInput
                                  className='custom-input'
                                  onChange={(e) => {
                                    onChange(e);
                                    trigger('minAge');
                                    // setValue('minAge',Number(value));
                                  }}
                                  value={value}
                                  name={name}
                                  placeHolder='Min.'
                                  type={'number'}
                                  minLength={2}
                                  error={errors?.minAge?.message}
                                />
                              );
                            }}
                          />
                        </Typography>
                      </Grid>
                      <Grid item md={4} sm={12} xs={12}>
                        <Typography component={'div'} className='custom-field'>
                          <Typography component={'p'} style={{ opacity: '0 !important' }}>
                            <span style={{ opacity: 0 }}>
                              Age<span>*</span>{' '}
                            </span>{' '}
                            {/* not for display*/}
                          </Typography>
                          {/* <TextField className="custom-input" id="standard-basic" placeholder="" variant="standard" /> */}

                          <Controller
                            control={control}
                            name='maxAge'
                            render={({ field: { onChange, value, name } }) => {
                              return (
                                <CustomInput
                                  className='custom-input'
                                  onChange={(e) => {
                                    onChange(e);
                                    trigger('maxAge');
                                  }}
                                  value={value}
                                  name={name}
                                  placeHolder='Max.'
                                  type={'number'}
                                  minLength={2}
                                  error={errors?.maxAge?.message}
                                />
                              );
                            }}
                          />
                        </Typography>
                      </Grid>
                      <Grid item md={4} sm={12} xs={12}>
                        <Typography component={'div'} className='custom-field'>
                          <Typography component={'p'}>
                            Minimum Experience<span>*</span>{' '}
                          </Typography>
                          <Controller
                            control={control}
                            name='minExp'
                            render={({ field: { onChange, value, name } }) => {
                              return (
                                <CustomInput
                                  className='custom-input'
                                  onChange={(e) => {
                                    const vals = e.target.value.replace(/[^0-9]/g, '');
                                    onChange(vals);
                                  }}
                                  value={value}
                                  name={name}
                                  placeHolder='Minimum Experience'
                                  type={'number'}
                                  minLength={2}
                                  error={errors?.minExp?.message}
                                />
                              );
                            }}
                          />
                        </Typography>
                      </Grid>
                      <Grid item md={4} sm={12} xs={12}>
                        <Typography component={'div'} className='custom-field'>
                          <Typography component={'p'}>
                            Maximum Experience<span>*</span>{' '}
                          </Typography>
                          <Controller
                            control={control}
                            name='maxExp'
                            render={({ field: { onChange, value, name } }) => {
                              return (
                                <CustomInput
                                  className='custom-input'
                                  onChange={(e) => {
                                    const vals = e.target.value.replace(/[^0-9]/g, '');
                                    onChange(vals);
                                  }}
                                  value={value}
                                  name={name}
                                  placeHolder='Maximum Experience'
                                  type={'number'}
                                  minLength={2}
                                  error={errors?.maxExp?.message}
                                />
                              );
                            }}
                          />
                        </Typography>
                      </Grid>
                      <Grid item md={4} sm={12} xs={12} style={{ display: 'none' }}>
                        <Typography component={'div'} className='custom-field'>
                          <Typography component={'p'}>
                            Minimum Experience<span>*</span>{' '}
                          </Typography>
                          <Controller
                            control={control}
                            name='maxAge'
                            render={({ field: { onChange, value, name } }) => {
                              return (
                                <CustomInput
                                  className='custom-input'
                                  onChange={(e) => {
                                    onChange(e);
                                    trigger('maxAge');
                                  }}
                                  value={value}
                                  name={name}
                                  placeHolder='Max.'
                                  type={'number'}
                                  minLength={2}
                                  error={errors?.maxAge?.message}
                                />
                              );
                            }}
                          />
                        </Typography>
                      </Grid>
                      <Grid item md={4} sm={12} xs={12}>
                        <Typography component={'div'} className='custom-field'>
                          <Typography component={'p'}>
                            Qualification <span>*</span>
                          </Typography>
                          {/* <TextField className="custom-input" id="standard-basic" placeholder="" variant="standard" /> */}

                          <Controller
                            control={control}
                            defaultValue={''}
                            name={'qualification'}
                            render={({ field: { onChange, value, name } }) => {
                              return (
                                <CustomInput
                                  value={value}
                                  name={name}
                                  className='custom-input'
                                  placeHolder='Qualification'
                                  error={errors.qualification ? errors?.qualification?.message : ''}
                                  onChange={(e) => {
                                    onChange(e);
                                  }}
                                />
                              );
                            }}
                          />
                        </Typography>
                      </Grid>
                      <Grid item md={4} sm={12} xs={12}>
                        <Typography component={'div'} className='custom-field'>
                          <Typography component={'p'}>
                            Technical Skills <span>*</span>
                          </Typography>
                          {/* <TextField className="custom-input" id="standard-basic" placeholder="" variant="standard" /> */}

                          <Controller
                            control={control}
                            defaultValue={[]}
                            name={'technicalSkillsData'}
                            render={({ field: { onChange, value, name } }) => {
                              return (
                                // <CustomInput
                                //   value={value}
                                //   name={name}
                                //   className='custom-input'
                                //   placeHolder='Specialization'
                                //   error={
                                // errors.technicalSkillsData
                                //   ? errors?.technicalSkillsData?.message
                                //   : ''
                                //   }
                                //   onChange={(e) => {
                                //     onChange(e);
                                //   }}
                                // />
                                <>
                                  <CustomTagInput
                                    value={value}
                                    name={name}
                                    error={errors.technicalSkillsData ? true : false}
                                    // onChange={onChange}
                                    placeHolder={'Technical Skills'}
                                    onChange={(e: any) => {
                                      onChange(e);
                                    }}
                                  />
                                  <div className='errorposition'>
                                    {errors.technicalSkillsData
                                      ? errors?.technicalSkillsData?.message
                                      : ''}
                                  </div>
                                </>
                              );
                            }}
                          />
                        </Typography>
                      </Grid>
                      <Grid item md={4} sm={12} xs={12}>
                        <Typography component={'div'} className='custom-field'>
                          <Typography component={'p'}>
                            Behavioural Skills <span>*</span>
                          </Typography>
                          {/* <TextField className="custom-input" id="standard-basic" placeholder="" variant="standard" /> */}

                          <Controller
                            control={control}
                            defaultValue={[]}
                            name={'behaviouralSkillsData'}
                            render={({ field: { onChange, value, name } }) => {
                              return (
                                // <CustomInput
                                //   value={value}
                                //   name={name}
                                //   className='custom-input'
                                //   placeHolder='Specialization'
                                //   error={
                                // errors.behaviouralSkillsData
                                //   ? errors?.behaviouralSkillsData?.message
                                //   : ''
                                //   }
                                //   onChange={(e) => {
                                //     onChange(e);
                                //   }}
                                // />
                                <>
                                  <CustomTagInput
                                    value={value}
                                    name={name}
                                    placeHolder={'Behavioural Skills'}
                                    // onChange={onChange}
                                    error={errors.behaviouralSkillsData ? true : false}
                                    onChange={(e: any) => {
                                      onChange(e);
                                    }}
                                  />
                                  <div className='errorposition'>
                                    {errors.behaviouralSkillsData
                                      ? errors?.behaviouralSkillsData?.message
                                      : ''}
                                  </div>
                                </>
                              );
                            }}
                          />
                        </Typography>
                      </Grid>
                      <Grid item md={4} sm={12} xs={12}>
                        <Typography component={'div'} className='custom-field'>
                          <Typography component={'p'}>{/* dummy text */}</Typography>
                          {/* <TextField className="custom-input" id="standard-basic" placeholder="" variant="standard" /> */}
                          {/* dummy text */}
                        </Typography>
                      </Grid>
                      <Grid item md={4} sm={12} xs={12}>
                        <Typography component={'div'} className='custom-field'>
                          <Typography component={'p'}>
                            Annual CTC <span>*</span>
                          </Typography>
                          {/* <TextField className="custom-input" id="standard-basic" placeholder="" variant="standard" /> */}

                          <Controller
                            control={control}
                            name='minCtc'
                            render={({ field: { onChange, value, name } }) => {
                              return (
                                //   <CustomInput
                                //   value={value}
                                //   name={name}
                                //   className='custom-input'
                                //   placeHolder='Specialization'
                                //   error={
                                //     errors.minCurrency
                                //       ? errors?.minCurrency?.message
                                //       : ''
                                //   }
                                //   onChange={(e) => {
                                //     onChange(e);
                                //   }}
                                // />
                                <CustomInput
                                  className='custom-input'
                                  onChange={onChange}
                                  value={value}
                                  name={name}
                                  placeHolder='Min. CTC'
                                  type={'number'}
                                  error={errors?.minCtc?.message}
                                  customComponent={
                                    <Box
                                      sx={{
                                        minWidth: 120,
                                        position: 'absolute',
                                        right: '-40px',
                                        transform: 'scale(0.9)',
                                      }}>
                                      <Controller
                                        control={control}
                                        name='minCurrency'
                                        render={({ field: { value, name } }) => {
                                          return (
                                            <NativeSelect
                                              // defaultValue={getValues('minCurrency')}
                                              value={value}
                                              name={name}
                                              onChange={(e: any) => {
                                                setValue('minCurrency', e.target.value);
                                              }}
                                              // disabled
                                              className='position-currency-option'
                                              IconComponent={ExpandMoreRoundedIcon}
                                              sx={{
                                                border: 'none !important',
                                                minHeight: '20px !important',
                                                '&::before': {
                                                  borderBottom: 'transparent !important',
                                                },
                                                '& > select': {
                                                  minWidth: '40px !important',
                                                  border: 'none !important',
                                                  outline: 'none !important',
                                                  marginRight: '8px',
                                                },
                                              }}>
                                              <option value={'inr'}>INR</option>
                                              <option value={'usd'}>USD</option>
                                              <option value={'aed'}>AED</option>
                                            </NativeSelect>
                                          );
                                        }}
                                      />
                                    </Box>
                                  }
                                />
                              );
                            }}
                          />
                        </Typography>
                      </Grid>

                      <Grid item md={4} sm={12} xs={12}>
                        <Typography component={'div'} className='custom-field'>
                          <Typography component={'p'} sx={{ opacity: '0 !important' }}>
                            Annual CTC <span>*</span> {/* not for display */}
                          </Typography>
                          {/* <TextField className="custom-input" id="standard-basic" placeholder="" variant="standard" /> */}

                          <Controller
                            control={control}
                            name='maxCtc'
                            render={({ field: { onChange, value, name } }) => {
                              return (
                                //   <CustomInput
                                //   value={value}
                                //   name={name}
                                //   className='custom-input'
                                //   placeHolder='Specialization'
                                //   error={
                                //     errors.maxCurrency
                                //       ? errors?.maxCurrency?.message
                                //       : ''
                                //   }
                                //   onChange={(e) => {
                                //     onChange(e);
                                //   }}
                                // />
                                <>
                                  <CustomInput
                                    className='custom-input phnumber-posiotion'
                                    onChange={onChange}
                                    value={value}
                                    name={name}
                                    placeHolder='Max CTC'
                                    type={'number'}
                                    error={errors?.maxCtc?.message}
                                    customComponent={
                                      <Box
                                        sx={{
                                          minWidth: 120,
                                          position: 'absolute',
                                          right: '-40px',
                                          transform: 'scale(0.9)',
                                        }}>
                                        <Controller
                                          control={control}
                                          name='maxCurrency'
                                          render={({ field: { value, name } }) => {
                                            return (
                                              <NativeSelect
                                                value={value}
                                                name={name}
                                                onChange={(e: any) => {
                                                  setValue('maxCurrency', e.target.value);
                                                }}
                                                // disabled
                                                className='position-currency-option'
                                                IconComponent={ExpandMoreRoundedIcon}
                                                sx={{
                                                  border: 'none !important',
                                                  minHeight: '20px !important',
                                                  '&::before': {
                                                    borderBottom: 'transparent !important',
                                                  },
                                                  '& > select': {
                                                    minWidth: '40px !important',
                                                    border: 'none !important',
                                                    outline: 'none !important',
                                                    marginRight: '8px',
                                                  },
                                                }}>
                                                <option value={'inr'}>INR</option>
                                                <option value={'usd'}>USD</option>
                                                <option value={'aed'}>AED</option>
                                              </NativeSelect>
                                            );
                                          }}
                                        />
                                      </Box>
                                    }
                                  />
                                </>
                              );
                            }}
                          />
                        </Typography>
                      </Grid>

                      <Grid item md={4} sm={12} xs={12}>
                        <Typography component={'div'} className='custom-field'>
                          <Typography component={'p'}>
                            Expected Joining Date <span>*</span>
                          </Typography>
                          {/* <TextField className="custom-input" id="standard-basic" placeholder="" variant="standard" /> */}

                          <Controller
                            control={control}
                            name={'expectedJoiningDate'}
                            render={({ field: { onChange, value, name } }) => {
                              return (
                                // <CustomInput
                                //   value={value}
                                //   name={name}
                                //   className='custom-input'
                                //   placeHolder='Specialization'
                                //   error={
                                //     errors.expectedJoiningDate
                                //       ? errors?.expectedJoiningDate?.message
                                //       : ''
                                //   }
                                //   onChange={(e) => {
                                //     onChange(e);
                                //   }}
                                // />
                                <>
                                  <TextField
                                    value={value}
                                    name={name}
                                    id='date'
                                    type='date'
                                    placeholder={'Select Date'}
                                    className={`clientfilter-time-from-to ${
                                      errors.expectedJoiningDate &&
                                      errors.expectedJoiningDate.message
                                        ? 'clientfilter-time-from-to-error'
                                        : ''
                                    }`}
                                    onChange={(e) => {
                                      // trigger('expectedJoiningDate');
                                      onChange(e);
                                    }}
                                    inputProps={{ min: today }}
                                    // className={classes.textField}
                                  />
                                  <div className='errorposition'>
                                    {errors.expectedJoiningDate
                                      ? errors?.expectedJoiningDate?.message
                                      : ''}
                                  </div>
                                </>
                              );
                            }}
                          />
                        </Typography>
                      </Grid>
                      <Grid item md={4} sm={12} xs={12}>
                        <Typography component={'div'} className='custom-field'>
                          <Typography component={'p'}>
                            Total Openings <span>*</span>
                          </Typography>
                          {/* <TextField className="custom-input" id="standard-basic" placeholder="" variant="standard" /> */}

                          <Controller
                            control={control}
                            name='totalOpenins'
                            render={({ field: { onChange, value, name } }) => {
                              return (
                                <CustomInput
                                  className='custom-input'
                                  onChange={(e) => {
                                    onChange(e);
                                  }}
                                  value={value}
                                  name={name}
                                  type={'number'}
                                  placeHolder='Total Openings'
                                  error={errors.totalOpenins?.message}
                                />
                              );
                            }}
                          />
                        </Typography>
                      </Grid>
                      <Grid item md={4} sm={12} xs={12}>
                        <Typography component={'div'} className='custom-field'>
                          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Typography component={'p'}>
                              CRM<span>*</span>
                            </Typography>
                            {searchParams.get('positionid') && (
                              <div
                                style={{ fontSize: '14px', color: '#5F2EE5', cursor: 'pointer' }}
                                onClick={() => setOpenCRMchange(true)}>
                                change
                              </div>
                            )}
                          </div>
                          {/* <TextField className="custom-input" id="standard-basic" placeholder="" variant="standard" /> */}
                          <Controller
                            control={control}
                            name='crmId'
                            render={({ field: { onChange, value, name } }) => {
                              const dropDownValue =
                                Mode === 'edit'
                                  ? crmId.filter((e: any) => e.value === watch('crmId'))
                                  : crmId.filter((e: any) => e.userId === userId);
                              return (
                                <CustomSelect
                                  labelKey={'label'}
                                  valueKey={'value'}
                                  options={dropDownValue}
                                  disabled
                                  name={name}
                                  value={value}
                                  onChange={(e) => {
                                    // setqualiload(false);
                                    onChange(e);
                                  }}
                                  placeHolder='Select'
                                  error={errors.crmId?.message}
                                />
                              );
                            }}
                          />
                        </Typography>
                      </Grid>
                      <Grid item md={4} sm={12} xs={12}>
                        <Typography component={'div'} className='custom-field'>
                          <Typography component={'p'}>
                            Recruiters<span>*</span>
                          </Typography>
                          {/* <TextField className="custom-input" id="standard-basic" placeholder="" variant="standard" /> */}

                          <Controller
                            control={control}
                            name='recruitersData'
                            render={({ field: { value, name } }) => {
                              return (
                                <>
                                  <CustomSelect
                                    labelKey={'label'}
                                    valueKey={'value'}
                                    // options={[{label:'san',value:{userId:'1',target:0}},{label:'go',value:{userId:'2',target:10}}]}
                                    options={Recuiterslis}
                                    disabled={
                                      Number(getValues('totalOpenins')) -
                                        Number(dynamictotalposition) >
                                      0
                                        ? false
                                        : true
                                    }
                                    onBlur={() => trigger('recruitersData')}
                                    onFocus={() => trigger('recruitersData')}
                                    onClose={() => trigger('recruitersData')}
                                    name={name}
                                    value={value}
                                    isMulti={true}
                                    Position={true}
                                    triggermodal={(val12: any) => {
                                      Number(getValues('totalOpenins')) -
                                        Number(dynamictotalposition) >
                                        0 && setmodaluid(val12);
                                      Number(getValues('totalOpenins')) -
                                        Number(dynamictotalposition) >
                                        0 && setRejectionModalOpen(true);
                                    }}
                                    onChange={() => trigger('recruitersData')}
                                    placeHolder='Select'
                                    error={errors.recruitersData?.message}
                                    isClearable={false}
                                  />
                                  <Grid container>
                                    {!_.isEmpty(watch('recruitersData')) &&
                                      watch('recruitersData').map((val2: any) => {
                                        if (val2 && val2.userId) {
                                          const findedrec = Recuiterslis.find(
                                            (d: any) => d.value.userId === val2.userId,
                                          );
                                          return (
                                            <div className='recuiter-tags' key={val2.userId}>
                                              <span>
                                                {findedrec.label} - {val2.target}
                                              </span>
                                              <img
                                                src={Svggreycircle}
                                                alt='svggreyclose'
                                                onClick={() => {
                                                  const filteredModaldata = !_.isEmpty(
                                                    watch('recruitersData'),
                                                  )
                                                    ? watch('recruitersData').filter((io1: any) => {
                                                        return (
                                                          !_.isEmpty(io1.userId) &&
                                                          io1.userId !== val2['userId']
                                                        );
                                                      })
                                                    : [];
                                                  setValue('recruitersData', filteredModaldata);
                                                }}
                                              />
                                            </div>
                                          );
                                        }
                                      })}
                                  </Grid>
                                </>
                              );
                            }}
                          />
                        </Typography>
                      </Grid>
                      <Grid item md={4} sm={12} xs={12}>
                        <Typography component={'div'} className='custom-field'>
                          <Typography component={'p'}>
                            Resume Type<span>*</span>
                          </Typography>
                          {/* <TextField className="custom-input" id="standard-basic" placeholder="" variant="standard" /> */}

                          <Controller
                            control={control}
                            name='resumeType'
                            render={({ field: { onChange, value, name } }) => {
                              return (
                                <CustomSelect
                                  labelKey={'label'}
                                  valueKey={'value'}
                                  options={[
                                    { label: 'Snapshot', value: 'Snapshot' },
                                    {
                                      label: 'Fully Formatted Resume',
                                      value: 'Fully Formatted Resume',
                                    },
                                  ]}
                                  name={name}
                                  value={value}
                                  onChange={(e) => {
                                    // setqualiload(false);
                                    onChange(e);
                                  }}
                                  placeHolder='Select'
                                  error={errors.resumeType?.message}
                                />
                              );
                            }}
                          />
                        </Typography>
                      </Grid>
                      <Grid item md={4} sm={12} xs={12}>
                        <Typography component={'div'} className='custom-field'>
                          <Typography component={'p'}>
                            Project Type<span>*</span>
                          </Typography>
                          {/* <TextField className="custom-input" id="standard-basic" placeholder="" variant="standard" /> */}

                          <Controller
                            control={control}
                            name='projectType'
                            render={({ field: { onChange, value, name } }) => {
                              return (
                                <CustomSelect
                                  labelKey={'label'}
                                  valueKey={'value'}
                                  options={[
                                    { label: 'RPO', value: 'RPO' },
                                    { label: 'Non-RPO', value: 'Non-RPO' },
                                  ]}
                                  name={name}
                                  value={value}
                                  onChange={(e) => {
                                    // setqualiload(false);
                                    onChange(e);
                                  }}
                                  placeHolder='Select'
                                  error={errors.projectType?.message}
                                />
                              );
                            }}
                          />
                        </Typography>
                      </Grid>
                      <Grid item md={4} sm={12} xs={12}>
                        <Typography component={'div'} className='custom-field'>
                          <Typography component={'p'}>{/* dummy text */}</Typography>
                          {/* <TextField className="custom-input" id="standard-basic" placeholder="" variant="standard" /> */}
                          {/* dummy text */}
                        </Typography>
                      </Grid>
                      <Grid item md={4} sm={12} xs={12}>
                        <Typography component={'div'} className='custom-field'>
                          <Typography component={'p'}>
                            Billable value <span>*</span>
                          </Typography>
                          {/* <TextField className="custom-input" id="standard-basic" placeholder="" variant="standard" /> */}

                          <Controller
                            control={control}
                            name='billableValue'
                            render={({ field: { onChange, value, name } }) => {
                              return (
                                <CustomInput
                                  className='custom-input'
                                  onChange={onChange}
                                  value={value}
                                  name={name}
                                  type={'number'}
                                  placeHolder='Billable value'
                                  error={errors.billableValue?.message}
                                />
                              );
                            }}
                          />
                        </Typography>
                      </Grid>
                      <Grid item md={4} sm={12} xs={12}>
                        <Typography component={'div'} className='custom-field'>
                          <Typography component={'p'}>
                            Total Billable Value <span>*</span>
                          </Typography>
                          {/* <TextField className="custom-input" id="standard-basic" placeholder="" variant="standard" /> */}

                          <CustomInput
                            className='custom-input'
                            value={totbval}
                            disabled
                            placeHolder='billableValue'
                            // error={errors.billableValue?.message}
                          />
                        </Typography>
                      </Grid>
                      <Grid item md={4} sm={12} xs={12}>
                        <Typography component={'div'} className='custom-field'>
                          <Typography component={'p'}>{/* dummy text */}</Typography>
                          {/* <TextField className="custom-input" id="standard-basic" placeholder="" variant="standard" /> */}
                          {/* dummy text */}
                        </Typography>
                      </Grid>
                      <Grid item md={4} sm={12} xs={12}>
                        <Typography component={'div'} className='custom-field'>
                          <Typography component={'p'}>
                            Hide Resume Contacts <span>*</span>
                          </Typography>
                          {/* <TextField className="custom-input" id="standard-basic" placeholder="" variant="standard" /> */}

                          <Controller
                            control={control}
                            name='hideResumeContacts'
                            render={({ field: { onChange, value, name } }) => {
                              return (
                                <RadioGroup
                                  className='custom-radio'
                                  row
                                  aria-labelledby=''
                                  name={name}
                                  value={value}
                                  onChange={(e) => {
                                    onChange(e);
                                  }}>
                                  <FormControlLabel
                                    value={true}
                                    control={<Radio />}
                                    label='Yes'
                                    // checked={value === true}
                                  />
                                  <FormControlLabel
                                    value={false}
                                    control={<Radio />}
                                    label='No'
                                    // checked={value === false}
                                  />
                                </RadioGroup>
                              );
                            }}
                          />
                        </Typography>
                      </Grid>
                      <Grid item md={4} sm={12} xs={12}>
                        <Typography component={'div'} className='custom-field'>
                          <Typography component={'p'}>
                            Publish in Website<span>*</span>
                          </Typography>
                          {/* <TextField className="custom-input" id="standard-basic" placeholder="" variant="standard" /> */}

                          <Controller
                            control={control}
                            defaultValue={true}
                            name='publishInWebsite'
                            render={({ field: { onChange, value, name } }) => {
                              return (
                                <RadioGroup
                                  className='custom-radio'
                                  row
                                  aria-labelledby=''
                                  name={name}
                                  value={value}
                                  onChange={(e) => {
                                    // setpublishinwebsites(value);
                                    onChange(e);
                                  }}>
                                  <FormControlLabel
                                    value={true}
                                    control={<Radio />}
                                    label='Yes'
                                    // checked={value === true}
                                  />
                                  <FormControlLabel
                                    value={false}
                                    control={<Radio />}
                                    label='No'
                                    // checked={value === false}
                                  />
                                </RadioGroup>
                              );
                            }}
                          />
                        </Typography>
                      </Grid>

                      <Grid item md={4} sm={12} xs={12}>
                        <Typography component={'div'} className='custom-field'>
                          <Typography component={'p'}>{/* dummy text */}</Typography>
                          {/* <TextField className="custom-input" id="standard-basic" placeholder="" variant="standard" /> */}
                          {/* dummy text */}
                        </Typography>
                      </Grid>
                      {String(watch('publishInWebsite')) === 'true' ? (
                        <>
                          <Grid item md={12} sm={12} xs={12}>
                            <Typography component={'div'} className='custom-field'>
                              <Typography component={'p'}>
                                Key Responsibilities <span>*</span>
                              </Typography>
                              {/* <TextField className="custom-input" id="standard-basic"  multiline
                        rows={4} placeholder="write content here..." variant="standard" /> */}
                              <Controller
                                render={({ field: { onChange, value, name } }) => (
                                  <CustomInput
                                    value={value ? value : ''}
                                    name={name}
                                    isTextArea={true}
                                    className='custom-input custom-input-position'
                                    placeHolder='Write your content here'
                                    error={errors.keyResponsibilities?.message}
                                    onChange={(e) => {
                                      onChange(e);
                                    }}
                                  />
                                )}
                                name='keyResponsibilities'
                                control={control}
                              />
                            </Typography>
                          </Grid>
                          <Grid item md={12} sm={12} xs={12}>
                            <Typography component={'div'} className='custom-field'>
                              <Typography component={'p'}>
                                Required Industry Exposure <span>*</span>
                              </Typography>
                              {/* <TextField className="custom-input" id="standard-basic"  multiline
                        rows={4} placeholder="write content here..." variant="standard" /> */}
                              <Controller
                                render={({ field: { onChange, value, name } }) => (
                                  <CustomInput
                                    value={value ? value : ''}
                                    name={name}
                                    isTextArea={true}
                                    className='custom-input custom-input-position'
                                    placeHolder='Write your content here'
                                    error={errors.industryExposure?.message}
                                    onChange={(e) => {
                                      onChange(e);
                                    }}
                                  />
                                )}
                                name='industryExposure'
                                control={control}
                              />
                            </Typography>
                          </Grid>
                          <Grid item md={12} sm={12} xs={12}>
                            <Typography component={'div'} className='custom-field'>
                              <Typography component={'p'}>
                                Required Competencies <span>*</span>
                              </Typography>
                              {/* <TextField className="custom-input" id="standard-basic"  multiline
                        rows={4} placeholder="write content here..." variant="standard" /> */}
                              <Controller
                                render={({ field: { onChange, value, name } }) => (
                                  <CustomInput
                                    value={value ? value : ''}
                                    name={name}
                                    isTextArea={true}
                                    className='custom-input custom-input-position'
                                    placeHolder='Write your content here'
                                    error={errors.competencies?.message}
                                    onChange={(e) => {
                                      onChange(e);
                                    }}
                                  />
                                )}
                                name='competencies'
                                control={control}
                              />
                            </Typography>
                          </Grid>
                        </>
                      ) : (
                        <></>
                      )}
                      <Grid item md={12} sm={12} xs={12}>
                        <Typography component={'div'} className='custom-field'>
                          <Typography component={'p'}>
                            Job Description <span>*</span>
                          </Typography>
                          {/* <TextField className="custom-input" id="standard-basic"  multiline
                        rows={4} placeholder="write content here..." variant="standard" /> */}
                          <span
                            className={
                              errors.jobDescription && errors.jobDescription.message
                                ? 'ckeditor-config'
                                : ''
                            }>
                            <CKEditor
                              editor={Editor}
                              data={editorContent && editorContent}
                              onChange={handleEditorChange}
                            />
                          </span>
                          <div className='errorposition'>
                            {errors?.jobDescription ? errors?.jobDescription?.message : ''}
                          </div>
                        </Typography>
                      </Grid>
                      <Grid item md={12} sm={12} xs={12}>
                        <Typography component={'div'} className='custom-field'>
                          <Typography component={'p'} className='position-upload-file-hed'>
                            Upload files
                          </Typography>
                          {/* <TextField className="custom-input" id="standard-basic"  multiline
                        rows={4} placeholder="write content here..." variant="standard" /> */}
                          <Dropzone onDrop={onDrop}>
                            {({ getRootProps, getInputProps }) => {
                              return (
                                <div
                                  {...getRootProps({
                                    className: 'dropzone container container-position pointer',
                                    onClick: (event: any) => {
                                      hiddenFileInput.current?.click();
                                    },
                                  })}>
                                  <div>
                                    <input {...getInputProps()} />
                                    <p className='img-container'>
                                      <img src={Fileupload} alt='file upload' />
                                    </p>
                                    <div className='fil-uplod-desc'>
                                      <p className='first-heading'>
                                        <span className='frst-span' role='button'>
                                          Click to upload
                                        </span>{' '}
                                        <span className='drago'>or drag and drop</span>
                                      </p>
                                      <p className='docx'>DOC, DOCX, PDF (up to 2MB)</p>
                                      <p className='docx'>Max. 3 Documents</p>
                                    </div>
                                  </div>
                                </div>
                              );
                            }}
                          </Dropzone>
                        </Typography>
                      </Grid>
                      <Grid item md={12} sm={12} xs={12}>
                        {fileRejectionItems && fileRejectionItems.length > 0 ? (
                          <Grid container>
                            <Typography component={'div'} className='custom-field'>
                              <Typography component={'p'}>
                                Uploading - {fileupload ? fileupload.length : 0}/
                                {fileupload && fileupload
                                  ? Number(fileupload.length) + Number(fileupload.length)
                                  : 0}{' '}
                                files
                              </Typography>
                            </Typography>
                          </Grid>
                        ) : (
                          <></>
                        )}
                        <Grid container>
                          <></>
                        </Grid>
                      </Grid>
                      <Grid item md={12} sm={12} xs={12}>
                        {fileupload && fileupload.length > 0 ? (
                          <Grid container>
                            <Typography component={'div'} className='custom-field'>
                              <Typography component={'p'}>Uploaded</Typography>
                            </Typography>
                          </Grid>
                        ) : (
                          <></>
                        )}
                        <Grid container>
                          <Typography component={'div'} sx={{ display: 'flex', width: '100%' }}>
                            {/* {prefileupload.map((datup: any, idx1: number) => {
                              return (
                                <Fragment key={idx1}>
                                  <FileComponentui2
                                    fileName={datup.fileName}
                                    id={datup.id}
                                    setcalbk={handleSuccessprops2}
                                  />
                                </Fragment>
                              );
                            })} */}
                            {fileupload.map((datup: any, idxc: number) => {
                              return (
                                <FileComponentui
                                  file={datup}
                                  key={datup.id}
                                  idxc={idxc}
                                  setcalbk={handleSuccessprops}
                                  success={true}
                                  handledelete={handleRemove}
                                />
                              );
                            })}

                            {/* {fileRejectionItems.map((datup: any, idxc: number) => {
                              return (
                                <FileComponentui
                                  file={datup}
                                  key={idxc}
                                  idxc={idxc}
                                  setcalbk={handleRejectprops}
                                  success={false}
                                />
                              );
                            })} */}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Typography>
            </form>
          </Typography>
        </Grid>
        <Modal open={isRejectionModalOpened} onClose={handleRejectClose}>
          <form className='private-form' id={'user-add'} onSubmit={handleSubmit2(onSubmitForm2)}>
            <Box
              className='spoc-list-modal center'
              // style={{ overflow: !opnspocbtndsc ? 'hidden' : 'inherit' }}
            >
              <img src={Closepng} alt='close' className='closepng' onClick={handleRejectClose} />
              <Typography component={'div'} className='custom-field'>
                <Typography component={'div'} className='reason-for-editing'>
                  Openings
                </Typography>
                <Typography component={'p'}>
                  No. of Openings<span>*</span>
                </Typography>
                <Controller
                  render={({ field: { onChange, value, name } }) => (
                    <CustomInput
                      value={value}
                      name={name}
                      type={'number'}
                      className={`custom-input client-comment-box ${
                        errors2.Openingsnumber?.message ? 'client-comment-box-error' : ''
                      }`}
                      placeHolder='No. of openings'
                      error={errors2.Openingsnumber?.message}
                      onChange={(e) => {
                        onChange(e);
                      }}
                    />
                  )}
                  name='Openingsnumber'
                  control={control2}
                />
              </Typography>
              {/* <div className='button-con center'>
                <button className='button'>Submit</button>
              </div> */}
              <div className='button-con end'>
                <div className='buttons editreason-btn'>
                  <button onClick={handleRejectClose} className='editreason-btn-close'>
                    Cancel
                  </button>
                  <button
                    type='submit'
                    // form={'user-add'}
                    // style={{pointerEvents:Number(getValues('totalOpenins')) - Number(dynamictotalposition) > 0?'inherit':'none',opacity:Number(getValues('totalOpenins')) - Number(dynamictotalposition) > 0?1:0.4}}
                    className='editreason-btn-submit'>
                    Submit
                  </button>
                </div>
              </div>
            </Box>
          </form>
        </Modal>
        <Modal
          open={ctgopen}
          onClose={handleClosectg}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'>
          <Box className='logout-modal' style={{ paddingLeft: '0px', paddingRight: '0px' }}>
            <Typography
              className='d-flex-ja mb-24'
              sx={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
              <img src={ClientSuccessIcon} alt='client success' />
              <Typography className='client-sucess-con'>
                <Typography className='title' style={{ marginBottom: '9px' }}>
                  Position Creation Successful!
                </Typography>
                <Typography className='approval' style={{ marginBottom: '24px' }}>
                  Awaiting Approval
                </Typography>
              </Typography>
              <Button className='client-success-modal-btn' onClick={handleClosectg}>
                Ok
              </Button>
            </Typography>
            <CloseIcon
              onClick={handleClosectg}
              style={{
                cursor: 'pointer',
                height: '20px',
                width: '20px',
                position: 'absolute',
                top: '16px',
                right: '16px',
              }}
            />
          </Box>
        </Modal>
        <Modal
          open={spocOpen.show}
          onClose={handleCloseSpocCancel}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'>
          <Box className='logout-modal' style={{ padding: '30px 25px 30px 25px' }}>
            <Typography
              className='d-flex-ja mb-24'
              sx={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
              <img src={ClientSuccessIcon} alt='client success' />
              <Typography className='client-sucess-con' component={'div'} textAlign={'center'}>
                <Typography className='title' style={{ marginBottom: '9px' }}>
                  SPOC Name Confirmation
                </Typography>
                <Typography className='title2' style={{ marginBottom: '24px' }}>
                  Are you sure, Do you want to assign
                  <Typography
                    component={'span'}
                    sx={{ fontSize: '15px', textTransform: 'capitalize' }}>
                    {''} {filterData}
                  </Typography>{' '}
                  as the SPOC for this position
                </Typography>
                <Typography className='approval' style={{ marginBottom: '24px' }}>
                  {' '}
                  Please Confirm
                </Typography>
              </Typography>
              <Typography className='d-flex-a'>
                <Button className='l-cancel-btn mr-16' onClick={handleCloseSpocCancel}>
                  Cancel
                </Button>
                <Button
                  className='client-success-modal-btn'
                  sx={{ width: '87px !important' }}
                  onClick={() => {
                    setValue('spocId', spocOpen.name);
                    setSpocOpen({ show: false, name: '' });
                  }}>
                  Ok
                </Button>
              </Typography>
            </Typography>
            <CloseIcon
              onClick={handleCloseSpocCancel}
              style={{
                cursor: 'pointer',
                height: '20px',
                width: '20px',
                position: 'absolute',
                top: '16px',
                right: '16px',
              }}
            />
          </Box>
        </Modal>
        <Modal open={isRejectionModalOpened3} onClose={handleRejectClose3}>
          <form className='private-form' id={'user-add'} onSubmit={handleSubmit3(onSubmitForm32)}>
            <Box
              className='spoc-list-modal center'
              // style={{ overflow: !opnspocbtndsc ? 'hidden' : 'inherit' }}
            >
              <img src={Closepng} alt='close' className='closepng' onClick={handleRejectClose3} />
              <Typography component={'div'} className='custom-field'>
                <Typography component={'div'} className='reason-for-editing'>
                  Reason For Editing
                </Typography>
                <Typography component={'p'}>
                  Reason<span>*</span>
                </Typography>
                <Controller
                  render={({ field: { onChange, value, name } }) => (
                    <CustomInput
                      value={value}
                      name={name}
                      className={`custom-input client-comment-box ${
                        errors3.reason?.message ? 'client-comment-box-error' : ''
                      }`}
                      placeHolder='Type your reason'
                      isTextArea={true}
                      error={errors3.reason?.message}
                      onChange={(e) => {
                        onChange(e);
                      }}
                    />
                  )}
                  name='reason'
                  control={control3}
                />
              </Typography>
              {/* <div className='button-con center'>
                <button className='button'>Submit</button>
              </div> */}
              <div className='button-con end'>
                <div className='buttons editreason-btn'>
                  <button onClick={handleRejectClose3} className='editreason-btn-close'>
                    Cancel
                  </button>
                  <button
                    type='submit'
                    // form={'user-add'}
                    // style={{pointerEvents:Number(getValues('totalOpenins')) - Number(dynamictotalposition) > 0?'inherit':'none',opacity:Number(getValues('totalOpenins')) - Number(dynamictotalposition) > 0?1:0.4}}
                    className='editreason-btn-submit'>
                    Submit
                  </button>
                </div>
              </div>
            </Box>
          </form>
        </Modal>
        {openCRMchange && (
          <CrmChange
            clientId={getValues('clientId')}
            currentCRM={currentCRM}
            setLD={setLd}
            openCRMchange={openCRMchange}
            setOpenCRMchange={setOpenCRMchange}
            alldropdownapicalls={alldropdownapicalls}
          />
        )}
      </Grid>
      {Ld && <Loader />}
    </>
  );
};
export default AddPositions;
