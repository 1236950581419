/* eslint-disable quotes */
import React from 'react';
import { Typography, Popover } from '@mui/material';
import { resumeStatusToBgColor } from 'utils/helper';
import CvReject from 'assets/png/billing-status.svg';
import { StatusTag } from './statusTag';
import { To, useNavigate } from 'react-router-dom';
import { AdminPrivateRoutes } from 'routes/routeConstans';
import { alertOptions } from 'utils/Config';
import { useAppSelector } from 'store/hooks';
import { toast } from 'react-toastify';

export const BillingComponent: React.FC<{
  resumeId: string;
  candidateName: string;
  getList: () => void;
  offerStatus: string;
  data: any;
  userId: string;
  crmUserId: string;
}> = ({ offerStatus, resumeId, data, userId, crmUserId }) => {
  const { menuList }: any = useAppSelector((store: any) => store.userLogin);
  const [panchorEl, setPAnchorEl] = React.useState<HTMLAnchorElement | null>(null);
  const open = Boolean(panchorEl);
  const paid = open ? 'simple-popover' : undefined;
  const handleClose = () => {
    setPAnchorEl(null);
  };

  const handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    setPAnchorEl(event.currentTarget);
  };
  const navigate = useNavigate();
  const gotoNavigate = (link: To, state: any) => {
    navigate(link, state);
  };

  return (
    <div>
      <Popover
        id={paid}
        anchorEl={panchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        style={{ width: '100%', padding: '1rem' }}>
        <Typography component={'div'}>
          <Typography
            onClick={() =>
              gotoNavigate(
                `${AdminPrivateRoutes.BILLING.BILLINGADD}?resumeId=${resumeId}&positionId=${data?.positionId}`,
                {
                  state: {
                    data: data,
                  },
                },
              )
            }
            className='menu-item'
            style={{ padding: '0px 15px 0px 15px' }}>
            <img src={CvReject} alt='approve' />
            Add Billing
          </Typography>
        </Typography>
      </Popover>
      <Typography component={'p'}>
        <StatusTag
          color='#4A5569'
          backgroundColor={resumeStatusToBgColor[offerStatus]}
          tag={offerStatus}
          onClick={(e: any) => {
            if (offerStatus === 'BP') {
              const settingData = menuList
                .find((e: any) => e.moduleName === 'Billing')
                .screens.find((a: any) => a.screenName === 'Create Billing');
              if (!settingData) {
                toast.error(`You don't have the access`, alertOptions);
              } else if (userId === crmUserId) {
                handleClick(e);
              }
            }
          }}
          userId={userId}
          crmUserId={crmUserId}
        />
      </Typography>
    </div>
  );
};
