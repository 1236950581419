import React from 'react';

function CrossIcon() {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='none' viewBox='0 0 16 16' style={{cursor:'pointer'}}>
      <path
        fill='#EE4B22'
        d='M7.999 1.333a6.667 6.667 0 100 13.334 6.667 6.667 0 000-13.334zm2.14 7.86a.666.666 0 010 .947.666.666 0 01-.947 0l-1.193-1.2-1.194 1.2a.666.666 0 01-.946 0 .667.667 0 010-.947L7.059 8l-1.2-1.193a.67.67 0 11.946-.947L8 7.06l1.193-1.2a.67.67 0 01.947.947L8.939 8l1.2 1.193z'></path>
    </svg>
  );
}

export default CrossIcon;
