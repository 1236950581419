/* eslint-disable @typescript-eslint/no-unused-vars */
import Closepng from 'assets/png/close.svg';
import { Modal, Box, Typography } from '@mui/material';

import NCAdedIcon from 'assets/iconcomponents/noclientsadded';
import _ from 'lodash';
import Commoncontent from '../common/commoncontent';
const Modalposition = ({
  open,
  handleClose,
  clientData,
  Uid,
}: {
  Uid: string;
  open: boolean;
  handleClose: () => void;
  Approved?: boolean;
  clientData?: any;
}) => {
  //   const navigate = useNavigate();

  //   if (_.isEmpty(clientData)) {
  //     return (    <Modal open={open} onClose={handleClose}>
  //       <Box className='client-apruv-modal'>
  //       <header>
  //           <img src={Closepng} alt='close' className='closepng' onClick={handleClose} />
  //         </header>
  //         <div
  //                               style={{
  //                                 display: 'flex',
  //                                 flexDirection: 'column',
  //                                 justifyContent: 'center',
  //                                 alignItems: 'center',
  //                                 marginBottom: '43px',
  //                                 height:'90%',
  //                               }}>
  //                               <NCAdedIcon />
  //                               <Typography className='no-client'>Client Details Not Found</Typography>
  //                             </div>
  //         </Box></Modal>)
  //   };
  return (
    <Modal open={open} onClose={handleClose}>
      <Box className='client-apruv-modal position-only-modal'>
        <img
          src={Closepng}
          alt='close'
          className='closepng'
          onClick={handleClose}
          style={{ cursor: 'pointer' }}
        />
        <Commoncontent uids={Uid} propmode={true} />
      </Box>
    </Modal>
  );
};

export default Modalposition;
