/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useRef, useState } from 'react';
import { Box, Breadcrumbs, Button, Grid, Link, Typography } from '@mui/material';
import HomeIcon from 'assets/png/dashboardactive.svg';
import ArrowIcon from 'assets/png/breadarrow.svg';
import ResumePersonal from './ResumePersonal';
import { ResumeStepper } from './ResumeStepper';
import Pptlogo from 'assets/iconcomponents/ppticon';
import TableTooltip from 'components/Tooltip/Tabletooltip';
import Wordpng from 'assets/png/Word.png';
import Deletebucket from 'assets/png/Deletebucket.svg';
import Fileupload from 'assets/png/upload-cloud.svg';
import Crossicon from 'assets/png/Cross icon.svg';
import CustomSelect from 'components/CustomSelect/customSelect';
import Dropzone, { useDropzone } from 'react-dropzone';
import { addNewData, getAllListData, getSingleData } from 'services/CommonServices';
import { ClientsENDPOINT, FILE_ENDPOINT, PositionENDPOINT, ResumesENDPOINT } from 'utils/Constant';
import { useAppSelector } from 'store/hooks';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import { resumeUploadSchema } from 'utils/ValidatorSchema';
import Loader from 'components/loader';
import { toast } from 'react-toastify';
import { alertOptions } from 'utils/Config';
import ResumeEducation from './ResumeEducation';
import ResumeExperience from './ResumeExperience';
import ResumeConsultant from './ResumeConsultant';
import { useNavigate, useSearchParams } from 'react-router-dom';
import _ from 'lodash';
import { AccessDenied, fileTypeCheck } from 'utils/helper';
import { ResumePreviewDrawer } from './ResumePreviewDrawer';
import ResumeDuplication from './ResumeDuplication';
import { HttpStatusCode } from 'axios';
import 'react-circular-progressbar/dist/styles.css';
import { ProgressBar } from 'components/CustomProgress';
import { AdminPrivateRoutes } from 'routes/routeConstans';

const AddResume = () => {
  const navigate = useNavigate();
  const [searchParams]: any = useSearchParams();
  const {
    menuList,
    userInfo: { organizationId: orgdy, id: userId },
  }: any = useAppSelector((store: any) => store.userLogin);
  const [loader, setLoader] = useState<any>(false);
  const [fileUpload, setfileupload] = useState<any>();
  const [clientId, setClientId] = useState<any>();
  const [positionId, setPositionId] = useState<any>();
  const [ClientData, setClientData] = useState<any>([]);
  const [step, setStep] = useState<number>(0);
  const [resumeCompliation, setResumeCompliation] = useState<number>(0);
  const [resumeId, setResumeId] = useState<any>();
  const [resumeFileId, setResumeFileId] = useState<any>('');
  const [position, setPosition] = useState<any>();
  const [imageUrl, setImageUrl] = useState<any>('');
  const [fileRejectionItems, setfileRejectionItems] = useState<any>();
  const [personalFormEnable, setPersonalFormEnable] = useState<any>(false);
  const [positionData, setPositionData] = useState<any>([]);
  const [removeId, setRemoveId] = useState<any>([]);
  const hiddenFileInput: any = useRef(null);
  const maxSize = 2 * 1024 * 1024;

  const [previewDrawerOpen, setPreviewDrawerOpen] = useState(false);
  const togglePreviewDrawer = (open: boolean) => {
    setPreviewDrawerOpen(open);
  };

  const [open, setOpen] = useState(false);
  const [isIgnore, setIsIgnore] = useState(false);
  const [duplication, setDuplication] = useState<{
    data: any;
    message: string;
    duplicationStatus: string;
  }>({
    data: {},
    message: 'Oops! The resume is already uploaded by someone',
    duplicationStatus: '',
  });
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setDuplication({ data: {}, message: '', duplicationStatus: '' });
  };
  const duplicationFn = (res: {
    message: string;
    duplicationStatus: string;
    data: any;
    status: HttpStatusCode;
  }) => {
    setDuplication({
      data: res.data,
      message: res.message,
      duplicationStatus: res.duplicationStatus,
    });
    handleOpen();
  };

  const {
    control: control,
    handleSubmit: handleSubmit,
    // reset: resetForm,
    setValue,
    // setError,
    getValues,
    watch,
    formState: { errors },
  } = useForm<any>({
    criteriaMode: 'all',
    defaultValues: {
      positionId: '',
      clientId: '',
      id: '',
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(resumeUploadSchema),
  });
  const onSubmitForm = (data: any) => {
    if (!fileUpload) {
      toast.error('File is required', alertOptions);
    } else {
      setPersonalFormEnable(false);
      setLoader(true);
      const uploadResumeData = {
        organizationId: orgdy,
        clientId: data.clientId,
        positionId: data.positionId,
        status: true,
        isIgnore,
      };
      const formdata: any = new FormData();
      if (fileUpload[0]) {
        formdata.append('resume', fileUpload[0]);
      }

      formdata.append(
        'uploadResumeData',
        JSON.stringify(
          !searchParams.get('id') ? uploadResumeData : { ...uploadResumeData, id: data.id },
        ),
      );
      formdata.append('resumeTabType', 'uploadResume');
      if (removeId.length) {
        formdata.append('removeFileId', removeId);
      }
      formdata.append('resumeId', resumeId || searchParams.get('id') || data.id);

      toast.success('Resume parsing is in progress');
      
      addNewData(
        formdata,
        ResumesENDPOINT.resumeList,
        {
          'Content-Type': 'multipart/form-data',
        },
        true,
      )
        .then((res: any) => {
          setLoader(false);
          if (res?.status === 400) {
            duplicationFn(res);
          } else {
            setResumeId(res?.id);
            setResumeFileId(res?.fileId);
            setPersonalFormEnable(true);
            setStep(0);
            setRemoveId([]);
          }
        })
        .catch((e) => {
          setLoader(false);
          if (e === 'Forbidden resource') {
            toast.error(AccessDenied);
            navigate('/dashboard');
          }
        });
    }
  };
  const onDrop = useCallback((acceptedFiles: any) => {
    onFileChange(acceptedFiles);
    // eslint-disable-next-line prettier/prettier, react-hooks/exhaustive-deps
  }, []);
  const handledelete = () => {
    setRemoveId(resumeFileId ? resumeFileId : '');
    setfileupload('');
    // hiddenFileInput.current.value = '';
  };
  const resumeData = (data: any) => {
    setResumeCompliation(
      (data?.personal?.firstName ? 4.5 : 0) +
        (data?.personal?.lastName ? 4.5 : 0) +
        (data?.personal?.email ? 3.5 : 0) +
        (data?.personal?.phoneNumber ? 3.5 : 0) +
        (data?.personal?.dob ? 3.5 : 0) +
        (data?.personal?.designation ? 3.5 : 0) +
        (data?.personal?.expYears ? 3.5 : 0) +
        (data?.personal?.currentCtc ? 3.5 : 0) +
        (data?.personal?.expectedCtc ? 3.5 : 0) +
        (data?.personal?.noticePeriod ? 3.5 : 0) +
        (data?.personal?.gender ? 3.5 : 0) +
        (data?.personal?.maritalStatus ? 3.5 : 0) +
        (data?.personal?.family ? 3.5 : 0) +
        (data?.personal?.presentLocation ? 3.5 : 0) +
        (data?.education[0]?.qualificationDetailsId ? 3.5 : 0) +
        (data?.education[0]?.degreeDetailsId ? 3.5 : 0) +
        (data?.education[0]?.year ? 3.5 : 0) +
        (data?.education[0]?.specializationId ? 3.5 : 0) +
        (data?.education[0]?.college ? 3.5 : 0) +
        (data?.personal?.expYears === 0
          ? 31.5
          : (data?.experience[0]?.companyName ? 3.5 : 0) +
            (data?.experience[0]?.designation ? 3.5 : 0) +
            (data?.experience[0]?.fromDate ? 3.5 : 0) +
            (data?.experience[0]?.tillDate ? 3.5 : data?.experience[0]?.toDate ? 3.5 : 0) +
            (data?.experience[0]?.specialization ? 3.5 : 0) +
            (data?.experience[0]?.location ? 3.5 : 0) +
            (data?.experience[0]?.reportingTo ? 3.5 : 0)) +
        (data?.personal?.consultantAssessment ? 3.5 : 0) +
        (data?.personal?.interviewAvailability ? 3.5 : 0),
    );
  };
  const onFileChange = (file: FileList) => {
    if (file[0] && file[0].size) {
      if (fileTypeCheck.includes(file[0].type)) {
        let fileSize = 0;
        fileSize = Math.round(file[0].size / 1024);
        if (fileSize > 2048) {
          toast.error('File is too big, Please select a file less than 2MB', alertOptions);
          return;
        }
      } else {
        toast.error('You can upload documents of types  DOCX and DOC only', alertOptions);
        return;
      }
    }
    setfileupload(file);
    hiddenFileInput.current.value = '';
  };

  React.useEffect(() => {
    if (clientId || watch('clientId')) {
      getAllListData(
        `${PositionENDPOINT.position}?organizationId=${orgdy}&clientId=${clientId}&${
          menuList
            ?.find((e: any) => e.moduleName === 'My Plan')
            ?.screens?.find((a: any) => a.screenName === 'Create Plan')
            ? `taskUserId=${userId}`
            : `recruiterId=${userId}`
        }`,
      )
        .then((res: any) => {
          const options: any = [];
          res &&
            res.data.map((e: any) => {
              options.push({ label: e.title, value: e.id });
            });
          setPositionData(options);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, [clientId]);
  React.useEffect(() => {
    getAllListData(
      `${ClientsENDPOINT.Client}?organizationId=${orgdy}&${
        menuList
          ?.find((e: any) => e.moduleName === 'My Plan')
          ?.screens?.find((a: any) => a.screenName === 'Create Plan')
          ? `taskUserId=${userId}`
          : `recruiterId=${userId}`
      }&approvalStatus=approved`,
    )
      .then((res: any) => {
        const options: any = [];
        res &&
          res.data.map((e: any) => {
            options.push({ label: e.corporateName, value: e.id });
          });
        setClientData(options);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);
  React.useEffect(() => {
    if (resumeFileId) {
      getSingleData('', `${FILE_ENDPOINT.files}/resume/${resumeFileId}`, true)
        .then((fileRes: any) => {
          setImageUrl(fileRes);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, [resumeFileId]);
  const back = () => {
    navigate('/resumes');
    setRemoveId([]);
  };
  const getInitialStatus = () => {
    if (searchParams.get('id')) {
      setLoader(true);
      getSingleData(searchParams.get('id'), ResumesENDPOINT.resumeList)
        .then((resp: any) => {
          if (resp && !_.isEmpty(resp.data)) {
            setResumeFileId(resp?.data[0]?.fileId);
            setValue('clientId', resp?.data[0]?.clientId);
            setClientId(resp?.data[0]?.clientId);
            setValue('positionId', resp?.data[0]?.positionId);
            setfileupload(resp?.data[0]?.file);
            setValue('id', resp?.data[0]?.id);
            setPositionId(resp?.data[0]?.positionId);
            setPersonalFormEnable(true);
          }
          setLoader(false);
        })
        .catch((e: any) => {
          setLoader(false);
          if (e === 'Forbidden resource') {
            toast.error(AccessDenied);
            navigate('/dashboard');
          }
        });
    }
  };
  React.useEffect(() => {
    getInitialStatus();
  }, []);

  return (
    <React.Fragment>
      <Grid container>
        {loader && <Loader />}
        {/* Start of Breadcrumbs*/}
        <Grid item md={12} sm={12} xs={12}>
          <Typography component={'div'} className='breadcrumbs d-flex-a'>
            <Breadcrumbs sx={{ alignItems: 'center' }} aria-label='breadcrumb'>
              <Link underline='hover' color='inherit' href='/'>
                <img src={HomeIcon} className='home' alt='Home' />
              </Link>
              <Link underline='hover' color='inherit' href={AdminPrivateRoutes.RESUMES.RESUMESLIST}>
                <img src={ArrowIcon} alt='arrow' className='arrows' />
                Resumes
              </Link>
              <Typography color='text.primary'>
                {' '}
                <img src={ArrowIcon} alt='arrow' className='arrows' />
                {searchParams.get('id') ? 'Edit' : 'Add'} Resume
              </Typography>
            </Breadcrumbs>
          </Typography>
          <Typography component={'div'} className='d-flex-a flex-sb title-add-box'>
            <Typography className='page-title'>
              {searchParams.get('id') ? 'Edit' : 'Add'} Resume
            </Typography>
            {imageUrl && (
              <Typography
                onClick={() => togglePreviewDrawer(true)}
                component={'div'}
                sx={{
                  color: '#fff',
                  backgroundColor: '#EE4B22',
                  padding: '8px',
                  borderRadius: '4px',
                  cursor: 'pointer',
                }}>
                <Typography sx={{ fontSize: '13px' }}>Candidate Resume</Typography>
              </Typography>
            )}
          </Typography>
        </Grid>
        <Grid item md={12} sm={12} xs={12} sx={{ padding: '12px', display: 'flex' }}>
          <Grid item md={11} sm={12} xs={12}>
            <ResumeStepper currentStep={step} />
          </Grid>
          <Grid
            item
            md={1}
            sm={12}
            xs={12}
            sx={{
              backgroundColor: '#fff',
              borderRadius: '0px 12px 12px 0px',
            }}>
            <Box position='relative' display='flex' alignItems='center' justifyContent='center'>
              <ProgressBar
                percentage={resumeCompliation}
                startColor='#3B82F6'
                midColor='#A855F7'
                endColor='#EC4899'
                gradientId='progress'>
                <div>
                  <div
                    style={{
                      fontSize: '8px',
                      color: '#344051',
                      fontWeight: '200',
                      position: 'relative',
                      textAlign: 'center',
                      marginTop: '85px',
                    }}>
                    Resume
                  </div>
                  <div
                    style={{
                      fontSize: '8px',
                      color: '#344051',
                      fontWeight: '200',
                      position: 'relative',
                      textAlign: 'center',
                    }}>
                    Completion
                  </div>
                </div>
                <div
                  style={{
                    fontSize: '20px !important',
                    color: '#344051',
                    fontWeight: '600',
                    position: 'relative',
                    textAlign: 'center',
                    marginTop: '3px',
                  }}>
                  {resumeCompliation}%
                </div>
              </ProgressBar>
            </Box>
          </Grid>
        </Grid>
        {!personalFormEnable && (
          <Grid item md={12} sm={12} xs={12} sx={{ padding: '12px' }}>
            <Typography component={'div'} className='step-parent-box'>
              <Typography component={'div'} className='d-flex-a flex-sb'>
                <Typography className='stepper-title'>Upload Resume</Typography>
                <Typography className='d-flex-a'>
                  <Button className='cancel-btn mr-16' onClick={back}>
                    Cancel
                  </Button>
                  <Button className='s-add-btn' form={'uploadResume'} type={'submit'}>
                    Save
                  </Button>
                </Typography>
              </Typography>
              <Typography className='bdiveder' />
              <form
                className='private-form'
                id={'uploadResume'}
                onSubmit={handleSubmit(onSubmitForm)}>
                <Grid container columnSpacing={4.25}>
                  <Grid item md={6} sm={12} xs={12}>
                    <Typography component={'div'} className='custom-field'>
                      <Typography component={'p'}>
                        Client <span>*</span>
                      </Typography>
                      <Controller
                        control={control}
                        defaultValue={''}
                        name='clientId'
                        render={({ field: { onChange, value, name } }) => {
                          return (
                            <CustomSelect
                              labelKey={'label'}
                              valueKey={'value'}
                              options={ClientData}
                              name={name}
                              value={value}
                              onChange={(e) => {
                                setClientId(e.target.value);
                                setValue('positionId', '');
                                setPositionData([]);
                                onChange(e);
                              }}
                              placeHolder='Select'
                              error={errors.clientId?.message}
                            />
                          );
                        }}
                      />
                    </Typography>
                    <Typography component={'div'} className='custom-field'>
                      <Typography component={'p'}>
                        Position For <span>*</span>
                      </Typography>
                      <Controller
                        control={control}
                        defaultValue={''}
                        name='positionId'
                        render={({ field: { onChange, value, name } }) => {
                          return (
                            <CustomSelect
                              labelKey={'label'}
                              valueKey={'value'}
                              options={positionData}
                              name={name}
                              value={value ? value : null}
                              onChange={(e) => {
                                const dat = positionData.find(
                                  (re: any) => re.value === e.target.value,
                                );
                                setPositionId(e.target.value);
                                setPosition(dat.label);
                                onChange(e);
                              }}
                              placeHolder='Select'
                              error={errors.positionId?.message}
                            />
                          );
                        }}
                      />
                    </Typography>
                  </Grid>
                  <Grid item md={6} sm={12} xs={12}>
                    <Typography component={'div'} className='custom-field'>
                      <Typography component={'p'}>
                        Upload files <span>*</span>
                      </Typography>
                      <Dropzone onDrop={onDrop}>
                        {({ getRootProps, getInputProps }) => {
                          return (
                            <div
                              {...getRootProps({
                                className: 'dropzone container container-position pointer',
                                onClick: (event) => {
                                  hiddenFileInput.current?.click();
                                },
                              })}>
                              <div>
                                <input {...getInputProps()} />
                                <p className='img-container'>
                                  <img src={Fileupload} alt='file upload' />
                                </p>
                                <div className='fil-uplod-desc'>
                                  <p className='first-heading'>
                                    <span className='frst-span' role='button'>
                                      Click to upload
                                    </span>{' '}
                                    <span className='drago'>or drag and drop</span>
                                  </p>
                                  <p className='docx'>DOC, DOCX (up to 2MB)</p>
                                </div>
                              </div>
                            </div>
                          );
                        }}
                      </Dropzone>
                    </Typography>
                    <div>
                      {fileUpload && fileUpload.length > 0 ? (
                        <Grid item md={12} sm={12} xs={12} style={{ display: 'inherit' }}>
                          <Typography component={'div'} className='custom-field file-view-box'>
                            <Typography
                              component={'p'}
                              className='first-children first-green-children'>
                              <div className='image-container'>
                                <span className='image image-success'>
                                  <img src={Wordpng} alt='Wordpng' />
                                </span>
                                {/* <span className={success ? 'texted texted-success' : 'texted'}> */}
                                <span className={'texted texted-success'}>
                                  <TableTooltip
                                    value={
                                      fileUpload && fileUpload[0]
                                        ? fileUpload[0]?.name
                                        : fileUpload
                                          ? fileUpload.fileName
                                          : ''
                                    }
                                    positionfile={true}></TableTooltip>
                                </span>
                              </div>
                              <div
                                className='Crossicon Crossicon-deletebucket'
                                onClick={handledelete}>
                                <img src={Deletebucket} alt='Crossicon' />
                              </div>
                            </Typography>
                          </Typography>
                        </Grid>
                      ) : (
                        ''
                      )}
                    </div>
                  </Grid>
                </Grid>
              </form>
            </Typography>
          </Grid>
        )}
        {step === 0 ? (
          personalFormEnable && (
            <>
              <Grid item md={12} sm={12} xs={12} sx={{ padding: '12px' }}>
                <Typography component={'div'} className='step-parent-box'>
                  <Typography component={'div'} className='d-flex-a flex-sb'>
                    <Typography className='stepper-title'>
                      {' '}
                      {searchParams.get('id') ? 'Re-upload' : 'Upload'} Resume
                    </Typography>
                    <Typography className='d-flex-a'>
                      <Button className='cancel-btn mr-16' onClick={back}>
                        Cancel
                      </Button>
                      <Button className='s-add-btn' form={'uploadResume'} type={'submit'}>
                        Save
                      </Button>
                    </Typography>
                  </Typography>
                  <Typography className='bdiveder' />
                  <form
                    className='private-form'
                    id={'uploadResume'}
                    onSubmit={handleSubmit(onSubmitForm)}>
                    <Grid container columnSpacing={4.25}>
                      <Grid item md={6} sm={12} xs={12}>
                        <Typography component={'div'} className='custom-field'>
                          <Typography component={'p'}>
                            Client <span>*</span>
                          </Typography>
                          <Controller
                            control={control}
                            defaultValue={''}
                            name='clientId'
                            render={({ field: { onChange, value, name } }) => {
                              return (
                                <CustomSelect
                                  labelKey={'label'}
                                  valueKey={'value'}
                                  options={ClientData}
                                  name={name}
                                  value={value}
                                  onChange={(e) => {
                                    setClientId(e.target.value);
                                    onChange(e);
                                  }}
                                  placeHolder='Select'
                                  error={errors.clientId?.message}
                                />
                              );
                            }}
                          />
                        </Typography>
                        <Typography component={'div'} className='custom-field'>
                          <Typography component={'p'}>
                            Position For <span>*</span>
                          </Typography>
                          <Controller
                            control={control}
                            defaultValue={''}
                            name='positionId'
                            render={({ field: { onChange, value, name } }) => {
                              return (
                                <CustomSelect
                                  labelKey={'label'}
                                  valueKey={'value'}
                                  options={positionData}
                                  name={name}
                                  value={value}
                                  onChange={(e) => {
                                    onChange(e);
                                  }}
                                  placeHolder='Select'
                                  error={errors.positionId?.message}
                                />
                              );
                            }}
                          />
                        </Typography>
                      </Grid>
                      <Grid item md={6} sm={12} xs={12}>
                        <Typography component={'div'} className='custom-field'>
                          <Typography component={'p'}>
                            Upload files <span>*</span>
                          </Typography>
                          <Dropzone onDrop={onDrop}>
                            {({ getRootProps, getInputProps }) => {
                              return (
                                <div
                                  {...getRootProps({
                                    className: 'dropzone container container-position pointer',
                                    onClick: (event) => {
                                      hiddenFileInput.current?.click();
                                    },
                                  })}>
                                  <div>
                                    <input {...getInputProps()} />
                                    <p className='img-container'>
                                      <img src={Fileupload} alt='file upload' />
                                    </p>
                                    <div className='fil-uplod-desc'>
                                      <p className='first-heading'>
                                        <span className='frst-span' role='button'>
                                          Click to upload
                                        </span>{' '}
                                        <span className='drago'>or drag and drop</span>
                                      </p>
                                      <p className='docx'>DOC, DOCX (up to 2MB)</p>
                                    </div>
                                  </div>
                                </div>
                              );
                            }}
                          </Dropzone>
                        </Typography>
                        <div>
                          {fileUpload ? (
                            <Grid item md={12} sm={12} xs={12} style={{ display: 'inherit' }}>
                              <Typography component={'div'} className='custom-field file-view-box'>
                                <Typography className='first-children first-green-children'>
                                  <div className='image-container'>
                                    <span className='image image-success'>
                                      <img src={Wordpng} alt='Wordpng' />
                                    </span>
                                    {/* <span className={success ? 'texted texted-success' : 'texted'}> */}
                                    <span className={'texted'}>
                                      <TableTooltip
                                        value={
                                          fileUpload && fileUpload[0]
                                            ? fileUpload[0]?.name
                                            : fileUpload
                                              ? fileUpload.fileName
                                              : ''
                                        }
                                        positionfile={true}></TableTooltip>
                                    </span>
                                  </div>
                                  <div
                                    className='Crossicon Crossicon-deletebucket'
                                    onClick={handledelete}>
                                    <img src={Deletebucket} alt='Crossicon' />
                                  </div>
                                </Typography>
                              </Typography>
                            </Grid>
                          ) : (
                            ''
                          )}
                        </div>
                      </Grid>
                    </Grid>
                  </form>
                </Typography>
              </Grid>
              <Grid item md={12} sm={12} xs={12} sx={{ padding: '12px' }}>
                <ResumePersonal
                  positionData={positionData}
                  position={position}
                  positionId={positionId}
                  orgId={orgdy}
                  clientId={clientId}
                  resumeIds={resumeId}
                  step={step}
                  setStep={setStep}
                  loader={loader}
                  setLoader={setLoader}
                  resumeData={resumeData}
                />
              </Grid>
            </>
          )
        ) : step === 1 ? (
          <ResumeEducation
            positionId={positionId}
            orgId={orgdy}
            clientId={clientId}
            step={step}
            resumeIds={resumeId}
            setStep={setStep}
            loader={loader}
            setLoader={setLoader}
            resumeData={resumeData}
          />
        ) : step === 2 ? (
          <ResumeExperience
            positionId={positionId}
            orgId={orgdy}
            clientId={clientId}
            step={step}
            resumeIds={resumeId}
            setStep={setStep}
            loader={loader}
            setLoader={setLoader}
            resumeData={resumeData}
          />
        ) : (
          step === 3 && (
            <ResumeConsultant
              positionId={positionId}
              orgId={orgdy}
              clientId={clientId}
              step={step}
              resumeIds={resumeId}
              setStep={setStep}
              loader={loader}
              setLoader={setLoader}
              resumeData={resumeData}
            />
          )
        )}
        {/* {imageUrl && (
          <Grid item md={12} sm={12} xs={12}>
            <Typography component={'div'} className='step-parent-box'>
              <Typography component={'div'} className='d-flex-a flex-sb title-add-box'>
                <Typography className='page-title'>Candidate Resume</Typography>
              </Typography>
              <Typography className='bdiveder' />
              <iframe
                // frameBorder={'0'}
                style={{ height: '70vh', width: '100%' }}
                src={`data:application/pdf;base64,${imageUrl}`}
              />
            </Typography>
          </Grid>
        )} */}
        <ResumePreviewDrawer
          imageUrl={imageUrl}
          toggleDrawer={togglePreviewDrawer}
          drawerOpen={previewDrawerOpen}
        />
      </Grid>
      {!_.isEmpty(duplication?.data) && open && (
        <ResumeDuplication
          open={open}
          data={duplication?.data}
          message={duplication?.message}
          duplicationStatus={duplication?.duplicationStatus}
          handleClose={handleClose}
          setIsIgnore={setIsIgnore}
        />
      )}
    </React.Fragment>
  );
};
export default AddResume;
