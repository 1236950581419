// import React from 'react';
import _, { isEmpty } from 'lodash';
import PublicRoutes from 'routes/PublicRoutes';
import PrivateRoutes from 'routes/PrivateRoutes';
import AdminPrivateRoutes from 'routes/AdminRoutes';
import { useAppSelector } from 'store/hooks';

const AppRoutes = () => {
  const { userInfo: userDetail }: any = useAppSelector((store: any) => store.userLogin);
  if (!_.isEmpty(userDetail)) {
    return <AdminPrivateRoutes />;
  } else if (isEmpty(userDetail)) {
    return <PublicRoutes />;
  } else {
    return <PrivateRoutes />;
  }
};

export default AppRoutes;
