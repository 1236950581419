import { Drawer } from '@mui/material';
import React, { useCallback, useState } from 'react';
import CloseIcon from 'assets/png/close.svg';
import { useForm } from 'react-hook-form';
import { InterviewFilterType, MailBoxFilterType } from 'types';
import { yupResolver } from '@hookform/resolvers/yup';
import { MailBoxFilterSchema } from 'utils/ValidatorSchema';
import { MailboxFilterForm } from './MailboxFilterFrorm';

interface ResumeFilterDrawerProps {
  isOpen: boolean;
  closeDrawer: () => void;
  callback: (filters: InterviewFilterType) => void;
  setParams?: any;
  listdataapi?: any;
  setcommonstate?: any;
  setLoading?: any;
  setpagetotal?: any;
}

export const MailboxFilterDrawer: React.FC<ResumeFilterDrawerProps> = ({
  isOpen,
  closeDrawer,
  // callback,
  setParams,
  listdataapi,
  // setcommonstate,
  // setLoading,
  // setpagetotal,
}) => {
  const [key, setKey] = useState(0);
  const { control, handleSubmit, reset, formState, watch, trigger } = useForm<MailBoxFilterType>({
    criteriaMode: 'all',
    defaultValues: {
      fromDate: undefined,
      toDate: undefined,
      type: undefined,
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(MailBoxFilterSchema),
  });

  const closeSideDrawer = useCallback(() => {
    closeDrawer();
  }, [closeDrawer]);

  const resetFilters = useCallback(() => {
    reset();
    listdataapi();
    setKey((prev) => prev + 1);
  }, [reset]);

  const onSubmit = (data: any) => {
    if (data.fromDate || data.toDate || data.type) {
      setParams((prev: any) => ({
        ...prev,
        from: data.from ? data.from : null,
        to: data.to ? data.to : null,
        type: data.type ? data.type : null,
      }));
    }
  };
  return (
    <Drawer
      anchor='right'
      open={isOpen}
      onClose={closeDrawer}
      sx={{
        flexShrink: 0,
        zIndex: 1200,
        ['& .MuiDrawer-paper']: {
          boxSizing: 'border-box',
          marginTop: '72px',
          width: '300px',
          padding: '30px',
          paddingTop: '0px',
        },
        ['& .MuiModal-backdrop ']: { backgroundColor: 'transparent' },
      }}>
      <header className='clientfilter-header'>
        <img
          src={CloseIcon}
          alt='closeicon'
          className='clientfilter-close-ico'
          onClick={closeSideDrawer}
        />
        <div className='heading'>Filters</div>
        <span
          className='clearall'
          style={{
            ...(!(watch('fromDate') || watch('toDate') || watch('type'))
              ? { opacity: '0.5', cursor: 'not-allowed' }
              : {}),
          }}
          onClick={() =>
            watch('fromDate') || watch('toDate') || watch('type') ? resetFilters() : ''
          }>
          Clear All
        </span>
      </header>
      <div className='clientfilter-divider'></div>
      <MailboxFilterForm
        setParams={setParams}
        key={key}
        control={control}
        handleSubmit={handleSubmit}
        formState={formState}
        onSubmit={onSubmit}
        watch={watch}
        trigger={trigger}
      />
    </Drawer>
  );
};
