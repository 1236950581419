/* eslint-disable @typescript-eslint/no-unused-vars */
import { yupResolver } from '@hookform/resolvers/yup';
import { Grid, Typography, Breadcrumbs, Button, Link, Switch, TextField } from '@mui/material';
import CustomInput from 'components/CustomInput/customInput';
import CustomSelect from 'components/CustomSelect/customSelect';
import Loader from 'components/loader';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useParams, useNavigate } from 'react-router-dom';
import { AdminPrivateRoutes } from 'routes/routeConstans';
import { getAllListData, updateData, addNewData } from 'services/CommonServices';
import { useAppSelector } from 'store/hooks';
import { EventsAddSchema } from 'utils/ValidatorSchema';
import HomeIcon from 'assets/png/dashboardactive.svg';
import ArrowIcon from 'assets/png/breadarrow.svg';
import { EVENTS_ENDPOINT } from 'utils/Constant';
import moment from 'moment';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import { AccessDenied } from 'utils/helper';

const AddEvents = () => {
  const [fromMaxDate, setFromMaxDate] = useState<string | null>(null);
  const currentDate = moment().format('YYYY-MM-DDTHH:MM');
  const { editId } = useParams();
  const navigate = useNavigate();
  const Color: any = [
    {
      label: '#D50000',
      value: '#D50000',
    },
    {
      label: '#E67C73',
      value: '#E67C73',
    },
    {
      label: '#F4511E',
      value: '#F4511E',
    },
    {
      label: '#F6BF26',
      value: '#F6BF26',
    },
    {
      label: '#33B679',
      value: '#33B679',
    },
    {
      label: '#0B8043',
      value: '#0B8043',
    },
    {
      label: '#039BE5',
      value: '#039BE5',
    },
    {
      label: '#00E096',
      value: '#00E096',
    },
    {
      label: '#7986CB',
      value: '#7986CB',
    },
    {
      label: '#3F51B5',
      value: '#3F51B5',
    },
    {
      label: '#616161',
      value: '#616161',
    },
    {
      label: '#8E24AA',
      value: '#8E24AA',
    },
  ];
  const [loader, setLoader] = useState<any>(false);
  const {
    userInfo: { organizationId: orgdy, id: userId },
  }: any = useAppSelector((store: any) => store.userLogin);
  const {
    control: control,
    handleSubmit: handleSubmit,
    reset: resetForm,
    setValue,
    formState: { errors },
  } = useForm<any>({
    criteriaMode: 'all',
    defaultValues: {
      eventTitle: '',
      location: '',
      eventStartDate: null,
      eventEndDate: null,
      description: '',
      notification: false,
      eventColor: '#D50000',
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(EventsAddSchema),
  });

  const getInitialStatus = () => {
    if (editId) {
      getAllListData(`${EVENTS_ENDPOINT.event}?id=${editId}`, true)
        .then((resp: any) => {
          if (resp && !_.isEmpty(resp.data)) {
            setValue('eventTitle', resp.data[0].title);
            setValue('location', resp.data[0].location);
            setValue('eventStartDate', dayjs(resp.data[0].startsAt));
            setValue('eventEndDate', dayjs(resp.data[0].endsAt));
            setValue('notification', resp.data[0].notifications);
            setValue('description', resp.data[0].description);
            setValue('eventColor', resp.data[0].colour);
          }
          setLoader(false);
        })
        .catch((err) => {
          setLoader(false);
          if (err === 'Forbidden resource') {
            toast.error(AccessDenied);
            navigate('/dashboard');
          }
        });
    }
  };

  const onSubmitForm = (data: any) => {
    setLoader(true);
    if (!_.isUndefined(editId)) {
      updateData(
        editId,
        {
          organizationId: orgdy,
          userId: userId,
          startsAt: new Date(data.eventStartDate),
          endsAt: new Date(data.eventEndDate),
          title: data.eventTitle,
          description: data.description,
          colour: data.eventColor,
          location: data.location,
          status: true,
          notifications: data.notification,
        },
        `${String(EVENTS_ENDPOINT.event)}`,
        true,
      )
        .then(() => {
          resetForm();
          navigate(AdminPrivateRoutes.MyEvents.EventsView);
          setLoader(false);
        })
        .catch((err) => {
          setLoader(false);
          if (err === 'Forbidden resource') {
            toast.error(AccessDenied);
            navigate('/dashboard');
          }
        });
    } else {
      addNewData(
        {
          organizationId: orgdy,
          userId: userId,
          startsAt: new Date(data.eventStartDate),
          endsAt: new Date(data.eventEndDate),
          title: data.eventTitle,
          description: data.description,
          colour: data.eventColor,
          location: data.location,
          status: true,
          notifications: data.notification,
        },
        `${String(EVENTS_ENDPOINT.event)}`,
        undefined,
        true,
      )
        .then(() => {
          resetForm();
          navigate(AdminPrivateRoutes.MyEvents.EventsView);
          setLoader(false);
        })
        .catch((err) => {
          setLoader(false);
          if (err === 'Forbidden resource') {
            toast.error(AccessDenied);
            navigate('/dashboard');
          }
        });
    }
  };

  useEffect(() => {
    getInitialStatus();
  }, []);

  return (
    <>
      <Grid container>
        {loader && <Loader />}
        <Grid item md={12} sm={12} xs={12}>
          <Typography component={'div'} className='breadcrumbs d-flex-a'>
            <Breadcrumbs aria-label='breadcrumb'>
              <Link underline='hover' color='inherit' href='/'>
                <img src={HomeIcon} className='home' alt='Home' />
              </Link>
              <Link underline='hover' color='inherit' href={AdminPrivateRoutes.MyEvents.EventsList}>
                <img src={ArrowIcon} alt='arrow' className='arrows' />
                Events
              </Link>
              <Typography color='text.primary'>
                {' '}
                <img src={ArrowIcon} alt='arrow' className='arrows' />
                {editId ? 'Edit' : 'Add'} Event
              </Typography>
            </Breadcrumbs>
          </Typography>
          <Typography component={'div'} className='d-flex-a flex-sb title-add-box'>
            <Typography className='page-title'>{editId ? 'Edit' : 'Add'} Event</Typography>
            <Typography component={'div'} className='d-flex-a'>
              <Button
                className='cancel-btn mr-16'
                onClick={() =>
                  navigate(
                    editId
                      ? AdminPrivateRoutes.MyEvents.EventsView
                      : AdminPrivateRoutes.MyEvents.EventsList,
                  )
                }>
                Cancel
              </Button>
              <Button form={'branch-add'} className='s-add-btn' type='submit'>
                Save
              </Button>
            </Typography>
          </Typography>
          <Typography component={'div'} className='main-content'>
            <Typography component={'div'} className='white-box'>
              <form
                className='private-form'
                id={'branch-add'}
                onSubmit={handleSubmit(onSubmitForm)}>
                <Grid container>
                  <Grid item md={8} sm={12} xs={12}>
                    <Grid container columnSpacing={4.25}>
                      <Grid item md={6} sm={12} xs={12}>
                        <Typography component={'div'} className='custom-field'>
                          <Typography component={'p'}>
                            Event Title<span>*</span>
                          </Typography>
                          <Controller
                            control={control}
                            defaultValue={null}
                            name='eventTitle'
                            render={({ field: { onChange, value, name } }) => {
                              return (
                                <CustomInput
                                  name={name}
                                  value={value}
                                  className='custom-input'
                                  onChange={(e) => {
                                    onChange(e);
                                  }}
                                  placeHolder='Event Title'
                                  error={errors.eventTitle?.message}
                                />
                              );
                            }}
                          />
                        </Typography>
                      </Grid>
                      <Grid item md={6} sm={12} xs={12}>
                        <Typography component={'div'} className='custom-field'>
                          <Typography component={'p'}>
                            Location<span>*</span>
                          </Typography>
                          <Controller
                            control={control}
                            defaultValue={null}
                            name='location'
                            render={({ field: { onChange, value, name } }) => {
                              return (
                                <CustomInput
                                  value={value}
                                  name={name}
                                  className='custom-input'
                                  placeHolder='Location'
                                  error={errors.location?.message}
                                  onChange={(e) => {
                                    onChange(e);
                                  }}
                                />
                              );
                            }}
                          />
                        </Typography>
                      </Grid>
                      <Grid item md={6} sm={12} xs={12}>
                        <Typography component={'div'} className='custom-field'>
                          {/* <Typography component={'p'}>
                            Start Date & Time<span>*</span>
                          </Typography> */}
                          <Controller
                            control={control}
                            defaultValue={''}
                            name='eventStartDate'
                            render={({ field: { onChange, value, name } }) => {
                              return (
                                <>
                                  {/* <TextField
                                    value={value}
                                    name={name}
                                    type='date'
                                    InputProps={{
                                      inputProps: {
                                        min: currentDate,
                                      },
                                    }}
                                    className={`clientfilter-time-from-to ${
                                      errors.eventStartDate && errors.eventStartDate?.message
                                        ? 'clientfilter-time-from-to-error'
                                        : ''
                                    }`}
                                    placeholder='Start Date & Time'
                                    onChange={(e) => {
                                      onChange(e);
                                      setFromMaxDate(
                                        moment(e.target.value).format('YYYY-MM-DDTHH:MM'),
                                      );
                                    }}
                                  /> */}
                                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DemoContainer components={['DateTimePicker']}>
                                      <Typography component={'p'}>
                                        Start Date & Time<span>*</span>
                                      </Typography>
                                      <DateTimePicker
                                        views={['day', 'month', 'year', 'hours', 'minutes']}
                                        name={name}
                                        value={value}
                                        sx={{
                                          overflow: 'hidden !important',
                                          minWidth: 'unset !important',
                                          marginTop: 'unset !important',
                                        }}
                                        disablePast
                                        className={`clientfilter-time-from-to ${
                                          errors?.eventStartDate && errors?.eventStartDate?.message
                                            ? 'clientfilter-time-from-to-error'
                                            : ''
                                        }`}
                                        onChange={(e: any) => {
                                          onChange(e);
                                          setFromMaxDate(moment(e).format('YYYY-MM-DDTHH:mm'));
                                        }}
                                      />
                                    </DemoContainer>
                                  </LocalizationProvider>
                                  {errors &&
                                    errors.eventStartDate &&
                                    errors.eventStartDate?.message && (
                                      <div className='Custom-error '>
                                        {String(errors.eventStartDate?.message)}
                                      </div>
                                    )}
                                </>
                              );
                            }}
                          />
                        </Typography>
                      </Grid>
                      <Grid item md={6} sm={12} xs={12}>
                        <Typography component={'div'} className='custom-field'>
                          {/* <Typography component={'p'}>
                            End Date & Time<span>*</span>
                          </Typography> */}
                          <Controller
                            control={control}
                            defaultValue={''}
                            name='eventEndDate'
                            render={({ field: { onChange, value, name } }) => {
                              return (
                                <>
                                  {/* <TextField
                                    value={value}
                                    name={name}
                                    InputProps={{
                                      inputProps: {
                                        min: fromMaxDate
                                          ? moment(fromMaxDate).format('YYYY-MM-DDTHH:MM')
                                          : currentDate,
                                      },
                                    }}
                                    className={`clientfilter-time-from-to ${
                                      errors.eventEndDate && errors.eventEndDate?.message
                                        ? 'clientfilter-time-from-to-error'
                                        : ''
                                    }`}
                                    placeholder='End Date & Time'
                                    // error={errors.eventEndDate?.message}
                                    onChange={(e) => {
                                      onChange(e);
                                    }}
                                  /> */}
                                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DemoContainer components={['DateTimePicker']}>
                                      <Typography component={'p'}>
                                        End Date & Time<span>*</span>
                                      </Typography>
                                      <DateTimePicker
                                        views={['day', 'month', 'year', 'hours', 'minutes']}
                                        name={name}
                                        value={value}
                                        sx={{
                                          overflow: 'hidden !important',
                                          minWidth: 'unset !important',
                                          marginTop: 'unset !important',
                                        }}
                                        disablePast
                                        className={`clientfilter-time-from-to ${
                                          errors?.eventStartDate && errors?.eventStartDate?.message
                                            ? 'clientfilter-time-from-to-error'
                                            : ''
                                        }`}
                                        onChange={(e: any) => {
                                          onChange(e);
                                        }}
                                      />
                                    </DemoContainer>
                                  </LocalizationProvider>
                                  {errors &&
                                    errors.eventStartDate &&
                                    errors.eventStartDate?.message && (
                                      <div className='Custom-error '>
                                        {String(errors.eventStartDate?.message)}
                                      </div>
                                    )}
                                </>
                              );
                            }}
                          />
                        </Typography>
                      </Grid>
                      <Grid item md={12} sm={12} xs={12} sx={{ paddingBottom: '12px' }}>
                        <Typography component={'div'} className='custom-field'>
                          <Typography component={'p'}>Description</Typography>
                          <Controller
                            control={control}
                            defaultValue={null}
                            name='description'
                            render={({ field: { onChange, value, name } }) => {
                              return (
                                <CustomInput
                                  value={value}
                                  name={name}
                                  isTextArea={true}
                                  className='custom-input custom-input-position'
                                  placeHolder='Type your Description..'
                                  error={errors.description?.message}
                                  onChange={(e) => {
                                    onChange(e);
                                  }}
                                />
                              );
                            }}
                          />
                        </Typography>
                      </Grid>
                      <Grid item md={5} sm={12} xs={12} sx={{ display: 'flex' }}>
                        <Typography component={'div'} className='custom-field'>
                          <Typography component={'p'}>Event Color</Typography>
                          <Controller
                            control={control}
                            defaultValue={null}
                            name='eventColor'
                            render={({ field: { onChange, value, name } }) => {
                              return (
                                <CustomSelect
                                  labelKey={'label'}
                                  valueKey={'value'}
                                  isClearable={false}
                                  options={Color}
                                  searchable={false}
                                  color={true}
                                  placeHolder=''
                                  value={value}
                                  name={name}
                                  onChange={(e) => {
                                    onChange(e);
                                  }}
                                  error={errors?.eventColor?.message}
                                />
                              );
                            }}
                          />
                        </Typography>
                        <Typography component={'div'} className='custom-field ml-40'>
                          <Typography component={'p'}>Notification</Typography>
                          <Controller
                            control={control}
                            defaultValue={null}
                            name='notification'
                            render={({ field: { onChange, value, name } }) => {
                              return (
                                <Switch
                                  value={value}
                                  name={name}
                                  checked={value}
                                  // defaultValue={userData.status}
                                  onChange={(e) => {
                                    onChange(e);
                                  }}
                                  className='custom-switch'
                                />
                              );
                            }}
                          />
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            </Typography>
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};
export default AddEvents;
