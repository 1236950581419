import * as React from 'react';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel, { stepLabelClasses } from '@mui/material/StepLabel';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { StepIconProps } from '@mui/material/StepIcon';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';

const ColorlibConnector = styled(StepConnector)(() => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: '#EE4B22',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 2.2,
    border: 0,
    backgroundColor: '#A1AEBE',
    borderRadius: 1,
    width: '80%',
  },
}));

const StLabel = styled(StepLabel)(() => ({
  [`.${stepLabelClasses.label}, &.${stepLabelClasses.disabled}`]: {
    fontWeight: 600,
    color: '#000',
    fontSize: '13px',
    [`&.${stepLabelClasses.active}`]: {
      fontWeight: 600,
    },
    [`&.${stepLabelClasses.completed}`]: {
      fontWeight: 600,
      color: '#EE4B22',
    },
  },
}));

const ColorlibStepIconRoot = styled('div')<{
  ownerState: { completed?: boolean; active?: boolean };
}>(({ ownerState }) => ({
  zIndex: 1,
  width: 32,
  height: 32,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  fontWeight: 600,
  color: '#242E39',
  border: '2px solid #A1AEBE',
  ...(ownerState.active && {
    fontWeight: 600,
    borderRadius: '50%',
    border: '2px solid #A1AEBE',
  }),
  ...(ownerState.completed && {
    backgroundColor: '#EE4B22',
    border: 'unset',
  }),
}));

const ColorlibStepIcon = (props: StepIconProps) => {
  const { active, completed, className } = props;

  const icons: { [index: string]: string } = {
    1: '01',
    2: '02',
    3: '03',
    4: '04',
  };

  return (
    <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
      {completed ? <CheckRoundedIcon sx={{ color: '#fff' }} /> : icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
};

const steps = ['Personal', 'Education', 'Experience', 'Consultant Assessment'];

export interface ResumeStepperProps {
  currentStep: number;
}

export const ResumeStepper: React.FC<ResumeStepperProps> = ({ currentStep }) => {
  return (
    <Stack
      sx={{
        width: '100%',
        backgroundColor: '#fff',
        paddingTop: '32px',
        paddingBottom: '24px',
        borderRadius: '12px 0px 0px 12px',
      }}
      spacing={4}>
      <Stepper alternativeLabel activeStep={currentStep} connector={<ColorlibConnector />}>
        {steps.map((label) => (
          <Step key={label}>
            <StLabel StepIconComponent={ColorlibStepIcon}>{label}</StLabel>
          </Step>
        ))}
      </Stepper>
    </Stack>
  );
};
