/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/prop-types */
import { yupResolver } from '@hookform/resolvers/yup';
import { Modal, Box, Typography, Grid, Button } from '@mui/material';
import CustomInput from 'components/CustomInput/customInput';
import CustomSelect from 'components/CustomSelect/customSelect';
import moment from 'moment';
import { useState, useEffect } from 'react';
import { useForm, Controller, useFormContext } from 'react-hook-form';
import { getAllListData, updateData } from 'services/CommonServices';
import { ChangeCRM } from 'types';
import { ClientsENDPOINT, PositionENDPOINT } from 'utils/Constant';
import { ChangeCRMSchema } from 'utils/ValidatorSchema';
import Closepng from 'assets/png/close.svg';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AccessDenied } from 'utils/helper';

const CrmChange: React.FC<{
  clientId: string;
  currentCRM: any;
  setLD: (val: any) => void;
  openCRMchange: any;
  setOpenCRMchange: (val: any) => void;
  alldropdownapicalls: () => void;
}> = ({ clientId, currentCRM, setLD, setOpenCRMchange, openCRMchange, alldropdownapicalls }) => {
  const [searchParams]: any = useSearchParams();
  const currentDate = moment().format('YYYY-MM-DD');
  const [CRMDAta, setCRMData] = useState<any>([]);
  const navigate = useNavigate();
  const handleClose = () => {
    setOpenCRMchange(false);
  };
  const {
    handleSubmit: handleSubmitCRM,
    control: controlCRM,
    formState: { errors: errorsCRM },
    // getValues,
    watch: watchCRM,
    setValue: setValueCRM,
    // reset,
    // trigger,
  } = useForm<ChangeCRM>({
    defaultValues: {
      crmFrom: '',
      crmTo: '',
      newCRM: '',
      recruiter: '',
      remarks: '',
    },
    reValidateMode: 'onChange',
    resolver: yupResolver(ChangeCRMSchema),
  });
  const onSubmitCRM = (data: ChangeCRM) => {
    setLD(true);
    const obj: any = {
      crmId: data.newCRM,
      remarks: data.remarks,
    };
    // console.log(obj, 'dsd');

    updateData(searchParams.get('positionid'), obj, PositionENDPOINT.CRMChange, true)
      .then((res: any) => {
        setLD(false);
        handleClose();
        alldropdownapicalls();
      })
      .catch((e) => {
        setLD(false);
        if (e === 'Forbidden resource') {
          toast.error(AccessDenied);
          navigate('/dashboard');
        }
        console.log(e);
      });
  };
  useEffect(() => {
    if (clientId) {
      getAllListData(
        `${ClientsENDPOINT.Client}?approvalStatus=approved&status=true&id=${clientId}`,
        true,
      )
        .then((res: any) => {
          const options: any = [];
          res &&
            res?.data &&
            res?.data[0]?.clientsCrm
              ?.filter((es: any) => es?.id !== currentCRM?.crmId)
              ?.map((e: any) => {
                options.push({
                  label: ` ${e?.user?.firstName} ${e?.user?.lastName ? e?.user?.lastName : ''}`,
                  value: e?.id,
                });
              });
          setCRMData(options);
        })
        .then((e: any) => {
          console.log(e);
        });
    }
  }, [clientId]);
  useEffect(() => {
    if (clientId) {
      setValueCRM('crmFrom', currentCRM.from);
      setValueCRM('crmTo', currentDate);
      setValueCRM('recruiter', currentDate);
    }
  }, [clientId]);

  return (
    <Modal open={openCRMchange} onClose={handleClose}>
      <Box className='logout-modal' sx={{ paddingBottom: '40px' }}>
        <Typography className='text-right'>
          <img src={Closepng} alt='close' onClick={handleClose} />
        </Typography>
        <Typography className='l-title'>CRM Change Approval</Typography>
        <form
          id='crm-change'
          className='private-form'
          style={{ padding: '0px 50px 0px 50px' }}
          onSubmit={handleSubmitCRM(onSubmitCRM)}>
          <Grid container columnSpacing={2} sx={{ marginTop: '30px' }}>
            <Grid item md={12} sm={12} xs={12}>
              <Typography component={'div'} className='custom-field'>
                <Typography component={'p'} sx={{ textTransform: 'capitalize' }}>
                  {currentCRM?.crm?.user?.firstName}{' '}
                  {currentCRM?.crm?.user?.lastName ? currentCRM?.crm?.user?.lastName : ''}: (From -
                  To) (Existing CRM)
                </Typography>
              </Typography>
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <Typography component={'div'} className='custom-field'>
                <Controller
                  control={controlCRM}
                  name='crmFrom'
                  render={({ field: { onChange, value, name } }) => {
                    return (
                      <CustomInput
                        value={value}
                        name={name}
                        disabled
                        className='custom-input'
                        placeHolder=''
                        error={errorsCRM.crmFrom?.message}
                        onChange={(e) => {
                          onChange(e);
                        }}
                      />
                    );
                  }}
                />
              </Typography>
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <Typography component={'div'} className='custom-field'>
                <Controller
                  control={controlCRM}
                  name='crmTo'
                  render={({ field: { onChange, value, name } }) => {
                    return (
                      <CustomInput
                        value={value}
                        name={name}
                        disabled
                        className='custom-input'
                        placeHolder=''
                        error={errorsCRM.crmTo?.message}
                        onChange={(e) => {
                          onChange(e);
                        }}
                      />
                    );
                  }}
                />
              </Typography>
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <Typography component={'div'} className='custom-field'>
                <Typography component={'p'}>New CRM</Typography>
                <Controller
                  control={controlCRM}
                  defaultValue={''}
                  name='newCRM'
                  render={({ field: { onChange, value, name } }) => {
                    return (
                      <CustomSelect
                        labelKey={'label'}
                        valueKey={'value'}
                        options={CRMDAta}
                        name={name}
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                        }}
                        placeHolder='Select'
                        error={errorsCRM.newCRM?.message}
                      />
                    );
                  }}
                />
              </Typography>
            </Grid>
            {watchCRM('newCRM') && (
              <Grid item md={12} sm={12} xs={12}>
                <Typography component={'div'} className='custom-field'>
                  <Typography component={'p'} sx={{ textTransform: 'capitalize' }}>
                    {CRMDAta.find((es: any) => es.value === watchCRM('newCRM'))?.label}: (From)
                  </Typography>
                  <Controller
                    control={controlCRM}
                    name='recruiter'
                    render={({ field: { onChange, value, name } }) => {
                      return (
                        <CustomInput
                          value={value}
                          name={name}
                          disabled
                          className='custom-input'
                          placeHolder=''
                          error={errorsCRM.recruiter?.message}
                          onChange={(e) => {
                            onChange(e);
                          }}
                        />
                      );
                    }}
                  />
                </Typography>
              </Grid>
            )}
            <Grid item md={12} sm={12} xs={12}>
              <Typography component={'div'} className='custom-field'>
                <Typography component={'p'}>Remarks</Typography>
                <Controller
                  name='remarks'
                  control={controlCRM}
                  render={({ field: { onChange, value, name } }) => (
                    <CustomInput
                      value={value ? value : ''}
                      name={name}
                      isTextArea={true}
                      className='custom-input'
                      placeHolder='Remarks'
                      error={errorsCRM.remarks?.message}
                      onChange={(e) => {
                        onChange(e);
                      }}
                    />
                  )}
                />
              </Typography>
            </Grid>
          </Grid>
        </form>
        <Typography className='d-flex-ja mt-10'>
          <Button className='l-cancel-btn mr-16' onClick={handleClose}>
            Cancel
          </Button>
          <Button className='l-save-btn' form={'crm-change'} type={'submit'}>
            Save
          </Button>
        </Typography>
      </Box>
    </Modal>
  );
};
export default CrmChange;
