// import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { PrivateRoutes as PrivateRoutesDirectives } from './routeConstans';
// import PrivateLayout from 'pages/private';

const PrivateRoutes = () => {
  const { DASHBOARD, } = PrivateRoutesDirectives;
  return (
    <Routes>
      <Route path='/' element={<Navigate to={DASHBOARD} replace />} />
      <Route path='*' element={<Navigate to='/' />} />
    </Routes>
  );
};

export default PrivateRoutes;
