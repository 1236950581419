/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  InputAdornment,
  Modal,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import AddIcon from 'assets/iconcomponents/addicon';
import { useEffect, useRef, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import CustomInput from 'components/CustomInput/customInput';
import CustomPhoneInput from 'components/CustomPhoneInput/customPhoneInput';
import CustomSelect from 'components/CustomSelect/customSelect';
import Loader from 'components/loader';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import EmailIcon from 'assets/png/email.svg';
import { SPOCType1 } from 'types';
import { SPOCSchema } from 'utils/ValidatorSchema';
import Deleteimgicon from 'assets/png/Deleteblack.svg';
import _ from 'lodash';
import { addNewData, getAllListData, getSingleData } from 'services/CommonServices';
import { ClientsENDPOINT, SETTING_ENDPOINT } from 'utils/Constant';
import { toast } from 'react-toastify';
import { Country, State, City } from 'country-state-city';

const CheckIcon = styled('span')(({ theme }) => ({
  borderRadius: 4,
  width: 16,
  height: 16,
  outline: '1px solid #EE4B22',
  backgroundColor: theme.palette.mode === 'dark' ? '#fff' : '#fff',
  '.Mui-focusVisible &': {
    outline: '1px auto #EE4B22',
    outlineOffset: 1,
  },
  'input:hover ~ &': {
    backgroundColor: theme.palette.mode === 'dark' ? '#ffffff' : '#ffffff',
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background: theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
  },
}));

const CheckedIcon = styled(CheckIcon)({
  backgroundColor: '#EE4B22',
  '&:before': {
    display: 'block',
    width: 16,
    height: 16,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: '#EE4B22',
  },
});

const SPOCDETAIL = ({ setformview, formview, setFormfinish }: any) => {
  const [Ld, setLd] = useState(false);
  const navigate = useNavigate();
  const [country, setCountry] = useState([]);
  const [searchParams]: any = useSearchParams();
  const [sameAsCorporateAddress, setSameAsCorporateAddress] = useState<any>();
  const [designationlist, setdesignationlist] = useState<any>([]);
  const [removespocids, setRemovespocids] = useState<any>([]);
  const [branchlist, setbranchlist] = useState<any>([]);
  const {
    control: control,
    handleSubmit: handleSubmit,
    reset: resetForm,
    setValue,
    // trigger,
    // setError,
    watch,
    // unregister,
    getValues,
    formState: { errors },
  } = useForm<SPOCType1>({
    criteriaMode: 'all',
    defaultValues: {
      clientSPOC: [
        {
          title: '',
          firstName: '',
          lastName: '',
          designationId: '',
          email: '',
          phoneNumber: '',
          mobileno: '',
          status: !false,
          branchId: '',
          address1: '',
          address2: '',
          country: '',
          pincode: '',
          city: '',
          sameAsCorporateAddress: false,
          state: '',
          countryCode: '',
          rowId: '',
          // countryCodeText:'',
        },
      ],
      editReason: 'no reason',
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(SPOCSchema),
  });

  const {
    fields,
    append,
    // replace: replaceform,
    // update,
    remove,
    // swap,
  } = useFieldArray({
    control,
    name: 'clientSPOC',
  });

  function onSubmitForm(data: any) {
    const final = data?.clientSPOC?.map((re: any) => {
      if (re?.rowId) {
        re['id'] = re.rowId;
        delete re.rowId;
      }
      return re;
    });
    const formdata: any = new FormData();
    formdata.append('clientTab', 'spocDetails');
    formdata.append('clientId', searchParams.get('clientid') || formview.clientId);
    formdata.append('clientSPOC', JSON.stringify(final));
    if (removespocids.length) {
      formdata.append('removeClientSpocIds', JSON.stringify(removespocids));
    }

    // formdata.append('editReason', '');
    setLd(true);
    addNewData(
      formdata,
      `${ClientsENDPOINT.Client}`,
      {
        'Content-Type': 'multipart/form-data',
      },
      true,
    )
      .then((res) => {
        setformview((prev: any) => ({
          ...prev,
          crm: true,
          spoc: false,
          clientdetail: false,
          termsCondition: false,
          businessDetail: false,
          clientId: res && res?.id,
        }));
        setFormfinish((prev: any) => ({
          ...prev,
          crm: false,
          spoc: true,
          clientdetail: true,
          termsCondition: false,
          businessDetail: false,
        }));
        setLd(false);
      })
      .catch((e) => {
        if (e === 'Forbidden resource') {
          toast.error(e);
          navigate('/dashboard');
        }
        setLd(false);
        console.log(e);
      });
  }
  const removeSpoc = (index: number, id: string) => {
    if (id) {
      removespocids.push(id);
      setRemovespocids([...removespocids]);
    }
    remove(index);
  };
  useEffect(() => {
    // dispatch(storespoc(watch));
    getAllListData(
      `${SETTING_ENDPOINT.Branch}?sortby=name&order=asc&branchType=client&status=true`,
      true,
    )
      .then((res: any) => {
        if (res && res.data && res.data.length > 0) {
          // // console.(res.data,'branch');
          //   setlocalcrm(res.data);
          setbranchlist(
            res.data.map((li1: any) =>
              li1 && !_.isEmpty(li1) && li1.id
                ? { value: li1.id, label: li1.name ? String(`${li1.name}`) : '' }
                : '',
            ),
          );
        }
      })
      .catch((e) => {
        // console.(e);
      });

    getAllListData(
      `${SETTING_ENDPOINT.designation}?sortby=name&order=asc&designationType=client&status=true`,
      true,
    )
      .then((res: any) => {
        if (res && res.data && res.data.length > 0) {
          // console.(res.data, 'branch');
          //   setlocalcrm(res.data);
          setdesignationlist(
            res.data.map((li1: any) =>
              li1 && !_.isEmpty(li1) && li1.id
                ? { value: li1.id, label: li1.name ? String(`${li1.name}`) : '' }
                : '',
            ),
          );
        }
      })
      .catch((e) => {
        // console.(e);
      });
  }, []);
  useEffect(() => {
    getInitialStatus();
  }, []);
  const getInitialStatus = async () => {
    if (searchParams.get('clientid') || formview.clientId) {
      setLd(true);
      await getSingleData(searchParams.get('clientid') || formview.clientId, ClientsENDPOINT.Client)
        .then((resp: any) => {
          setSameAsCorporateAddress(resp && resp.data && resp.data[0]);
          const SpocData =
            resp?.data &&
            resp?.data[0]?.clientsSpoc &&
            resp?.data[0]?.clientsSpoc.length &&
            resp?.data[0]?.clientsSpoc;
          const Day: any = [];
          setFormfinish((prev: any) => ({
            ...prev,
            crm: resp?.data[0]?.clientsCrm.length ? true : false,
            spoc: resp?.data[0]?.clientsSpoc.length ? true : false,
            clientdetail: resp?.data[0]?.corporateName ? true : false,
            termsCondition: resp?.data[0]?.clientsAgreement.length ? true : false,
            businessDetail: resp?.data[0]?.clientsBusinessInfo.length ? true : false,
          }));
          SpocData?.map((re: any, ind: number) => {
            Day.push({
              title: re.title,
              firstName: re.firstName,
              lastName: re.lastName,
              designationId: re.designation.id,
              email: re.email,
              phoneNumber: re.phoneNumber,
              mobileno: undefined,
              branchId: re.branch.id,
              address1: re.address1,
              address2: re.address2,
              status: true,
              country: re.country,
              pincode: re.pincode,
              city: re.city,
              sameAsCorporateAddress: re.sameAsCorporateAddress,
              state: re.state,
              countryCode: re.countryCode,
              rowId: re.id,
            });
          });
          setValue('clientSPOC', Day);
          setLd(false);
        })
        .catch((e: any) => {
          if (e === 'Forbidden resource') {
            toast.error(e);
            navigate('/dashboard');
          }
          setLd(false);
          console.log(e);
        });
    }
  };
  const sameAddress = (idx: number) => {
    if (sameAsCorporateAddress) {
      setValue(`clientSPOC.${idx}.address1`, sameAsCorporateAddress.address1);
      setValue(`clientSPOC.${idx}.address2`, sameAsCorporateAddress.address2);
      setValue(`clientSPOC.${idx}.country`, sameAsCorporateAddress.country);
      setValue(`clientSPOC.${idx}.pincode`, sameAsCorporateAddress.pincode);
      setValue(`clientSPOC.${idx}.city`, sameAsCorporateAddress.city);
      setValue(`clientSPOC.${idx}.state`, sameAsCorporateAddress.state);
    }
  };

  useEffect(() => {
    const options: any = [];
    Country.getAllCountries().map((country) => {
      options.push({ label: country.name, value: country.isoCode });
    });
    setCountry(options);
  }, []);

  return (
    <Grid item md={12} sm={8} xs={8}>
      <Typography component={'div'} className='form-box'>
        <Typography
          component={'div'}
          className='client-form-photo-area'
          sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography component={'p'} className='side-client-text'>
            SPOC Details
          </Typography>
          <AddIcon
            onClick={() => {
              append({
                title: '',
                firstName: '',
                lastName: '',
                designationId: '',
                email: '',
                phoneNumber: '',
                mobileno: undefined,
                status: !false,
                branchId: '',
                address1: '',
                address2: '',
                country: '',
                pincode: '',
                city: '',
                sameAsCorporateAddress: false,
                state: '',
                countryCode: '',
                rowId: '',
              });
            }}
          />
        </Typography>
        <form className='private-form' id={'user-add'} onSubmit={handleSubmit(onSubmitForm)}>
          {fields.map((ers: any, idx: number) => {
            return (
              <div key={ers.id}>
                {idx !== 0 ? (
                  <div
                    style={{
                      width: '100%',
                      height: '1px',
                      backgroundColor: '#EAECF0',
                      margin: '32px 0px',
                    }}></div>
                ) : (
                  <></>
                )}
                <Grid container columnSpacing={4.25}>
                  {idx !== 0 ? (
                    <div
                      className='list-del-add-icon'
                      style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                      }}>
                      <Controller
                        control={control}
                        defaultValue={undefined}
                        name={`clientSPOC.${idx}.title`}
                        render={({ field: { onChange, value, name } }) => {
                          return (
                            <span
                              style={{ cursor: 'pointer', marginRight: '24px' }}
                              onClick={() => {
                                removeSpoc(idx, ers.rowId);
                              }}>
                              <img src={Deleteimgicon} alt='delete' />
                            </span>
                          );
                        }}
                      />

                      <span>
                        <AddIcon
                          onClick={() => {
                            append({
                              title: '',
                              firstName: '',
                              lastName: '',
                              designationId: '',
                              email: '',
                              phoneNumber: '',
                              mobileno: undefined,
                              status: !false,
                              branchId: '',
                              address1: '',
                              address2: '',
                              country: '',
                              pincode: '',
                              city: '',
                              sameAsCorporateAddress: false,
                              state: '',
                              countryCode: '',
                              rowId: '',
                            });
                          }}
                        />
                      </span>
                    </div>
                  ) : (
                    <></>
                  )}
                  <Grid item md={14} sm={12} xs={12}>
                    <Grid item md={5.8} sm={12} xs={12}>
                      <Typography component={'div'} className='custom-field'>
                        <Typography component={'p'}>
                          Title <span>*</span>
                        </Typography>
                        <Controller
                          control={control}
                          defaultValue={undefined}
                          name={`clientSPOC.${idx}.title`}
                          render={({ field: { onChange, value, name } }) => {
                            return (
                              <CustomSelect
                                labelKey={'label'}
                                valueKey={'value'}
                                options={[
                                  { value: 'mr', label: 'Mr.' },
                                  { value: 'mrs', label: 'Mrs.' },
                                ]}
                                name={name}
                                value={value}
                                onChange={(e) => {
                                  onChange(e);
                                }}
                                placeHolder='Select Title'
                                error={errors?.clientSPOC?.[idx]?.title?.message}
                              />
                            );
                          }}
                        />
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item md={6} sm={12} xs={12}>
                    <Typography component={'div'} className='custom-field'>
                      <Typography component={'p'}>
                        First Name <span>*</span>
                      </Typography>
                      <Controller
                        control={control}
                        defaultValue={undefined}
                        name={`clientSPOC.${idx}.firstName`}
                        render={({ field: { onChange, value, name } }) => {
                          return (
                            <CustomInput
                              value={value}
                              name={name}
                              className='custom-input'
                              placeHolder='Enter First Name'
                              error={errors?.clientSPOC?.[idx]?.firstName?.message}
                              onChange={(e) => {
                                onChange(e);
                              }}
                            />
                          );
                        }}
                      />
                    </Typography>
                  </Grid>
                  <Grid item md={6} sm={12} xs={12}>
                    <Typography component={'div'} className='custom-field'>
                      <Typography component={'p'}>
                        Last Name <span>*</span>
                      </Typography>
                      <Controller
                        control={control}
                        defaultValue={undefined}
                        name={`clientSPOC.${idx}.lastName`}
                        render={({ field: { onChange, value, name } }) => {
                          return (
                            <CustomInput
                              value={value}
                              name={name}
                              className='custom-input'
                              placeHolder='Enter Last Name'
                              error={errors?.clientSPOC?.[idx]?.lastName?.message}
                              onChange={(e) => {
                                onChange(e);
                              }}
                            />
                          );
                        }}
                      />
                    </Typography>
                  </Grid>
                  <Grid item md={6} sm={12} xs={12}>
                    <Typography component={'div'} className='custom-field'>
                      <Typography component={'p'}>
                        Designation <span>*</span>
                      </Typography>
                      <Controller
                        control={control}
                        defaultValue={undefined}
                        name={`clientSPOC.${idx}.designationId`}
                        render={({ field: { onChange, value, name } }) => {
                          return (
                            <CustomSelect
                              labelKey={'label'}
                              valueKey={'value'}
                              options={
                                designationlist
                                  ? designationlist
                                  : [{ label: '', value: undefined }]
                              }
                              name={name}
                              value={value}
                              onChange={(e) => {
                                onChange(e);
                              }}
                              placeHolder='Select Designation'
                              error={errors?.clientSPOC?.[idx]?.designationId?.message}
                            />
                          );
                        }}
                      />
                    </Typography>
                  </Grid>
                  <Grid item md={6} sm={12} xs={12}>
                    <Typography component={'div'} className='custom-field'>
                      <Typography component={'p'}>
                        Email <span>*</span>
                      </Typography>

                      <Controller
                        control={control}
                        defaultValue={undefined}
                        name={`clientSPOC.${idx}.email`}
                        render={({ field: { onChange, value, name } }) => {
                          return (
                            <CustomInput
                              inputProps={{
                                startAdornment: (
                                  <InputAdornment position='start'>
                                    <img src={EmailIcon} alt='email' />
                                  </InputAdornment>
                                ),
                              }}
                              value={value}
                              name={name}
                              className='custom-input'
                              placeHolder='Enter Email'
                              error={errors?.clientSPOC?.[idx]?.email?.message}
                              onChange={(e) => {
                                onChange(e);
                              }}
                            />
                          );
                        }}
                      />
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container columnSpacing={4.25}>
                  <Grid item md={6} sm={12} xs={12}>
                    <Typography component={'div'} className='custom-field'>
                      <Typography component={'p'}>
                        Phone No <span>*</span>
                      </Typography>
                      <Controller
                        control={control}
                        defaultValue={undefined}
                        name={`clientSPOC.${idx}.phoneNumber`}
                        render={({ field: { onChange, value, name } }) => {
                          return (
                            <CustomPhoneInput
                              placeholder=''
                              containerClass={
                                errors?.clientSPOC?.[idx]?.phoneNumber?.message
                                  ? 'phone-input-error'
                                  : ''
                              }
                              inputClass={
                                errors?.clientSPOC?.[idx]?.phoneNumber?.message
                                  ? 'phone-input phone-input-no-border'
                                  : 'phone-input'
                              }
                              initialCountry={'in'}
                              specialLabel={''}
                              searchClass={'search-custom-class'}
                              value={value}
                              enableSearch={true}
                              searchNotFound={'No Country Found'}
                              inputStyle={{
                                width: '100%',
                                padding: '9px 11px',
                                borderRadius: '6px',
                                paddingLeft: '60px',
                                color: value && String(value).length > 0 ? '#344051' : '#bdbdbd',
                                fontSize: '16px',
                                fontFamily: 'Poppins-Regular',
                                boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
                              }}
                              onChange={(value, data, event, formattedValue) => {
                                const { countryCode, dialCode } = data;
                                onChange(value);
                                setValue(`clientSPOC.${idx}.countryCode`, `+${dialCode}`);
                              }}
                              error={
                                errors?.clientSPOC?.[idx]?.phoneNumber?.message
                                  ? errors?.clientSPOC?.[idx]?.phoneNumber?.message
                                  : ''
                              }
                              inputProps={{ tabIndex: 11 }}
                            />
                          );
                        }}
                      />
                    </Typography>
                  </Grid>
                  <Grid item md={6} sm={12} xs={12}>
                    <Typography component={'div'} className='custom-field'>
                      <Typography component={'p'}>
                        SPOC’s Branch <span>*</span>
                      </Typography>
                      <Controller
                        control={control}
                        defaultValue={undefined}
                        name={`clientSPOC.${idx}.branchId`}
                        render={({ field: { onChange, value, name } }) => {
                          return (
                            <CustomSelect
                              labelKey={'label'}
                              valueKey={'value'}
                              options={branchlist ? branchlist : [{ label: '', value: undefined }]}
                              name={name}
                              value={value}
                              onChange={(e) => {
                                onChange(e);
                              }}
                              placeHolder='Select Branch'
                              error={errors?.clientSPOC?.[idx]?.branchId?.message}
                            />
                          );
                        }}
                      />
                    </Typography>
                  </Grid>
                  <Grid item md={6} sm={12} xs={12} sx={{ display: 'none' }}>
                    <Typography component={'div'} className='custom-field'>
                      <Typography component={'p'}>Mobile No</Typography>
                      <Controller
                        control={control}
                        defaultValue={''}
                        name={`clientSPOC.${idx}.mobileno`}
                        render={({ field: { onChange, value, name } }) => {
                          return (
                            <CustomPhoneInput
                              placeholder=''
                              containerClass={
                                errors?.clientSPOC?.[idx]?.mobileno?.message
                                  ? 'phone-input-error'
                                  : ''
                              }
                              inputClass={
                                errors?.clientSPOC?.[idx]?.mobileno?.message
                                  ? 'phone-input phone-input-no-border'
                                  : 'phone-input'
                              }
                              initialCountry={'in'}
                              specialLabel={''}
                              searchClass={'search-custom-class'}
                              value={value}
                              enableSearch={true}
                              searchNotFound={'No Country Found'}
                              inputStyle={{
                                width: '100%',
                                padding: '9px 11px',
                                borderRadius: '6px',
                                paddingLeft: '60px',
                                color: value && String(value).length > 0 ? '#344051' : '#bdbdbd',
                                fontSize: '16px',
                                fontFamily: 'Poppins-Regular',
                                boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
                              }}
                              onChange={(value, data, event, formattedValue) => {
                                const { countryCode, dialCode } = data;
                                setValue(`clientSPOC.${idx}.countryCode`, dialCode);
                                onChange(formattedValue);
                              }}
                              error={
                                errors?.clientSPOC?.[idx]?.mobileno?.message
                                  ? errors?.clientSPOC?.[idx]?.mobileno?.message
                                  : ''
                              }
                              inputProps={{ tabIndex: 11 }}
                            />
                          );
                        }}
                      />
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container columnSpacing={4.25} className='mt-10'>
                  <Grid item md={6} sm={12} xs={12} alignSelf='flex-end'>
                    <Typography component={'div'} className='custom-field'>
                      <Typography component={'p'}>
                        SPOC Status <span>*</span>
                      </Typography>

                      <Controller
                        render={({ field }) => (
                          <>
                            <RadioGroup className='custom-radio' row {...field}>
                              <FormControlLabel value={true} control={<Radio />} label='Active' />
                              <FormControlLabel
                                value={false}
                                control={<Radio />}
                                label='Inactive'
                              />
                            </RadioGroup>
                            {errors?.clientSPOC?.[idx]?.status && (
                              <span>You must select a SPOC Status</span>
                            )}
                          </>
                        )}
                        name={`clientSPOC.${idx}.status`}
                        control={control}
                      />
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container columnSpacing={4.25}>
                  <Grid item md={12} sm={12} xs={12} sx={{ marginTop: '16px' }}>
                    <Typography component={'div'} className=' d-flex custom-field'>
                      <Controller
                        control={control}
                        defaultValue={false}
                        name={`clientSPOC.${idx}.sameAsCorporateAddress`}
                        render={({ field: { onChange, value, name } }) => {
                          return (
                            <Checkbox
                              className='custom-checkbox'
                              sx={{
                                '&:hover': { bgcolor: 'transparent' },
                              }}
                              disableRipple
                              color='default'
                              checkedIcon={<CheckedIcon />}
                              icon={<CheckIcon />}
                              checked={value}
                              name={name}
                              value={value}
                              onChange={(e) => {
                                onChange(e);
                                if (e.target.checked) {
                                  sameAddress(idx);
                                } else {
                                  setValue(`clientSPOC.${idx}.address1`, '');
                                  setValue(`clientSPOC.${idx}.address2`, '');
                                  setValue(`clientSPOC.${idx}.country`, '');
                                  setValue(`clientSPOC.${idx}.pincode`, '');
                                  setValue(`clientSPOC.${idx}.city`, '');
                                  setValue(`clientSPOC.${idx}.state`, '');
                                }
                              }}
                            />
                          );
                        }}
                      />
                      <Typography component={'p'} style={{ marginLeft: '10px', marginTop: '5px' }}>
                        Is the SPOC’s address going to be same as Corporate Address?{' '}
                      </Typography>
                    </Typography>
                  </Grid>
                </Grid>

                <Grid container columnSpacing={4.25}>
                  <Grid item md={6} sm={12} xs={12}>
                    <Typography component={'div'} className='custom-field'>
                      <Typography component={'p'}>
                        Address 1 <span>*</span>
                      </Typography>
                      <Controller
                        control={control}
                        defaultValue={undefined}
                        name={`clientSPOC.${idx}.address1`}
                        render={({ field: { onChange, value, name } }) => {
                          return (
                            <CustomInput
                              value={value}
                              name={name}
                              className='custom-input'
                              placeHolder='Enter Address'
                              error={errors?.clientSPOC?.[idx]?.address1?.message}
                              onChange={(e) => {
                                onChange(e);
                              }}
                            />
                          );
                        }}
                      />
                    </Typography>
                  </Grid>
                  <Grid item md={6} sm={12} xs={12}>
                    <Typography component={'div'} className='custom-field'>
                      <Typography component={'p'}>Address 2</Typography>
                      <Controller
                        control={control}
                        defaultValue={''}
                        name={`clientSPOC.${idx}.address2`}
                        render={({ field: { onChange, value, name } }) => {
                          return (
                            <CustomInput
                              value={value}
                              name={name}
                              className='custom-input'
                              placeHolder='Enter Address'
                              error={errors?.clientSPOC?.[idx]?.address2?.message}
                              onChange={(e) => {
                                onChange(e);
                              }}
                            />
                          );
                        }}
                      />
                    </Typography>
                  </Grid>
                  <Grid item md={6} sm={12} xs={12}>
                    <Typography component={'div'} className='custom-field'>
                      <Typography component={'p'}>
                        Country <span>*</span>
                      </Typography>
                      <Controller
                        control={control}
                        defaultValue={undefined}
                        name={`clientSPOC.${idx}.country`}
                        render={({ field: { onChange, value, name } }) => {
                          return (
                            <CustomSelect
                              labelKey={'label'}
                              valueKey={'value'}
                              options={country}
                              value={value}
                              name={name}
                              className='custom-input'
                              placeHolder='Enter Country'
                              error={errors?.clientSPOC?.[idx]?.country?.message}
                              onChange={(e) => {
                                onChange(e);
                                setValue(`clientSPOC.${idx}.state`, '');
                                setValue(`clientSPOC.${idx}.city`, '');
                              }}
                            />
                          );
                        }}
                      />
                    </Typography>
                  </Grid>
                  <Grid item md={6} sm={12} xs={12}>
                    <Typography component={'div'} className='custom-field'>
                      <Typography component={'p'}>
                        State <span>*</span>
                      </Typography>
                      <Controller
                        control={control}
                        defaultValue={undefined}
                        name={`clientSPOC.${idx}.state`}
                        render={({ field: { onChange, value, name } }) => {
                          const options: any = [];
                          if (watch(`clientSPOC.${idx}.country`)) {
                            State.getStatesOfCountry(watch(`clientSPOC.${idx}.country`)).map(
                              (state: any) => {
                                options.push({ label: state.name, value: state.isoCode });
                              },
                            );
                          }
                          return (
                            <CustomSelect
                              labelKey={'label'}
                              valueKey={'value'}
                              options={options}
                              value={value ? value : null}
                              name={name}
                              className='custom-input'
                              placeHolder='State'
                              error={errors?.clientSPOC?.[idx]?.state?.message}
                              onChange={(e) => {
                                onChange(e);
                                setValue(`clientSPOC.${idx}.city`, '');
                              }}
                            />
                          );
                        }}
                      />
                    </Typography>
                  </Grid>
                  <Grid item md={6} sm={12} xs={12}>
                    <Typography component={'div'} className='custom-field'>
                      <Typography component={'p'}>
                        City <span>*</span>
                      </Typography>
                      <Controller
                        control={control}
                        defaultValue={undefined}
                        name={`clientSPOC.${idx}.city`}
                        render={({ field: { onChange, value, name } }) => {
                          const options: any = [];
                          if (watch(`clientSPOC.${idx}.state`)) {
                            City.getCitiesOfState(
                              watch(`clientSPOC.${idx}.country`),
                              watch(`clientSPOC.${idx}.state`),
                            ).map((city: any) => {
                              options.push({ label: city.name, value: city.name });
                            });
                          }
                          return (
                            <CustomSelect
                              labelKey={'label'}
                              valueKey={'value'}
                              options={options}
                              value={value ? value : null}
                              name={name}
                              className='custom-input'
                              placeHolder='City'
                              error={errors?.clientSPOC?.[idx]?.city?.message}
                              onChange={(e) => {
                                onChange(e);
                              }}
                            />
                          );
                        }}
                      />
                    </Typography>
                  </Grid>
                  <Grid item md={6} sm={12} xs={12}>
                    <Typography component={'div'} className='custom-field'>
                      <Typography component={'p'}>
                        Pincode <span>*</span>
                      </Typography>
                      <Controller
                        control={control}
                        defaultValue={undefined}
                        name={`clientSPOC.${idx}.pincode`}
                        render={({ field: { onChange, value, name } }) => {
                          return (
                            <CustomInput
                              className='custom-input'
                              onChange={onChange}
                              value={value}
                              name={name}
                              placeHolder='Enter Pincode'
                              type={'number'}
                              error={errors?.clientSPOC?.[idx]?.pincode?.message}
                            />
                          );
                        }}
                      />
                    </Typography>
                  </Grid>
                </Grid>
              </div>
            );
          })}
        </form>
      </Typography>
      {Ld && <Loader />}
    </Grid>
  );
};

export default SPOCDETAIL;
