/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import Schedule from 'assets/png/schedule.svg';
import InterviewDetail from 'assets/png/interviewDetail.svg';
import SechduleToClient from 'assets/png/scheduletoClient.svg';
import SechduleToCandidate from 'assets/png/scheduletoCandidate.svg';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import Editor from 'ck-editor-plugin/build/ckeditor';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Popover,
  Typography,
  Modal,
  Box,
  Grid,
  Tab,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
  Button,
} from '@mui/material';
import CustomInput from 'components/CustomInput/customInput';
import CustomSelect from 'components/CustomSelect/customSelect';
import { Controller, useForm } from 'react-hook-form';
import { addNewData, getAllListData, patchData } from 'services/CommonServices';
import {
  CvRejectType,
  CvShortListType,
  InterviewConfirmationCandidateType,
  InterviewConfirmationClientType,
  InterviewDetailsType,
  InterviewRejectType,
} from 'types';
import { ResumesENDPOINT } from 'utils/Constant';
import {
  CVRejectSchema,
  InterviewConfirmationCandidateSchema,
  InterviewConfirmationClientSchema,
  InterviewDetailsSchema,
  InterviewRejectSchema,
} from 'utils/ValidatorSchema';
import {
  interviewDirectSelect,
  interviewResechdule,
  interviewSechdule,
  resumeStatusToBgColor,
} from 'utils/helper';
import Reschedule from 'assets/png/Reschedule.svg';
import CvReject from 'assets/png/cv-reject.svg';
import CvApprove from 'assets/png/cv-approve.svg';
import CloseIcon from 'assets/png/close.svg';
import { useSearchParams } from 'react-router-dom';
import {
  ResechduleReason,
  alertOptions,
  interviewDuration,
  interviewRejectReason,
} from 'utils/Config';
import { ViewInterviewDetails } from './viewInterviewDetails';
import Loader from 'components/loader';
import moment from 'moment';
import CustomTagInput from 'components/Taginput/Taginput';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import dayjs from 'dayjs';
import { useAppSelector } from 'store/hooks';
import { toast } from 'react-toastify';

const StatusTag: React.FC<{
  tag: string;
  color: string;
  backgroundColor?: string;
  curser?: string;
  onClick?: (e: any) => void;
  userId: string;
  crmUserId: string;
}> = ({ tag, color, backgroundColor, curser, onClick, userId, crmUserId }) => {
  return (
    <span
      style={{
        color,
        backgroundColor: backgroundColor || '#FFF2D0',
        paddingLeft: '8px',
        paddingRight: '8px',
        marginRight: '6px',
        fontSize: '14px',
        cursor:
          userId === crmUserId && !['S', 'R', 'OA', 'OR', 'JD', 'NJ', 'J', 'B'].includes(tag)
            ? 'pointer'
            : 'not-allowed',
      }}
      onClick={onClick}>
      {tag}
    </span>
  );
};
const InterviewComponent: React.FC<{
  resumeId: string;
  candidateName: string;
  getList: () => void;
  screeningStatus: any;
  resumeDetails: any;
  userId: string;
  crmUserId: string;
}> = ({ screeningStatus, candidateName, resumeId, getList, resumeDetails, userId, crmUserId }) => {
  const { menuList }: any = useAppSelector((store: any) => store.userLogin);
  const [loader, setLoader] = useState<any>(false);
  const [panchorEl, setPAnchorEl] = React.useState<HTMLAnchorElement | null>(null);
  const [interviewScheduleModalOpen, setInterviewScheduleModalOpen] = React.useState(false);
  const open = Boolean(panchorEl);
  const [tabValue, setTabValue] = useState('1');
  const [panchorElSelect, setPAnchorElSelect] = React.useState<HTMLAnchorElement | null>(null);
  const [isRejectModalOpen, setRejectModalOpen] = React.useState(false);
  const openselcet = Boolean(panchorElSelect);
  const paidOne = openselcet ? 'simple-popover' : undefined;
  const handleSelectClose = () => {
    reset();
    setRejectModalOpen(false);
  };
  const handleSelectOpen = (status: string) => {
    setValue('status', status);
    setPAnchorEl(null);
    setRejectModalOpen(true);
  };

  const {
    control,
    formState: { errors },
    handleSubmit: handleSubmitSelect,
    reset,
    setValue,
    trigger,
    watch: watchInterviewFeedback,
    getValues: getValuesSelect,
  } = useForm({ resolver: yupResolver(InterviewRejectSchema) });

  const isApplicableForNextInterview =
    watchInterviewFeedback('isApplicableForNextInterview') === ('false' as unknown as boolean);

  const onSubmit = (data: InterviewRejectType) => {
    const objSelect = {
      status: data.status === 'approved' && 'S',
      isApplicableForNextInterview: data.isApplicableForNextInterview,
      remarks: data.remarks,
    };
    const objReject = {
      status: data.status === 'rejected' && 'R',
      reason: data.reason,
      remarks: data.remarks,
    };
    patchData(
      '',
      data.status === 'approved' ? objSelect : objReject,
      `${ResumesENDPOINT.interview}/status/${resumeId}`,
      true,
    )
      .then(() => {
        handleModalClose();
        handleSelectClose();
        getList();
      })
      .catch((e) => console.log(e));
  };

  const paid = open ? 'simple-popover' : undefined;
  const [searchParams] = useSearchParams();
  const handleClose = () => {
    setPAnchorEl(null);
  };
  const handleModalClose = () => {
    setInterviewScheduleModalOpen(false);
    resetOne();
    setTabValue('1');
  };
  const handleChange = (event: any, newValue: React.SetStateAction<string>) => {
    setTabValue(newValue);
  };
  const handleModalOpen = (value: any, interviewType?: string) => {
    setvaluesOne('interviewType', interviewType);
    setPAnchorEl(null);
    setInterviewScheduleModalOpen(true);
  };

  const handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    setPAnchorEl(event.currentTarget);
  };

  const {
    control: controlOne,
    formState: { errors: errorsOne },
    handleSubmit: handleSubmitOne,
    reset: resetOne,
    setValue: setvaluesOne,
    watch,
    getValues: getValuesOne,
  } = useForm({ resolver: yupResolver(InterviewDetailsSchema) });

  const {
    control: controlTwo,
    formState: { errors: errorsTwo },
    handleSubmit: handleSubmitTwo,
    reset: resetTwo,
    setValue: setValuesTwo,
    getValues: getValuesTwo,
  } = useForm({ resolver: yupResolver(InterviewConfirmationClientSchema) });

  const {
    control: controlThree,
    formState: { errors: errorsThree },
    handleSubmit: handleSubmitThree,
    reset: resetThree,
    setValue: setValuesThree,
    getValues: getValuesThree,
  } = useForm({ resolver: yupResolver(InterviewConfirmationCandidateSchema) });

  const onSubmitOne = (data: InterviewDetailsType) => {
    setLoader(true);
    const obj: any = {
      positionId: searchParams.get('id'),
      interviewDetails: [
        {
          interviewType: getValuesOne('interviewType'),
          resumeId: resumeDetails.id,
          ...(getValuesOne('interviewType') === 'reSchedule'
            ? { rescheduleReason: data.rescheduleReason }
            : {}),
          duration: data.interviewDuration,
          level: data.interviewLevel,
          mode: data.interviewMode,
          address: data.interviewVenueAddress,
          venue: data.interviewVenue,
          dateTime: dayjs(data.interviewDateTime),
          spocId: resumeDetails.position.spocId,
          contactPerson: data.contactPerson,
          contactNumber: Number(data.contactNo),
          additionalInfo: data.additionalInfo,
        },
      ],
    };
    addNewData(JSON.stringify(obj), ResumesENDPOINT.emailTempalate)
      .then((res: any) => {
        if (res && res.data && res.data) {
          const data = res.data;
          setValuesTwo('client', data.confirmationToClient?.client);
          setValuesTwo('message', data.confirmationToClient?.message);
          setValuesTwo('subject', data.confirmationToClient?.subject);
          setValuesThree('candidate', data.confirmationToCandidate[0]?.candidate);
          setValuesThree('message', data.confirmationToCandidate[0]?.message);
          setValuesThree('subject', data.confirmationToCandidate[0]?.subject);
          setTabValue('2');
        }
        setLoader(false);
      })
      .then((e) => {
        setLoader(false);
        console.log(e);
      });
  };
  const onSubmitTwo = (data: InterviewConfirmationClientType) => {
    if (data) {
      setTabValue('3');
    }
  };
  const onSubmitThree = (data: InterviewConfirmationCandidateType) => {
    setLoader(true);
    const obj = {
      positionId: searchParams.get('id'),
      interviewDetails: [
        {
          interviewType: getValuesOne('interviewType'),
          ...(getValuesOne('interviewType') === 'reSchedule'
            ? { rescheduleReason: getValuesOne('rescheduleReason') }
            : {}),
          resumeId: resumeDetails.id,
          ...(getValuesOne('interviewMode') === 'faceToFace'
            ? { address: getValuesOne('interviewVenueAddress') }
            : {}),
          duration: getValuesOne('interviewDuration'),
          level: getValuesOne('interviewLevel'),
          mode: getValuesOne('interviewMode'),
          venue: getValuesOne('interviewVenue'),
          dateTime: dayjs(getValuesOne('interviewDateTime')),
          spocId: resumeDetails.position.spocId,
          contactPerson: getValuesOne('contactPerson'),
          contactNumber: Number(getValuesOne('contactNo')),
        },
      ],
      confirmationToClient: {
        subject: getValuesTwo('subject'),
        message: getValuesTwo('message'),
      },
      confirmationToCandidate: [
        {
          resumeId: resumeDetails.id,
          subject: getValuesThree('subject'),
          message: getValuesThree('message'),
        },
      ],
      ...(getValuesOne('cc') ? { cc: getValuesOne('cc') && getValuesOne('cc') } : {}),
    };
    // console.log(obj);

    addNewData(obj, ResumesENDPOINT.interview, {}, true)
      .then((res) => {
        handleModalClose();
        resetOne();
        resetTwo();
        resetThree();
        setLoader(false);
        getList();
        setTabValue('1');
      })
      .catch((e) => {
        setLoader(false);
        console.log(e);
      });
  };
  const handleEditorChangeTwo = (event: any, editor: any) => {
    const data = editor && editor.getData();
    setValuesTwo('message', data);
  };
  const handleEditorChangeThree = (event: any, editor: any) => {
    const data = editor && editor.getData();
    setValuesThree('message', data);
  };
  useEffect(() => {
    if (interviewScheduleModalOpen) {
      setvaluesOne(
        'contactPerson',
        `${resumeDetails?.position?.spoc?.firstName} ${resumeDetails?.position?.spoc?.lastName}`,
      );
      setvaluesOne('contactNo', resumeDetails?.position?.spoc?.phoneNumber);
    }
  }, [interviewScheduleModalOpen]);

  return (
    <div>
      {loader && <Loader />}
      <Popover
        id={paid}
        anchorEl={panchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        style={{ width: '100%', padding: '1rem' }}>
        {interviewSechdule.includes(screeningStatus) && (
          <Typography component={'div'}>
            <Typography
              onClick={() => {
                handleModalOpen(resumeDetails, 'schedule');
              }}
              className='menu-item'
              style={{ padding: '0px 15px 0px 15px' }}>
              <img src={Schedule} alt='Schedule Interview' />
              Schedule Interview
            </Typography>
          </Typography>
        )}
        {interviewResechdule.includes(screeningStatus) && (
          <Typography component={'div'}>
            <ViewInterviewDetails resumeDetails={resumeDetails} />
            <Typography
              onClick={() => handleModalOpen(resumeDetails, 'reSchedule')}
              className='menu-item'
              style={{ padding: '0px 15px 0px 15px' }}>
              <img src={Reschedule} alt='Ri' />
              Re-Schedule Interview
            </Typography>
          </Typography>
        )}
        {!interviewDirectSelect.includes(screeningStatus) && (
          <>
            <Typography
              onClick={() => handleSelectOpen('approved')}
              className='menu-item'
              style={{ padding: '0px 15px 0px 15px' }}>
              <img src={CvReject} alt='Is' />
              Interview Selected
            </Typography>
            <Typography
              onClick={() => handleSelectOpen('rejected')}
              className='menu-item'
              style={{ padding: '0px 15px 0px 15px' }}>
              <img src={CvApprove} alt='Ir' />
              Interview Rejected
            </Typography>
          </>
        )}
      </Popover>
      <Typography component={'p'}>
        {screeningStatus ? (
          <StatusTag
            color='#4A5569'
            backgroundColor={resumeStatusToBgColor[screeningStatus]}
            tag={screeningStatus}
            userId={userId}
            crmUserId={crmUserId}
            onClick={(e: any) => {
              if (
                interviewSechdule.includes(screeningStatus) ||
                interviewResechdule.includes(screeningStatus)
              ) {
                const settingData = menuList
                  .find((e: any) => e.moduleName === 'Interview')
                  .screens.find((a: any) => a.screenName === 'Create Interview Schedule');
                if (!settingData) {
                  toast.error("You don't have the access", alertOptions);
                } else if (userId === crmUserId) {
                  handleClick(e);
                }
              }
            }}
          />
        ) : (
          '-'
        )}
      </Typography>
      <Modal open={interviewScheduleModalOpen} onClose={handleModalClose}>
        <Box className='email-modal' sx={{ width: '865px !important' }}>
          <Typography component={'div'} className='d-flex-a flex-sb'>
            <Typography className='l-title'>
              {getValuesOne('interviewType') === 'reSchedule'
                ? 'Re-Schedule Interview'
                : 'Schedule Interview'}
            </Typography>
            <Typography className='text-right'>
              <img
                src={CloseIcon}
                alt='close'
                onClick={handleModalClose}
                style={{ cursor: 'pointer' }}
              />
            </Typography>
          </Typography>
          <Grid item md={12} sm={12} xs={12} sx={{ marginTop: '15px' }}>
            <Box>
              <TabContext value={tabValue}>
                <Typography component={'div'} className='d-flex-a d-flex-sb custom-position-tab'>
                  <Box
                    sx={{
                      borderColor: 'divider',
                      borderRadius: '8px',
                      background: '#F5F6FA',
                      padding: '5px',
                    }}>
                    <TabList aria-label='lab API tabs example'>
                      <Tab
                        icon={
                          <img
                            src={InterviewDetail}
                            alt='ID'
                            style={{
                              height: '18px',
                              width: '18px',
                              marginBottom: '0px',
                              marginRight: '10px',
                            }}
                          />
                        }
                        label='Interview Details'
                        value='1'
                      />

                      <Tab
                        icon={
                          <img
                            src={SechduleToClient}
                            alt='OverallStatus'
                            style={{
                              height: '18px',
                              width: '18px',
                              marginBottom: '0px',
                              marginRight: '10px',
                            }}
                          />
                        }
                        label='Interview Confirmation to Clients'
                        value='2'
                      />

                      <Tab
                        icon={
                          <img
                            src={SechduleToCandidate}
                            alt='SC'
                            style={{
                              height: '18px',
                              width: '18px',
                              marginBottom: '0px',
                              marginRight: '10px',
                            }}
                          />
                        }
                        label='Interview Confirmation to Candidate'
                        value='3'
                      />
                    </TabList>
                  </Box>
                </Typography>
                <Typography component={'div'}>
                  <TabPanel value='1' sx={{ padding: '10px 0px' }}>
                    <Typography component={'div'}>
                      <form
                        className='private-form'
                        id={'interview-form-One'}
                        onSubmit={handleSubmitOne(onSubmitOne)}>
                        <Grid container>
                          <Grid item md={12} sm={12} xs={12}>
                            <Typography component={'div'} className='custom-field'>
                              <Typography component={'p'}>Client</Typography>
                              <Controller
                                render={({ field: { onChange, value, name } }) => (
                                  <CustomInput
                                    value={resumeDetails?.client?.corporateName}
                                    disabled
                                    name={name}
                                    placeHolder='Client'
                                    className='custom-input'
                                    error={errorsOne.client?.message}
                                    onChange={(e) => {
                                      onChange(e);
                                    }}
                                  />
                                )}
                                name='client'
                                control={controlOne}
                              />
                            </Typography>
                          </Grid>
                          <Grid item md={12} sm={12} xs={12}>
                            <Typography component={'div'} className='custom-field'>
                              <Typography component={'p'}>CC</Typography>

                              <Controller
                                render={({ field: { onChange, value, name } }) => (
                                  <CustomTagInput
                                    value={value}
                                    name={name}
                                    placeHolder='Add multiple emails separated by enter'
                                    // className='custom-input'
                                    error={errorsOne.cc?.message ? true : false}
                                    onChange={(e: any) => {
                                      onChange(e);
                                    }}
                                  />
                                )}
                                name='cc'
                                control={controlOne}
                              />
                            </Typography>
                          </Grid>
                          <Grid item md={12} sm={12} xs={12}>
                            <Typography component={'div'} className='custom-field'>
                              <Typography component={'p'}>Candidate(s)</Typography>

                              <Controller
                                render={({ field: { onChange, value, name } }) => (
                                  <CustomInput
                                    value={candidateName}
                                    name={name}
                                    disabled
                                    placeHolder='Candidate(s)'
                                    className='custom-input'
                                    error={errorsOne.candidate?.message}
                                    onChange={(e) => {
                                      onChange(e);
                                    }}
                                  />
                                )}
                                name='candidate'
                                control={controlOne}
                              />
                            </Typography>
                          </Grid>
                          {getValuesOne('interviewType') === 'reSchedule' && (
                            <Grid item md={12} sm={12} xs={12}>
                              <Typography component={'div'} className='custom-field'>
                                <Typography component={'p'}>
                                  Reason for Re-sechedule <span>*</span>
                                </Typography>
                                <Controller
                                  render={({ field: { onChange, value, name } }) => (
                                    <CustomSelect
                                      labelKey={'label'}
                                      valueKey={'value'}
                                      options={ResechduleReason}
                                      name={name}
                                      value={value}
                                      onChange={(e) => {
                                        onChange(e);
                                      }}
                                      placeHolder='Reason for Re-schedule'
                                      error={errorsOne.rescheduleReason?.message}
                                    />
                                  )}
                                  name='rescheduleReason'
                                  control={controlOne}
                                />
                              </Typography>
                            </Grid>
                          )}
                          <Grid item md={12} sm={12} xs={12}>
                            <Typography component={'div'} className='custom-field'>
                              <Typography component={'p'}>
                                Interview Level <span>*</span>
                              </Typography>
                              <>
                                <Controller
                                  control={controlOne}
                                  name='interviewLevel'
                                  render={({ field: { onChange, value, name } }) => {
                                    return (
                                      <RadioGroup
                                        className='custom-radio'
                                        row
                                        aria-labelledby=''
                                        name={name}
                                        value={value}
                                        onChange={(e) => {
                                          onChange(e);
                                        }}>
                                        <FormControlLabel
                                          value='firstInterview'
                                          control={<Radio />}
                                          label='First Interview'
                                        />
                                        <FormControlLabel
                                          value='secondInterview'
                                          control={<Radio />}
                                          label='Second Interview'
                                        />
                                        <FormControlLabel
                                          value='thirdInterview'
                                          control={<Radio />}
                                          label='Third Interview'
                                        />
                                        <FormControlLabel
                                          value='fourthInterview'
                                          control={<Radio />}
                                          label='Fourth Interview'
                                        />
                                        <FormControlLabel
                                          value='finalInterview'
                                          control={<Radio />}
                                          label='Final Interview'
                                        />
                                      </RadioGroup>
                                    );
                                  }}
                                />
                                {errorsOne &&
                                  errorsOne?.interviewLevel &&
                                  errorsOne?.interviewLevel?.message && (
                                    <div className='Custom-error '>
                                      {String(errorsOne?.interviewLevel?.message)}
                                    </div>
                                  )}
                              </>
                            </Typography>
                          </Grid>
                          <Grid item md={12} sm={12} xs={12}>
                            <Typography component={'div'} className='custom-field'>
                              <Typography component={'p'}>
                                Interview Mode <span>*</span>
                              </Typography>
                              <>
                                <Controller
                                  control={controlOne}
                                  name='interviewMode'
                                  render={({ field: { onChange, value, name } }) => {
                                    return (
                                      <RadioGroup
                                        className='custom-radio'
                                        row
                                        aria-labelledby=''
                                        name={name}
                                        value={value}
                                        onChange={(e) => {
                                          onChange(e);
                                        }}>
                                        <FormControlLabel
                                          value='faceToFace'
                                          control={<Radio />}
                                          label='Face to Face'
                                        />
                                        <FormControlLabel
                                          value='teleCon'
                                          control={<Radio />}
                                          label='TeleCon'
                                        />
                                        <FormControlLabel
                                          value='videoConference'
                                          control={<Radio />}
                                          label='Video Conference'
                                        />
                                      </RadioGroup>
                                    );
                                  }}
                                />
                                {errorsOne &&
                                  errorsOne?.interviewMode &&
                                  errorsOne?.interviewMode?.message && (
                                    <div className='Custom-error '>
                                      {String(errorsOne?.interviewMode?.message)}
                                    </div>
                                  )}
                              </>
                            </Typography>
                          </Grid>
                          <Grid item md={12} sm={12} xs={12}>
                            <Typography className='bdiveder' />
                          </Grid>
                          <Grid container>
                            <Grid item md={12} sm={12} xs={12}>
                              <Grid container columnSpacing={2}>
                                {watch('interviewMode') === 'faceToFace' ? (
                                  <Grid item md={4} sm={12} xs={12}>
                                    <Typography component={'div'} className='custom-field'>
                                      <Typography component={'p'}>
                                        Interview Venue Address <span>*</span>
                                      </Typography>
                                      <Controller
                                        control={controlOne}
                                        defaultValue={''}
                                        name='interviewVenueAddress'
                                        render={({ field: { onChange, value, name } }) => {
                                          return (
                                            <CustomSelect
                                              labelKey={'label'}
                                              valueKey={'value'}
                                              options={[
                                                {
                                                  label: 'Client/Plant Address',
                                                  value: 'client/plantAddress',
                                                },
                                                {
                                                  label: 'Other Location',
                                                  value: 'otherLocation',
                                                },
                                              ]}
                                              name={name}
                                              value={value}
                                              onChange={(e) => {
                                                onChange(e);
                                                if (
                                                  watch('interviewVenueAddress') === 'otherLocation'
                                                ) {
                                                  setvaluesOne('interviewVenue', '');
                                                }
                                              }}
                                              placeHolder='Interview Venue Address'
                                              error={errorsOne.interviewVenueAddress?.message}
                                            />
                                          );
                                        }}
                                      />
                                    </Typography>
                                  </Grid>
                                ) : (
                                  ''
                                )}
                                {watch('interviewMode') === 'faceToFace' &&
                                watch('interviewVenueAddress') ? (
                                  watch('interviewVenueAddress') === 'client/plantAddress' ? (
                                    <Grid item md={8} sm={12} xs={12}>
                                      <Typography component={'div'} className='custom-field'>
                                        <Typography component={'p'}>
                                          Interview Venue <span>*</span>
                                        </Typography>
                                        <Controller
                                          render={({ field: { onChange, value, name } }) => {
                                            const options = [];
                                            if (
                                              !resumeDetails?.position?.spoc?.sameAsCorporateAddress
                                            ) {
                                              options.push(
                                                {
                                                  label: `${resumeDetails?.position?.spoc
                                                    ?.address1}, ${
                                                    resumeDetails?.position?.spoc?.address2
                                                      ? resumeDetails?.position?.spoc?.address2 +
                                                        ','
                                                      : ''
                                                  } ${resumeDetails?.position?.spoc
                                                    ?.city}-${resumeDetails?.position?.spoc
                                                    ?.pincode}, ${resumeDetails?.position?.spoc
                                                    ?.state} ${resumeDetails?.position?.spoc
                                                    ?.country}`,
                                                  value: `${resumeDetails?.position?.spoc
                                                    ?.address1}, ${
                                                    resumeDetails?.position?.spoc?.address2
                                                      ? resumeDetails?.position?.spoc?.address2 +
                                                        ','
                                                      : ''
                                                  } ${resumeDetails?.position?.spoc
                                                    ?.city}-${resumeDetails?.position?.spoc
                                                    ?.pincode}, ${resumeDetails?.position?.spoc
                                                    ?.state} ${resumeDetails?.position?.spoc
                                                    ?.country}`,
                                                },
                                                {
                                                  label: `${resumeDetails?.client.address1}, ${
                                                    resumeDetails?.client.address2
                                                      ? resumeDetails?.client.address2 + ','
                                                      : ''
                                                  } ${resumeDetails?.client.city}-${resumeDetails
                                                    ?.client.pincode}, ${resumeDetails?.client
                                                    .state} ${resumeDetails?.client.country}`,
                                                  value: `${resumeDetails?.client.address1}, ${
                                                    resumeDetails?.client.address2
                                                      ? resumeDetails?.client.address2 + ','
                                                      : ''
                                                  } ${resumeDetails?.client.city}-${resumeDetails
                                                    ?.client.pincode}, ${resumeDetails?.client
                                                    .state} ${resumeDetails?.client.country}`,
                                                },
                                              );
                                            } else {
                                              options.push({
                                                label: `${resumeDetails?.position?.spoc
                                                  ?.address1}, ${
                                                  resumeDetails?.position?.spoc?.address2
                                                    ? resumeDetails?.position?.spoc?.address2 + ','
                                                    : ''
                                                } ${resumeDetails?.position?.spoc
                                                  ?.city}-${resumeDetails?.position?.spoc
                                                  ?.pincode}, ${resumeDetails?.position?.spoc
                                                  ?.state} ${resumeDetails?.position?.spoc
                                                  ?.country}`,
                                                value: `${resumeDetails?.position?.spoc
                                                  ?.address1}, ${
                                                  resumeDetails?.position?.spoc?.address2
                                                    ? resumeDetails?.position?.spoc?.address2 + ','
                                                    : ''
                                                } ${resumeDetails?.position?.spoc
                                                  ?.city}-${resumeDetails?.position?.spoc
                                                  ?.pincode}, ${resumeDetails?.position?.spoc
                                                  ?.state} ${resumeDetails?.position?.spoc
                                                  ?.country}`,
                                              });
                                            }
                                            return (
                                              <CustomSelect
                                                labelKey={'label'}
                                                valueKey={'value'}
                                                value={value}
                                                name={name}
                                                options={options}
                                                placeHolder='Interview Venue'
                                                className='custom-input'
                                                error={errorsOne.interviewVenue?.message}
                                                onChange={(e) => {
                                                  onChange(e);
                                                }}
                                              />
                                            );
                                          }}
                                          name='interviewVenue'
                                          control={controlOne}
                                        />
                                      </Typography>
                                    </Grid>
                                  ) : (
                                    <Grid item md={8} sm={12} xs={12}>
                                      <Typography component={'div'} className='custom-field'>
                                        <Typography component={'p'}>
                                          Interview Venue <span>*</span>
                                        </Typography>
                                        <Controller
                                          render={({ field: { onChange, value, name } }) => (
                                            <CustomInput
                                              value={value}
                                              name={name}
                                              placeHolder='Interview Venue'
                                              className='custom-input'
                                              error={errorsOne.interviewVenue?.message}
                                              onChange={(e) => {
                                                onChange(e);
                                              }}
                                            />
                                          )}
                                          name='interviewVenue'
                                          control={controlOne}
                                        />
                                      </Typography>
                                    </Grid>
                                  )
                                ) : (
                                  ''
                                )}
                              </Grid>
                              <Grid container columnSpacing={2}>
                                <Grid item md={4} sm={12} xs={12}>
                                  <Typography component={'div'} className='custom-field'>
                                    <Controller
                                      control={controlOne}
                                      defaultValue={''}
                                      name='interviewDateTime'
                                      render={({ field: { onChange, value, name } }) => {
                                        return (
                                          <>
                                            {/* <TextField
                                              id='date'
                                              value={value}
                                              name={name}
                                              type='datetime-local'
                                              onChange={(e) => {
                                                onChange(e);
                                              }}
                                              className={`clientfilter-time-from-to ${
                                                errorsOne?.interviewDateTime &&
                                                errorsOne?.interviewDateTime?.message
                                                  ? 'clientfilter-time-from-to-error'
                                                  : ''
                                              }`}
                                              InputProps={{
                                                inputProps: {
                                                  min: currentDate,
                                                },
                                              }}
                                            /> */}
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                              <DemoContainer
                                                components={['DateTimePicker']}
                                                sx={{ paddingTop: '0px' }}>
                                                <Typography component={'p'}>
                                                  Interview Date & Time <span>*</span>
                                                </Typography>
                                                <DateTimePicker
                                                  views={[
                                                    'day',
                                                    'month',
                                                    'year',
                                                    'hours',
                                                    'minutes',
                                                  ]}
                                                  name={name}
                                                  value={dayjs(value)}
                                                  sx={{
                                                    overflow: 'hidden !important',
                                                    minWidth: 'unset !important',
                                                    marginTop: 'unset !important',
                                                    '& input': {
                                                      fontSize: '14px !important',
                                                      padding: '10.5px 12px',
                                                    },
                                                    '& .MuiOutlinedInput-root': {
                                                      '& fieldset': {
                                                        borderColor: '#d0d5dd', // Remove border
                                                        borderWidth: 0.5,
                                                      },
                                                      '&:hover fieldset': {
                                                        borderColor:
                                                          errorsOne?.interviewDateTime &&
                                                          errorsOne?.interviewDateTime?.message
                                                            ? 'red'
                                                            : '#d0d5dd', // Remove border on hover
                                                        borderWidth: 0.5,
                                                      },
                                                      '&.Mui-focused fieldset': {
                                                        borderColor:
                                                          errorsOne?.interviewDateTime &&
                                                          errorsOne?.interviewDateTime?.message
                                                            ? 'red'
                                                            : '#d0d5dd', // Remove border on focus
                                                        borderWidth: 0.5,
                                                      },
                                                    },
                                                    '& .MuiOutlinedInput-input': {
                                                      '&:focus': {
                                                        outline: 'none', // Remove default outline
                                                      },
                                                    },
                                                  }}
                                                  disablePast
                                                  slotProps={{
                                                    field: {
                                                      readOnly: true,
                                                    },
                                                    textField: {
                                                      error:
                                                        errorsOne?.interviewDateTime &&
                                                        errorsOne?.interviewDateTime?.message
                                                          ? true
                                                          : false,
                                                    },
                                                  }}
                                                  // className={`clientfilter-time-from-to ${
                                                  //   errorsOne?.interviewDateTime &&
                                                  //   errorsOne?.interviewDateTime?.message
                                                  //     ? 'clientfilter-time-from-to-error'
                                                  //     : ''
                                                  // }`}
                                                  onChange={(e: any) => {
                                                    onChange(e);
                                                  }}
                                                />
                                              </DemoContainer>
                                            </LocalizationProvider>

                                            {errorsOne &&
                                              errorsOne.interviewDateTime &&
                                              errorsOne?.interviewDateTime?.message && (
                                                <div className='Custom-error '>
                                                  {String(errorsOne?.interviewDateTime?.message)}
                                                </div>
                                              )}
                                          </>
                                        );
                                      }}
                                    />
                                  </Typography>
                                </Grid>
                                <Grid item md={4} sm={12} xs={12}>
                                  <Typography component={'div'} className='custom-field'>
                                    <Typography component={'p'}>
                                      Interview Duration <span>*</span>
                                    </Typography>
                                    <Controller
                                      render={({ field: { onChange, value, name } }) => (
                                        <CustomSelect
                                          labelKey={'label'}
                                          valueKey={'value'}
                                          options={interviewDuration}
                                          name={name}
                                          value={value}
                                          onChange={(e) => {
                                            onChange(e);
                                          }}
                                          placeHolder='Interview Duration'
                                          error={errorsOne.interviewDuration?.message}
                                        />
                                      )}
                                      name='interviewDuration'
                                      control={controlOne}
                                    />
                                  </Typography>
                                </Grid>
                              </Grid>
                              <Grid container columnSpacing={2}>
                                <Grid item md={4} sm={12} xs={12}>
                                  <Typography component={'div'} className='custom-field'>
                                    <Typography component={'p'}>
                                      Contact Person <span>*</span>
                                    </Typography>
                                    <Controller
                                      render={({ field: { onChange, value, name } }) => (
                                        <CustomInput
                                          value={value}
                                          name={name}
                                          placeHolder='Contact Person'
                                          className='custom-input'
                                          disabled={true}
                                          error={errorsOne.contactPerson?.message}
                                          onChange={(e) => {
                                            onChange(e);
                                          }}
                                        />
                                      )}
                                      name='contactPerson'
                                      control={controlOne}
                                    />
                                  </Typography>
                                </Grid>
                                <Grid item md={4} sm={12} xs={12}>
                                  <Typography component={'div'} className='custom-field'>
                                    <Typography component={'p'}>
                                      Contact No <span>*</span>
                                    </Typography>

                                    <Controller
                                      render={({ field: { onChange, value, name } }) => (
                                        <CustomInput
                                          value={value}
                                          name={name}
                                          type='number'
                                          disabled={true}
                                          placeHolder='Contact No'
                                          className='custom-input'
                                          error={errorsOne.contactNo?.message}
                                          onChange={(e) => {
                                            onChange(e);
                                          }}
                                        />
                                      )}
                                      name='contactNo'
                                      control={controlOne}
                                    />
                                  </Typography>
                                </Grid>
                                <Grid item md={4} sm={12} xs={12}>
                                  <Typography component={'div'} className='custom-field'>
                                    <Typography component={'p'}>Additional Info</Typography>

                                    <Controller
                                      render={({ field: { onChange, value, name } }) => (
                                        <CustomInput
                                          value={value}
                                          name={name}
                                          placeHolder='Additional Info'
                                          className='custom-input'
                                          error={errorsOne.additionalInfo?.message}
                                          onChange={(e) => {
                                            onChange(e);
                                          }}
                                        />
                                      )}
                                      name='additionalInfo'
                                      control={controlOne}
                                    />
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item md={12} sm={12} xs={12}>
                            <Typography className='bdiveder' />
                          </Grid>
                        </Grid>
                      </form>
                      <Typography className='d-flex-a flex-end mt-15'>
                        <Button className='l-cancel-btn mr-16' onClick={handleModalClose}>
                          Cancel
                        </Button>
                        <Button className='l-save-btn' form={'interview-form-One'} type={'submit'}>
                          {'Save and Continue'}
                        </Button>
                      </Typography>
                    </Typography>
                  </TabPanel>
                  <TabPanel value='2' sx={{ padding: '10px 0px' }}>
                    <Typography component={'div'}>
                      <form
                        className='private-form'
                        id={'interview-form-two'}
                        onSubmit={handleSubmitTwo(onSubmitTwo)}>
                        <Grid container>
                          <Grid item md={12} sm={12} xs={12}>
                            <Typography component={'div'} className='custom-field'>
                              <Typography component={'p'}>Client</Typography>
                              <Controller
                                render={({ field: { onChange, value, name } }) => (
                                  <CustomInput
                                    value={value}
                                    disabled
                                    name={name}
                                    placeHolder='Client'
                                    className='custom-input'
                                    error={errorsTwo.client?.message}
                                    onChange={(e) => {
                                      onChange(e);
                                    }}
                                  />
                                )}
                                name='client'
                                control={controlTwo}
                              />
                            </Typography>
                          </Grid>
                          <Grid item md={12} sm={12} xs={12}>
                            <Typography component={'div'} className='custom-field'>
                              <Typography component={'p'}>Subject</Typography>

                              <Controller
                                render={({ field: { onChange, value, name } }) => (
                                  <CustomInput
                                    value={value}
                                    name={name}
                                    placeHolder='Subject'
                                    className='custom-input'
                                    error={errorsTwo.subject?.message}
                                    onChange={(e) => {
                                      onChange(e);
                                    }}
                                  />
                                )}
                                name='subject'
                                control={controlTwo}
                              />
                            </Typography>
                          </Grid>
                          <Grid item md={12} sm={12} xs={12}>
                            <Typography component={'div'} className='custom-field'>
                              <Typography component={'p'}>
                                Message <span>*</span>
                              </Typography>
                              <>
                                <div
                                  className={
                                    errorsTwo && errorsTwo.message && errorsTwo.message.message
                                      ? 'ckeditor-config ckeditor-container'
                                      : 'ckeditor-container'
                                  }>
                                  <CKEditor
                                    editor={Editor}
                                    data={getValuesTwo('message')}
                                    onChange={handleEditorChangeTwo}
                                    // config={editorConfig}
                                  />
                                </div>
                                {errorsTwo && errorsTwo.message && errorsTwo.message.message && (
                                  <div className='Custom-error '>
                                    {String(errorsTwo.message.message)}
                                  </div>
                                )}
                              </>
                            </Typography>
                          </Grid>
                          <Grid item md={12} sm={12} xs={12}>
                            <Typography className='bdiveder' />
                          </Grid>
                        </Grid>
                      </form>
                      <Typography className='d-flex-a flex-end mt-15'>
                        <Button
                          className='l-cancel-btn mr-16'
                          onClick={() => {
                            setTabValue('1');
                          }}>
                          Cancel
                        </Button>
                        <Button className='l-save-btn' form={'interview-form-two'} type={'submit'}>
                          {'Save and Continue'}
                        </Button>
                      </Typography>
                    </Typography>
                  </TabPanel>
                  <TabPanel value='3' sx={{ padding: '10px 0px' }}>
                    <Typography component={'div'}>
                      <form
                        className='private-form'
                        id={'interview-form-three'}
                        onSubmit={handleSubmitThree(onSubmitThree)}>
                        <Grid container>
                          <Grid item md={12} sm={12} xs={12}>
                            <Typography component={'div'} className='custom-field'>
                              <Typography component={'p'}>Candidate(s)</Typography>
                              <Controller
                                render={({ field: { onChange, value, name } }) => (
                                  <CustomInput
                                    value={value}
                                    disabled
                                    name={name}
                                    placeHolder='Candidate(s)'
                                    className='custom-input'
                                    error={errorsThree.candidate?.message}
                                    onChange={(e) => {
                                      onChange(e);
                                    }}
                                  />
                                )}
                                name='candidate'
                                control={controlThree}
                              />
                            </Typography>
                          </Grid>
                          <Grid item md={12} sm={12} xs={12}>
                            <Typography component={'div'} className='custom-field'>
                              <Typography component={'p'}>Subject</Typography>

                              <Controller
                                render={({ field: { onChange, value, name } }) => (
                                  <CustomInput
                                    value={value}
                                    name={name}
                                    placeHolder='Subject'
                                    className='custom-input'
                                    error={errorsThree.subject?.message}
                                    onChange={(e) => {
                                      onChange(e);
                                    }}
                                  />
                                )}
                                name='subject'
                                control={controlThree}
                              />
                            </Typography>
                          </Grid>
                          <Grid item md={12} sm={12} xs={12}>
                            <Typography component={'div'} className='custom-field'>
                              <Typography component={'p'}>
                                Message <span>*</span>
                              </Typography>
                              <>
                                <div
                                  className={
                                    errorsThree &&
                                    errorsThree.message &&
                                    errorsThree.message.message
                                      ? 'ckeditor-config ckeditor-container'
                                      : 'ckeditor-container'
                                  }>
                                  <CKEditor
                                    editor={Editor}
                                    data={getValuesThree('message')}
                                    onChange={handleEditorChangeThree}
                                    // config={editorConfig}
                                  />
                                </div>
                                {errorsThree &&
                                  errorsThree.message &&
                                  errorsThree.message.message && (
                                    <div className='Custom-error '>
                                      {String(errorsThree.message.message)}
                                    </div>
                                  )}
                              </>
                            </Typography>
                          </Grid>
                          <Grid item md={12} sm={12} xs={12}>
                            <Typography className='bdiveder' />
                          </Grid>
                        </Grid>
                      </form>
                      <Typography className='d-flex-a flex-end mt-15'>
                        <Button
                          className='l-cancel-btn mr-16'
                          onClick={() => {
                            setTabValue('2');
                          }}>
                          Cancel
                        </Button>
                        <Button
                          className='l-save-btn'
                          form={'interview-form-three'}
                          type={'submit'}>
                          {'Save'}
                        </Button>
                      </Typography>
                    </Typography>
                  </TabPanel>
                </Typography>
              </TabContext>
            </Box>
          </Grid>
        </Box>
      </Modal>
      <Modal open={isRejectModalOpen} onClose={handleSelectClose} style={{ zIndex: '1' }}>
        <Box className='logout-modal p-32'>
          <Typography
            component={'div'}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginBottom: '1rem',
            }}>
            <Typography sx={{ fontSize: '20px', fontWeight: '600' }}>
              {getValuesSelect('status') === 'rejected'
                ? 'Interview Rejected'
                : 'Interview Selected'}
            </Typography>
            <Typography className='text-right'>
              <img
                src={CloseIcon}
                alt='close'
                style={{ cursor: 'pointer' }}
                onClick={handleSelectClose}
              />
            </Typography>
          </Typography>
          <form id='cv-reject' className='private-form' onSubmit={handleSubmitSelect(onSubmit)}>
            <Grid container>
              <Grid item md={12} sm={12} xs={12}>
                <Typography component={'div'} className='custom-field'>
                  <Typography component={'p'}>Candidate Name</Typography>
                  <CustomInput
                    disabled
                    value={candidateName}
                    placeHolder='Candidate Name'
                    className='custom-input'
                  />
                </Typography>
              </Grid>
              {getValuesSelect('status') === 'rejected' && (
                <Grid item md={12} sm={12} xs={12}>
                  <Typography component={'div'} className='custom-field'>
                    <Typography component={'p'}>
                      Reason <span>*</span>
                    </Typography>
                    <Controller
                      name='reason'
                      control={control}
                      render={({ field: { onChange, value, name } }) => (
                        <CustomSelect
                          labelKey={'label'}
                          valueKey={'value'}
                          options={interviewRejectReason}
                          name={name}
                          value={value}
                          onChange={(e) => {
                            onChange(e);
                          }}
                          placeHolder='Reason'
                          error={errors.reason?.message}
                        />
                      )}
                    />
                  </Typography>
                </Grid>
              )}
              <Grid item md={12} sm={12} xs={12}>
                <Typography component={'div'} className='custom-field'>
                  <Typography component={'p'}>Remarks</Typography>
                  <Controller
                    name='remarks'
                    control={control}
                    render={({ field: { onChange, value, name } }) => (
                      <CustomInput
                        name={name}
                        value={value}
                        isTextArea={true}
                        onChange={(e) => {
                          onChange(e);
                        }}
                        className='custom-input'
                        placeHolder='Remarks'
                        error={errors.remarks?.message}
                      />
                    )}
                  />
                </Typography>
              </Grid>
              {getValuesSelect('status') === 'approved' && (
                <Grid item md={12} sm={12} xs={12}>
                  <Typography component={'div'} className='custom-field'>
                    <Typography component={'p'}>
                      Next Interview <span>*</span>
                    </Typography>
                    <Controller
                      name='isApplicableForNextInterview'
                      control={control}
                      render={({ field: { onChange, value, name } }) => (
                        <RadioGroup
                          className='custom-radio'
                          row
                          aria-labelledby=''
                          name={name}
                          value={value}
                          onChange={(e) => {
                            onChange(e);
                            trigger('isApplicableForNextInterview');
                          }}>
                          <FormControlLabel value={true} control={<Radio />} label='Applicable' />
                          <FormControlLabel
                            value={false}
                            control={<Radio />}
                            label='Not Applicable'
                          />
                        </RadioGroup>
                      )}
                    />
                    {errors?.isApplicableForNextInterview &&
                      errors?.isApplicableForNextInterview.message && (
                        <div className='Custom-error '>
                          {String(errors?.isApplicableForNextInterview.message)}
                        </div>
                      )}
                  </Typography>
                </Grid>
              )}
              {isApplicableForNextInterview && (
                <Grid item md={12} sm={12} xs={12}>
                  <Typography
                    component={'p'}
                    className='custom-field'
                    style={{
                      border: '1px solid #D0D5DD',
                      borderRadius: '8px',
                      padding: '8px',
                      backgroundColor: '#FDEDE9',
                    }}>
                    This CV will now be moved to Offer Status
                  </Typography>
                </Grid>
              )}
              <Grid item md={12} sm={12} xs={12}>
                <Typography
                  component={'div'}
                  sx={{ justifyContent: 'flex-end' }}
                  className='d-flex-a'>
                  <Button className='cancel-btn mr-16' onClick={handleSelectClose}>
                    Cancel
                  </Button>
                  <Button form={'cv-reject'} className='s-add-btn' type='submit'>
                    Submit
                  </Button>
                </Typography>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Modal>
    </div>
  );
};

export default InterviewComponent;
