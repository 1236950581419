import React from 'react';

export const StatusTag: React.FC<{
  tag: string;
  color: string;
  backgroundColor?: string;
  curser?: string;
  userId?: string;
  crmUserId?: string;
  defaultPointer?: boolean;
  onClick?: (e: any) => void;
}> = ({ tag, color, backgroundColor, onClick, userId, crmUserId, defaultPointer }) => {
  return (
    <span
      style={{
        color,
        backgroundColor: backgroundColor || '#FFF2D0',
        paddingLeft: '8px',
        paddingRight: '8px',
        marginRight: '6px',
        fontSize: '14px',
        cursor: defaultPointer
          ? 'default'
          : userId === crmUserId && !['S', 'R', 'OA', 'OR', 'JD', 'NJ', 'J', 'B'].includes(tag)
            ? 'pointer'
            : 'not-allowed',
      }}
      onClick={onClick}>
      {tag}
    </span>
  );
};
