import { Grid, TextField, Typography } from '@mui/material';
import CustomSelect from 'components/CustomSelect/customSelect';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import {
  Control,
  Controller,
  FormState,
  UseFormHandleSubmit,
  UseFormSetValue,
  UseFormTrigger,
  UseFormWatch,
} from 'react-hook-form';
import { getAllListData } from 'services/CommonServices';
import { useAppSelector } from 'store/hooks';
import { ResumeFilterType } from 'types';
import { SETTING_ENDPOINT } from 'utils/Constant';
import { Resumetatus } from 'utils/helper';

interface ResumeFilterFormProps {
  control: Control<ResumeFilterType, any>;
  handleSubmit: UseFormHandleSubmit<ResumeFilterType, undefined>;
  formState: FormState<ResumeFilterType>;
  onSubmit: (data: ResumeFilterType) => void;
  watch: UseFormWatch<ResumeFilterType>;
  trigger: UseFormTrigger<ResumeFilterType>;
  setValue: UseFormSetValue<ResumeFilterType>;
  setParams: any;
}

const minExperience = Array.from({ length: 51 }, (_, index) => ({
  label: `${index} ${[0, 1].includes(index) ? 'Yr' : 'Yrs'}`,
  value: `${index}`,
}));

const maxExperience = Array.from({ length: 51 }, (_, index) => ({
  label: `${index} ${[0, 1].includes(index) ? 'Yr' : 'Yrs'}`,
  value: `${index}`,
}));

const ResumeFilterForm: React.FC<ResumeFilterFormProps> = ({
  control,
  handleSubmit,
  formState: { errors },
  onSubmit,
  trigger,
  watch,
}) => {
  const {
    userInfo: { organizationId },
    menuList,
  }: any = useAppSelector((store: any) => store.userLogin);

  const currentDate = moment().format('YYYY-MM-DD');
  const [fromDate, setFromDate] = useState<string | null>(null);
  const [toDate, setToDate] = useState<string | null>(null);
  // const [employee, setEmployee] = useState<any>();
  const [branchDetail, setBranchDetail] = useState<any>();
  const [interviewFromDate, setInterviewFromDate] = useState<string | null>(null);
  // const [interviewToDate, setInterviewToDate] = useState<string | null>(null);

  useEffect(() => {
    trigger('maxExperience');
    trigger('minExperience');
  }, [trigger, watch]);

  // const userData = () => {
  //   getAllListData(
  //     `${SETTING_ENDPOINT.users}/lite?organizationId=${organizationId}&roles=2,3,4,5${
  //       roleId !== 6 ? `&crmUserId=${userId}` : ''
  //     }&sortby=name&order=asc`,
  //     true,
  //   )
  //     .then((res: any) => {
  //       const options: any = [];
  //       res.data.map((e: any) => {
  //         options.push({ label: `${e.firstName} ${e.lastName}`, value: e.id });
  //       });
  //       setEmployee(options);
  //     })
  //     .catch((e: any) => {
  //       console.log(e);
  //     });
  // };
  const BranchData = () => {
    getAllListData(
      `${SETTING_ENDPOINT.Branch}?organizationId=${organizationId}&status=true&branchType=user`,
      true,
    )
      .then((res: any) => {
        const options: any = [];
        res.data.map((e: any) => {
          options.push({ label: e.name, value: e.id });
        });
        setBranchDetail(options);
      })
      .catch((e: any) => {
        console.log(e);
      });
  };
  useEffect(() => {
    // userData();
    BranchData();
  }, []);

  return (
    <form className='private-form clientfilter-form' onSubmit={handleSubmit(onSubmit)}>
      <Grid container>
        <Grid item md={12} sm={12} xs={12} sx={{ width: '100%' }}>
          <Grid container sx={{ width: '100%' }}>
            <Grid item md={12} sm={12} xs={12} sx={{ width: '100%' }}>
              <Typography component={'div'} className='custom-field' sx={{ width: '100%' }}>
                <Typography component={'p'}>From Date</Typography>
                <Controller
                  control={control}
                  defaultValue={undefined}
                  name='fromDate'
                  render={({ field: { onChange, value } }) => {
                    return (
                      <TextField
                        id='date'
                        type='date'
                        className='clientfilter-time-from-to'
                        value={value ? value : null}
                        onChange={(e) => {
                          setFromDate(moment(e.target.value).format('YYYY-MM-DD'));
                          onChange(e);
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        InputProps={{
                          inputProps: { max: toDate ?? currentDate },
                        }}
                      />
                    );
                  }}
                />
              </Typography>
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <Typography component={'div'} className='custom-field'>
                <Typography component={'p'}>To Date</Typography>
                <Controller
                  control={control}
                  defaultValue={undefined}
                  name='toDate'
                  render={({ field: { onChange, value } }) => {
                    return (
                      <TextField
                        id='date'
                        type='date'
                        className='clientfilter-time-from-to'
                        value={value ? value : null}
                        onChange={(e) => {
                          setToDate(moment(e.target.value).format('YYYY-MM-DD'));
                          onChange(e);
                        }}
                        // className={classes.textField}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        InputProps={{
                          inputProps: { min: fromDate, max: currentDate },
                        }}
                      />
                    );
                  }}
                />
              </Typography>
            </Grid>
            {/* {[3, 4, 5, 6].includes(roleId) && (
              <>
                <Grid item md={12} sm={12} xs={12}>
                  <Typography component={'div'} className='custom-field'>
                    <Typography component={'p'}>Employee</Typography>
                    <Controller
                      control={control}
                      name='employee'
                      defaultValue={undefined}
                      render={({ field: { onChange, value, name } }) => {
                        return (
                          <CustomSelect
                            labelKey={'label'}
                            valueKey={'value'}
                            options={employee}
                            isClearable={false}
                            name={name}
                            value={value ? value : null}
                            onChange={(e) => {
                              onChange(e);
                              watch('currentStatus') === 'draft' && userId !== e.target.value
                                ? setValue('currentStatus', '')
                                : '';
                            }}
                            placeHolder='Select Employee'
                          />
                        );
                      }}
                    />
                  </Typography>
                </Grid>
                <Grid item md={12} sm={12} xs={12}>
                  <Typography component={'div'} className='custom-field'>
                    <Typography component={'p'}>Branch</Typography>
                    <Controller
                      control={control}
                      name='branch'
                      defaultValue={undefined}
                      render={({ field: { onChange, value, name } }) => {
                        return (
                          <CustomSelect
                            labelKey={'label'}
                            valueKey={'value'}
                            options={branchDetail}
                            isClearable={false}
                            name={name}
                            value={value ? value : null}
                            onChange={(e) => {
                              onChange(e);
                            }}
                            placeHolder='Select Branch'
                          />
                        );
                      }}
                    />
                  </Typography>
                </Grid>
              </>
            )} */}
            <Grid item md={12} sm={12} xs={12}>
              <Typography component={'div'} className='custom-field'>
                <Typography component={'p'}>Branch</Typography>
                <Controller
                  control={control}
                  name='branch'
                  defaultValue={undefined}
                  render={({ field: { onChange, value, name } }) => {
                    return (
                      <CustomSelect
                        labelKey={'label'}
                        valueKey={'value'}
                        options={branchDetail}
                        isClearable={false}
                        name={name}
                        value={value ? value : null}
                        onChange={(e) => {
                          onChange(e);
                        }}
                        placeHolder='Select Branch'
                      />
                    );
                  }}
                />
              </Typography>
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <Typography component={'div'} className='custom-field'>
                <Typography component={'p'}>Current Status</Typography>
                <Controller
                  control={control}
                  name='currentStatus'
                  defaultValue={undefined}
                  render={({ field: { onChange, value, name } }) => {
                    const dropDownValue = menuList
                      ?.find((e: any) => e.moduleName === 'Resume')
                      ?.screens?.find((a: any) => a.screenName === 'Add Resume')
                      ? Resumetatus
                      : Resumetatus.filter((e: any) => e.value !== 'draft');
                    return (
                      <CustomSelect
                        labelKey={'label'}
                        valueKey={'value'}
                        isClearable={false}
                        options={dropDownValue}
                        name={name}
                        value={value ? value : null}
                        onChange={(e) => {
                          onChange(e);
                        }}
                        placeHolder='Select Status'
                      />
                    );
                  }}
                />
              </Typography>
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <Typography component={'div'} className='custom-field'>
                <Typography component={'p'}>Interview From</Typography>
                <Controller
                  control={control}
                  defaultValue={undefined}
                  name='interviewFrom'
                  render={({ field: { onChange, value } }) => {
                    return (
                      <TextField
                        id='date'
                        type='date'
                        className='clientfilter-time-from-to'
                        value={value ? value : null}
                        onChange={(e) => {
                          setInterviewFromDate(moment(e.target.value).format('YYYY-MM-DD'));
                          onChange(e);
                        }}
                        // className={classes.textField}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        // InputProps={{
                        //   inputProps: { max: interviewToDate ?? currentDate },
                        // }}
                      />
                    );
                  }}
                />
              </Typography>
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <Typography component={'div'} className='custom-field'>
                <Typography component={'p'}>Interview To</Typography>
                <Controller
                  control={control}
                  defaultValue={undefined}
                  name='interviewTo'
                  render={({ field: { onChange, value } }) => {
                    return (
                      <TextField
                        id='date'
                        type='date'
                        className='clientfilter-time-from-to'
                        value={value ? value : null}
                        onChange={(e) => {
                          // setInterviewToDate(moment(e.target.value).format('YYYY-MM-DD'));
                          onChange(e);
                        }}
                        // className={classes.textField}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        InputProps={{
                          inputProps: { min: interviewFromDate },
                        }}
                      />
                    );
                  }}
                />
              </Typography>
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <Typography component={'p'}>Experience</Typography>
              <Grid sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
                <Grid item md={6} sm={6} xs={6}>
                  <Typography component={'div'} className='custom-field'>
                    <Controller
                      control={control}
                      name='minExperience'
                      render={({ field: { onChange, value, name } }) => {
                        return (
                          <CustomSelect
                            labelKey={'label'}
                            valueKey={'value'}
                            isClearable={false}
                            options={minExperience}
                            name={name}
                            value={value ? value : null}
                            onChange={(e) => {
                              onChange(e);
                            }}
                            placeHolder='Min.'
                          />
                        );
                      }}
                    />
                  </Typography>
                </Grid>
                <Grid item md={6} sm={6} xs={6}>
                  <Typography component={'div'} className='custom-field'>
                    <Controller
                      control={control}
                      name='maxExperience'
                      render={({ field: { onChange, value, name } }) => {
                        return (
                          <CustomSelect
                            labelKey={'label'}
                            valueKey={'value'}
                            isClearable={false}
                            options={maxExperience}
                            name={name}
                            value={value ? value : null}
                            onChange={(e) => {
                              onChange(e);
                            }}
                            placeHolder='Max.'
                          />
                        );
                      }}
                    />
                  </Typography>
                </Grid>
              </Grid>
              {errors.minExperience?.message && (
                <Typography sx={{ color: 'red', fontSize: 12 }} component={'p'}>
                  {errors.minExperience.message}
                </Typography>
              )}
              {errors.maxExperience?.message && (
                <Typography sx={{ color: 'red', fontSize: 12 }} component={'p'}>
                  {errors.maxExperience.message}
                </Typography>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <button
        type='submit'
        className='clientfilter-form-btn'
        style={{
          ...(!(
            watch('branch') ||
            watch('currentStatus') ||
            watch('employee') ||
            watch('fromDate') ||
            watch('interviewFrom') ||
            watch('interviewTo') ||
            watch('maxExperience') ||
            watch('minExperience') ||
            watch('toDate')
          )
            ? { opacity: '0.5', cursor: 'not-allowed' }
            : {}),
        }}>
        Apply
      </button>
    </form>
  );
};

export default ResumeFilterForm;
