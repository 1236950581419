import { yupResolver } from '@hookform/resolvers/yup';
import { Breadcrumbs, Button, Grid, Link, TextField, Typography } from '@mui/material';
import HomeIcon from 'assets/png/dashboardactive.svg';
import ArrowIcon from 'assets/png/breadarrow.svg';
import { To, useNavigate, useParams } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { VersionHistorySchema } from 'utils/ValidatorSchema';
import { useEffect, useState } from 'react';
import { addNewData, getAllListData, updateData } from 'services/CommonServices';
import { AdminPrivateRoutes } from 'routes/routeConstans';
import CustomInput from 'components/CustomInput/customInput';
import Loader from 'components/loader';
import { useAppSelector } from 'store/hooks';
import { VERSIONHISTORYENDPOINT } from 'utils/Constant';
import _ from 'lodash';
import moment from 'moment';

export const VersionHistoryAdd = () => {
  const { editId } = useParams();
  const currentDate = moment().format('YYYY-MM-DD');
  const {
    userInfo: { organizationId: orgdy, roleId },
  }: any = useAppSelector((store: any) => store.userLogin);
  const navigate = useNavigate();
  const [Ld, setLd] = useState(false);
  const gotoNavigate = (link: To) => {
    navigate(link);
  };

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    getValues,
    reset: resetForm,
  } = useForm<any>({
    defaultValues: {
      organizationId: orgdy ? orgdy : '',
      versionName: '',
      releaseDate: '',
      description: '',
      type: '',
    },
    reValidateMode: 'onChange',
    resolver: yupResolver(VersionHistorySchema),
  });
  const onSubmit = async (data: {
    organizationId: string;
    versionName: string;
    releaseDate: string;
    description: string;
    type: string;
  }) => {
    setLd(true);
    if (!_.isUndefined(editId)) {
      updateData(
        editId,
        {
          //   organizationId: data.organizationId,
          versionId: data.versionName,
          //   releaseDate: data.releaseDate,
          releaseNotes: data.description,
          type: 'web',
          url: '',
          os: '',
        },
        `${String(VERSIONHISTORYENDPOINT.versionHistory)}`,
        true,
      )
        .then(() => {
          resetForm();
          navigate(`${AdminPrivateRoutes.VERSIONHISTORY.VERSIONHISTORYLIST}`);
          setLd(false);
        })
        .catch(() => {
          // console.log(e);
          setLd(false);
        });
    } else {
      addNewData(
        {
          organizationId: data.organizationId,
          versionId: data.versionName,
          //   releaseDate: data.releaseDate,
          releaseNotes: data.description,
          type: 'web',
          url: '',
          os: '',
        },
        `${String(VERSIONHISTORYENDPOINT.versionHistory)}`,
        undefined,
        true,
      )
        .then(() => {
          resetForm();
          navigate(`${AdminPrivateRoutes.VERSIONHISTORY.VERSIONHISTORYLIST}`);
          setLd(false);
        })
        .catch(() => {
          // console.log(e);
          setLd(false);
        });
    }
  };

  const getInitialStatus = () => {
    if (editId) {
      getAllListData(`${VERSIONHISTORYENDPOINT.versionHistory}?id=${editId}`, true)
        .then((resp: any) => {
          if (resp && !_.isEmpty(resp.data)) {
            setValue('versionName', resp.data[0].versionId);
            setValue('releaseDate', moment(resp.data[0].updatedAt).format('YYYY-MM-DD'));
            setValue('description', resp.data[0].releaseNotes);
          }
          setLd(false);
        })
        .catch(() => {
          setLd(false);
        });
    }
  };
  useEffect(() => {
    getInitialStatus();
    if (roleId != 15) {
      navigate('/');
    }
  }, []);

  return (
    <>
      <Grid container>
        <Grid item md={12} sm={12} xs={12}>
          <Typography component={'div'} className='breadcrumbs d-flex-a'>
            <Breadcrumbs aria-label='breadcrumb'>
              <Link underline='hover' color='inherit' href='/'>
                <img src={HomeIcon} className='home' alt='Home' />
              </Link>
              <Link
                underline='hover'
                color='inherit'
                href={''}
                onClick={() => gotoNavigate('/settings/functional-area')}>
                <img src={ArrowIcon} alt='arrow' className='arrows' />
                Version History
              </Link>
              <Typography color='text.primary'>
                {' '}
                <img src={ArrowIcon} alt='arrow' className='arrows' />
                {editId ? 'Edit' : 'Add'} Version History
              </Typography>
            </Breadcrumbs>
          </Typography>

          <Typography component={'div'} className='d-flex-a flex-sb title-add-box'>
            <Typography className='page-title'>
              {editId ? 'Edit' : 'Add'} Version History
            </Typography>
            <Typography component={'div'} className='d-flex-a'>
              <Button className='cancel-btn mr-16' onClick={() => gotoNavigate('/version-history')}>
                Cancel
              </Button>
              <Button className='s-add-btn' form={'version-form'} type='submit'>
                Save
              </Button>
            </Typography>
          </Typography>
          <Typography component={'div'} className='main-content'>
            <Typography component={'div'} className='white-box'>
              <form className='private-form' id={'version-form'} onSubmit={handleSubmit(onSubmit)}>
                <Grid container>
                  <Grid item md={8} sm={12} xs={12}>
                    <Grid container columnSpacing={3}>
                      <Grid item md={6} sm={12} xs={12}>
                        <Typography component={'div'} className='custom-field'>
                          <Typography component={'p'}>
                            Version Name <span>*</span>
                          </Typography>
                          <Controller
                            render={({ field: { onChange, value, name } }) => (
                              <CustomInput
                                value={value}
                                name={name}
                                placeHolder='Version Name'
                                className='custom-input'
                                error={errors.versionName?.message}
                                onChange={(e) => {
                                  onChange(e);
                                }}
                              />
                            )}
                            name='versionName'
                            control={control}
                          />
                        </Typography>
                      </Grid>
                      <Grid item md={6} sm={12} xs={12}>
                        <Typography component={'div'} className='custom-field'>
                          <Typography component={'p'}>
                            Release Date <span>*</span>
                          </Typography>
                          <Controller
                            render={({ field: { onChange, name } }) => (
                              <>
                                <TextField
                                  value={editId ? getValues('releaseDate') : currentDate}
                                  name={name}
                                  id='date'
                                  type='text'
                                  disabled
                                  placeholder={'Select Release Birth'}
                                  className={`clientfilter-time-from-to ${
                                    errors.releaseDate && errors.releaseDate.message
                                      ? 'clientfilter-time-from-to-error'
                                      : ''
                                  }`}
                                  onChange={(e) => {
                                    onChange(e);
                                  }}
                                  InputProps={{
                                    inputProps: {
                                      min: currentDate,
                                    },
                                  }}
                                />
                                {errors && errors.releaseDate && errors?.releaseDate?.message && (
                                  <div className='Custom-error '>
                                    {String(errors?.releaseDate?.message)}
                                  </div>
                                )}
                              </>
                            )}
                            name='releaseDate'
                            control={control}
                          />
                        </Typography>
                      </Grid>
                      <Grid item md={12} sm={12} xs={12}>
                        <Typography component={'div'} className='custom-field'>
                          <Typography component={'p'}>
                            Description <span>*</span>
                          </Typography>
                          <Controller
                            render={({ field: { onChange, value, name } }) => (
                              <CustomInput
                                value={value}
                                name={name}
                                isTextArea={true}
                                minRows={8}
                                className='custom-input'
                                placeHolder='Write your content here'
                                error={errors.description?.message}
                                onChange={(e) => {
                                  onChange(e);
                                }}
                              />
                            )}
                            name='description'
                            control={control}
                          />
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            </Typography>
          </Typography>
        </Grid>
      </Grid>
      {Ld && <Loader />}
    </>
  );
};
