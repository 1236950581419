import React from 'react';
import { Grid, Typography, Popover, Box, Button, Modal, TextField } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { JoiningStatusSchema } from 'utils/ValidatorSchema';
import { resumeStatusToBgColor } from 'utils/helper';
import Joined from 'assets/png/joined.svg';
import Deferred from 'assets/png/deferred.svg';
import NotJoined from 'assets/png/not-joined.svg';
import { JoiningStatusType } from 'types';
import CustomInput from 'components/CustomInput/customInput';
import CustomSelect from 'components/CustomSelect/customSelect';
import { joiningDeferred, offerrejectReason } from 'utils/Config';
import CloseIcon from 'assets/png/close.svg';
import { patchData } from 'services/CommonServices';
import { ResumesENDPOINT } from 'utils/Constant';
import { yupResolver } from '@hookform/resolvers/yup';
import { StatusTag } from './statusTag';
import moment from 'moment';

export const JoiningComponent: React.FC<{
  resumeId: string;
  candidateName: string;
  getList: () => void;
  offerStatus: string;
  userId: string;
  crmUserId: string;
}> = ({ offerStatus, candidateName, resumeId, getList, userId, crmUserId }) => {
  const [panchorEl, setPAnchorEl] = React.useState<HTMLAnchorElement | null>(null);
  const [isRejectModalOpen, setRejectModalOpen] = React.useState(false);
  const open = Boolean(panchorEl);
  const paid = open ? 'simple-popover' : undefined;
  const handleClose = () => {
    setPAnchorEl(null);
  };
  const handleModalClose = () => {
    reset();
    setRejectModalOpen(false);
  };
  const handleModalOpen = (status: string) => {
    setValue('status', status);
    setPAnchorEl(null);
    setRejectModalOpen(true);
  };
  const handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    setPAnchorEl(event.currentTarget);
  };

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
    getValues,
  } = useForm<JoiningStatusType>({
    resolver: yupResolver(JoiningStatusSchema),
  });

  const onSubmit = (data: JoiningStatusType) => {
    patchData(
      '',
      {
        ...data,
      },
      `${ResumesENDPOINT.resumeList}/joining-status/${resumeId}`,
      true,
    )
      .then(() => {
        handleModalClose();
        getList();
      })
      .catch((e: any) => console.log(e));
  };
  return (
    <div>
      <Popover
        id={paid}
        anchorEl={panchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        style={{ width: '100%', padding: '1rem' }}>
        <Typography component={'div'}>
          <Typography
            onClick={() => handleModalOpen('J')}
            className='menu-item'
            style={{ padding: '0px 15px 0px 15px' }}>
            <img src={Joined} alt='approve' />
            Joined
          </Typography>
          <Typography
            onClick={() => handleModalOpen('NJ')}
            className='menu-item'
            style={{ padding: '0px 15px 0px 15px' }}>
            <img src={Deferred} alt='reject' />
            Not Joined
          </Typography>
          <Typography
            onClick={() => handleModalOpen('JD')}
            className='menu-item'
            style={{ padding: '0px 15px 0px 15px' }}>
            <img src={NotJoined} alt='reject' />
            Deferred
          </Typography>
        </Typography>
      </Popover>
      <Typography component={'p'}>
        <StatusTag
          color='#4A5569'
          backgroundColor={resumeStatusToBgColor[offerStatus]}
          tag={offerStatus}
          userId={userId}
          crmUserId={crmUserId}
          onClick={(e: any) => {
            offerStatus === 'JA' ? userId === crmUserId && handleClick(e) : '';
          }}
        />
      </Typography>
      <Modal open={isRejectModalOpen} onClose={handleModalClose}>
        <Box className='logout-modal p-32'>
          <Typography
            component={'div'}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginBottom: '1rem',
            }}>
            <Typography sx={{ fontSize: '20px', fontWeight: '600' }}>
              {getValues('status') === 'J' && 'Candidate Joined'}
              {getValues('status') === 'NJ' && 'Candidate Not Joined'}
              {getValues('status') === 'JD' && 'Joining Deferred'}
            </Typography>
            <Typography className='text-right'>
              <img
                src={CloseIcon}
                alt='close'
                style={{ cursor: 'pointer' }}
                onClick={handleModalClose}
              />
            </Typography>
          </Typography>
          <form id='joining-status' className='private-form' onSubmit={handleSubmit(onSubmit)}>
            <Grid container>
              <Grid item md={12} sm={12} xs={12}>
                <Typography component={'div'} className='custom-field'>
                  <Typography component={'p'}>Candidate Name</Typography>
                  <CustomInput
                    disabled
                    value={candidateName}
                    placeHolder='Candidate Name'
                    className='custom-input'
                  />
                </Typography>
              </Grid>
              {['J', 'JD'].includes(getValues('status')) && (
                <Grid item md={12} sm={12} xs={12}>
                  <Typography component={'div'} className='custom-field'>
                    <Typography component={'p'}>
                      {getValues('status') === 'J' ? 'Joined On' : 'New Joining Date'}{' '}
                      <span>*</span>
                    </Typography>
                    <Controller
                      name='joinedDate'
                      control={control}
                      render={({ field: { onChange, value, name } }) => (
                        <>
                          <TextField
                            id='date'
                            type='date'
                            className='clientfilter-time-from-to'
                            defaultValue={value}
                            name={name}
                            onChange={onChange}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            InputProps={{
                              ...(getValues('status') === 'J'
                                ? { inputProps: {} }
                                : { inputProps: { min: moment().format('YYYY-MM-DD') } }),
                            }}
                          />
                          {errors && errors.joinedDate && errors?.joinedDate?.message && (
                            <div className='Custom-error '>
                              {String(errors?.joinedDate?.message)}
                            </div>
                          )}
                        </>
                      )}
                    />
                  </Typography>
                </Grid>
              )}
              {['NJ', 'JD'].includes(getValues('status')) && (
                <Grid item md={12} sm={12} xs={12}>
                  <Typography component={'div'} className='custom-field'>
                    <Typography component={'p'}>
                      Reason <span>*</span>
                    </Typography>
                    <Controller
                      name='reason'
                      control={control}
                      render={({ field: { onChange, value, name } }) => (
                        <CustomSelect
                          labelKey={'label'}
                          valueKey={'value'}
                          options={
                            getValues('status') === 'JD' ? joiningDeferred : offerrejectReason
                          }
                          name={name}
                          value={value}
                          onChange={(e) => {
                            onChange(e);
                          }}
                          placeHolder='Reason'
                          error={errors.reason?.message}
                        />
                      )}
                    />
                  </Typography>
                </Grid>
              )}
              <Grid item md={12} sm={12} xs={12}>
                <Typography component={'div'} className='custom-field'>
                  <Typography component={'p'}>Remarks</Typography>
                  <Controller
                    name='remarks'
                    control={control}
                    render={({ field: { onChange, value, name } }) => (
                      <CustomInput
                        name={name}
                        value={value}
                        isTextArea={true}
                        onChange={(e) => {
                          onChange(e);
                        }}
                        className='custom-input'
                        placeHolder='Remarks'
                        error={errors.remarks?.message}
                      />
                    )}
                  />
                </Typography>
              </Grid>
              <Grid item md={12} sm={12} xs={12}>
                <Typography
                  component={'div'}
                  sx={{ justifyContent: 'flex-end' }}
                  className='d-flex-a'>
                  <Button className='cancel-btn mr-16' onClick={handleModalClose}>
                    Cancel
                  </Button>
                  <Button form={'joining-status'} className='s-add-btn' type='submit'>
                    Submit
                  </Button>
                </Typography>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Modal>
    </div>
  );
};
