/* eslint-disable @typescript-eslint/no-unused-vars */
import { Grid, Checkbox, InputAdornment, Typography, Box, Switch } from '@mui/material';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import CustomInput from 'components/CustomInput/customInput';
import { useForm, Controller } from 'react-hook-form';
import { ClientDetailSchema, userFormSchema } from 'utils/ValidatorSchema';
import _ from 'lodash';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { ClientDetailType } from 'types';
import clientcorporatereducer, {
  storeclient,
  storeimage,
  storelogoid,
} from 'store/reducers/clientcorporatereducer';
import { toast } from 'react-toastify';
import ImageWithAuth from 'components/ProfileImage';
import { Loader } from 'components/loader';
import CustomSelect from 'components/CustomSelect/customSelect';
import { addNewData, getAllListData, getSingleData } from 'services/CommonServices';
import { ClientsENDPOINT, FILE_ENDPOINT, SETTING_ENDPOINT } from 'utils/Constant';
import { Country, State, City } from 'country-state-city';

const CheckIcon = styled('span')(({ theme }) => ({
  borderRadius: 4,
  width: 16,
  height: 16,
  outline: '1px solid #EE4B22',
  backgroundColor: theme.palette.mode === 'dark' ? '#fff' : '#fff',
  '.Mui-focusVisible &': {
    outline: '1px auto #EE4B22',
    outlineOffset: 1,
  },
  'input:hover ~ &': {
    backgroundColor: theme.palette.mode === 'dark' ? '#ffffff' : '#ffffff',
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background: theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
  },
}));

const CLIENTDETAILS = ({ setformview, formview, setFormfinish }: any) => {
  const [uploadImage, setUploadImage] = useState<any>({});
  const navigate = useNavigate();
  const [localImage, setLocalImage] = useState<any>('');
  const [nonlocalImage, setnonlocalImage] = useState<any>('');
  const imgref: any = useRef();
  const [searchParams]: any = useSearchParams();
  const [Ld, setLd] = useState(false);
  const [country, setCountry] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const {
    userInfo: { organizationId: orgdy },
  }: any = useAppSelector((store: any) => store.userLogin);
  const [industryDatas, setIndustryDatas] = useState<Array<{ label: string; value: string }>>([]);
  const {
    control: control,
    handleSubmit: handleSubmit,
    setValue,
    getValues,
    // setError,
    watch,
    formState: { errors },
  } = useForm<ClientDetailType>({
    criteriaMode: 'all',
    defaultValues: {
      corporateName: '',
      address1: '',
      address2: '',
      country: '',
      pincode: '',
      city: '',
      comments: '',
      state: '',
      logoId: '',
      status: true,
      industryId: '',
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(ClientDetailSchema),
  });

  function onSubmitForm(datas: any) {
    if (searchParams.get('mode') != 'edit' && !uploadImage) {
      setLd(false);
      toast.error('Company Logo is required', {
        autoClose: 3000,
        closeOnClick: true,
        draggable: true,
        hideProgressBar: false,
        pauseOnHover: true,
        position: 'top-right',
        progress: undefined,
      });
      return;
    }
    const client = {
      corporateName: datas.corporateName,
      industryId: datas.industryId,
      address1: datas.address1,
      address2: datas.address2,
      country: datas.country,
      pincode: datas.pincode,
      city: datas.city,
      state: datas.state,
      comments: datas.comments,
      status: datas.status,
      ...(searchParams.get('clientid')
        ? { id: searchParams.get('clientid') }
        : formview.clientId
          ? { id: formview.clientId }
          : {}),
    };
    const formdata: any = new FormData();
    formdata.append('clientTab', 'clientDetails');
    if (searchParams.get('mode') === 'edit') {
      formdata.append('clientId', searchParams.get('clientid') || formview.clientId);
    }
    formdata.append('client', JSON.stringify(client));
    formdata.append('file', uploadImage);
    // formdata.append('editReason', '');
    setLd(true);
    addNewData(
      formdata,
      `${ClientsENDPOINT.Client}`,
      {
        'Content-Type': 'multipart/form-data',
      },
      true,
    )
      .then((res) => {
        setformview((prev: any) => ({
          ...prev,
          crm: false,
          spoc: true,
          clientdetail: false,
          termsCondition: false,
          businessDetail: false,
          clientId: res && res?.id,
        }));
        setFormfinish((prev: any) => ({
          ...prev,
          crm: false,
          spoc: false,
          clientdetail: true,
          termsCondition: false,
          businessDetail: false,
        }));
        setLd(false);
      })
      .catch((e) => {
        if (e === 'Forbidden resource') {
          toast.error(e);
          navigate('/dashboard');
        }
        setLd(false);
        console.log(e);
      });

    setLd(false);
  }

  const industryData = () => {
    getAllListData(
      `${SETTING_ENDPOINT.Industry}?organizationId=${orgdy}&status=true&sortby=name&order=asc`,
      true,
    )
      .then((res) => {
        const options: Array<{ label: string; value: string }> = [];
        if (res.data.length) {
          res.data.forEach((e: any) => {
            options.push({ label: e.name, value: e.id });
          });
        }
        setIndustryDatas(options);
        setLd(false);
      })
      .catch(() => {
        setLd(false);
      });
  };

  useEffect(() => {
    getValues('logoId') ? setnonlocalImage(String(getValues('logoId'))) : '';
  }, [getValues('logoId')]);

  useEffect(() => {
    industryData();
    getInitialStatus();
  }, []);
  const getInitialStatus = async () => {
    if (searchParams.get('clientid') || formview.clientId) {
      setLd(true);
      await getSingleData(searchParams.get('clientid') || formview.clientId, ClientsENDPOINT.Client)
        .then((resp: any) => {
          const imageId = resp?.data[0]?.logoId;
          setValue('corporateName', String(resp?.data[0]?.corporateName) || '');
          setValue('address1', String(resp?.data[0]?.address1) || '');
          setValue('address2', resp?.data[0]?.address2 || '');
          setValue('country', resp?.data[0]?.country || '');
          setValue('pincode', resp?.data[0]?.pincode || '');
          setValue('city', resp?.data[0]?.city || '');
          setValue('comments', resp?.data[0]?.comments || '');
          setValue('state', resp?.data[0]?.state || '');
          setValue('status', resp?.data[0]?.status || '');
          setValue('industryId', resp?.data[0]?.industryId || '');
          setValue('logoId', resp?.data?.logoId || '');
          setFormfinish((prev: any) => ({
            ...prev,
            crm: resp?.data[0]?.clientsCrm.length ? true : false,
            spoc: resp?.data[0]?.clientsSpoc.length ? true : false,
            clientdetail: resp?.data[0]?.corporateName ? true : false,
            termsCondition: resp?.data[0]?.clientsAgreement.length ? true : false,
            businessDetail: resp?.data[0]?.clientsBusinessInfo.length ? true : false,
          }));
          if (imageId) {
            setLd(true);
            getSingleData('', `${FILE_ENDPOINT.files}/${imageId}`, true, {
              responseType: 'blob',
            }).then((fileRes: any) => {
              setUploadImage(URL.createObjectURL(fileRes));
              setnonlocalImage(URL.createObjectURL(fileRes));
              setLd(false);
            });
          }
          setLd(false);
        })
        .catch((e: any) => {
          if (e === 'Forbidden resource') {
            toast.error(e);
            navigate('/dashboard');
          }
          setLd(false);
          console.log(e);
        });
    }
  };

  useEffect(() => {
    if (watch('country')) {
      const options: any = [];
      State.getStatesOfCountry(watch('country')).map((state: any) => {
        options.push({ label: state.name, value: state.isoCode });
      });
      setStates(options);
    }
  }, [watch('country')]);

  useEffect(() => {
    if (watch('state')) {
      const options: any = [];
      City.getCitiesOfState(watch('country'), watch('state')).map((city: any) => {
        options.push({ label: city.name, value: city.name });
      });
      setCities(options);
    }
  }, [watch('country'), watch('state')]);

  useEffect(() => {
    const options: any = [];
    Country.getAllCountries().map((country) => {
      options.push({ label: country.name, value: country.isoCode });
    });
    setCountry(options);
  }, []);

  return (
    <Grid item md={12} sm={8} xs={8}>
      {Ld && <Loader />}
      <Typography component={'div'} className='form-box'>
        <Typography component={'div'} className='client-form-photo-area'>
          <Typography component={'p'} className='side-client-text'>
            Client Information
          </Typography>
          <Box className='main-box main-box-corporate-info'>
            <Typography component={'p'} className='client-img-container'>
              <div className='img-placeholder'>
                {localImage ? (
                  <img className='default-pic' src={localImage} />
                ) : _.isEmpty(localImage) && !_.isEmpty(nonlocalImage) ? (
                  <img className='default-pic' src={nonlocalImage} />
                ) : (
                  <span className='no-logo'>Logo</span>
                )}
              </div>
              <div className='img-info-con'>
                <div className='img-info-title'>
                  {' '}
                  <header>
                    Company Logo <span style={{ color: 'red' }}>*</span>
                  </header>
                  <span className='sub-info'>upload (.jpg, .jpeg, .png) file</span>
                </div>
                <div
                  className='img-click-btn'
                  onClick={() => imgref && imgref.current && imgref.current.click()}>
                  Upload Picture
                </div>
              </div>
              <input
                hidden
                type='file'
                accept='image/*'
                ref={imgref}
                onChange={(e: any) => {
                  setUploadImage(e?.target?.files[0]);
                  setLocalImage(URL.createObjectURL(e?.target?.files[0]));
                }}
              />
            </Typography>
            {searchParams.get('clientid') ? (
              <Typography className='status-text text-center'>
                Client Status
                <Controller
                  control={control}
                  defaultValue={true}
                  name='status'
                  render={({ field: { onChange, value, name } }) => {
                    return (
                      <Switch
                        value={value}
                        name={name}
                        // defaultChecked={true}
                        checked={value}
                        onChange={(e) => {
                          onChange(e);
                        }}
                        className='custom-switch'
                      />
                    );
                  }}
                />
              </Typography>
            ) : (
              <></>
            )}
          </Box>
        </Typography>
        <form className='private-form' id={'user-add'} onSubmit={handleSubmit(onSubmitForm)}>
          <Grid container columnSpacing={4.25}>
            <Grid item md={6} sm={12} xs={12}>
              <Typography component={'div'} className='custom-field'>
                <Typography component={'p'}>
                  Name <span>*</span>
                </Typography>
                <Controller
                  control={control}
                  defaultValue={undefined}
                  name='corporateName'
                  render={({ field: { onChange, value, name } }) => {
                    return (
                      <CustomInput
                        value={value}
                        name={name}
                        className='custom-input'
                        placeHolder='Enter Name'
                        error={errors.corporateName?.message}
                        onChange={(e) => {
                          onChange(e);
                        }}
                      />
                    );
                  }}
                />
              </Typography>
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <Typography component={'div'} className='custom-field'>
                <Typography component={'p'}>
                  Industry <span>*</span>
                </Typography>
                <Controller
                  control={control}
                  defaultValue={''}
                  name='industryId'
                  render={({ field: { onChange, value, name } }) => {
                    return (
                      <CustomSelect
                        labelKey={'label'}
                        valueKey={'value'}
                        options={
                          industryDatas.length ? industryDatas : [{ label: '', value: null }]
                        }
                        name={name}
                        value={value}
                        onChange={onChange}
                        placeHolder='Select'
                        error={errors?.industryId?.message}
                      />
                    );
                  }}
                />
              </Typography>
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <Typography component={'div'} className='custom-field'>
                <Typography component={'p'}>
                  Address 1 <span>*</span>
                </Typography>
                <Controller
                  control={control}
                  defaultValue={''}
                  name='address1'
                  render={({ field: { onChange, value, name } }) => {
                    return (
                      <CustomInput
                        value={value}
                        name={name}
                        className='custom-input'
                        placeHolder='Enter Address'
                        error={errors.address1?.message}
                        onChange={(e) => {
                          onChange(e);
                        }}
                      />
                    );
                  }}
                />
              </Typography>
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <Typography component={'div'} className='custom-field'>
                <Typography component={'p'}>Address 2</Typography>
                <Controller
                  control={control}
                  defaultValue={''}
                  name='address2'
                  render={({ field: { onChange, value, name } }) => {
                    return (
                      <CustomInput
                        value={value}
                        name={name}
                        className='custom-input'
                        placeHolder='Enter Address'
                        error={errors.address2?.message}
                        onChange={(e) => {
                          onChange(e);
                        }}
                      />
                    );
                  }}
                />
              </Typography>
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <Typography component={'div'} className='custom-field'>
                <Typography component={'p'}>
                  Country <span>*</span>
                </Typography>
                <Controller
                  control={control}
                  defaultValue={undefined}
                  name='country'
                  render={({ field: { onChange, value, name } }) => {
                    return (
                      <CustomSelect
                        labelKey={'label'}
                        valueKey={'value'}
                        options={country}
                        value={value}
                        name={name}
                        className='custom-input'
                        placeHolder='Country'
                        error={errors.country?.message}
                        onChange={(e) => {
                          onChange(e);
                          setValue('state', '');
                          setValue('city', '');
                        }}
                      />
                    );
                  }}
                />
              </Typography>
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <Typography component={'div'} className='custom-field'>
                <Typography component={'p'}>
                  State <span>*</span>
                </Typography>
                <Controller
                  control={control}
                  defaultValue={undefined}
                  name='state'
                  render={({ field: { onChange, value, name } }) => {
                    return (
                      <CustomSelect
                        labelKey={'label'}
                        valueKey={'value'}
                        options={states}
                        value={value ? value : null}
                        name={name}
                        className='custom-input'
                        placeHolder='State'
                        error={errors.state?.message}
                        onChange={(e) => {
                          onChange(e);
                          setValue('city', '');
                        }}
                      />
                    );
                  }}
                />
              </Typography>
            </Grid>

            <Grid item md={6} sm={12} xs={12}>
              <Typography component={'div'} className='custom-field'>
                <Typography component={'p'}>
                  City <span>*</span>
                </Typography>
                <Controller
                  control={control}
                  defaultValue={undefined}
                  name='city'
                  render={({ field: { onChange, value, name } }) => {
                    return (
                      <CustomSelect
                        labelKey={'label'}
                        valueKey={'value'}
                        options={cities}
                        value={value ? value : null}
                        name={name}
                        className='custom-input'
                        placeHolder='City'
                        error={errors.city?.message}
                        onChange={(e) => {
                          onChange(e);
                        }}
                      />
                    );
                  }}
                />
              </Typography>
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <Typography component={'div'} className='custom-field'>
                <Typography component={'p'}>
                  Pincode <span>*</span>
                </Typography>

                <Controller
                  control={control}
                  defaultValue={undefined}
                  name='pincode'
                  render={({ field: { onChange, value, name } }) => {
                    return (
                      <CustomInput
                        className='custom-input'
                        onChange={onChange}
                        value={value}
                        name={name}
                        placeHolder='Enter Pincode'
                        type={'number'}
                        error={errors.pincode?.message}
                      />
                    );
                  }}
                />
              </Typography>
            </Grid>

            <Grid item md={6} sm={12} xs={12} sx={{ display: 'none' }}>
              <Typography component={'div'} className='custom-field'>
                <Typography component={'p'}>
                  Country <span>*</span>
                </Typography>
                <Controller
                  control={control}
                  defaultValue={undefined}
                  name='country'
                  render={({ field: { onChange, value, name } }) => {
                    return (
                      <CustomInput
                        value={value}
                        name={name}
                        className='custom-input'
                        placeHolder='Country'
                        error={errors.country?.message}
                        onChange={(e) => {
                          onChange(e);
                        }}
                      />
                    );
                  }}
                />
              </Typography>
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <Typography component={'div'} className='custom-field'>
                <Typography component={'p'}>Comments</Typography>
                {/* <TextField className="custom-input" id="standard-basic"  multiline
                        rows={4} placeholder="write content here..." variant="standard" /> */}
                <Controller
                  render={({ field: { onChange, value, name } }) => (
                    <CustomInput
                      value={value}
                      name={name}
                      isTextArea={true}
                      className='custom-input'
                      placeHolder='Write your content here'
                      error={errors.comments?.message}
                      onChange={(e) => {
                        onChange(e);
                      }}
                    />
                  )}
                  name='comments'
                  control={control}
                />
              </Typography>
            </Grid>
          </Grid>
        </form>
      </Typography>
    </Grid>
  );
};

export default CLIENTDETAILS;
