import { Grid, TextField, Typography } from '@mui/material';
import CustomInput from 'components/CustomInput/customInput';
import CustomSelect from 'components/CustomSelect/customSelect';
import React, { useEffect, useState } from 'react';
import {
  Control,
  Controller,
  FormState,
  UseFormHandleSubmit,
  UseFormSetValue,
  UseFormTrigger,
  UseFormWatch,
} from 'react-hook-form';
import { getAllListData } from 'services/CommonServices';
import { useAppSelector } from 'store/hooks';
import { ClientFilterType } from 'types';
import { SETTING_ENDPOINT } from 'utils/Constant';
import { useLocation } from 'react-router-dom';

interface ClientFilterFormProps {
  control: Control<ClientFilterType, any>;
  handleSubmit: UseFormHandleSubmit<ClientFilterType, undefined>;
  formState: FormState<ClientFilterType>;
  onSubmit: (data: ClientFilterType) => void;
  watch: UseFormWatch<ClientFilterType>;
  trigger: UseFormTrigger<ClientFilterType>;
  setParams: any;
  setValue: UseFormSetValue<ClientFilterType>;
}

export const ClientFilterForm: React.FC<ClientFilterFormProps> = ({
  control,
  handleSubmit,
  formState: { errors },
  onSubmit,
  watch,
}) => {
  const location: any = useLocation();
  const {
    menuList,
    userInfo: { organizationId: orgdy },
  }: any = useAppSelector((store: any) => store.userLogin);
  const [Statusdropwn] = useState<any>([
    { label: 'Approved', value: 'approved' },
    ...(menuList
      ?.find((e: any) => e.moduleName === 'Client')
      ?.screens?.find((a: any) => a.screenName === 'Add Client')
      ? [{ label: 'Awaiting Approval', value: 'inReview' }]
      : []),
  ]);
  // const [crmdropwn, setcrmdropwn] = useState<any>([]);

  const currentDate = new Date();
  const day = String(currentDate.getDate()).padStart(2, '0');
  const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const year = currentDate.getFullYear();
  const [fromMaxDate, setFromMaxDate] = useState<string | null>(null);
  const [toMaxDate, setToMaxDate] = useState<string | null>(null);
  const [industry, setIndustry] = useState<any>();

  // const crmData = () => {
  //   getAllListData(
  //     `${SETTING_ENDPOINT.users}/lite?organizationId=${orgdy}&moduleId=1&screenId=1&status=true`,
  //     true,
  //   )
  //     .then((res: any) => {
  //       if (res && res.data && res.data.length > 0) {
  //         setcrmdropwn(
  //           res.data.map((li1: any) =>
  //             li1 && !_.isEmpty(li1) && li1.id
  //               ? {
  //                   value: li1.id,
  //                   label:
  //                     li1.firstName && li1.lastName
  //                       ? String(`${li1.firstName} ${li1.lastName}`)
  //                       : '',
  //                 }
  //               : '',
  //           ),
  //         );
  //       }
  //     })
  //     .catch(() => {
  //       // console.log(e);
  //     });
  // };
  const IndustryData = () => {
    getAllListData(`${SETTING_ENDPOINT.Industry}?organizationId=${orgdy}&status=true`, true)
      .then((res: any) => {
        if (res && res.data && res.data.length > 0) {
          const options: any = [];
          res.data.map((e: any) => {
            options.push({ label: e.name, value: e.id });
          });
          setIndustry(options);
        }
      })
      .catch(() => {
        // console.log(e);
      });
  };
  useEffect(() => {
    // crmData();
    IndustryData();
  }, []);

  return (
    <form className='private-form clientfilter-form' onSubmit={handleSubmit(onSubmit)}>
      <Grid container>
        <Grid item md={12} sm={12} xs={12} sx={{ width: '100%' }}>
          <Grid container sx={{ width: '100%' }}>
            <Grid item md={12} sm={12} xs={12} sx={{ width: '100%' }}>
              <Typography component={'div'} className='custom-field' sx={{ width: '100%' }}>
                <Typography component={'p'}>From Date</Typography>
                {/* <TextField className="custom-input" id="standard-basic" placeholder="" variant="standard" /> */}
                <Controller
                  control={control}
                  defaultValue={''}
                  name='from'
                  render={({ field: { onChange, value, name } }) => {
                    return (
                      <TextField
                        id='date'
                        type='date'
                        name={name}
                        value={value ? value : null}
                        className='clientfilter-time-from-to'
                        onChange={(e) => {
                          setFromMaxDate(e.target.value);
                          onChange(e);
                        }}
                        // className={classes.textField}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        InputProps={{
                          inputProps: { max: toMaxDate || `${year}-${month}-${day}` },
                        }}
                      />
                    );
                  }}
                />
              </Typography>
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <Typography component={'div'} className='custom-field'>
                <Typography component={'p'}>To Date</Typography>
                {/* <TextField className="custom-input" id="standard-basic" placeholder="" variant="standard" /> */}
                <Controller
                  control={control}
                  defaultValue={''}
                  name='to'
                  render={({ field: { onChange, value, name } }) => {
                    return (
                      <TextField
                        id='date'
                        type='date'
                        name={name}
                        value={value ? value : null}
                        className='clientfilter-time-from-to'
                        defaultValue={value}
                        onChange={(e) => {
                          setToMaxDate(e.target.value);
                          onChange(e);
                        }}
                        // className={classes.textField}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        InputProps={{
                          inputProps: { min: fromMaxDate, max: `${year}-${month}-${day}` },
                        }}
                      />
                    );
                  }}
                />
              </Typography>
            </Grid>
            {/* {[6].includes(roleId) && (
              <Grid item md={12} sm={12} xs={12}>
                <Typography component={'div'} className='custom-field'>
                  <Typography component={'p'}>Employee</Typography>
                  <Controller
                    control={control}
                    defaultValue={''}
                    name='crm'
                    render={({ field: { onChange, value, name } }) => {
                      return (
                        <CustomSelect
                          labelKey={'label'}
                          valueKey={'value'}
                          options={crmdropwn}
                          isClearable={false}
                          name={name}
                          value={value ? value : null}
                          onChange={(e) => {
                            onChange(e);
                            setValue('approvalStatus', '');
                          }}
                          placeHolder='Select Employee'
                          error={errors.crm?.message}
                        />
                      );
                    }}
                  />
                </Typography>
              </Grid>
            )} */}
            <Grid item md={12} sm={12} xs={12}>
              <Typography component={'div'} className='custom-field'>
                {/* <Typography component={'p'}>CRM</Typography> */}
                <Typography component={'p'}>Industry</Typography>
                {/* <TextField className="custom-input" id="standard-basic" placeholder="" variant="standard" /> */}
                <Controller
                  control={control}
                  defaultValue={''}
                  name='industry'
                  render={({ field: { onChange, value, name } }) => {
                    return (
                      <CustomSelect
                        labelKey={'label'}
                        valueKey={'value'}
                        options={industry}
                        isClearable={false}
                        name={name}
                        value={value ? value : null}
                        onChange={(e) => {
                          onChange(e);
                        }}
                        placeHolder='Select Industry'
                        error={errors.location?.message}
                      />
                    );
                  }}
                />
              </Typography>
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <Typography component={'div'} className='custom-field'>
                {/* <Typography component={'p'}>CRM</Typography> */}
                <Typography component={'p'}>Location</Typography>
                {/* <TextField className="custom-input" id="standard-basic" placeholder="" variant="standard" /> */}
                <Controller
                  control={control}
                  defaultValue={''}
                  name='location'
                  render={({ field: { onChange, value, name } }) => {
                    return (
                      <CustomInput
                        name={name}
                        value={value ? value : ''}
                        className='custom-input'
                        onChange={(e) => {
                          onChange(e);
                        }}
                        placeHolder='Select Location'
                        error={errors.location?.message}
                      />
                    );
                  }}
                />
              </Typography>
            </Grid>
            {!location.pathname.includes('approve') && (
              <>
                <Grid item md={12} sm={12} xs={12}>
                  <Typography component={'div'} className='custom-field'>
                    <Typography component={'p'}>Status</Typography>
                    {/* <TextField className="custom-input" id="standard-basic" placeholder="" variant="standard" /> */}
                    <Controller
                      control={control}
                      defaultValue={''}
                      name='status'
                      render={({ field: { onChange, value, name } }) => {
                        return (
                          <CustomSelect
                            labelKey={'label'}
                            valueKey={'value'}
                            isClearable={false}
                            options={[
                              { label: 'Active', value: 'active' },
                              { label: 'Inactive', value: 'inActive' },
                            ]}
                            name={name}
                            value={value ? value : null}
                            onChange={(e) => {
                              onChange(e);
                            }}
                            placeHolder='Select Status'
                            error={errors.status?.message}
                          />
                        );
                      }}
                    />
                  </Typography>
                </Grid>
                <Grid item md={12} sm={12} xs={12}>
                  <Typography component={'div'} className='custom-field'>
                    <Typography component={'p'}>Approval Status</Typography>
                    {/* <TextField className="custom-input" id="standard-basic" placeholder="" variant="standard" /> */}
                    <Controller
                      control={control}
                      defaultValue={''}
                      name='approvalStatus'
                      render={({ field: { onChange, value, name } }) => {
                        // const dropDownValue =
                        //   watch('crm') && watch('crm') !== userId
                        //     ? Statusdropwn.filter((e: any) => e.value === 'approved')
                        //     : Statusdropwn;
                        return (
                          <CustomSelect
                            labelKey={'label'}
                            valueKey={'value'}
                            options={Statusdropwn}
                            isClearable={false}
                            name={name}
                            value={value ? value : null}
                            onChange={(e) => {
                              onChange(e);
                            }}
                            placeHolder='Select Status'
                            error={errors.approvalStatus?.message}
                          />
                        );
                      }}
                    />
                  </Typography>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </Grid>

      <button
        type='submit'
        className='clientfilter-form-btn'
        style={{
          ...(!(
            watch('approvalStatus') ||
            // watch('crm') ||
            watch('from') ||
            watch('industry') ||
            watch('location') ||
            watch('status') ||
            watch('to')
          )
            ? { opacity: '0.5', cursor: 'not-allowed' }
            : {}),
        }}>
        Apply
      </button>
    </form>
  );
};
