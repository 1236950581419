import { Box, Modal, Typography, Grid, Button, TextField } from '@mui/material';
import CloseIcon from 'assets/png/close.svg';
import { yupResolver } from '@hookform/resolvers/yup';
import CustomInput from 'components/CustomInput/customInput';
import { Controller, useForm } from 'react-hook-form';
import { CreateInvoiceSchema } from 'utils/ValidatorSchema';
import { addNewData, updateData } from 'services/CommonServices';
import { BillingENDPOINT } from 'utils/Constant';
import { useEffect } from 'react';

export const CreateInvoice = ({
  open,
  setInvoiceModalOpen,
  setLd,
  ids,
  getList,
  invoiveData,
}: any) => {
  const {
    control: control,
    handleSubmit,
    setValue,
    // getValues,
    reset,
    formState: { errors },
  } = useForm<any>({
    criteriaMode: 'all',
    defaultValues: {
      invoiceNo: '',
      invoiceDate: '',
      professionalFees: '',
      tax: '',
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(CreateInvoiceSchema),
  });
  const handleClose = () => {
    setInvoiceModalOpen((prev: any) => ({
      ...prev,
      open: false,
      data: [],
    }));
    reset();
  };
  const onSubmitForm = (data: any) => {
    const obj: any = {
      resumeId: ids.resumeId,
      billingId: ids.billingId,
      invoiceNo: data.invoiceNo,
      invoiceDate: data.invoiceDate,
      professionalFees: data.professionalFees,
      tax: data.tax,
      status: true,
    };
    if (data.id) {
      updateData(data.id, obj, `${BillingENDPOINT.billingInvoice}`, true)
        .then(() => {
          handleClose();
          setLd(false);
          getList();
        })
        .catch((e: any) => {
          setLd(false);
          console.log(e);
        });
    } else {
      addNewData(obj, `${BillingENDPOINT.billingInvoice}`, {}, true)
        .then(() => {
          handleClose();
          setLd(false);
          getList();
        })
        .catch((e: any) => {
          setLd(false);
          console.log(e);
        });
    }
  };
  useEffect(() => {
    if (invoiveData) {
      setValue('invoiceNo', invoiveData?.invoiceNo);
      setValue('invoiceDate', invoiveData?.invoiceDate);
      setValue('professionalFees', invoiveData?.professionalFees);
      setValue('tax', invoiveData?.tax);
      setValue('id', invoiveData?.id);
    }
  }, [invoiveData]);

  return (
    <Modal
      sx={{ zIndex: 11000 }}
      open={open}
      onClose={handleClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'>
      <Box
        className='email-modal'
        sx={{ width: '865px !important', minHeight: '400px !important' }}>
        <Typography className='d-flex flex-sb '>
          <Typography className='l-title'>Create Invoice</Typography>
          <img src={CloseIcon} alt='close' style={{ cursor: 'pointer' }} onClick={handleClose} />
        </Typography>
        <Typography className='bdiveder' />
        <div>
          <Grid container>
            <Grid item md={12} sm={12} xs={12}>
              <Typography component={'div'} className='custom-field'>
                <form
                  className='private-form'
                  id={'payment-add'}
                  onSubmit={handleSubmit(onSubmitForm)}>
                  <div style={{ height: '10px' }}></div>
                  <Grid container>
                    <Grid item md={12} sm={12} xs={12}>
                      <Grid container columnSpacing={3}>
                        <Grid item md={6} sm={12} xs={12}>
                          <Typography component={'div'} className='custom-field'>
                            <Typography component={'p'}>
                              Invoice No<span>*</span>
                            </Typography>
                            <Controller
                              control={control}
                              defaultValue={undefined}
                              name='invoiceNo'
                              render={({ field: { onChange, value, name } }) => {
                                return (
                                  <CustomInput
                                    value={value}
                                    name={name}
                                    className='custom-input'
                                    placeHolder='Invoice No'
                                    error={errors.invoiceNo?.message}
                                    onChange={(e) => {
                                      onChange(e);
                                    }}
                                  />
                                );
                              }}
                            />
                          </Typography>
                        </Grid>
                        <Grid item md={6} sm={12} xs={12}>
                          <Typography component={'div'} className='custom-field'>
                            <Typography component={'p'}>
                              Invoice Date <span>*</span>
                            </Typography>
                            <Controller
                              control={control}
                              name={'invoiceDate'}
                              render={({ field: { onChange, value, name } }) => {
                                return (
                                  <>
                                    <TextField
                                      value={value}
                                      name={name}
                                      id='date'
                                      type='date'
                                      placeholder={'Invoice Date'}
                                      className={`clientfilter-time-from-to ${
                                        errors.invoiceDate && errors.invoiceDate.message
                                          ? 'clientfilter-time-from-to-error'
                                          : ''
                                      }`}
                                      onChange={(e) => {
                                        onChange(e);
                                      }}
                                    />
                                    {errors &&
                                      errors.invoiceDate &&
                                      errors?.invoiceDate?.message && (
                                        <div className='Custom-error '>
                                          {String(errors?.invoiceDate?.message)}
                                        </div>
                                      )}
                                  </>
                                );
                              }}
                            />
                          </Typography>
                        </Grid>
                        <Grid item md={6} sm={12} xs={12}>
                          <Typography component={'div'} className='custom-field'>
                            <Typography component={'p'}>
                              Professional Fees<span>*</span>
                            </Typography>
                            <Controller
                              control={control}
                              defaultValue={undefined}
                              name='professionalFees'
                              render={({ field: { onChange, value, name } }) => {
                                return (
                                  <CustomInput
                                    value={value}
                                    type='number'
                                    name={name}
                                    className='custom-input'
                                    placeHolder='Professional Fees'
                                    error={errors.professionalFees?.message}
                                    onChange={(e) => {
                                      onChange(e);
                                    }}
                                  />
                                );
                              }}
                            />
                          </Typography>
                        </Grid>
                        <Grid item md={6} sm={12} xs={12}>
                          <Typography component={'div'} className='custom-field'>
                            <Typography component={'p'}>Tax</Typography>
                            <Controller
                              control={control}
                              defaultValue={undefined}
                              name='tax'
                              render={({ field: { onChange, value, name } }) => {
                                return (
                                  <CustomInput
                                    value={value}
                                    name={name}
                                    type='number'
                                    className='custom-input'
                                    placeHolder='Tax'
                                    error={errors.tax?.message}
                                    onChange={(e) => {
                                      onChange(e);
                                    }}
                                  />
                                );
                              }}
                            />
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </form>
              </Typography>
            </Grid>
          </Grid>
          <Typography className='bdiveder' />
          <Typography className='d-flex flex-end  mt-25'>
            <Button className='l-cancel-btn mr-16' onClick={handleClose}>
              Cancel
            </Button>
            <Button className='l-save-btn' type='submit' form={'payment-add'}>
              Submit
            </Button>
          </Typography>
        </div>
      </Box>
    </Modal>
  );
};
