import React from 'react';
import { Box, Drawer, Typography } from '@mui/material';
import Close from 'assets/png/Close-drawer.svg';

interface ResumePreviewDrawerProps {
  imageUrl: string;
  toggleDrawer: (open: boolean) => void;
  drawerOpen: boolean;
}

export const ResumePreviewDrawer: React.FC<ResumePreviewDrawerProps> = ({
  imageUrl,
  drawerOpen,
  toggleDrawer,
}) => {
  return (
    <Drawer anchor={'right'} open={drawerOpen} onClose={() => toggleDrawer(false)} sx={{zIndex:1206,}}>
      <Box sx={{ width: '50vw', height: '100vh', overflowY: 'hidden' }}>
        <Typography sx={{ height: '100%' }}>
          <Typography
            sx={{
              width: '100%',
              height: '56px',
              backgroundColor: '#F2F4F7',
              display: 'flex',
              alignItems: 'center',
              paddingLeft: '32px',
              paddingRight: '25px',
              justifyContent: 'space-between',
            }}>
            <Typography sx={{ fontWeight: '600', fontSize: '20px' }}>Candidate Resume</Typography>
            <Typography
              sx={{
                width: '20px',
                height: '20px',
                cursor: 'pointer',
              }}
              onClick={() => toggleDrawer(false)}>
              <img src={Close} alt='close icon' />
            </Typography>
          </Typography>
          <iframe
            // frameBorder={'0'}
            style={{ height: 'calc(100% - 56px)', width: '100%', border: 'none' }}
            src={`data:application/pdf;base64,${imageUrl}`}
          />
        </Typography>
      </Box>
    </Drawer>
  );
};
