import React, { Fragment, useEffect, useState } from 'react';
import HomeIcon from 'assets/png/dashboardactive.svg';
import ArrowIcon from 'assets/png/breadarrow.svg';
import {
  Breadcrumbs,
  Grid,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { To, useNavigate, useSearchParams } from 'react-router-dom';
import { AdminPrivateRoutes } from 'routes/routeConstans';
import { getAllListData } from 'services/CommonServices';
import { ResumesENDPOINT } from 'utils/Constant';
import Loader from 'components/loader';
import moment from 'moment';
import {
  AccessDenied,
  InterviewLevel,
  InterviewLevelToText,
  currentResumeStage,
  currentResumeStatus,
} from 'utils/helper';
import { toast } from 'react-toastify';

export const InterviewView = () => {
  const [commonState, setcommonstate] = useState<any[]>([]);
  const [interviewData, setInterviewData] = useState<any[]>([]);
  const [isLoading, setLoading] = useState(true);
  const navigate = useNavigate();
  const gotoNavigate = (link: To) => {
    navigate(link);
  };

  const [searchParams] = useSearchParams();

  useEffect(() => {
    getAllListData(
      `${ResumesENDPOINT.resumeList}?id=${searchParams.get(
        'id',
      )}&type=interview,offer,joining,billing&screen=interview`,
      true,
    )
      .then((val: any) => {
        if (val && val.data && val.data.length > 0) {
          const intervieData = val.data[0].interview;
          intervieData.forEach((r: any, idx: number) =>
            r.rescheduleReason
              ? ((intervieData[idx + 1].status = 'Scheduled'),
                (intervieData[idx].status = 'Selected'))
              : !intervieData[idx].status
                ? (intervieData[idx].status = 'Selected')
                : {},
          );
          setcommonstate([...val.data]);
          setInterviewData(intervieData);
        } else {
          setcommonstate([]);
        }
        setLoading(!true);
      })
      .catch((e) => {
        setLoading(!true);
        if (e === 'Forbidden resource') {
          toast.error(AccessDenied);
          navigate('/dashboard');
        }
      });
  }, [searchParams.get('id')]);

  return (
    <Grid container>
      {isLoading && <Loader />}
      <Grid item md={12} sm={12} xs={12}>
        <Typography component={'div'} className='breadcrumbs d-flex-a'>
          <Breadcrumbs aria-label='breadcrumb'>
            <Link underline='hover' href='/'>
              <img src={HomeIcon} className='home' alt='Home' />
            </Link>
            <Typography
              color='text.primary'
              sx={{ cursor: 'pointer' }}
              onClick={() => gotoNavigate(AdminPrivateRoutes.INTERVIEW.INTERVIEWLIST)}>
              {' '}
              <img src={ArrowIcon} alt='arrow' className='arrows' /> Interviews
            </Typography>
            <Typography color='text.primary' sx={{ cursor: 'pointer' }}>
              {' '}
              <img src={ArrowIcon} alt='arrow' className='arrows' /> View Interview Schedule
            </Typography>
          </Breadcrumbs>
        </Typography>

        <Typography
          component={'div'}
          className='d-flex-a flex-sb title-add-box'
          sx={{ paddingRight: '56px' }}>
          <Typography className='page-title'>View Interview Schedule</Typography>
        </Typography>

        <Typography component={'div'} className='main-content'>
          <Typography component={'div'} className='white-box'>
            <Typography component={'div'} sx={{ marginBottom: '5rem' }}>
              {commonState.map(
                ({
                  personal,
                  position,
                  id,
                  client: { corporateName },
                  user,
                  interview,
                  resumeStatus,
                  createdAt,
                }) => {
                  const candidateName = personal
                    ? `${personal.firstName} ${personal.lastName || ''}`
                    : '-';
                  const positionName = position.title || '-';
                  const company = corporateName || '-';

                  const recruiter = user ? `${user.firstName} ${user.lastName || ''}` : '-';
                  const interviewDate = interview?.length
                    ? moment(interview[0].dateTime).format('DD-MM-YYYY')
                    : '-';
                  const createdAtDate = createdAt ? moment(createdAt).format('DD-MM-YYYY') : '-';
                  const interviewLevel = currentResumeStage(resumeStatus) || '-';
                  const status = currentResumeStatus(resumeStatus) || '-';
                  return (
                    <Fragment key={id}>
                      <Grid container>
                        <Grid item md={3} sm={12} xs={12}>
                          <Typography component={'p'} sx={{ fontWeight: '600' }}>
                            Candidate Name
                          </Typography>
                          <Typography
                            component={'p'}
                            sx={{
                              fontWeight: '400',
                              fontSize: '14px',
                              marginTop: '8px',
                              textTransform: 'capitalize',
                            }}>
                            {candidateName}
                          </Typography>
                        </Grid>
                        <Grid item md={3} sm={12} xs={12}>
                          <Typography component={'p'} sx={{ fontWeight: '600' }}>
                            Position
                          </Typography>
                          <Typography
                            component={'p'}
                            sx={{
                              fontWeight: '400',
                              fontSize: '14px',
                              marginTop: '8px',
                              textTransform: 'capitalize',
                            }}>
                            {positionName}
                          </Typography>
                        </Grid>
                        <Grid item md={3} sm={12} xs={12}>
                          <Typography component={'p'} sx={{ fontWeight: '600' }}>
                            Company
                          </Typography>
                          <Typography
                            component={'p'}
                            sx={{
                              fontWeight: '400',
                              fontSize: '14px',
                              marginTop: '8px',
                              textTransform: 'capitalize',
                            }}>
                            {company}
                          </Typography>
                        </Grid>
                        <Grid item md={3} sm={12} xs={12}>
                          <Typography component={'p'} sx={{ fontWeight: '600' }}>
                            Interview Date
                          </Typography>
                          <Typography
                            component={'p'}
                            sx={{ fontWeight: '400', fontSize: '14px', marginTop: '8px' }}>
                            {interviewDate}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid container sx={{ marginTop: '3rem' }}>
                        <Grid item md={3} sm={12} xs={12}>
                          <Typography component={'p'} sx={{ fontWeight: '600' }}>
                            Current Stage
                          </Typography>
                          <Typography
                            component={'p'}
                            sx={{ fontWeight: '400', fontSize: '14px', marginTop: '8px' }}>
                            {interviewLevel}
                          </Typography>
                        </Grid>
                        <Grid item md={3} sm={12} xs={12}>
                          <Typography component={'p'} sx={{ fontWeight: '600' }}>
                            Current Status
                          </Typography>
                          <Typography
                            component={'p'}
                            sx={{ fontWeight: '400', fontSize: '14px', marginTop: '8px' }}>
                            {status}
                          </Typography>
                        </Grid>
                        <Grid item md={3} sm={12} xs={12}>
                          <Typography component={'p'} sx={{ fontWeight: '600' }}>
                            Recruiter
                          </Typography>
                          <Typography
                            component={'p'}
                            sx={{
                              fontWeight: '400',
                              fontSize: '14px',
                              marginTop: '8px',
                              textTransform: 'capitalize',
                            }}>
                            {recruiter}
                          </Typography>
                        </Grid>
                        <Grid item md={3} sm={12} xs={12}>
                          <Typography component={'p'} sx={{ fontWeight: '600' }}>
                            Created Date
                          </Typography>
                          <Typography
                            component={'p'}
                            sx={{ fontWeight: '400', fontSize: '14px', marginTop: '8px' }}>
                            {createdAtDate}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Fragment>
                  );
                },
              )}
              {commonState.length === 0 && !isLoading && (
                <Typography
                  sx={{ width: '100%', textAlign: 'center', fontWeight: 600, fontSize: '2.5rme' }}>
                  Interview Schedule not found
                </Typography>
              )}
            </Typography>

            <Typography
              component={'div'}
              sx={{ height: '1px', width: '100%', backgroundColor: '#D9D9D9' }}
            />
            <TableContainer component={Paper} sx={{ marginTop: '1.2rem' }} className='sb-table2'>
              <Table
                sx={{ overflowX: 'auto', minWidth: 650 }}
                stickyHeader
                className='custom-table custom-table-client'
                aria-label='sticky Header'>
                <TableHead>
                  <TableRow
                    sx={{
                      marginLeft: '50px',
                      marginRight: '43px',

                      position: 'relative',
                    }}>
                    <TableCell sx={{ whiteSpace: 'nowrap' }}>Interview Date</TableCell>
                    <TableCell sx={{ whiteSpace: 'nowrap' }}>Stage</TableCell>
                    <TableCell sx={{ whiteSpace: 'nowrap' }}>Status</TableCell>
                    <TableCell sx={{ whiteSpace: 'nowrap' }}>Remarks</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {interviewData?.map(
                    ({ dateTime, level, id, remarks, rescheduleReason, status }, i) => {
                      const interviewDate = dateTime ? moment(dateTime).format('DD-MM-YYYY') : '-';
                      const interviewLevel = level
                        ? InterviewLevelToText[level as InterviewLevel]
                        : '';

                      const interviewStatus =
                        i === 0
                          ? commonState[0]?.resumeStatus?.find((e: any) => e.type === 'interview')
                              ?.status === 'S'
                            ? 'Selected'
                            : commonState[0]?.resumeStatus?.find((e: any) => e.type === 'interview')
                                  ?.status === 'R'
                              ? 'Rejected'
                              : rescheduleReason
                                ? 'Re-Scheduled'
                                : 'Scheduled'
                          : status;
                      return (
                        <TableRow key={id}>
                          <TableCell sx={{ whiteSpace: 'nowrap', textTransform: 'capitalize' }}>
                            {interviewDate}
                          </TableCell>
                          <TableCell sx={{ whiteSpace: 'nowrap', textTransform: 'capitalize' }}>
                            {interviewLevel}
                          </TableCell>
                          <TableCell sx={{ whiteSpace: 'nowrap', textTransform: 'capitalize' }}>
                            {interviewStatus}
                          </TableCell>
                          <TableCell sx={{ whiteSpace: 'nowrap', textTransform: 'capitalize' }}>
                            {remarks || '-'}
                          </TableCell>
                        </TableRow>
                      );
                    },
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Typography>
        </Typography>
      </Grid>
    </Grid>
  );
};
