import {
  Breadcrumbs,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import HomeIcon from 'assets/png/dashboardactive.svg';
import ArrowIcon from 'assets/png/breadarrow.svg';
import { useEffect, useRef, useState } from 'react';
import SearchIcon from 'assets/png/nsearch.svg';
import CloseIcon from '@mui/icons-material/Close';
import Funnelimg from 'assets/png/FilterFunnel.svg';
import Paper from '@mui/material/Paper';
import UP from 'assets/png/up.svg';
import DOWN from 'assets/png/down.svg';
import Paginate from 'components/Pagination/Pagination';
import NCAdedIcon from 'assets/iconcomponents/noclientsadded';
import moment from 'moment';
import ThreedotIcon from 'assets/png/threedot.svg';
import View from 'assets/png/View-leave.svg';
import ViewDrawer from '../ViewDrawer';
import { To, useNavigate } from 'react-router-dom';
import { AdminPrivateRoutes } from 'routes/routeConstans';
import LeaveModal from '../LeaveModal';
import { useDebounce } from 'hooks';
import { getAllListData } from 'services/CommonServices';
import { LEAVE_ENDPOINT } from 'utils/Constant';
import { useAppSelector } from 'store/hooks';
import Loader from 'components/loader';
import PreIcon from 'assets/png/pre.svg';
import TableTooltip from 'components/Tooltip/Tabletooltip';
import { LeaveFilterDrawer } from '../LeaveFilter/LeaveFilterDrawer';
import { toast } from 'react-toastify';
import { AccessDenied } from 'utils/helper';

const Defaultparam = {
  search: null,
  order: null,
  skip: 0,
  sortby: null,
  take: 50,
  fromDate: null,
  toDate: null,
  leaveTypeId: null,
};

const AppoveLeaveDetails = () => {
  const navigate = useNavigate();
  const [params, setParams] = useState<any>({ ...Defaultparam });
  const [pagetotal, setPageTotal] = useState<number>(0);
  const [leaveData, setLeaveData] = useState<any>([]);
  const [panchorEl, setPAnchorEl] = useState<HTMLAnchorElement | null>(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [listLeaveData, setListLeaveData] = useState<any>([]);
  const [title, setTitle] = useState('');
  const debouncedParams = useDebounce(params, 500);
  const [loader, setLoader] = useState<boolean>(true);
  const [rowData, setRowData] = useState<any>({});
  const open = Boolean(panchorEl);
  const paid = open ? 'simple-popover' : undefined;
  const [drawerOpen, setdDrawerOpen] = useState(false);
  const [dweropen, setdweropen] = useState(false);
  const dweropn = () => setdweropen(true);
  const dwerclose = () => setdweropen(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const containerRef = useRef(null) as any;
  const { userInfo }: any = useAppSelector((store: any) => store.userLogin);

  const getLeaveData = () => {
    setLoader(true);
    const searcharr = [
      `${userInfo.organizationId ? `organizationId=${userInfo.organizationId}` : null}`,
      `${debouncedParams.search ? `search=${encodeURIComponent(debouncedParams.search)}` : null}`,
      `${debouncedParams.fromDate ? `from=${debouncedParams.fromDate}` : null}`,
      `${debouncedParams.toDate ? `to=${debouncedParams.toDate}` : null}`,
      `${debouncedParams.leaveTypeId ? `typeId=${debouncedParams.leaveTypeId}` : null}`,
      `${debouncedParams.order ? `order=${debouncedParams.order}` : null}`,
      `${debouncedParams.sortby ? `sortby=${debouncedParams.sortby}` : null}`,
      `${
        debouncedParams.skip
          ? `skip=${debouncedParams.skip}`
          : debouncedParams.take
            ? 'skip=0'
            : null
      }`,
      `${debouncedParams.take ? `take=${debouncedParams.take}` : null}`,
      'screenType=approve',
    ]
      .filter((ea: any) => ea !== 'null')
      .join('&');
    getAllListData(
      `${LEAVE_ENDPOINT.leaves}${Object.values({ ...params }).length > 0 && '?'}${searcharr}`,
      true,
    )
      .then((leaveData) => {
        setListLeaveData(leaveData.data);
        setPageTotal(leaveData.total);
        setLoader(false);
      })
      .catch((e) => {
        if (e === 'Forbidden resource') {
          toast.error(AccessDenied);
          navigate('/dashboard');
        }
        setLoader(false);
      });
  };
  const handleScroll = (scrollAmount: number) => {
    // Calculate the new scroll position
    const newScrollPosition = scrollPosition + scrollAmount;
    // Update the state with the new scroll position
    if (newScrollPosition >= 0 && newScrollPosition <= 800) {
      setScrollPosition(newScrollPosition);
      const element = containerRef.current;
      // Access the container element and set its scrollLeft property
      element.scrollLeft = newScrollPosition;
    }
  };
  const sortablecliks = (sor: string, ord: string) => {
    setParams((prev: any) => ({
      ...prev,
      sortby: sor ? sor : null,
      order: ord ? ord : null,
    }));
  };

  const handlePag = (sk: number, tk: number) => {
    setPageTotal(50); // for testing
    setParams((prev: any) => ({
      ...prev,
      skip: sk,
      take: tk,
    }));
  };
  function handleFilterPag(filteredobj1: any) {
    setParams((prev: any) => ({
      ...prev,
      ...filteredobj1,
    }));
  }
  const handleClick = (event: React.MouseEvent<HTMLAnchorElement>, row?: any) => {
    setRowData(row);
    setLeaveData([
      {
        key: 'Leave From',
        value: moment(row.fromDate).format('DD-MM-YYYY'),
      },
      {
        key: 'Leave To',
        value: moment(row.toDate).format('DD-MM-YYYY'),
      },
      {
        key: 'Leave Type',
        value: row?.policy?.type?.type,
      },
      {
        key: 'Session',
        value: row?.isFullDayLeave
          ? 'Full Day'
          : `${row?.sessionFrom === 1 ? 'First Half' : 'Second Half'} - ${
              row?.sessionTo === 2 ? 'Second Half' : 'First Half'
            }`,
      },
      {
        key: 'Reason',
        value: row?.note,
      },
      {
        key: 'Created By',
        value: `${row?.user?.firstName} ${row?.user?.lastName}`,
      },
      {
        key: 'Created On',
        value: moment(row?.createdAt).format('DD-MM-YYYY'),
      },
      {
        key: 'status',
        value: row?.status,
      },
    ]);
    setPAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    // setLeaveData({});
    setPAnchorEl(null);
  };

  const toggleDrawer = (open: boolean) => {
    setdDrawerOpen(open);
  };

  const modalOpenFn = (open: boolean) => {
    setModalOpen(open);
  };

  const gotoNavigate = (link: To) => {
    navigate(link);
  };

  useEffect(() => {
    getLeaveData();
  }, [debouncedParams]);

  return (
    <>
      {loader && <Loader />}
      <Grid container>
        <Grid item md={12} sm={12} xs={12}>
          <Typography component={'div'} className='breadcrumbs d-flex-a'>
            <Breadcrumbs aria-label='breadcrumb'>
              <Link underline='hover' color='inherit' href='/'>
                <img src={HomeIcon} className='home' alt='Home' />
              </Link>
              <Link
                underline='hover'
                color='inherit'
                href={AdminPrivateRoutes.MyLeave.ViewList}
                style={{ cursor: 'pointer' }}>
                <img src={ArrowIcon} alt='arrow' className='arrows' />
                Leaves
              </Link>
              <Typography color='text.primary' sx={{ cursor: 'pointer' }}>
                {' '}
                <img src={ArrowIcon} alt='arrow' className='arrows' />
                Approve Leave
              </Typography>
            </Breadcrumbs>
          </Typography>

          <Typography
            component={'div'}
            className='d-flex-a flex-sb title-add-box'
            sx={{ paddingRight: '56px' }}>
            <Typography className='page-title'>Leaves</Typography>
            <Typography
              component={'div'}
              style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <div style={{ width: '16px' }}></div>
              <Typography component={'div'} className='d-flex-a'>
                <Button
                  className='go-to-clnt-list-btn'
                  type='submit'
                  form={'user-add'}
                  sx={{ textTransform: 'none !important' }}
                  onClick={() => gotoNavigate(AdminPrivateRoutes.MyLeave.ViewList)}>
                  Go to Leave List
                </Button>
              </Typography>
            </Typography>
          </Typography>
          <Typography component={'div'} className='main-content'>
            <Typography component={'div'} className='white-box'>
              <Typography component={'div'} className='d-flex-cc flex-sb'>
                <TextField
                  className='custom-search'
                  placeholder='Search'
                  id='outlined-start-adornment'
                  value={params.search}
                  sx={{ width: '320px' }}
                  onChange={(e: any) =>
                    setParams((prev: any) => ({
                      ...prev,
                      search: e.target.value,
                      skip: 0,
                      take: 50,
                    }))
                  }
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <img src={SearchIcon} alt='search' />
                      </InputAdornment>
                    ),
                    endAdornment: params.search ? (
                      <InputAdornment position='end'>
                        <IconButton
                          aria-label='toggle password visibility'
                          edge='end'
                          onClick={() => {
                            setParams(() => ({ ...Defaultparam, search: '' }));
                          }}>
                          <CloseIcon style={{ height: '20px', width: '20px' }} />
                        </IconButton>
                      </InputAdornment>
                    ) : null,
                  }}
                />
                <Typography
                  component={'div'}
                  className='d-flex-cc flex-sb d-flex-nwrp filterfunnel-con'
                  onClick={dweropn}>
                  <img src={Funnelimg} alt='filter funnel icon' />
                  <span className='filterfunnel' />
                </Typography>
              </Typography>
              <Typography component={'div'} className='mt-32' sx={{ position: 'relative' }}>
                <Button onClick={() => handleScroll(-200)} className='pre-btn'>
                  <img src={PreIcon} alt='' />
                </Button>
                <Button onClick={() => handleScroll(200)} className='next-btn'>
                  <img src={PreIcon} alt='' style={{ transform: 'rotate(180deg)' }} />
                </Button>
                <TableContainer component={Paper} className='sb-table2' ref={containerRef}>
                  <Table
                    sx={{ minWidth: 650 }}
                    stickyHeader
                    className='custom-table custom-table-client'
                    aria-label='sticky Header'>
                    <TableHead>
                      <TableRow
                        sx={{
                          marginLeft: '50px',
                          marginRight: '43px',
                          // width: 'calc(100% - 93px)',
                          // position: 'relative',
                        }}>
                        <TableCell sx={{ whiteSpace: 'nowrap' }}>
                          Name
                          <span className='sorting'>
                            <img
                              src={UP}
                              alt='asc'
                              className='up'
                              onClick={() => sortablecliks('name', 'asc')}
                            />
                            <img
                              src={DOWN}
                              alt='desc'
                              className='down'
                              onClick={() => sortablecliks('name', 'desc')}
                            />
                          </span>
                        </TableCell>
                        <TableCell sx={{ whiteSpace: 'nowrap' }}>
                          Leave From
                          <span className='sorting'>
                            <img
                              src={UP}
                              alt='asc'
                              className='up'
                              onClick={() => sortablecliks('fromDate', 'asc')}
                            />
                            <img
                              src={DOWN}
                              alt='desc'
                              className='down'
                              onClick={() => sortablecliks('fromDate', 'desc')}
                            />
                          </span>
                        </TableCell>
                        <TableCell sx={{ whiteSpace: 'nowrap' }}>
                          Leave To
                          <span className='sorting'>
                            <img
                              src={UP}
                              alt='asc'
                              className='up'
                              onClick={() => sortablecliks('toDate', 'asc')}
                            />
                            <img
                              src={DOWN}
                              alt='desc'
                              className='down'
                              onClick={() => sortablecliks('toDate', 'desc')}
                            />
                          </span>
                        </TableCell>
                        <TableCell sx={{ whiteSpace: 'nowrap' }}>
                          Leave Type
                          <span className='sorting'>
                            <img
                              src={UP}
                              alt='asc'
                              className='up'
                              onClick={() => sortablecliks('type', 'asc')}
                            />
                            <img
                              src={DOWN}
                              alt='desc'
                              className='down'
                              onClick={() => sortablecliks('type', 'desc')}
                            />
                          </span>
                        </TableCell>
                        <TableCell sx={{ whiteSpace: 'nowrap' }}>Session</TableCell>
                        <TableCell sx={{ whiteSpace: 'nowrap' }}>
                          Reason
                          <span className='sorting'>
                            <img
                              src={UP}
                              alt='asc'
                              className='up'
                              onClick={() => sortablecliks('note', 'asc')}
                            />
                            <img
                              src={DOWN}
                              alt='desc'
                              className='down'
                              onClick={() => sortablecliks('note', 'desc')}
                            />
                          </span>
                        </TableCell>
                        <TableCell align='center' sx={{ whiteSpace: 'nowrap' }}>
                          Status
                          <span className='sorting'>
                            <img
                              src={UP}
                              alt='asc'
                              className='up'
                              onClick={() => sortablecliks('status', 'asc')}
                            />
                            <img
                              src={DOWN}
                              alt='desc'
                              className='down'
                              onClick={() => sortablecliks('status', 'desc')}
                            />
                          </span>
                        </TableCell>
                        <TableCell align='center' sx={{ whiteSpace: 'nowrap' }}>
                          Created
                          <span className='sorting'>
                            <img
                              src={UP}
                              alt='asc'
                              className='up'
                              onClick={() => sortablecliks('createdAt', 'asc')}
                            />
                            <img
                              src={DOWN}
                              alt='desc'
                              className='down'
                              onClick={() => sortablecliks('createdAt', 'desc')}
                            />
                          </span>
                        </TableCell>
                        <TableCell sx={{ whiteSpace: 'nowrap' }}>Actions</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {listLeaveData && listLeaveData.length > 0 ? (
                        listLeaveData.map((row: any) => {
                          return (
                            <TableRow
                              key={row.id}
                              sx={{
                                '&:last-child td, &:last-child th': { border: 0 },
                                marginLeft: '50px',
                                marginRight: '43px',
                                width: 'calc(100% - 93px)',
                              }}>
                              <TableCell
                                sx={{
                                  minWidth: '140px',
                                  textTransform: 'capitalize',
                                }}>
                                {`${row?.user?.firstName} ${row?.user?.lastName}`.length > 9 ? (
                                  <TableTooltip
                                    value={
                                      row?.user?.firstName
                                        ? `${row?.user?.firstName} ${row?.user?.lastName}`
                                        : '-'
                                    }
                                  />
                                ) : row?.user?.firstName ? (
                                  `${row?.user?.firstName} ${row?.user?.lastName}`
                                ) : (
                                  '-'
                                )}
                              </TableCell>
                              <TableCell
                                sx={{
                                  minWidth: '180px',
                                }}>
                                {row?.fromDate ? moment(row.fromDate).format('DD-MM-YYYY') : '-'}
                              </TableCell>
                              <TableCell
                                sx={{
                                  minWidth: '180px',
                                }}>
                                {row?.toDate ? moment(row.toDate).format('DD-MM-YYYY') : '-'}
                              </TableCell>
                              <TableCell
                                sx={{
                                  textTransform: 'capitalize',
                                  minWidth: '180px',
                                }}>
                                {row?.policy?.type?.type ? row?.policy?.type?.type : '-'}
                              </TableCell>
                              <TableCell
                                sx={{
                                  textTransform: 'capitalize',
                                  minWidth: '150px',
                                }}>
                                {row?.isFullDayLeave
                                  ? 'Full Day'
                                  : `${row?.sessionFrom === 1 ? 'First Half' : 'Second Half'} - ${
                                      row?.sessionTo === 2 ? 'Second Half' : 'First Half'
                                    }`}
                              </TableCell>
                              <TableCell
                                sx={{
                                  textTransform: 'capitalize',
                                  minWidth: '180px',
                                }}>
                                {row?.note ? row.note : '-'}
                              </TableCell>
                              <TableCell
                                align='center'
                                className='client-status-edit-icon-colum'
                                sx={{ textTransform: 'capitalize' }}>
                                <div className='innercontainer'>
                                  <Typography
                                    className={'leave-status-batch'}
                                    sx={{
                                      width: '135px !important',
                                      fontSize: '10px !important',
                                      color:
                                        row.status === 'pending'
                                          ? '#D9920A'
                                          : row?.status === 'rejected'
                                            ? '#A0290F'
                                            : row.status === 'approved'
                                              ? '#31AA27'
                                              : '#4A5569',
                                      background:
                                        row.status === 'pending'
                                          ? '#FFF0D2'
                                          : row?.status === 'rejected'
                                            ? '#FFE4DE'
                                            : row.status === 'approved'
                                              ? '#EBF6EB'
                                              : '#DEE4EC',
                                    }}>
                                    {row.status === 'pending'
                                      ? 'Awaiting Approval'
                                      : row.status === 'approved'
                                        ? row?.status
                                        : row?.status}
                                  </Typography>
                                </div>
                              </TableCell>
                              <TableCell
                                align='center'
                                sx={{
                                  textTransform: 'capitalize',
                                  minWidth: '180px',
                                }}>
                                {row?.createdAt ? moment(row.createdAt).format('DD-MM-YYYY') : '-'}
                              </TableCell>

                              <TableCell align='center'>
                                <img
                                  src={ThreedotIcon}
                                  alt='ThreedotIcon'
                                  onClick={(e: any) => handleClick(e, row)}
                                />
                              </TableCell>
                            </TableRow>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan={14} align={'center'}>
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginBottom: '43px',
                              }}>
                              <NCAdedIcon />
                              <Typography className='no-client'>No Record(s) found</Typography>
                            </div>
                          </td>
                        </tr>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Typography>
              <Paginate total={pagetotal} setfn={handlePag} DefaultPerPage={50} />
            </Typography>
          </Typography>

          <Popover
            className='leave-profile-drop'
            id={paid}
            anchorEl={panchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}>
            <Typography
              className='leave-menu-item'
              onClick={() => (toggleDrawer(true), handleClose())}>
              <img src={View} alt='view-leave' />
              View Leave
            </Typography>
          </Popover>

          <ViewDrawer
            toggleDrawer={toggleDrawer}
            drawerOpen={drawerOpen}
            data={leaveData}
            viewType={'approve'}
            modalOpen={modalOpenFn}
            setTitle={setTitle}
          />
          <LeaveFilterDrawer
            setParams={setParams}
            isOpen={dweropen}
            closeDrawer={dwerclose}
            callback={handleFilterPag}
            setBillingData={setListLeaveData}
            setLoading={setLoader}
            setpagetotal={setPageTotal}
            listdataapi={getLeaveData}
          />
          <LeaveModal
            getLeaveData={getLeaveData}
            toggleDrawer={toggleDrawer}
            open={modalOpen}
            modalOpen={modalOpenFn}
            title={title}
            rowData={rowData}
            setRowData={setRowData}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default AppoveLeaveDetails;
