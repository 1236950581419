import React from 'react';
import { Provider } from 'react-redux';
import { store } from 'store';
import './style/style.scss';
import PreApp from 'PreApp';

function App() {
  return (
    <Provider store={store}>
      <PreApp />
    </Provider>
  );
}

export default App;
