/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useRef, useState } from 'react';
import BackImg from 'assets/png/interior.png';
import CTLogo from 'assets/png/cthiring_new_logo.png';
import { Button, FormControlLabel, Grid, TextField, Typography } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { ForgotpasswordSchema } from 'utils/ValidatorSchema';
import CustomInput from 'components/CustomInput/customInput';
import { addNewData } from 'services/CommonServices';
import Loader from 'components/loader';
import { useNavigate } from 'react-router-dom';

const ForgotPassword = () => {
  const subtgref: React.MutableRefObject<any> = useRef();
  const [Ld, setLd] = useState(false);
  const navigate = useNavigate();
  const {
    handleSubmit,
    control,
    reset: resetForm,
    formState: { errors },
    setValue,
    watch,
  } = useForm<{
    email: any;
  }>({
    defaultValues: {
      email: '',
    },
    reValidateMode: 'onChange',
    resolver: yupResolver(ForgotpasswordSchema),
  });
  const onSubmitForm = async (data: any) => {
    setLd(true);
    try {
      const updatedpsd = await addNewData(data, 'password/forgot', null, true);
      // console.log(updatedpsd, 'updated');
      navigate('/login');
    } catch (error) {
      // console.log(error);
    } finally {
      setLd(false);
      resetForm();
    }
  };
  return (
    <>
      <Typography
        component={'div'}
        sx={{ backgroundImage: `url(${BackImg})` }}
        className='login-banner'>
        <Grid container className='px-40'>
          <Grid item md={6} sm={12} xs={12} className=''>
            <Typography component={'div'} className='pl-15'>
              <Typography className='mb-36 mt-5r'>
                <img src={CTLogo} alt='Carrier Tree' />
              </Typography>
              <Typography className='banner-text'>
                We have the <span>capabilities</span> and <span>experience</span> to navigate you in
                <span>finding solutions</span> you need to move forward
              </Typography>
            </Typography>
          </Grid>
          <Grid item md={6} sm={12} xs={12}>
            <Typography component={'div'} className='login-form d-flex-ja'>
              <Typography component={'div'} className='login-box for-log-box'>
                <Typography className='for-text'>Forgot Password</Typography>
                <Typography className='for-dis' style={{ marginBottom: '32px' }}>
                  A verification link will be sent to your registered email address.
                </Typography>
                <form onSubmit={handleSubmit(onSubmitForm)}>
                  <Typography component={'div'} className='custom-field'>
                    <Typography component={'p'}>
                      Email <span style={{ color: 'red' }}>*</span>
                    </Typography>
                    {/* <TextField className="custom-input" id="standard-basic" placeholder="Enter your email" variant="standard" /> */}
                    <Controller
                      control={control}
                      name='email'
                      render={({ field: { onChange, value, name } }) => {
                        return (
                          <CustomInput
                            value={value}
                            name={name}
                            className='custom-input'
                            id='standard-basic'
                            // variant='standard'
                            placeHolder='Enter your email'
                            error={errors.email?.message}
                            onChange={(e) => {
                              onChange(e);
                            }}
                            autoFocus={true}
                          />
                        );
                      }}
                    />
                  </Typography>
                  <Typography className='d-flex-ja' flexDirection='column'>
                    <Button
                      className='submit-btn'
                      sx={{ marginTop: '8px !important', width: '100% !important' }}
                      type='submit'>
                      Submit
                    </Button>
                    <Typography className='back-text' onClick={() => navigate('/login')}>
                      Back to Sign In
                    </Typography>
                  </Typography>
                </form>
              </Typography>
              {/* <Typography className="w-100 text-left foot-text">© CT - Hiring 2023</Typography> */}
            </Typography>
          </Grid>
        </Grid>
      </Typography>
      {Ld && <Loader />}
    </>
  );
};

export default ForgotPassword;
