/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/display-name */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import 'react-phone-input-2/lib/material.css';

import { FormHelperText, Typography } from '@mui/material';
import React, { ReactElement } from 'react';
import PhoneInput from 'react-phone-input-2';

type InputProps = {
  initialCountry: string;
  value?: string;
  onlyCountries?: any;
  preferredCountries?: any;
  excludeCountries?: any;
  placeholder: string;
  inputProps?: any;
  autoFormat?: boolean;
  disabled?: boolean;
  disableDropdown?: boolean;
  disableCountryCode?: boolean;
  enableAreaCodes?: boolean;
  enableTerritories?: boolean;
  enableLongNumbers?: boolean;
  countryCodeEditable?: boolean;
  enableSearch?: boolean;
  disableSearchIcon?: boolean;
  containerClass?: string;
  inputClass?: string;
  buttonClass?: string;
  dropdownClass?: string;
  searchClass?: string;
  containerStyle?: any;
  inputStyle?: any;
  buttonStyle?: any;
  dropdownStyle?: any;
  searchStyle?: any;
  onChange?: (value: any, data: any, event: any, formattedValue: any) => void;
  onFocus?: (src: any) => void;
  onBlur?: (src: any) => void;
  onClick?: (src: any) => void;
  onKeyDown?: (src: any) => void;
  countryData?: any;
  regions?: any;
  masks?: any;
  areaCodes?: any;
  defaultMask?: string;
  alwaysDefaultMask?: false;
  prefix?: string;
  searchPlaceholder?: string;
  searchNotFound?: string;
  copyNumbersOnly?: boolean;
  renderStringAsFlag?: string;
  autocompleteSearch?: boolean;
  jumpCursorToEnd?: boolean;
  priority?: any;
  enableClickOutside?: boolean;
  showDropdown?: boolean;
  defaultErrorMessage?: boolean;
  specialLabel?: string;
  disableInitialCountryGuess?: boolean;
  disableCountryGuess?: boolean;
  error?: any;
};

const CustomPhoneInput = React.forwardRef((props: InputProps, ref: any): ReactElement => {
  const {
    initialCountry,
    value,
    onlyCountries,
    preferredCountries,
    excludeCountries,
    placeholder,
    inputProps = {},
    autoFormat = true,
    disabled = false,
    disableDropdown = false,
    disableCountryCode = false,
    enableAreaCodes = false,
    enableTerritories = false,
    enableLongNumbers = false,
    countryCodeEditable = true,
    enableSearch = false,
    disableSearchIcon = false,
    containerClass,
    inputClass,
    buttonClass,
    dropdownClass,
    searchClass,
    containerStyle = {},
    inputStyle = {},
    buttonStyle = {},
    dropdownStyle = {},
    searchStyle = {},
    onChange,
    onFocus,
    onBlur,
    onClick,
    onKeyDown,
    countryData,
    regions,
    masks,
    areaCodes,
    defaultMask = '... ... ... ... ..',
    alwaysDefaultMask = false,
    prefix = '+',
    searchPlaceholder = 'Search',
    searchNotFound = 'No entries to show',
    copyNumbersOnly = true,
    renderStringAsFlag,
    autocompleteSearch = false,
    jumpCursorToEnd = false,
    priority,
    enableClickOutside = true,
    showDropdown = false,
    defaultErrorMessage,
    specialLabel,
    disableInitialCountryGuess = false,
    disableCountryGuess = false,
    error,
  } = props;

  return (
    <Typography component='div' className='phone-input-custom'>
      <PhoneInput
        placeholder={placeholder}
        containerClass={containerClass}
        inputClass={inputClass}
        country={initialCountry}
        specialLabel={specialLabel}
        searchClass={searchClass}
        value={value}
        enableSearch={enableSearch}
        inputStyle={inputStyle}
        inputProps={inputProps}
        autoFormat={autoFormat}
        disabled={disabled}
        disableDropdown={disableDropdown}
        disableCountryCode={disableCountryCode}
        enableAreaCodes={enableAreaCodes}
        enableTerritories={enableTerritories}
        enableLongNumbers={enableLongNumbers}
        countryCodeEditable={countryCodeEditable}
        disableSearchIcon={disableSearchIcon}
        buttonClass={buttonClass}
        dropdownClass={dropdownClass}
        containerStyle={containerStyle}
        buttonStyle={buttonStyle}
        dropdownStyle={dropdownStyle}
        searchStyle={searchStyle}
        onChange={onChange && onChange}
        onFocus={onFocus && onFocus}
        onBlur={onBlur && onBlur}
        onClick={onClick && onClick}
        onKeyDown={onKeyDown && onKeyDown}
        countryData={countryData}
        regions={regions}
        masks={masks}
        areaCodes={areaCodes}
        defaultMask={defaultMask}
        alwaysDefaultMask={alwaysDefaultMask}
        prefix={prefix}
        searchPlaceholder={searchPlaceholder}
        searchNotFound={searchNotFound}
        copyNumbersOnly={copyNumbersOnly}
        renderStringAsFlag={renderStringAsFlag}
        autocompleteSearch={autocompleteSearch}
        jumpCursorToEnd={jumpCursorToEnd}
        priority={priority}
        enableClickOutside={enableClickOutside}
        showDropdown={showDropdown}
        defaultErrorMessage={defaultErrorMessage}
        disableCountryGuess={disableCountryGuess}
        disableInitialCountryGuess={disableInitialCountryGuess}
        {...(onlyCountries ? onlyCountries : {})}
        {...(preferredCountries ? preferredCountries : {})}
        // {...(excludeCountries ? excludeCountries : {})}
        excludeCountries={['is', 'ht']}
      />
      {error && <FormHelperText style={{ color: '#DA5A30' }}>{error}</FormHelperText>}
    </Typography>
  );
});

export default CustomPhoneInput;
