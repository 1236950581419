/* eslint-disable @typescript-eslint/no-unused-vars */
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Grid,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Checkbox,
  styled,
  Popover,
  Box,
  Button,
  Modal,
  Tab,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { CvShortListSchema } from 'utils/ValidatorSchema';
import { interviewSelectReject, resumeStatusToBgColor } from 'utils/helper';
import CvReject from 'assets/png/cv-reject.svg';
import CvApprove from 'assets/png/cv-approve.svg';
import Reschedule from 'assets/png/Reschedule.svg';
import { CvRejectType, CvShortListType } from 'types';
import CustomInput from 'components/CustomInput/customInput';
import CustomSelect from 'components/CustomSelect/customSelect';
import { cvRejectReason } from 'utils/Config';
import CloseIcon from 'assets/png/close.svg';
import { patchData } from 'services/CommonServices';
import { ResumesENDPOINT } from 'utils/Constant';
import InterviewComponent from './interviewComponent';
import { ScreeningComponent } from './screeningComponent';
import { StatusTag } from './statusTag';
import { OfferComponent } from './offerComponent';
import { useNavigate, To } from 'react-router-dom';
import { AdminPrivateRoutes } from 'routes/routeConstans';
import { TRUE } from 'sass';
import { JoiningComponent } from './joiningComponent';
import { BillingComponent } from './billingComponent';

const CheckIcon = styled('span')(({ theme }) => ({
  borderRadius: 4,
  width: 16,
  height: 16,
  outline: '1px solid #C7CDD8',
  backgroundColor: theme?.palette?.mode === 'dark' ? '#fff' : '#fff',
  '.Mui-focusVisible &': {
    outline: '1px auto #C7CDD8',
    outlineOffset: 1,
  },
  'input:hover ~ &': {
    backgroundColor: theme?.palette?.mode === 'dark' ? '#ffffff' : '#ffffff',
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background: theme?.palette?.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
  },
}));

const CheckedIcon = styled(CheckIcon)({
  backgroundColor: '#EE4B22',
  '&:before': {
    display: 'block',
    width: 16,
    height: 16,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: '#EE4B22',
  },
});

type defaultprops = {
  positionId?: string;
  tableData: any;
  settableData: any;
  loading: any;
  setloading: any;
  getList: () => void;
  selectedCandidate: any;
  setSelectedCandidate: any;
  userId: string;
  crmUserId: string;
};
const CvStatus: React.FC<defaultprops> = ({
  tableData,
  getList,
  selectedCandidate,
  setSelectedCandidate,
  userId,
  crmUserId,
}) => {
  const navigate = useNavigate();
  const gotoNavigate = (link: To) => {
    navigate(link);
  };
  const [allCheckBoxChecked, setAllCheckBoxChecked] = useState(false);

  const pendingCvToSend = tableData.filter((f: any) =>
    f.resumeStatus.find(
      (ff: any) => ff.type === 'interview' && !interviewSelectReject.includes(ff.status),
    ),
  );
  const disableCheck = tableData.filter((f: any) =>
    f.resumeStatus.find(
      (ff: any) => ff.type === 'interview' && interviewSelectReject.includes(ff.status),
    ),
  );
  const disableCheckOne = tableData.filter((f: any) =>
    f.resumeStatus.find(
      (ff: any) => (ff.type === 'screening' && ff.status === 'FA') || ff.status === 'R',
    ),
  );

  const selectOne = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, data: any) => {
      if (e.target.checked) {
        setSelectedCandidate((pre: any) => [...pre, { ...data, checked: true }]);
      } else {
        setSelectedCandidate((pre: any) => pre.filter((f: any) => data.id !== f.id));
      }
    },
    [selectedCandidate],
  );

  const selectAll = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.checked) {
        setSelectedCandidate(pendingCvToSend.map((re: any) => ({ ...re, checked: true })));
        setAllCheckBoxChecked(true);
      } else {
        setAllCheckBoxChecked(false);
        setSelectedCandidate([]);
      }
    },
    [setSelectedCandidate, pendingCvToSend, setAllCheckBoxChecked],
  );

  useEffect(() => {
    if (
      pendingCvToSend.length === selectedCandidate.length &&
      pendingCvToSend.length !== 0 &&
      selectedCandidate.length !== 0
    ) {
      setAllCheckBoxChecked(true);
    } else {
      setAllCheckBoxChecked(false);
    }
  }, [pendingCvToSend, selectedCandidate, setAllCheckBoxChecked]);

  return (
    <Grid container>
      <Grid item md={12}>
        <TableContainer component={Paper} className='sb-table2' sx={{ width: '100% !important' }}>
          <Table sx={{ minWidth: 650 }} className='custom-table' aria-label='simple table'>
            <TableHead>
              <TableRow>
                {userId === crmUserId && (
                  <TableCell>
                    <Checkbox
                      sx={{ padding: 'unset' }}
                      checkedIcon={<CheckedIcon />}
                      icon={<CheckIcon />}
                      disabled={pendingCvToSend.length === 0}
                      checked={allCheckBoxChecked}
                      onChange={selectAll}
                    />
                  </TableCell>
                )}

                <TableCell>Candidate Name</TableCell>
                <TableCell align='center'>Screening Status</TableCell>
                <TableCell align='center'>Interview Status</TableCell>
                <TableCell align='center'>Offer Status</TableCell>
                <TableCell align='center'>Joining Status</TableCell>
                <TableCell align='center'>Billing Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tableData && tableData.length ? (
                tableData.map((dat: any, index: number) => {
                  const candidateName = `${dat?.personal?.firstName} ${
                    dat?.personal?.lastName || ''
                  }`;
                  const screeningStatus = dat?.resumeStatus?.find(
                    (el: any) => el.type === 'screening',
                  )?.status;
                  const interviewStatus = dat?.resumeStatus?.find(
                    (el: any) => el.type === 'interview',
                  )?.status;
                  const offerStatus = dat?.resumeStatus?.find((el: any) => el.type === 'offer')
                    ?.status;
                  const joiningStatus = dat?.resumeStatus?.find((el: any) => el.type === 'joining')
                    ?.status;
                  const billingStatus = dat?.resumeStatus?.find((el: any) => el.type === 'billing')
                    ?.status;
                  return (
                    <TableRow
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      key={index}>
                      {userId === crmUserId && (
                        <TableCell>
                          <Checkbox
                            sx={{ padding: 'unset' }}
                            checkedIcon={<CheckedIcon />}
                            icon={<CheckIcon />}
                            disabled={
                              disableCheck.map((f: any) => f.id).includes(dat.id) ||
                              disableCheckOne.map((f: any) => f.id).includes(dat.id)
                            }
                            checked={selectedCandidate.map((f: any) => f.id).includes(dat.id)}
                            onChange={(e) => selectOne(e, dat)}
                          />
                        </TableCell>
                      )}

                      <TableCell>
                        <Typography
                          component={'p'}
                          sx={{
                            textTransform: 'capitalize',
                            cursor: 'pointer',
                            color: '#5F2EE5',
                            fontSize: '14px !important',
                          }}
                          onClick={() => {
                            gotoNavigate(`${AdminPrivateRoutes.RESUMES.RESUMESVIEW}?id=${dat.id}`);
                          }}>
                          {dat.personal ? candidateName : '-'}
                        </Typography>
                      </TableCell>
                      <TableCell sx={{ textTransform: 'capitalize' }} align='center'>
                        <ScreeningComponent
                          screeningStatus={screeningStatus}
                          candidateName={candidateName}
                          resumeId={dat.id}
                          getList={getList}
                          userId={userId}
                          crmUserId={crmUserId}
                        />
                      </TableCell>
                      <TableCell sx={{ textTransform: 'capitalize' }} align='center'>
                        <InterviewComponent
                          screeningStatus={interviewStatus}
                          candidateName={candidateName}
                          resumeId={dat.id}
                          getList={getList}
                          resumeDetails={dat}
                          userId={userId}
                          crmUserId={crmUserId}
                        />
                      </TableCell>
                      <TableCell sx={{ textTransform: 'capitalize' }} align='center'>
                        {offerStatus ? (
                          <OfferComponent
                            offerStatus={offerStatus}
                            candidateName={candidateName}
                            resumeId={dat.id}
                            getList={getList}
                            userId={userId}
                            crmUserId={crmUserId}
                          />
                        ) : (
                          '-'
                        )}
                      </TableCell>
                      <TableCell sx={{ textTransform: 'capitalize' }} align='center'>
                        {joiningStatus ? (
                          <JoiningComponent
                            offerStatus={joiningStatus}
                            candidateName={candidateName}
                            resumeId={dat.id}
                            getList={getList}
                            userId={userId}
                            crmUserId={crmUserId}
                          />
                        ) : (
                          '-'
                        )}
                      </TableCell>
                      <TableCell sx={{ textTransform: 'capitalize' }} align='center'>
                        {billingStatus ? (
                          <BillingComponent
                            offerStatus={billingStatus}
                            candidateName={candidateName}
                            resumeId={dat.id}
                            getList={getList}
                            data={dat}
                            userId={userId}
                            crmUserId={crmUserId}
                          />
                        ) : (
                          '-'
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <tr className={'text-center'}>
                  <td colSpan={12} align={'center'}>
                    No Record(s) Found.
                  </td>
                </tr>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid
        container
        md={12}
        sx={{ border: '1px solid #E4E7EC', padding: '1rem', marginTop: '1rem' }}>
        <Grid item md={2.4} sm={6} xs={12}>
          <Typography component={'p'} sx={{ padding: '8px', fontSize: '12px' }}>
            <StatusTag color='#4A5569' backgroundColor='#F0EEFB' tag='S' defaultPointer />
            Shortlisted
          </Typography>
          <Typography component={'p'} sx={{ padding: '8px', fontSize: '12px' }}>
            <StatusTag color='#4A5569' backgroundColor='#F0EEFB' tag='OA' defaultPointer />
            Offer Accepted
          </Typography>
          <Typography component={'p'} sx={{ padding: '8px', fontSize: '12px' }}>
            <StatusTag color='#4A5569' backgroundColor='#F0EEFB' tag='BP' defaultPointer />
            Billing Pending
          </Typography>
        </Grid>
        <Grid item md={2.4} sm={6} xs={12}>
          <Typography component={'p'} sx={{ padding: '8px', fontSize: '12px' }}>
            <StatusTag color='#4A5569' backgroundColor='#F0EEFB' tag='R' defaultPointer />
            Rejected
          </Typography>
          <Typography component={'p'} sx={{ padding: '8px', fontSize: '12px' }}>
            <StatusTag color='#4A5569' backgroundColor='#F0EEFB' tag='OR' defaultPointer />
            Offer Rejected
          </Typography>
          <Typography component={'p'} sx={{ padding: '8px', fontSize: '12px' }}>
            <StatusTag color='#4A5569' backgroundColor='#F0EEFB' tag='B' defaultPointer />
            Billed
          </Typography>
        </Grid>
        <Grid item md={2.4} sm={6} xs={12}>
          <Typography component={'p'} sx={{ padding: '8px', fontSize: '12px' }}>
            <StatusTag color='#4A5569' backgroundColor='#F0EEFB' tag='ISA' defaultPointer />
            Interview Schedule Awaited
          </Typography>
          <Typography component={'p'} sx={{ padding: '8px', fontSize: '12px' }}>
            <StatusTag color='#4A5569' backgroundColor='#F0EEFB' tag='JA' defaultPointer />
            Joining Awaited
          </Typography>
          <Typography component={'p'} sx={{ padding: '8px', fontSize: '12px' }}>
            <StatusTag color='#4A5569' backgroundColor='#F0EEFB' tag='IS' defaultPointer />
            Interview Schedule / Rescheduled
          </Typography>
          <Typography component={'p'} sx={{ padding: '8px', fontSize: '12px' }}>
            <StatusTag color='#4A5569' backgroundColor='#F0EEFB' tag='J' defaultPointer />
            Joined
          </Typography>
        </Grid>
        <Grid item md={2.4} sm={6} xs={12}>
          <Typography component={'p'} sx={{ padding: '8px', fontSize: '12px' }}>
            <StatusTag color='#4A5569' backgroundColor='#F0EEFB' tag='FIS' defaultPointer />
            Final Interview Schedule
          </Typography>
          <Typography component={'p'} sx={{ padding: '8px', fontSize: '12px' }}>
            <StatusTag color='#4A5569' backgroundColor='#F0EEFB' tag='NJ' defaultPointer />
            Not Joined
          </Typography>
        </Grid>
        <Grid item md={2.4} sm={6} xs={12}>
          <Typography component={'p'} sx={{ padding: '8px', fontSize: '12px' }}>
            <StatusTag color='#4A5569' backgroundColor='#F0EEFB' tag='OP' defaultPointer />
            Offer Pending
          </Typography>
          <Typography component={'p'} sx={{ padding: '8px', fontSize: '12px' }}>
            <StatusTag color='#4A5569' backgroundColor='#F0EEFB' tag='JD' defaultPointer />
            Joining Deffered
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CvStatus;
