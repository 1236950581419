import { Box, Modal, Typography, Grid, Button, TextField } from '@mui/material';
import CloseIcon from 'assets/png/close.svg';
import { yupResolver } from '@hookform/resolvers/yup';
import CustomSelect from 'components/CustomSelect/customSelect';
import { useForm, Controller } from 'react-hook-form';
import { CreatePaymentSchema } from 'utils/ValidatorSchema';
import CustomInput from 'components/CustomInput/customInput';
import { BillingENDPOINT } from 'utils/Constant';
import { addNewData } from 'services/CommonServices';

export const CreatePayment = ({
  open,
  setpaymentModalOpen,
  setLd,
  ids,
  getList,
  handleClosePop,
}: any) => {
  const {
    control: control,
    handleSubmit,
    // setValue,
    // getValues,
    reset,
    formState: { errors },
  } = useForm<any>({
    criteriaMode: 'all',
    defaultValues: {
      modeOfPayment: '',
      paymentDate: '',
      paymentReceived: '',
      referenceNo: '',
      shortfall: '',
      remarks: '',
      toMail: '',
      ccMail: '',
      mailSubject: '',
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(CreatePaymentSchema),
  });
  const handleClose = () => {
    setpaymentModalOpen(false);
    reset();
  };
  const onSubmitForm = (data: any) => {
    const obj: any = {
      type: 'payment',
      resumeId: ids.resumeId,
      billingId: ids.billingId,
      modeOfPayment: data.modeOfPayment,
      paymentReceivedDate: data.paymentDate,
      amount: data.paymentReceived,
      referenceNo: data.referenceNo,
      to: data.toMail,
      cc: data.ccMail,
      subject: data.mailSubject,
      shortfall: data.shortfall,
      remarks: data.remarks,
      status: true,
    };
    addNewData(obj, `${BillingENDPOINT.billingPayment}`, {}, true)
      .then(() => {
        handleClose();
        setLd(false);
        getList();
        handleClosePop();
      })
      .catch((e: any) => {
        setLd(false);
        console.log(e);
      });
  };
  return (
    <Modal
      sx={{ zIndex: 11000 }}
      open={open}
      onClose={handleClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'>
      <Box className='email-modal' sx={{ width: '865px !important' }}>
        <Typography className='d-flex flex-sb '>
          <Typography className='l-title'>Create Payment</Typography>
          <img src={CloseIcon} alt='close' style={{ cursor: 'pointer' }} onClick={handleClose} />
        </Typography>
        <Typography className='bdiveder' />
        <div>
          <Grid container>
            <Grid item md={12} sm={12} xs={12}>
              <Typography component={'div'} className='custom-field'>
                <form
                  className='private-form'
                  id={'payment-add'}
                  onSubmit={handleSubmit(onSubmitForm)}>
                  <div style={{ height: '10px' }}></div>
                  <Grid container>
                    <Grid item md={12} sm={12} xs={12}>
                      <Grid container columnSpacing={3}>
                        <Grid item md={6} sm={12} xs={12}>
                          <Typography component={'div'} className='custom-field'>
                            <Typography component={'p'}>
                              Mode Of Payment <span>*</span>
                            </Typography>
                            <Controller
                              control={control}
                              defaultValue={''}
                              name={'modeOfPayment'}
                              render={({ field: { onChange, value, name } }) => {
                                return (
                                  <CustomSelect
                                    labelKey={'label'}
                                    valueKey={'value'}
                                    options={[
                                      {
                                        label: 'CHEQUE',
                                        value: 'cheque',
                                      },
                                      {
                                        label: 'CASH',
                                        value: 'cash',
                                      },
                                      {
                                        label: 'ONLINE TRANSFER',
                                        value: 'onlineTransfer',
                                      },
                                      {
                                        label: 'DD',
                                        value: 'dd',
                                      },
                                    ]}
                                    name={name}
                                    value={value}
                                    onChange={(e) => {
                                      onChange(e);
                                    }}
                                    placeHolder='Select'
                                    error={errors?.modeOfPayment?.message}
                                  />
                                );
                              }}
                            />
                          </Typography>
                        </Grid>
                        <Grid item md={6} sm={12} xs={12}>
                          <Typography component={'div'} className='custom-field'>
                            <Typography component={'p'}>
                              Payment Received Date <span>*</span>
                            </Typography>
                            <Controller
                              control={control}
                              name={'paymentDate'}
                              render={({ field: { onChange, value, name } }) => {
                                return (
                                  <>
                                    <TextField
                                      value={value}
                                      name={name}
                                      id='date'
                                      type='date'
                                      placeholder={'Proposal Date'}
                                      className={`clientfilter-time-from-to ${
                                        errors.paymentDate && errors.paymentDate.message
                                          ? 'clientfilter-time-from-to-error'
                                          : ''
                                      }`}
                                      onChange={(e) => {
                                        onChange(e);
                                      }}
                                    />
                                    {errors &&
                                      errors.paymentDate &&
                                      errors?.paymentDate?.message && (
                                        <div className='Custom-error '>
                                          {String(errors?.paymentDate?.message)}
                                        </div>
                                      )}
                                  </>
                                );
                              }}
                            />
                          </Typography>
                        </Grid>
                        <Grid item md={6} sm={12} xs={12}>
                          <Typography component={'div'} className='custom-field'>
                            <Typography component={'p'}>
                              Payment Received Amount<span>*</span>
                            </Typography>
                            <Controller
                              control={control}
                              defaultValue={undefined}
                              name='paymentReceived'
                              render={({ field: { onChange, value, name } }) => {
                                return (
                                  <CustomInput
                                    value={value}
                                    name={name}
                                    type={'number'}
                                    className='custom-input'
                                    placeHolder='Payment Received Amount'
                                    error={errors.paymentReceived?.message}
                                    onChange={(e) => {
                                      onChange(e);
                                    }}
                                  />
                                );
                              }}
                            />
                          </Typography>
                        </Grid>
                        <Grid item md={6} sm={12} xs={12}>
                          <Typography component={'div'} className='custom-field'>
                            <Typography component={'p'}>
                              Reference No<span>*</span>
                            </Typography>
                            <Controller
                              control={control}
                              defaultValue={undefined}
                              name='referenceNo'
                              render={({ field: { onChange, value, name } }) => {
                                return (
                                  <CustomInput
                                    value={value}
                                    name={name}
                                    className='custom-input'
                                    placeHolder='Reference No'
                                    error={errors.referenceNo?.message}
                                    onChange={(e) => {
                                      onChange(e);
                                    }}
                                  />
                                );
                              }}
                            />
                          </Typography>
                        </Grid>
                        <Grid item md={6} sm={12} xs={12}>
                          <Typography component={'div'} className='custom-field'>
                            <Typography component={'p'}>Shortfall</Typography>
                            <Controller
                              control={control}
                              defaultValue={undefined}
                              name='shortfall'
                              render={({ field: { onChange, value, name } }) => {
                                return (
                                  <CustomInput
                                    value={value}
                                    name={name}
                                    className='custom-input'
                                    placeHolder='Shortfall'
                                    error={errors.shortfall?.message}
                                    onChange={(e) => {
                                      onChange(e);
                                    }}
                                  />
                                );
                              }}
                            />
                          </Typography>
                        </Grid>
                        <Grid item md={6} sm={12} xs={12}>
                          <Typography component={'div'} className='custom-field'>
                            <Typography component={'p'}>Remarks</Typography>
                            <Controller
                              control={control}
                              defaultValue={undefined}
                              name='remarks'
                              render={({ field: { onChange, value, name } }) => {
                                return (
                                  <CustomInput
                                    value={value}
                                    name={name}
                                    className='custom-input'
                                    placeHolder='Remarks'
                                    error={errors.remarks?.message}
                                    onChange={(e) => {
                                      onChange(e);
                                    }}
                                  />
                                );
                              }}
                            />
                          </Typography>
                        </Grid>
                        <Grid item md={6} sm={12} xs={12}>
                          <Typography component={'div'} className='custom-field'>
                            <Typography component={'p'}>
                              To mail<span>*</span>
                            </Typography>
                            <Controller
                              control={control}
                              defaultValue={undefined}
                              name='toMail'
                              render={({ field: { onChange, value, name } }) => {
                                return (
                                  <CustomInput
                                    value={value}
                                    name={name}
                                    type='email'
                                    className='custom-input'
                                    placeHolder='To mail'
                                    error={errors.toMail?.message}
                                    onChange={(e) => {
                                      onChange(e);
                                    }}
                                  />
                                );
                              }}
                            />
                          </Typography>
                        </Grid>
                        <Grid item md={6} sm={12} xs={12}>
                          <Typography component={'div'} className='custom-field'>
                            <Typography component={'p'}>
                              CC Mail<span>*</span>
                            </Typography>
                            <Controller
                              control={control}
                              defaultValue={undefined}
                              name='ccMail'
                              render={({ field: { onChange, value, name } }) => {
                                return (
                                  <CustomInput
                                    value={value}
                                    name={name}
                                    className='custom-input'
                                    placeHolder='CC Mail'
                                    error={errors.ccMail?.message}
                                    onChange={(e) => {
                                      onChange(e);
                                    }}
                                  />
                                );
                              }}
                            />
                          </Typography>
                        </Grid>
                        <Grid item md={12} sm={12} xs={12}>
                          <Typography component={'div'} className='custom-field'>
                            <Typography component={'p'}>
                              Mail Subject<span>*</span>
                            </Typography>
                            <Controller
                              control={control}
                              defaultValue={undefined}
                              name='mailSubject'
                              render={({ field: { onChange, value, name } }) => {
                                return (
                                  <CustomInput
                                    value={value}
                                    name={name}
                                    className='custom-input'
                                    placeHolder='Mail Subject'
                                    error={errors.mailSubject?.message}
                                    onChange={(e) => {
                                      onChange(e);
                                    }}
                                  />
                                );
                              }}
                            />
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </form>
              </Typography>
            </Grid>
          </Grid>
          <Typography className='bdiveder' />
          <Typography className='d-flex flex-end  mt-25'>
            <Button className='l-cancel-btn mr-16' onClick={handleClose}>
              Cancel
            </Button>
            <Button className='l-save-btn' type='submit' form={'payment-add'}>
              Submit
            </Button>
          </Typography>
        </div>
      </Box>
    </Modal>
  );
};
