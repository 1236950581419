/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Grid,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Checkbox,
  TableBody,
  Popover,
  Typography,
  Modal,
  Box,
  Button,
} from '@mui/material';
import Loader from 'components/loader';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { To, useNavigate, useSearchParams } from 'react-router-dom';
import { getAllListData, patchData } from 'services/CommonServices';
import { useAppSelector } from 'store/hooks';
import styled from 'styled-components';
import { ResumesENDPOINT } from 'utils/Constant';
import ThreedotIcon from 'assets/png/threedot.svg';
import moment from 'moment';
import { cvRejectReason, noticePeriod } from 'utils/Config';
import CvPending from 'assets/png/cv-pending.svg';
import CvRejected from 'assets/png/cv-rejected.svg';
import CvApproved from 'assets/png/cv-approved.svg';
import CvApprove from 'assets/png/cv-approve.svg';
import CvReject from 'assets/png/cv-reject.svg';
import CloseIcon from 'assets/png/close.svg';
import { Controller, useForm } from 'react-hook-form';
import CustomInput from 'components/CustomInput/customInput';
import CustomSelect from 'components/CustomSelect/customSelect';
import { yupResolver } from '@hookform/resolvers/yup';
import { CVRejectSchema } from 'utils/ValidatorSchema';
import { CvRejectType } from 'types';
import { AdminPrivateRoutes } from 'routes/routeConstans';
import TableTooltip from 'components/Tooltip/Tabletooltip';
import PreIcon from 'assets/png/pre.svg';

const CheckIcon = styled('span')(({ theme }) => ({
  borderRadius: 4,
  width: 16,
  height: 16,
  outline: '1px solid #C7CDD8',
  backgroundColor: theme?.palette?.mode === 'dark' ? '#fff' : '#fff',
  '.Mui-focusVisible &': {
    outline: '1px auto #C7CDD8',
    outlineOffset: 1,
  },
  'input:hover ~ &': {
    backgroundColor: theme?.palette?.mode === 'dark' ? '#ffffff' : '#ffffff',
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background: theme?.palette?.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
  },
}));

const CheckedIcon = styled(CheckIcon)({
  backgroundColor: '#EE4B22',
  '&:before': {
    display: 'block',
    width: 16,
    height: 16,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: '#EE4B22',
  },
});
type defaultprops = {
  positionId?: string;
  sentCv: () => void;
  cvUploadSend: any;
  setCvUploadSend: React.Dispatch<React.SetStateAction<string[]>>;
  tableData: any;
  settableData: any;
  getList: () => void;
  loading: any;
  setloading: any;
  handleClick: (e: any, val: any) => void;
  userId: string;
  crmUserId: string;
};
const CvUpload: React.FC<defaultprops> = ({
  positionId,
  sentCv,
  cvUploadSend,
  setCvUploadSend,
  tableData,
  settableData,
  getList,
  loading,
  setloading,
  handleClick,
  userId,
  crmUserId,
}) => {
  const navigate = useNavigate();
  const gotoNavigate = (link: To) => {
    navigate(link);
  };
  const [allCheckBoxChecked, setAllCheckBoxChecked] = useState(false);

  const pendingCvToSend = tableData.filter(
    (f: any) => f.approvalStatus === 'approved' && f.isCvSentToClient === false,
  );

  const [scrollPosition, setScrollPosition] = useState(0);
  const containerRef = useRef(null) as any;
  // Function to handle scrolling when the button is clicked
  const handleScroll = (scrollAmount: number) => {
    // Calculate the new scroll position
    const newScrollPosition = scrollPosition + scrollAmount;
    // Update the state with the new scroll position
    if (newScrollPosition >= 0 && newScrollPosition <= 800) {
      setScrollPosition(newScrollPosition);
      const element = containerRef.current;
      // Access the container element and set its scrollLeft property
      element.scrollLeft = newScrollPosition;
    }
  };

  const selectOne = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, currentId: string) => {
      if (e.target.checked) {
        setCvUploadSend((pre) => [...pre, currentId]);
      } else {
        setCvUploadSend((pre) => pre.filter((p) => p !== currentId));
      }
    },
    [setCvUploadSend],
  );

  const selectAll = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.checked) {
        setCvUploadSend(pendingCvToSend.map((m: any) => m.id));
        setAllCheckBoxChecked(true);
      } else {
        setAllCheckBoxChecked(false);
        setCvUploadSend([]);
      }
    },
    [setCvUploadSend, pendingCvToSend, setAllCheckBoxChecked],
  );

  useEffect(() => {
    if (
      pendingCvToSend.length === cvUploadSend.length &&
      pendingCvToSend.length !== 0 &&
      cvUploadSend.length !== 0
    ) {
      setAllCheckBoxChecked(true);
    } else {
      setAllCheckBoxChecked(false);
    }
  }, [pendingCvToSend, cvUploadSend, setAllCheckBoxChecked]);

  return (
    <Grid container>
      {loading && <Loader />}
      <Grid item md={12} sx={{ position: 'relative' }}>
        <Button onClick={() => handleScroll(-200)} className='pre-btn'>
          <img src={PreIcon} alt='' />
        </Button>
        <Button onClick={() => handleScroll(200)} className='next-btn'>
          <img src={PreIcon} alt='' style={{ transform: 'rotate(180deg)' }} />
        </Button>
        <TableContainer
          component={Paper}
          className='sb-table2'
          ref={containerRef}
          sx={{ width: '100% !important' }}>
          <Table sx={{ minWidth: 650 }} className='custom-table' aria-label='simple table'>
            <TableHead>
              <TableRow
                sx={{
                  marginLeft: '50px',
                  marginRight: '43px',
                  width: 'calc(100% - 93px)',
                }}>
                {userId === crmUserId && (
                  <TableCell>
                    <Checkbox
                      sx={{ padding: 'unset' }}
                      checkedIcon={<CheckedIcon />}
                      icon={<CheckIcon />}
                      disabled={pendingCvToSend.length === 0}
                      checked={allCheckBoxChecked}
                      onChange={selectAll}
                    />
                  </TableCell>
                )}

                <TableCell>Code</TableCell>
                <TableCell>Candidate Name</TableCell>
                <TableCell>Mobile</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Location</TableCell>
                <TableCell>Pre. CTC</TableCell>
                <TableCell>Exp. CTC</TableCell>
                <TableCell>Notice </TableCell>
                <TableCell>Recruiter</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Created</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tableData && tableData.length ? (
                tableData.map((row: any) => {
                  const candidateName = `${
                    row?.personal?.firstName ? row?.personal?.firstName : '-'
                  } ${row?.personal?.lastName ? row?.personal?.lastName : ''}`;
                  const phoneNumber = `+${row.personal.countryCode} ${row.personal.phoneNumber}`;
                  const isCvSent =
                    row.approvalStatus === 'approved' && row.isCvSentToClient === false;
                  const noticePeriodData = noticePeriod?.find(
                    (r: any) => r?.value === row?.personal?.noticePeriod,
                  );
                  const isApproved = row.approvalStatus === 'approved';
                  const isRejected = row.approvalStatus === 'rejected';
                  const isPending = row.approvalStatus === 'inReview';

                  return (
                    <TableRow
                      key={row.name}
                      sx={{
                        '&:last-child td, &:last-child th': { border: 0 },
                        marginLeft: '50px',
                        marginRight: '43px',
                        width: 'calc(100% - 93px)',
                      }}>
                      {userId === crmUserId && (
                        <TableCell>
                          <Checkbox
                            sx={{ padding: 'unset' }}
                            disabled={isPending || isRejected || !isCvSent}
                            checkedIcon={<CheckedIcon />}
                            icon={<CheckIcon />}
                            name='checkbox'
                            checked={cvUploadSend.includes(row.id)}
                            onChange={(e) => selectOne(e, row.id)}
                          />
                        </TableCell>
                      )}
                      <TableCell sx={{ textTransform: 'capitalize', minWidth: '100px' }}>
                        CT-{row.code ? row.code : '-'}
                      </TableCell>
                      <TableCell sx={{ minWidth: '100px', whiteSpace: 'nowrap' }}>
                        <Typography
                          component={'p'}
                          sx={{
                            textTransform: 'capitalize',
                            cursor: 'pointer',
                            color: '#5F2EE5',
                            fontSize: '14px !important',
                            minWidth: '100px',
                            whiteSpace: 'nowrap',
                          }}
                          onClick={() => {
                            gotoNavigate(`${AdminPrivateRoutes.RESUMES.RESUMESVIEW}?id=${row.id}`);
                          }}>
                          {row.personal ? candidateName : '-'}
                        </Typography>
                      </TableCell>
                      <TableCell
                        sx={{
                          textTransform: 'capitalize',
                          minWidth: '100px',
                          whiteSpace: 'nowrap',
                        }}>
                        <TableTooltip value={row.personal ? phoneNumber : '-'}></TableTooltip>
                      </TableCell>
                      <TableCell
                        sx={{
                          textTransform: 'capitalize',
                          minWidth: '100px',
                          whiteSpace: 'nowrap',
                        }}>
                        <TableTooltip value={row.personal ? row.personal.email : '-'} />
                      </TableCell>
                      <TableCell
                        sx={{ textTransform: 'capitalize', whiteSpace: 'nowrap' }}
                        width={'150px'}>
                        <TableTooltip value={row.personal ? row.personal.presentLocation : '-'} />
                      </TableCell>
                      <TableCell
                        sx={{ textTransform: 'capitalize', whiteSpace: 'nowrap' }}
                        width={'100px'}>
                        {row.personal
                          ? `${Number(row.personal.currentCtc).toLocaleString('en-IN')}`
                          : '-'}
                      </TableCell>
                      <TableCell
                        sx={{ textTransform: 'capitalize', whiteSpace: 'nowrap' }}
                        width={'100px'}>
                        {row.personal
                          ? `${Number(row.personal.expectedCtc).toLocaleString('en-IN')}`
                          : '-'}
                      </TableCell>
                      <TableCell sx={{ textTransform: 'capitalize', whiteSpace: 'nowrap' }}>
                        {row.personal && noticePeriodData?.label ? noticePeriodData?.label : '-'}
                      </TableCell>
                      <TableCell
                        sx={{
                          textTransform: 'capitalize',
                          minWidth: '100px',
                          whiteSpace: 'nowrap',
                        }}>
                        <TableTooltip
                          value={
                            row.createdBy
                              ? `${row.createdBy.firstName} ${
                                  row.createdBy.lastName ? row.createdBy.lastName : ''
                                }`
                              : '-'
                          }></TableTooltip>
                      </TableCell>
                      <TableCell sx={{ textTransform: 'capitalize' }}>
                        {isApproved && <ApprovedComponent />}
                        {isRejected && <RejectedComponent />}
                        {isPending && (
                          <PendingComponent
                            getList={getList}
                            resumeId={row.id}
                            candidateName={candidateName}
                            userId={userId}
                            crmUserId={crmUserId}
                          />
                        )}
                      </TableCell>
                      <TableCell sx={{ textTransform: 'capitalize', whiteSpace: 'nowrap' }}>
                        {row.createdAt ? `${moment(row.createdAt).format('DD-MM-YYYY')}` : '-'}
                      </TableCell>
                      <TableCell align='center' sx={{ textTransform: 'capitalize' }}>
                        <img
                          src={ThreedotIcon}
                          alt='ThreedotIcon'
                          onClick={(e: any) => handleClick(e, row)}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <tr className={'text-center'}>
                  <td colSpan={13} align={'center'}>
                    No Record(s) Found.
                  </td>
                </tr>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export const PendingComponent: React.FC<{
  resumeId: string;
  candidateName: string;
  userId?: string;
  crmUserId?: string;
  getList: () => void;
}> = ({ candidateName, resumeId, getList, userId, crmUserId }) => {
  const [panchorEl, setPAnchorEl] = React.useState<HTMLAnchorElement | null>(null);
  const [isRejectModalOpen, setRejectModalOpen] = React.useState(false);
  const open = Boolean(panchorEl);
  const paid = open ? 'simple-popover' : undefined;
  const handleClose = () => {
    setPAnchorEl(null);
  };
  const handleModalClose = () => {
    reset();
    setRejectModalOpen(false);
  };
  const handleModalOpen = (status: string) => {
    setValue('approvalStatus', status);
    setPAnchorEl(null);
    setRejectModalOpen(true);
  };
  const handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    setPAnchorEl(event.currentTarget);
  };

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
    getValues,
  } = useForm({ resolver: yupResolver(CVRejectSchema) });

  const onSubmit = (data: CvRejectType) => {
    patchData('', { ...data }, `${ResumesENDPOINT.resumeList}/status/${resumeId}`, true)
      .then(() => {
        handleModalClose();
        getList();
      })
      .catch((e) => console.log(e));
  };

  return (
    <div>
      <Popover
        id={paid}
        anchorEl={panchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        style={{ width: '100%', padding: '1rem' }}>
        <Typography component={'div'}>
          <Typography
            onClick={() => handleModalOpen('approved')}
            className='menu-item'
            style={{ padding: '0px 15px 0px 15px' }}>
            <img src={CvReject} alt='approve' />
            Approve
          </Typography>
          <Typography
            onClick={() => handleModalOpen('rejected')}
            className='menu-item'
            style={{ padding: '0px 15px 0px 15px' }}>
            <img src={CvApprove} alt='reject' />
            Reject
          </Typography>
        </Typography>
      </Popover>
      <img
        src={CvPending}
        style={{ cursor: userId === crmUserId ? 'pointer' : 'not-allowed' }}
        onClick={(e: any) => userId === crmUserId && handleClick(e)}
      />
      <Modal open={isRejectModalOpen} onClose={handleModalClose}>
        <Box className='logout-modal p-32'>
          <Typography
            component={'div'}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginBottom: '1rem',
            }}>
            <Typography sx={{ fontSize: '20px', fontWeight: '600' }}>
              {getValues('approvalStatus') === 'rejected' ? 'Reject CV' : 'Approve CV'}
            </Typography>
            <Typography className='text-right'>
              <img
                src={CloseIcon}
                alt='close'
                style={{ cursor: 'pointer' }}
                onClick={handleModalClose}
              />
            </Typography>
          </Typography>
          <form id='cv-reject' className='private-form' onSubmit={handleSubmit(onSubmit)}>
            <Grid container>
              <Grid item md={12} sm={12} xs={12}>
                <Typography component={'div'} className='custom-field'>
                  <Typography component={'p'}>Candidate Name</Typography>
                  <CustomInput
                    disabled
                    value={candidateName}
                    placeHolder='Candidate Name'
                    className='custom-input'
                  />
                </Typography>
              </Grid>
              {getValues('approvalStatus') === 'rejected' && (
                <Grid item md={12} sm={12} xs={12}>
                  <Typography component={'div'} className='custom-field'>
                    <Typography component={'p'}>
                      Reason <span>*</span>
                    </Typography>
                    <Controller
                      name='reason'
                      control={control}
                      render={({ field: { onChange, value, name } }) => (
                        <CustomSelect
                          labelKey={'label'}
                          valueKey={'value'}
                          options={cvRejectReason}
                          name={name}
                          value={value}
                          onChange={(e) => {
                            onChange(e);
                          }}
                          placeHolder='Reason'
                          error={errors.reason?.message}
                        />
                      )}
                    />
                  </Typography>
                </Grid>
              )}
              <Grid item md={12} sm={12} xs={12}>
                <Typography component={'div'} className='custom-field'>
                  <Typography component={'p'}>Remarks</Typography>
                  <Controller
                    name='remarks'
                    control={control}
                    render={({ field: { onChange, value, name } }) => (
                      <CustomInput
                        name={name}
                        value={value}
                        isTextArea={true}
                        onChange={(e) => {
                          onChange(e);
                        }}
                        className='custom-input'
                        placeHolder='Remarks'
                        error={errors.remarks?.message}
                      />
                    )}
                  />
                </Typography>
              </Grid>
              <Grid item md={12} sm={12} xs={12}>
                <Typography
                  component={'div'}
                  sx={{ justifyContent: 'flex-end' }}
                  className='d-flex-a'>
                  <Button className='cancel-btn mr-16' onClick={handleModalClose}>
                    Cancel
                  </Button>
                  <Button form={'cv-reject'} className='s-add-btn' type='submit'>
                    Submit
                  </Button>
                </Typography>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Modal>
    </div>
  );
};

export const ApprovedComponent: React.FC = () => {
  return (
    <div>
      <img src={CvApproved} />
    </div>
  );
};

export const RejectedComponent: React.FC = () => {
  return (
    <div>
      <img src={CvRejected} />
    </div>
  );
};
export default CvUpload;
