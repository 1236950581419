/* eslint-disable @typescript-eslint/no-unused-vars */
import _ from 'lodash';
import React, { CSSProperties, ReactElement } from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
// type InputProps = {

// };

const BootstrapTooltip = styled(({ className, ...props }: TooltipProps & CSSProperties) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: 'rgba(222, 219, 219, 0.87)',
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#989898',
    boxShadow: theme.shadows[1],
    color: 'white',
    fontFamily: 'Poppins-Regular',
    fontWeight: 400,
    //   color: '#344051',
    fontSize: '14px',
    fontStyle: 'normal',
    lineHeight: '16px' /* 114.286% */,
    padding: '10px',
  },
}));
const TableTooltip = (props: any): ReactElement => {
  const length = props?.value?.length;
  if (props?.positionfile && props?.value && props?.value?.length > 0) {
    return props?.value?.length > 14 ? (
      <>
        <BootstrapTooltip title={String(props.value)}>
          <div>
            {props.value.slice(0, 24)}
            <span style={{ cursor: 'pointer' }}>...</span>
          </div>
        </BootstrapTooltip>
      </>
    ) : (
      <span>{props.value}</span>
    );
  }
  if (props.position && props.value && props.value.length > 0) {
    return props.value.length > 14 ? (
      <>
        <BootstrapTooltip title={String(props.value)}>
          <span style={{ ...props.style }}>
            {props.value.slice(0, 15)}
            <span style={{ cursor: 'pointer' }}>...</span>
          </span>
        </BootstrapTooltip>
      </>
    ) : (
      <span style={{ ...props.style }}>{props.value}</span>
    );
  }
  if (
    !props.position &&
    props.value &&
    props.value.includes(',') &&
    props.value.split(',')[0].length > 5
  ) {
    return (
      <>
        <BootstrapTooltip title={String(props.value)}>
          <span style={{ ...props.style }}>
            {props.value.split(',')[0].slice(0, 5)}
            <span style={{ cursor: 'pointer' }}>...</span>
          </span>
        </BootstrapTooltip>
      </>
    );
  }

  if (!props.position && props.value && props.value.length) {
    return (
      <>
        <BootstrapTooltip title={String(props.value)}>
          <span style={{ ...props.style }}>
            {props.value.slice(0, 9)}
            {props.value.length > 9 ? <span style={{ cursor: 'pointer' }}>...</span> : ''}
          </span>
        </BootstrapTooltip>
      </>
    );
  }
  if (props.value === ' ' || !props.value) {
    return <span>-</span>;
  }
  if (!props.position && !props.positionfile && length <= 5) {
    return <span style={{ ...props.style }}>{props.value}</span>;
  }
  return <span style={{ ...props.style }}>{props.children}</span>;
};

export default TableTooltip;
