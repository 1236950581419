import { Drawer } from '@mui/material';
import React, { useCallback, useState } from 'react';
import CloseIcon from 'assets/png/close.svg';
import BillingFilterForm from './BillingFilterFrorm';
import { useForm } from 'react-hook-form';
import { BillingFilterType } from 'types';
import { yupResolver } from '@hookform/resolvers/yup';
import { ResumeFilterSchema } from 'utils/ValidatorSchema';

interface BillingFilterDrawerProps {
  isOpen: boolean;
  closeDrawer: () => void;
  callback: (filters: BillingFilterType) => void;
  setParams?: any;
  setBillingData?: any;
  setLoading?: any;
  setpagetotal?: any;
  listdataapi?: any;
}

export const BillingFilterDrawer: React.FC<BillingFilterDrawerProps> = ({
  isOpen,
  closeDrawer,
  // callback,
  setParams,
  // setBillingData,
  // setLoading,
  // setpagetotal,
  listdataapi,
}) => {
  const [key, setKey] = useState(0);

  const { control, handleSubmit, reset, formState, watch, trigger } = useForm<BillingFilterType>({
    criteriaMode: 'all',
    defaultValues: {
      interviewFrom: undefined,
      interviewTo: undefined,
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(ResumeFilterSchema),
  });

  const closeSideDrawer = useCallback(() => {
    closeDrawer();
  }, [closeDrawer]);

  const resetFilters = useCallback(() => {
    reset();
    listdataapi();
    setKey((prev) => prev + 1);
  }, [reset]);

  const onSubmit = (data: any) => {
    if (data.interviewFrom || data.interviewTo || data.createdBy) {
      setParams((prev: any) => ({
        ...prev,
        from: data.interviewFrom ? data.interviewFrom : null,
        to: data.interviewTo ? data.interviewTo : null,
        createdBy: data.createdBy ? data.createdBy : null,
      }));
    }
  };

  return (
    <Drawer
      anchor='right'
      open={isOpen}
      onClose={closeDrawer}
      sx={{
        flexShrink: 0,
        zIndex: 1200,
        ['& .MuiDrawer-paper']: {
          boxSizing: 'border-box',
          marginTop: '72px',
          width: '300px',
          padding: '30px',
          paddingTop: '0px',
        },
        ['& .MuiModal-backdrop ']: { backgroundColor: 'transparent' },
      }}>
      <header className='clientfilter-header'>
        <img
          src={CloseIcon}
          alt='closeicon'
          className='clientfilter-close-ico'
          onClick={closeSideDrawer}
        />
        <div className='heading'>Filters</div>
        <span
          className='clearall'
          style={{
            ...(!(watch('interviewFrom') || watch('interviewTo') || watch('createdBy'))
              ? { opacity: '0.5', cursor: 'not-allowed' }
              : {}),
          }}
          onClick={() =>
            watch('interviewFrom') || watch('interviewTo') || watch('createdBy')
              ? resetFilters()
              : ''
          }>
          Clear All
        </span>
      </header>
      <div className='clientfilter-divider'></div>
      <BillingFilterForm
        setParams={setParams}
        key={key}
        control={control}
        handleSubmit={handleSubmit}
        formState={formState}
        onSubmit={onSubmit}
        watch={watch}
        trigger={trigger}
      />
    </Drawer>
  );
};
