/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { yupResolver } from '@hookform/resolvers/yup';
import { Grid, Typography, Button } from '@mui/material';
import CustomInput from 'components/CustomInput/customInput';
import CustomSelect from 'components/CustomSelect/customSelect';
import React, { useEffect, useState } from 'react';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import { addNewData, getAllListData, getSingleData, updateData } from 'services/CommonServices';
import { ResumesENDPOINT, SETTING_ENDPOINT } from 'utils/Constant';
import Loader from 'components/loader';
import { resumeEducationSchema } from 'utils/ValidatorSchema';
import { useNavigate, useSearchParams } from 'react-router-dom';
import AddIcon from 'assets/iconcomponents/addicon';
import Deleteimgicon from 'assets/png/Deleteblack.svg';
import _, { find, isEmpty } from 'lodash';

type defaultprops = {
  positionId: string;
  orgId: string;
  clientId: string;
  step: number;
  setStep: (val: number) => void;
  loader: boolean;
  setLoader: (val: boolean) => void;
  resumeIds: string;
  resumeData: (val: number) => void;
};
type educationForm = {
  specializationId: string;
  qualificationId: string;
  isSaved?: boolean;
  marks: string;
  modeOfEducation: string;
  year: string;
  univercity: string;
  degreeId: string;
  college: string;
  rowId?: string;
  school: string;
};
const ResumeEducation: React.FC<defaultprops> = ({
  positionId,
  clientId,
  orgId,
  step,
  setStep,
  loader,
  setLoader,
  resumeIds,
  resumeData,
}) => {
  const navigate = useNavigate();
  const [qualification, setQualification] = useState([]);
  // const [qualificationId, setQualificationId] = useState('');
  const [degree, setDegree] = useState([]);
  const [specialization, setSpecialization] = useState([]);
  const [reload, setreload] = useState(false);
  const [removeId, setRemoveId] = useState<any>([]);
  const [personalData, setPersonalData] = useState<any>([]);
  const [searchParams]: any = useSearchParams();
  const [draftCheck, setDraftCheck] = useState(false);
  const [resumeId, setResumeId] = useState('');
  const {
    control: control,
    handleSubmit: handleSubmit,
    reset,
    setValue,
    // setError,
    trigger,
    watch,
    getValues,
    formState: { errors },
  } = useForm<{ educationData: educationForm[] }>({
    criteriaMode: 'all',
    defaultValues: {
      educationData: [
        {
          rowId: '',
          qualificationId: '',
          isSaved: true,
          marks: '',
          modeOfEducation: '',
          year: '',
          univercity: '',
          degreeId: '',
          specializationId: '',
          college: '',
          school: '',
        },
      ],
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
    // @ts-expect-error ts(2322)
    resolver: yupResolver(resumeEducationSchema),
  });
  const { fields, append, remove, replace } = useFieldArray({
    control,
    name: 'educationData',
  });
  const educationDraft = () => {
    const filter = getValues('educationData').map((re: any) => {
      if (re?.isSaved) {
        re['isSaved'] = false;
      }
      delete re?.id;
      if (re?.rowId) {
        re['id'] = re.rowId;
      }
      delete re?.rowId;
      if (!re?.modeOfEducation) {
        delete re?.modeOfEducation;
      }
      if (!re?.specializationId) {
        delete re?.specializationId;
      }
      if (!re?.qualificationId) {
        delete re?.qualificationId;
      }
      if (!re?.degreeId) {
        delete re?.degreeId;
      }
      return re;
    });
    const formdata: any = new FormData();
    formdata.append('resumeId', resumeId);
    formdata.append('resumeTabType', 'education');
    formdata.append('educationData', JSON.stringify(filter));
    if (removeId.length) {
      formdata.append('removeEducationIds', JSON.stringify(removeId));
    }
    addNewData(
      formdata,
      `${ResumesENDPOINT.resumeList}`,
      {
        'Content-Type': 'multipart/form-data',
      },
      true,
    )
      .then(() => {
        navigate('/resumes');
        setLoader(false);
      })
      .catch((e) => {
        setLoader(false);
        console.log(e);
      });
  };
  const back = () => {
    setStep(0);
  };
  const removeSpecialization = (index: number, id: string) => {
    if (id) {
      removeId.push(id);
      setRemoveId([...removeId]);
    }
    remove(index);
  };
  const onSubmitForm = (data: any) => {
    setLoader(true);
    const filter = data.educationData.map((re: any, index: number) => {
      delete re?.id;
      if (re?.rowId) {
        re['id'] = re.rowId;
      }
      delete re?.rowId;
      if (!re?.modeOfEducation) {
        delete re?.modeOfEducation;
      }
      if ([5, 6, 7].includes(Number(getValues(`educationData.${index}.qualificationId`)))) {
        delete re?.specializationId;
        delete re?.degreeId;
      }
      return re;
    });

    const formdata: any = new FormData();
    formdata.append('resumeId', resumeId);
    formdata.append('resumeTabType', 'education');
    formdata.append('educationData', JSON.stringify(filter));
    if (removeId.length) {
      formdata.append('removeEducationIds', JSON.stringify(removeId));
    }

    addNewData(
      formdata,
      `${ResumesENDPOINT.resumeList}`,
      {
        'Content-Type': 'multipart/form-data',
      },
      true,
    )
      .then(() => {
        if (personalData.expYears === 0) {
          setStep(3);
        } else {
          setStep(2);
        }
        setLoader(false);
        setRemoveId([]);
      })
      .catch((e) => {
        setLoader(false);
        console.log(e);
      });
  };
  useEffect(() => {
    getAllListData(SETTING_ENDPOINT.Qualification)
      .then((res: any) => {
        const options: any = [];
        res &&
          res.data.map((e: any) => {
            options.push({ label: e.name, value: e.id, qualificationData: e.qualification });
          });
        setQualification(options);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);
  useEffect(() => {
    getAllListData('qualification/degree')
      .then((res: any) => {
        const options: any = [];
        res &&
          res.data.map((e: any) => {
            options.push({
              label: e.degree,
              value: e.id,
              degreeData: e.qualificationsDegree,
              ...e,
            });
          });
        setDegree(options);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  useEffect(() => {
    getAllListData('qualification/specialization')
      .then((res: any) => {
        const options: any = [];
        res &&
          res.data.map((e: any) => {
            options.push({
              label: e.specialization,
              value: e.id,
              ...e,
            });
          });
        setSpecialization(options);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);
  const getInitialStatus = () => {
    if (searchParams.get('id') || resumeIds) {
      setLoader(true);
      getSingleData(searchParams.get('id') || resumeIds, ResumesENDPOINT.resumeList)
        .then((resp: any) => {
          resumeData(resp.data[0]);
          setResumeId(resp.data && resp.data[0]?.id);
          setPersonalData(resp.data[0].personal);
          setDraftCheck(resp.data[0].approvalStatus === 'draft' ? true : false);
          if (resp && !_.isEmpty(resp.data) && !_.isEmpty(resp.data[0]?.education)) {
            const dat: any = resp.data[0]?.education;
            const finalData: any = [];
            // console.log(dat);

            dat.map((ses: any, index: any) =>
              finalData.push({
                qualificationId: ses.qualificationId,
                isSaved: true,
                marks: ses.marks,
                modeOfEducation: ses.modeOfEducation,
                year: ses.year,
                univercity: ses.univercity,
                degreeId: ses.degreeId,
                specializationId: ses.specializationId,
                college: ses.college,
                rowId: ses.id,
                school: ses.school,
              }),
            );

            reset({
              educationData: finalData,
            });
          }
          setLoader(false);
        })
        .catch((e: any) => {
          setLoader(false);
          // console.log(e);
        });
    }
  };
  React.useEffect(() => {
    getInitialStatus();
  }, []);

  React.useEffect(() => {
    setResumeId(resumeIds);
  }, [resumeIds]);

  return (
    <Grid container>
      {loader && <Loader />}
      <Grid item md={12} sm={12} xs={12} sx={{ padding: '12px' }}>
        <Typography component={'div'} className='step-parent-box'>
          <Typography component={'div'} className='d-flex-a flex-sb'>
            <Typography className='stepper-title'>Education</Typography>
            <Typography className='d-flex-a'>
              <Typography sx={{ marginRight: '20px !important', marginTop: '6px' }}>
                <AddIcon
                  onClick={() =>
                    append({
                      qualificationId: '',
                      isSaved: true,
                      marks: '',
                      modeOfEducation: '',
                      year: '',
                      univercity: '',
                      degreeId: '',
                      specializationId: '',
                      college: '',
                      rowId: '',
                      school: '',
                    })
                  }
                />
              </Typography>
              <Button className='cancel-btn mr-16' onClick={back}>
                Back
              </Button>
              <Button
                className='go-to-clnt-list-btn  mr-16'
                disabled={!draftCheck ? true : false}
                sx={{ cursor: !draftCheck ? 'not-allowed' : 'pointer' }}
                onClick={educationDraft}>
                Draft
              </Button>
              <Button className='s-add-btn' type={'submit'} form={'education-form'}>
                Save and Continue
              </Button>
            </Typography>
          </Typography>
          <Typography className='bdiveder' />
          <form
            className='private-form'
            id={'education-form'}
            onSubmit={handleSubmit(onSubmitForm)}>
            {fields && fields.length >= 0
              ? fields.map((item: any, index: number) => {
                  return (
                    <Grid container columnSpacing={4.25} key={item.id}>
                      <Grid item md={12} sm={12} xs={12}>
                        {index !== 0 ? (
                          <>
                            <Typography className='bdiveder' />
                            <div
                              className='list-del-add-icon'
                              style={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'flex-end',
                                alignItems: 'center',
                              }}>
                              <span
                                style={{ cursor: 'pointer', marginRight: '24px' }}
                                onClick={() => {
                                  removeSpecialization(index, item.rowId);
                                }}>
                                <img src={Deleteimgicon} alt='delete' />
                              </span>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                      </Grid>
                      <Grid item md={4} sm={12} xs={12}>
                        <Typography component={'div'} className='custom-field'>
                          <Typography component={'p'}>
                            Qualification <span>*</span>
                          </Typography>
                          <Controller
                            control={control}
                            name={`educationData.${index}.qualificationId`}
                            // name={'qualificationId'}
                            render={({ field: { onChange, value, name } }) => {
                              return (
                                <CustomSelect
                                  labelKey={'label'}
                                  valueKey={'value'}
                                  options={qualification}
                                  name={name}
                                  isClearable
                                  placeHolder='Qualification'
                                  value={value ? value : null}
                                  onChange={(e) => {
                                    onChange(e);
                                    setreload(!reload);
                                    setValue(`educationData.${index}.degreeId`, '');
                                    setValue(`educationData.${index}.specializationId`, '');
                                    if (
                                      [5, 6, 7].includes(
                                        Number(getValues(`educationData.${index}.qualificationId`)),
                                      )
                                    ) {
                                      setValue(`educationData.${index}.modeOfEducation`, '');
                                      setValue(`educationData.${index}.degreeId`, '');
                                      setValue(`educationData.${index}.specializationId`, '');
                                      setValue(`educationData.${index}.college`, '');
                                      setValue(`educationData.${index}.univercity`, '');
                                    } else if (
                                      ![5, 6, 7].includes(
                                        Number(getValues(`educationData.${index}.qualificationId`)),
                                      )
                                    ) {
                                      setValue(`educationData.${index}.school`, '');
                                    }
                                  }}
                                  error={
                                    errors?.educationData &&
                                    errors?.educationData?.[index] &&
                                    errors?.educationData?.[index]?.qualificationId?.message
                                  }
                                />
                              );
                            }}
                          />
                        </Typography>
                      </Grid>
                      <Grid item md={4} sm={12} xs={12}>
                        <Typography component={'div'} className='custom-field'>
                          <Typography component={'p'}>Marks / Grade</Typography>
                          <Controller
                            control={control}
                            name={`educationData.${index}.marks`}
                            // name={'markGrade'}
                            render={({ field: { onChange, value, name } }) => {
                              return (
                                <CustomInput
                                  value={value}
                                  name={name}
                                  className='custom-input'
                                  placeHolder='Enter marks / grade'
                                  error={
                                    errors?.educationData &&
                                    errors?.educationData?.[index] &&
                                    errors?.educationData?.[index]?.marks?.message
                                  }
                                  onChange={(e) => {
                                    onChange(e);
                                  }}
                                />
                              );
                            }}
                          />
                        </Typography>
                      </Grid>
                      {![5, 6, 7].includes(
                        Number(getValues(`educationData.${index}.qualificationId`)),
                      ) && (
                        <>
                          <Grid item md={4} sm={12} xs={12}>
                            <Typography component={'div'} className='custom-field'>
                              <Typography component={'p'}>Mode of Education</Typography>
                              <Controller
                                control={control}
                                name={`educationData.${index}.modeOfEducation`}
                                // name={'modeOfEducation'}
                                render={({ field: { onChange, value, name } }) => {
                                  return (
                                    <CustomSelect
                                      labelKey={'label'}
                                      valueKey={'value'}
                                      options={[
                                        { label: 'Regular', value: 'regular' },
                                        { label: 'Correspondence', value: 'correspondence' },
                                      ]}
                                      name={name}
                                      placeHolder='Enter mode of education'
                                      value={value ? value : null}
                                      onChange={(e) => {
                                        onChange(e);
                                      }}
                                      error={
                                        errors?.educationData &&
                                        errors?.educationData?.[index] &&
                                        errors?.educationData?.[index]?.modeOfEducation?.message
                                      }
                                    />
                                  );
                                }}
                              />
                            </Typography>
                          </Grid>
                          <Grid item md={4} sm={12} xs={12}>
                            <Typography component={'div'} className='custom-field'>
                              <Typography component={'p'}>
                                Degree <span>*</span>
                              </Typography>
                              <Controller
                                control={control}
                                // name={'degreeId'}
                                name={`educationData.${index}.degreeId`}
                                render={({ field: { onChange, value, name } }) => {
                                  let selectData: any = [];
                                  if (getValues('educationData')[index].qualificationId) {
                                    selectData = degree.filter(
                                      (re: any) =>
                                        re.qualificationId ==
                                        getValues('educationData')[index].qualificationId,
                                    );
                                  }

                                  return (
                                    <CustomSelect
                                      labelKey={'label'}
                                      valueKey={'value'}
                                      options={selectData}
                                      name={name}
                                      placeHolder='Degree'
                                      value={value ? value : null}
                                      onChange={(e) => {
                                        onChange(e);
                                        setValue(`educationData.${index}.specializationId`, '');
                                        setreload(!reload);
                                      }}
                                      error={
                                        errors?.educationData &&
                                        errors?.educationData?.[index] &&
                                        errors?.educationData?.[index]?.degreeId?.message
                                      }
                                    />
                                  );
                                }}
                              />
                            </Typography>
                          </Grid>
                        </>
                      )}
                      <Grid item md={4} sm={12} xs={12}>
                        <Typography component={'div'} className='custom-field'>
                          <Typography component={'p'}>
                            Year of Passing <span>*</span>
                          </Typography>
                          <Controller
                            control={control}
                            // name={'year'}
                            name={`educationData.${index}.year`}
                            render={({ field: { onChange, value, name } }) => {
                              return (
                                <CustomInput
                                  value={value}
                                  name={name}
                                  type={'number'}
                                  className='custom-input'
                                  placeHolder={`Enter year of passing (e.g: ${new Date().getFullYear()})`}
                                  error={
                                    errors?.educationData &&
                                    errors?.educationData?.[index] &&
                                    errors?.educationData?.[index]?.year?.message
                                  }
                                  onChange={(e) => {
                                    onChange(e);
                                  }}
                                />
                              );
                            }}
                          />
                        </Typography>
                      </Grid>
                      {![5, 6, 7].includes(
                        Number(getValues(`educationData.${index}.qualificationId`)),
                      ) && (
                        <>
                          <Grid item md={4} sm={12} xs={12}>
                            <Typography component={'div'} className='custom-field'>
                              <Typography component={'p'}>
                                Specialization <span>*</span>
                              </Typography>
                              <Controller
                                control={control}
                                // name={'specializationId'}
                                name={`educationData.${index}.specializationId`}
                                render={({ field: { onChange, value, name } }) => {
                                  let selectData: any = [];
                                  if (getValues('educationData')[index].degreeId) {
                                    selectData = specialization.filter(
                                      (re: any) =>
                                        re?.degreeId == getValues('educationData')[index]?.degreeId,
                                    );
                                    // if (
                                    //   findSpecial &&
                                    //   findSpecial?.degreeData &&
                                    //   findSpecial?.degreeData?.length
                                    // ) {
                                    //   selectData = specialization?.filter(
                                    //     (es: any) =>
                                    //       es.qualificationId ===
                                    //       findSpecial?.degreeData[0]?.qualificationId,
                                    //   );
                                    // }
                                  }
                                  return (
                                    <CustomSelect
                                      labelKey={'label'}
                                      valueKey={'value'}
                                      options={selectData}
                                      name={name}
                                      placeHolder='Specialization'
                                      value={value ? value : null}
                                      onChange={(e) => {
                                        onChange(e);
                                        setreload(!reload);
                                      }}
                                      error={
                                        errors?.educationData &&
                                        errors?.educationData?.[index] &&
                                        errors?.educationData?.[index]?.specializationId?.message
                                      }
                                    />
                                  );
                                }}
                              />
                            </Typography>
                          </Grid>
                          <Grid item md={4} sm={12} xs={12}>
                            <Typography component={'div'} className='custom-field'>
                              <Typography component={'p'}>
                                College <span>*</span>
                              </Typography>
                              <Controller
                                control={control}
                                // name={'college'}
                                name={`educationData.${index}.college`}
                                render={({ field: { onChange, value, name } }) => {
                                  return (
                                    <CustomInput
                                      value={value}
                                      name={name}
                                      className='custom-input'
                                      placeHolder='Enter College'
                                      error={
                                        errors?.educationData &&
                                        errors?.educationData?.[index] &&
                                        errors?.educationData?.[index]?.college?.message
                                      }
                                      onChange={(e) => {
                                        onChange(e);
                                      }}
                                    />
                                  );
                                }}
                              />
                            </Typography>
                          </Grid>
                          <Grid item md={4} sm={12} xs={12}>
                            <Typography component={'div'} className='custom-field'>
                              <Typography component={'p'}>University</Typography>
                              <Controller
                                control={control}
                                name={`educationData.${index}.univercity`}
                                // name={'university'}
                                render={({ field: { onChange, value, name } }) => {
                                  return (
                                    <CustomInput
                                      value={value}
                                      name={name}
                                      className='custom-input'
                                      placeHolder='Enter University'
                                      error={
                                        errors?.educationData &&
                                        errors?.educationData?.[index] &&
                                        errors?.educationData?.[index]?.univercity?.message
                                      }
                                      onChange={(e) => {
                                        onChange(e);
                                      }}
                                    />
                                  );
                                }}
                              />
                            </Typography>
                          </Grid>
                        </>
                      )}
                      {[5, 6, 7].includes(
                        Number(getValues(`educationData.${index}.qualificationId`)),
                      ) ? (
                        <Grid item md={4} sm={12} xs={12}>
                          <Typography component={'div'} className='custom-field'>
                            <Typography component={'p'}>
                              School <span>*</span>
                            </Typography>
                            <Controller
                              control={control}
                              name={`educationData.${index}.school`}
                              // name={'university'}
                              render={({ field: { onChange, value, name } }) => {
                                return (
                                  <CustomInput
                                    value={value}
                                    name={name}
                                    className='custom-input'
                                    placeHolder='School'
                                    error={
                                      errors?.educationData &&
                                      errors?.educationData?.[index] &&
                                      errors?.educationData?.[index]?.school?.message
                                    }
                                    onChange={(e) => {
                                      onChange(e);
                                    }}
                                  />
                                );
                              }}
                            />
                          </Typography>
                        </Grid>
                      ) : (
                        ''
                      )}
                    </Grid>
                  );
                })
              : ''}
          </form>
        </Typography>
      </Grid>
    </Grid>
  );
};
export default ResumeEducation;
