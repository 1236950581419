export const fileTypeCheck = [
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/msword',
];
export const fileTypePDF = [
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/msword',
];
export const fileTypewordPDF = [
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/msword',
  'application/pdf',
  'application/PDF',
];
export const resumeStatusToBgColor: Record<string, string> = {
  S: '#D0FFE8',
  R: '#FDEDE9',
  OA: '#D0FFE8',
  OR: '#FDEDE9',
  J: '#D0FFE8',
  JD: '#D0FFE8',
  B: '#D0FFE8',
  NJ: '#FDEDE9',
};
export enum InterviewLevel {
  FirstInterview = 'firstInterview',
  SecondInterview = 'secondInterview',
  ThirdInterview = 'thirdInterview',
  FourthInterview = 'fourthInterview',
  FinalInterview = 'finalInterview',
}
export const InterviewLevelToText: Record<InterviewLevel, string> = {
  [InterviewLevel.FirstInterview]: 'First Interview',
  [InterviewLevel.SecondInterview]: 'Second Interview',
  [InterviewLevel.ThirdInterview]: 'Third Interview',
  [InterviewLevel.FourthInterview]: 'Fourth Interview',
  [InterviewLevel.FinalInterview]: 'Final Interview',
};
export const Resumetatus: any = [
  {
    label: 'CRM Validation Pending',
    value: 'inReview',
  },
  {
    label: 'CRM Validated',
    value: 'approved',
  },
  {
    label: 'CRM Rejected',
    value: 'rejected',
  },
  {
    label: 'CV Sent/Feedback Awaiting',
    value: 'FA',
  },
  {
    label: 'Shortlisted',
    value: 'S',
  },
  {
    label: 'CV Rejected',
    value: 'R',
  },
  {
    label: 'Interview Scheduled',
    value: 'IS',
  },
  {
    label: 'Interview Selected',
    value: 'interviewSelected',
  },
  {
    label: 'Interview Rejected',
    value: 'interviewRejected',
  },
  {
    label: 'Offer Pending',
    value: 'OP',
  },
  {
    label: 'Joined',
    value: 'J',
  },
  {
    label: 'Not Joined',
    value: 'NJ',
  },
  {
    label: 'Deffered',
    value: 'JD',
  },
  {
    label: 'Billed',
    value: 'B',
  },
  {
    label: 'Draft',
    value: 'draft',
  },
];
export const InterviewStatus: any = [
  {
    label: 'Interview Scheduled',
    value: 'IS',
  },
  {
    label: 'Interview Selected',
    value: 'interviewSelected',
  },
  {
    label: 'Interview Rejected',
    value: 'interviewRejected',
  },
  {
    label: 'Offer Pending',
    value: 'OP',
  },
  {
    label: 'Joined',
    value: 'J',
  },
  {
    label: 'Not Joined',
    value: 'NJ',
  },
  {
    label: 'Deffered',
    value: 'JD',
  },
  {
    label: 'Billed',
    value: 'B',
  },
];
export const interviewSechdule = ['ISA', '2ISA', '3ISA', '4ISA', '5ISA'];
export const interviewResechdule = ['1IS', '2IS', '3IS', '4IS', '5IS'];
export const interviewDirectSelect = ['ISA', 'S', 'R'];
export const interviewSelectReject = ['S', 'R'];

export enum ResumeStatusType {
  APPROVAL = 'approval', // resume approve/reject status type
  SCREENING = 'screening',
  INTERVIEW = 'interview',
  OFFER = 'offer',
  JOINING = 'joining',
  BILLING = 'billing',
  L1BillingAppoval = 'l1BillingApproval',
  BhBillingAppoval = 'BhBillingApproval',
}

export enum ResumeApprovalStatus {
  InReview = 'inReview',
  Approved = 'approved',
  Rejected = 'rejected',
  Draft = 'draft',
}

export enum ScreeningStatus {
  SHORTLISTED = 'S',
  REJECTED = 'R',
}
export const RejetedStatus = ['R', 'OR', 'NJ', 'rejected'];

export enum MailBoxType {
  SendCvToClient = 'sendCvToClient',
  InterviewConfirmationToClient = 'interviewConfirmationToClient',
  InterviewConfirmationToCandidate = 'interviewConfirmationToCandidate',
}

export const mailBoxTypeToText: Record<MailBoxType, string> = {
  [MailBoxType.SendCvToClient]: 'Send CV to client',
  [MailBoxType.InterviewConfirmationToClient]: 'Interview Confirmation to Client',
  [MailBoxType.InterviewConfirmationToCandidate]: 'Schedule Interview to Candidates',
};

export const AccessDenied = 'Access is Denied, Please Contact Administrator for the Access';

export const getResumeStatus = (
  approvalStatus: string,
  cvSent?: boolean,
  resumeStatusType?: string,
  resumeStatus?: string,
) => {
  switch (approvalStatus) {
    case ResumeApprovalStatus.Approved:
      if (resumeStatusType) {
        if (cvSent) {
          if (resumeStatusType === ResumeStatusType.INTERVIEW) {
            if (resumeStatus === 'R') {
              return 'Interview Rejected';
            }
            return 'Interview Scheduled';
          } else if (resumeStatusType === ResumeStatusType.SCREENING) {
            if (resumeStatus === ScreeningStatus.SHORTLISTED) {
              return 'CV Shortlisted';
            } else if (resumeStatus === ScreeningStatus.REJECTED) {
              return 'CV Rejected';
            }
          } else if (resumeStatusType === ResumeStatusType.OFFER) {
            return 'Offer Pending';
          }
          return 'CV Sent';
        } else if (resumeStatusType === ResumeStatusType.APPROVAL) {
          if (resumeStatus === ResumeApprovalStatus.Approved) {
            return 'CRM Validated';
          }
        }
      }
      break;
    case ResumeApprovalStatus.Rejected:
      return 'CRM Rejected';
    case ResumeApprovalStatus.InReview:
      return 'CRM Validation Pending';
    case ResumeApprovalStatus.Draft:
      return 'Draft';
    default:
      return '-';
  }
};

const billingStatusToText = (status: string, color?: boolean): string => {
  switch (status) {
    case 'B':
      return color ? '#52E0E0' : 'Billed';
    default:
      return color ? '#77F36E' : 'Joined';
  }
};

const joiningStatusToText = (status: string, color?: boolean): string => {
  switch (status) {
    case 'J':
      return color ? '#77F36E' : 'Joined';
    case 'JD':
      return color ? '#C79767' : 'Joining Deferred';
    case 'NJ':
      return color ? '#CBF340' : 'Not Joined';
    default:
      return color ? '#33AAFF' : 'Offer Accepted';
  }
};

const offerStatusToText = (status: string, color?: boolean): string => {
  switch (status) {
    case 'OA':
      return color ? '#33AAFF' : 'Offer Accepted';
    case 'OR':
      return color ? '#FFBDED' : 'Offer Rejected';
    default:
      return color ? '#008FFB' : 'Offer Pending';
  }
};

const interviewStatusToText = (status: string, color?: boolean): string => {
  switch (status) {
    case '5IS':
    case '4IS':
    case '3IS':
    case '2IS':
    case '1IS':
      return color ? '#F59E0B' : 'Interview Scheduled';
    case '2ISA':
      return color ? '#F042BC' : '1st round selected';
    case '3ISA':
      return color ? '#F042BC' : '2nd round selected';
    case '4ISA':
      return color ? '#F042BC' : '3rd round selected';
    case '5ISA':
      return color ? '#F042BC' : '4th round selected';
    case 'R':
      return color ? '#F3733F' : 'Interview Rejected';
    case 'S':
      return color ? '#F042BC' : 'Interview Selected';
    default:
      return color ? '#5CC8BE' : 'CV Shortlisted';
  }
};

const screeningStatusToText = (status: string, color?: boolean): string => {
  switch (status) {
    case ScreeningStatus.SHORTLISTED:
      return color ? '#5CC8BE' : 'CV Shortlisted';
    case ScreeningStatus.REJECTED:
      return color ? '#AF52DE' : 'CV Rejected';
    default:
      return color ? '#F7CB45' : 'CV Sent';
  }
};

const crmStatusToText = (status: string, color?: boolean): string => {
  switch (status) {
    case 'approved':
      return color ? '#008FFB' : 'CRM Validated';
    case 'rejected':
      return color ? '#F59E0B' : 'CRM Rejected';
    default:
      return color ? '#F042BC' : 'CRM Validation Pending';
  }
};
export const currentResumeStatus = (resumeStatus: any[], color?: boolean): string | null => {
  const billing = resumeStatus.find((r) => r.type === ResumeStatusType.BILLING);
  const joining = resumeStatus.find((r) => r.type === ResumeStatusType.JOINING);
  const offer = resumeStatus.find((r) => r.type === ResumeStatusType.OFFER);
  const interview = resumeStatus.find((r) => r.type === ResumeStatusType.INTERVIEW);
  const screening = resumeStatus.find((r) => r.type === ResumeStatusType.SCREENING);
  const approval = resumeStatus.find((r) => r.type === ResumeStatusType.APPROVAL);
  if (billing) {
    return billingStatusToText(billing.status, color);
  }
  if (joining) {
    return joiningStatusToText(joining.status, color);
  }
  if (offer) {
    return offerStatusToText(offer.status, color);
  }
  if (interview) {
    return interviewStatusToText(interview.status, color);
  }
  if (screening) {
    return screeningStatusToText(screening.status, color);
  }
  if (approval) {
    return crmStatusToText(approval.status, color);
  }
  return null;
};

export const currentResumeReason = (resumeStatus: any[]): string | null => {
  const billing = resumeStatus.find((r) => r.type === ResumeStatusType.BILLING);
  const joining = resumeStatus.find((r) => r.type === ResumeStatusType.JOINING);
  const offer = resumeStatus.find((r) => r.type === ResumeStatusType.OFFER);
  const interview = resumeStatus.find((r) => r.type === ResumeStatusType.INTERVIEW);
  const screening = resumeStatus.find((r) => r.type === ResumeStatusType.SCREENING);
  const approval = resumeStatus.find((r) => r.type === ResumeStatusType.APPROVAL);
  if (billing) {
    return billing.reason;
  }
  if (joining) {
    return joining.reason;
  }
  if (offer) {
    return offer.reason;
  }
  if (interview) {
    return interview.reason;
  }
  if (screening) {
    return screening.reason;
  }
  if (approval) {
    return approval.reason;
  }
  return null;
};

export const currentResumeStage = (resumeStatus: any[]): string | null => {
  const billing = resumeStatus.find((r) => r.type === ResumeStatusType.BILLING);
  const joining = resumeStatus.find((r) => r.type === ResumeStatusType.JOINING);
  const offer = resumeStatus.find((r) => r.type === ResumeStatusType.OFFER);
  const interview = resumeStatus.find((r) => r.type === ResumeStatusType.INTERVIEW);
  const screening = resumeStatus.find((r) => r.type === ResumeStatusType.SCREENING);
  const approval = resumeStatus.find((r) => r.type === ResumeStatusType.APPROVAL);
  if (billing) {
    return billing.status === 'BP' ? 'Joining' : 'Billing';
  }
  if (joining) {
    return joining.status === 'JA' ? 'Offer' : 'Joining';
  }
  if (offer) {
    return offer.status === 'OP' ? 'Interview' : 'Offer';
  }
  if (interview) {
    return interview.status === 'ISA' ? 'Screening' : 'Interview';
  }
  if (screening) {
    return screening.status === 'FA' ? 'CRM validation' : 'Screening';
  }
  if (approval) {
    return 'CRM validation';
  }
  return null;
};

export const resumeStatusTextToBgColor: Record<string, string> = {
  'CRM Validated': '#16C06E',
  'CRM Rejected': '#EE4B22',
  'CV Shortlisted': '#16C06E',
  'CV Rejected': '#EE4B22',
  'Interview Selected': '#16C06E',
  'Interview Scheduled': '#16C06E',
  'Interview Rejected': '#EE4B22',
  'Offer Accepted': '#16C06E',
  'Offer Rejected': '#EE4B22',
  Joined: '#16C06E',
  'Joining Deferred': '#16C06E',
  'Not Joined': '#EE4B22',
  Billed: '#16C06E',
};

export enum BillingStatus {
  BILLING_PENDING = 'BP',
  BILLED = 'B',
  L1ApprovalPending = 'l1ApprovalPending',
  L1Approved = 'l1Approved',
  L1Rejected = 'l1Rejected',
  BhApprovalPending = 'bhApprovalPending',
  BhApproved = 'bhApproved',
  BhRejected = 'bhRejected',
}
