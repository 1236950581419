import { Grid, TextField, Typography } from '@mui/material';
import CustomSelect from 'components/CustomSelect/customSelect';
import moment from 'moment';
import React, { useState } from 'react';
import {
  Control,
  Controller,
  FormState,
  UseFormGetValues,
  UseFormHandleSubmit,
  UseFormTrigger,
  UseFormWatch,
} from 'react-hook-form';
import { InterviewFilterType } from 'types';
import { InterviewStatus } from 'utils/helper';

interface InterviewFilterFormProps {
  control: Control<InterviewFilterType, any>;
  handleSubmit: UseFormHandleSubmit<InterviewFilterType, undefined>;
  formState: FormState<InterviewFilterType>;
  onSubmit: (data: InterviewFilterType) => void;
  watch: UseFormWatch<InterviewFilterType>;
  trigger: UseFormTrigger<InterviewFilterType>;
  getValues: UseFormGetValues<InterviewFilterType>;
  setParams: any;
}

export const InterviewFilterForm: React.FC<InterviewFilterFormProps> = ({
  control,
  handleSubmit,
  // formState: { errors },
  onSubmit,
  watch,
}) => {
  // const currentDate = moment().format('YYYY-MM-DD');
  const [fromDate, setFromDate] = useState<string | null>(null);
  // const [toDate, setToDate] = useState<string | null>(null);
  // useEffect(() => {
  //   getAllListData(
  //     `${SETTING_ENDPOINT.users}/lite?roles=2,3,4,5&organizationId=${organizationId}${
  //       roleId !== 6 ? `&crmUserId=${userId}` : ''
  //     }&sortby=name&order=asc`,
  //     true,
  //   )
  //     .then((res: any) => {
  //       const options: any = [];
  //       res.data.map((e: any) => {
  //         options.push({ label: `${e.firstName} ${e.lastName}`, value: e.id });
  //       });
  //       setEmployee(options);
  //     })
  //     .catch((e: any) => {
  //       console.log(e);
  //     });
  // }, []);
  return (
    <form className='private-form clientfilter-form' onSubmit={handleSubmit(onSubmit)}>
      <Grid container>
        <Grid item md={12} sm={12} xs={12} sx={{ width: '100%' }}>
          <Grid container sx={{ width: '100%' }}>
            <Grid item md={12} sm={12} xs={12} sx={{ width: '100%' }}>
              <Typography component={'div'} className='custom-field' sx={{ width: '100%' }}>
                <Typography component={'p'}>From Date</Typography>
                <Controller
                  control={control}
                  defaultValue={undefined}
                  name='fromDate'
                  render={({ field: { onChange, value } }) => {
                    return (
                      <TextField
                        id='date'
                        type='date'
                        value={value ? value : null}
                        className='clientfilter-time-from-to'
                        onChange={(e) => {
                          setFromDate(moment(e.target.value).format('YYYY-MM-DD'));
                          onChange(e);
                          // setParams((prev: any) => ({
                          //   ...prev,
                          //   from: e.target.value,
                          // }));
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    );
                  }}
                />
              </Typography>
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <Typography component={'div'} className='custom-field'>
                <Typography component={'p'}>To Date</Typography>
                <Controller
                  control={control}
                  defaultValue={undefined}
                  name='toDate'
                  render={({ field: { onChange, value } }) => {
                    return (
                      <TextField
                        id='date'
                        type='date'
                        value={value ? value : null}
                        className='clientfilter-time-from-to'
                        onChange={(e) => {
                          // setToDate(moment(e.target.value).format('YYYY-MM-DD'));
                          onChange(e);
                        }}
                        // className={classes.textField}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        InputProps={{
                          inputProps: { min: fromDate },
                        }}
                      />
                    );
                  }}
                />
              </Typography>
            </Grid>
            {/* <Grid item md={12} sm={12} xs={12}>
              <Typography component={'div'} className='custom-field'>
                <Typography component={'p'}>Employee</Typography>
                <Controller
                  control={control}
                  name='employee'
                  defaultValue={undefined}
                  render={({ field: { onChange, value, name } }) => {
                    return (
                      <CustomSelect
                        labelKey={'label'}
                        valueKey={'value'}
                        options={employee}
                        name={name}
                        isClearable={false}
                        value={value ? value : null}
                        onChange={(e) => {
                          onChange(e);
                        }}
                        placeHolder='Select Employee'
                      />
                    );
                  }}
                />
              </Typography>
            </Grid> */}
            <Grid item md={12} sm={12} xs={12}>
              <Typography component={'div'} className='custom-field'>
                <Typography component={'p'}>Current Status</Typography>
                <Controller
                  control={control}
                  name='currentStatus'
                  defaultValue={undefined}
                  render={({ field: { onChange, value, name } }) => {
                    return (
                      <CustomSelect
                        labelKey={'label'}
                        valueKey={'value'}
                        isClearable={false}
                        options={InterviewStatus}
                        name={name}
                        value={value ? value : null}
                        onChange={(e) => {
                          onChange(e);
                        }}
                        placeHolder='Select Status'
                      />
                    );
                  }}
                />
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <button
        type='submit'
        className='clientfilter-form-btn'
        style={{
          ...(!(watch('currentStatus') || watch('employee') || watch('fromDate') || watch('toDate'))
            ? { opacity: '0.5', cursor: 'not-allowed' }
            : {}),
        }}>
        Apply
      </button>
    </form>
  );
};
