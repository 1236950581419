import React, { useEffect, useState } from 'react';
import BackImg from 'assets/png/interior.png';
import CTLogo from 'assets/png/cthiring_new_logo.png';
import { Button, Checkbox, FormControlLabel, Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { yupResolver } from '@hookform/resolvers/yup';
import CustomInput from 'components/CustomInput/customInput';
import { useForm, Controller } from 'react-hook-form';
import { login } from 'store/actions/UserAction';
import { useAppDispatch } from 'store/hooks';
import { LoginForm } from 'types';
import { loginSchema } from 'utils/ValidatorSchema';
import { useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { toast } from 'react-toastify';
import { alertOptions } from 'utils/Config';

const CheckIcon = styled('span')(({ theme }) => ({
  borderRadius: 4,
  width: 16,
  height: 16,
  outline: '1px solid #EE4B22',
  backgroundColor: theme.palette.mode === 'dark' ? '#fff' : '#fff',
  '.Mui-focusVisible &': {
    outline: '1px auto #EE4B22',
    outlineOffset: 1,
  },
  'input:hover ~ &': {
    backgroundColor: theme.palette.mode === 'dark' ? '#ffffff' : '#ffffff',
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background: theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
  },
}));

const CheckedIcon = styled(CheckIcon)({
  backgroundColor: '#EE4B22',
  '&:before': {
    display: 'block',
    width: 16,
    height: 16,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: '#EE4B22',
  },
});

const Login = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [rememberMe, setRememberMe] = useState(false);
  const [cookies, setCookie] = useCookies(['email', 'password']);
  const {
    control: control,
    handleSubmit: handleSubmit,
    reset: resetForm,
    setValue,
    formState: { errors },
  } = useForm<LoginForm>({
    criteriaMode: 'all',
    defaultValues: {
      email: '',
      password: '',
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(loginSchema),
  });
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const pswRegex = /^.{8,23}$/;
  const onSubmit = (data: any) => {
    if (rememberMe) {
      setCookie('email', data.email);
      setCookie('password', data.password);
    }
    if (data.email.match(regex)) {
      if (data.password.match(pswRegex)) {
        login(data.email, data.password, dispatch, navigate);
        resetForm();
      } else {
        toast.error('Invalid password', alertOptions);
      }
    } else {
      toast.error('Invalid email', alertOptions);
    }
  };

  useEffect(() => {
    if (cookies.email) {
      setValue('email', cookies.email);
      setValue('password', cookies.password);
    }
  }, [cookies]);
  return (
    <Typography
      component={'div'}
      sx={{ backgroundImage: `url(${BackImg})` }}
      className='login-banner'>
      <Grid container className='px-40'>
        <Grid item md={6} sm={12} xs={12} className=''>
          <Typography component={'div'} className='pl-15'>
            <Typography className='mb-36 mt-5r'>
              <img src={CTLogo} alt='Carrier Tree' />
            </Typography>
            <Typography className='banner-text'>
              We have the <span>capabilities</span> and <span>experience</span> to navigate you in
              <span>finding solutions</span> you need to move forward
            </Typography>
          </Typography>
        </Grid>
        <Grid item md={6} sm={12} xs={12}>
          <Typography component={'div'} className='login-form'>
            <Typography component={'div'} className='d-flex flex-sb mpad-20 log-title'>
              <Typography component={'div'} className='wel-text'>
                Welcome to{' '}
                <img src={CTLogo} alt='Carrier Tree' style={{ width: '142px', height: '22px' }} />
                <Typography className='logo-text '>Sign In</Typography>
              </Typography>
              {/* <Typography className='signup-txt'>
                No Account ?<br />
                <span>Sign up</span>
              </Typography> */}
            </Typography>
            <Typography component={'div'} className='login-box'>
              {/* <Typography className="s-dis">Welcome back! Please enter your details.</Typography> */}
              <form id={'login-form'} onSubmit={handleSubmit(onSubmit)}>
                <Typography component={'div'} className='custom-field'>
                  <Typography component={'p'}>Enter email address</Typography>
                  <Controller
                    control={control}
                    name='email'
                    render={({ field: { onChange, value, name } }) => {
                      return (
                        <CustomInput
                          value={value}
                          name={name}
                          className='custom-input'
                          placeHolder='Email address'
                          error={errors.email?.message}
                          onChange={(e) => {
                            onChange(e);
                          }}
                          autoFocus={true}
                        />
                      );
                    }}
                  />
                </Typography>
                <Typography component={'div'} className='custom-field'>
                  <Typography component={'p'}>Enter your password</Typography>
                  <Controller
                    control={control}
                    name='password'
                    render={({ field: { onChange, value, name } }) => {
                      return (
                        <CustomInput
                          value={value}
                          name={name}
                          type='password'
                          isSecureTextEntry={true}
                          className='custom-input'
                          placeHolder='Password'
                          error={errors.password?.message}
                          onChange={(e) => {
                            onChange(e);
                          }}
                        />
                      );
                    }}
                  />
                </Typography>
                <Grid container className='mb-24'>
                  <Grid item md={7} sm={12} xs={12}>
                    <Typography>
                      <FormControlLabel
                        label='Remember me'
                        control={
                          <Checkbox
                            className='custom-checkbox'
                            sx={{
                              '&:hover': { bgcolor: 'transparent' },
                            }}
                            value={rememberMe}
                            disableRipple
                            color='default'
                            checkedIcon={<CheckedIcon />}
                            icon={<CheckIcon />}
                            onChange={(e) => setRememberMe(e.target.checked)}
                          />
                        }
                      />
                    </Typography>
                  </Grid>
                  <Grid item md={5} sm={12} xs={12} className='flex-end d-flex-a'>
                    <Typography
                      className='forgot-text'
                      onClick={() => navigate('/forgot-password')}>
                      Forgot Password
                    </Typography>
                  </Grid>
                </Grid>
                <Typography className='d-flex-ja'>
                  <Button form='login-form' className='submit-btn' type={'submit'}>
                    Sign In
                  </Button>
                </Typography>
              </form>
              {errors.email?.message || errors.password?.message ? (
                <div style={{ height: '28px' }}></div>
              ) : (
                <></>
              )}
            </Typography>
            <Typography className='for-dis ml-60 mt-8'>
              <span style={{ color: 'red' }}>*</span>Please Enable the Location to Login the
              application.
            </Typography>
            {/* <Typography className="w-100 text-left foot-text">© CT - Hiring 2023</Typography> */}
          </Typography>
        </Grid>
      </Grid>
    </Typography>
  );
};

export default Login;
