import Closepng from 'assets/png/close.svg';
import { Modal, Box, Typography } from '@mui/material';
import Spoclocation from 'assets/png/spocLocation.svg';
import LeftIco from 'assets/png/LeftArrowIcon.svg';
import PrintIco from 'assets/png/printIcon.svg';

import { useState, Fragment, useEffect } from 'react';
import { patchData } from 'services/CommonServices';
import { ClientsENDPOINT } from 'utils/Constant';
import { AdminPrivateRoutes } from 'routes/routeConstans';
import { useNavigate } from 'react-router-dom';
import CustomInput from 'components/CustomInput/customInput';
import { Controller, useForm } from 'react-hook-form';
import ImageWithAuth from 'components/ProfileImage';
import Dummyimgn from 'assets/png/cticon.png';
import { yupResolver } from '@hookform/resolvers/yup';
import { ClientRejectSchema } from 'utils/ValidatorSchema';
import NCAdedIcon from 'assets/iconcomponents/noclientsadded';
import _ from 'lodash';
const Modalclient = ({
  open,
  handleClose,
  Approved,
  clientData,
  setLoading,
}: {
  open: boolean;
  handleClose: () => void;
  Approved: boolean;
  clientData?: any;
  setLoading?: any;
}) => {
  const [openloca, setOpenloca] = useState(false);
  const [isRejectionModalOpened, setRejectionModalOpen] = useState(false);
  const [Print, setPrint] = useState(false);

  const handleRejectOpen = () => setRejectionModalOpen(true);

  const handleRejectClose = () => {
    reset();
    setRejectionModalOpen(false);
  };
  const [localspocaddress, setlocalspocaddress] = useState<any>({});
  const [opnspocbtndsc, setopnspocbtndsc] = useState(false);
  const handleOpenloca = () => setOpenloca(true);
  const handleCloseloca = () => {
    setopnspocbtndsc(!true);
    setOpenloca(false);
  };
  const handleOpenspocdsc = () => setopnspocbtndsc(true);
  const handleClosespocdsc = () => {
    setlocalspocaddress({});
    setopnspocbtndsc(false);
  };
  // const printref: React.MutableRefObject<any> = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (Print) {
      // // printref && printref.current && printref.current.innerHtml;
      window.print();
      setPrint(false);
    }
  }, [Print]);

  const approveClient = (clientId: string) => {
    setLoading(true);
    patchData(
      '',
      { approvalStatus: 'approved' },
      `${ClientsENDPOINT.Client}/status/${clientId}`,
      true,
    )
      .then(() => {
        setLoading(false);

        navigate(AdminPrivateRoutes.CLIENTS.CLIENTVIEW);
      })
      .catch((e) => {
        setLoading(false);
        console.log(e);
      });
  };

  const rejectClient = (clientId: string, comments: string) => {
    setLoading(true);
    patchData(
      '',
      { approvalStatus: 'rejected', comments },
      `${ClientsENDPOINT.Client}/status/${clientId}`,
      true,
    )
      .then(() => {
        setLoading(false);
        navigate(AdminPrivateRoutes.CLIENTS.CLIENTVIEW);
      })
      .catch((e) => {
        setLoading(false);
        console.log(e);
      });
  };

  const {
    control,
    reset,
    formState: { errors },
    handleSubmit: handleSubmit,
  } = useForm<{ comments: string }>({
    criteriaMode: 'all',
    defaultValues: {
      comments: '',
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(ClientRejectSchema),
  });

  const onSubmitForm = (data: any) => {
    rejectClient(clientData.id, data.comments);
  };

  if (_.isEmpty(clientData)) {
    return (
      <Modal open={open} onClose={handleClose}>
        <Box className='client-apruv-modal'>
          <header>
            <img src={Closepng} alt='close' className='closepng' onClick={handleClose} />
          </header>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              marginBottom: '43px',
              height: '90%',
            }}>
            <NCAdedIcon />
            <Typography className='no-client'>Client Details Not Found</Typography>
          </div>
        </Box>
      </Modal>
    );
  }
  return (
    <Modal open={open} onClose={handleClose}>
      <Box className='client-apruv-modal'>
        <header>
          <div className='modal-header'>
            <span style={{ width: '80px', height: '80px' }}>
              {clientData.logoId ? (
                <ImageWithAuth
                  styled={{
                    width: '100%',
                    height: '100%',
                    borderRadius: '100%',
                    objectFit: 'cover',
                    transform: 'translateY(-4px)',
                  }}
                  id={clientData.logoId}
                />
              ) : (
                <img
                  src={Dummyimgn}
                  style={{
                    width: '100%',
                    height: '100%',
                    borderRadius: '100%',
                    objectFit: 'cover',
                    transform: 'translateY(-4px)',
                  }}
                />
              )}
            </span>
            <div>
              <div
                className='cnt-info'
                style={{ transform: Approved ? 'translateY(16px)' : 'translateY(0px)' }}>
                <div className='support'>{clientData.corporateName}</div>
                <div className='location'>{`${clientData.city}, ${clientData.state}`}</div>
              </div>

              {!Approved ? <button className='main-view-btn'>Awaiting Approval</button> : <></>}
            </div>
          </div>
          {!Approved ? (
            <div className='buttons'>
              {' '}
              {!clientData?.approvedBy && (
                <button onClick={handleRejectOpen} className='client-aprv-btn'>
                  Reject
                </button>
              )}
              <button
                onClick={() => approveClient(clientData.id)}
                className='client-aprv-btn aprvd-btn-2'>
                Approve
              </button>
            </div>
          ) : (
            <div className='active-inactive-right-modal'>
              <header>Client Status</header>
              <main className={clientData && clientData.status ? 'active-badge' : 'inactive-badge'}>
                {clientData && clientData.status ? 'Active' : 'Inactive'}
              </main>
            </div>
          )}
          <img src={Closepng} alt='close' className='closepng' onClick={handleClose} />
        </header>
        {!Approved && clientData && !_.isEmpty(clientData.history) ? (
          <>
            <div style={{ width: '100%', backgroundColor: '#EDEDED', height: '1px' }}></div>
            <div className='corporate-heading corporate-reason-heading'>Reason for Editing</div>
            {clientData.history.map((li1: any, idx23: number) => {
              return !_.isEmpty(li1) && !_.isEmpty(li1.reason) ? (
                <main key={idx23} className='main-reason-modal'>
                  {li1.reason}
                </main>
              ) : (
                <></>
              );
            })}
            <div style={{ height: '30px' }}></div>
          </>
        ) : (
          <></>
        )}
        <div style={{ width: '100%', backgroundColor: '#EDEDED', height: '1px' }}></div>
        <div className='corporate-heading'>Corporate Details</div>
        <div className='first-section'>
          <div className='section-cards'>
            <header>Address</header>
            <main>{`${clientData && clientData.address1 ? clientData.address1 : ''} ${
              clientData && clientData.address2 ? clientData.address2 : ''
            }`}</main>
          </div>
          <div className='section-cards'>
            <header>Country</header>
            <main>{clientData && clientData.country ? clientData.country : '-'}</main>
          </div>
          <div className='section-cards'>
            <header>Pin Code</header>
            <main>{clientData && clientData.pincode ? clientData.pincode : '-'}</main>
          </div>
          <div className='section-cards'>
            <header>City</header>
            <main>{clientData && clientData.city ? clientData.city : '-'}</main>
          </div>
          <div className='section-cards'>
            <header>State</header>
            <main>{clientData && clientData.state ? clientData.state : '-'}</main>
          </div>
          <div className='section-cards'>
            <header>Comments</header>
            <main>{clientData && clientData.comments ? clientData.comments : '-'}</main>
          </div>
        </div>
        <div className='corporate-heading'>CRM Details</div>
        {Array.isArray(clientData.clientsCrm) &&
          clientData.clientsCrm.length &&
          clientData.clientsCrm.map((crm: any, i: number) => (
            <div
              className='first-section'
              key={i}
              style={{ marginTop: i !== 0 ? '16px' : 'inherit' }}>
              {/* {i !== 0 ? <div style={{height:'16px',width:'100%'}}/>:<></>} */}
              <div className='section-cards'>
                <header>Title</header>
                <main>{crm && crm.user.title ? crm.user.title : '-'}</main>
              </div>
              <div className='section-cards'>
                <header>First Name</header>
                <main>{crm && crm.user.firstName ? crm.user.firstName : '-'}</main>
              </div>
              <div className='section-cards'>
                <header>Last Name</header>
                <main>{crm && crm.user.lastName ? crm.user.lastName : '-'}</main>
              </div>
              <div className='section-cards'>
                <header>Designation</header>
                <main>{crm && crm.user.designation ? crm.user.designation : '-'}</main>
              </div>
              <div className='section-cards'>
                <header>Email</header>
                <main>{crm && crm.user.login.email ? crm.user.login.email : '-'}</main>
              </div>
              <div className='section-cards'>
                <header>Phone No.</header>
                <main>
                  {(crm && crm.user && crm.user.countryCode ? crm.user.countryCode : '') +
                    '-' +
                    (crm && crm.user && crm.user.phoneNumber ? crm.user.phoneNumber : '-')}
                </main>
              </div>
              {/* <div className='section-cards'>
                <header>Mobile No.</header>
                <main>+91-8101406716</main>
              </div> */}
            </div>
          ))}
        <div className='corporate-heading spoc-detl'>SPOC Details</div>
        {Approved ? (
          <div className='spoc-location' onClick={handleOpenloca}>
            <img src={Spoclocation} alt='spoc location image' />
            <span className='spoc-loc-text'>View Address Book</span>
          </div>
        ) : (
          <div className='spoc-location'></div>
        )}

        {Array.isArray(clientData.clientsSpoc) &&
          clientData.clientsSpoc.length > 0 &&
          clientData.clientsSpoc.map((spoc: any, i: number) => (
            <Fragment key={i}>
              {/* {i !== 0 ? <div style={{height:'16px',width:'100%'}}/>:<></>} */}
              <div
                className='first-section spoc-section'
                style={{ marginTop: i !== 0 ? '16px' : 'inherit' }}>
                <div className='section-cards'>
                  <header>Title</header>
                  <main>
                    {spoc && spoc.title
                      ? spoc.title.charAt(0).toUpperCase() + spoc.title.slice(1)
                      : '-'}
                    .
                  </main>
                </div>
                <div className='section-cards'>
                  <header>First Name</header>
                  <main>{spoc && spoc.firstName ? spoc.firstName : '-'}</main>
                </div>
                <div className='section-cards'>
                  <header>Last Name</header>
                  <main>{spoc && spoc.lastName ? spoc.lastName : '-'}</main>
                </div>
                <div className='section-cards'>
                  <header>Designation</header>
                  <main>
                    {spoc && spoc.designation && spoc.designation.name
                      ? spoc.designation.name
                      : '-'}
                  </main>
                </div>
                <div className='section-cards'>
                  <header>Email</header>
                  <main>{spoc && spoc.email ? spoc.email : '-'}</main>
                </div>
                <div className='section-cards'>
                  <header>Phone No.</header>
                  <main>
                    {spoc && spoc.countryCode && spoc.phoneNumber
                      ? spoc.countryCode + '-' + spoc.phoneNumber
                      : '-'}
                  </main>
                </div>
                <div className='section-cards'>
                  <header>SPOC Status</header>
                  <main>{spoc && spoc.status ? 'Active' : 'InActive'}</main>
                </div>
                <div className='section-cards'>
                  <header>SPOC’s Branch</header>
                  <main>{spoc && spoc.branch && spoc.branch.name ? spoc.branch.name : '-'}</main>
                </div>
              </div>
              {/* -- */}
              <div className='corporate-heading' style={{ opacity: 0 }}>
                Corporate Details
              </div>

              <div className='first-section spoc-section'>
                <div className='section-cards'>
                  <header>Address</header>
                  <main>{`${spoc && spoc.address1 ? spoc.address1 : ''} ${
                    spoc && spoc.address2 ? spoc.address2 : ''
                  }`}</main>
                </div>
                <div className='section-cards'>
                  <header>Country</header>
                  <main>{spoc && spoc.country ? spoc.country : '-'}</main>
                </div>
                <div className='section-cards'>
                  <header>Pin Code</header>
                  <main>{spoc && spoc.pincode ? spoc.pincode : '-'}</main>
                </div>
                <div className='section-cards'>
                  <header>City</header>
                  <main>{spoc && spoc.city ? spoc.city : '-'}</main>
                </div>
                <div className='section-cards'>
                  <header>State</header>
                  <main>{spoc && spoc.state ? spoc.state : '-'}</main>
                </div>
              </div>
            </Fragment>
          ))}
        <Modal open={openloca} onClose={handleCloseloca}>
          <Box
            className='spoc-list-modal'
            style={{ overflow: !opnspocbtndsc ? 'hidden' : 'inherit' }}>
            {!opnspocbtndsc ? (
              <>
                <img src={Closepng} alt='close' className='closepng' onClick={handleCloseloca} />
                <header>SPOC ADDRESS</header>
                <div className='divider'></div>
                <div className='button-con'>
                  {clientData.clientsSpoc.map((spoc: any, i: number) => {
                    return (
                      <>
                        {spoc && spoc.address1 && (
                          <button
                            onClick={() => {
                              setlocalspocaddress({
                                name:
                                  spoc.firstName && spoc.lastName
                                    ? `${spoc.firstName} ${spoc.lastName}`
                                    : '-',
                                desc:
                                  spoc.designation && spoc.designation.name
                                    ? spoc.designation.name
                                    : '',
                                address: spoc.address1 ? spoc.address1 : '-',
                                address2: spoc.address2 ? spoc.address2 : '-',
                                phone:
                                  spoc.countryCode && spoc.phoneNumber
                                    ? `${spoc.countryCode}-${spoc.phoneNumber}`
                                    : '-',
                              });
                              handleOpenspocdsc();
                            }}
                            key={i}>{`SPOC${i + 1} Address`}</button>
                        )}
                        {/* {spoc && spoc.address2 && <button onClick={() => {
                      setlocalspocaddress({name:spoc.firstName && spoc.lastName ? `${spoc.firstName} ${spoc.lastName}`:'-',desc:spoc.designation && spoc.designation.name ? spoc.designation.name:'',address:spoc.address2 ? spoc.address2 :'-',phone:spoc.countryCode && spoc.phoneNumber ?`${spoc.countryCode}-${spoc.phoneNumber}`:'-'});
                      handleOpenspocdsc();
                     } } key={i}>{`SPOC${i+1} Address2`}</button>} */}
                      </>
                    );
                  })}
                  {/* <button onClick={handleOpenspocdsc}>SPOC1 Address</button> */}
                  {/* <button onClick={handleOpenspocdsc}>SPOC1 Address</button>
                  <button onClick={handleOpenspocdsc}>SPOC1 Address</button>
                  <button onClick={handleOpenspocdsc}>SPOC1 Address</button>
                  <button onClick={handleOpenspocdsc}>SPOC1 Address</button>
                  <button onClick={handleOpenspocdsc}>SPOC1 Address</button>
                  <button onClick={handleOpenspocdsc}>SPOC1 Address</button> */}
                </div>
              </>
            ) : (
              <>
                <nav className={Print ? 'displayNone' : 'spoc-client-info-nav'}>
                  <div className='left-ico'>
                    <img src={LeftIco} alt='lefticon' onClick={handleClosespocdsc} />
                  </div>
                  <div className='right-ico'>
                    <img
                      src={PrintIco}
                      alt='print'
                      onClick={() => {
                        setPrint(true);
                      }}
                    />
                    <img src={Closepng} alt='close' onClick={handleCloseloca} />
                  </div>
                </nav>
                {/* <div ref={printref}> */}
                <div>
                  <main className='spoc-client-info-main'>
                    <header>
                      {localspocaddress && localspocaddress.name ? localspocaddress.name : '-'}
                    </header>
                    <div className='description'>
                      {`${
                        localspocaddress && localspocaddress.desc ? localspocaddress.desc : '-'
                      } - ${
                        clientData && clientData.corporateName
                          ? clientData.corporateName.toUpperCase()
                          : ''
                      }.`}
                      {localspocaddress && localspocaddress.address ? (
                        <div className='address-spocs'>{localspocaddress.address}</div>
                      ) : (
                        <div className='address-spocs'>-</div>
                      )}
                      {localspocaddress && localspocaddress.address2 ? (
                        <div className='address-spocs'>{localspocaddress.address2}</div>
                      ) : (
                        <div className='address-spocs'>-</div>
                      )}
                    </div>
                  </main>
                  <div className='spoc-client-info-divider'></div>
                  <div className='spoc-client-info-phnumber-con'>
                    <div className='landline'>
                      <span className='headng'>Phone No:</span>{' '}
                      {localspocaddress && localspocaddress.phone ? localspocaddress.phone : ''}
                    </div>
                    <div className='mobile'>
                      {/* <span className='headng'>Mobile No:</span> +91-8101406716 */}
                    </div>
                  </div>
                </div>
              </>
            )}
          </Box>
        </Modal>
        <Modal open={isRejectionModalOpened} onClose={handleRejectClose}>
          <form className='private-form' id={'user-add'} onSubmit={handleSubmit(onSubmitForm)}>
            <Box
              className='spoc-list-modal center'
              style={{ overflow: !opnspocbtndsc ? 'hidden' : 'inherit' }}>
              <img src={Closepng} alt='close' className='closepng' onClick={handleRejectClose} />
              <Typography component={'div'} className='custom-field'>
                <Typography component={'p'}>
                  Comments <span>*</span>
                </Typography>
                <Controller
                  render={({ field: { onChange, value, name } }) => (
                    <CustomInput
                      value={value}
                      name={name}
                      isTextArea={true}
                      className={`custom-input client-comment-box ${
                        errors.comments?.message ? 'client-comment-box-error' : ''
                      }`}
                      placeHolder='Write your content here'
                      error={errors.comments?.message}
                      onChange={(e) => {
                        onChange(e);
                      }}
                    />
                  )}
                  name='comments'
                  control={control}
                />
              </Typography>
              {/* <div className='button-con center'>
                <button className='button'>Submit</button>
              </div> */}
              <div className='button-con center'>
                <div className='buttons'>
                  <button
                    type='submit'
                    // onClick={() => reset()}
                    className='client-aprv-btn client-modal-comment-btn'>
                    Submit
                  </button>
                </div>
              </div>
            </Box>
          </form>
        </Modal>
      </Box>
    </Modal>
  );
};

export default Modalclient;
