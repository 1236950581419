import {
  Breadcrumbs,
  TableContainer,
  TableBody,
  TableRow,
  TableCell,
  Grid,
  Typography,
  Button,
  Table,
  Link,
} from '@mui/material';
import HomeIcon from 'assets/png/dashboardactive.svg';
import ArrowIcon from 'assets/png/breadarrow.svg';
import Loader from 'components/loader';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useNavigate, To, useSearchParams } from 'react-router-dom';
import { AdminPrivateRoutes } from 'routes/routeConstans';
import { getAllListData } from 'services/CommonServices';
import { BillingENDPOINT } from 'utils/Constant';

const PaymentMailView = () => {
  const [commonState, setcommonstate] = useState<any[]>([]);
  const [isLoading, setLoading] = useState(true);
  const navigate = useNavigate();
  const gotoNavigate = (link: To) => {
    navigate(link);
  };

  const [searchParams] = useSearchParams();
  useEffect(() => {
    getAllListData(`${BillingENDPOINT.PaymentMail}?id=${searchParams.get('id')}`, true)
      .then((val: any) => {
        if (val && val.data && val.data.length > 0) {
          setcommonstate([...val.data]);
        } else {
          setcommonstate([]);
        }
        setLoading(!true);
      })
      .catch(() => {
        setLoading(!true);
      });
  }, [searchParams.get('id')]);

  return (
    <Grid container>
      {isLoading && <Loader />}
      <Grid item md={12} sm={12} xs={12}>
        <Typography component={'div'} className='breadcrumbs d-flex-a'>
          <Breadcrumbs aria-label='breadcrumb'>
            <Link underline='hover' href='/'>
              <img src={HomeIcon} className='home' alt='Home' />
            </Link>
            <Typography
              color='text.primary'
              sx={{ cursor: 'pointer' }}
              onClick={() => gotoNavigate(AdminPrivateRoutes.MAILBOX.MAILBOXLIST)}>
              {' '}
              <img src={ArrowIcon} alt='arrow' className='arrows' />
              Payment Mail
            </Typography>
            <Typography color='text.primary' sx={{ cursor: 'pointer' }}>
              {' '}
              <img src={ArrowIcon} alt='arrow' className='arrows' /> View Sent Item
            </Typography>
          </Breadcrumbs>
        </Typography>

        <Typography
          component={'div'}
          className='d-flex-a flex-sb title-add-box'
          sx={{ paddingRight: '56px' }}>
          <Typography className='page-title'>View Sent Item</Typography>

          <Button
            className='cancel-btn mr-16'
            onClick={() => gotoNavigate(AdminPrivateRoutes.PAYMENTMAIL.PAYMENTMAILLIST)}>
            Back
          </Button>
        </Typography>

        <Typography component={'div'} className='main-content'>
          <Typography component={'div'} className='white-box'>
            {commonState.length ? (
              <form id={'mail-form'} className='private-form'>
                <TableContainer sx={{ border: '1px solid #E4E7EC', borderRadius: '8px' }}>
                  <Table className='custom-table' aria-label='simple table'>
                    <TableBody>
                      <TableRow
                        sx={{ '&:last-child td, &:last-child th': { border: 0 }, display: 'none' }}>
                        <TableCell></TableCell>
                        <TableCell align='left' sx={{ paddingLeft: '23px !important' }}></TableCell>
                      </TableRow>
                      <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell
                          align='left'
                          sx={{
                            textTransform: 'capitalize',
                            fontWeight: '600',
                            borderRight: '1px solid #E4E7EC',
                            minWidth: '200px',
                          }}>
                          To
                        </TableCell>
                        <TableCell align='left' sx={{ paddingLeft: '23px !important' }}>
                          {commonState[0]?.to}
                        </TableCell>
                      </TableRow>
                      <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell
                          align='left'
                          sx={{
                            textTransform: 'capitalize',
                            fontWeight: '600',
                            borderRight: '1px solid #E4E7EC',
                          }}>
                          Cc
                        </TableCell>
                        <TableCell align='left' sx={{ paddingLeft: '23px !important' }}>
                          {commonState[0]?.cc}
                        </TableCell>
                      </TableRow>
                      <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell
                          align='left'
                          sx={{
                            textTransform: 'capitalize',
                            fontWeight: '600',
                            borderRight: '1px solid #E4E7EC',
                          }}>
                          Subject
                        </TableCell>
                        <TableCell align='left' sx={{ paddingLeft: '23px !important' }}>
                          {commonState[0]?.subject}
                        </TableCell>
                      </TableRow>
                      <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell
                          align='left'
                          sx={{
                            textTransform: 'capitalize',
                            fontWeight: '600',
                            borderRight: '1px solid #E4E7EC',
                          }}>
                          Message
                        </TableCell>
                        <TableCell align='left' sx={{ paddingLeft: '23px !important' }}>
                          <div dangerouslySetInnerHTML={{ __html: commonState[0]?.message }}></div>
                        </TableCell>
                      </TableRow>
                      <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell
                          align='left'
                          sx={{
                            textTransform: 'capitalize',
                            fontWeight: '600',
                            borderRight: '1px solid #E4E7EC',
                          }}>
                          Sent Date
                        </TableCell>
                        <TableCell align='left' sx={{ paddingLeft: '23px !important' }}>
                          {moment(commonState[0]?.createdAt).format('DD-MM-YYYY') ?? '-'}
                        </TableCell>
                      </TableRow>
                      <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell
                          align='left'
                          sx={{
                            textTransform: 'capitalize',
                            fontWeight: '600',
                            borderRight: '1px solid #E4E7EC',
                            minWidth: '200px',
                          }}>
                          Sent By
                        </TableCell>
                        <TableCell
                          align='left'
                          sx={{
                            paddingLeft: '23px !important',
                            borderLeft: '1px solid #e4e7ec !important',
                          }}>
                          {`${commonState[0]?.user?.firstName ?? ' '} ${
                            commonState[0]?.user?.lastName ?? ' '
                          }`}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </form>
            ) : (
              <></>
            )}
          </Typography>
        </Typography>
      </Grid>
    </Grid>
  );
};
export default PaymentMailView;
