import { Drawer } from '@mui/material';
import React, { useCallback, useState } from 'react';
import CloseIcon from 'assets/png/close.svg';
import ResumeFilterForm from './LeaveFilterFrorm';
import { useForm } from 'react-hook-form';
import { leaveFilterType } from 'types';
import { yupResolver } from '@hookform/resolvers/yup';
import { LeaveFilterSchema } from 'utils/ValidatorSchema';

interface LeaveFilterDrawerProps {
  isOpen: boolean;
  closeDrawer: () => void;
  callback: (filters: leaveFilterType) => void;
  setParams?: any;
  setBillingData?: any;
  setLoading?: any;
  setpagetotal?: any;
  listdataapi?: any;
}

export const LeaveFilterDrawer: React.FC<LeaveFilterDrawerProps> = ({
  isOpen,
  closeDrawer,
  setParams,
  listdataapi,
}) => {
  const [key, setKey] = useState(0);

  const { control, handleSubmit, reset, formState, watch, trigger } = useForm<leaveFilterType>({
    criteriaMode: 'all',
    defaultValues: {
      fromDate: undefined,
      toDate: undefined,
      leaveType: undefined,
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(LeaveFilterSchema),
  });

  const closeSideDrawer = useCallback(() => {
    closeDrawer();
  }, [closeDrawer]);

  const resetFilters = useCallback(() => {
    reset();
    listdataapi();
    setKey((prev) => prev + 1);
  }, [reset]);

  const onSubmit = (data: any) => {
    if (data.fromDate || data.toDate || data.leaveType) {
      setParams((prev: any) => ({
        ...prev,
        fromDate: data.fromDate ? data.fromDate : null,
        toDate: data.toDate ? data.toDate : null,
        leaveTypeId: data.leaveType ? data.leaveType : null,
      }));
    }
  };

  return (
    <Drawer
      anchor='right'
      open={isOpen}
      onClose={closeDrawer}
      sx={{
        flexShrink: 0,
        zIndex: 1200,
        ['& .MuiDrawer-paper']: {
          boxSizing: 'border-box',
          marginTop: '72px',
          width: '300px',
          padding: '30px',
          paddingTop: '0px',
        },
        ['& .MuiModal-backdrop ']: { backgroundColor: 'transparent' },
      }}>
      <header className='clientfilter-header'>
        <img
          src={CloseIcon}
          alt='closeicon'
          className='clientfilter-close-ico'
          onClick={closeSideDrawer}
        />
        <div className='heading'>Filters</div>
        <span
          className='clearall'
          style={{
            ...(!(watch('fromDate') || watch('toDate') || watch('leaveType'))
              ? { opacity: '0.5', cursor: 'not-allowed' }
              : {}),
          }}
          onClick={() =>
            watch('fromDate') || watch('toDate') || watch('leaveType') ? resetFilters() : ''
          }>
          Clear All
        </span>
      </header>
      <div className='clientfilter-divider'></div>
      <ResumeFilterForm
        setParams={setParams}
        key={key}
        control={control}
        handleSubmit={handleSubmit}
        formState={formState}
        onSubmit={onSubmit}
        watch={watch}
        trigger={trigger}
      />
    </Drawer>
  );
};
