/* eslint-disable @typescript-eslint/no-unused-vars */
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Grid, Typography, Box } from '@mui/material';
import { To, useNavigate } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { DegreeAddschgemaType } from 'types';
import { DegreeAddschgema } from 'utils/ValidatorSchema';
import { useEffect, useRef, useState } from 'react';
import { addNewData } from 'services/CommonServices';
import { toast } from 'react-toastify';
import CustomInput from 'components/CustomInput/customInput';
import Loader from 'components/loader';
import { useAppSelector } from 'store/hooks';
import { alertOptions } from 'utils/Config';
import CloseIcon from 'assets/png/close.svg';

const DegreeAdd = (props: { close: () => void; qualiId: string | number }) => {
  const {
    userInfo: { organizationId: orgdy },
  }: any = useAppSelector((store: any) => store.userLogin);
  const navigate = useNavigate();
  const [Ld, setLd] = useState(false);
  const subtgref: React.MutableRefObject<any> = useRef();
  const [createRole, setCreateRole] = useState<any>('');
  const [userFunctionaId, setUserFunctionalId] = useState('');
  const gotoNavigate = (link: To) => {
    navigate(link);
  };

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm<DegreeAddschgemaType>({
    defaultValues: {
      qualificationId: '',
      code: undefined,
      degree: '',
    },
    reValidateMode: 'onChange',
    resolver: yupResolver(DegreeAddschgema),
  });

  const onSubmit = async (data: { qualificationId: string; degree: string }) => {
    setLd(true);

    try {
      const datas = await addNewData({ ...data }, 'qualification/degree', undefined, true);
      // console.log(datas, 'datsd');
      if (datas && datas.status >= 200 && datas.status < 300) {
        props.close();
        // gotoNavigate('/settings/functional-area');
        // toast.success(datas && datas.message ? datas.message : '', alertOptions);
      }
    } catch (er: any) {
      // console.log(er);
      // toast.error(er || String(er), alertOptions);
    } finally {
      setLd(!true);
    }
  };

  useEffect(() => {
    setValue('qualificationId', props.qualiId ? String(props.qualiId) : '');
  }, []);

  // console.log(props.qualiId, 'qualiid');
  // console.log(locationpathname, 'locationpathname');
  return (
    <>
      <Box className='Degree-modal'>
        <Typography className='text-right'>
          <img src={CloseIcon} alt='close' style={{ cursor: 'pointer' }} onClick={props.close} />
        </Typography>
        <Typography className='l-title'>Add Degree</Typography>
        <Grid container>
          <Grid item md={12} sm={12} xs={12}>
            <Typography component={'div'} className='custom-field'>
              {/* <Typography component={'p'} sx={{ marginRight: '10px' }}>
                    New Degree
                  </Typography> */}
              <form className='private-form' onSubmit={handleSubmit(onSubmit)}>
                <div style={{ height: '10px' }}></div>
                <Grid container>
                  <Grid item md={12} sm={12} xs={12}>
                    <Grid container>
                      <Grid item md={12} sm={12} xs={12} sx={{ display: 'none' }}>
                        <Typography component={'div'} className='custom-field'>
                          <Typography component={'p'}>
                            Qualification Details Id <span>*</span>
                          </Typography>
                          {/* <TextField className="custom-input" id="standard-basic" placeholder="" variant="standard" /> */}
                          <Controller
                            render={({ field: { onChange, value, name } }) => (
                              <CustomInput
                                value={value}
                                name={name}
                                className='custom-input'
                                error={errors.qualificationId?.message}
                                onChange={(e) => {
                                  onChange(e);
                                }}
                              />
                            )}
                            name='qualificationId'
                            control={control}
                          />
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item md={12} sm={12} xs={12} sx={{ display: 'none' }}>
                    <Grid container>
                      <Grid item md={12} sm={12} xs={12}>
                        <Typography component={'div'} className='custom-field'>
                          <Typography component={'p'}>
                            Code <span>*</span>
                          </Typography>
                          {/* <TextField className="custom-input" id="standard-basic" placeholder="" variant="standard" /> */}
                          <Controller
                            render={({ field: { onChange, value, name } }) => (
                              <CustomInput
                                value={value}
                                name={name}
                                className='custom-input'
                                error={errors.code?.message}
                                onChange={(e) => {
                                  onChange(e);
                                }}
                              />
                            )}
                            name='code'
                            control={control}
                          />
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item md={12} sm={12} xs={12}>
                    <Grid container>
                      <Grid item md={12} sm={12} xs={12}>
                        <Typography component={'div'} className='custom-field'>
                          <Typography component={'p'}>
                            Name <span>*</span>
                          </Typography>
                          {/* <TextField className="custom-input" id="standard-basic" placeholder="" variant="standard" /> */}
                          <Controller
                            render={({ field: { onChange, value, name } }) => (
                              <CustomInput
                                value={value}
                                name={name}
                                className='custom-input'
                                error={errors.degree?.message}
                                onChange={(e) => {
                                  onChange(e);
                                }}
                              />
                            )}
                            name='degree'
                            control={control}
                          />
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                {/* this is for dum,m,y to trigger form */}
                <Button
                  type='submit'
                  variant='contained'
                  color='primary'
                  ref={subtgref}
                  sx={{ display: 'none' }}>
                  Submit
                </Button>
                <div style={{ height: '10px' }}></div>
              </form>
            </Typography>
          </Grid>
        </Grid>
        <Typography className='d-flex-ja'>
          <Button className='l-cancel-btn mr-16' onClick={props.close}>
            Cancel
          </Button>
          <Button className='l-save-btn' onClick={() => subtgref.current.click()}>
            save
          </Button>
        </Typography>
      </Box>
      {Ld && <Loader />}
    </>
  );
};

export default DegreeAdd;
