import React from 'react';
import Schedule from 'assets/png/schedule.svg';
import {
  Box,
  Grid,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import CloseIcon from 'assets/png/close.svg';
import moment from 'moment';

export const ViewInterviewDetails: React.FC<{ resumeDetails: any }> = ({ resumeDetails }) => {
  const [interviewScheduleModalOpen, setInterviewScheduleModalOpen] = React.useState(false);

  const handleModalClose = () => {
    setInterviewScheduleModalOpen(false);
  };

  const handleModalOpen = () => {
    setInterviewScheduleModalOpen(true);
  };

  return (
    <>
      <Typography
        onClick={() => handleModalOpen()}
        className='menu-item'
        style={{ padding: '0px 15px 0px 15px' }}>
        <img src={Schedule} alt='Vi' />
        View Interview Details
      </Typography>
      <Modal open={interviewScheduleModalOpen} onClose={handleModalClose}>
        <Box className='email-modal' sx={{ width: '865px !important' }}>
          <Typography component={'div'} className='d-flex-a flex-sb'>
            <Typography className='l-title'>{'View Interview Details'}</Typography>
            <Typography className='text-right'>
              <img
                src={CloseIcon}
                alt='close'
                onClick={handleModalClose}
                style={{ cursor: 'pointer' }}
              />
            </Typography>
          </Typography>
          <Grid item md={12} sm={12} xs={12} sx={{ marginTop: '15px' }}>
            <TableContainer component={Paper}>
              <Table className='custom-table' aria-label='simple table'>
                <TableBody>
                  {resumeDetails && (
                    <>
                      <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell
                          align='left'
                          sx={{ textTransform: 'capitalize', paddingLeft: '80px !important' }}>
                          Candidate Name
                        </TableCell>
                        <TableCell align='center' sx={{ textTransform: 'capitalize' }}>
                          {resumeDetails?.personal?.firstName
                            ? `${resumeDetails?.personal?.firstName} ${
                                resumeDetails?.personal?.lastName
                                  ? resumeDetails?.personal?.lastName
                                  : ''
                              }`
                            : '-'}
                        </TableCell>
                      </TableRow>
                      <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell
                          align='left'
                          sx={{ textTransform: 'capitalize', paddingLeft: '80px !important' }}>
                          Interview Level
                        </TableCell>
                        <TableCell align='center' sx={{ textTransform: 'capitalize' }}>
                          {resumeDetails?.interview && resumeDetails?.interview[0]?.level
                            ? resumeDetails?.interview[0]?.level
                            : '-'}
                        </TableCell>
                      </TableRow>
                      <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell
                          align='left'
                          sx={{ textTransform: 'capitalize', paddingLeft: '80px !important' }}>
                          Interview Mode
                        </TableCell>
                        <TableCell align='center' sx={{ textTransform: 'capitalize' }}>
                          {resumeDetails?.interview && resumeDetails?.interview[0].mode
                            ? resumeDetails?.interview[0].mode
                            : '-'}
                        </TableCell>
                      </TableRow>
                      <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell
                          align='left'
                          sx={{ textTransform: 'capitalize', paddingLeft: '80px !important' }}>
                          Interview Date
                        </TableCell>
                        <TableCell align='center' sx={{ textTransform: 'capitalize' }}>
                          {resumeDetails?.interview && resumeDetails?.interview[0].dateTime
                            ? moment(resumeDetails?.interview[0].dateTime).format('DD-MM-YYYY')
                            : '-'}
                        </TableCell>
                      </TableRow>
                      <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell
                          align='left'
                          sx={{ textTransform: 'capitalize', paddingLeft: '80px !important' }}>
                          Interview Time
                        </TableCell>
                        <TableCell align='center' sx={{ textTransform: 'capitalize' }}>
                          {resumeDetails?.interview && resumeDetails?.interview[0].dateTime
                            ? moment(resumeDetails?.interview[0].dateTime).format('hh:mm a')
                            : '-'}
                        </TableCell>
                      </TableRow>
                      <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell
                          align='left'
                          sx={{ textTransform: 'capitalize', paddingLeft: '80px !important' }}>
                          Interview Duration
                        </TableCell>
                        <TableCell align='center' sx={{ textTransform: 'capitalize' }}>
                          {resumeDetails?.interview && resumeDetails?.interview[0]?.duration
                            ? resumeDetails?.interview[0]?.duration
                            : '-'}
                        </TableCell>
                      </TableRow>
                      <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell
                          align='left'
                          sx={{ textTransform: 'capitalize', paddingLeft: '80px !important' }}>
                          Venue
                        </TableCell>
                        <TableCell align='center' sx={{ textTransform: 'capitalize' }}>
                          {resumeDetails?.interview && resumeDetails?.interview[0]?.venue
                            ? resumeDetails?.interview[0]?.venue
                            : '-'}
                        </TableCell>
                      </TableRow>
                      <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell
                          align='left'
                          sx={{ textTransform: 'capitalize', paddingLeft: '80px !important' }}>
                          Contact Person
                        </TableCell>
                        <TableCell align='center' sx={{ textTransform: 'capitalize' }}>
                          {resumeDetails?.interview && resumeDetails?.interview[0]?.contactPerson
                            ? resumeDetails?.interview[0]?.contactPerson
                            : '-'}
                        </TableCell>
                      </TableRow>
                      <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell
                          align='left'
                          sx={{ textTransform: 'capitalize', paddingLeft: '80px !important' }}>
                          Contact No.
                        </TableCell>
                        <TableCell align='center' sx={{ textTransform: 'capitalize' }}>
                          {resumeDetails?.interview && resumeDetails?.interview[0]?.contactNumber
                            ? resumeDetails?.interview[0]?.contactNumber
                            : '-'}
                        </TableCell>
                      </TableRow>
                      <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell
                          align='left'
                          sx={{ textTransform: 'capitalize', paddingLeft: '80px !important' }}>
                          Additional Info
                        </TableCell>
                        <TableCell align='center' sx={{ textTransform: 'capitalize' }}>
                          {resumeDetails?.interview && resumeDetails?.interview[0]?.additionalInfo
                            ? resumeDetails?.interview[0]?.additionalInfo
                            : '-'}
                        </TableCell>
                      </TableRow>
                      <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell
                          align='left'
                          sx={{ textTransform: 'capitalize', paddingLeft: '80px !important' }}>
                          Last Updated
                        </TableCell>
                        <TableCell align='center' sx={{ textTransform: 'capitalize' }}>
                          {resumeDetails?.interview && resumeDetails?.interview[0].updatedAt
                            ? moment(resumeDetails?.interview[0].updatedAt).format('DD-MM-YYYY')
                            : '-'}
                        </TableCell>
                      </TableRow>
                    </>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Box>
      </Modal>
    </>
  );
};
