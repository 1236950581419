/* eslint-disable @typescript-eslint/no-unused-vars */
import Closepng from 'assets/png/close.svg';
import {
  Box,
  Breadcrumbs,
  Button,
  // Button,
  // Checkbox,
  // FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  Modal,
  Popover,
  TextField,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import HomeIcon from 'assets/png/dashboardactive.svg';
import ArrowIcon from 'assets/png/breadarrow.svg';
import EditIcon from 'assets/png/edit.svg';
// import EditIcon from 'assets/png/edit.svg';
// import DeleteIcon from 'assets/png/delete.svg';
import AddIcon from 'assets/png/addplus.svg';
import SearchIcon from 'assets/png/nsearch.svg';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { To, useNavigate, useSearchParams } from 'react-router-dom';
import { getAllListData, patchData } from 'services/CommonServices';
import React, { useEffect, useRef, useState } from 'react';
import { useDebounce } from 'hooks';
import { ClientsENDPOINT, PlanENDPOINT } from 'utils/Constant';
import ConfirmDialog from 'components/ConfirmDialog/ConfirmDialog';
import Loader from 'components/loader';
import { useAppSelector } from 'store/hooks';
import UP from 'assets/png/up.svg';
import DOWN from 'assets/png/down.svg';
import Paginate from 'components/Pagination/Pagination';
import { AdminPrivateRoutes } from 'routes/routeConstans';
// import PdfIcon from 'assets/iconcomponents/pdficon';
// import ExcelIcon from 'assets/iconcomponents/excelicon';
import PreIcon from 'assets/png/pre.svg';
import NCAdedIcon from 'assets/iconcomponents/noclientsadded';
import Funnelimg from 'assets/png/FilterFunnel.svg';
import moment from 'moment';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import _ from 'lodash';
import TableTooltip from 'components/Tooltip/Tabletooltip';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
// import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import ExcelIcon from 'assets/iconcomponents/excelicon';
import ThreedotIcon from 'assets/png/threedot.svg';
import { Controller, useForm } from 'react-hook-form';
import { ClientRejectSchema, PlanRejectSchema } from 'utils/ValidatorSchema';
import { yupResolver } from '@hookform/resolvers/yup';
import CustomInput from 'components/CustomInput/customInput';
import { Tooltip } from 'react-tooltip';
import * as R from 'ramda';
import { PlanFilterDrawer } from './PlanFilter/PlanFilterDrawer';
import { toast } from 'react-toastify';
import { AccessDenied } from 'utils/helper';

const CheckIcon = styled('span')(({ theme }) => ({
  borderRadius: 4,
  width: 16,
  height: 16,
  // boxShadow:
  //   theme.palette.mode === 'dark'
  //     ? '0 0 0 1px rgb(16 22 26 / 40%)'
  //     : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
  backgroundColor: theme.palette.mode === 'dark' ? '#EFEFEF' : '#EFEFEF',
  // backgroundImage:
  //   theme.palette.mode === 'dark'
  //     ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
  //     : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
  '.Mui-focusVisible &': {
    outline: '1px auto #D0D5DD',
    outlineOffset: 1,
  },
  'input:hover ~ &': {
    backgroundColor: theme.palette.mode === 'dark' ? '#ffffff' : '#ffffff',
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background: theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
  },
}));

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const CheckedIcon = styled(CheckIcon)({
  backgroundColor: '#0061FF',
  // backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&:before': {
    display: 'block',
    width: 16,
    height: 16,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: '#0061FF',
  },
});

function createData(
  name?: string,
  calories?: number,
  fat?: number,
  carbs?: number,
  protein?: number,
) {
  return { name, calories, fat, carbs, protein };
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const rows = [
  createData('Frozen yoghurt'),
  createData('Ice cream sandwich'),
  createData('Eclair'),
  createData('Cupcake'),
  createData('Gingerbread'),
];

const Defaultparam = {
  search: null,
  order: 'desc',
  skip: 0,
  sortby: 'date',
  status: null,
  take: 50,
  fromDate: null,
  toDate: null,
  crm: null,
  organizationId: null,
  session: null,
  workType: null,
  clientId: null,
  workPlan: null,
  approvalStatus: null,
};

const TooltipComp = ({ apruv, reject }: { apruv: any; reject: any }) => {
  const [panchorEl, setPAnchorEl] = React.useState<HTMLAnchorElement | null>(null);
  const open = Boolean(panchorEl);
  const paid = open ? 'simple-popover' : undefined;
  const handlePoperClose = () => {
    setPAnchorEl(null);
  };
  const handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    setPAnchorEl(event.currentTarget);
  };
  return (
    <>
      <a style={{ width: '100%' }}>
        <img src={ThreedotIcon} alt='threedoticon' onClick={(e: any) => handleClick(e)} />
      </a>
      {/* <Tooltip
        // anchorSelect='#clickable'
        data-tooltip-place={'bottom'}
        place={'bottom'}
        className='Myplantooltipcomp'
        classNameArrow='Myplantooltipcomp-arrow'>
        <div className='Myplantooltipcontent' onClick={apruv}>
          Approve
        </div>
        <div className='Myplantooltipcontent' onClick={reject}>
          Reject
        </div>
      </Tooltip> */}
      <Popover
        className='profile-drop'
        id={paid}
        anchorEl={panchorEl}
        open={open}
        onClose={handlePoperClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        style={{ width: '150px' }}>
        <Typography component={'div'} className='menu-box' style={{ padding: '10px' }}>
          <Typography className='menu-item' onClick={apruv} style={{ padding: '0px 0px 0px 15px' }}>
            {/* <img src={Download} alt='Download' /> */}
            Approve
          </Typography>

          <Typography
            className='menu-item'
            onClick={reject}
            style={{ padding: '0px 0px 0px 15px' }}>
            {/* <img src={View} alt='View' /> */}
            Reject
          </Typography>
        </Typography>
      </Popover>
    </>
  );
};

const MyPlan = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const {
    menuList,
    userInfo: { organizationId: orgdy, id: Useruniqueid, roleId },
  }: any = useAppSelector((store: any) => store.userLogin);
  const [planModule, setPlanModule] = useState([]);
  // const [orgdy,setorgdy] = useState('');
  const [clientId, setclientId] = useState('');
  const [apruvtotal, setapruvtotal] = useState(0);
  const [modaltitle, setmodaltitle] = useState<boolean>(false);
  const navigate = useNavigate();
  const [isRejectionModalOpened, setRejectionModalOpen] = useState(false);
  const [commonstate, setcommonstate] = useState<any[]>([]);
  const [pagetotal, setpagetotal] = useState<number>(0);
  const [params, setParams] = useState<any>({ ...Defaultparam });
  const [Ld, setLd] = useState(false);
  const debouncedParams = useDebounce(params, 500);
  const [confirmOpen, setConfirmOpen] = useState<any>({
    show: false,
    id: '',
  });
  const [searchParams] = useSearchParams();
  const Mode: any = searchParams.get('mode');
  const [scrollPosition, setScrollPosition] = useState(0);
  const containerRef = useRef(null) as any;
  // Function to handle scrolling when the button is clicked
  const handleScroll = (scrollAmount: number) => {
    // Calculate the new scroll position
    const newScrollPosition = scrollPosition + scrollAmount;
    // Update the state with the new scroll position
    if (newScrollPosition >= 0 && newScrollPosition <= 800) {
      setScrollPosition(newScrollPosition);
      const element = containerRef.current;
      // Access the container element and set its scrollLeft property
      element.scrollLeft = newScrollPosition;
    }
  };
  const [taskPlan, setTaskPlan] = useState({
    currentDay: true,
    previousDay: true,
  });
  const {
    control,
    formState: { errors },
    handleSubmit: handleSubmit2,
    setValue: setValue2,
    setError,
  } = useForm<{ comments: string }>({
    criteriaMode: 'all',
    defaultValues: {
      comments: '',
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(PlanRejectSchema),
  });

  const gotoNavigate = (link: To) => {
    navigate(link);
  };

  const listdataapi = () => {
    const searcharr = [
      `${orgdy ? `organizationId=${orgdy}` : null}`,
      `${debouncedParams.search ? `search=${encodeURIComponent(debouncedParams.search)}` : null}`,
      `${
        debouncedParams.fromDate
          ? `fromDate=${moment(debouncedParams.fromDate).format('YYYY-MM-DD')}`
          : null
      }`,
      `${
        debouncedParams.toDate
          ? `toDate=${moment(debouncedParams.toDate).format('YYYY-MM-DD')}`
          : null
      }`,
      `${debouncedParams.session ? `session=${debouncedParams.session}` : null}`,
      `${debouncedParams.workType ? `workType=${debouncedParams.workType}` : null}`,
      `${debouncedParams.order ? `order=${debouncedParams.order}` : null}`,
      `${debouncedParams.sortby ? `sortby=${debouncedParams.sortby}` : null}`,
      `${debouncedParams.clientId ? `clientId=${debouncedParams.clientId}` : null}`,
      `${debouncedParams.workPlan ? `workPlan =${debouncedParams.workPlan}` : null}`,
      `${
        debouncedParams.skip
          ? `skip=${debouncedParams.skip}`
          : debouncedParams.take
            ? 'skip=0'
            : null
      }`,
      `${debouncedParams.take ? `take=${debouncedParams.take}` : null}`,
      `screenType=${Mode === 'apruv' ? 'approve' : 'view'}`,
    ]
      .filter((ea: any) => ea !== 'null')
      .join('&');
    setLd(true);
    // getAllListData(
    //   `${ClientsENDPOINT.Client}${
    //     Object.values({ ...params }).length > 0 && '?'
    //   }${searcharr}`,
    //   true,
    // )
    getAllListData(
      `${PlanENDPOINT.plan}${Object.values({ ...params }).length > 0 && '?'}${searcharr}`,
      true,
    )
      .then((val: any) => {
        setapruvtotal(val && val.totalInreview ? val.totalInreview : 0);
        if (val && val.data && val.data.length > 0) {
          setcommonstate([...val.data]);
          setpagetotal(Number(val.total));
          // val.totalInReview ? setapprovetotal(Number(val.totalInReview)):'';
        } else {
          setcommonstate([]);
        }
        setLd(!true);
      })
      .catch(() => {
        setLd(!true);
      });
  };

  useEffect(() => {
    listdataapi();
    // setcommonstate([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedParams, Mode]);

  const sortablecliks: (sor: string, ord: string) => void = (sor: string, ord: string) => {
    setParams((prev: any) => ({
      ...prev,
      sortby: sor ? sor : null,
      order: ord ? ord : null,
    }));
  };

  function handlePag(sk: number, tk: number) {
    setParams((prev: any) => ({
      ...prev,
      skip: sk,
      take: tk,
    }));
  }
  function handleFilterPag(filteredobj1: any) {
    setParams((prev: any) => ({
      ...prev,
      ...filteredobj1,
    }));
  }

  const localstorageclear: any = () => {
    localStorage.setItem('clientcrm', JSON.stringify([]));
    localStorage.setItem('clientcrm2', JSON.stringify([]));
    localStorage.setItem('clientdetail', JSON.stringify({}));
    localStorage.setItem('clientspoc', JSON.stringify([]));

    localStorage.setItem('refreshstate', JSON.stringify({}));
  };

  const [dweropen, setdweropen] = useState(false);
  const dweropn = () => setdweropen(true);
  const dwerclose = () => {
    setdweropen(false);
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const approveClient = (clientcomments: { approvalStatus: string; remarks: string }) => {
    patchData('', { ...clientcomments }, `${PlanENDPOINT.plan}/status/${clientId}`, true)
      .then(() => {
        listdataapi();
        setRejectionModalOpen(false);
        navigate(AdminPrivateRoutes.MyPlan.MyPlanVIEW);
      })
      .catch((e) => console.log(e));
  };

  const rejectClient = (clientId: string, comments: string) => {
    patchData(
      '',
      { approvalStatus: 'rejected', comments },
      `${PlanENDPOINT.plan}/status/${clientId}`,
      true,
    )
      .then(() => {
        navigate(AdminPrivateRoutes.CLIENTS.CLIENTVIEW);
      })
      .catch((e) => console.log(e));
  };

  const handleRejectClose = () => {
    // reset();
    setError('comments', { type: 'custom', message: undefined });
    setValue2('comments', 'undefined');
    setRejectionModalOpen(false);
  };

  const apruvclick = (id: string) => {
    setclientId(id);
    setValue2('comments', '');
    setmodaltitle(true);
    setRejectionModalOpen(!false);
  };
  const rejectclick = (id: string) => {
    setclientId(id);
    setValue2('comments', '');
    setmodaltitle(false);
    setRejectionModalOpen(!false);
  };

  const onSubmitForm2 = (data: any) => {
    approveClient({
      approvalStatus: modaltitle ? 'approved' : 'rejected',
      remarks: data.comments ? data.comments : '',
    });
  };

  // modal
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (!_.isEmpty(menuList)) {
      if (searchParams.get('mode') === 'apruv') {
        const settingData = menuList
          .find((e: any) => e?.moduleName === 'My Plan')
          .screens.find((a: any) => a?.screenName === 'Approve Plan');
        if (!settingData) {
          navigate('/');
        }
        setPlanModule(settingData && settingData.screens ? settingData.screens : {});
      } else {
        const settingData = menuList.find((e: any) => e.moduleName === 'My Plan');
        if (!settingData) {
          navigate('/');
        }
        setPlanModule(settingData && settingData.screens ? settingData.screens : {});
      }
    }
  }, [menuList]);

  const addWorkPlan = async () => {
    await getAllListData(`${PlanENDPOINT.plan}/access`)
      .then((res) => {
        setTaskPlan({
          currentDay: res.currentDay,
          previousDay: res.previousDay,
        });
      })
      .catch((e) => {
        if (e === 'Forbidden resource') {
          toast.error(AccessDenied);
          navigate('/dashboard');
        }
      });
  };

  useEffect(() => {
    addWorkPlan();
  }, []);

  return (
    <>
      <Grid container>
        <Grid item md={12} sm={12} xs={12}>
          <Typography component={'div'} className='breadcrumbs d-flex-a'>
            <Breadcrumbs aria-label='breadcrumb'>
              <Link underline='hover' color='inherit' href='/'>
                <img src={HomeIcon} className='home' alt='Home' />
              </Link>
              {/* <Link underline='hover' color='inherit' href=''>
                  <img src={ArrowIcon} alt='arrow' className='arrows' />
                  Setting
                </Link> */}
              <Typography
                color='text.primary'
                sx={{ cursor: 'pointer' }}
                onClick={() => {
                  gotoNavigate(AdminPrivateRoutes.MyPlan.MyPlanVIEW);
                }}>
                {' '}
                <img src={ArrowIcon} alt='arrow' className='arrows' /> My Plan
              </Typography>
            </Breadcrumbs>
          </Typography>

          <Typography
            component={'div'}
            className='d-flex-a flex-sb title-add-box'
            sx={{ paddingRight: '56px' }}>
            <Typography className='page-title'>My Plan</Typography>
            <Typography
              component={'div'}
              style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <div className='expot-icon'>
                <span>
                  <ExcelIcon
                    url={`${PlanENDPOINT.plan}${Object.values({ ...params }).length > 0 && '?'}${[
                      `${orgdy ? `organizationId=${orgdy}` : null}`,
                      `${debouncedParams.search ? `search=${encodeURIComponent(debouncedParams.search)}` : null}`,
                      `${
                        debouncedParams.fromDate
                          ? `fromDate=${moment(debouncedParams.fromDate).format('YYYY-MM-DD')}`
                          : null
                      }`,
                      `${
                        debouncedParams.toDate
                          ? `toDate=${moment(debouncedParams.toDate).format('YYYY-MM-DD')}`
                          : null
                      }`,
                      `${debouncedParams.session ? `session=${debouncedParams.session}` : null}`,
                      `${debouncedParams.workType ? `workType=${debouncedParams.workType}` : null}`,
                      `${debouncedParams.clientId ? `clientId=${debouncedParams.clientId}` : null}`,
                      `${
                        debouncedParams.createdBy ? `createdBy=${debouncedParams.createdBy}` : null
                      }`,
                      `${
                        debouncedParams.workPlan ? `workPlan =${debouncedParams.workPlan}` : null
                      }`,
                      'order=desc',
                      'sortby=createdAt',
                      'type=export',
                      `screenType=${Mode === 'apruv' ? 'approve' : 'view'}`,
                    ]
                      .filter((ea: any) => ea !== 'null')
                      .join('&')}`}
                    fileName={'plans'}
                    setLoader={setLd}
                  />
                </span>
                {/* <span style={{ width: '12.17px' }}></span> */}
                {/* <span> */}
                {/* <PdfIcon /> */}
                {/* </span> */}
              </div>
              {searchParams.get('mode') !== 'apruv' ? (
                <>
                  {R.findIndex(R.propEq('Approve Plan', 'screenName'))(
                    planModule ? planModule : [],
                  ) !== -1 ? (
                    <Typography
                      component={'div'}
                      className='add-btn-apruv'
                      onClick={() => {
                        if (apruvtotal > 0) {
                          gotoNavigate(`${AdminPrivateRoutes.MyPlan.MyPlanVIEW}?mode=apruv`);
                        }
                      }}>
                      <Typography component={'div'}>Approve Plan</Typography>{' '}
                      <Typography className='apruv-cunt'>{apruvtotal ? apruvtotal : 0}</Typography>
                    </Typography>
                  ) : (
                    ''
                  )}

                  <div style={{ width: '16px' }}></div>

                  {R.findIndex(R.propEq('Create Plan', 'screenName'))(
                    planModule ? planModule : [],
                  ) !== -1 ? (
                    <Typography
                      component={'div'}
                      className='client-add-btn'
                      // onClick={() => gotoNavigate(AdminPrivateRoutes.MyPlan.MyPlanADD)}
                      onClick={() => {
                        // addWorkPlan();
                        handleOpen();
                      }}>
                      <img src={AddIcon} alt='plus' />
                      <Typography onClick={localstorageclear}>Add Task Plan</Typography>
                    </Typography>
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <Typography
                  component={'div'}
                  className='add-btn-apruv'
                  onClick={() => gotoNavigate(`${AdminPrivateRoutes.MyPlan.MyPlanVIEW}`)}>
                  <Typography component={'div'}>Go To Plans List</Typography>{' '}
                </Typography>
              )}
            </Typography>
          </Typography>
          <Typography component={'div'} className='main-content'>
            <Typography component={'div'} className='white-box'>
              <Typography component={'div'} className='d-flex-cc flex-sb'>
                <TextField
                  className='custom-search'
                  placeholder='Search'
                  id='outlined-start-adornment'
                  value={params.search}
                  sx={{ width: '320px' }}
                  onChange={(e: any) =>
                    setParams((prev: any) => ({
                      ...prev,
                      search: e.target.value,
                      skip: 0,
                      take: 50,
                    }))
                  }
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <img src={SearchIcon} alt='search' />
                      </InputAdornment>
                    ),
                    endAdornment: params.search ? (
                      <InputAdornment position='end'>
                        <IconButton
                          aria-label='toggle password visibility'
                          edge='end'
                          onClick={() => {
                            setParams(() => ({ ...Defaultparam, search: '' }));
                          }}>
                          <CloseIcon style={{ height: '20px', width: '20px' }} />
                        </IconButton>
                      </InputAdornment>
                    ) : null,
                  }}
                />
                <Typography
                  component={'div'}
                  className='d-flex-cc flex-sb d-flex-nwrp filterfunnel-con'
                  onClick={dweropn}>
                  <img src={Funnelimg} alt='filter funnel icon' />
                  <span className='filterfunnel'>More Filters</span>
                </Typography>
              </Typography>
              <Typography component={'div'} className='mt-32' sx={{ position: 'relative' }}>
                <Button onClick={() => handleScroll(-200)} className='pre-btn'>
                  <img src={PreIcon} alt='' />
                </Button>
                <Button onClick={() => handleScroll(200)} className='next-btn'>
                  <img src={PreIcon} alt='' style={{ transform: 'rotate(180deg)' }} />
                </Button>
                <TableContainer component={Paper} className='sb-table2' ref={containerRef}>
                  <Table sx={{ minWidth: 650 }} className='custom-table' aria-label='simple table'>
                    <TableHead>
                      <TableRow
                        sx={{
                          marginLeft: '50px',
                          marginRight: '43px',
                          width: 'calc(100% - 93px)',
                        }}>
                        <TableCell sx={{ whiteSpace: 'nowrap' }}>
                          Date
                          <span className='sorting'>
                            <img
                              src={UP}
                              alt='asc'
                              className='up'
                              onClick={() => sortablecliks('date', 'asc')}
                            />
                            <img
                              src={DOWN}
                              alt='desc'
                              className='down'
                              onClick={() => sortablecliks('date', 'desc')}
                            />
                          </span>
                        </TableCell>
                        <TableCell sx={{ whiteSpace: 'nowrap' }}>
                          Session
                          <span className='sorting'>
                            <img
                              src={UP}
                              alt='asc'
                              className='up'
                              onClick={() => sortablecliks('session', 'asc')}
                            />
                            <img
                              src={DOWN}
                              alt='desc'
                              className='down'
                              onClick={() => sortablecliks('session', 'desc')}
                            />
                          </span>
                        </TableCell>
                        <TableCell sx={{ whiteSpace: 'nowrap' }}>
                          Work Type
                          <span className='sorting'>
                            <img
                              src={UP}
                              alt='asc'
                              className='up'
                              onClick={() => sortablecliks('workPlanType', 'asc')}
                            />
                            <img
                              src={DOWN}
                              alt='desc'
                              className='down'
                              onClick={() => sortablecliks('workPlanType', 'desc')}
                            />
                          </span>
                        </TableCell>
                        <TableCell sx={{ whiteSpace: 'nowrap' }}>
                          Work Description 1
                          <span className='sorting'>
                            <img
                              src={UP}
                              alt='asc'
                              className='up'
                              onClick={() => sortablecliks('workDescription1', 'asc')}
                            />
                            <img
                              src={DOWN}
                              alt='desc'
                              className='down'
                              onClick={() => sortablecliks('workDescription1', 'desc')}
                            />
                          </span>
                        </TableCell>
                        <TableCell sx={{ whiteSpace: 'nowrap' }}>
                          Work Description 2
                          <span className='sorting'>
                            <img
                              src={UP}
                              alt='asc'
                              className='up'
                              onClick={() => sortablecliks('workDescription2', 'asc')}
                            />
                            <img
                              src={DOWN}
                              alt='desc'
                              className='down'
                              onClick={() => sortablecliks('workDescription2', 'desc')}
                            />
                          </span>
                        </TableCell>
                        <TableCell sx={{ whiteSpace: 'nowrap' }}>
                          CTC
                          {/* <span className='sorting'>
                              <img
                                src={UP}
                                alt='asc'
                                className='up'
                                onClick={() => sortablecliks('createdBy', 'asc')}
                              />
                              <img
                                src={DOWN}
                                alt='desc'
                                className='down'
                                onClick={() => sortablecliks('createdBy', 'desc')}
                              />
                            </span> */}
                        </TableCell>
                        <TableCell sx={{ whiteSpace: 'nowrap' }}>
                          Created By
                          <span className='sorting'>
                            <img
                              src={UP}
                              alt='asc'
                              className='up'
                              onClick={() => sortablecliks('createdBy', 'asc')}
                            />
                            <img
                              src={DOWN}
                              alt='desc'
                              className='down'
                              onClick={() => sortablecliks('createdBy', 'desc')}
                            />
                          </span>
                        </TableCell>
                        <TableCell sx={{ whiteSpace: 'nowrap' }}>
                          Created On
                          <span className='sorting'>
                            <img
                              src={UP}
                              alt='asc'
                              className='up'
                              onClick={() => sortablecliks('createdAt', 'asc')}
                            />
                            <img
                              src={DOWN}
                              alt='desc'
                              className='down'
                              onClick={() => sortablecliks('createdAt', 'desc')}
                            />
                          </span>
                        </TableCell>
                        <TableCell sx={{ whiteSpace: 'nowrap' }}>
                          Modified On
                          <span className='sorting'>
                            <img
                              src={UP}
                              alt='asc'
                              className='up'
                              onClick={() => sortablecliks('updatedAt', 'asc')}
                            />
                            <img
                              src={DOWN}
                              alt='desc'
                              className='down'
                              onClick={() => sortablecliks('updatedAt', 'desc')}
                            />
                          </span>
                        </TableCell>
                        <TableCell sx={{ whiteSpace: 'nowrap' }}>
                          Approved Status
                          <span className='sorting'>
                            <img
                              src={UP}
                              alt='asc'
                              className='up'
                              onClick={() => sortablecliks('approvalStatus', 'asc')}
                            />
                            <img
                              src={DOWN}
                              alt='desc'
                              className='down'
                              onClick={() => sortablecliks('approvalStatus', 'desc')}
                            />
                          </span>
                        </TableCell>
                        <TableCell sx={{ whiteSpace: 'nowrap' }}>
                          Remarks
                          <span className='sorting'>
                            <img
                              src={UP}
                              alt='asc'
                              className='up'
                              onClick={() => sortablecliks('remarks', 'asc')}
                            />
                            <img
                              src={DOWN}
                              alt='desc'
                              className='down'
                              onClick={() => sortablecliks('remarks', 'desc')}
                            />
                          </span>
                        </TableCell>

                        <TableCell sx={{ whiteSpace: 'nowrap' }}>
                          Action
                          {/* <span className='sorting'>
                              <img
                                src={UP}
                                alt='asc'
                                className='up'
                                onClick={() => sortablecliks('status', 'asc')}
                              />
                              <img
                                src={DOWN}
                                alt='desc'
                                className='down'
                                onClick={() => sortablecliks('status', 'desc')}
                              />
                            </span> */}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {commonstate && commonstate.length > 0 ? (
                        commonstate.map((row) => (
                          <TableRow
                            key={row.id}
                            sx={{
                              '&:last-child td, &:last-child th': { border: 0 },
                              // verticalAlign: 'baseline',
                              marginLeft: '50px',
                              marginRight: '43px',
                              width: 'calc(100% - 93px)',
                            }}>
                            <TableCell
                              sx={{
                                textTransform: 'capitalize',
                                whiteSpace: 'nowrap',
                                minWidth: '140px',
                              }}>
                              <div>
                                <TableTooltip
                                  value={
                                    row && row.date
                                      ? `${moment(row.date).format('DD-MM-YYYY')}`
                                      : '-'
                                  }
                                  position={true}></TableTooltip>
                              </div>
                            </TableCell>
                            <TableCell sx={{ textTransform: 'capitalize', minWidth: '100px' }}>
                              <TableTooltip
                                value={
                                  row && row.session === 'firstHalf'
                                    ? '1st half'
                                    : row.session === 'secondHalf'
                                      ? '2nd half'
                                      : row.session === 'fullDay'
                                        ? 'Full Day'
                                        : '-'
                                }></TableTooltip>
                            </TableCell>
                            <TableCell
                              sx={{
                                textTransform: 'capitalize',
                                minWidth: '130px',
                                textAlign: 'left !important',
                              }}>
                              {row && row.workPlanType ? row.workPlanType : '-'}
                            </TableCell>
                            <TableCell
                              sx={{
                                textTransform: 'capitalize',
                                maxWidth: '200px',
                                minWidth: '100px',
                                hypens: 'auto',
                              }}>
                              {/* {row && row.description ? row.description.slice(0, 6) : ' '} */}
                              {row &&
                              row.workPlanType === 'sourcing' &&
                              row.client &&
                              row.client.corporateName
                                ? row.client.corporateName
                                : row && row.reason
                                  ? row.reason
                                  : '-'}
                            </TableCell>
                            <TableCell
                              sx={{
                                textTransform: 'capitalize',
                                maxWidth: '200px',
                                minWidth: '100px',
                                hypens: 'auto',
                              }}>
                              <TableTooltip
                                value={
                                  row &&
                                  row.workPlanType === 'sourcing' &&
                                  row.position &&
                                  row.position.title
                                    ? `${row.position.title}`
                                    : row && row.comments
                                      ? row.comments
                                      : '-'
                                }></TableTooltip>
                            </TableCell>

                            <TableCell
                              sx={{
                                textTransform: 'capitalize',
                                minWidth: '100px',
                                hyphens: 'auto',
                              }}>
                              {row.workPlanType === 'sourcing'
                                ? row?.position?.maxCtc
                                  ? ` ${row?.position?.maxCtc / 100000} L`
                                  : ''
                                : 'NA'}
                            </TableCell>

                            <TableCell
                              sx={{
                                textTransform: 'capitalize',
                                minWidth: '120px',
                                hyphens: 'auto',
                              }}>
                              <TableTooltip
                                value={
                                  row &&
                                  row.createdByUser &&
                                  row.createdByUser.firstName &&
                                  row.createdByUser.lastName
                                    ? `${row.createdByUser.firstName} ${row.createdByUser.lastName}`
                                    : '-'
                                }></TableTooltip>
                            </TableCell>

                            <TableCell
                              sx={{
                                textTransform: 'capitalize',
                                whiteSpace: 'nowrap',
                                minWidth: '140px',
                              }}>
                              {row && row.createdAt
                                ? moment(row.createdAt).format('DD-MM-YYYY')
                                : '-'}
                            </TableCell>

                            <TableCell sx={{ textTransform: 'capitalize', minWidth: '130px' }}>
                              {row && row.updatedAt
                                ? moment(row.updatedAt).format('DD-MM-YYYY')
                                : '-'}
                            </TableCell>
                            <TableCell
                              sx={{
                                textTransform: 'capitalize',
                                minWidth: '140px',
                                hyphens: 'auto',
                              }}>
                              {row &&
                              row.approvalStatus &&
                              row &&
                              row.approvalStatus === 'inReview' ? (
                                <button
                                  className={`position-view-btn ${
                                    Mode === 'apruv' ? 'billed' : 'awaitingApproval'
                                  }`}>
                                  <span>
                                    {Mode === 'apruv' ? 'Awaiting Approval' : 'In Review'}
                                  </span>
                                </button>
                              ) : (
                                <></>
                              )}
                              {row &&
                              row.approvalStatus &&
                              row &&
                              row.approvalStatus === 'rejected' ? (
                                <button className='position-view-btn'>
                                  <span>Rejected</span>
                                </button>
                              ) : (
                                <></>
                              )}
                              {row && row.approvalStatus && row.approvalStatus === 'approved' ? (
                                <button className='position-view-btn assigned'>
                                  <span>Approved</span>
                                </button>
                              ) : (
                                <></>
                              )}
                            </TableCell>
                            <TableCell sx={{ textTransform: 'capitalize', minWidth: '100px' }}>
                              {row?.remarks ? row.remarks : '-'}
                            </TableCell>
                            {Mode !== 'apruv' ? (
                              row &&
                              row.approvalStatus &&
                              row.approvalStatus !== 'approved' &&
                              R.findIndex(R.propEq('Create Plan', 'screenName'))(
                                planModule ? planModule : [],
                              ) !== -1 ? (
                                <TableCell sx={{ textTransform: 'capitalize', minWidth: '100px' }}>
                                  <span style={{ marginLeft: '16px' }}>
                                    <img
                                      src={EditIcon}
                                      alt='Edit'
                                      className='pointer'
                                      onClick={() => {
                                        gotoNavigate(
                                          `${AdminPrivateRoutes.MyPlan.MyPlanADD}?mode=edit&ids=${row.id}&planid=${row.plan.id}`,
                                        );
                                      }}
                                    />
                                  </span>
                                </TableCell>
                              ) : (
                                <TableCell sx={{ textTransform: 'capitalize', minWidth: '180px' }}>
                                  <span style={{ marginLeft: '16px' }}>
                                    <img
                                      src={EditIcon}
                                      alt='Edit'
                                      // className='pointer'
                                      style={{ filter: 'saturate(10%)', opacity: '50%' }}
                                    />
                                  </span>
                                </TableCell>
                              )
                            ) : (
                              <></>
                            )}
                            {Mode === 'apruv' ? (
                              <TableCell sx={{ textTransform: 'capitalize', minWidth: '100px' }}>
                                <TooltipComp
                                  apruv={() => apruvclick(row.id)}
                                  reject={() => rejectclick(row.id)}
                                />
                              </TableCell>
                            ) : (
                              <></>
                            )}
                          </TableRow>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={10} align={'center'}>
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginBottom: '43px',
                              }}>
                              <NCAdedIcon />
                              <Typography className='no-client'>No Plan added</Typography>
                            </div>
                          </td>
                        </tr>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Typography>
              <Paginate total={pagetotal} setfn={handlePag} DefaultPerPage={50} />
            </Typography>
          </Typography>
        </Grid>

        <PlanFilterDrawer
          setParams={setParams}
          isOpen={dweropen}
          closeDrawer={dwerclose}
          // callback={handleFilterPag}
          setcommonstate={setcommonstate}
          setLoading={setLd}
          setpagetotal={setpagetotal}
          listdataapi={listdataapi}
        />

        <ConfirmDialog
          title='Confirm'
          floatingBtnShow={true}
          open={confirmOpen.show}
          close={() =>
            setConfirmOpen({
              show: false,
              id: '',
            })
          }
          visibleClose={true}
          onConfirm={(act: any) =>
            act === 'yes'
              ? ''
              : setConfirmOpen({
                  show: false,
                  id: '',
                })
          }
          autoCloseDisable={true}>
          {'Are you sure do you want to delete?'}
        </ConfirmDialog>

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby='parent-modal-title'
          aria-describedby='parent-modal-description'>
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 600,
              height: 306,
              bgcolor: 'background.paper',
              //   border: '2px solid #000',
              boxShadow: 24,
              pt: 2,
              px: 4,
              pb: 3,
              borderRadius: 6,
            }}
            className={'myplan-modal'}>
            <img
              src={Closepng}
              alt='close'
              className='closepng'
              onClick={handleClose}
              style={{ cursor: 'pointer' }}
            />
            <div className='title'>Add Task Plan</div>
            <Grid item md={12} sm={12} xs={12} className='custom-field-my-plan'>
              <div className='title-my-plan'>
                <span className='span1'>Work Plan</span> <span>*</span>
              </div>
              <>
                {/* <FormControl sx={{display:'flex',flexDirection:'column'}}> */}
                <FormLabel id='demo-row-radio-buttons-group-label' sx={{ display: 'none' }}>
                  Gender
                </FormLabel>

                <RadioGroup row className='custom-radio d-flex-sa'>
                  <FormControlLabel
                    value='current'
                    control={<Radio />}
                    disabled={taskPlan.currentDay}
                    label='Current Day Plan'
                    onClick={() =>
                      !taskPlan.currentDay
                        ? gotoNavigate(
                            `${AdminPrivateRoutes.MyPlan.MyPlanADD}?mode=add&type=current`,
                          )
                        : ''
                    }
                  />
                  <FormControlLabel
                    value='previous'
                    control={<Radio />}
                    disabled={taskPlan.previousDay}
                    label='Previous Day Plan'
                    onClick={() =>
                      !taskPlan.previousDay
                        ? gotoNavigate(
                            `${AdminPrivateRoutes.MyPlan.MyPlanADD}?mode=add&type=previous`,
                          )
                        : ''
                    }
                  />
                  {/* <FormControlLabel
                    value='leave'
                    control={<Radio />}
                    disabled={true}
                    label='Long Leave'
                    // onClick={() =>
                    //   gotoNavigate(`${AdminPrivateRoutes.MyPlan.MyPlanADD}?mode=add&type=leave`)
                    // }
                  /> */}
                </RadioGroup>
                {/* </FormControl> */}
              </>
            </Grid>
          </Box>
        </Modal>
        <Modal open={isRejectionModalOpened} onClose={handleRejectClose}>
          <form className='private-form' id={'user-add'} onSubmit={handleSubmit2(onSubmitForm2)}>
            <Box
              className='spoc-list-modal center'
              // style={{ overflow: !opnspocbtndsc ? 'hidden' : 'inherit' }}
            >
              <img src={Closepng} alt='close' className='closepng' onClick={handleRejectClose} />
              <Typography component={'div'} className='custom-field'>
                <Typography component={'div'} className='reason-for-editing'>
                  {modaltitle ? 'Approve Task' : 'Reject Task'}
                </Typography>
                <Typography component={'p'}>
                  {modaltitle ? 'Remarks' : 'Remarks'} <span>*</span>
                </Typography>
                <Controller
                  render={({ field: { onChange, value, name } }) => (
                    <CustomInput
                      value={value}
                      name={name}
                      isTextArea={true}
                      className={`custom-input client-comment-box ${
                        errors.comments?.message ? 'client-comment-box-error' : ''
                      }`}
                      // placeHolder='Changed the SPOC details'
                      error={errors && errors.comments && errors.comments.message}
                      onChange={(e) => {
                        onChange(e);
                      }}
                    />
                  )}
                  name='comments'
                  control={control}
                />
              </Typography>
              {/* <div className='button-con center'>
                <button className='button'>Submit</button>
              </div> */}
              <div className='button-con end'>
                <div className='buttons editreason-btn'>
                  <button onClick={handleRejectClose} className='editreason-btn-close'>
                    Cancel
                  </button>
                  <button
                    type='submit'
                    // form={'user-add'}
                    className='editreason-btn-submit'>
                    Submit
                  </button>
                </div>
              </div>
            </Box>
          </form>
        </Modal>
      </Grid>
      {Ld && <Loader />}
    </>
  );
};

export default MyPlan;
