import {
  Grid,
  Typography,
  Breadcrumbs,
  TextField,
  InputAdornment,
  IconButton,
  Link,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import UP from 'assets/png/up.svg';
import DOWN from 'assets/png/down.svg';
import SearchIcon from 'assets/png/nsearch.svg';
import HomeIcon from 'assets/png/dashboardactive.svg';
import ArrowIcon from 'assets/png/breadarrow.svg';
import ConfirmDialog from 'components/ConfirmDialog/ConfirmDialog';
import Paginate from 'components/Pagination/Pagination';
import Loader from 'components/loader';
import EditIcon from 'assets/png/edit.svg';
import DeleteIcon from 'assets/png/delete.svg';
import { useDebounce } from 'hooks';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AdminPrivateRoutes } from 'routes/routeConstans';
import { getAllListData, deleteData } from 'services/CommonServices';
import { useAppSelector } from 'store/hooks';
import { alertOptions } from 'utils/Config';
import { EVENTS_ENDPOINT } from 'utils/Constant';
import TableTooltip from 'components/Tooltip/Tabletooltip';
import { AccessDenied } from 'utils/helper';

interface Statetype {
  search?: string | null;
  order?: string | null;
  skip?: number | null;
  take?: number | null;
  sortby?: string | null;
  status?: string | null;
  year?: string;
  branchId?: string;
}

const Defaultparam = {
  search: null,
  skip: 0,
  order: 'desc',
  sortby: '',
  status: null,
  take: 50,
};
const ViewEvents = () => {
  const {
    userInfo: { organizationId: orgdy },
  }: any = useAppSelector((store: any) => store.userLogin);
  const [loader, setLoader] = useState<boolean>(false);
  const [params, setParams] = useState<Statetype>({ ...Defaultparam });
  const [pagetotal, setpagetotal] = useState<number>(0);
  const debouncedParams = useDebounce(params, 500);
  const [eventsList, setEventsList] = useState<any>();
  const navigate = useNavigate();
  const containerRef = useRef(null) as any;
  const [confirmOpen, setConfirmOpen] = useState<any>({
    show: false,
    id: '',
  });
  const getList = () => {
    setLoader(true);
    const searcharr = [
      `${orgdy ? `organizationId=${orgdy}` : null}`,
      `${debouncedParams.search ? `search=${encodeURIComponent(debouncedParams.search)}` : null}`,
      `${debouncedParams.status ? `status=${debouncedParams.status}` : null}`,
      `${debouncedParams.order ? `order=${debouncedParams.order}` : null}`,
      `${debouncedParams.sortby ? `sortby=${debouncedParams.sortby}` : null}`,
      `${
        debouncedParams.skip
          ? `skip=${debouncedParams.skip}`
          : debouncedParams.take
            ? 'skip=0'
            : null
      }`,
      `${debouncedParams.take ? `take=${debouncedParams.take}` : null}`,
    ]
      .filter((ea: any) => ea !== 'null')
      .join('&');

    getAllListData(
      `${EVENTS_ENDPOINT.event}${
        Object.values({ ...params, organizationId: orgdy }).length > 0 && '?'
      }${searcharr}`,
      true,
    )
      .then((res: any) => {
        setpagetotal(Number(res.total));
        setEventsList(res && res.data);
        setLoader(false);
      })
      .catch((err) => {
        // console.log(e);
        setLoader(false);
        if (err === 'Forbidden resource') {
          toast.error(AccessDenied);
          navigate('/dashboard');
        }
      });
  };
  const handlePag = (sk: number, tk: number) => {
    setParams((prev: any) => ({
      ...prev,
      skip: sk,
      take: tk,
    }));
  };
  const deletebtn = async (id: any) => {
    setLoader(true);
    try {
      await deleteData(id, `${EVENTS_ENDPOINT.event}`, true);
      setConfirmOpen({
        show: false,
        id: '',
      });
      getList();
    } catch (err) {
      toast.error(String(err), alertOptions);
    } finally {
      setLoader(false);
    }
  };
  const sortablecliks: (sor: string, ord: string) => void = (sor: string, ord: string) => {
    setParams((prev: any) => ({
      ...prev,
      sortby: sor ? sor : null,
      order: ord ? ord : null,
    }));
  };
  useEffect(() => {
    getList();
  }, [debouncedParams]);
  return (
    <Grid container>
      {loader && <Loader />}
      <Grid item md={12} sm={12} xs={12}>
        <Typography component={'div'} className='breadcrumbs d-flex-a'>
          <Breadcrumbs aria-label='breadcrumb'>
            <Link underline='hover' color='inherit' href='/'>
              <img src={HomeIcon} className='home' alt='Home' />
            </Link>
            <Link underline='hover' color='inherit' href={AdminPrivateRoutes.MyEvents.EventsList}>
              <img src={ArrowIcon} alt='arrow' className='arrows' />
              Events
            </Link>
            <Typography color='text.primary'>
              <img src={ArrowIcon} alt='arrow' className='arrows' />
              View All Events
            </Typography>
          </Breadcrumbs>
        </Typography>
        <Typography component={'div'} className='d-flex-a flex-sb title-add-box'>
          <Typography className='page-title'>View All Events</Typography>
        </Typography>
        <Typography component={'div'} className='main-content'>
          <Typography component={'div'} className='white-box'>
            <TextField
              className='custom-search'
              placeholder='Search'
              id='outlined-start-adornment'
              value={params.search}
              sx={{ width: '320px' }}
              onChange={(e: any) =>
                setParams((prev: any) => ({
                  ...prev,
                  search: e.target.value,
                  skip: 0,
                  take: 50,
                }))
              }
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <img src={SearchIcon} alt='search' />
                  </InputAdornment>
                ),
                endAdornment: params.search ? (
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='toggle password visibility'
                      edge='end'
                      onClick={() => {
                        setParams(() => ({ ...Defaultparam, search: '' }));
                      }}>
                      <CloseIcon style={{ height: '20px', width: '20px' }} />
                    </IconButton>
                  </InputAdornment>
                ) : null,
              }}
            />
            <Typography component={'div'} className='mt-32'>
              <TableContainer component={Paper} className='sb-table' ref={containerRef}>
                <Table className='custom-table' aria-label='simple table'>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        Title
                        <span className='sorting'>
                          <img
                            src={UP}
                            alt='asc'
                            className='up'
                            onClick={() => sortablecliks('title', 'asc')}
                          />
                          <img
                            src={DOWN}
                            alt='desc'
                            className='down'
                            onClick={() => sortablecliks('title', 'desc')}
                          />
                        </span>
                      </TableCell>
                      <TableCell>
                        Start Date{' '}
                        <span className='sorting'>
                          <img
                            src={UP}
                            alt='asc'
                            className='up'
                            onClick={() => sortablecliks('startsAt', 'asc')}
                          />
                          <img
                            src={DOWN}
                            alt='desc'
                            className='down'
                            onClick={() => sortablecliks('startsAt', 'desc')}
                          />
                        </span>
                      </TableCell>
                      <TableCell>
                        End Date{' '}
                        <span className='sorting'>
                          <img
                            src={UP}
                            alt='asc'
                            className='up'
                            onClick={() => sortablecliks('endsAt', 'asc')}
                          />
                          <img
                            src={DOWN}
                            alt='desc'
                            className='down'
                            onClick={() => sortablecliks('endsAt', 'desc')}
                          />
                        </span>
                      </TableCell>
                      <TableCell>
                        Description{' '}
                        <span className='sorting'>
                          <img
                            src={UP}
                            alt='asc'
                            className='up'
                            onClick={() => sortablecliks('description', 'asc')}
                          />
                          <img
                            src={DOWN}
                            alt='desc'
                            className='down'
                            onClick={() => sortablecliks('description', 'desc')}
                          />
                        </span>
                      </TableCell>
                      <TableCell>
                        Created On{' '}
                        <span className='sorting'>
                          <img
                            src={UP}
                            alt='asc'
                            className='up'
                            onClick={() => sortablecliks('createdAt', 'asc')}
                          />
                          <img
                            src={DOWN}
                            alt='desc'
                            className='down'
                            onClick={() => sortablecliks('createdAt', 'desc')}
                          />
                        </span>
                      </TableCell>
                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {eventsList && eventsList.length ? (
                      eventsList.map((row: any) => (
                        <TableRow
                          key={row.id}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                          <TableCell
                            sx={{
                              minWidth: '180px',
                              lineHeight: '1.5 !important',
                            }}>
                            {row?.title
                              ? `${row?.title.slice(0, 1).toUpperCase()}` + row?.title.slice(1)
                              : '-'}
                          </TableCell>
                          <TableCell sx={{ textTransform: 'capitalize', minWidth: '150px' }}>
                            {row?.startsAt ? moment(row?.startsAt).format('DD-MM-YYYY') : '-'}
                          </TableCell>
                          <TableCell sx={{ textTransform: 'capitalize', minWidth: '150px' }}>
                            {row?.endsAt ? moment(row?.endsAt).format('DD-MM-YYYY') : '-'}
                          </TableCell>
                          <TableCell
                            sx={{
                              maxWidth: '350px',
                              // lineHeight: '1.5 !important',
                            }}>
                            <TableTooltip
                              value={
                                row?.description
                                  ? `${row?.description.slice(0, 1).toUpperCase()}` +
                                    row?.description.slice(1)
                                  : '-'
                              }
                            />
                          </TableCell>
                          <TableCell sx={{ textTransform: 'capitalize' }}>
                            {row?.createdAt ? moment(row?.createdAt).format('DD-MM-YYYY') : '-'}
                          </TableCell>
                          <TableCell>
                            <div
                              style={{
                                display: 'flex',
                                flexWrap: 'nowrap',
                                alignItems: 'center',
                                alignContent: 'center',
                                transform: 'translateY(8px)',
                              }}>
                              <img
                                src={EditIcon}
                                alt='Edit'
                                style={{
                                  opacity: moment().isAfter(row.endsAt) ? 0.3 : '',
                                  cursor: moment().isAfter(row?.endsAt) ? 'not-allowed' : 'pointer',
                                }}
                                onClick={() =>
                                  !moment().isAfter(row?.endsAt) &&
                                  navigate(`${AdminPrivateRoutes.MyEvents.EventsEdit}/${row.id}`)
                                }
                              />
                              <img
                                src={DeleteIcon}
                                alt='Delete'
                                className='ml-24'
                                style={{
                                  opacity: moment().isAfter(row.endsAt) ? 0.3 : '',
                                  cursor: moment().isAfter(row?.endsAt) ? 'not-allowed' : 'pointer',
                                }}
                                onClick={() =>
                                  !moment().isAfter(row.endsAt) &&
                                  setConfirmOpen((pre: any) => ({
                                    ...pre,
                                    show: true,
                                    id: row.id,
                                  }))
                                }
                              />
                            </div>
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <tr className={'text-center'}>
                        <td colSpan={5} align={'center'}>
                          No Record(s) Found.
                        </td>
                      </tr>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Typography>
            <Grid container className='mt-24'>
              <Grid item md={12} sm={12} xs={12}>
                <Typography component={'div'} className='d-flex-a flex-end pagination'>
                  <Paginate total={pagetotal} setfn={handlePag} DefaultPerPage={50} />
                </Typography>
              </Grid>
            </Grid>
          </Typography>
        </Typography>
      </Grid>
      <ConfirmDialog
        title='Confirmation'
        floatingBtnShow={true}
        open={confirmOpen.show}
        close={() =>
          setConfirmOpen({
            show: false,
            id: '',
          })
        }
        visibleClose={true}
        onConfirm={(act: any) =>
          act === 'yes'
            ? deletebtn(confirmOpen.id)
            : setConfirmOpen({
                show: false,
                id: '',
              })
        }
        autoCloseDisable={true}>
        {'Are you sure do you want to delete this Event?'}
      </ConfirmDialog>
    </Grid>
  );
};
export default ViewEvents;
